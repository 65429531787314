import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  Column,
  Columns,
  Title,
  Text,
  Button,
  Icon,
  Field,
  Control,
  Label,
  Buttons,
} from "@safelyq/bulma-ui-library";
import { NoAppointmentFound } from "../NotFoundSection";
import { useData } from "./hooks";

export const AppointmentDetailView = ({ ...props }) => {
  const [text, setText] = useState("");

  const { id } = props;

  const { goBack } = useHistory();

  const { data, onRemoveComment, onAddComment } = useData({ id });

  const handleTextChange = ({ target: { value } }) => setText(value);

  const handleAddComment = () => {
    onAddComment(text);
    setText("");
  };

  if (isEmpty(data))
    return (
      <NoAppointmentFound
        subtitle={"Sorry maybe you are accessing wrong appointment"}
      />
    );

  return (
    <Fragment>
      <Columns>
        <Column size="12">
          <div className="is-flex is-align-items-center">
            <Button size="small" className="button-icon mr-3" onClick={goBack}>
              <Icon name="arrow-left" />
            </Button>
            <Title size="6" color="grey-light">
              <u>
                <i>APPT-{id}</i>
              </u>
            </Title>
          </div>
        </Column>
      </Columns>
      <Columns multiline>
        <Column size="2">
          <Field>
            <Control>
              <Label>Service</Label>
              <Button size="small" className="button-icon mr-3">
                {data.service}
              </Button>
            </Control>
          </Field>
        </Column>
        <Column size="2">
          <Field>
            <Control>
              <Label>Service Provider</Label>
              <Button size="small" className="button-icon mr-3">
                {data.serviceProvider}
              </Button>
            </Control>
          </Field>
        </Column>
        <Column size="2">
          <Field>
            <Control>
              <Label>Date - Time</Label>
              <Button size="small" className="button-icon mr-3">
                {data.date} - {data.time}
              </Button>
            </Control>
          </Field>
        </Column>
      </Columns>
      <Columns multiline>
        <Column size="2">
          <Field>
            <Control>
              <Label>UserName</Label>
              <Button size="small" className="button-icon mr-3">
                {data.user.name}
              </Button>
            </Control>
          </Field>
        </Column>
        <Column size="2">
          <Field>
            <Control>
              <Label>Email</Label>
              <Button size="small" className="button-icon mr-3">
                {data.user.email}
              </Button>
            </Control>
          </Field>
        </Column>
        <Column size="2">
          <Field>
            <Control>
              <Label>Phone</Label>
              <Button size="small" className="button-icon mr-3">
                {data.user.phone}
              </Button>
            </Control>
          </Field>
        </Column>
        <Column size="12">
          <Text color="grey-light">
            <i>Created By: </i>
            <small>Khalid Ishaq</small>
          </Text>
        </Column>
      </Columns>
      <Columns multiline>
        <Column size="12">
          <Title size="6" color="grey-light">
            <Icon name="comment" className="mr-2" />
            <i>Comments</i>
          </Title>
        </Column>
        <Column size="8">
          {data.notes.map((text, index) => (
            <article className="media" key={`comment-${index}`}>
              <figure className="media-left">
                <p className="image is-48x48">
                  <img
                    src={"/images/dummy-profile.jpg"}
                    alt="profile"
                    className="profile-avatar"
                  />
                </p>
              </figure>
              <div className="media-content">
                <div className="content">
                  <p>
                    <strong>Barbara Middleton</strong> &nbsp;&nbsp;
                    <small className="has-text-grey-light">10-01-2021</small>
                    <br />
                    {text}
                    <br />
                    <small className="has-text-grey-light">
                      <b>
                        <a>Edit</a> ·{" "}
                        <a onClick={() => onRemoveComment(index)}>Delete</a>
                      </b>
                    </small>
                  </p>
                </div>
              </div>
            </article>
          ))}

          <article className="media">
            <figure className="media-left">
              <p className="image is-48x48">
                <img
                  src={"/images/dummy-profile.jpg"}
                  alt="profile"
                  className="profile-avatar"
                />
              </p>
            </figure>
            <div className="media-content">
              <Field>
                <Control>
                  <textarea
                    rows={1}
                    value={text}
                    className="textarea"
                    onChange={handleTextChange}
                    placeholder="Add a comment..."
                  />
                </Control>
              </Field>
              <Field>
                <Control>
                  <Buttons size="small">
                    <Button color="info" onClick={handleAddComment}>
                      Save
                    </Button>
                    <Button>Cancel</Button>
                  </Buttons>
                </Control>
              </Field>
            </div>
          </article>
        </Column>
      </Columns>
    </Fragment>
  );
};
