import React, { useEffect, useRef, useState } from 'react'
import { useChatSLBConverastionMsgs, useChatSLBConversations } from '../hooks';
import { BiConversation } from "react-icons/bi";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useLocation, useHistory } from 'react-router-dom';
import { debounce } from 'lodash';
import moment from 'moment';
import { RxHamburgerMenu } from "react-icons/rx";
import MainChatSection from './MainChatSection';

const AdminConversations = ({ conversationProps }) => {
    // States
    const {
        userId
    } = conversationProps;
    const history = useHistory();
    const { pathname } = useLocation(); // Get the path without query params
    const [previousConversation, setPreviousConversations] = useState([]);
    const totalMessagesCount = useRef(null);
    const scrollableDivRef = useRef(null);
    const scrollableDivRefMobile = useRef(null);
    const scrollableDivMessagesRef = useRef(null);
    const previousConversationTotal = useRef();
    const [paginationForMsgs, setPaginatingForMsgs] = useState(false); // Converasation messages
    const messagesEndRef = useRef(null); // responsible for sending the view to the last message
    const dropdownRef = useRef(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [, setIsPagination] = useState(false); // New flag to track pagination conversation
    const [selectedConversationId, setSelectedConversationId] = useState(null); // New flag to track pagination conversation

    const [messages, setMessages] = useState([]);


    const [selectedMessageId, setSelectedMessageId] = useState(null);
    const [selectedMessageIdForDesktop, setSelectedMessageIdForDesktop] = useState(null);

    const totalConversationsCount = useRef(null);

    // For Conversations
    const [paginationConversation, setPaginationConversation] = useState({
        skip: 0,
        take: 25
    })

    // For Conversation Messages
    const [paginationMsgs, setPaginationMsgs] = useState({
        skip: 0,
        take: 6,
    })

    // CUstom Hooks
    // Get all the conversations
    const {
        getChatSLBConversation,
        conversationData,
    } = useChatSLBConversations();


    // Get all the conversation messages
    const {
        getChatSLBConversationMsgs,
        isLoadingConversationMsgs,
        conversationMsgsData,
    } = useChatSLBConverastionMsgs();

    // useEffects
    useEffect(() => {
        getChatSLBConversation({ variables: { userId: userId, ...paginationConversation } }) // fetch user conversations
    }, [])

    useEffect(() => {
        if (paginationConversation?.skip !== 0) {
            getChatSLBConversation({ variables: { userId: userId, ...paginationConversation } }); // get previous conversation
        }
    }, [paginationConversation?.skip])

    useEffect(() => {
        if (conversationData) {
            totalConversationsCount.current = conversationData?.getChatSLBConversations?.totalCount;

            const fetchedConversations = conversationData.getChatSLBConversations.chatSLBConversations;

            // If pagination, append the new conversations to the previous list
            fetchedConversations && setPreviousConversations((prevConversations) => [
                ...prevConversations,
                ...fetchedConversations
            ]);
        }
    }, [conversationData])

    useEffect(() => {
        // Set previous conversation messages from API to the state
        if (conversationMsgsData?.getConversationMessages?.totalCount) {
            const total = conversationMsgsData?.getConversationMessages?.totalCount;

            totalMessagesCount.current = total;
        }
        if (conversationMsgsData?.getConversationMessages?.conversationMessages) {

            const conversationMessages = conversationMsgsData?.getConversationMessages?.conversationMessages;

            let responseArray = [];
            const response = conversationMessages?.map(message => {
                let responseUser = {
                    customId: messages?.length + 1,
                    id: message?.id,
                    text: message?.userStatement || "",
                    sender: 'user',
                };

                let responseAi = {
                    customId: messages?.length + 2,
                    id: message?.id,
                    text: message?.geminiResponse,
                    sender: 'ai',
                    businesses: message?.businesses
                };

                let responsePrompt = {
                    customId: messages?.length + 3,
                    id: message?.id,
                    text: message?.promptVersioning?.promptText ?
                        message?.promptVersioning?.promptText !== '' ? message?.promptVersioning?.promptText : null : null,
                    sender: 'prompt',
                };

                let responseAiJson = {
                    customId: messages?.length + 4,
                    id: message?.id,
                    text: message?.geminiJsonResponse ?
                        message?.geminiJsonResponse !== '' ? message?.geminiJsonResponse : null // if the response is '' than also store null
                        : null,
                    sender: 'json',
                };


                responseArray.push(responseAiJson, responsePrompt, responseAi, responseUser,);
            });


            // mesages are fetched in last first order in api and than shown column-reverse
            paginationMsgs?.skip > 2
                ?
                setMessages([...messages, ...responseArray,]) // Add to messages
                :
                setMessages([...responseArray]) // first time conversation is being fetched
        }
    }, [conversationMsgsData])

    useEffect(() => {
        const scrollDiv = scrollableDivRef.current;

        if (scrollDiv) {
            scrollDiv.addEventListener('scroll', handleScroll);
            // Cleanup the event listener on component unmount
            return () => {
                scrollDiv.removeEventListener('scroll', handleScroll);
            };
        } else {
        }
    }, [scrollableDivRef.current]);

    // For mobile dropdown
    useEffect(() => {
        const scrollDiv = scrollableDivRefMobile.current;
        if (scrollDiv) {
            scrollDiv.addEventListener('scroll', handleScrollMobile);
            return () => {
                scrollDiv.removeEventListener('scroll', handleScrollMobile);
            };
        }
    }, [scrollableDivRefMobile.current, isDropdownOpen]);

    // set the length of the conversation
    useEffect(() => {
        if (previousConversation?.length !== 0) {
            previousConversationTotal.current = previousConversation?.length;
        }
    }, [previousConversation])

    useEffect(() => {
        // if the conversation id is selected than trigger this
        if (selectedConversationId && paginationMsgs?.skip !== 0) {
            getChatSLBConversationMsgs({ variables: { ...paginationMsgs, conversationId: selectedConversationId } }) // Get mesages of single conversation
        }
    }, [paginationMsgs?.skip])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Functions
    // Custom Drop Down that closes when clicked anywhere other on the screen
    const toggleDropdown = () => {
        setIsDropdownOpen(prevState => !prevState);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    const onSelectChat = (chat) => {
        setMessages([]); // as a new converrsation is selected - empty old conversation messages
        setSelectedConversationId(chat?.id);
        getChatSLBConversationMsgs({ variables: { conversationId: chat?.id, skip: 0, take: 6 } }) // fetch user conversations
        setPaginationMsgs({
            skip: 0,
            take: 6,
        }) // set the pagination to 0 once new conversation is called
    }

    // For Conversations Scroll event handler
    const handleScroll = () => {
        if (scrollableDivRef.current) {

            const { scrollTop, scrollHeight, clientHeight } = scrollableDivRef.current;

            // Check if the user has scrolled to the bottom
            if (scrollTop + clientHeight >= scrollHeight) {
                // Check if we have loaded all conversations

                if (previousConversationTotal?.current >= totalConversationsCount.current) {
                    // No more conversations to load
                    return;
                }

                // Update the pagination state to load more notifications
                setPaginationConversation((prevPagination) => ({
                    ...prevPagination,
                    skip: prevPagination.skip + prevPagination?.take,
                    take: 10,
                }));
                setIsPagination(true); // Set the flag indicating pagination is happening
            }
        }
    };

    const handleScrollMobile = () => {
        if (scrollableDivRefMobile.current) {

            const { scrollTop, scrollHeight, clientHeight } = scrollableDivRefMobile.current;

            // Check if the user has scrolled to the bottom
            if (scrollTop + clientHeight >= scrollHeight) {
                // Check if we have loaded all conversations

                if (previousConversationTotal?.current >= totalConversationsCount.current) {
                    // No more conversations to load
                    return;
                }

                // Update the pagination state to load more notifications
                setPaginationConversation((prevPagination) => ({
                    ...prevPagination,
                    skip: prevPagination.skip + prevPagination?.take,
                    take: 10,
                }));
                setIsPagination(true); // Set the flag indicating pagination is happening
            }
        }
    };


    // For Conversation Messages inside the conversation Scroll event handler
    const handleScrollMsgs = () => {
        if (scrollableDivMessagesRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollableDivMessagesRef.current;

            // In reverse column, scrollTop is negative or 0, so we check when it's close to the maximum top.
            const isAtTop = Math.abs(scrollTop) + clientHeight >= scrollHeight - 1; // Adjust for 1px
            // const tolerance = 1; // Small tolerance for differences in scroll measurements

            // Detect if the user has scrolled to the top in normal flow and not reverse (scrollTop = 0)
            // const isAtTop = scrollTop === 0;

            // Use the current state value of paginationMsgs inside setPaginationMsgs to ensure it is up-to-date
            !isLoadingConversationMsgs && setPaginationMsgs((prevPagination) => { // so that it doesnot call apis even when one is being called

                // Calculate the total number of messages already fetched
                const totalFetched = prevPagination?.skip + prevPagination?.take;

                // Calculate the remaining messages to fetch
                const remainingMessages = totalMessagesCount.current - totalFetched;

                // Ensure we don't request more messages than available
                const take = Math.min(6, remainingMessages); // Take either 4 or the remaining messages if less than 4

                // If we already fetched all messages, prevent further API calls
                if (take === 0) {
                    return prevPagination; // No changes if no more messages to fetch
                }

                // Check if there are still messages left to fetch
                const shouldFetchMore = remainingMessages > 0;

                if (isAtTop && shouldFetchMore) {
                    setPaginatingForMsgs(true); // Set paginating flag when fetching older messages

                    return {
                        ...prevPagination,
                        skip: prevPagination?.skip + 6, // Increment skip by the number of messages fetched
                        take: take, // Adjust take dynamically to fetch the remaining messages
                    };
                }

                return prevPagination; // No changes if no more messages to fetch
            });
        }
    };

    const setRefs = (element) => {
        scrollableDivMessagesRef.current = element;
    };

    // Debounce the scroll handler to limit how often it fires
    const debouncedHandleScrollMsgs = debounce(handleScrollMsgs, 300); // 300ms debounce delay

    useEffect(() => {

        const scrollDiv = scrollableDivMessagesRef.current;
        if (scrollDiv) {

            scrollDiv.addEventListener('scroll', debouncedHandleScrollMsgs);

            // Cleanup the event listener on component unmount
            return () => {
                scrollDiv.removeEventListener('scroll', debouncedHandleScrollMsgs);
            };
        } else {
        }
    }, [scrollableDivMessagesRef.current, debouncedHandleScrollMsgs]);

    // Props
    const mainChatProps = {
        setRefs,
        isLoadingConversationMsgs,
        messages,
        messagesEndRef,
        selectedMessageId,
        setSelectedMessageId,
        selectedMessageIdForDesktop,
        setSelectedMessageIdForDesktop
    };

    return (
        <div>
            <div className='is-flex is-clickable'
                onClick={() => history.push(pathname)}
            >
                <FaArrowLeftLong className='mr-2 mt-1' fontSize={16} />
                <p className=''>Return</p>
            </div>

            {
                previousConversation.length === 0
                    ?
                    <div className='is-flex mt-3'>
                        <BiConversation fontSize={22} className='mr-2' />
                        <p className='has-text-semobild is-size-6'>No Conversations exist for this user</p>
                    </div>
                    :
                    <div className='columns mt-3' style={{ height: "100%", border: "1px solid #D4D4D4", boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)", borderRadius: "2px" }}>
                        <div className='column is-3' style={{ backgroundColor: "rgb(245, 245, 245)" }}>
                            <div className="is-flex is-justify-content-space-between">
                                <p className="is-size-6 has-text-weight-bold has-text-centered my-3 mb-4">
                                    ChatSLB User Conversations
                                </p>

                                {/* Dropdown menu for mobile */}
                                <div className="custom-dropdown is-hidden-desktop pt-2" ref={dropdownRef}>
                                    <div className='setting-icon-custom' onClick={toggleDropdown} >
                                        <RxHamburgerMenu />
                                    </div>

                                    {isDropdownOpen && (
                                        <>
                                            <ul className="dropdown-menu-custom pl-2"
                                                ref={scrollableDivRefMobile}
                                            //  style={{ left: "280%" }}
                                            >
                                                {previousConversation.map(chat => (
                                                    <li key={chat?.id} className='mb-2'>
                                                        <a className='is-flex p-0 m-0 is-clickable' onClick={() => onSelectChat(chat)}>
                                                            <small
                                                                className="is-flex is-justify-content-space-between"
                                                                style={{
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    display: 'block',
                                                                }}
                                                            >
                                                                {chat?.title}
                                                            </small>
                                                        </a>
                                                        <p className='is-size-7 p-0 m-0 has-text-grey'> {moment(chat?.modifiedTime || chat?.createdTime).format('MMMM Do YYYY, h:mm:ss')}</p>
                                                    </li>
                                                ))}
                                            </ul>
                                        </>

                                    )}
                                </div>

                            </div>

                            <ul className="menu-list-custom is-hidden-mobile"
                                ref={scrollableDivRef}
                            >
                                {previousConversation && previousConversation?.map(chat => (
                                    <li key={chat?.id} className='mb-2'>
                                        <a className='is-flex p-0 m-0 is-clickable' onClick={() => onSelectChat(chat)}>
                                            <small
                                                className="is-flex is-justify-content-space-between"
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: 'block',
                                                }}
                                            >
                                                {chat?.title}
                                            </small>
                                        </a>
                                        <p className='is-size-7 p-0 m-0 has-text-grey'> {moment(chat?.modifiedTime || chat?.createdTime).format('MMMM Do YYYY, h:mm:ss')}</p>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className='column is-9'>
                            <MainChatSection mainChatProps={mainChatProps} />
                        </div>
                    </div >
            }
        </div >
    )
}

export default AdminConversations