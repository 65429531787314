import { gql } from '@apollo/client';

export const GET_APPOINTMENT_SUMMARY = gql`
  query GetAppointmentSummary(
    $businessId: Int
    $startDate: Date
    $endDate: Date
  ) {
    getBusinessAppointmentsSummary(
      appointmentsSummaryInput: {
        businessId: $businessId
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      business {
        name
      }
      summary {
        summaryDetails {
          noOfGuests
          startTime
          totalCapacity
          startTimeOnly
          endTimeOnly
          appointments {
            adults
            totalGuests
            status
            checkedInTime
            startTime
            subBusiness {
              name
            }
            venueEntrance {
              name
            }
            service {
              name
            }
            user {
              email
            }
          }
        }
      }
    }
  }
`;

export const GET_BUSINESS_CAMPAIGNS = gql`
query ($selectCampaignsInput: SelectCampaignsInput!) {
getCampaigns(selectCampaignsInput: $selectCampaignsInput){
    totalCount
    campaigns {
      chargeAmount
      details
      endDateTime
      id
      modifiedDate
      startDateTime
      status
      targetSlotAudience
      title
      totalTargetAudience
      zipCodes
      budget
      businessAttachment {
        id
      }
      businessCoupon {
        discountType
        endDate
        discount
      }
      campaignGroups {
        messageText
      }
    }
  }
  }
`;

export const GET_BUSINESS_APPOINTMENTS = gql`
  query GetBusinessAppointments(
    $businessId: Int
    $startDate: Date
    $endDate: Date
    $orderSequence: OrderSequence
    $sortOrder: AppointmentSortOrder
  ) {
    getBusinessAppointments(
      businessId: $businessId
      startDate: $startDate
      endDate: $endDate
      orderSequence: $orderSequence
      sortOrder: $sortOrder
    ) {
      timeZone
      id
      status
      checkedInTime
      business {
        id
      }
      service {
        id
        name
      }
      subBusiness {
        id
        name
      }
      user {
        id
        firstName
        lastName
        name
        email
        phoneNumber
      }
      startTime
      endTime
      startTimeOnly
      endTimeOnly
      comments {
        comment {
          commentText
        }
      }
    }
  }
`;