import { useMutation } from "@apollo/client";
import { UPDATE_BUSINESS_DATA } from "features/admin-manage-business/requests";
import { toast } from "react-toastify";

export const useSaveBusiness = (refetch, setIsRefetching) => {
    const [onUpdateBusinessData, { data: savedData, loading: savedLoading, error: savedError }] = useMutation(UPDATE_BUSINESS_DATA, {
        onCompleted: (savedData) => {
            if (savedData?.saveBusiness?.isSaved) {
                toast.success("Business Data Saved Successfull");
            } else {
                toast.error(savedData?.saveBusiness.errorMessage || "Could not Save Business Details")
            }
        },
        onError: (savedError) => {
            toast.error(savedError.message || "Could not update Business");
        }
    });
    return { onUpdateBusinessData, savedLoading, savedError }
}
