import styled from 'styled-components';

const className = () => {
  const classNames = ['block'].join(' ');

  return classNames;
};

const Block = styled.div.attrs((props) => ({
  className: className`${props}`,
}))``;

Block.propTypes = {};

Block.defaultProps = {};

export default Block;
