import { gql } from '@apollo/client';

export const UPDATE_BUSINESS_COUPON = gql`
  mutation SaveBusinessCoupons($businessCouponInput: BusinessCouponInput!) {
    saveBusinessCoupon(businessCouponInput: $businessCouponInput) {
      errorMessage
      isSaved
    }
  }
`;
