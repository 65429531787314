import React from 'react';
import moment from 'moment';
import { Modal, Title, Text, Buttons, Button, Icon } from '@safelyq/bulma-ui-library';

export const PromotionModalTileView = ({ ...props }) => {
    const { isOpen, onClose, data, setState } = props;
    const { businessCoupons } = data;

    const filterData = businessCoupons?.filter(businessCoupon => {
        return businessCoupon.expireStatus === false;
    })
    const handleSelectButton = (coupon) => {
        setState(coupon)
        onClose()
    }
    const modalStyle = {
        position: "absolute",
        // top: "10%",
        width: '95%',
        borderRadius: '8px'
    }

    return (
        <Modal active={isOpen} clipped>
            <Modal.Background />
            <Modal.Card style={modalStyle}>
                <Modal.Card.Body>
                    <Text>
                        <Title size='6'>Select Coupon</Title>
                    </Text>
                    <br />
                    <div>
                        {
                            filterData?.map((coupon) => (
                                <article className='media'>
                                    <div className='media-content'>
                                        <div>
                                            <span className='is-right'>
                                                <Button style={{ fontSize: '8px' }}
                                                    color='info'
                                                    onClick={() => handleSelectButton(coupon)}
                                                >
                                                    <Icon name='check' />
                                                </Button>
                                            </span>
                                        </div>
                                        <div className='content'>
                                            <p>
                                                <strong>Title: </strong>
                                                <small>{coupon?.title}</small>
                                                <br />
                                                <strong>Summary: </strong>   &nbsp;&nbsp;
                                                <small>{coupon?.summary}</small>
                                                <br />
                                                <strong>Expire Date: </strong>
                                                <small>{moment(coupon?.endDate).format('MM-DD-YYYY')}</small>

                                            </p>
                                        </div>
                                    </div>
                                </article>
                            ))}
                    </div>

                    <div className='mt-5'>
                        <Buttons size='small' className='is-right'>
                            <Button onClick={onClose}>Cancel</Button>
                        </Buttons>
                    </div>
                </Modal.Card.Body>
            </Modal.Card>
        </Modal>
    );
};