import React from 'react';
import { Container, Title, Subtitle } from '@safelyq/bulma-ui-library';

export const NoAppointmentFound = ({ subtitle }) => (
  <section className='hero'>
    <div className='hero-body'>
      <Container className='has-text-centered'>
        <img src='/images/search-not-found.svg' alt="search-not-found" className='mb-2' width={130} />
        <Title size='5'>No appointments found</Title>
        <Subtitle size='6'>
          {subtitle || (
            <span>As appointments are set, they will appear here</span>
          )}
        </Subtitle>
      </Container>
    </div>
  </section>
);
