import { bool } from 'prop-types';
import styled from 'styled-components';
import ModalBackground from './styles/ModalBackground';
import ModalCard from './styles/ModalCard';
import ModalClose from './styles/ModalClose';
import ModalContent from './styles/ModalContent';

const className = (strings, props) => {
  const classNames = ['modal']
    .concat(
      ['active', 'clipped']
        .map((name) => (props[name] ? `is-${name}` : null))
        .filter((className) => !!className)
    )
    .join(' ');

  return classNames;
};

const Modal = styled.div.attrs((props) => ({
  className: className`${props}`,
}))``;

Modal.Background = ModalBackground;
Modal.Content = ModalContent;
Modal.Close = ModalClose;
Modal.Card = ModalCard;

Modal.propTypes = {
  active: bool,
  clipped: bool,
};

Modal.defaultProps = {
  active: undefined,
  clipped: undefined,
};

export default Modal;
