import styled from 'styled-components';
import ModalCardHead from '../ModalCardHead';
import ModalCardBody from '../ModalCardBody';
import ModalCardFoot from '../ModalCardFoot';

const className = (strings, props) => {
  const classNames = ['modal-card'].join(' ');

  return classNames;
};

const ModalCard = styled.div.attrs((props) => ({
  className: className`${props}`,
}))``;

ModalCard.Head = ModalCardHead;
ModalCard.Body = ModalCardBody;
ModalCard.Foot = ModalCardFoot;

ModalCard.propTypes = {};

ModalCard.defaultProps = {};

export default ModalCard;
