import React, { useEffect, useState } from 'react';
import { Box, Icon, Title, Subtitle, Field, Label, Help, PasswordInput, Button } from '@safelyq/bulma-ui-library';
import axios from 'axios';
import { APIPaths } from 'services/api-provider';
import { getAccessToken } from 'methods/addAuthorizationHeader';
import { toast } from 'react-toastify';
import { Spin } from 'antd';


export const ChangePassword = () => {
  const [values, setalues] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  })
  const [errors, seterrors] = useState({
    newPasswordError: "",
    confirmPasswordError: "",
  })
  const [loader, setloader] = useState(false)
  const [accessToken, setAccessToken] = useState('');


  const handleValuesChange = (e) => {
    setalues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }))
  }
  const handlePasswordChange = async () => {
    if (values.confirmPassword === "") {
      seterrors((prev) => ({
        ...prev,
        confirmPasswordError: "This field is required"
      }))
      return
    }
    if (values.newPassword === "") {
      seterrors((prev) => ({
        ...prev,
        newPasswordError: "The Password must be at least 6 characters long"
      }))
      return
    }
    if (values.confirmPassword !== values.newPassword) {
      seterrors((prev) => ({
        ...prev,
        confirmPasswordError: "Please make sure your passwords match"
      }))
      return
    }
    try {
      setloader(true)
      seterrors({
        newPasswordError: "",
        confirmPasswordError: "",
      })
      const response = await axios.post(`${APIPaths.Backend}/account/change-password`, { ...values, }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
      const result = response.data;

      if (result.isSuccess) {
        toast.success('Your password has been changed successfully.');
        setalues({
          oldPassword: "",
          newPassword: "",
          confirmPassword: ""
        })
      }
      else {
        toast.error(result.errors.join('. '));
      }
      setloader(false)
    } catch (error) {
      console.log(error);
    }
  }

  const [inputType, setInputType] = useState({
    currentPassword: 'password',
    newPassword: 'password',
    confirmPassword: 'password',
  })
  const [eye, setEye] = useState({
    current: 'fa fa-eye-slash',
    new: 'fa fa-eye-slash',
    confirm: 'fa fa-eye-slash',
  })

  const handleEye = (name) => {
    if (name === 'current') {
      if (inputType.currentPassword === "password") {
        setInputType({
          ...inputType,
          currentPassword: 'text'
        });
        setEye({
          ...eye,
          current: 'fa fa-eye'
        })
      } else {
        setInputType({
          ...inputType,
          currentPassword: 'password'
        });
        setEye({
          ...eye,
          current: 'fa fa-eye-slash'
        })
      }
    }
    else if (name === 'new') {
      if (inputType.newPassword === "password") {
        setInputType({
          ...inputType,
          newPassword: 'text'
        });
        setEye({
          ...eye,
          new: 'fa fa-eye'
        })
      } else {
        setInputType({
          ...inputType,
          newPassword: 'password'
        });
        setEye({
          ...eye,
          new: 'fa fa-eye-slash'
        })
      }
    }
    else if (name === 'confirm') {
      if (inputType.confirmPassword === "password") {
        setInputType({
          ...inputType,
          confirmPassword: 'text'
        });
        setEye({
          ...eye,
          confirm: 'fa fa-eye'
        })
      } else {
        setInputType({
          ...inputType,
          confirmPassword: 'password'
        });
        setEye({
          ...eye,
          confirm: 'fa fa-eye-slash'
        })
      }
    }
  }

  useEffect(() => {
    (async () => {
      var tokenResult = await getAccessToken();
      setAccessToken(tokenResult);
    })();
  }, [])

  return (
    <Box shadow={true}>
      <Title size='5'>   <Icon name='lock' /> Change Password   </Title>
      <Subtitle size='6' color='grey-light'>   Change password for your SafelyQ account  </Subtitle>
      <hr />
      <div className='row'>
        <div className='col-lg-12 mb-4'>
          <Field>
            <Label>Current Password</Label>
            <div className='control is-flex'>
              <PasswordInput type={inputType.currentPassword} name='oldPassword' value={values.oldPassword} onChange={handleValuesChange} placeholder='*********' />
              <span className="is-medium" onClick={() => { handleEye('current') }} style={{ fontSize: 24, cursor: "pointer", paddingLeft: 12 }}>
                <i className={eye.current} />
              </span>
            </div>
          </Field>
        </div>
        <div className='col-lg-12 mb-4'>
          <Field>
            <Label>New Password</Label>
            <div className='control is-flex'>
              <PasswordInput type={inputType.newPassword} name='newPassword' color={errors.newPasswordError ? 'danger' : 'grey'} value={values.newPassword} onChange={handleValuesChange} placeholder='*********' />
              <span className="is-medium" onClick={() => { handleEye('new') }} style={{ fontSize: 24, cursor: "pointer", paddingLeft: 12 }}>
                <i className={eye.new} />
              </span>
            </div>
            <Help color='danger'>{errors.newPasswordError}</Help>
          </Field>
        </div>
        <div className='col-lg-12 mb-4'>
          <Field>
            <Label>Confirm Password</Label>
            <div className='control is-flex'>
              <PasswordInput type={inputType.confirmPassword} name='confirmPassword' color={errors.confirmPasswordError ? 'danger' : 'grey'} value={values.confirmPassword} onChange={handleValuesChange} placeholder='*********' />
              <span className="is-medium" onClick={() => { handleEye('confirm') }} style={{ fontSize: 24, cursor: "pointer", paddingLeft: 12 }}>
                <i className={eye.confirm} />
              </span>
            </div>
            <Help color='danger'>{errors.confirmPasswordError}</Help>
          </Field>
        </div>
        <div className='col-lg-12 mb-4'>
          <Spin spinning={loader}>
            <Button color='info' onClick={handlePasswordChange}>Save Changes</Button>
          </Spin>
        </div>
      </div>
    </Box>
  )
}
