import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Section, Container } from '@safelyq/bulma-ui-library';
import { Loader } from '@safelyq/base-feature';
import { AppointmentsList, AppointmentDetailView } from '../../components';
import { useData } from './hooks';
import { useSelector } from 'react-redux';

export const ManageAppointment = () => {
  const { search } = useLocation();

  if (String(search).includes('?view=appt-')) {
    return <AppointmentViewSection />;
  } else return <AppointmentListSection />;
};

const AppointmentListSection = () => {
  const { businessId } = useParams();

  const businessSubLevels = useSelector(state => state?.business?.businessSubLevels);
  // const { isLoading, data } = useData();

  // if (isLoading) return <Loader isFullScreen />;

  // const { businessSubLevels } = data;

  const isAnySubLevel =
    !isEmpty(businessSubLevels) &&
    businessSubLevels[0].id !== parseInt(businessId);

  return (
    <Section>
      <Container>
        <AppointmentsList isAnySubLevel={isAnySubLevel} />
      </Container>
    </Section>
  );
};

const AppointmentViewSection = () => {
  const { search } = useLocation();

  let appointmentId = search.substring(search.indexOf('-') + 1);

  return (
    <Section>
      <Container>
        <AppointmentDetailView id={parseInt(appointmentId)} />
      </Container>
    </Section>
  );
};
