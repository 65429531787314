import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { isEmpty, isEqual } from 'lodash';
import PulseLoader from 'react-spinners/PulseLoader';
import {
  Modal,
  Icon,
  Title,
  Field,
  Control,
  Label,
  Buttons,
  Button,
  DateInput,
  Columns,
  Column,
  List,
} from "@safelyq/bulma-ui-library";
import { CommentSection } from "../CommentSection";
import { ResponsesSection } from "../ResponsesSection";
import { AvailableSlots } from "../../../AddAppointment/components";
import { useData } from "./hooks";
import { getStatusName, getStatusColor } from "../../../../helpers";

export const EditAppointmentModal = ({ ...props }) => {
  const { id: appointmentId, isOpen, onClose } = props;

  const [appointmentInfo, setAppointmentInfo] = useState({});
  const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(true);

  const [editableInitialAppointmentInfo, setEditableInitialAppointmentInfo] =
    useState({});

  const compareAppointmentInfo = () => {
    let currentAppointmentInfo = {
      date: appointmentInfo.date,
      service: appointmentInfo.service,
      serviceProvider: appointmentInfo.serviceProvider?.toString(),
      slot: appointmentInfo.slot,
    };
    setIsUpdateButtonDisabled(
      isEqual(currentAppointmentInfo, editableInitialAppointmentInfo)
    );
  };

  const [isAnySubLevel, setIsSubLevel] = useState(true);

  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    const { service: serviceId } = appointmentInfo;

    const service = services.find(
      ({ id }) => parseInt(id) === parseInt(serviceId)
    );

    const isAnySubLevelExist = service?.businesses?.length > 0;

    setIsSubLevel(isAnySubLevelExist);

    compareAppointmentInfo();
  }, [appointmentInfo]);

  const handleAppointmentInfoPopulate = (data) =>
    setAppointmentInfo({ ...data });

  const {
    isLoading,
    isUpdateLoading,
    data: appointment,
    data: organizations,
    onUpdate,
  } = useData({
    appointmentId,
    onPopulateData: handleAppointmentInfoPopulate,
    onCloseModal: onClose,
  });


  const handleServiceChange = ({ target: { name, value } }) => {
    setAppointmentInfo({
      ...appointmentInfo,
      service: value,
      serviceProvider: 0,
      slot: {},
    });
  };

  const handleServiceProviderChange = ({ target: { value, name } }) => {
    setAppointmentInfo({ ...appointmentInfo, [name]: value, slot: {} });
  };

  const handleAppointmentInfoChange = ({ target: { value, name } }) =>
    setAppointmentInfo({ ...appointmentInfo, [name]: value });

  const handleSlotChange = (slot) =>
    setAppointmentInfo({
      ...appointmentInfo,
      slot: {
        startTimeOnly: slot.startTimeOnly,
        startTime: moment(slot.startTime).utc().format("YYYY-MM-DD hh:mm A"),
        endTime: moment(slot.endTime).utc().format("YYYY-MM-DD hh:mm A"),
      },
    });

  const handleModeChange = () => {
    setIsUpdateButtonDisabled(true);
    setEditableInitialAppointmentInfo({
      date: appointmentInfo.date,
      service: appointmentInfo.service,
      serviceProvider: appointmentInfo.serviceProvider?.toString(),
      slot: appointmentInfo.slot,
    });
    if (!isEditMode) setIsEditMode(true);
    else if (window.confirm("Are you sure you want to discard changes?"))
      setIsEditMode(false);
  };

  const isButtonEnable = () => {
    const { service, serviceProvider, date, slot } = appointmentInfo;

    if (service && date && !isEmpty(slot))
      if (isAnySubLevel && !serviceProvider) return false;
      else return true;
    else return false;
  };

  const handleUpdate = () => {
    const {
      service,
      serviceProvider,
      slot,
      businessId,
      timeZone,
      children,
      adults,
    } = appointmentInfo;

    onUpdate({
      variables: {
        appointmentInput: {
          id: appointmentId,
          businessId,
          timeZone,
          adults: parseInt(adults),
          children: parseInt(children),
          serviceId: parseInt(service),
          subBusinessId:
            serviceProvider === 0
              ? parseInt(businessId)
              : parseInt(serviceProvider),
          startTime: slot.startTime,
          endTime: slot.endTime,
        },
      },
    });
  };

  const {
    business: { services, timeZone, isChildrenAllowed, maxAllowedGuests },
    subBusiness,
    startTimeOnly,
    startTime,
    endTime,
  } = appointment;

  const currentSlot = { startTimeOnly, startTime, endTime, subBusiness };

  const isAllowToEditAppointment = !(
    String(appointmentInfo.status).toUpperCase() === "PASSED" ||
    String(appointmentInfo.status).toUpperCase() === "CANCELLED" ||
    String(appointmentInfo.status).toUpperCase() === "COMPLETED" ||
    String(appointmentInfo.status).toUpperCase() === "MISSED" ||
    String(appointmentInfo.status).toUpperCase() === "CHECKEDIN" ||
    String(appointmentInfo.status).toUpperCase() === "CHECKEDINBYBUSINESS" ||
    String(appointmentInfo.status).toUpperCase() === "CHECKED_IN_BY_BUSINESS" ||
    String(appointmentInfo.status).toUpperCase() === "SERVING_NOW"
  );

  const isAllowComment = !(
    String(appointmentInfo.status).toUpperCase() === "PASSED" ||
    String(appointmentInfo.status).toUpperCase() === "CANCELLED" ||
    String(appointmentInfo.status).toUpperCase() === "COMPLETED"
  );

  const isGuestsAllowed = maxAllowedGuests > 0;

  return (
    <Modal active={isOpen} clipped>
      <Modal.Background />
      <Modal.Card>
        <Modal.Card.Head className={"is-flex is-justify-content-space-between"}>
          <Title size="5" className="m-0">
            Edit Appointment
            <span className="tag is-black is-small ml-3">
              <u>
                <i>APPT-{appointmentId}</i>
              </u>
            </span>
            <span
              className={`tag is-light ml-3`}
              style={{
                background: `rgba(${getStatusColor(
                  appointmentInfo?.status
                )}, 0.1)`,
                color: `rgb(${getStatusColor(appointmentInfo?.status)})`,
              }}
            >
              {getStatusName(appointmentInfo?.status)}
            </span>
          </Title>
          <Button size="small" className="button-icon" onClick={onClose}>
            <Icon name="close" className="is-right" />
          </Button>
        </Modal.Card.Head>
        <Modal.Card.Body>
          {isLoading ? (
            <PulseLoader size={7} />
          ) : (
            <Fragment>
              <Columns multiline>
                {!isAllowToEditAppointment && (
                  <Column size="12">
                    <small className="has-text-danger">
                      You can't edit this appointment.
                    </small>
                  </Column>
                )}
                <Column size="4">
                  <Field>
                    <Control>
                      <Label>Name</Label>
                      <span>{appointmentInfo?.name}</span>
                    </Control>
                  </Field>
                </Column>
                {appointmentInfo?.email && (
                  <Column size="4">
                    <Field>
                      <Control>
                        <Label>Email</Label>
                        <span>{appointmentInfo?.email}</span>
                      </Control>
                    </Field>
                  </Column>
                )}
                {appointmentInfo?.phoneNumber && (
                  <Column size="4">
                    <Field>
                      <Control>
                        <Label>Phone Number</Label>
                        <span>{appointmentInfo?.phoneNumber}</span>
                      </Control>
                    </Field>
                  </Column>
                )}
                <Column size="6">
                  <Field>
                    <Control>
                      <Label>Date</Label>
                      <DateInput
                        name="date"
                        disabled={!isEditMode}
                        value={appointmentInfo.date}
                        onChange={handleAppointmentInfoChange}
                      />
                    </Control>
                  </Field>
                </Column>
                <Column size="6">
                  <Field>
                    <Control>
                      <Label>Entrance</Label>
                      <input
                        name="entrance"
                        // disabled={!isEditMode}
                        disabled={true}
                        className="input"
                        value={appointmentInfo.venueEntrance}
                        onChange={handleAppointmentInfoChange}
                      />
                    </Control>
                  </Field>
                </Column>
                <Column size="6">
                  <Field>
                    <Control>
                      <Label>Service</Label>
                      <div className="select is-fullwidth">
                        <select
                          name="service"
                          disabled={!isEditMode}
                          value={appointmentInfo.service}
                          onChange={handleServiceChange}
                        >
                          <option value={""}>Select Service</option>
                          {services.map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Control>
                  </Field>
                </Column>
                {isAnySubLevel && (
                  <Column size="6">
                    <Field>
                      <Control>
                        <Label>Service Provider</Label>
                        <div className="select is-fullwidth">
                          <select
                            name="serviceProvider"
                            disabled={!isEditMode}
                            value={appointmentInfo.serviceProvider}
                            onChange={handleServiceProviderChange}
                          >
                            <option value={""}>Select Service</option>
                            {services
                              .find(
                                ({ id }) =>
                                  parseInt(id) ===
                                  parseInt(appointmentInfo.service)
                              )
                              ?.businesses.map(({ id, name }) => (
                                <option key={id} value={id}>
                                  {name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </Control>
                    </Field>
                  </Column>
                )}
                {isGuestsAllowed && (
                  <Column size="6">
                    <Field>
                      <Label size="medium">Additional Guests</Label>
                      <div className="select is-fullwidth">
                        <select
                          name="adults"
                          disabled={!isEditMode}
                          value={parseInt(appointmentInfo.adults)}
                          onChange={handleAppointmentInfoChange}
                        >
                          <option value={""}>No of Guests</option>
                          {[...Array(maxAllowedGuests)].map((_, index) => (
                            <option key={`guest-${index}`} value={index + 1}>
                              {index + 1}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Field>
                  </Column>
                )}
              </Columns>
              <Columns multiline>
                <Column size="4">
                  {isChildrenAllowed ? (
                    <Field>
                      <Label size="medium">Children in your party?</Label>
                      <div className="control">
                        <label className="radio">
                          <input
                            type="radio"
                            name="children"
                            value={1}
                            disabled={!isEditMode}
                            checked={parseInt(appointmentInfo.children)}
                            onChange={handleAppointmentInfoChange}
                            className="mr-2"
                          />
                          Yes
                        </label>
                        <label className="radio">
                          <input
                            type="radio"
                            name="children"
                            value={0}
                            disabled={!isEditMode}
                            checked={!parseInt(appointmentInfo.children)}
                            onChange={handleAppointmentInfoChange}
                            className="mr-2"
                          />
                          No
                        </label>
                      </div>
                    </Field>
                  ) : (
                    <Field>
                      <Title size="6">
                        <Icon name="times" color="danger" />
                        Adults only
                      </Title>
                    </Field>
                  )}
                </Column>
              </Columns>
              <Columns multiline>
                {isEditMode ? (
                  <Column size="12">
                    <AvailableSlots
                      {...appointmentInfo}
                      isAnySubLevel={isAnySubLevel}
                      timeZone={timeZone}
                      currentSlot={currentSlot}
                      onChangeSlot={handleSlotChange}
                    />
                  </Column>
                ) : (
                  <Column size="6">
                    <Field>
                      <Control>
                        <Label>Appointment Time</Label>
                        <span className="tag is-info is-small is-disabled">
                          {moment(appointmentInfo?.startTime).format("hh:mm A")}
                        </span>
                      </Control>
                    </Field>
                  </Column>
                )}

                <Column size="12">
                  <CommentSection
                    locations={organizations}
                    appointmentId={appointmentId}
                    comments={appointmentInfo.comments || []}
                    isAllowComment={isAllowComment}
                  />
                </Column>
                <Column size="12">
                  <ResponsesSection responses={appointmentInfo.responses} />
                </Column>
              </Columns>
            </Fragment>
          )}
          <span />
        </Modal.Card.Body>
        <Modal.Card.Foot className="has-background-white">
          <Buttons size="small" className="is-right">
            <Button
              color="info"
              disabled={
                (isEditMode && isUpdateButtonDisabled) || !isAllowToEditAppointment || isLoading || isUpdateLoading || !isButtonEnable()
              }
              onClick={isEditMode ? handleUpdate : handleModeChange}
            >
              {isEditMode ? (
                <span>Update</span>
              ) : (
                <span>
                  <Icon name="pencil" /> &nbsp; Edit
                </span>
              )}
            </Button>
            {isEditMode && <Button onClick={handleModeChange}>Cancel</Button>}
          </Buttons>
        </Modal.Card.Foot>
      </Modal.Card>
    </Modal>
  );
};
