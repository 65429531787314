import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment';
import voucher_codes from 'voucher-code-generator';
import {
  Columns,
  Column,
  Button,
  Modal,
  Title,
  Icon,
} from '@safelyq/bulma-ui-library';
import { useBusinessState } from '@safelyq/admin-promotions-feature';
import { UploadControl } from '../UploadControl';
import { useData, useImagePicker } from './hooks';
import { isGSMAlphabet } from 'methods/isGSMAlphabet';

const defaultValues = {
  code: '',
  title: '',
  summary: '',
  startDate: moment().format('YYYY-MM-DD'),
  endDate: moment().format('YYYY-MM-DD'),
  discountType: 'PERCENTAGE',
  discount: '',
  isActive: false,
  terms: '',
};

export const AddCouponModal = ({ isOpen, onClose, refetch }) => {
  const [values, setValues] = useState(defaultValues);

  const [isLimitedUsage, setUsageLimited] = useState(false);

  const [isCouponCodeExist, setCouponCodeExist] = useState(false);

  const [imageUrl, setImageUrl] = useState(null);

  const { id: businessId } = useParams();

  const { business, selectedCouponId } = useBusinessState();

  const { isLoading, onSaveBusinessCoupon } = useData(
    onClose,
    values,
    selectedCouponId,
    refetch
  );

  const {
    base64String,
    name: imageFileName,
    type: imageFileType,
    onUpload,
    onRemove,
  } = useImagePicker();

  useEffect(() => {
    if (selectedCouponId === 0 || !selectedCouponId) {
      setValues(defaultValues);

      setImageUrl('');

      onRemove();
      return;
    }

    if (!isEmpty(business) && selectedCouponId) {
      onRemove();

      const { businessCoupons } = business;

      const coupon = businessCoupons.find(({ id }) => id === selectedCouponId);

      if (!isEmpty(coupon)) {
        let startDate = coupon.startDate;

        let endDate = coupon.endDate;

        if (coupon.couponUsageLimit === 'Limited') setUsageLimited(true);
        else setUsageLimited(false);

        setValues({
          code: coupon.code || '',
          title: coupon.title || '',
          discount: parseFloat(coupon.discount) || '',
          couponUsageLimitValue: coupon.couponUsageLimitValue || '',
          discountType: String(coupon.discountType).toUpperCase(),
          startDate: startDate && moment(startDate).format('YYYY-MM-DD'),
          endDate: endDate && moment(endDate).format('YYYY-MM-DD'),
          summary: coupon.summary || '',
          terms: coupon.terms || '',
          isActive: coupon.isActive,
          couponUrl: coupon.couponUrl || coupon.imageUrl,
        });

        setImageUrl(coupon.imageUrl || coupon.couponUrl);
      }
    }
  }, [selectedCouponId]);

  const handleImageRemove = () => {
    setImageUrl('');
    onRemove();
  };

  const handleValueChangeGsm = ({ target: { name, value } }) => {
    if (!isGSMAlphabet(value)) return
    setValues({ ...values, [name]: value });
  };

  const handleCodeChange = ({ target: { value } }) => {
    const regex = /^[A-Za-z0-9]+$/;

    if (String(value).length <= 15 && regex.test(value))
      setValues({ ...values, code: value });

    if (!value) setValues({ ...values, code: '' });

    //check is coupon code already used/exist
    setCouponCodeExist(false);

    const { businessCoupons } = business;

    const isCouponCodeExist = businessCoupons.find(
      (coupon) =>
        String(coupon.code).toLowerCase() === String(value).toLowerCase()
    );

    setCouponCodeExist(isCouponCodeExist);
  };

  const handleUsageToggle = ({ target: { value, checked } }) => {
    if (!checked) {
      setValues({ ...values, couponUsageLimitValue: 0 });

      setUsageLimited(false);

      return;
    }

    setValues({ ...values, couponUsageLimitValue: value });

    if (value >= 1 || isEmpty(value)) setUsageLimited(true);
    else setUsageLimited(false);
  };

  const handleUsageChange = ({ target: { value } }) => {
    if (value === 0) setUsageLimited(true);

    setValues({ ...values, couponUsageLimitValue: value });
  };

  const handleValuesChange = ({ target: { name, value } }) =>
    setValues({ ...values, [name]: value });

  const handleCodeGenerate = () => {
    setCouponCodeExist(false);

    const code = voucher_codes.generate({
      length: 6,
      count: 1,
      charset: voucher_codes.charset('alphanumeric'),
    });

    setValues({ ...values, code: code[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let couponId = 0;

    let couponUsageLimitValue = 0;

    let couponUsageLimit = 'UNLIMITED';

    if (selectedCouponId) couponId = selectedCouponId;

    if (!isEmpty(values.couponUsageLimitValue)) {
      couponUsageLimitValue = values.couponUsageLimitValue;

      if (values.couponUsageLimitValue > 1) couponUsageLimit = 'LIMITED';
      else couponUsageLimit = 'ONE_PER_CUSTOMER';
    }

    values.discount = parseFloat(values.discount);

    if (base64String || !imageUrl) delete values.couponUrl;

    onSaveBusinessCoupon({
      variables: {
        businessCouponInput: {
          id: parseInt(couponId),
          businessId: parseInt(businessId) || 0,
          ...values,
          code: String(values.code).toUpperCase(),
          couponUsageLimitValue: parseInt(couponUsageLimitValue),
          couponUsageLimit,
          image: {
            fileName: imageFileName,
            contentType: imageFileType,
            base64String: window.btoa(base64String),
          },
        },
      },
    }).then(() => {
      setValues(defaultValues);
      setImageUrl('');
      setUsageLimited(false);
    });
  };

  const props = {
    image: selectedCouponId ? base64String || imageUrl : base64String,
    onUpload,
    onRemove: handleImageRemove,
  };

  return (
    <Modal active={isOpen} clipped>
      <Modal.Background />
      <Modal.Card>
        <Modal.Card.Head className="is-justify-content-space-between">
          <Title size='5' className='m-0'>
            {selectedCouponId ? 'Edit Coupon' : 'Add new coupon'}
          </Title>
          <div className='control'>
            <label className='checkbox'>
              <input
                type='checkbox'
                name='isActive'
                checked={values.isActive}
                onChange={({ target: { checked } }) =>
                  setValues({ ...values, isActive: checked })
                }
              />
              <strong className='ml-2'>Is Active</strong>
            </label>
          </div>
        </Modal.Card.Head>
        <Modal.Card.Body>
          <form onSubmit={handleSubmit}>
            <Columns multiline>
              <Column size='12'>
                <div className='field'>
                  <label className='label'>
                    Title <span className='has-text-danger'>*</span>
                  </label>
                  <div className='control'>
                    <input
                      maxlength='40'
                      name='title'
                      value={values?.title}
                      className='input'
                      required={true}
                      onChange={handleValueChangeGsm}
                    />
                  </div>
                </div>
              </Column>
              <Column size='7'>
                <div className='field'>
                  <label className='label'>
                    Code <span className='has-text-danger'>*</span>
                  </label>
                  <div className='is-flex control'>
                    <input
                      name='code'
                      value={values.code}
                      className={`input ${isCouponCodeExist && 'is-danger'}`}
                      required={true}
                      onChange={handleCodeChange}
                    />
                    <Button
                      type='button'
                      light
                      color='info'
                      onClick={handleCodeGenerate}
                    >
                      Generate Code
                    </Button>
                  </div>
                  {isCouponCodeExist && (
                    <p className='help is-danger'>Coupon code already used</p>
                  )}
                </div>
              </Column>
              <Column size='12'>
                <div className='field'>
                  <label className='label'>Summary</label>
                  <div className='control'>
                    <textarea
                      name='summary'
                      value={values.summary}
                      className='textarea'
                      maxLength='250'
                      rows={2}
                      onChange={handleValueChangeGsm}
                    />
                  </div>
                </div>
              </Column>
              <Column size='12'>
                <div className='field'>
                  <label className='label'>Terms & conditions</label>
                  <div className='control'>
                    <textarea
                      name='terms'
                      value={values.terms}
                      className='textarea'
                      rows={3}
                      onChange={handleValuesChange}
                    />
                  </div>
                </div>
              </Column>
              <Column size='12'>
                <Title size='5'>Coupon Data</Title>
              </Column>
              <Column size='6'>
                <div className='field'>
                  <label className='label'>
                    Start Date <span className='has-text-danger'>*</span>
                  </label>
                  <div className='control'>
                    <input
                      name='startDate'
                      value={values.startDate}
                      type='date'
                      min={!selectedCouponId && moment().format('YYYY-MM-DD')}
                      required={true}
                      className='input'
                      onChange={handleValuesChange}
                    />
                  </div>
                </div>
              </Column>
              <Column size='6'>
                <div className='field'>
                  <label className='label'>
                    Expiration Date <span className='has-text-danger'>*</span>
                  </label>
                  <div className='control'>
                    <input
                      name='endDate'
                      value={values.endDate}
                      type='date'
                      required={true}
                      className='input'
                      min={moment(values.startDate).format('YYYY-MM-DD')}
                      onChange={handleValuesChange}
                    />
                  </div>
                </div>
              </Column>
              <Column size='6'>
                <div className='field'>
                  <label className='label'>
                    Discount Type <span className='has-text-danger'>*</span>
                  </label>
                  <div className='select is-fullwidth'>
                    <select
                      name='discountType'
                      value={values.discountType}
                      required={true}
                      onChange={handleValuesChange}
                    >
                      <option value='DEFAULT' disabled>
                        ---Select Discount Type---
                      </option>
                      <option value='PERCENTAGE'>Percentage</option>
                      <option value='VALUE'>Value</option>
                    </select>
                  </div>
                </div>
              </Column>
              <Column size='6'>
                <div className='field'>
                  <label className='label'>
                    Coupon Amount <span className='has-text-danger'>*</span>
                  </label>
                  <div className='control has-icons-left has-icons-right'>
                    <input
                      name='discount'
                      value={values.discount}
                      type='number'
                      required={true}
                      className='input'
                      onChange={handleValuesChange}
                    />
                    {values.discountType === 'PERCENTAGE' && (
                      <span className='icon is-small is-right'>
                        <Icon name='percent' />
                      </span>
                    )}
                    {values.discountType === 'VALUE' && (
                      <span className='icon is-small is-left'>
                        <Icon name='usd' />
                      </span>
                    )}
                  </div>
                </div>
              </Column>
              <Column size='6'>
                <div className='control'>
                  <label className='label'>Coupon Image</label>
                  <UploadControl name='image' {...props} />
                </div>
              </Column>
              <Column size='12'>
                <Title size='5'>Usage Limit</Title>
              </Column>
              <Column size='12'>
                <div className='control'>
                  <label className='checkbox'>
                    <input
                      type='checkbox'
                      name='couponUsageLimitValue'
                      value={2}
                      checked={
                        isLimitedUsage && isEmpty(values.couponUsageLimitValue)
                          ? true
                          : values.couponUsageLimitValue >= 1
                      }
                      onChange={handleUsageToggle}
                    />
                    <span className='ml-2'>
                      Limit the number of times this coupon can be used
                    </span>
                  </label>
                </div>
              </Column>
              {isLimitedUsage && (
                <Column size='4'>
                  <div className='field'>
                    <div className='control'>
                      <input
                        name='couponUsageLimitValue'
                        value={values.couponUsageLimitValue}
                        type='number'
                        required={isLimitedUsage ? true : false}
                        className='input'
                        placeholder='Enter limit'
                        onChange={handleUsageChange}
                      />
                    </div>
                  </div>
                </Column>
              )}
              {/*<Column size='12'>
                <div className='control'>
                  <label className='checkbox'>
                    <input
                      type='checkbox'
                      name='couponUsageLimitValue'
                      value={1}
                      checked={values.couponUsageLimitValue == 1}
                      onChange={handleUsageToggle}
                    />
                    <span className='ml-2'>Limit to one use per customer</span>
                  </label>
                </div>
              </Column>*/}
              <Column size='12'>
                <Button
                  color='info'
                  disabled={isLoading || isCouponCodeExist}
                  loading={isLoading}
                >
                  {selectedCouponId ? 'Save Coupon' : 'Add Coupon'}
                </Button>
                &nbsp;
                <Button type='button' onClick={onClose}>
                  Cancel
                </Button>
              </Column>
            </Columns>
          </form>
        </Modal.Card.Body>
      </Modal.Card>
    </Modal>
  );
};
