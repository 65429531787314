import React, { useContext, useReducer, createContext } from 'react';
import { isEmpty } from 'lodash';

const MessageStateContext = createContext();
const MessageDispatchContext = createContext();

const messageReducer = (state, action) => {
  switch (action.type) {
    case 'SET_NEW_MESSAGE_OPEN':
      return {
        ...state,
        isOpenNewMessageSection: action.payload.isOpen,
      };

    case 'SET_NEW_MESSAGE_TO_RECENT': {
      let recentConversation = [...state.recentConversation];

      const { user, recentMessages, recentMessageTime } = action.payload;

      const index = recentConversation.findIndex(
        ({ user: { id } }) => id === user.id
      );

      let latestConversation = { ...recentConversation[index] };

      latestConversation.recentMessages = [
        { message: { commentText: recentMessages[0].message.commentText } },
      ];

      latestConversation.user = user;

      latestConversation.recentMessageTime = recentMessageTime;

      latestConversation.unreadCount = 0;

      recentConversation.splice(index, 1);

      recentConversation = [latestConversation, ...recentConversation];

      return {
        ...state,
        recentConversation:
          index === -1
            ? [action.payload, ...state.recentConversation]
            : recentConversation,
        recentConversationCopy:
          index === -1
            ? [action.payload, ...state.recentConversation]
            : recentConversation,
        isOpenNewMessageSection: false,
      };
    }

    case 'SET_RECENT_CONVERSATION':
      return {
        ...state,
        recentConversation: action.payload.conversations,
        recentConversationCopy: action.payload.conversations,
        isReceivedSignal: false,
      };

    case 'SET_SELECTED_USER': {
      let recentConversation = [...state.recentConversation];

      const index = recentConversation.findIndex(
        ({ user: { id } }) => id === action.payload.id
      );

      if (index !== -1) {
        let conversation = { ...recentConversation[index] };

        conversation.unreadCount = 0;

        recentConversation.splice(index, 1, conversation);
      }

      return {
        ...state,
        isOpenNewMessageSection: false,
        selectedUserId: action.payload.id,
        recentConversation,
      };
    }

    case 'SET_BUSINESSES':
      return {
        ...state,
        businessesList: action.payload.businesses,
      };

    case 'SET_USER_CONVERSATION':
      return {
        ...state,
        userConversation: action.payload.conversation,
        isSuccess: action.payload.isSuccess,
        user: action.payload.user,
        business: action.payload.business,
      };
    case 'SET_LATEST_MESSAGE': {
      let recentConversation = [...state.recentConversation];

      const { user, recentMessages, ...rest } = action.payload;

      const index = recentConversation.findIndex(
        ({ user: { id } }) => id === user.id
      );

      let latestConversation = { ...recentConversation[index] };

      latestConversation.recentMessages = [
        {
          message: { commentText: recentMessages[0].message.commentText },
          attachment: rest.attachment,
        },
      ];

      latestConversation.recentMessageTime = new Date();

      latestConversation.user = user;

      recentConversation.splice(index, 1);

      recentConversation = [latestConversation, ...recentConversation];

      //const result={
      //  ...state,
      //  recentConversation:
      //    index === -1
      //      ? [action.payload, ...state.recentConversation]
      //      : recentConversation,
      //  recentConversationCopy:
      //    index === -1
      //      ? [action.payload, ...state.recentConversation]
      //      : recentConversation,
      //  userConversation: [
      //    {
      //      ...rest,
      //      user,
      //      message: {
      //        user: recentMessages[0].message.user,
      //        commentText: recentMessages[0].message.commentText,
      //      },
      //      attachment: rest.attachment
      //    },
      //    ...state.userConversation,
      //  ],
      //};
      //console.log(result);
      return {
        ...state,
        recentConversation:
          index === -1
            ? [action.payload, ...state.recentConversation]
            : recentConversation,
        recentConversationCopy:
          index === -1
            ? [action.payload, ...state.recentConversation]
            : recentConversation,
        userConversation: [
          {
            ...rest,
            user,

            message: {
              user: recentMessages[0].message.user,
              commentText: recentMessages[0].message.commentText,
            },
          },
          ...state.userConversation,
        ],
      };
    }

    case 'FILTER_RECENT_MESSAGES': {
      const recentConversation = action.payload
        ? state.recentConversation.filter(
            ({
              user: { firstName, lastName, name, phoneNumber },
              recentMessages,
            }) =>
              String(firstName)
                .toLocaleLowerCase()
                .includes(String(action.payload).toLowerCase()) ||
              String(lastName)
                .toLocaleLowerCase()
                .includes(String(action.payload).toLowerCase()) ||
              String(name)
                .toLocaleLowerCase()
                .includes(String(action.payload).toLowerCase()) ||
              String(phoneNumber)
                .toLocaleLowerCase()
                .includes(String(action.payload).toLowerCase()) ||
              String(recentMessages[0].message.commentText)
                .toLocaleLowerCase()
                .includes(String(action.payload).toLowerCase())
          )
        : state.recentConversationCopy;
      return {
        ...state,
        recentConversation,
      };
    }

    case 'SIGNAL_RECEIVED':
      return {
        ...state,
        isReceivedSignal: action.payload,
      };

    default:
      throw new Error(`Unknow action type: ${action.type}`);
  }
};

export const MessageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(messageReducer, {
    isOpenNewMessageSection: false,
    isReceivedSignal: false,
    recentConversation: [],
    recentConversationCopy: [],
    selectedUserId: null,
    userConversation: [],
    isSuccess: false,
    user: { name: '', email: '', phoneNumber: '' },
    businessesList: [],
  });

  return (
    <MessageDispatchContext.Provider value={dispatch}>
      <MessageStateContext.Provider value={state}>
        {children}
      </MessageStateContext.Provider>
    </MessageDispatchContext.Provider>
  );
};

export const useMessageState = () => useContext(MessageStateContext);
export const useMessageDispatch = () => useContext(MessageDispatchContext);
