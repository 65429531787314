import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import moment from 'moment';
import {
  Box,
  Title,
  Paragraph,
  Columns,
  Column,
  Icon,
} from '@safelyq/bulma-ui-library';
import { useData } from './hooks';

const Timeline = styled.div.attrs(() => ({}))`
  left: 0;
  width: 2px;
  height: 100%;
  background: #8080804f;
  & > small {
    position: absolute;
    transform: translate(20px, 25px);
  }
`;

const Dot = styled.div.attrs(({ }) => ({}))`
  position: absolute;
  width: 12px;
  height: 12px;
  border: 2px solid grey;
  background: #f5f5f5;
  border-radius: 100%;
  transform: translate(-5px, 30px);
`;

const MediaImage = styled.img.attrs(() => ({
  className: 'is-rounded',
}))`
  width: 47px !important;
  height: 47px !important;
`;

export const NotificationCard = ({ ...props }) => {
  const {
    key,
    content,
    correlationKey,
    notificationType,
    sourceId,
    isRead,
    subject,
    sentChannels,
    createdTime,
    business,
    refetch,
  } = props;

  let { id: businessId, name, timeZone, picture, } = business || {
    id: null,
    name: null,
    timeZone: null,

    picture: {
      path: '',
    },
  };

  const { onMarkRead } = useData(refetch);

  var now = moment();

  var nowInBizTZ = moment(now).tz(timeZone);

  var apptTime = moment.tz(createdTime, timeZone);

  const notifyTime = moment(apptTime).from(nowInBizTZ);

  const handleMarkAsRead = () => onMarkRead({ variables: { correlationKey } });

  return (
    <Columns multiline>
      <Column size='2' className='is-hidden-mobile'>
        <Timeline>
          <Dot />
          <small>
            <strong>{moment(createdTime).format('MMMM DD, yyyy')}</strong>
          </small>
        </Timeline>
      </Column>
      <Column size='10'>
        <Box
          bordered
          key={key}
          className='p-3'
          style={{ background: `${isRead ? 'white' : '#dbdbdb2b'}` }}
        >
          <Link
            to={
              notificationType == 'BusinessMessage'
                ? `/admin/messages/${businessId}`
                :
                notificationType === 'BusinessRating'
                  ?
                  `/admin/manage-reviews/${businessId}`
                  :
                  notificationType === 'Appointment'
                    ?
                    `/admin/appointments/${businessId}`
                    :
                    `/admin/appointments-log/${businessId}`
            }
            onClick={handleMarkAsRead}
          >
            <article className='media'>
              <figure className='image is-48x48 media-left'>
                <MediaImage src={picture?.path ? picture?.path : 'https://s3.amazonaws.com/speedsport-news/speedsport-news/wp-content/uploads/2018/07/01082232/image-not-found.png'} />
              </figure>
              <div className='media-content'>
                <div className='content'>
                  <div className='is-flex is-justify-content-space-between'>
                    {subject ? (
                      <Title size='6' className='mb-1'>
                        {String(subject).length <= 30
                          ? subject
                          : `${String(subject).substring(0, 30)}...`}
                      </Title>
                    ) : (
                      <Skeleton width={170} height={18} />
                    )}
                    <small className='has-text-grey-light'>{notifyTime}</small>
                  </div>
                  <Paragraph color='grey' className='mb-1'>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                    {/*String(content).substring(0, 100) + '...'*/}
                  </Paragraph>

                  <p className='is-right'>
                    <small>
                      {sentChannels.includes('OneSignal') && (
                        <Icon name='bell' />
                      )}
                      {sentChannels.includes('Email') && (
                        <Icon name='envelope' className='ml-2' />
                      )}
                      {sentChannels.includes('Sms') && (
                        <Icon name='mobile' className='ml-3' />
                      )}
                    </small>
                  </p>
                </div>
              </div>
            </article>
          </Link>
        </Box>
      </Column>
    </Columns>
  );
};
