import { bool } from 'prop-types';
import styled from 'styled-components';
import CardImage from './styles/CardImage';
import CardHeader from './styles/CardHeader';
import CardContent from './styles/CardContent';
import CardFooter from './styles/CardFooter';
import CardFooterItem from './styles/CardFooterItem';

const className = (strings, props) => {
  props.shadow = !props.shadow;
  const classNames = ['card']
    .concat(
      ['shadow']
        .map((name) => (props[name] ? `is-${name}less` : null))
        .filter((name) => !!name)
    )
    .join(' ');

  return classNames;
};

const Card = styled.div.attrs((props) => ({
  className: className`${props}`,
}))`
  border: ${({ bordered }) => bordered && '1px solid #80808033'};
`;

Card.Image = CardImage;
Card.Header = CardHeader;
Card.Content = CardContent;
Card.Footer = CardFooter;
Card.FooterItem = CardFooterItem;

Card.propTypes = {
  shadow: bool,
};

Card.defaultProps = {
  shadow: false,
};

export default Card;
