import { bool, func, oneOf, string } from 'prop-types';
import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = ['input']
    .concat(
      ['static', 'rounded']
        .map((name) => (props[name] ? `is-${name}` : null))
        .filter((className) => !!className)
    )
    .concat(
      ['color', 'size']
        .map((name) => (props[name] ? `is-${props[name]}` : null))
        .filter((className) => !!className)
    )
    .join(' ');

  return classNames;
};

const TextInput = styled.input.attrs(
  ({ placeholder, defaultValue, value, onChange, ...props }) => ({
    className: className`${props}`,
    type: 'text',
    placeholder,
    defaultValue,
    onChange,
  })
)``;

TextInput.propTypes = {
  color: oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  size: oneOf(['small', 'medium', 'large']),
  static: bool,
  rounded: bool,
  placeholder: string,
  defaultValue: string,
  value: string,
  onChange: func,
};

TextInput.defaultProps = {
  color: undefined,
  size: undefined,
  rounded: undefined,
  static: undefined,
  placeholder: undefined,
  defaultValue: undefined,
  value: undefined,
  onChange: undefined,
};

export default TextInput;
