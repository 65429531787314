import { useLazyQuery, useMutation } from "@apollo/client";
import { ALLOW_CUSTOMER_TO_PAY_FEE, BUSINESS_STRIPE_DETAILS, GET_STRIPE_CONNECT } from "./request";
import { toast } from "react-toastify";

export const useConnectStripeHook = () => {
    // get stripe account link
    const [onGetStripeLink, { error, loading: isLoading, data }] = useLazyQuery(
        GET_STRIPE_CONNECT,
        {
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true,
        }
    );

    if (error) {
        toast.error(error?.errorMessage)
    }

    // permission for the customer to pay fee
    const [onAllowCustomerToPayFee, { loading: isLoadingCustomerFee }] = useMutation(ALLOW_CUSTOMER_TO_PAY_FEE, {
        fetchPolicy: 'no-cache',
        onCompleted: ({
            updateBusinessStripeAccountDetail: { isSaved, errorMessage },
        }) => {
            if (isSaved) toast.success("Customer fee permission updated successfully");
            if (errorMessage) toast.error(errorMessage || 'Something went wrong');
        },
        onError: (error) => toast.error(error?.errorMessage || 'Something went wrong'),
    });

    // get business stripe details
    const [getBusinessDetails, { error: isStripError, loading: isLoadingStripe, data: stripeData, }] = useLazyQuery(
        BUSINESS_STRIPE_DETAILS,
        {
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true,
        }
    );

    return {
        onGetStripeLink,
        isLoading,
        data,
        isLoadingCustomerFee,
        onAllowCustomerToPayFee,
        isLoadingStripe,
        stripeData,
        getBusinessDetails
    };
}
