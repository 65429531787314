
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_BUSINESS_ATTATCHMENTS, UPLOAD_BUSINESS_ATTATCHMENTS, DELETE_BUSINESS_ATTATCHMENT } from './request';
import { toast as showToast } from 'react-toastify';
import { useState } from 'react';

export const useData = () => {
  const { businessId } = useParams();
  const id = parseInt(businessId);
  // const id = 1;

  const { loading: isDataLoading, error, data, refetch, } = useQuery(
    GET_BUSINESS_ATTATCHMENTS, {
    variables: { id },
    onError: () => { }
  }
  );
  return {
    isDataLoading,
    error,
    data,
    refetch,
  };
}

export const useSaveBusinessAttatchment = (refetch) => {
  const [hasDuplicatesState, setHasDuplicatesState] = useState();
  const [onUploadAttachment, { loading: isSaving }] = useMutation(
    UPLOAD_BUSINESS_ATTATCHMENTS,
    {
      onCompleted: async ({ uploadBusinessFile }) => {
        const { isSaved, errorMessage, hasDuplicates: duplicate } = uploadBusinessFile;
        setHasDuplicatesState(duplicate);
        if (isSaved) {
          showToast.success('File uploaded successfully')
          // Refetch files
          refetch();
        } else showToast.error(errorMessage || 'Failed to upload file');
      },
      onError: (error) => {
        showToast.error(error?.message || 'Something went wrong, Could not upload CSV');
      },
    }
  );
  return { isSaving, onUploadAttachment, hasDuplicatesState };
};

export const useDeleteBusinessAttatchment = () => {
  const [onDeleteAttachment, { loading: isDeleting }] = useMutation(
    DELETE_BUSINESS_ATTATCHMENT,
    {
      onCompleted: async ({ deleteBusinessFile }) => {
        const { isSaved, errorMessage } = deleteBusinessFile;
        if (isSaved) {

          showToast.success('File Deleted');
        } else showToast.error(errorMessage || 'Failed to delete file');
      },
      onError: () => {
        showToast.error('Something went wrong');
      },
    }
  );
  return { isDeleting, onDeleteAttachment };
};