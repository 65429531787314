import React, { useState, Fragment } from 'react';
import { AccordionDesktopHeader, AccordionMobileHeader } from './AccordionComponents/AccordionHeader';
import { AccordionPanel } from './AccordionComponents/AccordionPanel';
import { AccordionDesktopItem, AccordionMobileItem } from './AccordionComponents/AccordionItem';

export const Accordion = ({ key, isHighlighted, ...props }) => {
    const [isOpen, setOpen] = useState(false);

    const { appointments, startTimeOnly, noOfGuests, noOfSlides } = props;

    const totalCheckedInAppointments = appointments.filter(
        ({ status }) => status.toUpperCase() === 'CHECKED_IN'
    ).length;

    const headerProps = {
        time: startTimeOnly,
        totalGuests: noOfGuests,
        totalAppointments: appointments.length,
        totalCheckedInAppointments,
    };

    return (
        <div key={key} className={isHighlighted && 'accordion-border'}>
            <div className='is-hidden-mobile'>
                <AccordionDesktopHeader {...headerProps} />
            </div>
            <div className='is-hidden-desktop'>
                <AccordionMobileHeader {...headerProps} />
            </div>
            <AccordionPanel
                noOfSlides={noOfSlides}
                className={isOpen ? 'is-hidden' : ''}
            >
                {appointments.map(({ ...appointment }, index) => (
                    <Fragment>
                        <div className='is-hidden-mobile'>
                            <AccordionDesktopItem
                                key={`summary-appointment-${index}`}
                                {...appointment}
                            />
                        </div>
                        <div className='is-hidden-desktop'>
                            <AccordionMobileItem
                                key={`summary-appointment-${index}`}
                                {...appointment}
                            />
                        </div>
                        {index + 1 < appointments.length && <hr className='m-0' />}
                    </Fragment>
                ))}
            </AccordionPanel>
        </div>
    );
};
