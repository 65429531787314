import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { toast as showToast } from 'react-toastify';
import { GET_SUBSCRIPTION_PLANS, GET_DATA, CREATE_BUSINESS_SUBSCRIPTION, GET_SUBSCRIPTIONS, GET_STRIPE_PUBLISHABLE_KEY } from './requests';

const INITIAL_DATA = {
    getBusinessById: {
        campaigns: [],
    },
}

export const useCreateBusinessSubscription = ({ onComplete }) => {
    const [subscriptionData, setSubscriptionData] = useState({});
    const [onSaveSubscription, { loading: isSaving, error: onError, }] = useMutation(
        CREATE_BUSINESS_SUBSCRIPTION,
        {
            onCompleted: async ({ createBusinessSubscription }) => {
                const { isSaved, errorMessage } = createBusinessSubscription;
                if (isSaved) {
                    showToast.success('Subscription Saved');
                    onComplete()
                } else showToast.error(errorMessage || 'Failed to Save Subscription');
            },
            onError: () => {
                showToast.error(onError.message);
            },
        }
    );
    return { isSaving, onSaveSubscription, subscriptionData, setSubscriptionData };
};

export const useGetPrice = () => {
    const { loading, data: subscriptionPlan } = useQuery(GET_SUBSCRIPTION_PLANS);
    return { loading, subscriptionPlan };
};

export const useGetData = (id) => {
    const {
        loading: isDataLoading,
        error,
        data = INITIAL_DATA,
    } = useQuery(GET_DATA, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: { id },
    });
    if (error) {
        showToast.error(error.message);
    }
    else {
        return { isDataLoading, data };
    }
};

export const useGetSubscription = (id) => {
    const {
        error,
        loading: isLoading,
        data: subscriptionData,
        refetch
    } = useQuery(GET_SUBSCRIPTIONS, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: { id }
    });
    if (error) {
        showToast.error(error.message);
    }
    else {
        return {
            isLoading,
            subscriptionData,
            refetch
        };
    }
};

export const useStripePayment = () => {
    const {
        error,
        loading: isLoading,
        data: stripData,
    } = useQuery(GET_STRIPE_PUBLISHABLE_KEY, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
    });

    const { getStripePublishableKey } = stripData || {};
    const { isSuccess, publishableKey } = getStripePublishableKey || {};
    if (error) {
        showToast.error(error.message);
    }
    else {
        return {
            isLoading,
            isSuccess,
            publishableKey,
        };
    }
};
