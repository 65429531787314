import { useRef } from "react";
import { useQuery, useMutation } from '@apollo/client';
import { INITIAL_DATA } from './constants';
import Queries from 'components/Common/Queries';
import moment from 'moment-timezone';
import { useDispatch } from "react-redux";
import { addAppointments } from "Redux/AuthSlice/AuthSlice";
export const useData = () => {
  const { loading: isLoading, error, data = INITIAL_DATA, refetch } = useQuery(Queries.IS_PROFILE_COMPLETE);

  const { data: userData = { getUser: { user: { conversationsSummary: {} } } }, } = useQuery(Queries.GET_USER);

  const [onMarkAsViewed] = useMutation(Queries.MARK_AS_VIEWED);

  const { isProfileComplete: { unviewedCount: totalNotifications }, } = data;

  const { getUser: { user }, } = userData;

  return {
    isLoading,
    error,
    totalNotifications,
    unreadCount: user?.conversationsSummary?.unreadCount,
    authUser: {
      id: user?.id,
      firstName: user?.firstName,
      lastName: user?.lastName,
      name: user?.name,
      email: user?.email,
    },
    onMarkAsViewed,
    refetch
  };
};


export const useGetAllData = () => {
  const { loading, error, data, refetch } = useQuery(Queries.GET_USER_IS_PROFILE_COMPLETE);
  return { loading, error, data, refetch };
};

export const useMarkasViewed = () => {
  const [onMarkAsViewed] = useMutation(Queries.MARK_AS_VIEWED);;
  return { onMarkAsViewed };
};


export const useGetAllAppointments = () => {
  const dispatch = useDispatch()
  const dateFormatString = 'YYYY-MM-DD';
  const todayDate = useRef(moment(new Date()).format(dateFormatString));
  const { loading, refetch, error, data = { getCurrentUserAppointments: [] } } = useQuery(Queries.GET_USER_APPOINTMENTS, {
    variables: { businessId: 0, status: 'ALL', startDate: todayDate.current },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  dispatch(addAppointments(data.getCurrentUserAppointments))
};






