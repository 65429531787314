import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { Container, Switch, Icon } from '@safelyq/bulma-ui-library';
import { CreateProfile } from '../CreateProfile';
import { HoursOperation } from '../HoursOperation';
import { TrafficSetup } from '../TrafficSetup';
import { CheckInSetup } from '../CheckInSetup';
import { OfferedServices } from '../OfferedServices';
import { AppointmentConfirmationSetup } from '../AppointmentConfirmationSetup';
import { ServiceHolidaySchedule } from '../ServiceHolidaySchedule';
import { blankServiceProvider } from './constants';
import { BUSINESS_PROFILE_MESSAGES } from '../../common/constants';
import { toast as showToast } from 'react-toastify';
import { Spin } from 'antd';

export const ServiceProvidersSetup = ({ ...props }) => {
  const {
    business,
    countries,
    isLoading,
    isCreateServiceProvider,
    setCreateServiceProvider,
    refetch,
    onSaveBusiness,
    onUpdateServiceProviderStatus,
    businessHoursValidator,
    udpateBusinessHoursStatus,
    handleCreateServiceProvider,
    setAllowToast,
    businessActiveStatus,
    setBusinessActiveStatus,
  } = props || {};

  const { id: businessId } = useParams();

  const HandleMinusService = () => {
    handleCreateServiceProvider()
  }

  const handleSubmit = (data, step, id, taostMessages) => {
    onSaveBusiness({
      variables: {
        businessInput: {
          id,
          parentId: parseInt(businessId),
          organizationId: business?.organization?.id,
          ...data,
        },
      },
    })
      .then((result) => {
        var { data, error } = result;
        const { saveBusiness } = data || {};

        if (saveBusiness.isSaved) {
          showToast.success(taostMessages?.success ?? BUSINESS_PROFILE_MESSAGES.Success);
          // refetch({ id: parseInt(businessId) })
        }
        else {
          showToast.error(saveBusiness?.errorMessage ?? taostMessages?.error ?? BUSINESS_PROFILE_MESSAGES.Error);
        }
        setCreateServiceProvider();

      })
      .catch((error) => showToast.error(error));
  };

  const handleStatusChange = (serviceProviderId, isActive) => {
    onUpdateServiceProviderStatus({
      variables: {
        serviceProviderId,
        businessId,
        isActive,
      },
    });

    // allow toast
    setAllowToast(true);

    // set the loading on of the service that is changed
    const newArray = businessActiveStatus?.map(business => {
      if (business.id === serviceProviderId) {
        return { ...business, isLoading: true }
      }
      else {
        return business
      }
    })
    setBusinessActiveStatus(newArray)
  };

  return (
    <Container className='ml-4'>
      {isCreateServiceProvider && (
        <Accordion allowZeroExpanded={true}>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <span>New Service Provider</span>

                <Icon name='minus-square' onClick={HandleMinusService} style={{ fontSize: "24px" }} className="is-pulled-right has-text-info" />
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Accordion allowZeroExpanded={true}>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>Profile</AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <CreateProfile
                      tab='SERVICE_PROVIDER'
                      data={{ ...blankServiceProvider, countries }}
                      isLoading={isLoading}
                      onSubmit={handleSubmit}
                    />
                  </AccordionItemPanel>
                </AccordionItem>

              </Accordion>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      )}
      <Accordion allowZeroExpanded={true}>
        {business?.children?.map(
          ({ id, name, isActive, businessPermissions, businessHolidays, businessHours }, index) => {
            const hasPermission =
              businessPermissions?.isAdmin || businessPermissions?.isAssociate;
            const hasValidBusinessHours =
              businessHoursValidator[`${id}`] ?? false;


            return (
              hasPermission && (
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton className='accordion__button is-flex is-align-items-center'>
                      <div className='is-flex is-justify-content-space-between w-100'>
                        <span>{name}</span>

                        <div className='is-flex'>
                          {/* Set loading if the status changes */}
                          <Spin spinning={businessActiveStatus?.some(business => business.id === id && business.isLoading)}>
                            <Switch
                              value={isActive}
                              onValueChange={(checked) =>
                                handleStatusChange(id, checked)
                              }
                            />
                          </Spin>
                        </div>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <Accordion allowZeroExpanded={true}>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>Profile</AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <CreateProfile
                            tab='SERVICE_PROVIDER'
                            data={{ ...business?.children[index], countries }}
                            isLoading={isLoading}
                            onSubmit={handleSubmit}
                          />
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton style={{ background: hasValidBusinessHours ? '' : '#ffecf0', }}    >
                            Hours Of Operation
                            {hasValidBusinessHours ? ('') : (<span className='ml-3 has-text-danger'>  <small>Business Hours is required</small>   </span>)}
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <HoursOperation
                            tab='SERVICE_PROVIDER'
                            data={businessHours}
                            isLoading={isLoading}
                            udpateBusinessHoursStatus={udpateBusinessHoursStatus}
                            onSubmit={handleSubmit}
                            serviceId={id}
                          />
                        </AccordionItemPanel>
                      </AccordionItem>




                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>  Holiday Schedule  </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <ServiceHolidaySchedule refetch={refetch} serviceId={id} businessId={parseInt(businessId)} businessHolidays={business.businessHolidays} serviceHolidays={businessHolidays} onSubmit={handleSubmit} isLoading={isLoading} />
                        </AccordionItemPanel>
                      </AccordionItem>




                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Offered Services
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <OfferedServices
                            tab='SERVICE_PROVIDER'
                            data={{ ...business }}
                            serviceProviderId={id}
                            isLoading={isLoading}
                            onSubmit={handleSubmit}
                          />
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Customer Traffic Setup
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <TrafficSetup
                            tab='SERVICE_PROVIDER'
                            data={{ ...business?.children[index] }}
                            isLoading={isLoading}
                            onSubmit={handleSubmit}
                          />
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Appointment Confirmation Setup
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <AppointmentConfirmationSetup
                            tab='SERVICE_PROVIDER'
                            data={{ ...business?.children[index] }}
                            isLoading={isLoading}
                            onSubmit={handleSubmit}
                          />
                        </AccordionItemPanel>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            Check-in Setup
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <CheckInSetup
                            tab='SERVICE_PROVIDER'
                            data={{ ...business?.children[index] }}
                            isLoading={isLoading}
                            onSubmit={handleSubmit}
                          />
                        </AccordionItemPanel>
                      </AccordionItem>
                    </Accordion>
                  </AccordionItemPanel>
                </AccordionItem>
              )
            );
          }
        )}
      </Accordion>
    </Container>
  );
};
