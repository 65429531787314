import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { GET_BUSINESS_CONSOLE_IMAGES, SAVE_CONSOLE_IMAGES, REMOVE_CONSOLE_IMAGES } from './requests';
import { toast } from 'react-toastify';

export const useConsoleImages = (consoleApplicationAllowed) => {
    const { id } = useParams();
    const { loading, error, data, refetch } = useQuery(GET_BUSINESS_CONSOLE_IMAGES, { variables: { id }, skip: !consoleApplicationAllowed, fetchPolicy: 'no-cache', });

    // Delete Image
    const [onConsoleImageDelete, { loading: isDeleteLoading }] = useMutation(REMOVE_CONSOLE_IMAGES, {
        onCompleted: ({ deleteBusinessConsoleImage: { error, isSuccess } }) => {
            if (isSuccess) {
                refetch();
                toast.success('Image deleted successfully');
            } else toast.error(error || 'Image deletion failed.');
        },
        onError: () => toast.error('Image deletion failed.'),
    });

    return { data, loading, error, refetch, onConsoleImageDelete, isDeleteLoading }
};

export const useSaveConsoleImages = () => {
    const [onSaveImages, { data: savedData, loading: savedLoading, error: savedError }] = useMutation(SAVE_CONSOLE_IMAGES, {
        onCompleted: (data) => {
            if (data?.uploadBusinessConsoleImages?.isSuccess) {
                toast.success("Console Details Saved Successfull");
            }
            else {
                toast.error(data?.uploadBusinessConsoleImages?.error || "Could not save console details");
            }
        },
        onError: (error) => {
            // console.log("error:", error);
            // if (error.networkError && error.networkError.statusCode === 413) {
            // toast.error("Image size is too large. Please upload a smaller image under 2mb.");
            // } else {
            toast.error(error.message || "Could not save console details");
            // }
        }
    });
    return { onSaveImages, savedData, savedLoading, savedError }
}