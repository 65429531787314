import { gql } from '@apollo/client';

export const DELETE_BUSINESS_HOLIDAY = gql`
  mutation deleteBusinessHoliday($businessId: Int!  $date: Date! )
   {
        deleteBusinessHoliday(businessId: $businessId  date: $date   ) 
        {
            errorMessage
            isSaved
        }
    }
`;

// export const Save_BUSINESS_HOLIDAY = gql`
//   mutation saveBusinessHoliday($businessHolidays:Array $id: Int!  $organizationId:Int! $isCancelAppointment: Boolean)
//    {
//     saveBusinessHoliday(businessHolidays:$businessHolidays id: $id  organizationId: $organizationId  isCancelAppointment: $isCancelAppointment ) 
//         {
//             errorMessage
//             isSaved
//         }
//     }
// `

export const Save_BUSINESS_HOLIDAY = gql`
  mutation saveBusinessHoliday($businessInput: BusinessInput!) {
    saveBusinessHoliday(businessInput: $businessInput) {
      errorMessage
      isAppointment
      isSaved
    }
  }
`;
