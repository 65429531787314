import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Icon } from '@safelyq/bulma-ui-library';

export const AdminTabList = () => {
  const { state } = useLocation() || {};

  const { currentMenu } = state || { currentMenu: 0 };

  return (
    <div className='tabs is-small is-hidden-tablet'>
      <ul>
        <li>
          <Link
            to={{
              pathname: '/admin/account/setting',
              state: { currentMenu: 0 },
            }}
          >
            <Icon name='user' />
            <span>Profile</span>
          </Link>
        </li>
        <li>
          <Link
            to={{
              pathname: '/admin/account/notifications',
              state: { currentMenu: 1 },
            }}
          >
            <Icon name='bell' /> <span> Business Notifications</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};
