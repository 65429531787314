import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import Image from './styles/Image';
import Figure from './styles/Figure';

const className = (strings, props) => {
  const classNames = ['card-image'].join(' ');

  return classNames;
};

const StyledCardImage = styled.div.attrs((props) => ({
  className: className`${props}`,
}))``;

const CardImage = ({ src, alt, size, ...props }) => {
  return (
    <StyledCardImage {...props}>
      <Figure size={size}>
        <Image src={src} alt={alt} />
      </Figure>
    </StyledCardImage>
  );
};

CardImage.propTypes = {
  src: string,
  size: string,
};

CardImage.defaultProps = {
  size: undefined,
  src: undefined,
};

export default CardImage;
