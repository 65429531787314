import { gql } from '@apollo/client';

export const ADD_COMMENT = gql`
  mutation AddComment($appointmentId: Int!, $commentText: String!, $messageDirection: MessageDirection!) {
  addAppointmentComment(appointmentCommentInput: {appointmentId: $appointmentId, commentText: $commentText, messageDirection: $messageDirection}) {
    isSaved
    errorMessage
    appointmentComment {
      id
      comment {
        id
        commentText
        createdTime
        modifiedTime
        user {
          id
          firstName
          lastName
          email
        }
      }
      messageDirection
    }
  }
}
`;
