import styled from 'styled-components';
import MenuLabel from './styles/MenuLabel';
import MenuList from './styles/MenuList';

const className = () => {
  const classNames = ['menu'].join(' ');

  return classNames;
};

const Menu = styled.aside.attrs((props) => ({
  className: className`${props}`,
}))``;

Menu.Label = MenuLabel;
Menu.List = MenuList;

Menu.propTypes = {};

Menu.defaultProps = {};

export default Menu;
