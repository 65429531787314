
import moment from 'moment';
export const parsedHolidays = (holidays) => {
    if(holidays){
        const updatedDays = holidays.map((day) => {
            let obj = {}
            obj.isOpen = true
            obj.id = day.id
            obj.date = moment(day.date).format('YYYY-MM-DD')
            obj.specialHolidayId1 = undefined
            obj.specialHolidayId2 = undefined
            obj.openingTimeError = ""
            obj.closingTimeError = ""
            obj.hasBreakError = ""
            obj.breakStartTimeError = ""
            obj.breakDurationError = ""
            obj.hasBreak = false
            obj.breakStartTime = ""
            obj.breakDuration = ""
            obj.closingTime = ""
            obj.closingTime = ""
            if (day.specialHours.length > 0) {
                if ([day.specialHours[0].openingTime, day.specialHours[0].closingTime, day.specialHours[1].openingTime, day.specialHours[1].closingTime].includes("0001-01-01T00:00:00")) {
                    obj.isOpen = false
                }
                else {
                    obj.openingTime = moment(day.specialHours[0].openingTime).format('HH:mm')
                    obj.isOpen = true
                    if (day.specialHours[1].openingTime === day.specialHours[1].closingTime) {
                        // it means business is open and there is no break 
                        obj.closingTime = moment(day.specialHours[0].closingTime).format('HH:mm')
                        obj.businessClosingTime = moment(day.specialHours[0].closingTime).format('HH:mm')
                        obj.hasBreak = false
                        obj.breakStartTime = ""
                        obj.breakDuration = ""
                    }
                    else {
                        // it means business is open and and there is some brek
                        obj.closingTime = moment(day.specialHours[1].closingTime).format('HH:mm')
                        obj.hasBreak = true
                        obj.breakStartTime = moment(day.specialHours[0].closingTime).format('HH:mm')
                        const timeInMilliSec = moment(day.specialHours[1].openingTime).diff(moment(day.specialHours[0].closingTime))
                        const timInSec = moment.duration(timeInMilliSec).asMinutes()
                        obj.breakDuration = timInSec
                    }
                }
            }
            else {
                obj.openingTime = ""
                obj.closingTime = ""
                obj.isOpen = false
            }




            return obj
        })
        return updatedDays
    }else {
        return []
    }
}


export const getFinalData = (workingslots) => {

    let finalSlots = [];

    let specialHours = [];
    const dateFormat = 'YYYY-MM-DD HH:mm';

    const blankTime = moment('0001-01-01 00:00').format(dateFormat);

    workingslots.map(({ id, date, specialHolidayId1, specialHolidayId2, hasBreak, openingTime, closingTime, breakDuration, breakStartTime, isOpen }) => {

        let firstSlot = {};

        let secondSlot = {};

        if (date) {
            openingTime = date + ' ' + openingTime;
            closingTime = date + ' ' + closingTime;
        }

        openingTime = moment(openingTime).format(dateFormat);
        closingTime = moment(closingTime).format(dateFormat);
        breakStartTime = moment(`0001-01-01 ${breakStartTime}`).format(dateFormat);

        firstSlot = {
            id: typeof specialHolidayId1 == 'string' || !specialHolidayId1 ? 0 : specialHolidayId1,
            openingTime,
            closingTime: breakStartTime,
            date: date,
        };

        secondSlot = {
            id: typeof specialHolidayId2 == 'string' || !specialHolidayId2 ? 0 : specialHolidayId2,
            openingTime: moment(breakStartTime, dateFormat).add(breakDuration, 'minutes').format(dateFormat),
            closingTime,
            date: date,
        };

        if (!hasBreak) {
            breakDuration = 0;
            breakStartTime = blankTime;
            firstSlot = { ...firstSlot, closingTime };
            secondSlot = { ...secondSlot, openingTime: closingTime, closingTime };
        }

        if (isOpen === false) {
            specialHours = [];
        }
        else {
            specialHours = [{ ...firstSlot }, { ...secondSlot }];
        }

        if (date) {
            finalSlots.push({ id: typeof id == 'string' || !id ? 0 : id, name: ' ', date: date, specialHours, });
        }
    });

    return finalSlots;
};


export const handleBusinessHolidayRemove = (Ids, holidays) => {
    const businessHolidays = holidays.filter((item) => {
        return !Ids.some((el) => {
            return el.id === item.id;
        });
    });
    return businessHolidays
};

export const onClosingTimechange = () => {

}