import React, { useEffect } from 'react';
import { Section, Container, Title, Icon } from '@safelyq/bulma-ui-library';
import { Loader } from '@safelyq/base-feature';
import { ReviewList } from '../../components';
import { useData, useReviewRead } from './hooks';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

export const ManageReviews = () => {
  const dispatch = useDispatch();

  const { businessId } = useParams();
  const { data: business, isLoading, refetch } = useData();
  const { onReviewRead } = useReviewRead(dispatch);
  const reviewCount = useSelector(state => state?.business?.reviewCount);

  useEffect(() => {
    const timer = setTimeout(() => {
      reviewCount && onReviewRead({
        variables: { businessId }
      });
    }, 5000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timer);
  }, [businessId, onReviewRead]);

  if (isLoading) return <Loader isFullScreen={true} />;

  const { userRatings } = business || {};

  return (
    <Section>
      <Container>
        <Title size='5'>
          <Icon name='star' className='mr-3' />
          Reviews and ratings
        </Title>
        <ReviewList userRatings={userRatings} refetch={refetch} />
      </Container>
    </Section>
  );
};
