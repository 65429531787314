import React from 'react';
import styled from 'styled-components';
import { Column, Columns } from '@safelyq/bulma-ui-library';
import { MessageProvider, useMessageState } from './context';
import {
  RecentConversation,
  MessageSection,
  NewMessageSection,
} from '../../components';

const StyledColumn = styled(Column)`
  border-left: 1px solid rgba(0, 0, 0, 0.1);
`;

const ExitScreenIcon = styled(Column)`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  border-radius: 100%;
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  &:hover {
    cursor: pointer;
  }
`;

export const ManageAdminMessages = () => {
  return (
    <MessageProvider>
      <Wrapper />
    </MessageProvider>
  );
};

const Wrapper = () => {
  const { isOpenNewMessageSection, selectedUserId } = useMessageState();

  return (
    <Columns multiline gap='0'>
      <StyledColumn
        size='4'
        className={`${selectedUserId || isOpenNewMessageSection ? 'is-hidden-mobile' : ''
          }`}
      >
        <RecentConversation />
      </StyledColumn>
      <StyledColumn
        size='8'
        className={`${!selectedUserId && !isOpenNewMessageSection ? 'is-hidden-mobile' : ''
          }`}
      >
        {isOpenNewMessageSection ? <NewMessageSection /> : <MessageSection />}
      </StyledColumn>
    </Columns>
  );
};
