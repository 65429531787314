import { gql } from '@apollo/client';

export const GET_BUSINESS_FORMS = gql`
  query GetBusinessForms ($id: Int) {
    getBusinessById(id: $id ) {
       businessGoogleForms {
        editFormUrl
        viewFormUrl
        formId
        formTitle
        responseCount
        id
        googleFormPermissions{
          email
          id
        }
    }
      
  }
}
`;

export const CREATE_BUSINESS_FORM = gql`
  mutation CreateGoogleForm($createFormInput: CreateFormInput!) {
  createGoogleForm(createFormInput: $createFormInput) {
    isSaved
    errorMessage
  }
}
`;

export const DELETE_BUSINESS_FORM = gql`
  mutation DeleteGoogleForm($businessGoogleFormId: Int!) {
  deleteGoogleForm(businessGoogleFormId: $businessGoogleFormId,) {
    isSaved
    errorMessage
  }
}
`;

export const CREATE_GOOGLE_FORM_PERMISSION = gql`
  mutation CreateGoogleFormPermission($businessGoogleFormId: Int!,$email:String!) {
  createGoogleFormPermission(businessGoogleFormId: $businessGoogleFormId,email:$email) {
    isSaved
    errorMessage
  }
}
`;

export const DELETE_GOOGLE_FORM_PERMISSION = gql`
  mutation DeleteGoogleFormPermission($googleFormPermissionId: Int!) {
  deleteGoogleFormPermission(googleFormPermissionId: $googleFormPermissionId,) {
    isSaved
    errorMessage
  }
}
`;

export const GET_GOOGLE_FORM_QUESTIONS = gql`
  query GetGoogleFormQuestions {
    getGoogleFormQuestions {
      formQuestionType
      isSelected
      name
    }
  }
`;

export const GET_BUSINESS_BY_ID = gql`
  query GetBusinessById($id: Int!) {
  getBusinessById(id: $id) {
    businessSubscription {
      subscriptionPlan {
        features {
          googleFormAllowed
        }
      }
    }
  }
}
`;