import styled from 'styled-components';

const className = () => {
  const classNames = ['card-footer'].join(' ');

  return classNames;
};

const CardFooter = styled.footer.attrs((props) => ({
  className: className`${props}`,
}))``;

CardFooter.propTypes = {};

CardFooter.defaultProps = {};

export default CardFooter;
