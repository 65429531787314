import React, { Fragment, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import {
  Title,
  Button,
  Icon,
  Columns,
  Column,
  Field,
  Control,
  DateInput,
  TextInput,
  Block,
} from '@safelyq/bulma-ui-library';
import {
  ConfirmationModal,
  EditAppointmentModal,
  TableView,
  TileView,
} from './components';
import { NoAppointmentFound } from '../NotFoundSection';
import { useData } from './hooks';
import { STATUS } from 'constants/index';
import { getStatusColor, getStatusName } from 'methods/getStatuses';

const StyledSearchBar = styled(TextInput).attrs(() => ({}))`
  background: rgba(0, 0, 0, 0.02);
`;

const StyledDateInputBar = styled(DateInput).attrs(() => ({}))`
  background: rgba(0, 0, 0, 0.02);
`;

const currentDate = moment().format('YYYY-MM-DD');

export const AppointmentsList = ({ ...props }) => {
  const { businessId } = useParams();
  const { isAnySubLevel } = props;

  const [appointments, setAppointments] = useState([]);


  const [filteredAppointments, setFilteredAppointments] = useState([]);

  const [editModal, setEditModal] = useState({ isOpen: false, actionId: null });

  const [filterDate, setFilterDate] = useState({
    startDate: currentDate,
    endDate: currentDate,
  });

  const [filterStatuses, setFilterStatuses] = useState([]);

  const [modal, setModal] = useState({
    isOpen: false,
    actionId: null,
  });

  useEffect(() => onFilterByDate(filterDate), [filterDate]);

  useEffect(() => {
    const dumpAppointments = [...appointments];

    const dumpFilteredAppointments = [];

    dumpAppointments.map((appointment) => {
      if (
        filterStatuses.find(
          (status) =>
            String(getStatusName(status)).toUpperCase() ==
            String(getStatusName(appointment?.status)).toUpperCase()
        )
      ) {
        dumpFilteredAppointments.push(appointment);
      }
    });

    setFilteredAppointments([...dumpFilteredAppointments]);
  }, [filterStatuses]);

  const handleEditModalOpen = (id) =>
    setEditModal({ isOpen: true, actionId: id });

  const handleEditModalClose = () =>
    setEditModal({ isOpen: false, actionId: null });

  const handleConfirmModalOpen = (id, status) =>
    setModal({ isOpen: true, actionId: id, status });

  const handleConfirmModalClose = () =>
    setModal({ ...modal, isOpen: false, status: '' });

  const handleAppointmentStatusChange = () =>
    onChangeAppointmentStatus(modal.status, modal.actionId);

  const handleDateChange = ({ target: { name, value } }) =>
    setFilterDate({ ...filterDate, [name]: value });

  const handleTodayAppointments = () =>
    setFilterDate({
      ...filterDate,
      startDate: currentDate,
      endDate: currentDate,
    });

  const handleTextChange = ({ target: { value } }) => onFilterByText(value);

  const handleFilterCheckboxChange = (status, checked) => {
    if (checked) setFilterStatuses([...filterStatuses, status]);
    else
      setFilterStatuses(
        filterStatuses.filter(
          (filterStatus) =>
            String(filterStatus).toUpperCase() !== String(status).toUpperCase()
        )
      );
  };

  const {
    isLoading,
    isAssociate,
    isCancelLoading,
    onChangeAppointmentStatus,
    onFilterByDate,
    onFilterByText,
  } = useData({
    onCloseModal: handleConfirmModalClose,
    onPopulateData: setAppointments,
  });

  const isAppointmentsFound = appointments.length > 0;

  const passingProps = {
    appointments:
      filterStatuses.length <= 0 ? appointments : filteredAppointments,
    handleEditModalOpen,
    isCancelLoading,
    handleConfirmModalOpen,
  };


  // if (isLoading) return <Loader isFullScreen />;

  return (
    <Fragment>
      <Block className='is-flex is-align-items-center'>
        <Title size='5' className='mb-0'>   <Icon name='calendar' className='mr-3' />  List of appointments </Title>
        <Button size='small' color='info' className='ml-3' onClick={handleTodayAppointments}  >  Today  </Button>
      </Block>
      <Columns multiline>
        <Column size='3'>
          <Field>
            <Control>
              <StyledDateInputBar name='startDate' value={filterDate?.startDate} onChange={handleDateChange} />
              <small className='has-text-grey'>   <i>Choose start date</i>  </small>
            </Control>
          </Field>
        </Column>
        <Column size='3'>
          <Field>
            <Control>
              <StyledDateInputBar name='endDate' value={filterDate?.endDate} onChange={handleDateChange} />
              <small className='has-text-grey'> <i>Choose end date</i>   </small>
            </Control>
          </Field>
        </Column>
        <Column size='3'>
          <Field>
            <Control>
              <StyledSearchBar onChange={handleTextChange} placeholder={'Search by name, email and time'} />
              <Icon name='search' position='right' />
            </Control>
          </Field>
        </Column>
        <Column size='3'>
          <div className='is-flex is-align-items-center'>
            <div className='dropdown is-hoverable mr-5'>
              <div className='dropdown-trigger'>
                <Button className='button-icon' aria-haspopup='true' aria-controls='actions-dropdown'  >  <Icon name='filter' color='info' scale='lg' />    </Button>
              </div>
              <div className='dropdown-menu' id='actions-dropdown' role='menu'>
                <div className='dropdown-content'>
                  {Object.keys(STATUS).map((keyName, index) => (
                    <label className='checkbox dropdown-item'>
                      <div className='control'>
                        <input type='checkbox' onChange={({ target: { checked } }) => handleFilterCheckboxChange(getStatusName(STATUS[keyName]), checked)} />
                        <span key={`status-${index}`}
                          className={`tag is-light ml-2`}
                          style={{ background: `rgba(${getStatusColor(STATUS[keyName])}, 0.1)`, color: `rgb(${getStatusColor(STATUS[keyName])})`, }}
                        >
                          {getStatusName(STATUS[keyName])}
                        </span>
                      </div>
                    </label>
                  ))}
                </div>
              </div>
            </div>
            <Field>
              <Control>
                <Link to={`/admin/create-appointment/${businessId}`}>
                  <Button color='info'>Create Appointment</Button>
                </Link>
              </Control>
            </Field>
          </div>
        </Column>
      </Columns>
      {!isAppointmentsFound ? (
        <NoAppointmentFound />
      ) : (
        <>
          <div className='is-hidden-mobile'>
            <TableView isAnySubLevel={isAnySubLevel} {...passingProps} isAssociate={isAssociate} />
          </div>
          <div className='is-hidden-tablet'>
            <TileView isAnySubLevel={isAnySubLevel} {...passingProps} isAssociate={isAssociate} />
          </div>
        </>
      )}
      <ConfirmationModal
        isOpen={modal.isOpen}
        action={modal.status}
        onCancel={handleAppointmentStatusChange}
        onClose={handleConfirmModalClose}
      />
      {editModal.actionId && (
        <EditAppointmentModal
          id={editModal.actionId}
          isOpen={editModal.isOpen}
          onClose={handleEditModalClose}
        />
      )}
    </Fragment>
  );
};
