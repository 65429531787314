import { toast as showToast } from 'react-toastify';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_BUSINESS_BY_ID } from './requests';
import { useHistory } from "react-router-dom";
import { useEffect } from 'react';
// import { useParams } from 'react-router-dom';

export const useData = (id, stateId, businessName) => {
  let history = useHistory();
  const [
    onLoadBusiness,
    { loading: isLoading, error, data = { getBusinessById: {} } }
  ] = useLazyQuery(GET_BUSINESS_BY_ID, {
    fetchPolicy: 'no-cache',
  });


  const { getBusinessById: business } = data;

  useEffect(() => {
    if (error || business === null) {
      showToast.error('There was a problem. Data could not be loaded.');
      history.push("/admin");
    }
  }, [error, business, history]);
  return { isLoading, error, business, onLoadBusiness };

}