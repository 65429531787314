import React, { useState, useEffect } from 'react';
import {
    Box,
    Columns,
    Column,
    Title,
    Button,
    Card,
} from '@safelyq/bulma-ui-library';
import { useQuery } from '@apollo/client';
import { GET_PRICING_PAGE_INFO } from './requests'
import { Loader } from '@safelyq/base-feature';
import { useHistory, useLocation } from 'react-router-dom';
import { toast as showToast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components'
import { setActiveItem } from 'Redux/MiscSlice/Misc';

const Pricing = ({ handleGetStarted, subscription }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        // close the drawer
        dispatch(setActiveItem(null));
    }, [])

    const history = useHistory();
    let location = useLocation();
    const state = useSelector(state => state);
    // const businessCategoryName = state?.business?.businessCategory?.name;
    const businessCategory = state?.business?.businessCategory;

    const [subscriptionPlans, setSubscriptionPlans] = useState([''])
    const [tierHeading, setTierHeading] = useState('')

    const { loading: isLoading, data: pricingInfo, error } = useQuery(GET_PRICING_PAGE_INFO);

    if (error) { showToast.error(error.message); };

    useEffect(() => {
        setSubscriptionPlans(pricingInfo?.getPricingPageInfo?.pricing?.planTiers)
        setTierHeading(pricingInfo?.getPricingPageInfo?.pricing?.title)

    }, [pricingInfo, setSubscriptionPlans, setTierHeading]);


    const handleButtonClick = (monthlyPrice, yearlyPrice, tierHeading, planTierType) => {
        if (subscription) {
            handleGetStarted(monthlyPrice, yearlyPrice, tierHeading, planTierType);
        } else {
            history.push(
                `/admin/businesses`
            );
        }
    };
    const endDateTime = subscription?.endDateTime;
    let formattedDate = null;

    if (endDateTime) {
        const endDate = new Date(endDateTime);
        formattedDate = endDate.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
    }

    // is pathname is not via admin - then enable button
    // If category is restaurant disable button
    let disableButton = location.pathname !== "/for-business"
        ?
        // businessCategoryName === "restaurant" ? false : true
        businessCategory?.features?.googleReserveAllowed ? false : true
        :
        false;

    if (isLoading) return <Loader isFullScreen={true} />;
    return (
        <React.Fragment>
            <div className='bscontainer'>
                <Box className='p-4'>
                    <Column size='full' className='mb-5 p-0'>
                        <Title size='3'>
                            <center>{tierHeading}</center>
                        </Title>
                        {subscription && subscription?.length !== 0 &&
                            <ColumnBox size='6' className='is-flex is-justify-content-center'>
                                <ul className='ml-0 pl-0' style={{ listStyle: 'none' }}>
                                    <li>
                                        <div className='is-flex'>
                                            <span className='mr-2' role="img" aria-label="checkmark">✓</span>
                                            <div> You are currently subscribed into: {subscription?.subscriptionPlan?.name}</div>
                                        </div>
                                    </li>
                                    {subscription?.subscriptionPlan?.subscriptionTierType !== "FREE" &&
                                        <li>
                                            <div className='is-flex'>
                                                <span className='mr-2' role="img" aria-label="checkmark">✓</span>
                                                <div>Renews On: {formattedDate}</div>
                                            </div>
                                        </li>
                                    }
                                    {
                                        subscription?.subscriptionPlan?.subscriptionTierType !== "FREE" && <li>
                                            <div className='is-flex'>
                                                <span className='mr-2' role="img" aria-label="checkmark">✓</span>
                                                <p>Billing Type: </p>
                                                <p className='ml-2'>{subscription?.subscriptionPlan?.durationType.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}</p>
                                            </div>
                                        </li>
                                    }

                                </ul>
                            </ColumnBox>
                        }
                    </Column>

                    <Columns multiline className='mb-6 pb-3'>
                        {subscriptionPlans?.map((tier) => (
                            <Column size='3' className='p-2'>
                                <Card className='p-4 tier-card is-flex is-flex-direction-column is-justify-content-space-between' style={{
                                    border: '1px solid rgb(178 193 204)',
                                    borderRadius: '10px',
                                    height: '100%',
                                    backgroundColor: tier?.planTierType === subscription?.subscriptionPlan?.subscriptionTierType
                                        ? '#afdffa'
                                        : '#daf0ff'
                                }}>
                                    <div>
                                        <center className='mb-3'>
                                            <Title size='5' className='mb-3'>
                                                {tier?.heading}
                                            </Title>
                                            <span className='has-text-link has-text-weight-bold'>
                                                {tier?.paymentMonth}<br />
                                                {tier?.paymentYear}
                                            </span>
                                        </center>

                                        <Title size='6' className='mb-0'>
                                            {tier?.detailTitle}
                                        </Title>
                                        <div class="content">
                                            <ul className='ml-0 pl-0' style={{ listStyle: 'none' }}>
                                                {tier.feature1 && (
                                                    <li>
                                                        <div className='is-flex'>
                                                            <span className='mr-2' role="img" aria-label="checkmark">✓</span>
                                                            <div>{tier.feature1}</div>
                                                        </div>
                                                    </li>
                                                )}
                                                {tier.feature2 && (
                                                    <li>
                                                        <div className='is-flex'>
                                                            <span className='mr-2' role="img" aria-label="checkmark">✓</span>
                                                            <div>{tier.feature2}</div>
                                                        </div>
                                                    </li>
                                                )}
                                                {tier.feature3 && (
                                                    <li>
                                                        <div className='is-flex'>
                                                            <span className='mr-2' role="img" aria-label="checkmark">✓</span>
                                                            <div>{tier.feature3}</div>
                                                        </div>
                                                    </li>
                                                )}
                                                {tier.feature4 && (
                                                    <li>
                                                        <div className='is-flex'>
                                                            <span className='mr-2' role="img" aria-label="checkmark">✓</span>
                                                            <div>{tier.feature4}</div>
                                                        </div>
                                                    </li>
                                                )}
                                                {tier.feature5 && (
                                                    <li>
                                                        <div className='is-flex'>
                                                            <span className='mr-2' role="img" aria-label="checkmark">✓</span>
                                                            <div>{tier.feature5}</div>
                                                        </div>
                                                    </li>
                                                )}
                                                {tier.feature6 && (
                                                    <li>
                                                        <div className='is-flex'>
                                                            <span className='mr-2' role="img" aria-label="checkmark">✓</span>
                                                            <div>{tier.feature6}</div>
                                                        </div>
                                                    </li>
                                                )}
                                                {tier.feature7 && (
                                                    <li>
                                                        <div className='is-flex'>
                                                            <span className='mr-2' role="img" aria-label="checkmark">✓</span>
                                                            <div>{tier.feature7}</div>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                    <div>

                                        <center className='p-4'>
                                            {tier?.planTierType === subscription?.subscriptionPlan?.subscriptionTierType ? (
                                                <div style={{
                                                    backgroundColor: '#0aa35e',
                                                    padding: '5px',
                                                    paddingBottom: "8px",
                                                    paddingTop: "8px",
                                                    borderRadius: '4px',
                                                    minWidth: '100px',
                                                    textAlign: 'center',
                                                    fontWeight: 800,
                                                    color: 'white',
                                                }}>
                                                    Subscribed
                                                </div>
                                            ) : (
                                                <Button
                                                    size='normal'
                                                    color='info'
                                                    className='ml-auto is-fullwidth'
                                                    onClick={() => handleButtonClick(tier?.paymentMonth, tier?.paymentYear, tier?.heading, tier?.planTierType)}
                                                    disabled={tier?.planTierType === subscription?.subscriptionPlan?.subscriptionTierType || (tier.planTierType === "ENHANCED_PLUS_RESERVE_GOOGLE" && disableButton)}
                                                >
                                                    {tier?.buttonText}
                                                </Button>
                                            )}
                                        </center>
                                    </div>
                                </Card>
                            </Column>
                        ))}
                    </Columns>
                </Box>
            </div>
        </React.Fragment >
    );
};

export default Pricing;

const ColumnBox = styled.div`
  border: 1px solid white;
  border-radius:10px;
  margin-left:auto;
  margin-right:auto;
  padding-top:15px;
  padding-bottom:15px;
  padding-left:5px;
  padding-right:5px;
  width: 30%;
  background-color: #0aa35e;
  color: white;
  @media (max-width: 768px) {
    width: 100%;
    padding:15px;
  }
`