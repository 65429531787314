import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { GET_USER_PROFILE, SAVE_USER_PROFILE, SAVE_MARKETING_DETAILS } from "./requests";
import { INITIAL_DATA } from "./constants";

export const useData = () => {
  const {
    loading: isLoading,
    error,
    refetch,
    data = INITIAL_DATA,
  } = useQuery(GET_USER_PROFILE, {
    fetchPolicy: "no-cache",
  });

  const { getUserProfile: profile } = data;

  return { isLoading, error, refetch, data: profile };
};

export const useAccount = (refetch) => {
  const { push } = useHistory();

  const [onProfileUpdate, { loading: isProfileLoading, error }] = useMutation(
    SAVE_USER_PROFILE,
    {
      onCompleted: ({
        saveUserProfile: {
          error,
          isVerficationCodeSent,
          hasSemiAuthAccount,
          user: { phoneNumber },
        },
      }) => {
        if (isVerficationCodeSent) {
          if(hasSemiAuthAccount) toast.success("Your account will be merged with this number acount");
          push({
            pathname: "/account/verify-phone",
            state: { currentMenu: 0, phoneNumber },
          });
          return;
        }
        if (error) {
          throwError(error);
          return;
        } else {
          toast.success("You profile has been updated successfully");
          refetch();
        }
      },
      onError: () => throwError("Something went wrong, please try again later"),
    }
  );

  const throwError = (error) => toast.error(error);

  return { isProfileLoading, error, onProfileUpdate };
};

export const useMarketing = (refetch) => {

  const [onMarketingDetailsUpdate, { loading: isMarketingDetails, error }] = useMutation(
    SAVE_MARKETING_DETAILS,
    {
      onCompleted: ({
        updateMarketingUser: {
          errorMessage,
          isSaved,
        },
      }) => {
        if (isSaved) {
          toast.success("You profile has been updated successfully");
          refetch();
        }
        else{
          throwError(errorMessage || error);
        }
      },
      onError: () => throwError("Something went wrong, please try again later"),
    }
  );

  const throwError = (error) => toast.error(error);

  return { isMarketingDetails, error, onMarketingDetailsUpdate };
};
