import React, { Fragment } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { NotificationsChannel } from './components';
import { useData } from './hooks';

export const CommunicationTemplates = ({ data: business, refetch }) => {
  const { onSaveTemplate, isLoading } = useData(refetch);

  const handleSave = (id, notificationChannel ,templateType ,content) => {
    return onSaveTemplate({
      variables: {
        notificationTemplateInput: {
          id: id,
          businessId: business.id,
          notificationFormat: 'CONTENT',
          notificationChannel: notificationChannel,
          templateType: templateType,
          templateContent: content,
        },
      },
    });
  };

  return (
    <Fragment>
      <Accordion allowZeroExpanded={true}>
        {business?.appointmentTemplates?.map(({ name, notificationTemplateType, templates }, index) => (
          <AccordionItem uuid={index + 1}>
            <AccordionItemHeading>
              <AccordionItemButton>{name}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <NotificationsChannel
                notificationTemplateType={notificationTemplateType}
                templates={templates}
                templatesReplacements={business?.templatesReplacements || []}
                onSave={handleSave}
                isLoading={isLoading}
              />
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Fragment>
  );
};
