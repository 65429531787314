import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { APIPaths } from 'services/api-provider';

export const useData = ({ email }) => {
  const [isMailSend, setMailResponse] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();

  const isValidEmailFormat = () => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
  };

  const handleResetRequest = () => {
    if (!isValidEmailFormat()) {
      setError('Please enter a valid email address');
      return;
    }
    setLoading(true);
    axios
      .get(`${APIPaths.Gateway}/api/identity/forgotpassword?email=${email}`)
      .then(() => {
        setMailResponse(true);
        setLoading(false);
      })
      .catch(() => {
        setMailResponse(false);
        setLoading(false);
        toast.error('Something went wrong. Please try again later', {
          hideProgressBar: true,
        });
      });
  };

  return {
    loading,
    error,
    isMailSend,
    onResetPassword: handleResetRequest,
  };
};
