import React, { Fragment } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Table, Title, Text, Button } from '@safelyq/bulma-ui-library';

const StyledRow = styled.tr.attrs(() => ({}))``;

const StyledList = styled.td.attrs(() => ({
  className: 'py-3',
}))`
  border: 10px solid white !important;
  background: #fafafa;
  color: black;
`;

export const RegionalHolidaysCalendar = ({ ...props }) => {
  const { slots, handleRegionalHolidayToggle, handleBusinessHolidayCreate } =
    props;

  return (
    <Fragment>
      <Title size='5' className='mx-2'>
        Regional Holidays Calendar
      </Title>
      <Table fullwidth bordered>
        {slots?.regionalHolidays?.map(
          ({ id, name, baseDayOfYear, date, dayOfYear, isSelected }) => (
            <StyledRow key={`regional-holiday-${id}`}>
              <StyledList>
                <div className='is-flex is-justify-content-center'>
                  <input
                    type='checkbox'
                    checked={isSelected}
                    onChange={({ target: { checked } }) =>
                      handleRegionalHolidayToggle({ id, checked })
                    }
                  />
                </div>
              </StyledList>
              <StyledList>
                <Text>{name}</Text>
              </StyledList>
              <StyledList>
                <Text>
                  {moment(baseDayOfYear || date).format('MM/DD/YYYY')}
                </Text>
              </StyledList>
            </StyledRow>
          )
        )}
      </Table>
    </Fragment>
  );
};
