import React, { useState } from 'react'
import { Column } from '@safelyq/bulma-ui-library';

export const CampaignGroup = ({ group, i, existingCampaignGroups, handleCampaignGroup }) => {

  let existingCampaignG = existingCampaignGroups || {}
  const [isChecked, setChecked] = useState(existingCampaignG[i]?.isSelected)

  return (
    <Column size='3'>
      <label className='checkbox'>
        <input
          type='checkbox'
          name={group.name}
          value={group.name}
          checked={group.isSelected}
          onChange={e => {
            handleCampaignGroup(e, () => {
              setChecked(!isChecked)
            })
          }}
        />
        <strong className='ml-2'>{group.name} </strong>
        <i>
          {group.name === "SMS" ? <small>({group.priceInCents}¢/segment)</small> : <small>({group.priceInCents}¢/msg)</small>}
        </i>
      </label>
    </Column>
  )
}
