import { gql } from "@apollo/client";

export const GET_USER_PROFILE = gql`
  query GetUserProfile {
    getUserProfile {
      user {
        id
        email
        emailConfirmed
        firstName
        lastName
        phoneNumber
        claimedPhoneNumber
        phoneNumberConfirmed
        userPreferences {
          allowEmail
          allowSms
          allowWhatsApp
        }
      }
      hasSemiAuthAccount
      isVerficationCodeSent
      error
    }
  }
`;

export const GET_USER_LOCATIONS = gql`
  query GetUserLocations {
    getUserLocations {
      id
      name
      businessWatcherInfo {
        userType
        allowBusinessSms
        allowBusinessEmail
        allowBusinessWhatsApp
        
        allowBusinessMessageSms
        allowBusinessMessageEmail
        allowBusinessMessageWhatsApp
        
        allowAppointmentSms
        allowAppointmentEmail
        allowAppointmentWhatsApp
        
        allowRatingSms
        allowRatingEmail
        allowRatingWhatsApp
      }
    }
  }
`;

export const SAVE_USER_PROFILE = gql`
  mutation SaveUserProfile($userProfileInput: UserProfileInput!) {
    saveUserProfile(userProfileInput: $userProfileInput) {
      user {
        id
        email
        emailConfirmed
        firstName
        lastName
        phoneNumber
        claimedPhoneNumber
        phoneNumberConfirmed
        claimedPhoneNumber
        userPreferences {
          allowEmail
          allowSms
          allowWhatsApp
        }
      }
      hasSemiAuthAccount
      isVerficationCodeSent
      error
    }
  }
`;

export const SAVE_BUSINESS_WATCHER = gql`
  mutation SaveBusinessWatcherInfo($businessWatcherInput: BusinessWatcherInput!) {
    saveBusinessWatcherInfo(businessWatcherInput: $businessWatcherInput) {
      errorMessage
      isSaved
    }
  }
`;
