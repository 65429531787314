import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client';
export const useAdminCount = (apiCalled) => {

  // Combined query for messages, notifications and review
  const [onGetAdminData, {
    loading: isLoading,
    error,
    refetch,
    data = {
      getBusinessById: {
        conversationsSummary: { unreadCount: 0 },
        getUnViewedReviewsCount: 0,
      },
      getAdminNotifications: {
        unviewedCount: 0,
        unreadCount: 0,
      },
    },
  }] = useLazyQuery(GET_ADMIN_DATA, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      apiCalled.current = false; // Reset flag
    }
  });

  // Ensure safe destructuring with default values
  const {
    getBusinessById = {},
    getAdminNotifications = {}
  } = data;

  // Destructure with default values and optional chaining
  const {
    getUnViewedReviewsCount: unreadReviews = 0,
    conversationsSummary: { unreadCount = 0 } = {}
  } = getBusinessById || {}; // Ensure getBusinessById is an object;

  const {
    unviewedCount: notificationsUnviewedCount = 0,
    unreadCount: notificationsUnreadCount = 0
  } = getAdminNotifications || {}; // Ensure getAdminNotifications is an object;

  return {
    onGetAdminData,
    isLoading,
    error,
    refetchBusinessDate: refetch,
    unreadCount,
    unreadReviews,
    notificationsUnviewedCount,
    notificationsUnreadCount,
  };
  //   isLoading, error, refetch, data: unreadCount, unreadReviews,
  //   isLoadingNotifications, isErrorNotifications, isRefetchNotifications, dataNotifications, onGetBusiness, onGetBusinessNotifications
  // };
};

const GET_ADMIN_DATA = gql`
  query GetAdminData($id: Int!, $businessId: Int!) {
    getBusinessById(id: $id, showAll: true) {
      getUnViewedReviewsCount
      conversationsSummary {
        unreadCount
      }
    }
    getAdminNotifications(businessId: $businessId) {
      unviewedCount
      unreadCount
    }
  }
`;
