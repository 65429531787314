import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
    Table,
} from '@safelyq/bulma-ui-library';
import { isEmpty } from 'lodash';
import { useData } from "../../../../../admin-appointment-feature/src/routes/ManageAppointments/hooks"
import { useParams } from 'react-router-dom';
import AppointmentRows from './AppointmentRows';
import moment from 'moment';
import { useSelector } from 'react-redux';

const ScrollableDiv = styled.div`
    width: 100%;
    height: 420px;
    overflow-y: auto;
    overflow-x: auto;

    /* Custom scrollbar styles for Webkit browsers */
    &::-webkit-scrollbar {
        width: 12px;
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #b3c7e6; /* Light aesthetic color for scrollbar thumb */
        border-radius: 10px;
        border: 3px solid #f1f1f1; /* Adds a border for better visibility and aesthetics */
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #a0b9d6; /* Slightly darker shade on hover */
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* Light background color for the scrollbar track */
    }

    /* Firefox scrollbar styling */
    scrollbar-width: thin;
    scrollbar-color: #dddddd #f7f5f5;
`;

export const TableViewAppontments = ({ appointments, isRefetchAppointments, getUserAppointments }) => {
    // if (isLoading) return;
    // <ClipLoader isFullScreen />;

    const { businessId } = useParams();
    // const { isLoading, data } = useData();

    // const { businessSubLevels } = data;
    const global = useSelector(state => state)
    const businessSubLevels = useSelector(state => state?.business?.businessSubLevels)
    const isAnySubLevel =
        !isEmpty(businessSubLevels) &&
        businessSubLevels[0].id !== parseInt(businessId);;

    return (
        <ScrollableDiv>
            <Table
                style={{ width: "100%", whiteSpace: "nowrap", overflowX: "scroll" }}
            >
                <thead>
                    <tr>
                        <th className='pl-2'>Users</th>
                        <th className='pl-2'>Date</th>
                        <th className='pl-2'>Time</th>
                        <th className='pl-2'>Service</th>
                        {
                            isAnySubLevel &&
                            <th className='pl-2'>Service Provider</th>}
                        <th className='pl-2'>Status</th>
                    </tr>
                </thead>
                <tbody className='has-text-black'>
                    {
                        appointments?.filter(appointment => {
                            // Convert startDate to the timezone of the business and return only remaining appointments
                            // const startDateTimeZone = moment.tz(appointment?.startTime, appointment?.timeZone);
                            const endDateTimeTimeZone = moment.tz(appointment?.endTime, appointment?.timeZone);
                            return endDateTimeTimeZone?.isAfter(moment());
                        })?.map((appointment, index) => (
                            <AppointmentRows
                                isAnySubLevel={isAnySubLevel}
                                index={index}
                                key={appointment.id}
                                {...appointment}
                                serviceName={appointment.service.name}
                                serviceProviderName={appointment.subBusiness.name}
                                userName={appointment.user.name}
                                firstName={appointment.user.firstName}
                                lastName={appointment.user.lastName}
                                email={appointment.user.email}
                                // isRefetchAppointments={isRefetchAppointments}
                                getUserAppointments={getUserAppointments}
                                appointments={appointments}
                                isHighlighted={
                                    moment(appointment?.endTime).isSameOrAfter( // in future wrt current time
                                        moment() // current time
                                    )
                                        ? moment(appointment?.startTime).isSameOrBefore( // in past wrt current time
                                            moment() // current time
                                        )
                                            ? index + 1 < appointments?.length
                                                ? moment(
                                                    appointments[index + 1]?.startTimeOnly,
                                                    'h:mmA'
                                                ).isSameOrBefore(moment(moment().format('h:mmA'), 'h:mmA'))
                                                    ? false
                                                    : true
                                                : true
                                            : false
                                        : false
                                }
                            />
                        ))
                    }
                </tbody>
            </Table>
            {/* {pageCount > 1 ? (
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    pageCount={pageCount}
                    onPageChange={handleChangePage}
                    containerClassName={'paginationBttns'}
                    previousLinkClassName={'previousBttn'}
                    nextLinkClassName={'nextBttn'}
                    disabledClassName={'paginationDisabled'}
                    activeClassName={'paginationActive'}
                />
            ) : null} */}
            {/* </div> */}
        </ScrollableDiv>
    );
};
