import { gql } from '@apollo/client';
export const GET_MANAGE_BUSINESS_PAGE_INFO = gql` 
query {
	getManageBusinessPageInfo{
		manageBusiness{
			profile{
        name
        address1
        category
        categoryTags
        country
        safetyTags
        timeZone
      }
            businessImage{
        uploadBusinessPictures
        uploadLogoPictures
        uploadMenuPictures
      }
			customerTraffic{
        businessAppointmentScheduledAllowed
        childrenAllowed
        endingOffset
        gapBetweenAppointment
        maximumGuestAllowed
        oldAppointmentThreshold
        startOffset
        totalCapacity
        userAppointmentScheduledAllowed
      }
          
            appointmentConfirmationSetup{
        appointmentConfirmationAllowed
        customerCanConfirmX_MinsBeforeAppointment
        specialInstructions
      }
            messagingSetup{
        allowUserToRespondToMessages
        allowUserToSendAttachments
        userMessagingAllowed
      }
      servicesSetup {
        googleReserve
        nonRestaurant
      }
    }
    errorMessage
    isSuccess
  }
}
`;