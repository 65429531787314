const handleNumericCheck = async (event, allowfloat = '', allownve) => {

  var charCode = event.which ? event.which : event.keyCode
  // console.log(charCode);
  if (allownve) {
    if (charCode === 45) {
      return true
    }
  }

  if (charCode === 46) {
    //Check if event.target.value  already contains the . character
    if (event.target.value.indexOf('.') === -1) {
      if (allowfloat === 'allowFloat') {
        return true
      }
      event.preventDefault()
      return false
    } else {
      event.preventDefault()
      return false
    }
  } else {

    if (charCode >= 48 && charCode <= 57) {
      return true
    } else {
      event.preventDefault()
      return false
    }
  }
}
export default handleNumericCheck
