import React, { useState, Fragment, useEffect } from 'react';
import { useMarketingUser, useSaveBusinessAttatchment } from '../routes/hooks';
import {
    Section,
    Container,
    Title,
    Table,
    DateInput,
    TextInput,
    Icon,
    Block,
    Column,
    Field,
    Control,
    Columns,
    Button,
} from "@safelyq/bulma-ui-library";
import moment from 'moment';
import styled from "styled-components";
import BreadCrumbs from 'features/business-admin/src/components/Breadcrumbs';
import { toast as showToast } from 'react-toastify';
import Pagination from 'components/PaginationGlobal';
import { ClipLoader } from 'react-spinners';
import ReactTooltip from 'react-tooltip';
import { FilterBy, FilterByConstants, getStatusColor } from './contants';

const StyledList = styled.td.attrs(() => ({
    className: "py-3 pl-2",
}))``;

const StyledDateInputBar = styled(DateInput).attrs(() => ({}))`
  background: rgba(0, 0, 0, 0.02);
`;

const StyledInputField = styled(TextInput).attrs(() => ({}))`
  background: rgba(0, 0, 0, 0.02);
`;

const MarketingUser = () => {

    /* States */
    // CSV File states
    const [inputFileKey, setInputFileKey] = useState(0);
    const [base64Attatchments, setBase64Attatchments] = useState([]);
    const [showUsers, setShowUsers] = useState(false);
    const [csvFile, setCsvFile] = useState();

    const [filters, setFilter] = useState({
        name: true,
        email: false,
        country: false,
        phoneNumber: false,
        state: false,
        zipCode: false,
    })

    const [paginationVariables, setPaginationvariables] = useState({
        currentPage: 1,
        totalPages: 0,
        skip: 0,
        take: 10
    })

    // Marketing States
    const { marketingUsers, loadingGetMarkeringUsers, getMarketingUsers } = useMarketingUser();

    const { isSaving, onUploadAttachment } = useSaveBusinessAttatchment();
    const [filterValues, setFilterValues] = useState({
        startDate: null,
        endDate: null,
        search: null,
    });

    // Filters
    // const [advanceSearchOpen, setAdvanceSearchOpen] = useState(false);
    // const [filters, setFilters] = useState({
    //     country: { value: null, isChecked: false },
    //     email: { value: null, isChecked: false },
    //     endDate: { value: null, isChecked: false },
    //     name: { value: null, isChecked: false },
    //     phoneNumber: { value: null, isChecked: false },
    //     state: { value: null, isChecked: false },
    //     startDate: { value: null, isChecked: false },
    //     zipCode: { value: null, isChecked: false }
    // });

    // const handleCheckboxChange = (e) => {
    //     const { name, checked } = e.target;
    //     setFilters(prevState => ({
    //         ...prevState,
    //         [name]: {
    //             ...prevState[name],
    //             isChecked: checked
    //         }
    //     }));
    // };

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setFilters(prevState => ({
    //         ...prevState,
    //         [name]: {
    //             ...prevState[name],
    //             value: value
    //         }
    //     }));
    // };

    /* UseEffect */
    useEffect(() => {
        const searchVariable = {
            startDate: filterValues.startDate ? moment(filterValues.startDate).format('YYYY-MM-DD') : null,
            endDate: filterValues.endDate ? moment(filterValues.endDate).format('YYYY-MM-DD') : null,
            name: filters?.name ? filterValues?.search : null,
            email: filters?.email ? filterValues?.search : null,
            country: filters?.country ? filterValues?.search : null,
            phoneNumber: filters?.phoneNumber ? filterValues?.search : null,
            zipCode: filters?.zipCode ? filterValues?.search : null,
            state: filters?.state ? filterValues?.search : null,
            paginationInputModel: {
                skip: paginationVariables?.skip,
                take: paginationVariables?.take
            }
        }

        getMarketingUsers({
            variables: {
                selectMarketingUsersInput: searchVariable
            }
        });
        setShowUsers(true);
    }, [paginationVariables?.skip])

    const handleSearchUsers = (e) => {
        e.preventDefault();

        const searchVariable = {
            startDate: filterValues.startDate ? moment(filterValues.startDate).format('YYYY-MM-DD') : null,
            endDate: filterValues.endDate ? moment(filterValues.endDate).format('YYYY-MM-DD') : null,
            name: filters?.name ? filterValues?.search : null,
            email: filters?.email ? filterValues?.search : null,
            country: filters?.country ? filterValues?.search : null,
            phoneNumber: filters?.phoneNumber ? filterValues?.search : null,
            zipCode: filters?.zipCode ? filterValues?.search : null,
            state: filters?.state ? filterValues?.search : null,
            paginationInputModel: {
                // skip: paginationVariables?.skip,
                // take: paginationVariables?.take
                skip: 0,
                take: 10
            }
        }

        getMarketingUsers({
            variables: {
                selectMarketingUsersInput: searchVariable
            }
        });
        setShowUsers(true);
    }

    useEffect(() => {
        if (marketingUsers) {
            setPaginationvariables({
                ...paginationVariables,
                currentPage: (paginationVariables?.skip / 10) + 1,
                totalPages: Math.ceil(marketingUsers?.totalCount / 10)
            })
        }
    }, [marketingUsers])

    /* Functions */
    const handleFilterCheckboxChange = (keyName, checked) => {
        // Set all filters to false, then set the selected filter to true
        const newFilters = Object.keys(filters).reduce((acc, key) => {
            acc[key] = false;
            return acc;
        }, {});
        newFilters[keyName] = checked;

        setFilter(newFilters);
    }

    const handlePageChange = (page) => {
        setPaginationvariables({
            ...paginationVariables,
            skip: (page * 10) - 10,
            take: 10,
            currentPage: page
        })
    };

    const handleFilterInputChange = (e) => {
        const { value, name } = e.target;
        setFilterValues({
            ...filterValues,
            [name]: value
        });
    }

    // CSV File Upload
    const isValidFileUploaded = (file) => {
        const validExtensions = ['csv']
        const fileExtension = file.type.split('/')[1]
        return validExtensions.includes(fileExtension)
    }

    const onUploadChange = (e) => {
        let files = e.target.files
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                const isvalid = isValidFileUploaded(files[i])
                if (isvalid) {
                    let reader = new FileReader();
                    reader.readAsDataURL(files[i]);
                    reader.onload = function () {

                        let obj = { name: files[i].name, file: reader.result }
                        setBase64Attatchments(() => ([...base64Attatchments, obj]))
                        const parsedBase64 = reader.result.split(",")[1]
                        let fileInput = {
                            fileName: files[i].name,
                            base64String: parsedBase64,
                            contentType: "application/csv"
                        };

                        // Set file
                        setCsvFile(fileInput)
                        // Append key to trigger the input for the same file
                        setInputFileKey((prevKey) => prevKey + 1);
                    };
                    reader.onerror = function (error) {
                    };
                }
                else {
                    showToast.error('Please upload only csv file');
                    setBase64Attatchments([])
                    break;
                }
            }
        }
    };

    // When file is set then call APi
    useEffect(() => {
        if (csvFile) {
            showToast.error('Valid csv file uploaded');
            onUploadAttachment({
                variables: {
                    fileInput: csvFile,
                },
            }).then((response) => {
                setBase64Attatchments([])
            });
        }
    }, [csvFile])

    return (
        < Container >
            <Section>
                <Container className="mb-4">
                    <BreadCrumbs />
                </Container>
                <Fragment>
                    <Block className='is-flex is-align-items-center is-justify-content-space-between'>
                        <Title size='5' className='mb-0'>   <Icon name='calendar' className='mr-3' />  Marketing Users </Title>
                        <div className="file has-name is-small is-info is-flex is-justify-content-end">
                            <label className="file-label">
                                <input className="file-input"
                                    key={inputFileKey}
                                    onChange={(e) => onUploadChange(e)}
                                    type="file" name="resume" />
                                <span className="file-cta">
                                    <span className="file-icon"> <Icon name='upload' className="px-2 py-auto" /> </span>
                                    <span className="file-label">  {base64Attatchments.length > 0 ? (base64Attatchments[0].name) : "Add file"} </span>
                                </span>
                            </label>
                        </div>
                    </Block>
                    <Columns multiline>
                        <Column size='3'>
                            <Field>
                                <Control>
                                    <StyledDateInputBar name='startDate' selected={filterValues.startDate} onChange={(data) => handleFilterInputChange(data)} />
                                    <small className='has-text-grey'>   <i>Choose Date Range Start</i>  </small>
                                </Control>
                            </Field>
                        </Column>
                        <Column size='3'>
                            <Field>
                                <Control>
                                    <StyledDateInputBar name='endDate' selected={filterValues.endDate} onChange={(data) => handleFilterInputChange(data)} />
                                    <small className='has-text-grey'> <i>Choose Date Range End</i>   </small>
                                </Control>
                            </Field>
                        </Column>
                        <Column size='4'>
                            <Field>
                                <Control>
                                    <StyledInputField
                                        name='search'
                                        placeholder={`Enter ${FilterByConstants[Object.keys(filters).find(key => filters[key])]}`}
                                        value={filterValues?.search}
                                        onChange={(data) => handleFilterInputChange(data)}
                                    />
                                    <small className='has-text-grey'> <i>Search Marketing Users</i>   </small>
                                </Control>
                            </Field>
                        </Column>
                        <Column size='2' className='is-flex is-justify-content-center' >
                            {/* <Button data-tip data-for='advanceSearch' onClick={() => setAdvanceSearchOpen(true)}>
                                <Icon name='filter' color='info' scale='lg' />
                            </Button> */}
                            <div className=''>
                                <div className='dropdown is-hoverable'>
                                    <div className='dropdown-trigger'>
                                        <Button
                                            className='button-icon'
                                            aria-haspopup='true'
                                            aria-controls='actions-dropdown'
                                        >
                                            <Icon name='filter' color='info' scale='lg' />
                                        </Button>
                                    </div>
                                    <div className='dropdown-menu' id='actions-dropdown' role='menu'>
                                        <div className='dropdown-content'>
                                            {Object.keys(FilterBy).map((keyName, index) => (
                                                <label className='checkbox dropdown-item'>
                                                    <div className='control'>
                                                        <input
                                                            type='checkbox'
                                                            name={keyName}
                                                            value={filters[keyName]}
                                                            checked={filters[keyName]}
                                                            onChange={({ target: { checked } }) =>
                                                                handleFilterCheckboxChange(keyName, checked)
                                                            }
                                                        />
                                                        <span
                                                            key={`status-${index}`}
                                                            className={`tag is-light ml-2`}
                                                            style={{
                                                                background: `rgba(${getStatusColor(
                                                                    keyName
                                                                )}, 0.1)`,
                                                                color: `rgb(${getStatusColor(keyName)})`,
                                                            }}
                                                        >
                                                            {FilterByConstants[keyName]}
                                                        </span>
                                                    </div>
                                                </label>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Button className='ml-4 is-info'
                                onClick={handleSearchUsers}
                            >
                                <small>Search</small>
                                <Icon name='search' className="px-2" />
                            </Button>

                            <ReactTooltip id='advanceSearch' place='top' effect='solid'>
                                Click for Advance Search
                            </ReactTooltip>
                        </Column>
                    </Columns>

                    {
                        !showUsers
                            ?
                            <div className='is-flex is-justify-content-center is-align-items-center' style={{ marginTop: "100px" }}>
                                <div className='has-text-centered'>
                                    <Icon name='users' className="px-2 py-auto" style={{ fontSize: "80px", color: "grey" }} />
                                    <p className='is-size-5 has-text-grey'>Search for marketing users by entering a name or email above</p>
                                </div>
                            </div>
                            :
                            loadingGetMarkeringUsers
                                ?
                                <div className='is-fullWidth my-5 is-flex is-justify-content-center'>
                                    <ClipLoader size='40' />
                                </div>

                                :
                                <Table fullwidth hoverable>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th className="pl-2">Name</th>
                                            <th>Phone Number</th>
                                            <th>Email</th>
                                            <th>Created Date</th>
                                            <th>Zip Code</th>
                                        </tr>
                                    </thead>
                                    {(marketingUsers && marketingUsers?.totalCount > 0) ? (
                                        <tbody className="has-text-black">
                                            {/* {marketingUsers.slice((paginationVariables?.currentPage - 1) * 10, ((paginationVariables?.currentPage - 1) * 10) + 10).map(({ firstName, middleName, lastName, phoneNumber, email, createdTime, zipCode }, index) => ( */}
                                            {marketingUsers?.marketingUsers?.map(({ firstName, middleName, lastName, phoneNumber, email, createdTime, zipCode }, index) => (
                                                <tr key={`marketingUsers-${index}`}>
                                                    <StyledList>{index + 1}</StyledList>
                                                    <StyledList>{firstName} {middleName} {lastName || "---"}</StyledList>
                                                    <StyledList>{phoneNumber || "---"}</StyledList>
                                                    <StyledList>{email || "---"}</StyledList>
                                                    <StyledList>{createdTime ? moment(createdTime).format('YYYY-MM-DD') : "---"}</StyledList>
                                                    <StyledList>{zipCode || "---"}</StyledList>
                                                </tr>
                                            ))}
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            <tr>
                                                <StyledList className="has-text-black">No records found</StyledList>
                                            </tr>
                                        </tbody>
                                    )}
                                </Table>
                    }

                    {showUsers && marketingUsers && marketingUsers?.totalCount > 0
                        &&
                        <Pagination
                            currentPage={paginationVariables?.currentPage}
                            totalPages={paginationVariables?.totalPages}
                            onPageChange={handlePageChange}
                        />
                    }
                </Fragment>
            </Section>
        </Container >
    );
};

export default MarketingUser;