import React, { Fragment } from "react";
import { Columns, Column } from "@safelyq/bulma-ui-library";
import { Card } from "./components";
import { NoSearchFound } from "../NotFoundSection";

export const CardView = ({ ...props }) => {
  const { locations, googleReserve } = props;
  const noLocationFound = locations.length === 0;

  if (noLocationFound) return <NoSearchFound />;

  return (
    <Fragment>
      <Columns multiline>
        {locations.map(
          (
            {
              id,
              name,
              logoUrl,
              picture,
              organization,
              ...rest
            },
            index
          ) => (
            <Column size="3" key={`cardview-${index}`}>
              <Card
                id={id}
                name={name}
                logoUrl={logoUrl}
                organization={organization?.name ?? ""}
                picture={picture}
                googleReserve={googleReserve}
                {...rest}
              />
            </Column>
          )
        )}
      </Columns>
    </Fragment>
  );
};
