import styled from 'styled-components';

const className = () => {
  const classNames = ['card-content'].join(' ');

  return classNames;
};

const CardContent = styled.div.attrs((props) => ({
  className: className`${props}`,
}))``;

CardContent.propTypes = {};

CardContent.defaultProps = {};

export default CardContent;
