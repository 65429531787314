import { useQuery } from '@apollo/client';
import { GET_MANAGE_BUSINESS_PAGE_INFO } from './request';

export const useData = () => {
  const { loading, error, data } = useQuery(GET_MANAGE_BUSINESS_PAGE_INFO);

  const { getManageBusinessPageInfo } = data || {};

  return { loading, error, data: getManageBusinessPageInfo?.manageBusiness };
};
