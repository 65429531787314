import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Title, Subtitle, Button } from '@safelyq/bulma-ui-library';

export const NoBusinessFound = () => (
  <section className='hero is-fullheight'>
    <div className='hero-body'>
      <Container className='has-text-centered'>
        <img src='/images/search-not-found.svg' alt="search-not-found" className='mb-2' width={130} />
        <Title size='5'>Oh no !</Title>
        <Subtitle size='6'>
          Sorry, no business linked with your account <br />
          <Link to='/' className='has-text-link my-3'>
            Go back
          </Link>
          <br />
          <Link to='/admin/manage-business'>
            <Button size='small' color='info'>
              Create New Business
            </Button>
          </Link>
        </Subtitle>
      </Container>
    </div>
  </section>
);
