import { string } from 'prop-types';
import styled from 'styled-components';

const className = () => {
  const classNames = ['card-footer-item'].join(' ');

  return classNames;
};

const CardFooterItem = styled.a.attrs((props) => ({
  className: className`${props}`,
  href: props.href,
}))``;

CardFooterItem.propTypes = {
  href: string,
};

CardFooterItem.defaultProps = {
  href: '#',
};

export default CardFooterItem;
