import React, { useState } from 'react'
import { Modal, Icon, Title, Button } from '@safelyq/bulma-ui-library';
import { useSaveBusinessInfo } from '../hooks';
import { toast } from 'react-toastify';
const AddressSuggestionPopup = ({ isOpen, onClose, allAddresses, refetch, businessId, businessData, organization }) => {
    const [googleAddress, setGoogleAddress] = useState({});
    const [loading, setLoading] = useState(false)
    const { isSaveLoading, onSaveBusiness } = useSaveBusinessInfo()
    const handleAddressChange = ({ target: { value } }) => {
        setGoogleAddress(JSON.parse(value));
    }
    const handleSaveAddress = async () => {
        if (googleAddress === "Keep the existing address") {
            toast.success("Existing Address Saved")
            onClose()
        }
        else if (googleAddress === "") {
            toast.warning("Please select atleast one address")
        }
        else {
            try {
                setLoading(true);
                if (googleAddress.locationType !== 'EXISTING_ADDRESS') {
                    delete googleAddress.formattedAddress;
                    
                    const response = await onSaveBusiness({ variables: { businessInput: { id: businessId, businessDetails: { ...businessData, googleAddress, }, organizationId: parseInt(organization) ?? -1, }, }, })
                    if (response.data.saveBusiness.isSaved === false) {
                        const errorMessage = { code: 403, message: response.data.saveBusiness.errorMessage };
                        throw errorMessage;
                    }
                    else {
                        await refetch()
                        toast.success("Address Saved Successfully")
                    }
                }
                onClose();
                setLoading(false);
            } catch (error) {
                setLoading(false)
                toast.error(error.message)
            }
        }

    }
    return (
        <>

            <Modal active={isOpen} clipped>
                <Modal.Background />
                <Modal.Card>
                    <Modal.Card.Head className={'is-flex is-justify-content-space-between'}>
                        <Title size='5' className='m-0'>  Save Address  </Title>
                        <Button size='small' className='button-icon' type='button' onClick={onClose} >
                            <Icon name='close' className='is-right' />
                        </Button>
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        <div className='field'>
                            <label className='label'>Address Verification</label>
                            <div className='control'>
                                <div className='select is-fullwidth'>
                                    <select onChange={handleAddressChange}>
                                        <option value="">Select address</option>
                                        {allAddresses.map((address, index) => (<option key={index} value={JSON.stringify(address)}>   {`${address.locationType === "EXISTING_ADDRESS" ? "Keep Typed Address" : "Google Address - " + address.formattedAddress}`}  </option>))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </Modal.Card.Body>
                    <Modal.Card.Foot className='has-background-white'>
                        <Button size='small' color='info' type='button' loading={loading} className='is-right' onClick={handleSaveAddress}  >  Save </Button>
                    </Modal.Card.Foot>
                </Modal.Card>
            </Modal>
        </>

    )
}

export default AddressSuggestionPopup