import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { isEmpty } from "lodash";
import {
  Section,
  Container,
  Title,
  Buttons,
  Button,
  Columns,
  Column,
  Field,
  Icon,
  Control,
  TextInput,
} from "@safelyq/bulma-ui-library";
import { Loader } from "@safelyq/base-feature";
import { NoBusinessFound, TableView, CardView } from "../../components";
import { useData } from "./hooks";
import { viewEnum } from "./constants";
import { useDispatch, useSelector } from "react-redux";
import { setBusinessName, setBusinessCategory, setBusinessFeatures, setBusinessSubscriptionPlan, setBusinessId } from "Redux/BusinessSlice/Business";

const StyledSearchBar = styled(TextInput).attrs(() => ({}))`
  background: rgba(0, 0, 0, 0.02);
`;

export const ViewHome = () => {
  const [view, setView] = useState(viewEnum.GRID);
  const [businessState, setBusinessState] = useState([]);
  const dispatch = useDispatch();
  const globalState = useSelector(state => state);

  const { reserve } = useParams();

  const [isSearchTouched, setSearchTouched] = useState(false);
  const [isGoogleReserve, setIsGoogleReserve] = useState(false);

  const { isLoading, error, data: organizations, refetch } = useData(isGoogleReserve);

  // set organization / business to the state
  useEffect(() => {
    organizations && organizations.length !== 0 && setBusinessState(organizations)
  }, [organizations])

  useEffect(() => {
    if (reserve) {
      let tempreserve = reserve.toLowerCase();
      if (tempreserve === 'optout') {
        setIsGoogleReserve(true);
      }
    }
  }, [reserve]);

  useEffect(() => {
    // Refetch Api data and update list of the businesses
    refetch();

    // Set the business states to empty
    dispatch(setBusinessName(""));
    dispatch(setBusinessCategory(""));
    dispatch(setBusinessFeatures({}));
    dispatch(setBusinessId(0));
    dispatch(setBusinessSubscriptionPlan(""));
  }, []);

  const isEmptyOrganization = isEmpty(organizations) && !isSearchTouched;

  const isListView = view === viewEnum.LIST;

  const handleSearch = ({ target: { value } }) => {
    // setSearchTouched(true);
    // onSearch(value);
    const newOrganizations = organizations.filter(({ name, id }) =>
      String(name).toLowerCase().includes(value.toLowerCase()) || (typeof id === "number" && id === parseInt(value))
    );
    setBusinessState(newOrganizations);

  };

  const handleChangeView = (view) => setView(view);

  if (isLoading) return <Loader isFullScreen />;

  if (error) return <small>Error</small>;

  if (isEmptyOrganization) return <NoBusinessFound />;

  return (
    <Section>
      <Container>
        <header className="is-flex is-justify-content-space-between is-align-items-center">
          {reserve === 'OptOut' ? (<Title size="5" className="m-0">
            Google Reserve Businesses
          </Title>) : (<Title size="5" className="m-0">
            Businesses
          </Title>)
          }
          <Buttons addons size="small">
            <Link to="/admin/manage-business">
              <Button size="small" color="info">
                Create Business
              </Button>
            </Link>
            <div className="is-hidden-mobile ml-3">
              <Button
                color={isListView ? "info" : "light"}
                onClick={() => handleChangeView(viewEnum.LIST)}
              >
                <Icon name="th-list" />
              </Button>
              <Button
                color={!isListView ? "info" : "light"}
                onClick={() => handleChangeView(viewEnum.GRID)}
              >
                <Icon name="th-large" />
              </Button>
            </div>
          </Buttons>
        </header>
        <Columns className="m-0">
          <Column size="3" className="pl-0">
            <Field>
              <Control>
                <StyledSearchBar
                  placeholder="Search location"
                  onChange={handleSearch}
                />
                <Icon name="search" position="right" />
              </Control>
            </Field>
          </Column>
        </Columns>
        {!isListView ? (
          <CardView locations={businessState} googleReserve={isGoogleReserve} />
        ) : (
          <div className="is-hidden-mobile">
            <TableView locations={businessState} googleReserve={isGoogleReserve} />
          </div>
        )}
      </Container>
    </Section>
  );
};
