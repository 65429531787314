import { STATUS } from './constants';

export const getStatusColor = (status = '') => {
  let color = 'white';
  if (status.toUpperCase() === STATUS.BOOKED) {
    color = '163, 139, 0'; //red
  } else if (status.toUpperCase() === STATUS.CANCELLED) {
    color = '255, 0, 0'; //red
  } else if (status.toUpperCase() === STATUS.CHECKEDIN) {
    color = '219, 142, 0'; //orange
  } else if (status.toUpperCase() === STATUS.COMPLETED) {
    color = '18, 173, 43'; //green
  } else if (status.toUpperCase() === STATUS.MISSED) {
    color = '255, 0, 0'; //red
  } else if (status.toUpperCase() === STATUS.CHECKED_IN) {
    color = '219, 142, 0'; //orange
  } else if (status.toUpperCase() === STATUS.PASSED) {
    color = '255, 0, 0'; //red
  } else if (status.toUpperCase() === STATUS.SERVING_NOW) {
    color = '133, 133, 133'; //gray
  } else if (status.toUpperCase() === STATUS.CHECKED_IN_BY_BUSINESS) {
    color = '18, 173, 43'; //green
  } else if (status.toUpperCase() === STATUS.CONFIRMED) {
    color = '40, 135, 239'; //blue
  }
  return color;
};

export const getStatusName = (status = '') => {
  let color = status;
  if (status.toUpperCase() === STATUS.BOOKED) {
    color = status;
  } else if (status.toUpperCase() === STATUS.CANCELLED) {
    color = status;
  } else if (status.toUpperCase() === STATUS.CHECKEDIN) {
    color = 'Customer Checked In';
  } else if (status.toUpperCase() === STATUS.COMPLETED) {
    color = status;
  } else if (status.toUpperCase() === STATUS.MISSED) {
    color = status;
  } else if (status.toUpperCase() === STATUS.PASSED) {
    color = status;
  } else if (status.toUpperCase() === STATUS.SERVING_NOW) {
    color = 'Now Serving';
  } else if (status.toUpperCase() === STATUS.CHECKED_IN_BY_BUSINESS) {
    color = 'Business Checked In';
  } else if (status.toUpperCase() === STATUS.CONFIRMED) {
    color = status;
  }
  return color;
};

export const getStatusTileColor = (status) => {
  let color = '#defbff';
  if (status.toUpperCase() === STATUS.BOOKED) {
    color = '#defbff';
  } else if (status.toUpperCase() === STATUS.CANCELLED) {
    color = '#defbff';
  } else if (status.toUpperCase() === STATUS.CHECKEDIN) {
    color = 'green';
  } else if (status.toUpperCase() === STATUS.COMPLETED) {
    color = 'green';
  } else if (status.toUpperCase() === STATUS.MISSED) {
    color = '#defbff';
  } else if (status.toUpperCase() === STATUS.PASSED) {
    color = '#defbff';
  } else if (status.toUpperCase() === STATUS.SERVING_NOW) {
    color = 'white';
  } else if (status.toUpperCase() === STATUS.CHECKED_IN_BY_BUSINESS) {
    color = 'white';
  } else if (status.toUpperCase() === STATUS.CONFIRMED) {
    color = '#defbff';
  }
  return color;
};
