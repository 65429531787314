// New message section hook
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { toast as showToast } from 'react-toastify';
import { SEND_New_MESSAGE } from './requests';
import { useMessageDispatch } from '../../index';


const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

const extensions = ['jpeg', 'png', 'gif', 'tiff', 'psd', 'jpg'];

const k = 1024;

export const useData = () => {
  const dispatch = useMessageDispatch();


  const [onSendMessage, { loading: isLoading }] = useMutation(
    SEND_New_MESSAGE,
    {
      onCompleted: ({ sendMessageToUser }) => {
        const { errorMessage, isSaved, userMessage } = sendMessageToUser;
        
        if(isSaved){
          const payload = {
            user: userMessage?.user,
            attachment: userMessage?.attachment,
            recentMessages: [{ message: { commentText: userMessage?.message?.commentText } }],
            recentMessageTime: userMessage?.message?.createdTime,
          };
          dispatch({ type: 'SET_NEW_MESSAGE_TO_RECENT', payload });
        }
        else{
          showToast.error(errorMessage);
          return;
        }
      },
      onError: () => showToast.error('Message sending failed'),
    }
  );

  return {
    isLoading,
    onSendMessage,
  };
};
