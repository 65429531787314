import React from 'react';
import styled from 'styled-components';
import { Icon, Text } from '@safelyq/bulma-ui-library';

const UploadButton = styled.div`
  width: 50px;
  height: 38px;
  border-radius: 5px;
  border: 1px dashed grey;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const StyledImage = styled.img`
  width: 60px;
  height: 38px;
  objectfit: cover;
  border: 1px dashed grey;
`;

const DeleteButton = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const StyledControl = styled.div`
  &:hover ${DeleteButton} {
    display: flex;
  }
`;

export const UploadControl = ({ name, image, onUpload, onRemove }) => {
  return (
    <div className='is-flex is-flex-wrap-wrap'>
      {image && (
        <StyledControl className='control is-relative '>
          <StyledImage src={image} />
          <DeleteButton onClick={onRemove}>
            <Icon name='trash-o' color='white' />
          </DeleteButton>
        </StyledControl>
      )}
      {!image && (
        <div className='control'>
          <label for='file-input'>
            <UploadButton>
              <Icon name='upload' />
            </UploadButton>
          </label>
          <input
            id='file-input'
            type='file'
            accept='image/*'
            name={name}
            style={{ opacity: 0, position: 'absolute', top: 10, width: 50 }}
            onChange={onUpload}
          />
        </div>
      )}
    </div>
  );
};
