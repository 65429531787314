import React, { useState, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  Button,
  Columns,
  Column,
  Buttons,
  Icon,
} from '@safelyq/bulma-ui-library';
import { useForm, useFormState, useFieldArray } from 'react-hook-form';
import { APPOINTMENT_CONFIRMATION_MESSAGES } from '../../common/constants';
import { FiInfo } from "react-icons/fi";
import { Tooltip } from 'antd';

export const AppointmentConfirmationSetup = ({
  tab,
  data: business,
  onSubmit: onSubmitParent,
  isLoading,
  toolTip
}) => {

  const [isNewQuestion, setIsNewQuestion] = useState(false);

  const [error, setError] = useState('');

  const {
    register,
    control,
    handleSubmit: onSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  // Watch all form values
  const formValues = watch();

  const [initialState, setInitialState] = useState(null);


  const { dirtyFields } = useFormState({ control });


  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questions',
    keyName: 'key',
  });

  useEffect(() => {
    if (!isEmpty(business)) {
      let {
        preCheckinInstructions,
        appointmentConfirmationOffset,
        isAppointmentConfirmationRequired,
        questions,
      } = business;

      questions = questions?.map((question) => ({
        ...question,
        isEditable: false,
        isAllowDelete: false,
      }));

      setInitialState(questions);

      reset({
        confirmationOffset: String(appointmentConfirmationOffset),
        specialInstructions: preCheckinInstructions[0] || '',
        confirmationIsRequired: isAppointmentConfirmationRequired,
        questions,
      });
    }
  }, [business]);

  const handleQuestionCancel = (index) => remove(index);

  const handleFieldMakeEditable = (index) => {
    const isEditable = fields.find(
      (question, indx) => question.isEditable && index !== indx
    );

    if (isEditable) {
      alert('Please check mark to add changes first');
      return;
    }

    if (!fields[index].isEditable) {
      fields[index].isEditable = true;

      setIsNewQuestion(true);

      reset({ questions: fields });
    } else {
      if (isEmpty(errors)) {
        let questions = watch('questions');

        questions[index].isEditable = false;

        setError('');

        reset({ questions }, { keepDirty: true });
      }
    }
  };

  // whatch the question (get data) and reset the states with the update 
  const handleCancelField = (index) => {
    // let questions = watch('questions'); 
    let questions = [...fields];
    questions[index].isEditable = false;
    setError('');
    reset({ questions }, { keepDirty: true });
  }

  const handleAddMore = (e) => {
    e.preventDefault();

    const isEditable =
      fields.length > 0
        ? fields.filter((question) => question.isEditable).length > 0
        : false;

    if (isEditable) {
      setError('Please check mark to add this question');
      return;
    }

    setIsNewQuestion(false);

    append({
      prompt: '',
      textYes: 'Yes',
      textNo: 'No',
      isEditable: true,
      isAllowDelete: true,
      isActive: false,
    });
  };

  const handleSubmit = (values) => {

    // Clone the array and remove specific properties from each object
    // values has the same data as of fields from useFieldArray as fields data can be used everywhere
    let businessQuestionValues = values?.questions.map(({ isEditable, isAllowDelete, key, ...rest }) => ({
      ...rest
    }));

    fields.forEach(function (v) {
      delete v.isEditable;
      delete v.isAllowDelete;
      delete v.key;
    });

    if (tab === 'LOCATION') {

      // let businessQuestions = businessQuestionValues?.filter(
      //   (question) => Object.keys(question).length !== 0
      // );
      let businessQuestionsValues = fields?.filter(
        (question) => Object.keys(question).length !== 0
      );

      let businessQuestions = businessQuestionsValues.map(
        ({ isUserResponseRequired, ...rest }) => ({
          ...rest,
          isUserResponseRequired: Boolean(isUserResponseRequired),
        })
      );

      onSubmitParent(
        {
          appointmentConfirmationSetup: {
            preCheckinInstructions: values.specialInstructions,
            preCheckinInstructionsAcceptText: 'Accept',
            preCheckinInstructionsRejectText: 'Reject',
            isAppointmentConfirmationRequired: values.confirmationIsRequired,
            appointmentConfirmationOffset: values.confirmationOffset,
            // businessQuestions: values?.questions,
            businessQuestions,
          },
        },
        8,
        {
          success: APPOINTMENT_CONFIRMATION_MESSAGES.Success,
          error: APPOINTMENT_CONFIRMATION_MESSAGES.Error,
        }
      );
    } else {
      let businessQuestions = businessQuestionValues?.filter(
        (question) => Object.keys(question).length !== 0
      );
      // let businessQuestions = fields?.filter(
      //   (question) => Object.keys(question).length !== 0
      // );

      businessQuestions = businessQuestions.map(
        ({ isUserResponseRequired, ...rest }) => ({
          ...rest,
          isUserResponseRequired: Boolean(isUserResponseRequired),
        })
      );


      onSubmitParent(
        {
          appointmentConfirmationSetup: {
            preCheckinInstructions: values.specialInstructions,
            preCheckinInstructionsAcceptText: 'Accept',
            preCheckinInstructionsRejectText: 'Reject',
            isAppointmentConfirmationRequired: values.confirmationIsRequired,
            appointmentConfirmationOffset: values.confirmationOffset,
            // businessQuestions: values?.questions,
            businessQuestions,
          },
        },
        8,
        business.id,
        {
          success: APPOINTMENT_CONFIRMATION_MESSAGES.Success,
          error: APPOINTMENT_CONFIRMATION_MESSAGES.Error,
        }
      );
    }
  };

  const compareArrays = () => {
    if (fields.length !== initialState.length) {
      return false;
    }

    return fields.every(field => {
      const matchingQuestion = initialState.find(formQuestion =>
        field.prompt === formQuestion.prompt &&
        field.textNo === formQuestion.textNo &&
        field.textYes === formQuestion.textYes &&
        field.isActive === formQuestion.isActive &&
        field.isUserResponseRequired === formQuestion.isUserResponseRequired
      );

      return !!matchingQuestion; // if the value is null or undefined than it converts it to boolean
    });
  };

  const isDataChanged = (formValues, fields, index, initialState, isEditable) => {
    const current = formValues?.questions[index] || {};
    const field = fields[index] || {};

    // form data and field data are not same means data needs to be confirmed
    const isDifferent = ["prompt", "textNo", "textYes", "isUserResponseRequired", "isActive"]
      .some(key => current[key] !== field[key]);

    // field length is not same means not saved yet and also in editable state
    return isDifferent && fields.length === initialState.length && isEditable;
  };


  const isDataConfirmed = (formValues, fields, index, initialState, isEditable) => {
    const current = formValues?.questions[index] || {};
    // const field = fields[index];
    const field = fields?.[index] || {};
    const initial = initialState[index] || {};

    // Field data and form data are same
    const isSame = ["prompt", "textNo", "textYes", "isUserResponseRequired", "isActive"]
      .every(key => current[key] === field[key]);

    // Fields data and initial data are not same, means data is updated
    const isUpdated = ["prompt", "textNo", "textYes", "isUserResponseRequired", "isActive"]
      .some(key => field[key] !== initial[key]);

    // length is same means data is updated and needed to be saved
    return isSame && isUpdated && fields.length === initialState.length && !isEditable;
  };



  return (
    <form onSubmit={onSubmit(handleSubmit)}>
      <Columns multiline>
        <Column size='4'>
          <div className='control'>
            <label className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>
              Customer can confirm X-mins before appointment
              <Tooltip title={toolTip?.customerCanConfirmX_MinsBeforeAppointment}> <FiInfo /> </Tooltip>
            </label>
            <input
              className='input'
              type='number'
              {...register('confirmationOffset')}
            />
          </div>
        </Column>
        <Column size='4'>
          <div className='control'>
            <label className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>Special Instructions
              <Tooltip title={toolTip?.specialInstructions}> <FiInfo /> </Tooltip>
            </label>
            <textarea
              className='textarea'
              rows={3}
              {...register('specialInstructions')}
            />
          </div>
        </Column>
        <Column size='4'>
          <div className='control'>
            <label className='label is-hidden-mobile'>&nbsp;</label>
            <label className='checkbox'>
              <input type='checkbox' {...register('confirmationIsRequired')} />
              <strong className='ml-2'>
                Appointment Confirmation Allowed ?
              </strong>
              <Tooltip title={toolTip?.appointmentConfirmationAllowed}> <FiInfo /> </Tooltip>
            </label>
          </div>
        </Column>
        <Column size='12'>
          <label className='label'>
            Confirmation Questions <small>({fields?.length} of 10)</small>
          </label>
          <small>
            Maximum 10 questions. Question response can be either yes/no or text.
          </small>
        </Column>
        {fields?.map(({ key, isEditable, isUserResponseRequired }, index) => (
          <Fragment>
            <Column size='1'>
              <label className='label'>
                Visible{' '}
                {/* <span className='has-text-danger'>*</span> */}
              </label>
              <label className='checkbox'>
                <input
                  type='checkbox'
                  disabled={!isEditable}
                  {...register(`questions.${index}.isActive`)}
                />
                {/*<strong className='ml-2'>Is Active ?</strong>*/}
              </label>
            </Column>
            <Column size='4'>
              <div className='control'>
                <label className='label'>
                  Question {index + 1}{' '}
                  <span className='has-text-danger'>*</span>
                </label>
                <div className='is-flex'>


                  <input
                    key={key}
                    disabled={!isEditable}
                    className={`input ${!isEmpty(errors) && errors?.questions[index]?.prompt
                      ? 'is-danger'
                      : ''
                      }`}
                    {...register(`questions.${index}.prompt`, {
                      required: true,
                    })}
                  />
                </div>
                {!isEmpty(errors) && errors?.questions[index]?.prompt && (
                  <p className='help is-danger'>Question is required</p>
                )}
              </div>
            </Column>
            <Column size='4'>
              <label className='label'>
                Response
                <span className='has-text-danger'>*</span>
              </label>
              <div>
                <div className='is-flex'>
                  <label className='radio'>
                    <input
                      type='radio'
                      name={`questions.${index}.isUserResponseRequired`}
                      defaultChecked={!isUserResponseRequired}
                      value={false}
                      onChange={() =>
                        setValue(
                          `questions.${index}.isUserResponseRequired`,
                          false,
                          {
                            shouldDirty: true,
                          }
                        )
                      }
                      disabled={!isEditable}
                    />
                  </label>
                  &nbsp;
                  <div className='control'>
                    <input
                      key={key}
                      disabled={!isEditable}
                      className={`input is-small ${!isEmpty(errors) && errors?.questions[index]?.textYes
                        ? 'is-danger'
                        : ''
                        }`}
                      {...register(`questions.${index}.textYes`)}
                    />
                  </div>
                  &nbsp;
                  <div className='control'>
                    <input
                      key={key}
                      disabled={!isEditable}
                      className={`input is-small ${!isEmpty(errors) && errors?.questions[index]?.textNo
                        ? 'is-danger'
                        : ''
                        }`}
                      {...register(`questions.${index}.textNo`)}
                    />
                  </div>
                </div>
                <br />
                <div className='is-flex'>
                  <label className='radio'>
                    <input
                      type='radio'
                      name={`questions.${index}.isUserResponseRequired`}
                      defaultChecked={isUserResponseRequired}
                      value={true}
                      onChange={() =>
                        setValue(
                          `questions.${index}.isUserResponseRequired`,
                          true,
                          {
                            shouldDirty: true,
                          }
                        )
                      }
                      disabled={!isEditable}
                    />
                  </label>
                  &nbsp;
                  <div className='control'>
                    <input
                      className='input is-small'
                      disabled={true}
                      placeholder='Text answer required'
                    />
                  </div>
                </div>
              </div>
            </Column>
            <Column size='2'>
              <label className='label is-hidden-mobile'>&nbsp;</label>
              <Buttons size='small'>
                {isEditable ? (
                  <Fragment>
                    <div className='is-flex'>
                      <Button
                        color='primary'
                        type='button'
                        disabled={formValues?.questions[index]?.prompt.trim() === ''} // if text is not available than dont let user check
                        onClick={() => handleFieldMakeEditable(index)}
                      >
                        <Icon name='check' />
                      </Button>

                      {/* {!isNewQuestion && ( */}

                      <Button
                        color='danger'
                        type='button'
                        className='ml-3'
                        onClick={() => !isNewQuestion ? handleQuestionCancel(index) : handleCancelField(index)}
                      >
                        <Icon name='times' />
                      </Button>
                    </div>
                    {/* )} */}
                  </Fragment>
                ) : (
                  <Button
                    color='info'
                    type='button'
                    onClick={() => handleFieldMakeEditable(index)}
                  >
                    <Icon name='pencil' />
                  </Button>
                )}

                {
                  isDataChanged(formValues, fields, index, initialState, isEditable) && (
                    <p className='has-text-danger' style={{ width: "100%" }}>Confirm?</p>
                  )
                }
                {
                  isDataConfirmed(formValues, fields, index, initialState, isEditable) && (
                    <p className='has-text-success' style={{ width: "100%" }}>Confirmed</p>
                  )
                }

              </Buttons>
            </Column>
            <Column size='1' />
          </Fragment>
        ))}
        {error && (
          <Column size='12'>
            <span className='has-text-danger'>{error}</span>
          </Column>
        )}
        <Column size='12'>
          <Link to='#' className='has-text-info' onClick={handleAddMore}>
            <Icon name='plus' className='mr-2' />
            Add Question
          </Link>
        </Column>
        <Column size='12'>
          <Button
            type='submit'
            color='info'
            disabled={isEmpty(dirtyFields) || compareArrays() || isLoading || fields.some(obj => obj.isEditable === true)} // is any field is enabled than dont let save
            loading={isLoading}
          >
            Save
          </Button>
        </Column>
      </Columns>
    </form>
  );
};
