import React, { useState, useEffect } from "react";
import { Title, Modal, Icon, Button, Columns, Column } from "@safelyq/bulma-ui-library";
import PhoneInput from 'react-phone-input-2';
import {
    Accordion,
    AccordionItem,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { states } from '../../features/manage-business/src/components/CreateOrganization/constants';
import { useData } from '../../features/manage-business/src/components/CreateOrganization//hooks';
import { isEqual } from "lodash";

const EditOrganization = ({ foundOrg, isOpen, onClose, refetch }) => {

    const [initial, setInitial] = useState();
    const [values, setValues] = useState();
    const { onCreateOrganization } = useData(true);
    const [phoneNumber, setphoneNumber] = useState("");
    const [formChanged, setFormChanged] = useState(false);

    useEffect(() => {
        const orgInitialState = {
            id: foundOrg?.id || '',
            name: foundOrg?.name || '',
            address1: foundOrg?.address1 || '',
            address2: foundOrg?.address2 || '',
            city: foundOrg?.city || '',
            contactName: foundOrg?.contactName || '',
            email: foundOrg?.email || '',
            phone: foundOrg?.phone || '',
            state: foundOrg?.state || '',
            zipCode: foundOrg?.zipCode || '',
        };
        setInitial(orgInitialState);
        setValues(orgInitialState);
        setphoneNumber(foundOrg?.phone);
    }, [foundOrg]);

    useEffect(() => {
        const isValuesChanged = !isEqual(initial, values);
        const isPhoneChanged = values?.phone !== initial?.phone;
        const isFormChanged = isValuesChanged || isPhoneChanged;
        setFormChanged(isFormChanged);
    }, [values, phoneNumber, initial])

    const handleValuesChange = (e) => {
        const { name, value } = e.target;
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    }
    const handlePhoneChange = (val) => {
        setphoneNumber(val)
        setValues({
            ...values,
            phone: val
        })
    }

    const handleSubmit = async (e) => {
        setFormChanged(false);
        e.preventDefault();
        await onCreateOrganization({ variables: { organizationInput: { ...values, id: values.id, phone: phoneNumber } } });
        refetch()
    };

    return (
        <Modal active={isOpen} clipped>
            <Modal.Background />
            <Modal.Card>
                <Modal.Card.Head className={'is-flex is-justify-content-space-between'}>
                    <Title size='5' className='m-0'>Edit Organization</Title>
                    <Button size='small' className='button-icon' onClick={() => onClose(false)}><Icon name='close' className='is-right' />   </Button>
                </Modal.Card.Head>
                <Modal.Card.Body>
                    <Columns multiline>
                        <Column size='12'>
                            <Accordion preExpanded={['1']}>
                                <AccordionItem uuid={'1'}>
                                    <AccordionItemPanel>
                                        <form onSubmit={handleSubmit}>
                                            <Columns multiline>
                                                <Column size='6'>
                                                    <div className='field'>
                                                        <label className='label'>
                                                            Name <span className='has-text-danger'>*</span>
                                                        </label>
                                                        <div className='control'>
                                                            <input
                                                                value={values?.name}
                                                                name='name'
                                                                required={true}
                                                                className='input'
                                                                onChange={handleValuesChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </Column>
                                                <Column size='6'>
                                                    <div className='field'>
                                                        <label className='label'>Contact Name</label>
                                                        <div className='control'>
                                                            <input
                                                                value={values?.contactName}
                                                                name='contactName'
                                                                className='input'
                                                                onChange={handleValuesChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </Column>
                                                <Column size='6'>
                                                    <div className='field'>
                                                        <label className='label'>Phone Number</label>
                                                        <div className='control'>
                                                            <PhoneInput
                                                                inputClass='input'
                                                                countryCodeEditable={false}
                                                                country={'us'}
                                                                value={values?.phone}
                                                                onChange={handlePhoneChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </Column>
                                                <Column size='6'>
                                                    <div className='field'>
                                                        <label className='label'>Email Address</label>
                                                        <div className='control'>
                                                            <input
                                                                name='email'
                                                                className='input'
                                                                value={values?.email}
                                                                onChange={handleValuesChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </Column>
                                                <Column size='6'>
                                                    <div className='field'>
                                                        <label className='label'>Address 1 <span className='has-text-danger'>*</span></label>
                                                        <div className='control'>
                                                            <input
                                                                name='address1'
                                                                required={true}
                                                                className='input'
                                                                value={values?.address1}
                                                                onChange={handleValuesChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </Column>
                                                <Column size='6'>
                                                    <div className='field'>
                                                        <label className='label'>Address 2</label>
                                                        <div className='control'>
                                                            <input
                                                                name='address2'
                                                                className='input'
                                                                value={values?.address2}
                                                                onChange={handleValuesChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </Column>
                                                <Column size='4'>
                                                    <div className='field'>
                                                        <label className='label'>
                                                            City <span className='has-text-danger'>*</span>
                                                        </label>
                                                        <div className='control'>
                                                            <input
                                                                name='city'
                                                                required={true}
                                                                className='input'
                                                                value={values?.city}
                                                                onChange={handleValuesChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </Column>
                                                <Column size='4'>
                                                    <div className='field'>
                                                        <label className='label'>State <span className='has-text-danger'>*</span></label>
                                                        <div className='control'>
                                                            <input
                                                                list='states'
                                                                name='state'
                                                                required={true}
                                                                value={values?.state}
                                                                className='input'
                                                                onChange={handleValuesChange}
                                                            />
                                                            <datalist id='states'>
                                                                {states.map(({ name }, index) => (
                                                                    <option key={`state-${index}`} value={name} />
                                                                ))}
                                                            </datalist>
                                                        </div>
                                                    </div>
                                                </Column>
                                                <Column size='3'>
                                                    <div className='field'>
                                                        <label className='label'>Zip Code <span className='has-text-danger'>*</span></label>
                                                        <div className='control'>
                                                            <input
                                                                name='zipCode'
                                                                className='input'
                                                                required={true}
                                                                value={values?.zipCode}
                                                                onChange={handleValuesChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </Column>
                                                <Column size='6'>
                                                    <Button onClick={onClose} className='mr-2'>Cancel</Button>
                                                    <Button disabled={!formChanged} type='submit' color='info' className='ml-2'>Update</Button>
                                                </Column>
                                            </Columns>
                                        </form>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </Column>
                    </Columns>
                </Modal.Card.Body>
            </Modal.Card>
        </Modal>
    );
}

export default EditOrganization;
