import { gql } from '@apollo/client';

export const GET_ORGANIZATIONS = gql`
  query GetOrganizations {
    getUserOrganizations {
      id
      name
    }
  }
`;

export const GET_BUSINESS_BY_ID = gql`
  query ($businessId: Int) {
    getBusinessById(id: $businessId, showAll: true) {
      id
      name
      businessCategory {
        name
      }
      menus {
        id
        path
      }
      pictures {
        id
        path
      }
      businessCoupons {
        id
        code
        couponUrl
        imageUrl
        couponUsageLimit
        couponUsageLimitValue
        discount
        discountType
        startDate
        endDate
        summary
        terms
        isActive
      }
      services {
        id
        name
        duration
        isActive
      }
      categoryTags
      city
      state
      timeZone
      country
      zipCode
      email
      phone
      webAddress
      safetyTags
      address1
      address2
      description
      organization {
        id
        name
      }
      totalCapacity
      allowedCapacityAsPercentage
      allowedCapacityInUnits
      startingOffset
      appointmentGap
      endingOffset
      maxAllowedGuests
      isChildrenAllowed
      specialInstructions
      preCheckinInstructions
      appointmentConfirmationOffset
      isAppointmentConfirmationRequired
      questions {
        id
        prompt
        textYes
        textNo
        isActive
      }
      details {
        offset
      }
      businessVenue {
        venue {
          entrances {
            id
            capacity
            isActive
            name
            order
          }
        }
      }
      region {
        id
        name
        holidays {
          id
          name
          dayOfWeek
          date
          dayOfYear
          baseDayOfYear
        }
      }
      businessHours {
        id
        dayOfYear
        closingTime
        dayOfWeek
        date
        openingTime
      }
      businessHolidays {
        id
        dayOfWeek
        date
        baseDayOfYear
        dayOfYear
        specialHours {
          openingTime
          closingTime
        }
      }
    }
  }
`;

export const GET_COUNTRIES = gql`
  query GetCountries {
    getCountries {
      id
      name
      timeZones {
        id
        name
        tzName
      }
    }
  }
`;
