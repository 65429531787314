import React, { useState, useEffect } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PageTitleContext from 'contexts/PageTitleContext';
import { oneSignalAppId } from 'services/prefix-holder';
import Routes from 'routes';
import OneSignal from 'react-onesignal';
import { ErrorBoundary } from '@safelyq/base-feature';
import ScrollToTop from 'components/ScrollToTop';

import 'bulma.css';
import 'bootstrap.grid.css';

console.disableYellowBox = true;

console.warn = () => { };
console.error = () => { };


const App = () => {
  const [pageTitle, setPageTitle] = useState('');
  const { pathname } = useLocation();

  // try {
  //   OneSignal.init({ appId: oneSignalAppId });
  // } catch (e) {
  //   console.log('OneSignal Error: ', e);
  // } finally {
  //   console.log('OneSignal initialized successfully.');
  // }

  useEffect(() => {
    (() => {
      try {
        OneSignal.init({ appId: oneSignalAppId });
      } catch (e) {
        console.log('OneSignal Error: ', e);
      } finally {
        console.log('OneSignal initialized successfully.');
      }
    })();

  }, [])

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'cookieyes';
    script.src = 'https://cdn-cookieyes.com/client_data/6c61cb2cdc2257f305eaea5e/script.js';
    script.async = true;
    if (!String(pathname).includes('admin')) document.body.appendChild(script);
  }, [pathname]);
  return (
    <ErrorBoundary>
      <Helmet>
        <title>SafelyQ</title>
        <meta name='description' content='SafelyQ, a IT Best Solutions INC subsidiary, was formed to help business, hospitals, counties, cities, large  gatherings of any kind to manage people traffic in and out of facilities open or closed.' />
        <meta name='keywords' content='Appointments, SafelyQ Appointment, Online Appointments, Queueing System, Le Bernardin Appointment, Dallas City Hall' />
      </Helmet>
      <PageTitleContext.Provider value={{ pageTitle, setPageTitle }}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes />
        </BrowserRouter>
      </PageTitleContext.Provider>
    </ErrorBoundary>
  );
};

export default App;
