import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useForm, useFormState } from 'react-hook-form';
import { Columns, Column, Button } from '@safelyq/bulma-ui-library';
import { GEOLOCATION_SETUP_MESSAGES } from '../../common/constants';

export const GeoLocationSetup = ({
  data: business,
  tab,
  onSubmit: onSubmitParent,
  isLoading,
}) => {
  const [wifiName, setWifiName] = useState('');

  const {
    register,
    control,
    getValues,
    setValue,
    handleSubmit: onSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { dirtyFields } = useFormState({ control });

  useEffect(() => {
    if (!isEmpty(business)) {
      const { wifiNames, autoCheckinRadius } = business;

      reset({
        wifiNames,
        autoCheckinRadius,
      });
    }
  }, [business]);

  const handleWifiNameChange = ({ target: { name, value } }) =>
    setWifiName(value);

  const handleWifiNameAdd = (event) => {
    const {
      target: { name, value },
      key,
    } = event;

    if (key === 'Enter' && value) {
      event.preventDefault();
      if (!getValues(name)) setValue(name, [value], { shouldDirty: true });
      else setValue(name, [...getValues(name), value], { shouldDirty: true });
      setWifiName('');
    }
  };

  const handleRemoveWifiName = ({ name, index }) => {
    setValue(
      name,
      getValues(name).filter((_, indx) => indx !== index),
      { shouldDirty: true }
    );
  };

  const handleSubmit = (values) => {
    let finalData = {};

    finalData = {
      regionId:
        business?.countries?.find((country) => country.name == values.country)
          ?.id || 0,
      customerAutoCheckInSetup: {
        ...values,
      },
    };

    onSubmitParent(
      {
        ...finalData,
      },
      11,
      {
        success: GEOLOCATION_SETUP_MESSAGES.Success,
        error: GEOLOCATION_SETUP_MESSAGES.Error,
      },
      business.id
    );
  };

  const isEmptyWifiNames = getValues('wifiNames')?.length <= 0;

  return (
    <form onSubmit={onSubmit(handleSubmit)}>
      <Columns multiline>
        <Column size='4'>
          <div className='field'>
            <label className='label'>
              Wifi Names <span className='has-text-danger'>*</span>
            </label>
            <div className='control'>
              <input
                name='wifiNames'
                className='input'
                value={wifiName}
                onKeyPress={handleWifiNameAdd}
                onChange={handleWifiNameChange}
              />
              <small>
                <i>Enter wifi name and press enter</i>
              </small>
              {!isEmptyWifiNames && (
                <div className='field is-grouped is-grouped-multiline mt-2'>
                  {getValues('wifiNames')?.map((name, index) => (
                    <div
                      className='control has-text-grey'
                      key={`wifiName-${index}`}
                    >
                      <div className='tags has-addons'>
                        <span className='tag '>{name}</span>
                        <a
                          className='tag is-delete'
                          onClick={() =>
                            handleRemoveWifiName({
                              name: 'wifiNames',
                              index,
                            })
                          }
                        ></a>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </Column>
        <Column size='4'>
          <div className='field'>
            <label className='label'>
              Auto Check-In Radius <span className='has-text-danger'>*</span>
            </label>
            <div className='control'>
              <input
                className={`input ${
                  errors.autoCheckinRadius ? 'is-danger' : ''
                }`}
                {...register('autoCheckinRadius', { required: true })}
              />
              {errors.autoCheckinRadius && (
                <p className='help is-danger'>
                  Auto Check-In Radius is required
                </p>
              )}
            </div>
          </div>
        </Column>
        <Column size='12'>
          <Button
            type='submit'
            color='info'
            disabled={isEmpty(dirtyFields) || !isEmpty(errors)}
            loading={isLoading}
          >
            Save
          </Button>
        </Column>
      </Columns>
    </form>
  );
};
