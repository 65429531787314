import { isEmpty } from 'lodash';
import { useReactOidc } from '@axa-fr/react-oidc-context';
// import { useData } from '../components/Common/LoginMenu/hooks';

const getUserName = (profile) =>
  profile &&
  (profile.given_name ||
    profile.name ||
    profile.email ||
    profile.preferred_username);
const getAvatarName = (authUser) => {
  if (authUser) {
    const { firstName, lastName, email } = authUser;

    if (isEmpty(firstName) && isEmpty(lastName))
      return String(email).substring(0, 2).toUpperCase();
    else
      return (
        String(firstName).charAt(0).toUpperCase() +
        String(lastName).charAt(0).toUpperCase()
      );
  }
};

const getDisplayName = (profile) => {
  if (profile) {
    const { given_name, family_name, name } = profile;

    if (isEmpty(given_name) && isEmpty(family_name)) return name;
    else return given_name + ' ' + family_name;
  }
};

const getFormattedEmail = (email) => email.substring(0, email.lastIndexOf('@'));

const getFormattedPhone = (phone) =>
  phone.substring(phone.length - 4, phone.length);

const persistStorage = (data) => {
  localStorage.setItem('persist-data', JSON.stringify(data));
};

export function useProfile() {
  const { oidcUser, login, logout } = useReactOidc();
  const { access_token: accessToken, profile } = oidcUser || {};

  // const authuserrr = useData();

  // console.log("authuserrr", authuserrr);

  //console.log(`oidcUser: ${JSON.stringify(oidcUser)}`);

  const isLoggedIn = profile ? true : false;

  if (profile && profile.email)
    profile.email = getFormattedEmail(profile.email);
  if (profile && profile.phone)
    profile.phone = getFormattedPhone(profile.phone);

  const userName = getUserName(profile);

  const avatarName = getAvatarName("authUser");

  const displayName = getDisplayName(profile);

  const handleLogout = () => {
    if (window.OneSignal) {
      try {
        window.OneSignal.removeExternalUserId();
      } catch { }
    }
    logout();
  };

  return {
    accessToken,
    userName,
    avatarName,
    displayName,
    profile,
    login,
    logout: handleLogout,
    isLoggedIn,
    persistStorage,
    oidcUser
    //authUser,
  };
}
