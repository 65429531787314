import { oneOf, string } from 'prop-types';
import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = ['label']
    .concat(
      ['size']
        .map((name) => (props[name] ? `is-size-${props[name]}` : null))
        .filter((className) => !!className)
    )
    .join(' ');

  return classNames;
};

const Label = styled.label.attrs((props) => ({
  className: className`${props}`,
  htmlFor: props.htmlFor,
}))``;

Label.propTypes = {
  htmlFor: string,
  size: oneOf(['small', 'medium', 'large']),
};

Label.defaultProps = {
  htmlFor: undefined,
  size: undefined,
};

export default Label;
