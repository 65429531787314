import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = [
    'modal-content'
  ]
    .join(' ');

  return classNames;
};

const ModalContent = styled.div.attrs((props) => ({
  className: className`${props}`
}))``;

ModalContent.propTypes = {
};

ModalContent.defaultProps = {
};

export default ModalContent;
