import { gql } from '@apollo/client';
export const GET_BUSINESS_CONSOLE_IMAGES = gql`
  query all($id: Int!) {
    getBusinessById(id: $id) {
      id
      name
      displayBusinessPicture
      displayInterval
      consoleImages {
        id
        path
      }
    }
  }
`;

export const SAVE_CONSOLE_IMAGES = gql`
  mutation SaveConsoleImages($businessConsoleImagesInput: BusinessConsoleImagesInput!) {
    uploadBusinessConsoleImages(businessConsoleImagesInput: $businessConsoleImagesInput) {
      isSuccess
      error
    }
  }
`;
export const SAVE_BUSINESS = gql`
  mutation all($businessInput: BusinessInput!) {
    saveBusiness(businessInput: $businessInput) {
      isSaved
      errorMessage
      errors
    }
  }
`;

export const REMOVE_CONSOLE_IMAGES = gql`
  mutation RemoveConsoleImages($businessId: Int, $pictureId: Int) {
    deleteBusinessConsoleImage(businessId: $businessId, pictureId: $pictureId) {
      error
      isSuccess
    }
  }
`;







