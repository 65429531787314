import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allowChatSLB: null,
    searchRadii: [],
};

const consumerSlice = createSlice({
    name: 'business',
    initialState,
    reducers: {
        setAllowChatSLB(state, action) {
            state.allowChatSLB = action?.payload;
        },
        setSearchRadii(state, action) {
            state.searchRadii = action?.payload;
        },
    },
});

export const { setAllowChatSLB, setSearchRadii } = consumerSlice.actions;
export default consumerSlice.reducer;
