import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { toast as showToast } from 'react-toastify';
import { GET_AVAILABLE_SLOTS } from './requests';

export const useData = ({ service, date, serviceProvider, currentSlot, adults }) => {
  const { businessId } = useParams();

  const business = parseInt(businessId);

  const [slots, setSlots] = useState([]);

  const [onLoadSlots, { loading: isLoading, error }] = useLazyQuery(
    GET_AVAILABLE_SLOTS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        service,
        appointmentDate: date,
        adults: adults ? parseInt(adults) : 0,
        business,
        appointmentWindow: 'DAY',
        subBusinesses:
          !serviceProvider || parseInt(serviceProvider) === parseInt(business)
            ? []
            : [serviceProvider],
      },
      onCompleted: ({ getAvailability: slots }) => {
        let dumpArray = [];
        const hasOtherSlotsAvailable = !isEmpty(slots.others);
        if (!isEmpty(slots.first)) dumpArray.push(slots.first);
        if (!isEmpty(slots.second)) dumpArray.push(slots.second);
        if (!isEmpty(slots.third)) dumpArray.push(slots.third);
        if (!isEmpty(slots.fourth)) dumpArray.push(slots.fourth);
        if (!isEmpty(slots.fifth)) dumpArray.push(slots.fifth);
        if (hasOtherSlotsAvailable) dumpArray = dumpArray.concat(slots.others);
        const uniqueSlots = Array.from(
          new Set(
            dumpArray.map((a) => a.startTimeOnly + ':' + a.subBusiness.id)
          )
        ).map((refkey) => {
          return dumpArray.find(
            (a) => a.startTimeOnly + ':' + a.subBusiness.id === refkey
          );
        });
        setSlots(uniqueSlots);
      },
    }
  );

  useEffect(() => {
    const isReadyToLoadData = (service && date) || serviceProvider;

    if (isReadyToLoadData) onLoadSlots();
  }, [service, date, serviceProvider]);

  return {
    isLoading,
    error,
    data: slots,
    onLoadSlots,
  };
};
