import React from 'react';
import { Route } from 'react-router-dom';
import {
  Section,
  Container,
  Title,
  Columns,
  Column,
} from '@safelyq/bulma-ui-library';
import {
  GeneralSetting,
  LoadingSection,
  PhoneVerification,
  AdminNavigationSection,
  AdminNotificationSetting,
} from '../../components';
import { useData, useNotificationData, useAccount } from './hooks';

export const ManageAdminSettings = () => {
  const { isLoading, refetch, data: profile } = useData();

  const { onSaveNotification, data: organizations } = useNotificationData();

  const { onProfileUpdate, isProfileLoading } = useAccount(refetch);

  const parameters = { ...profile, isProfileLoading, onProfileUpdate, ...organizations, onSaveNotification };

  return (
    <Section>
      <Container>
        <Title size='5'>User Account Settings</Title>
        <Columns>
          <Column size='3'>
              <AdminNavigationSection />
          </Column>
          {isLoading ? (
            <Column size='9'>
              <LoadingSection />
            </Column>
          ) : (
            <Column size='9'>
              <Route
                exact
                path='/admin/account/setting'
                render={(props) => (
                  <GeneralSetting {...props} {...parameters} />
                )}
              />
              <Route
                exact
                path='/admin/account/notifications'
                render={(props) => (
                  <AdminNotificationSetting {...props} {...parameters} />
                )}
              />
              <Route
                exact
                path='/admin/account/verify-phone'
                render={(props) => (
                  <PhoneVerification {...props} {...parameters} />
                )}
              />
            </Column>
          )}
        </Columns>
      </Container>
    </Section>
  );
};
