import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Box, Title, Subtitle } from '@safelyq/bulma-ui-library';

export const LoadingSection = () => {
  return (
    <Box>
      <Title size='5'>
        <Skeleton count={1} width={300} height={20} />
      </Title>
      <Subtitle size='6' color='grey-light'>
        <Skeleton count={1} width={200} height={20} />
      </Subtitle>
      <hr />
      <Skeleton count={1} height={20} />
    </Box>
  );
};
