import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { isEmpty, isEqual } from 'lodash';
import { useParams, useLocation } from 'react-router-dom';
import StripeCheckout from 'react-stripe-checkout';
import { Section, Container, Buttons, Button, Label, Title, Icon, Columns, Column } from '@safelyq/bulma-ui-library';
import { useCreateCampaign, useSaveCampaign, useCalculateAudiance, useData, useStripePayment, useSingleCampaign } from './hooks';
import { PromotionModal, PromotionModalTileView, CampaignGroup, CampaignSlot, CampaignSummary, CampaignTitle, TargetAudience, MessageDetails } from './components';
import { isGSMAlphabet } from 'methods/isGSMAlphabet';
import { Loader } from '@safelyq/base-feature';
import { toast } from 'react-toastify';


export const AddCampaign = () => {

  const currentDate = moment().format('YYYY-MM-DD');
  const currentTime = moment().add(10, 'minutes').format('HH:mm');

  let { businessId } = useParams();

  businessId = parseInt(businessId);

  const { state } = useLocation();
  const { campaignId: id } = state || {}

  // Fetch data of Single Campaign
  const { singleCampaignLoading, singleCampaignData } = useSingleCampaign(id);

  const [campaign, setCampaign] = useState({
    title: "",
    details: "",
    existingBusinessAttachment: null,
    existingStartDateTime: null,
    existingEndDateTime: null,
    budget: 0,
    zipCodes: null,
    existingCampaignGroups: null,
    existingCampaignTargetTimes: null,
    existingBusinessCoupon: null,
    attachmentId: null,
    startDate: currentDate,
    endDate: currentDate,
    startTime: currentTime,
    endTime: "23:59",
    isZipCode: true,
    isFile: false,
    isClipFile: null,
    campaignGroups: [],
    campaignImages: [],
    messageTypes: [],
  });

  // Set Data to state
  useEffect(() => {
    if (singleCampaignData && singleCampaignData?.title !== "" && campaign?.title === "") {
      setCampaign({
        title: singleCampaignData?.title || "",
        details: singleCampaignData?.details || "",
        existingBusinessAttachment: singleCampaignData?.businessAttachment,
        existingStartDateTime: singleCampaignData?.startDateTime,
        existingEndDateTime: singleCampaignData?.endDateTime,
        budget: singleCampaignData?.businessAttachment ? 0 : singleCampaignData?.budget || 0,
        zipCodes: singleCampaignData?.zipCodes,
        existingCampaignGroups: singleCampaignData?.campaignGroups || null,
        existingCampaignTargetTimes: singleCampaignData?.campaignTargetTimes || null,
        existingBusinessCoupon: singleCampaignData?.businessCoupon || null,
        attachmentId: singleCampaignData?.businessAttachment?.id || null,
        startDate: singleCampaignData?.startDateTime ? moment(singleCampaignData?.startDateTime).format('YYYY-MM-DD') : currentDate,
        endDate: singleCampaignData?.endDateTime ? moment(singleCampaignData?.endDateTime).format('YYYY-MM-DD') : currentDate,
        startTime: singleCampaignData?.startDateTime ? moment(singleCampaignData?.startDateTime).format('HH:mm') : currentTime,
        endTime: singleCampaignData?.endDateTime ? moment(singleCampaignData?.endDateTime).format('HH:mm') : moment('23:59', 'HH:mm').format('HH:mm'), // Set end time to 11:59 pm by default
        isZipCode: singleCampaignData?.businessAttachment === null ? true : false, // if zipcodes are 0 and businessAttachment exist then turn state false
        isFile: singleCampaignData?.businessAttachment && true,
        isClipFile: null,
        campaignGroups: [],
        campaignImages: singleCampaignData?.campaignImages,
        messageTypes: [],
      })
    }
  }, [singleCampaignData])

  const today = moment(new Date()).format('YYYY-MM-DD');

  const { isLoading, onCreateCampaign, campaignData } = useCreateCampaign();

  const { isPaymentRequired, amount, bonus } = campaignData;

  const { isSaving, onSaveCampaign } = useSaveCampaign();

  const { onCalculateAudience, data, isCalculateLoading } = useCalculateAudiance();

  const { costInDollar = 0, totalEmailCost = 0, totalWhatsAppCost = 0, totalSMSCost = 0, totalMMSCost = 0, usersCost = 0 } = data;

  const { data: Data, isDataLoading, getSafelyQLegalTexts, userEmail } = useData();

  const { safelyQCampaignGroups, campaignTargetTimes: safelyQCampaignTargetTimes, businessSubscription, businessAttachments, timeZone, marketingCampaignTemplates } = Data;

  const { remainingBonus: currentRemainingBonus = 0 } = businessSubscription || {}

  const legalText = getSafelyQLegalTexts?.find(x => x.legalTextFor === "CreateCampaignPage").legalText

  const [bonusRemain, setBonusRemain] = useState()

  const [youllPay, setYoullPay] = useState();

  const [customSlotValidation, setCustomSlotValidation] = useState({ status: false, message: '', show: false });

  const [campaignTargetTimes, setCampaignTargetTimes] = useState([]);

  // For Custom Message
  const [selectedMessageType, setSelectedMessageType] = useState({ name: "All", isSelected: true });

  const [sMS, setSMS] = useState({
    sMSSegment: 0,
    sMSPriceInCents: 0,
    maxCharacter: 0,
    templateCharacter: 0,
  });

  const [campaignFiles, setCampaignFiles] = useState([]);

  const [businessFiles, setBusinessFiles] = useState([]);

  useEffect(() => {
    // Only if campaign groups are empty populate campaignGroups
    if (campaign?.campaignGroups?.length === 0 && safelyQCampaignGroups && safelyQCampaignGroups?.length !== 0) {
      let newArray = safelyQCampaignGroups.map(
        ({ name, priceInCents, maxCharacter, isSelected }) => {
          return { name, priceInCents, isSelected };
        },
      );
      setCampaign({
        ...campaign,
        campaignGroups: newArray,
      })
    }

    // Populate SMS
    if (safelyQCampaignGroups && safelyQCampaignGroups?.length !== 0) {
      let foundIndex = safelyQCampaignGroups.findIndex(i => i.name === "SMS");
      setSMS((sMS) => ({ ...sMS, sMSPriceInCents: safelyQCampaignGroups[foundIndex].priceInCents }));

      safelyQCampaignGroups.forEach(({ name, maxCharacter }) => {
        if (name === 'SMS') {
          setSMS((sMS) => ({ ...sMS, maxCharacter }));
        }
      });
    }

    if (safelyQCampaignTargetTimes && safelyQCampaignTargetTimes?.length !== 0 && campaignTargetTimes && campaignTargetTimes?.length === 0) {
      campaignTargetTimes?.length === 0 && setCampaignTargetTimes(safelyQCampaignTargetTimes)
    }

    if (businessAttachments) {
      setBusinessFiles(businessAttachments)
    }

    // If existing campaign exists, then populate campaign groups with it otherwise with the safelyQCampaignGroups
    if (campaign?.existingCampaignGroups && campaign?.existingCampaignGroups?.length !== 0) {

      // If campaign.details is not empty that means it is for all, hence fill all the campaign types
      if (campaign?.details !== "") {
        const dummyCampaign = campaign?.existingCampaignGroups.map(camp => {
          if (camp.isSelected) {
            return camp = {
              ...camp,
              messageText: campaign?.details
            }
          }
          else {
            return camp = {
              ...camp,
              messageText: ""
            }
          }
        })
        setCampaign({
          ...campaign,
          campaignGroups: dummyCampaign,
        })
      } else {
        setCampaign({
          ...campaign,
          campaignGroups: campaign?.existingCampaignGroups,
        })
      }
    }

  }, [safelyQCampaignGroups, safelyQCampaignTargetTimes, businessAttachments, campaign?.existingCampaignGroups])

  // Will only render when campaign details and campaign business coupon will change
  useEffect(() => {
    // if id exists means it is existing campaign
    if (id) {
      if (campaign?.existingBusinessCoupon) {
        coupon?.id === null && setSelectedCoupon(true) // means once coupon is populated do not set value again
        coupon?.id === null && setCoupon(campaign?.existingBusinessCoupon)
      }
      else {
        setSelectedCoupon(false)
      }

      // Set CAmpaign files for the first time only
      if (campaignFiles?.length === 0 && campaign?.campaignImages?.length !== 0) {
        setCampaignFiles(prevState => ({
          ...prevState,
          c: campaign?.campaignImages
        }))
      }
    }
  }, [campaign?.details, campaign?.existingBusinessCoupon])

  useEffect(() => {
    // Set campaign target times if the campaign already exists
    if (campaignTargetTimes?.length === 0 && campaign?.existingCampaignTargetTimes && campaign?.existingCampaignTargetTimes?.length !== 0) {
      setCampaignTargetTimes(campaign?.existingCampaignTargetTimes)
    }
  }, [campaign?.existingCampaignTargetTimes])

  // Will only render only the first time when campaign groups will change so set the state
  useEffect(() => {
    if (campaign?.campaignGroups && campaign?.campaignGroups?.length !== 0 && campaign?.messageTypes.length === 0 && campaign?.isClipFile === null) {
      // Find if mms is selected
      let findPlatform = campaign?.campaignGroups?.find(x => x.name === 'MMS' && x.isSelected === true);

      // Find if images exist
      const newFiles = campaign?.campaignImages?.map((file) => {
        const newfile = {
          fileName: file?.fileName,
          contentType: file?.contentType,
          url: file?.publicUrl,
          id: file?.id,
          fileSize: file?.fileSize
        }
        return newfile;
      })
      setCampaignFiles(newFiles)
    }

    // Update the message types array along with the campaign groups
    if (campaign?.campaignGroups && campaign?.campaignGroups?.length !== 0) {
      let arraangedArray = [];
      let namesInOrder = ['MMS', 'SMS', 'WhatsApp', 'Email'];

      for (let name of namesInOrder) {
        let item = campaign?.campaignGroups.find(obj => obj?.name === name);
        if (item) {
          // set sms selected by default
          if (item?.name === "SMS") {
            arraangedArray.push(item);
          }
          else {
            arraangedArray.push(item);
          }
        }
      }
      setCampaign({
        ...campaign,
        messageTypes: arraangedArray
      })
    }
  }, [campaign?.campaignGroups])

  const [coupon, setCoupon] = useState({
    id: null,
  })

  const [zipCodeList, setZipCodeList] = useState([]);
  const [isEmptyNotZipCode, setIsEmptyNotZipCode] = useState();

  useEffect(() => {
    campaign?.zipCodes && setZipCodeList(campaign?.zipCodes);
  }, [campaign?.zipCodes]);

  useEffect(() => {
    setIsEmptyNotZipCode(zipCodeList?.length > 0);
  }, [zipCodeList]);

  const handleZipCodeAdd = (event) => {
    const {
      target: { value },
      key,
    } = event;

    if (key === 'Enter' && value) {
      event.preventDefault();
      setZipCodeList(zipCodeList.concat(value));

      // empty the state after entering and allow to enter the budget 
      setCampaign({
        ...campaign,
        isZipCode: true,
        zipCode: ""
      })

    }
  };

  const handleRemoveZipCode = ({ index }) => {
    setZipCodeList((current) =>
      current.filter((_, indx) => indx !== index)
    );
  };

  const [areLegalTermsAccepted, setLegalTermsAccepted] = useState(false)
  const [audienceButtonDisable, setAudienceButtonDisable] = useState(false);
  const [saveButtonDisable, setSaveButtonDisable] = useState(true);

  const [initialCampaign, setInitialCampaign] = useState();
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  const [nonce] = useState(null);
  const [tokenId, setTokenId] = useState(null);
  useEffect(() => {
    if (tokenId !== null) handleCreateCampaign();
  }, [tokenId]);

  // const [showBraintreeDropIn, setShowBraintreeDropIn] = useState(false);
  // const [braintreeInstance, setBraintreeInstance] = useState(undefined);
  // const onBraintreeRequestCompleted = () => {
  //   setShowBraintreeDropIn(false);
  // };

  // const {
  //   clientToken,
  //   invokingBraintreeRequest
  // } = useBraintreePayment(onBraintreeRequestCompleted);

  // useEffect(() => {
  //   if (showBraintreeDropIn && clientToken) {
  //     const initializeBraintree = () => dropin.create({
  //       authorization: clientToken,
  //       container: '#braintree-drop-in-div',
  //     }, function (error, instance) {
  //       if (error)
  //         console.error(error)
  //       else {
  //         setBraintreeInstance(instance);
  //       }
  //     });

  //     if (braintreeInstance) {
  //       braintreeInstance
  //         .teardown()
  //         .then(() => {
  //           initializeBraintree();
  //         });
  //     } else {
  //       initializeBraintree();
  //     }
  //   }
  // }, [showBraintreeDropIn, clientToken])

  const [showStripeDropIn, setShowStripeDropIn] = useState(false);
  const {
    publishableKey,
  } = useStripePayment();

  const handleToken = (token) => {
    setTokenId(token.id);
  };

  useEffect(() => {
    // setShowBraintreeDropIn(isPaymentRequired)
    setShowStripeDropIn(isPaymentRequired);
  }, [isPaymentRequired])

  // Check if Custom Message is selected or Coupon
  const handleRadioButtonChange = (e) => {
    setSelectedCoupon(e.target.value === "true" ? true : false);
  };

  const [modal, setModal] = useState({
    isOpen: false,
    businessCouponId: null,
  });

  const handleModalOpen = (businessId) =>
    setModal({ isOpen: true, businessCouponId: businessId });

  const handleModalClose = () =>
    setModal({ ...modal, isOpen: false });

  const [tileView, setTileView] = useState({
    isOpen: false,
    businessCouponId: null,
  });

  const handleTileViewModalOpen = (businessId) => {
    setTileView({ isOpen: true, businessCouponId: businessId });
  }

  const handleTileViewModalClose = () => {
    setTileView({ ...tileView, isOpen: false });
  }

  const handleViewChange = () => {
    if (window.innerWidth > 450) {
      handleModalOpen();
    } else {
      handleTileViewModalOpen();
    }
  }

  const isSelected = (index) => {
    return index.isSelected
  }

  const isAudienceButtonEnable = () => {
    const { budget, endTime, startTime, attachmentId } = campaign;
    const isValidZipCodeFile = campaign?.isZipCode ? (isEmptyNotZipCode && budget > 0) : attachmentId !== 0;
    if (isValidZipCodeFile && campaign?.startDate >= today && campaign?.endDate >= campaign?.startDate && campaign?.campaignGroups?.some(isSelected) && campaignTargetTimes?.some(isSelected))
      if (!(campaign?.startDate === campaign?.endDate && startTime === endTime)) {
        return true;
      }
      else {
        return false
      };
  };

  const isButtonEnable = () => {
    const { title, budget, details, attachmentId } = campaign;
    const { totalTargetAudience } = data;
    const isValidZipCodeFile = campaign?.isZipCode ? (isEmptyNotZipCode && budget > 0) : attachmentId !== 0;

    // Valid Custom SLot Validation
    if (customSlotValidation?.status) {
      return false;
    }

    // check for campaign message text
    let campaignCheck = false;

    // Custom Text Validation For all tab
    if (selectedMessageType.name === "All") {
      if (isEmpty(details)) {
        campaignCheck = false;
      }
      else {
        campaignCheck = true;
      }
    }
    else {
      // For Campaign Groups
      // If message text is empty or null and selected then disable the run campaign button
      const selectedCampaignTypes = campaign?.campaignGroups.filter(campaign => campaign?.isSelected && (campaign?.messageText === null || campaign?.messageText?.trim() === ""));
      if (selectedCampaignTypes.length > 0) {
        campaignCheck = false;
      }
      else {
        campaignCheck = true
      }
    }

    if (coupon.id !== null || campaignCheck) {
      if (!isEmpty(title) && isValidZipCodeFile && campaign.startDate >= today && campaign.endDate >= campaign.startDate && areLegalTermsAccepted) {
        if (totalTargetAudience > 0) {
          if (audienceButtonDisable) {
            return true;
          }
          else { return false; }
        }
      }
    } else {
      return false;
    }
  };

  const isFormFilled = () => {
    const { title, budget, details, attachmentId } = campaign;
    const isValidZipCodeFile = campaign?.isZipCode ? (isEmptyNotZipCode && budget > 0) : attachmentId !== 0;

    // if details or message text in the campaign groups is not empty then check other field and disable text
    if (coupon.id !== null || (!isEmpty(details) || campaign?.campaignGroups?.find(camp => camp.isSelected && camp.messageText)?.messageText.trim() !== "")) {
      if (!isEmpty(title) && isValidZipCodeFile && campaign?.startDate >= today && campaign?.endDate >= campaign?.startDate && !customSlotValidation?.show) {
        // Fields are not empty, do not show validation text
        return true;
      }
      else {
        // Show validation text
        return false
      };
    }

  };

  const handleCampaignChangeGsm = ({ target: { name, value } }) => {
    if (!isGSMAlphabet(value) && selectedMessageType.name === "SMS") return;

    if (name === "All") {
      const isSelected = campaign?.campaignGroups?.filter(camp => camp?.isSelected);
      if (isSelected.length === 0) {
        return;
      }

      // Turn all other campaign groups to null
      let dummy = [...campaign?.campaignGroups];
      const updatedData = dummy.map((campaign) => {
        return { ...campaign, messageText: "" };
      });
      setCampaign({
        ...campaign,
        details: value,
        campaignGroups: updatedData,
      })
      return;
    }

    // Add Text of relevant message type inside of the campaign groups
    let dummy = [...campaign?.campaignGroups];
    const updatedData = dummy.map((campaign, index) => {
      if (campaign?.name === name) {
        return { ...campaign, messageText: value };
      }
      return campaign;
    });

    setCampaign({
      ...campaign,
      campaignGroups: updatedData,
      details: ""
    })
  };

  const startDateTime = `${moment(campaign?.startDate).format('YYYY-MM-DD')} ${campaign?.startTime}`;
  const endDateTime = `${moment(campaign?.endDate).format('YYYY-MM-DD')} ${campaign?.endTime}`;

  const handleCampaignGroup = ({ target: { name, checked } }, cb) => {
    let isClipFile = false;
    cb();
    let updatedCampaignGroups = [...campaign?.campaignGroups];

    // set this type to selected message type
    setSelectedMessageType({ name: name, isSelected: true })

    // Find the index of the current campaign
    let currentIndex = updatedCampaignGroups.findIndex((i) => i.name === name);

    // Change the isSelected Status and delete the message
    if (currentIndex !== -1) {
      updatedCampaignGroups[currentIndex].isSelected = checked;
    }

    // Set isSelected of the MMS campaign to false if SMS is selected and vise versa
    let mmsIndex = updatedCampaignGroups.findIndex((i) => i.name === "MMS");
    let smsIndex = updatedCampaignGroups.findIndex((i) => i.name === "SMS");
    if (name === "SMS" && checked) {

      if (mmsIndex !== -1) {
        updatedCampaignGroups[mmsIndex].isSelected = false;

        // set the text of mms to sms and empty mms
        // if (updatedCampaignGroups[mmsIndex].messageText && updatedCampaignGroups[mmsIndex].messageText.trim() !== "") {
        //   updatedCampaignGroups[smsIndex].messageText = updatedCampaignGroups[mmsIndex].messageText;
        //   updatedCampaignGroups[mmsIndex].messageText = "";
        // }
        isClipFile = false;
      }
    } else if (name === "MMS" && checked) {

      if (smsIndex !== -1) {
        updatedCampaignGroups[smsIndex].isSelected = false;

        // set the text of sms to mms and empty sms
        // if (updatedCampaignGroups[smsIndex].messageText && updatedCampaignGroups[smsIndex].messageText.trim() !== "") {
        //   updatedCampaignGroups[mmsIndex].messageText = updatedCampaignGroups[smsIndex].messageText;
        //   updatedCampaignGroups[smsIndex].messageText = "";
        // }
        isClipFile = true;
      }
    }

    // if any message type is unselected then change the selectedMessagetype to all
    if (!checked) {
      setSelectedMessageType({ name: "All", isSelected: true })
    }

    setCampaign({
      ...campaign,
      campaignGroups: updatedCampaignGroups,
      isClipFile: isClipFile,
    })
  }

  const compareCampaignInfo = () => {
    let currentCampaignInfo = {
      businessId,
      zipCodes: campaign?.isZipCode ? zipCodeList : [],
      budget: campaign?.isZipCode ? parseFloat(campaign?.budget).toFixed(2) : 0.00,
      campaignGroups: initialCampaign?.campaignGroups,
      campaignTargetTimes,
      details: campaign?.details,
      endDateTime,
      startDateTime,
      attachmentId: campaign?.isFile ? campaign?.attachmentId : 0,
      campaignFiles
    };

    function compareIsSelected(array1, array2) {
      return array1.every((object, i) => {
        return object.isSelected === array2[i].isSelected;
      });
    }

    if (initialCampaign) {
      if (compareIsSelected(initialCampaign.campaignGroups, campaign?.campaignGroups) &&
        compareIsSelected(initialCampaign.campaignTargetTimes, campaignTargetTimes) &&
        isEqual(currentCampaignInfo, initialCampaign)) {
        if (data.isSuccess) {
          setAudienceButtonDisable(true);
        } else { setAudienceButtonDisable(false) }
      } else {
        setAudienceButtonDisable(false)
      }
    }
  }

  const checkSaveable = () => {
    if (campaign?.title) setSaveButtonDisable(false); else setSaveButtonDisable(true)
  }

  useEffect(() => {
    checkSaveable()
    compareCampaignInfo();
  }, [campaign, initialCampaign, campaign?.campaignGroups, campaignTargetTimes, zipCodeList, campaignFiles]);

  // You will Pay amount calculating function
  const handleYouWillPay = useCallback((value) => {
    let payable;
    // budget is greater than bonus
    if (parseFloat(currentRemainingBonus - value) < 0) {
      payable = (value - currentRemainingBonus).toFixed(2);
      if (payable < 5) {
        // charge 5 and set remaining to bonus
        // setBonus(5 - payable);
        payable = 5;
      }
      // Else keep the same amount
    }
    // Remaining bonus is greater than budget
    else {
      const bonusRemaining = (currentRemainingBonus - value).toFixed(2);
      // payable = (currentRemainingBonus - value).toFixed(2);

      // No need to pay as covered in existing bonus
      if (parseFloat(bonusRemaining) >= 0) {
        // if (parseFloat(bonusRemaining) !== 0 && parseFloat(payable) < 5) {
        // setBonus(5 - payable);
        payable = 0;
        // payable = 5;
      }
      // else {
      //   // No need to pay as covered in existing bonus
      //   payable = 0
      // }
    }
    setYoullPay(payable)
  }, [currentRemainingBonus]);

  // Cost in dollar only comes when we give file
  useEffect(() => {
    if (campaign?.isFile && costInDollar > 0) {
      setBonusRemain(parseFloat(currentRemainingBonus - parseFloat(costInDollar) > 0 ? currentRemainingBonus - parseFloat(costInDollar) : 0).toFixed(2))
      // setYoullPay(parseFloat(currentRemainingBonus - parseFloat(costInDollar) < 0 ? parseFloat(costInDollar) - currentRemainingBonus : 0).toFixed(2))

      // Calculate the amount that user will pay
      handleYouWillPay(parseFloat(costInDollar))
    }
  }, [costInDollar, campaign?.isFile, currentRemainingBonus, handleYouWillPay]);

  const handleCreateCampaign = () => {

    let newCampaignGroups = campaign?.campaignGroups.map(({
      maxCharacter, ...rest
    }) => { return rest })

    const updatedCampaignGroups = newCampaignGroups.map(campaign => {
      const newCampaign = {
        ...campaign,
        messageText: "", // empty message text when the coupon is selected
      }
      return newCampaign;
    })

    let campaignInput = {
      id: id ? id : 0,
      businessId,
      title: campaign?.title,
      details: selectedCoupon ? "" : campaign?.details,
      businessCouponId: selectedCoupon ? coupon.id : null,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      targetSlotAudience: data.targetSlotAudience,
      totalTargetAudience: data.totalTargetAudience,
      campaignTargetTimes: campaignTargetTimes,
      campaignGroups: selectedCoupon ? updatedCampaignGroups : newCampaignGroups,
      perUserCost: data?.perUserCost,
      // budget: campaign?.isZipCode ? parseFloat(campaign?.budget).toFixed(2) : 0,
      budget: campaign?.isZipCode ? parseFloat(campaign?.budget).toFixed(2) : parseFloat(costInDollar).toFixed(2),
      zipCodes: campaign?.isZipCode ? zipCodeList : [],
      isPaymentRequired: isPaymentRequired,
      chargeAmount: youllPay,
      // amount: youllPay,
      amount: amount,
      nonce: nonce,
      tokenId: tokenId,
      bonus: bonus,
      campaignDetails: data?.campaignDetails,
      attachmentId: campaign?.isFile ? campaign?.attachmentId : 0,
      attachments: selectedCoupon ? [] : campaignFiles?.length !== 0 ? campaignFiles : [],
    };
    onCreateCampaign({
      variables: {
        campaignInput,
      },
    });
  };

  const handleSaveCampaign = () => {
    const updatedCampaignGroups = campaign?.campaignGroups.map(campaign => {
      const newCampaign = {
        ...campaign,
        messageText: "", // empty message text when the coupon is selected
      }
      return newCampaign;
    })

    let campaignInput = {
      id: id ? id : 0,
      businessId,
      title: campaign?.title,
      details: selectedCoupon ? "" : campaign?.details,
      businessCouponId: selectedCoupon ? coupon.id : null,
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      targetSlotAudience: data.targetSlotAudience,
      totalTargetAudience: data.totalTargetAudience,
      campaignTargetTimes: campaignTargetTimes,
      campaignGroups: selectedCoupon ? updatedCampaignGroups : campaign?.campaignGroups,
      budget: campaign?.isZipCode ? parseFloat(campaign?.budget).toFixed(2) : parseFloat(costInDollar).toFixed(2),
      zipCodes: campaign?.isZipCode ? zipCodeList : [],
      isPaymentRequired: isPaymentRequired,
      amount: amount,
      nonce: nonce,
      tokenId: tokenId,
      attachmentId: campaign?.isFile ? campaign?.attachmentId : 0,
      // If coupon selected then do not save campaign files
      attachments: selectedCoupon ? [] : campaignFiles?.length !== 0 ? campaignFiles : [],
    };
    onSaveCampaign({
      variables: {
        campaignInput,
      },
    });
  };

  const handleCalculateValidation = () => {
    const isMmsCampaignSelected = campaign?.campaignGroups.some(campaign => campaign?.name === "MMS" && campaign?.isSelected === true);
    const isDetailsSelected = (selectedCoupon === false || selectedCoupon === null);

    // If MMS selected
    if (isMmsCampaignSelected) {
      // If Image selected
      if (isDetailsSelected) {
        if (campaignFiles?.length === 0) {
          toast.error("Please upload an image or select SMS");
        } else {
          handleCalculateAudience();
        }
      } else {
        // If Coupon selected
        if (!coupon?.imageUrl) {
          toast.error("Coupon does not have an image, Please select SMS if it does not have image");
        } else {
          handleCalculateAudience();
        }
      }
    }
    else {
      handleCalculateAudience();
    }
  }

  const handleCalculateAudience = async () => {
    let newArray = campaign?.campaignGroups.map(
      ({ name, priceInCents, isSelected, ...rest }) => {
        if (name === 'SMS' && isSelected) {
          priceInCents = sMS.sMSPriceInCents * sMS.sMSSegment
        }
        return { priceInCents, name, isSelected, ...rest };
      },
    );
    let campaignUsersCalculationInput = {
      businessId,
      zipCodes: campaign?.isZipCode ? zipCodeList : [],
      budget: campaign?.isZipCode ? parseFloat(campaign?.budget).toFixed(2) : 0.00,
      campaignGroups: newArray,
      campaignTargetTimes,
      endDateTime,
      startDateTime,
      attachmentId: campaign?.isFile ? campaign?.attachmentId : 0,
    };
    await onCalculateAudience({
      variables: {
        campaignUsersCalculationInput
      },
    });

    // Add File Validation if changes
    campaignUsersCalculationInput = {
      ...campaignUsersCalculationInput,
      details: campaign?.details,
      campaignFiles
      // campaignImage
    };
    const initialCampaignDeepCopy = JSON.parse(JSON.stringify(campaignUsersCalculationInput))
    setInitialCampaign((initialCampaignDeepCopy))
    if (campaign?.isZipCode) {
      // setCampaign({ ...campaign, "budget": parseFloat(campaign?.budget).toFixed(2) });
      setBonusRemain(parseFloat(currentRemainingBonus - parseFloat(campaign?.budget) > 0 ? currentRemainingBonus - parseFloat(campaign?.budget) : 0).toFixed(2));
      // setYoullPay(parseFloat(currentRemainingBonus - parseFloat(campaign.budget) < 0 ? parseFloat(campaign.budget) - currentRemainingBonus : 0).toFixed(2));

      // Calculate the amount that user will pay
      handleYouWillPay(parseFloat(campaign?.budget))
    }
    setLegalTermsAccepted(false)
  };


  // If data is loading return loader and destruct the value;
  if (singleCampaignLoading || isDataLoading) {
    return <Loader isFullScreen />;
  }

  // Props Setting
  const messageDetailsComponentProps = {
    audienceButtonDisable,
    campaign,
    setCampaign,
    coupon,
    existingBusinessCoupon: campaign?.existingBusinessCoupon,
    handleRadioButtonChange,
    isClipFile: campaign?.isClipFile,
    sMS,
    setSMS,
    selectedCoupon,
    handleCampaignChangeGsm,
    // campaignImage,
    // setcampaignImage,
    campaignFiles,
    setCampaignFiles,
    handleViewChange,
    campaignGroups: campaign?.campaignGroups,
    selectedMessageType,
    setSelectedMessageType,
    marketingCampaignTemplates: marketingCampaignTemplates
  }

  const targetAudienceComponentProps = {
    isZipCode: campaign?.isZipCode,
    // setIsZipCode,
    setCampaign,
    handleZipCodeAdd,
    handleRemoveZipCode,
    isFile: campaign?.isFile,
    // setIsFile,
    zipCodeList,
    campaign,
    currentRemainingBonus,
    isCalculateLoading,
    isAudienceButtonEnable,
    audienceButtonDisable,
    handleCalculateValidation,
    costInDollar,
    businessFiles,
    campaignTargetTimes,
    setCampaignTargetTimes
  }

  const campaignSlotComponentProps = {
    timeZone,
    existingCampaignTargetTimes: campaign?.existingCampaignTargetTimes,
    safelyQCampaignTargetTimes,
    setCampaignTargetTimes,
    campaignTargetTimes,
    customSlotValidation,
    setCustomSlotValidation
  };

  const campaignSummaryComponentProps = {
    data,
    totalWhatsAppCost,
    totalEmailCost,
    totalSMSCost,
    totalMMSCost,
    bonusRemain,
    youllPay,
    usersCost,
    amount,
  }

  return (
    <Section>
      <Container>

        <Columns multiline className='is-flex is-justify-content-start'>
          <Column className='p-1 mb-3 is-full-mobile is-full-tablet is-full-desktop is-four-fifths-widescreen is-four-fifths-fullhd'>
            <Title size='5'>
              <Icon name='bullhorn' className='mr-3' />
              Create new campaign
            </Title>
          </Column>

          <CampaignTitle
            campaign={campaign}
            setCampaign={setCampaign}
            audienceButtonDisable={audienceButtonDisable}
          />

          <Column className='p-1 is-full-mobile is-full-tablet is-full-desktop is-four-fifths-widescreen is-four-fifths-fullhd'>
            <div className='box'>
              <Columns multiline>
                <Column size='12'>
                  <Label className='has-text-info'>
                    Message Type <span className='ml-1 has-text-danger'>*</span>
                  </Label>
                </Column>
                {campaign?.messageTypes.map((group, i) =>
                  <CampaignGroup
                    group={group}
                    i={i}
                    handleCampaignGroup={handleCampaignGroup}
                    existingCampaignGroups={campaign?.existingCampaignGroups}
                  />
                )}
              </Columns>
            </div>
          </Column>

          <MessageDetails {...messageDetailsComponentProps} />

          <CampaignSlot {...campaignSlotComponentProps} />

          <TargetAudience {...targetAudienceComponentProps} />

          <CampaignSummary {...campaignSummaryComponentProps} />

          <Column className='p-1 is-full-mobile is-full-tablet is-full-desktop is-four-fifths-widescreen is-four-fifths-fullhd'>
            <div className='box'>
              <Columns multiline>
                <Column className='pb-0' size='12'>
                  <textarea
                    style={{ width: "100%", height: "60px" }}
                    className='text p-2'
                    name='legalText'
                    disabled={true}
                    value={legalText}
                  />
                </Column>
                <Column className='pb-0' size='12'>
                  <label className='checkbox'>
                    <input
                      type='checkbox'
                      name={"legal"}
                      onChange={(e) => { setLegalTermsAccepted(!areLegalTermsAccepted) }}
                      checked={areLegalTermsAccepted}
                    />
                    <span className='ml-2'>I Accept</span>
                  </label>
                </Column>
              </Columns>
            </div>
          </Column>

          <Column className='p-1 pb-0 mt-3 is-full-mobile is-full-tablet is-full-desktop is-four-fifths-widescreen is-four-fifths-fullhd'>
            <Buttons className='is-align-items-flex-start' size='small'>
              <Button
                color='info'
                loading={isSaving}
                disabled={saveButtonDisable}
                onClick={handleSaveCampaign}
              >
                Save Campaign
              </Button>
              <div>
                <Button
                  className='mb-0'
                  color='info'
                  loading={isLoading}
                  disabled={!isButtonEnable()}
                  onClick={(e) => { handleCreateCampaign() }}
                >
                  Run Campaign
                </Button>
                {
                  !audienceButtonDisable ?
                    <UserHint>Please Click On Calculate {(campaign?.isFile && campaign?.attachmentId !== null && campaign?.attachmentId !== "0") ? "Cost" : "Audience"} </UserHint> :
                    // <UserHint>Please Click On Calculate {(campaign?.businessAttachment !== 0 && campaign?.businessAttachment !== null) ? "Cost" : "Audience"} </UserHint> :
                    !isFormFilled() && audienceButtonDisable ? <UserHint>Please Fill The Required Fields</UserHint> :
                      isFormFilled() && !areLegalTermsAccepted ? <UserHint>Please Click On I Accept To Run Campaign</UserHint> :
                        (
                          <p className='mb-4'>
                          </p>
                        )}
              </div>
              {showStripeDropIn ? (
                <StripeCheckout
                  token={handleToken}
                  stripeKey={publishableKey && publishableKey}
                  amount={amount * 100}
                  email={userEmail && userEmail}
                />
              ) : null}
            </Buttons>
          </Column>
        </Columns>
        <>
          <div className='is-hidden-mobile'>
            {modal.isOpen && (
              <PromotionModal
                isOpen={modal.isOpen}
                onClose={handleModalClose}
                data={Data}
                setState={setCoupon}
              />
            )}
          </div>
          <div className='is-hidden-tablet'>
            <PromotionModalTileView
              isOpen={tileView.isOpen}
              onClose={handleTileViewModalClose}
              data={Data}
              setState={setCoupon}
            />
          </div>

          {/* <PaymentModal 
            isOpen={showBraintreeDropIn} 
            onCloseModal={()=>{setShowBraintreeDropIn(false)}} 
            setNonce={setNonce}
            braintreeInstance={braintreeInstance}
            invokingBraintreeRequest={invokingBraintreeRequest}
            setCampaignData={setCampaignData}
          /> */}

        </>
      </Container>
    </Section >
  );
};

const UserHint = styled.span.attrs(() => ({
  className: 'help is-danger',
}))`
  font-size: 10px;
  padding-inline: 2px;
}
`;