import { gql } from '@apollo/client';

export const GET_BUSINESS_BY_ID = gql`
  query GetBusinessById($businessId: Int) {
    getBusinessById(id: $businessId, showAll: true) {
      id
      businessSubLevels {
        id
      }
    }
  }
`;
