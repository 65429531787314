import React from 'react';
import { bool, oneOf } from 'prop-types';
import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = ['']
    .concat(
      ['spaced']
        .map((name) => (props[name] ? `is-marginless` : null))
        .filter((className) => !!className)
    )
    .join(' ');

  return classNames;
};

const H1Heading = styled.h1.attrs((props) => ({
  className: className`${props}`,
}))``;

const H2Heading = styled.h2.attrs((props) => ({
  className: className`${props}`,
}))``;

const H3Heading = styled.h3.attrs((props) => ({
  className: className`${props}`,
}))``;

const H4Heading = styled.h4.attrs((props) => ({
  className: className`${props}`,
}))``;

const H5Heading = styled.h5.attrs((props) => ({
  className: className`${props}`,
}))``;

const H6Heading = styled.h6.attrs((props) => ({
  className: className`${props}`,
}))``;

const Heading = ({ size, children, ...props }) => {
  switch (size) {
    case '1':
      return (
        <H1Heading size={size} {...props}>
          {children}
        </H1Heading>
      );

    case '2':
      return (
        <H2Heading size={size} {...props}>
          {children}
        </H2Heading>
      );

    case '3':
      return (
        <H3Heading size={size} {...props}>
          {children}
        </H3Heading>
      );

    case '4':
      return (
        <H4Heading size={size} {...props}>
          {children}
        </H4Heading>
      );

    case '5':
      return (
        <H5Heading size={size} {...props}>
          {children}
        </H5Heading>
      );

    case '6':
      return (
        <H6Heading size={size} {...props}>
          {children}
        </H6Heading>
      );

    default:
      throw new Error(`heading of size ${size} is not supported`);
  }
};

Heading.propTypes = {
  size: oneOf(['1', '2', '3', '4', '5', '6']).isRequired,
  color: oneOf([
    'white',
    'black',
    'light',
    'dark',
    'primary',
    'info',
    'link',
    'success',
    'warning',
    'danger',
    'black-bis',
    'black-ter',
    'grey-darker',
    'grey-dark',
    'grey',
    'grey-light',
    'grey-lighter',
    'white-ter',
    'white-bis',
  ]),
  spaced: bool,
};

Heading.defaultProps = {
  color: undefined,
  spaced: undefined,
};

export default Heading;
