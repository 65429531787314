import { gql } from '@apollo/client';

export const SAVE_NOTIFICATION_TEMPLATE = gql`
  mutation ($notificationTemplateInput: NotificationTemplateInput!) {
  saveNotificationTemplate(notificationTemplateInput: $notificationTemplateInput) {
    isSaved
    errorMessage
    notificationTemplateResource
    notificationTemplate {
      value
    }
  }
}
`;
