import { func, oneOf, string } from 'prop-types';
import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = [
    'input'
  ]
    .concat(
      [
        'size'
      ]
        .map((name) => props[name] ? `is-${props[name]}` : null)
        .filter((className) => !!className)
    )
    .join(' ');

  return classNames;
};

const SearchInput = styled.input.attrs(({ placeholder, defaultValue, value, onChange, ...props }) => ({
  className: className`${props}`,
  type: 'search',
  placeholder,
  defaultValue,
  value,
  onChange
}))``;

SearchInput.propTypes = {
  size: oneOf(
    [
      'small',
      'medium',
      'large'
    ]
  ),
  placeholder: string,
  defaultValue: string,
  value: string,
  onChange: func
};

SearchInput.defaultProps = {
  size: undefined,
  placeholder: undefined,
  defaultValue: undefined,
  value: undefined,
  onChange: undefined
};

export default SearchInput;
