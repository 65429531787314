import React from 'react'
import { Title } from 'libraries/bulma-ui-library'

const SelectedQuestions = ({ selectedQuestionsarr, handleInputChange2, allowForm }) => {
    //   is-flex is-align-items-center
    return (
        <div className='col-12 box'>
            <Title size='6' className='mb-4'>Assigned Questions / fields</Title>
            {selectedQuestionsarr.map((SQ, index) => {
                return (
                    <div className='row mb-3'>
                        <div className='col-lg-1'>
                            <p>{index + 1}):</p>
                        </div>
                        <div className='col-lg-3'>
                            <Title size='6' className='mb-4'>{SQ.name}</Title>
                        </div>
                        <div className='col-lg-3 is-flex is-align-items-center' style={{ width: "fit-content" }}>
                            <input id={`${SQ.name}`} onChange={(e) => handleInputChange2(e, index)} className='mr-2' checked={SQ.isSelected} name="isSelected" type="checkbox" disabled={!allowForm} />
                            <label htmlFor={`${SQ.name}`} className="is-clickable">Add to form</label>
                        </div>

                    </div>
                )
            })}
        </div>
    )
}

export default SelectedQuestions
