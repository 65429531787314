import React, { Fragment, useState } from 'react'
import { Modal, Icon, Title, Columns, Column, Field, Button, Control, Label } from "@safelyq/bulma-ui-library";
import Overflow from 'react-overflow-indicator';
import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';

export const UpdateCategoriesModal = (props) => {
    const { isOpenModal,
        handleModalClose,
        categoryToUpdate,
        initialCategoryData,
        setCategoryToUpdate,
        loadingUpdate,
        handleSaveBusinessCategory,
        handleDeleteCategory,
        confirmUpdate,
        setConfirmUpdate,
        loadingDelete } = props;

    const handleCategoryDetailsChange = (name, value) => {
        setCategoryToUpdate({
            ...categoryToUpdate,
            [name]: value
        })
    };

    const handleDisable = () => {
        if (initialCategoryData) {
            const isEqual = JSON.stringify(categoryToUpdate.name) === JSON.stringify(initialCategoryData.name) &&
                JSON.stringify(categoryToUpdate.description) === JSON.stringify(initialCategoryData.description) &&
                categoryToUpdate.couponAllowed === initialCategoryData?.couponAllowed &&
                categoryToUpdate.googleReserveAllowed === initialCategoryData?.googleReserveAllowed;
            return (isEqual);
        }
    }


    return (
        <Fragment>
            {/* Update Business Category Modal */}
            <Modal active={isOpenModal}>
                <Modal.Background />
                <Modal.Card className='modal-compaign'>
                    <Overflow style={{ overflow: "scroll" }}>
                        <Overflow.Content className='is-flex is-flex-direction-column'>

                            <Modal.Card.Body style={{ overflow: "hidden" }} className='modal-compaign-body'>
                                <div className='my-4 is-flex is-justify-content-space-between is-align-items-center'>
                                    <Title size='5' className='m-0'>
                                        {confirmUpdate.type.charAt(0).toUpperCase() + confirmUpdate.type.slice(1)} Category Details
                                    </Title>
                                    <div>
                                        <Icon
                                            name='times'
                                            className='is-hoverable has-text-link'
                                            onClick={() => handleModalClose()}
                                        />
                                    </div>
                                </div>

                                {
                                    // type is upadte but not confirmed than show input fields else validation - type is add than also show fields
                                    ((confirmUpdate?.type === "update" && !confirmUpdate.status) || confirmUpdate?.type === "add")
                                        ?
                                        <Columns multiline className='my-4'>
                                            <Column size='6'>
                                                <Field>
                                                    <Control>
                                                        <Label className='has-text-info'>
                                                            Name
                                                        </Label>
                                                        <input
                                                            className={`input`}
                                                            value={categoryToUpdate?.name}
                                                            disabled={initialCategoryData?.name}
                                                            onChange={(e) => handleCategoryDetailsChange('name', e.target.value)} // if initial value is null means add category is executed
                                                        />
                                                    </Control>
                                                </Field>
                                            </Column>
                                            <Column size='6'>
                                                <Field>
                                                    <Control>
                                                        <Label className='has-text-info'>
                                                            Description
                                                        </Label>
                                                        <input
                                                            className={`input`}
                                                            value={categoryToUpdate?.description}
                                                            onChange={(e) => handleCategoryDetailsChange('description', e.target.value)}
                                                        />
                                                    </Control>
                                                </Field>
                                            </Column>

                                            <Column size='6'>
                                                <Field>
                                                    <Control>
                                                        <StyledLabel>
                                                            <StyledCheckbox
                                                                type='checkbox'
                                                                name="couponAllowed"
                                                                disabled={false}
                                                                checked={categoryToUpdate?.couponAllowed}
                                                                onChange={(e) => handleCategoryDetailsChange('couponAllowed', e.target.checked)}
                                                            />
                                                            <span className='has-text-info ml-3'>
                                                                Coupon Allowed
                                                            </span>
                                                        </StyledLabel>
                                                    </Control>
                                                </Field>
                                            </Column>
                                            <Column size='6'>
                                                <Field>
                                                    <Control>
                                                        <StyledLabel>
                                                            <StyledCheckbox
                                                                type='checkbox'
                                                                name="googleReserveAllowed"
                                                                disabled={false}
                                                                checked={categoryToUpdate?.googleReserveAllowed}
                                                                onChange={(e) => handleCategoryDetailsChange('googleReserveAllowed', e.target.checked)}
                                                            />
                                                            <span className='has-text-info'>Google Reserve Allowed</span>
                                                        </StyledLabel>
                                                    </Control>
                                                </Field>
                                            </Column>

                                        </Columns>
                                        :
                                        <p> Are you sure you want to {confirmUpdate?.type} the Business Category?</p>
                                }

                                <div class="buttons is-flex is-justify-content-end my-4">
                                    <Button class="button is-secondary" onClick={() => handleModalClose()}>Close</Button>
                                    <Button class="button is-info"
                                        color='info'
                                        disabled={handleDisable()}
                                        loading={(confirmUpdate?.type === "update" || confirmUpdate?.type === "add") ? loadingUpdate : loadingDelete}
                                        onClick={
                                            // confirm update type checks for type of action i.e: add, update, delete and than add confirmation layer by checking status
                                            confirmUpdate.type === "add"
                                                ?
                                                () => handleSaveBusinessCategory()
                                                :
                                                confirmUpdate?.type === "update"
                                                    ?
                                                    !confirmUpdate?.status
                                                        ?
                                                        () => setConfirmUpdate({ type: "update", status: true })
                                                        :
                                                        () => handleSaveBusinessCategory()
                                                    :
                                                    confirmUpdate?.type === "delete" && confirmUpdate?.status
                                                        ?
                                                        () => handleDeleteCategory()
                                                        :
                                                        window.alert("Could not delete")
                                        }>
                                        {confirmUpdate.type === "add" ? "Add" : confirmUpdate.type === "update" ? confirmUpdate?.status ? "Confirm" : "Update" : "Delete"}
                                    </Button>
                                </div>

                            </Modal.Card.Body>
                        </Overflow.Content>
                    </Overflow>
                </Modal.Card>
            </Modal>
        </Fragment >
    )
}

const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 4px; /* Adjust the value for desired roundness */
  border: 1px solid #ccc; /* Optional: Customize the border color */
  
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  .has-text-info {
    margin-left: 0.75rem; /* Equivalent to Bulma's ml-3 class */
  }
`;