import React, { Fragment } from 'react';
import { Route, useLocation } from 'react-router-dom';
import { OidcSecure } from '@axa-fr/react-oidc-context';
import NavMenu from 'components/Common/NavMenu';
import Footer from 'components/Common/Footer';


export const ApolloRoute = ({ ...props }) => {
  const { component: Component, secure, isNavbarHide, profile, ...rest } = props;


  // const profile = useProfile();

  // useGetAllAppointments()

  // const { pathname } = useLocation();

  // let isAllowFooter = false;
  // if (!profile.isLoggedIn) {
  //   isAllowFooter = pathname !== '/';
  // } else {
  //   isAllowFooter =
  //     !String(pathname).includes('/admin') &&
  //     !String(pathname).includes('/superadmin') &&
  //     !String(pathname).includes('/messages') &&
  //     !String(pathname).includes('/business/search');
  // }

  // if (String(pathname).includes('/notifications')) {
  //   refetch()
  // }

  return (
    <Fragment>
      {/* {loading && <FullPageLoader />} */}
      {/* {!isNavbarHide && <NavMenu />} */}
      <Route
        {...rest}
        render={(props) =>
          secure ? (
            <OidcSecure>
              <Component {...profile} {...props} />
            </OidcSecure>
          ) : (
            <Component {...profile} {...props} />
          )
        }
      />
      {/* {isAllowFooter && <Footer />} */}
    </Fragment>
  );
};
