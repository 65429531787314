import React, { Fragment } from 'react';
import { Title, List } from '@safelyq/bulma-ui-library';

export const ResponsesSection = ({ responses = [] }) => {
  const isAnyResponse = responses.length > 0;

  if (!isAnyResponse) return null;
  return (
    <Fragment>
      <Title size='6'>User Responses</Title>
      <List type='unordered'>
        {responses.map((response, index) => (
          <Fragment>
            <List.Item className='mb-2'>
              <b>
                Q {index + 1}. {response?.businessQuestion?.prompt}
              </b>
            </List.Item>
            <List.Item className='mb-3'>
              <b>Ans:</b> {response.answer ? 'Yes' : 'No'}
            </List.Item>
          </Fragment>
        ))}
      </List>
    </Fragment>
  );
};
