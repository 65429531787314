import React, { useState, useEffect, Fragment } from 'react'
import { isEmpty } from 'lodash';
import { Buttons, Button, Icon } from '@safelyq/bulma-ui-library';
import { FiInfo } from "react-icons/fi";
import { Tooltip } from 'antd';
import { SERVICE_SETUP_MESSAGES } from '../../common/constants';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

export const ServicesSetup = ({ data, onSubmit: onSubmitParent, isLoading, infoMessage }) => {
  const [services, setServices] = useState([])
  const [hasError, setHasError] = useState(false)
  const [compareServices, setCompareServices] = useState([])
  const [dirtyFields, setDirtyFields] = useState(true)
  const state = useSelector(state => state);
  const businessCategory = state?.business?.businessCategory;
  // const businessCategoryName = businessCategory?.name;

  let history = useHistory();

  const handleFieldMakeEditable = (id, status) => {
    const oneServiceEdit = services.some((service) => service.isEditable)
    if (oneServiceEdit) {
      if (status === false) {
        if (services.find((service) => service.isEditable).id !== id) {
          return toast.warning("You can edit only one service at a time")
        }
      }
      else {
        const oneServssiceEdit = services.some((service) => service.isEditable)
        if (oneServssiceEdit) {
          return toast.warning("You can edit only one service at a time")
        }
      }
    }
    const foundObject = services.find(o => o.isGoogleReserve === true);

    const updated_services = services.map((service) => {
      let obj = { ...service }
      if (foundObject) {
        if (obj.id === id) {
          if (obj.isActive) {
            obj.isEditable = status
            obj.grEditable = status
          }
          else {
            obj.isEditable = status
          }
        }
      }
      else {
        if (obj.id === id) {
          if (obj.isActive) {
            obj.isEditable = status
            obj.grEditable = status
          }
          else {
            obj.isEditable = status
          }
        }
      }
      return obj
    })
    setServices(updated_services)
  }
  const handleServiceCancel = (id) => {
    const updated_services = services.filter((service) => service.id !== id)
    setServices(updated_services)
  }

  const handleAddMore = () => {
    const obj = {
      id: 0,
      name: "",
      duration: "",
      isActive: false,
      isGoogleReserve: false,
      isEditable: false,
      grEditable: false,
      nameError: "name is required",
      durationError: "duration is required"
    }
    setServices((prev) => ([
      ...prev,
      obj
    ]))
  }


  const handleInputChange = (e, index, id) => {
    let { name, value } = e.target;
    const updatedInputs = [...services];
    if (name === "isActive") {
      updatedInputs[index][name] = e.target.checked;
      updatedInputs[index]["grEditable"] = e.target.checked
      if (e.target.checked === false) {
        updatedInputs[index]["isGoogleReserve"] = e.target.checked;
      }

    }
    else if (name === "isGoogleReserve") {
      const anyServiceTrue = services.find((service) => service.isGoogleReserve)
      if (anyServiceTrue) {
        if (anyServiceTrue.isGoogleReserve === id) {
          updatedInputs[index][name] = e.target.checked;
        }
        else {
          toast.warning("You are allowed to select only one service for Google Reserve.")
        }

      }
      else {
        updatedInputs[index][name] = e.target.checked;

      }
    }
    else {
      if (value === "") {
        updatedInputs[index][`${name}Error`] = `${name} is required`
      }
      else {
        updatedInputs[index][`${name}Error`] = ""
      }
      updatedInputs[index][name] = value;
    }
    setServices(updatedInputs)
  }

  const handleSave = () => {
    services.forEach(function (v) {
      delete v.isEditable;
      delete v.grEditable;
      delete v.businesses;
      delete v.nameError;
      delete v.durationError;
    });

    onSubmitParent(
      { businessServices: { services: services } }, 6,
      { success: SERVICE_SETUP_MESSAGES.Success, error: SERVICE_SETUP_MESSAGES.Error }
    );
  }

  const newService = services.some((service) => service.id === 0)
  useEffect(() => {
    if (!isEmpty(data)) {
      const newServices = data.services.map((service) => {
        let obj = {
          ...service,
          isEditable: false,
          grEditable: false,
          nameError: "",
          durationError: ""
        }
        return obj
      })
      setServices(newServices)
      setCompareServices(newServices)
    }

  }, [data])
  useEffect(() => {
    const status = services.some((day) => day.nameError || day.durationError)
    setHasError(status)
    const s1 = JSON.stringify(services);
    const s2 = JSON.stringify(compareServices);
    if (s1 === s2) {
      setDirtyFields(true)
    } else {
      setDirtyFields(false)
    }
  }, [services, compareServices])

  return (

    <div className='row'>
      <div className='col-lg-12'>
        <label className='label'>Total Services <small>({services?.length} of 10)</small>   </label>
      </div>
      {services.map((service, index) => {
        return (
          <div className='col-lg-12 mb-4'>
            <div className='row'>
              <div className='col-lg-4'>
                <div className='control'>
                  <label className='label'>  Service Name <span className='has-text-danger'>*</span>   </label>
                  <input type="text" name={"name"} value={service.name} onChange={(e) => handleInputChange(e, index)} disabled={!service.isEditable} className='input' />
                  {service.nameError && <span style={{ color: 'red' }}>{service.nameError}</span>}
                </div>
              </div>
              <div className='col-lg-3'>
                <div className='control'>
                  <label className='label'>Duration{' '}<small>(in minutes) <span className='has-text-danger'>*</span></small></label>
                  <input type="text" name={"duration"} value={service.duration} onChange={(e) => handleInputChange(e, index)} disabled={!service.isEditable} className='input' />
                  {service.durationError && <span style={{ color: 'red' }}>{service.durationError}</span>}
                </div>
              </div>
              <div className='col-lg-2'>
                <div className='control'>
                  <label className='label is-hidden-mobile'>&nbsp;</label>
                  <label className='checkbox'>
                    <input type='checkbox' name="isActive" disabled={!service.isEditable} checked={service.isActive} onChange={(e) => handleInputChange(e, index)} />
                    <strong className='ml-2'>Is Active ?</strong>
                  </label>
                </div>
              </div>
              {
                // Enable "is Google Reserve Only when category is restaurant"
                <div className='col-lg-2'>
                  <div className='control'>
                    <label className='label is-hidden-mobile'>&nbsp;</label>
                    <label className='checkbox'>
                      <input type='checkbox' name="isGoogleReserve" disabled={
                        service.isEditable
                          ?
                          service.grEditable
                            ?
                            // businessCategoryName === "restaurant"
                            businessCategory?.features?.googleReserveAllowed
                              ?
                              data?.businessSubscription?.subscriptionPlan?.subscriptionTierType !== "ENHANCED_PLUS_RESERVE_GOOGLE" ? true : false
                              :
                              true
                            : true
                          :
                          true
                      } checked={service.isGoogleReserve} onChange={(e) => handleInputChange(e, index, service.isGoogleReserve)} />
                      <strong className='ml-2'>Is Google Reserve <span>
                        <Tooltip
                          title={
                            <>
                              {
                                // businessCategoryName === "restaurant" ?
                                businessCategory?.features?.googleReserveAllowed ?
                                  data?.businessSubscription?.subscriptionPlan?.subscriptionTierType !== "ENHANCED_PLUS_RESERVE_GOOGLE" ?
                                    <>
                                      <p> {infoMessage?.googleReserve} </p>
                                      <p className='is-clickable has-text-underline' onClick={() => history.push(`/admin/pricing/${data?.id}`)}>
                                        Go To Subscription
                                      </p>
                                    </>
                                    :
                                    <p>Your subscription Level supports Google Reserve integration</p>
                                  :
                                  <p> {infoMessage?.nonRestaurant} </p>
                              }
                            </>
                          }
                        >
                          <FiInfo />
                        </Tooltip>
                      </span></strong>
                    </label>
                  </div>
                </div>
              }

              <div className='col-lg-1'>
                <label className='label is-hidden-mobile'>&nbsp;</label>
                <Buttons size='small'>
                  {service.isEditable ? (
                    <Button color='info' type='button' onClick={() => handleFieldMakeEditable(service.id, false)}><Icon name='check' />  </Button>
                  ) : (
                    <Button color='info' type='button' onClick={() => handleFieldMakeEditable(service.id, true)}  >  <Icon name='pencil' />  </Button>
                  )}
                  {service.id === 0 && <Button color='info' type='button' onClick={() => handleServiceCancel(service.id)}><Icon name='times' />  </Button>}
                </Buttons>
              </div>
            </div>
          </div>
        )
      })}
      {
        !newService && services?.length <= 9 && (
          <div className='col-lg-12 mb-4'>
            <span className='has-text-info' onClick={handleAddMore}> <Icon name='plus' className='mr-2' />  Add more service   </span>
          </div>
        )
      }

      <div className='col-lg-12'>
        <Button onClick={handleSave} disabled={dirtyFields || hasError} color='info' loading={isLoading}> Save  </Button>
      </div>
    </div >
  )
} 