import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { toast as showToast } from 'react-toastify';
import { INITIAL_BUSINESS_DATA } from './constants';
import { GET_BUSINESS_BY_ID, CREATE_USER_APPOINTMENT } from './requests';


export const useData = () => {
  const { businessId } = useParams();

  const id = parseInt(businessId);

  const {
    loading: isLoading,
    error,
    data = INITIAL_BUSINESS_DATA,
    refetch,
  } = useQuery(GET_BUSINESS_BY_ID, { fetchPolicy: 'no-cache', variables: { id } });

  if (error) showToast.error('There was a problem. Data could not be loaded.');

  const { getBusinessById: business } = data;
  return { isLoading, error, data: business, refetch };
};

export const useCreateAppointment = () => {
  const { push } = useHistory();

  const { businessId } = useParams();


  const [onCreateAppointment, { loading: isLoadingToCreate }] = useMutation(
    CREATE_USER_APPOINTMENT,
    {
      onCompleted: async ({ createUserAppointment }) => {
        const { isSaved, errorMessage } = createUserAppointment;

        if (isSaved) {
          push(`/admin/appointments/${businessId}`);
          showToast.success('New Appointment has been created');
        } else showToast.error(errorMessage || 'Appointment creation failed');
      },
      onError: () => {
        showToast.error('Something went wrong');
      },
    }
  );

  return { isLoadingToCreate, onCreateAppointment };
};
