import { bool, oneOf } from 'prop-types';
import styled from 'styled-components';

const gap = (strings, value) => {
  switch (value) {
    case '0':
      return 'is-gapless';

    case '3':
      return null;

    default:
      return `is-variable is-${value}`;
  }
};

const className = (strings, props) => {
  const classNames = [
    'columns'
  ]
    .concat(
      [
        'centered',
        'multiline'
      ]
        .map((name) => props[name] ? `is-${name}` : null)
        .filter((className) => !!className)
    )
    .concat(
      [
        'breakpoint'
      ]
        .map((name) => props[name] ? `is-${props[name]}` : null)
        .filter((className) => !!className)
    )
    .concat(
      [
        'gap'
      ]
        .map((name) => props[name] ? gap`${props[name]}` : null)
        .filter((className) => !!className)
    )
    .join(' ');

  return classNames;
};

const Columns = styled.div.attrs((props) => ({
  className: className`${props}`
}))``;

Columns.propTypes = {
  centered: bool,
  breakpoint: oneOf(
    [
      'mobile',
      'desktop'
    ]
  ),
  gap: oneOf(
    [
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8'
    ]
  ),
  multiline: bool
};

Columns.defaultProps = {
  centered: undefined,
  breakpoint: undefined,
  gap: undefined,
  multiline: undefined
};

export default Columns;
