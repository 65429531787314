import { bool, func, oneOf, string } from 'prop-types';
import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = ['input']
    .concat(
      ['color', 'size']
        .map((name) => (props[name] ? `is-${props[name]}` : null))
        .filter((className) => !!className)
    )
    .join(' ');

  return classNames;
};

const PasswordInput = styled.input.attrs(
  ({ multiple, placeholder, defaultValue, value, onChange, type, ...props }) => ({
    className: className`${props}`,
    type: type ?? 'password',
    multiple,
    placeholder,
    defaultValue,
    value,
    onChange,
  })
)``;

PasswordInput.propTypes = {
  color: oneOf(['primary', 'info', 'success', 'warning', 'danger', 'grey']),
  multiple: bool,
  size: oneOf(['small', 'medium', 'large']),
  placeholder: string,
  defaultValue: string,
  value: string,
  onChange: func,
};

PasswordInput.defaultProps = {
  color: undefined,
  multiple: undefined,
  size: undefined,
  placeholder: undefined,
  defaultValue: undefined,
  value: undefined,
  onChange: undefined,
};

export default PasswordInput;
