import styled from 'styled-components';
import LevelItem from '../LevelItem';

const className = (strings, props) => {
  const classNames = ['level-right'].join(' ');

  return classNames;
};

const LevelRight = styled.div.attrs((props) => ({
  className: className`${props}`,
}))``;

LevelRight.Item = LevelItem;

LevelRight.propTypes = {};

LevelRight.defaultProps = {};

export default LevelRight;
