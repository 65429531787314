import React from 'react';
import { bool, oneOf } from 'prop-types';
import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = [
    'title'
  ]
    .concat(
      [
        'size'
      ]
        .map((name) => props[name] ? `is-${props[name]}` : null)
        .filter((className) => !!className)
    )
    .concat(
      [
        'color'
      ]
        .map((name) => props[name] ? `has-text-${props[name]}` : null)
        .filter((className) => !!className)
    )
    .concat(
      [
        'spaced'
      ]
        .map((name) => props[name] ? `is-${name}` : null)
        .filter((className) => !!className)
    )
    .join(' ');

  return classNames;
};

const H1Title = styled.h1.attrs((props) => ({
  className: className`${props}`
}))``;

const H2Title = styled.h2.attrs((props) => ({
  className: className`${props}`
}))``;

const H3Title = styled.h3.attrs((props) => ({
  className: className`${props}`
}))``;

const H4Title = styled.h4.attrs((props) => ({
  className: className`${props}`
}))``;

const H5Title = styled.h5.attrs((props) => ({
  className: className`${props}`
}))``;

const H6Title = styled.h6.attrs((props) => ({
  className: className`${props}`
}))``;

const Title = ({ size, children, ...props }) => {
  switch (size) {
    case '1':
      return (
        <H1Title size={size} {...props}>{children}</H1Title>
      );

    case '2':
      return (
        <H2Title size={size} {...props}>{children}</H2Title>
      );

    case '3':
      return (
        <H3Title size={size} {...props}>{children}</H3Title>
      );

    case '4':
      return (
        <H4Title size={size} {...props}>{children}</H4Title>
      );

    case '5':
      return (
        <H5Title size={size} {...props}>{children}</H5Title>
      );

    case '6':
      return (
        <H6Title size={size} {...props}>{children}</H6Title>
      );

    default:
      throw new Error(`title of size ${size} is not supported`);
  }
};

Title.propTypes = {
  size: oneOf(
    [
      '1',
      '2',
      '3',
      '4',
      '5',
      '6'
    ]
  ).isRequired,
  color: oneOf(
    [
      'white',
      'black',
      'light',
      'dark',
      'primary',
      'info',
      'link',
      'success',
      'warning',
      'danger',
      'black-bis',
      'black-ter',
      'grey-darker',
      'grey-dark',
      'grey',
      'grey-light',
      'grey-lighter',
      'white-ter',
      'white-bis'
    ]
  ),
  spaced: bool
};

Title.defaultProps = {
  color: undefined,
  spaced: undefined
};

export default Title;
