import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import {
    Title,
    Button,
    Icon,
    Section,
    Container,
    Block,
    Columns,
    Column,
    Field,
    Control,
    DateInput,
    TextInput,
} from '@safelyq/bulma-ui-library';
import { TableView, TileView, ConfirmationModal, ViewModel, NoCampaignsFound } from './components';
import { getStatusColor, getStatusName } from 'methods/getStatuses';
import { STATUS_CAMPAIGN } from 'constants/index';
import { useData, useDeleteData, useSingleCampaign } from './hooks';
import { Loader } from '@safelyq/base-feature';
import Pagination from 'components/PaginationGlobal';

const currentDate = moment().format('YYYY-MM-DD');
const yearBeforeDate = moment().subtract(12, "months").format('YYYY-MM-DD');

export const ManageCampaign = () => {

    // Provided variables
    const { businessId } = useParams();
    const history = useHistory();

    // Custom States
    const [modal, setModal] = useState({
        isOpen: false,
        actionId: null,
    });

    const [viewModal, setViewModal] = useState({
        isOpen: false, id: null, type: null
    });

    const [paginationVariables, setPaginationvariables] = useState({
        currentPage: 1,
        totalPages: 5,
    })

    const [campaignVariables, setCampaignVariables] = useState({
        businessId: businessId,
        statuses: [],
        searchText: '',
        skip: 0,
        take: 10,
        endDate: null,
        startDate: null,
    })

    const [filteredCampaigns, setFilteredCampaigns] = useState([])
    const [cancelledMessageState, setCancelledMessageState] = useState(null);

    // Custom Hooks for API calls
    const { isLoading, campaigns, totalCount, getUserCampaigns, onChangeCampaignStatus, isCancelLoading, refetch, cancelledMessage } = useData({ onPopulateData: setFilteredCampaigns });
    const { fetchCampaign, campaignData, campaignLoading, campaignError, refetchSingleCampaign } = useSingleCampaign();
    const { deleteLoading, deleteCampaign } = useDeleteData();

    useEffect(() => {
        totalCount && setPaginationvariables({
            ...paginationVariables,
            totalPages: Math.ceil(totalCount / 10)
        })
    }, [totalCount])
    // useEffects
    useEffect(() => {
        // skip and take will check if the variables is not null
        // if (paginationVariables?.currentPage && paginationVariables.skip && paginationVariables.take) {
        if (paginationVariables?.currentPage) {
            const updatedVariables = {
                ...campaignVariables,
                skip: (paginationVariables.currentPage - 1) * 10,
            };

            // call api
            getUserCampaigns({
                variables: {
                    selectCampaignsInput: updatedVariables
                }
            })

            // set updated variables to setCampaign Variables
            setCampaignVariables(updatedVariables)
        }
    }, [paginationVariables?.currentPage])

    useEffect(() => {
        if (cancelledMessage) {
            setCancelledMessageState(cancelledMessage)
        }
    }, [cancelledMessage])

    // Get user campaigns for the first time only
    useEffect(() => {
        getUserCampaigns({
            variables: {
                selectCampaignsInput: campaignVariables
            }
        })
    }, [])

    // Functions
    const handlePageChange = (page) => {
        setPaginationvariables({
            ...paginationVariables,
            currentPage: page
        })
    };

    const handleTodayCampaigns = () => {
        setCampaignVariables({
            ...campaignVariables,
            startDate: currentDate,
            endDate: currentDate
        })
    }

    const handleViewModalOpen = ({ id, type }) => {
        setViewModal({ isOpen: true, id: id, type });
        // Fetch campaign data of this id
        type === "view" && fetchCampaign({ variables: { id: id } })
    }

    const handleViewModalClose = () =>
        setViewModal({ isOpen: false, id: null });

    const handleEditCampaign = (existingCampaign) =>
        history.push(`/admin/create-campaign/${businessId}`, { campaignId: existingCampaign?.id });

    const handleConfirmModalOpen = (id, status) =>
        setModal({ isOpen: true, actionId: id, status });

    const handleConfirmModalClose = () => {
        // if (cancelledMessage) {
        if (cancelledMessageState) {
            // refetch campaign
            refetch();
            setCancelledMessageState(null)
        }
        setModal({ ...modal, isOpen: false, status: '' });
    }

    const handleCampaignStatusChange = () =>
        onChangeCampaignStatus(modal.status, modal.actionId);

    // If status is already present than remove else add in the statuses
    const handleFilterCheckboxChange = (status, checked) => {
        setCampaignVariables(prevVariables => {
            let newStatusArray;

            if (checked) {
                // Add status to the array if it's not already present
                newStatusArray = [...prevVariables.statuses, status];
            } else {
                // Remove status from the array if it exists
                newStatusArray = prevVariables.statuses.filter(s => s !== status);
            }

            return {
                ...prevVariables,
                statuses: newStatusArray,
            };
        });
    };

    const handleVariablesChanges = ({ target: { name, value } }) => {

        // If title is entered, also search details
        // if (name === "title") {
        //     setCampaignVariables({
        //         ...campaignVariables,
        //         title: value,
        //         details: value,
        //     })
        // }
        // else {
        setCampaignVariables({
            ...campaignVariables,
            [name]: value
        })
        // }

    };

    const handleApplyFilters = () => {

        if (paginationVariables.currentPage !== 1) {
            // when paginations is changed, api is automatically called
            setPaginationvariables({
                ...paginationVariables,
                currentPage: 1
            })
        }
        // call api api if the current page is already 1 and useeffect can not be called
        else {
            getUserCampaigns({
                variables: {
                    selectCampaignsInput: campaignVariables
                }
            })
        }
    }

    const passingProps = {
        campaigns: filteredCampaigns ? filteredCampaigns : [],
        isLoading,
        isCancelLoading,
        handleConfirmModalOpen,
        handleViewModalOpen,
        handleEditCampaign,
    };

    const areCampaignsFound = filteredCampaigns?.length > 0;

    return (
        <Section>
            <Container>
                <Block className='is-flex is-align-items-center'>
                    <Title size='5' className='mb-0'>
                        <Icon name='bullhorn' className='mr-3 ' />
                        <strong>Campaign List</strong>
                    </Title>
                    <Button
                        size='small'
                        color='info'
                        className='ml-3 is-justify-content-flex-end'
                        onClick={handleTodayCampaigns}
                    >
                        Today
                    </Button>
                </Block>
                <Columns multiline>
                    <Column size='3'>
                        <Field>
                            <Control>
                                <StyledDateInputBar
                                    name='startDate'
                                    value={campaignVariables?.startDate}
                                    onChange={handleVariablesChanges}
                                />
                                <small className='has-text-grey'>
                                    <i>Start Date</i>
                                </small>
                            </Control>
                        </Field>
                    </Column>
                    <Column size='3'>
                        <Field>
                            <Control>
                                <StyledDateInputBar
                                    name='endDate'
                                    value={campaignVariables?.endDate}
                                    onChange={handleVariablesChanges}
                                />
                                <small className='has-text-grey'>
                                    <i>End Date</i>
                                </small>
                            </Control>
                        </Field>
                    </Column>
                    <Column size='3'>
                        <Field>
                            <Control>
                                <StyledSearchBar
                                    name='searchText'
                                    value={campaignVariables?.searchText}
                                    onChange={handleVariablesChanges}
                                    placeholder={'Search by Title Or Details'}
                                />
                                <Icon name='search' position='right' />
                            </Control>
                        </Field>
                    </Column>
                    <Column size='3'>
                        <div className='is-flex is-align-items-center'>
                            <div className='dropdown is-hoverable mr-5'>
                                <div className='dropdown-trigger'>
                                    <Button
                                        className='button-icon'
                                        aria-haspopup='true'
                                        aria-controls='actions-dropdown'
                                    >
                                        <Icon name='filter' color='info' scale='lg' />
                                    </Button>
                                </div>
                                <div className='dropdown-menu' id='actions-dropdown' role='menu'>
                                    <div className='dropdown-content'>
                                        {Object.keys(STATUS_CAMPAIGN).map((keyName, index) => (
                                            <label className='checkbox dropdown-item'>
                                                <div className='control'>
                                                    <input
                                                        type='checkbox'
                                                        onChange={({ target: { checked } }) =>
                                                            handleFilterCheckboxChange(
                                                                getStatusName(STATUS_CAMPAIGN[keyName]),
                                                                checked
                                                            )
                                                        }
                                                    />
                                                    <span
                                                        key={`status-${index}`}
                                                        className={`tag is-light ml-2`}
                                                        style={{
                                                            background: `rgba(${getStatusColor(
                                                                STATUS_CAMPAIGN[keyName]
                                                            )}, 0.1)`,
                                                            color: `rgb(${getStatusColor(STATUS_CAMPAIGN[keyName])})`,
                                                        }}
                                                    >
                                                        {getStatusName(STATUS_CAMPAIGN[keyName])}
                                                    </span>
                                                </div>
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <Button className='mx-1 is-info'
                                onClick={handleApplyFilters}
                            >
                                <Icon name='search' className="px-2" />
                            </Button>
                            <Field>
                                <Control>
                                    <Link to={`/admin/create-campaign/${businessId}`}>
                                        <Button color='info'>Create Campaign</Button>
                                    </Link>
                                </Control>
                            </Field>
                        </div>
                    </Column>
                </Columns>
                {
                    isLoading ? <Loader isFullScreen /> :
                        !areCampaignsFound ? (
                            <NoCampaignsFound />
                        ) : (
                            <>
                                <div className='is-hidden-mobile'>
                                    <TableView {...passingProps} />
                                    <div className='mt-3'>
                                        <Pagination
                                            currentPage={paginationVariables?.currentPage}
                                            totalPages={paginationVariables?.totalPages}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>

                                </div>
                                <div className='is-hidden-tablet'>
                                    <TileView {...passingProps} />
                                    <div className='mt-3'>
                                        <Pagination
                                            currentPage={paginationVariables?.currentPage}
                                            totalPages={paginationVariables?.totalPages}
                                            onPageChange={handlePageChange}
                                        />
                                    </div>

                                </div>
                            </>
                        )}
                {modal.isOpen && (
                    <ConfirmationModal
                        isOpen={modal.isOpen}
                        action={modal.status}
                        onCancel={handleCampaignStatusChange}
                        onClose={handleConfirmModalClose}
                        data={campaigns?.find(campaign => campaign.id === modal.actionId)}
                        cancelledMessage={cancelledMessageState}
                        isCancelLoading={isCancelLoading}
                    />
                )}
                {viewModal.isOpen && (
                    <ViewModel
                        isOpen={viewModal.isOpen}
                        onCloseModal={handleViewModalClose}
                        deletedData={campaigns?.find(campaign => campaign.id === viewModal.id)}
                        data={campaignData}
                        loading={campaignLoading}
                        campaignId={viewModal?.id}
                        type={viewModal?.type}
                        deleteLoading={deleteLoading}
                        deleteCampaign={deleteCampaign}
                        refetch={refetch}
                        refetchSingleCampaign={refetchSingleCampaign}
                    />
                )}
            </Container>
        </Section>
    );
};

const StyledSearchBar = styled(TextInput).attrs(() => ({}))`
  background: rgba(0, 0, 0, 0.02);
`;

const StyledDateInputBar = styled(DateInput).attrs(() => ({}))`
  background: rgba(0, 0, 0, 0.02);
`;