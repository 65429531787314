import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { VERIFY_CODE, RESEND_CODE } from './requests';

export const useData = (code) => {
  const { push } = useHistory();

  const [onVerifyCode, { loading: isVerifyLoading }] = useMutation(
    VERIFY_CODE,
    {
      variables: { code },
      onCompleted: ({ confirmVerificationCode: { error } }) => {
        if (error) throwError(error);
        else {
          toast.success('Your phone number has been verified');
          push('/account/setting');
        }
      },
      onError: () => throwError(),
    }
  );

  const [onResendCode, { loading: isResendLoading }] = useMutation(RESEND_CODE);

  const throwError = (error) => toast.error(error || 'Somthing went wrong');

  return { isVerifyLoading, isResendLoading, onVerifyCode, onResendCode };
};
