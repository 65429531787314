import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button, Label, Columns, Column } from '@safelyq/bulma-ui-library';
import { CampaignTargetTime } from '../CampaignTargetTime/index';
import styled from 'styled-components';

export const CampaignSlot = ({ existingCampaignTargetTimes, safelyQCampaignTargetTimes, setCampaignTargetTimes, campaignTargetTimes, timeZone, customSlotValidation, setCustomSlotValidation }) => {

  const currentTime = moment().add(10, 'minutes').format('HH:mm');
  const currentTimePlus = moment().add(20, 'minutes').format('HH:mm');

  const [customSlotStates, setCustomSlotStates] = useState({
    customSlotRadio: false,
    isShowCustomSlot: false,
    showButton: true,
    showForm: false,
  });

  const [targetSlotStates, setTargetSlotStates] = useState({
    showTargetSlot: false,
    targetSlotRadio: true,
    isAllChecked: true
  });

  const customTargetTime = existingCampaignTargetTimes?.find(target_time => target_time?.isCustomTime);
  if (customTargetTime) {
    var startTime24Hour = moment(customTargetTime.startTime, ["h:mm A"]).format("HH:mm");
    var endTime24Hour = moment(customTargetTime.endTime, ["h:mm A"]).format("HH:mm");
  }

  const [values, setValues] = useState({
    startTime: startTime24Hour || currentTime,
    endTime: endTime24Hour || currentTimePlus,
  });

  const startDayTime = moment().startOf('day').format('HH:mm');
  const endDayTime = moment().endOf('day').format('HH:mm');

  const handleInputChange = ({ target: { name, value } }) => {
    if (name === 'startTime') {
      setValues({
        ...values,
        [name]: value
      });

      const changedStartTime = value;
      // Slot end time must be greater than s;ot starting time selected and also starting time must be less than end day time (11:50pm)
      if (values.endTime > changedStartTime && changedStartTime < endDayTime) {
        // set slot validation to false
        setCustomSlotValidation({ ...customSlotValidation, status: false });
      } else {
        setCustomSlotValidation({ ...customSlotValidation, status: true, message: `Slot start time ' ${moment(changedStartTime, ["HH:mm"]).format("h:mm A")} ' must be before the slot end time ' ${moment(values.endTime, ["HH:mm"]).format("h:mm A")} '` })
      }
    }

    if (name === 'endTime') {
      const changedEndTime = value;

      setValues({
        ...values,
        [name]: value
      });

      // Slot end time must be greater than start day time (12:00am) and also greater than slot start time
      if (startDayTime < changedEndTime && changedEndTime > values.startTime) {
        setCustomSlotValidation({ ...customSlotValidation, status: false });
      }
      else {
        setCustomSlotValidation({ ...customSlotValidation, status: true, message: `Slot End Time ' ${moment(changedEndTime, ["HH:mm"]).format("h:mm A")} ' must be after the slot start time ' ${moment(values.startTime, ["HH:mm"]).format("h:mm A")} '` })
      }
    }
  }

  const handleCancelClick = (e) => {
    e.preventDefault();
    if (campaignTargetTimes?.find(target_time => target_time?.isCustomTime)) {
      setCustomSlotStates({
        ...customSlotStates,
        isShowCustomSlot: true,
        showButton: false,
        showForm: false,
      })
      setTargetSlotStates({
        ...targetSlotStates,
        isAllChecked: true
      })
    }
    else {
      setCustomSlotStates({
        ...customSlotStates,
        showForm: false,
        showButton: true,
        isShowCustomSlot: true
      })
    }
  };

  const handleButtonFormClick = () => {
    setCustomSlotStates({
      ...customSlotStates,
      showForm: true,
      showButton: false
    })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!campaignTargetTimes?.find(target_time => target_time?.isCustomTime)) {

      // Loop through campaignTargetTimes and set isSelected to false for all objects
      campaignTargetTimes.forEach(target_time => {
        target_time.isSelected = false;
      });

      const newCampaignTargetTimes = [...campaignTargetTimes];

      newCampaignTargetTimes.push({
        name: 'Custom Slot',
        isCustomTime: true,
        isSelected: true,
        startTime: moment(values.startTime, 'HH:mm').format('h:mm A'),
        endTime: moment(values.endTime, 'HH:mm').format('h:mm A'),
      });
      setCampaignTargetTimes(newCampaignTargetTimes)
    }
    else {
      const index = campaignTargetTimes.findIndex(time => time.isCustomTime);
      // pass clone to new variable via deconstructing
      // elements in the state object can be changed but it does not render
      const dummyCampaignTargetTimes = [...campaignTargetTimes];
      dummyCampaignTargetTimes[index].startTime = moment(values.startTime, 'HH:mm').format('h:mm A');
      dummyCampaignTargetTimes[index].endTime = moment(values.endTime, 'HH:mm').format('h:mm A');

      setCampaignTargetTimes(dummyCampaignTargetTimes)
    }

    setCustomSlotStates({
      ...customSlotStates,
      isShowCustomSlot: true,
      showButton: false,
      showForm: false
    })
  };

  const customRadioButtonChange = () => {
    setCustomSlotStates({
      ...customSlotStates,
      customSlotRadio: true,
    })
    setTargetSlotStates({
      ...targetSlotStates,
      isAllChecked: false,
      targetSlotRadio: false,
    })
    const updatedSafelyQCampaignTargetTimes = campaignTargetTimes?.map(item => {
      const newItem = {
        ...item,
        isSelected: false,
      };
      return newItem;
    });

    // setCampaignTargetTimes(updatedSafelyQCampaignTargetTimes);

    // Custom Slot exist
    const existingCustomTargetTime = existingCampaignTargetTimes?.find(target_time => target_time?.isCustomTime);
    if (existingCustomTargetTime) {
      // Turn previous isSelected of all slots to false
      campaignTargetTimes.length = 0;
      Array.prototype.push.apply(campaignTargetTimes, updatedSafelyQCampaignTargetTimes);

      // Turn isSelected of custom slot to true
      const newCampaignTarget = campaignTargetTimes?.map((camp) => {
        if (camp.name === "Custom Slot") {
          return { ...camp, isSelected: true }
        }
        return camp
      })
      setCampaignTargetTimes(newCampaignTarget)
    }

    // Cusotom Slot does not exist and propably campaign is being created
    else {

      // Turn isSelected of all the slots to false and then true only custom slot
      let newCampaignTargetTimes = [...updatedSafelyQCampaignTargetTimes];
      if (newCampaignTargetTimes.length !== 0) {
        const lastIndex = newCampaignTargetTimes.length - 1;

        if (!newCampaignTargetTimes[lastIndex].isCustomTime) {
          return;
        }

        newCampaignTargetTimes[lastIndex].startTime = moment(values?.startTime, 'HH:mm').format('h:mm A');
        newCampaignTargetTimes[lastIndex].endTime = moment(values?.endTime, 'HH:mm').format('h:mm A');

        // mark as seleted only if custom slot
        if (newCampaignTargetTimes[lastIndex].name === "Custom Slot") {
          newCampaignTargetTimes[lastIndex].isSelected = true;
        }

        // send upadted target time as a callback
        setCampaignTargetTimes(newCampaignTargetTimes)
      }
    }
  };

  const targetRadioButtonChange = () => {
    const updatedSafelyQCampaignTargetTimes = campaignTargetTimes?.map(item => ({
      ...item,
      isSelected: false,
    }));
    setCampaignTargetTimes(updatedSafelyQCampaignTargetTimes);
    setCustomSlotStates({
      ...customSlotStates,
      customSlotRadio: false,
    })
    setTargetSlotStates({
      ...targetSlotStates,
      isAllChecked: true,
      targetSlotRadio: true,
    })

  };

  const onEditCustomSlot = () => {
    if (existingCampaignTargetTimes?.find(target_time => target_time?.isCustomTime)) {
      setCustomSlotStates({
        ...customSlotStates,
        showButton: false,
        showForm: true,
        isShowCustomSlot: false
      })
    }
    else {
      setCustomSlotStates({
        ...customSlotStates,
        showButton: false,
        showForm: true,
        isShowCustomSlot: false
      })
    }
  }

  useEffect(() => {

    // Saved campaign has custom slot
    if (existingCampaignTargetTimes && existingCampaignTargetTimes?.find(target_time => target_time?.isCustomTime)) {
      setCustomSlotStates({
        ...customSlotStates,
        customSlotRadio: true,
        isShowCustomSlot: true,
        showButton: false
      })

      // Target times does not exist
      if (safelyQCampaignTargetTimes?.length === 0) {
        setTargetSlotStates({
          ...targetSlotStates,
          targetSlotRadio: false,
          showTargetSlot: false,
        })
      }
      else {
        // Target times exist
        setTargetSlotStates({
          ...targetSlotStates,
          targetSlotRadio: false,
          showTargetSlot: true
        })
      }

    }
    // saved campaign does not have custom slot
    else {
      // campaign target times not exist
      if (safelyQCampaignTargetTimes?.length === 0) {
        setTargetSlotStates({
          ...targetSlotStates,
          targetSlotRadio: false,
          showTargetSlot: false,
        })
        setCustomSlotStates({
          ...customSlotStates,
          customSlotRadio: false,
          showButton: true,
        })
      }
      // campaign target times exist
      else {
        setTargetSlotStates({
          ...targetSlotStates,
          targetSlotRadio: true,
          showTargetSlot: true,
        })
        setCustomSlotStates({
          ...customSlotStates,
          customSlotRadio: false,
          showButton: true,
        })
      }
    }
  }, [existingCampaignTargetTimes])

  useEffect(() => {
    // If campaign is created for first time, than show target slots
    if (!existingCampaignTargetTimes) {

      // if campaign target times does not exist because the category is not category
      if (safelyQCampaignTargetTimes?.length === 0) {
        setTargetSlotStates({
          ...targetSlotStates,
          targetSlotRadio: false,
          showTargetSlot: false,
        })
      }
      else {
        setTargetSlotStates({
          ...targetSlotStates,
          showTargetSlot: true
        })
      }
    }
  }, [safelyQCampaignTargetTimes])

  // Custom slot checkbox select
  const handleCampaignTargetedTime = ({ target: { name, checked } }, cb) => {
    cb();
    let target_Times = [...campaignTargetTimes];
    let foundIndex = target_Times.findIndex(i => i.name === name);
    // for check
    if (checked === true) {
      target_Times[foundIndex].isSelected = true
      setCampaignTargetTimes(target_Times);
    }
    // for uncheck
    else {
      target_Times[foundIndex].isSelected = false
      setCampaignTargetTimes(target_Times);
    }
  };

  return (

    <Column className='p-1 is-full-mobile is-full-tablet is-full-desktop is-four-fifths-widescreen is-four-fifths-fullhd'>
      <div className='box'>
        {targetSlotStates?.showTargetSlot ? (
          <>
            <Columns multiline>
              <Column size='1' className='is-flex-desktop is-justify-content-center is-align-items-center'>
                <Label className='radio'>
                  <LargeRadio
                    type="radio"
                    value="target"
                    checked={targetSlotStates?.targetSlotRadio}
                    onChange={targetRadioButtonChange}
                  />
                </Label>
              </Column>
              <Column size='11'>
                <Label className='has-text-info'>
                  Targeted Slots <span style={{ fontSize: 12 }}>({timeZone})</span> <span className='ml-1 has-text-danger'>*</span>
                </Label>
                <Columns multiline >
                  {safelyQCampaignTargetTimes?.map((target_time, i) => (
                    !target_time.isCustomTime &&
                    <CampaignTargetTime
                      key={i}
                      handleCampaignTargetedTime={handleCampaignTargetedTime}
                      target_time={target_time} i={i}
                      existingCampaignTargetTimes={existingCampaignTargetTimes}
                      SlotRadio={customSlotStates?.customSlotRadio}
                      isAllChecked={targetSlotStates?.isAllChecked}
                    />
                  ))}
                </Columns>
              </Column>
            </Columns>
            <hr />
          </>
        ) : null}
        <Columns multiline>
          {targetSlotStates?.showTargetSlot ? (
            <Column size='1' className='is-flex-desktop is-justify-content-center is-align-items-center'>
              <Label className='radio'>
                <LargeRadio
                  type="radio"
                  value="custom"
                  checked={customSlotStates?.customSlotRadio}
                  onChange={customRadioButtonChange}
                />
              </Label>
            </Column>
          ) : null}
          <Column size='11'>
            <Label className='has-text-info'>
              Custom Slots <span style={{ fontSize: 12 }}>({timeZone})</span> <span className='ml-1 has-text-danger'>*</span>
            </Label>

            {
              // Slot Validation Text
              customSlotStates?.customSlotRadio && customSlotStates?.showForm && customSlotValidation?.status && <UserHint> {customSlotValidation?.message}</UserHint>
            }

            <Columns multiline >
              {
                campaignTargetTimes?.map((target_time, i) => (
                  (target_time?.isCustomTime && customSlotStates?.isShowCustomSlot) && (
                    <CampaignTargetTime
                      key={i}
                      handleCampaignTargetedTime={handleCampaignTargetedTime}
                      target_time={target_time}
                      i={i}
                      existingCampaignTargetTimes={existingCampaignTargetTimes}
                      SlotRadio={targetSlotStates?.targetSlotRadio}
                      isAllChecked={targetSlotStates?.isAllChecked}
                      onEditCustomSlot={onEditCustomSlot}
                    />
                  )
                ))
              }
            </Columns>
            {customSlotStates?.showButton && safelyQCampaignTargetTimes && safelyQCampaignTargetTimes.length !== 5 && (
              <Button disabled={targetSlotStates?.targetSlotRadio} color='info' onClick={handleButtonFormClick} className='mt-2 mb-1'>Add Custom Slots</Button>
            )}
            {customSlotStates?.showForm && (
              <form onSubmit={handleSubmit}>
                <Columns multiline>
                  <Column size='4'>
                    <div className='field'>
                      <label className='label'>
                        Start Time<span className='has-text-danger'>*</span>
                      </label>
                      <div className='control'>
                        <input
                          className='input'
                          type='time'
                          name='startTime'
                          value={values.startTime}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </Column>
                  <Column size='4'>
                    <div className='field'>
                      <label className='label'>End Time <span className='has-text-danger'>*</span></label>
                      <div className='control'>
                        <input
                          className='input'
                          type='time'
                          name='endTime'
                          value={values.endTime}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </Column>
                  <Column size='4'>
                    <div className='field' style={{ paddingTop: '33px' }}>
                      <Button onClick={handleCancelClick} className='mr-2'>Cancel</Button>
                      <Button type='submit' disabled={customSlotValidation?.status} color='info' className='ml-2'>Save</Button>
                    </div>
                  </Column>
                </Columns>
              </form>
            )
            }
          </Column>
        </Columns>
      </div>
    </Column >
  );
};

const LargeRadio = styled.input`
  height: 18px;
  width: 18px;
`;

const UserHint = styled.span.attrs(() => ({
  className: 'help is-danger',
}))`
  font-size: 12px;
  padding-inline: 2px;
  margin-bottom: 10px;
}
`;