export const BUSINESS_PROFILE_MESSAGES = {
  Success: 'Business Profile data has been saved successfully',
  Error: 'Error occoured in saving the busienss profile data',
};

export const APPOINTMENT_CONFIRMATION_MESSAGES = {
  Success: 'Appointment confirmation setup data has been saved successfully',
  Error: 'Error occoured in saving the appointment confirmation setup data',
};

export const SERVICE_SETUP_MESSAGES = {
  Success: 'Service setup data has been saved successfully',
  Error: 'Error occoured in saving the Service setup data',
};

export const HOLIDAY_SCHEDULE_MESSAGES = {
  Success: 'Holidays schedule successfully',
  Error: 'Error occoured while scheduling the holidays',
};

export const HOURS_OF_OPERATION_MESSAGES = {
  Success: 'Hours of operation data has been saved successfully',
  Error: 'Error occoured in saving the hours of operation data',
};

export const TRAFFIC_SETUP_MESSAGES = {
  Success: 'Customer traffic data has been saved successfully',
  Error: 'Error occoured in saving the customer traffic data',
};

export const CHECK_IN_MESSAGES = {
  Success: 'Customer checked in successfully',
  Error: 'Error occoured in checked in the customer',
};

export const COMMUNICATION_CHECKS_MESSAGES = {
  Success: 'Messaging setup data has been saved successfully',
  Error: 'Error occoured in saving the messaging setup data',
};

export const PROMOTION_SETUP_MESSAGES = {
  Success: 'Messaging setup data has been saved successfully',
  Error: 'Error occoured in saving the messaging setup data',
};

export const GEOLOCATION_SETUP_MESSAGES = {
  Success: 'Geo location setup data has been saved successfully',
  Error: 'Error occoured in Geo location setup',
};

export const OFFERED_SERVICES_MESSAGES = {
  Success: 'Offered services data has been saved successfully',
  Error: 'Error occoured in saving the offered services data',
};
