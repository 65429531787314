import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { INITIAL_DATA } from './constants';
import { GET_USER_ORGANIZATIONS } from './request';

const useGetOrganization = () => {
    const [organizations, setOrganizations] = useState([]);

    const { loading: isLoading, error, data = { INITIAL_DATA }, refetch } = useQuery(
        GET_USER_ORGANIZATIONS,
        {
            onCompleted: ({ getUserOrganizations: organizations }) => setOrganizations(organizations),
        }
    );
    const handleSearch = (searchQuery) => {
        let { getUserOrganizations: filteredOrganizations } = data;

        filteredOrganizations = filteredOrganizations.filter(({ name }) =>
            String(name).toLowerCase().includes(searchQuery.toLowerCase())
        );

        setOrganizations(filteredOrganizations);
    };
    return {
        isLoading,
        error,
        data: organizations,
        onSearch: handleSearch,
        refetch,
    };
};
export default useGetOrganization;