import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Title, Subtitle } from '@safelyq/bulma-ui-library';

export const NoLogFound = () => {
  const { pathname: tempPath } = useLocation();
  const pathname = tempPath.toLowerCase();

  const dontDisplay = pathname.includes('/appointments');

  return (
    // <section className='hero is-fullheight'>
    <section className='hero'>
      <div className='hero-body'>
        <Container className='has-text-centered'>
          <img src='/images/search-not-found.svg' className='mb-2' width={130} />
          <Title size='5'>No appointments log found</Title>
          <Subtitle size='6' className={dontDisplay && "is-invisible"}>
            As appointments are set, they will appear here
          </Subtitle>
        </Container>
      </div>
    </section>
  )
}
