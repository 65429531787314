import React, { useState, Fragment, useEffect } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  Columns,
  Column,
  Buttons,
  Button,
  Icon,
  Modal
} from '@safelyq/bulma-ui-library';
import { useForm, useFormState, useFieldArray } from 'react-hook-form';
import { CHECK_IN_MESSAGES } from '../../common/constants';
import { useSelector } from 'react-redux';

export const CheckInSetup = ({ data: business, onSubmit, isLoading }) => {
  const { id = 0 } = useParams();
  const history = useHistory();

  const [isCongregational, setIsCongregational] = useState(false);
  const [showpop, isShowPopup] = useState(false)
  const [basicButton, setBasicButton] = useState(false)
  const [freeButton, setFreeButton] = useState(false)
  const advanceAppointmentAllowed = useSelector(state => state?.business?.businessFeatures?.advanceAppointmentAllowed);
  const planName = useSelector(state => state?.business?.businessSubscriptionPlan);

  // const { data } = useGetBusinessById(id);
  // let advanceAppointmentAllowed = data?.getBusinessById?.businessSubscription?.subscriptionPlan?.features?.advanceAppointmentAllowed;
  // let planName = data?.getBusinessById?.businessSubscription?.subscriptionPlan?.name;

  useEffect(() => {
    setIsCongregational(business?.hasCongregationalCheckin)
    if (business?.hasCongregationalCheckin) {
      setBasicButton(true)
      setFreeButton(false)
    } else {
      setFreeButton(true)
      setBasicButton(false)
    }
  },
    [business]
  );

  const handleCongregationalSwitch = (value) => {
    if (advanceAppointmentAllowed) {
      setIsCongregational(value);
    }
    else {
      isShowPopup(true);
    }
    if (freeButton) {
      setBasicButton(true)
      setFreeButton(false)
    }
    else {
      setFreeButton(true)
      setBasicButton(false)
    }
  }

  const handleUpgrade = () => {
    history.push(`/admin/pricing/${id}`);
  };

  const handleCancel = () => {
    isShowPopup(false);
  };

  return (
    <>
      {showpop &&
        <Modal active={showpop} clipped>
          <Modal.Background />
          <Modal.Card>
            <Modal.Card.Body>
              <div className=' content'>
                <p>Upgrade your business subscription to access this operation.</p>
              </div>
              <div className='is-flex is-justify-content-space-evenly'>
                <Button className='button is-outlined is-rounded is-active has-text-weight-bold' onClick={() => handleUpgrade()} >Upgrade</Button>
                <Button className='button is-outlined is-rounded is-active has-text-weight-bold' onClick={() => handleCancel()} >Cancel</Button>
              </div>
            </Modal.Card.Body>
          </Modal.Card>
        </Modal>
      }

      <Columns multiline>
        <Column size='12'>
          <em>
            This business is currently under the <strong>{planName}</strong> plan
          </em>
        </Column>
        <Column size='12'>
          <Buttons size='small'>
            <Button
              color={isCongregational ? '' : 'info'}
              onClick={() => handleCongregationalSwitch(false)}
              disabled={freeButton}
            >
              Free
            </Button>
            <Button
              color={isCongregational ? 'info' : ''}
              onClick={() => handleCongregationalSwitch(true)}
              disabled={basicButton}
            >
              Basic
            </Button>
          </Buttons>
        </Column>
        <Column size='12'>
          {isCongregational ? (
            <BasicSetup
              isCongregational={isCongregational}
              business={business}
              onSubmit={onSubmit}
              isLoading={isLoading}
              tab='SERVICE_PROVIDER'
            />
          ) : (
            <FreeSetup
              isCongregational={isCongregational}
              business={business}
              onSubmit={onSubmit}
              isLoading={isLoading}
              tab='SERVICE_PROVIDER'
            />
          )}
        </Column>
      </Columns>
    </>
  );
};

const FreeSetup = ({
  isCongregational,
  business,
  onSubmit: onSubmitParent,
  isLoading,
  tab,
}) => {
  const { control, register, handleSubmit: onSubmit, reset } = useForm();

  const { dirtyFields } = useFormState({ control });

  useEffect(() => {
    if (!isEmpty(business)) {
      let { details, lateCheckInTimeThreshold } = business || {};

      reset({
        preCheckinOffset: String(details.offset),
        lateCheckInTimeThreshold: String(lateCheckInTimeThreshold),
      });
    }
  }, [business]);

  const handleSubmit = (values) => {
    if (tab === 'LOCATION') {
      onSubmitParent(
        {
          businessCheckinSetup: {
            hasCongregationalCheckin: isCongregational,
            preCheckinOffset: values?.preCheckinOffset,
            preCheckinArrivalTime: 0,
            selectedVenueEntranceIds: [],
          },
          businessLateCheckinSetup: {
            allowLateCheckin: true,
            lateCheckInTimeThreshold: values?.lateCheckInTimeThreshold,
          },
        },
        9,
        {
          success: CHECK_IN_MESSAGES.Success,
          error: CHECK_IN_MESSAGES.Error,
        }
      );
    } else {
      onSubmitParent(
        {
          businessCheckinSetup: {
            hasCongregationalCheckin: isCongregational,
            preCheckinOffset: values?.preCheckinOffset,
            preCheckinArrivalTime: 0,
            selectedVenueEntranceIds: [],
          },
          businessLateCheckinSetup: {
            allowLateCheckin: true,
            lateCheckInTimeThreshold: values?.lateCheckInTimeThreshold,
          },
        },
        9,
        business.id,
        {
          success: CHECK_IN_MESSAGES.Success,
          error: CHECK_IN_MESSAGES.Error,
        }
      );
    }
  };

  return (
    <form onSubmit={onSubmit(handleSubmit)}>
      <Columns multiline>
        <Column size='6'>
          <div className='control'>
            <label className='label has-text-weight-light'>
              Customer should arrive <strong>X</strong>-min before appointment
            </label>
            <input
              type='number'
              min='0'
              className='input'
              {...register('preCheckinOffset')}
            />
          </div>
        </Column>
        <Column size='6'>
          <div className='control'>
            <label className='label has-text-weight-light'>
              Customer can check-in <strong>X</strong>-min after appointment
              time
            </label>
            <input
              type='number'
              min='0'
              className='input'
              {...register('lateCheckInTimeThreshold')}
            />
          </div>
        </Column>
        <Column size='12'>
          <Button
            type='submit'
            color='info'
            disabled={isEmpty(dirtyFields) || isLoading}
            loading={isLoading}
          >
            Save
          </Button>
        </Column>
      </Columns>
    </form>
  );
};

const BasicSetup = ({
  tab,
  isCongregational,
  business,
  onSubmit: onSubmitParent,
  isLoading,
}) => {
  const [isNewEntry, setIsNewEntry] = useState(false);

  const [error, setError] = useState('');

  const {
    control,
    register,
    handleSubmit: onSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const { dirtyFields } = useFormState({ control });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'entrances',
    keyName: 'key',
  });

  useEffect(() => {
    if (!isEmpty(business)) {
      let { businessVenue, lateCheckInTimeThreshold } = business || {};

      let { venue } = businessVenue || {};

      let { entrances, details } = venue || {};

      let { backoff } = details || {};

      if (entrances) {
        entrances = entrances?.map((entrance) => ({
          ...entrance,
          isEditable: false,
          isAllowDelete: false,
        }));

        reset({
          backoff: String(backoff),
          lateCheckInTimeThreshold: String(lateCheckInTimeThreshold),
          entrances,
        });
      }
    }
  }, [business]);

  const handleEntranceCancel = (index) => remove(index);

  const handleFieldMakeEditable = (index) => {
    const isEditable = fields.find(
      (entrance, indx) => entrance.isEditable && index !== indx
    );

    if (isEditable) {
      alert('Please check mark to add changes first');
      return;
    }

    if (!fields[index].isEditable) {
      fields[index].isEditable = true;

      setIsNewEntry(true);

      reset({ entrances: fields });
    } else {
      if (isEmpty(errors)) {
        let entrances = watch('entrances');

        entrances[index].isEditable = false;

        setError('');

        reset({ entrances }, { keepDirty: true });
      }
    }
  };

  const handleAddMore = (e) => {
    e.preventDefault();

    const isEditable =
      fields.length > 0
        ? fields.filter((entrance) => entrance.isEditable).length > 0
        : false;

    if (isEditable) {
      setError('Please check mark to add this entrance');
      return;
    }

    setIsNewEntry(false);

    append({
      name: '',
      capacity: 0,
      order: 0,
      isEditable: true,
      isAllowDelete: true,
      isActive: false,
    });
  };

  const handleSubmit = (values) => {
    fields.forEach(function (v) {
      delete v.isEditable;
      delete v.isAllowDelete;
      delete v.key;
    });

    if (tab === 'LOCATION') {
      let venueEntrances = fields.filter(
        (entrance) => Object.keys(entrance).length !== 0
      );

      onSubmitParent(
        {
          businessVenue: {
            backoff: values?.backoff,
            venueEntrances,
          },
          businessCheckinSetup: {
            hasCongregationalCheckin: isCongregational,
            preCheckinArrivalTime: 0,
            selectedVenueEntranceIds: [],
          },
          businessLateCheckinSetup: {
            allowLateCheckin: true,
            lateCheckInTimeThreshold: values?.lateCheckInTimeThreshold,
          },
        },
        9,
        {
          success: CHECK_IN_MESSAGES.Success,
          error: CHECK_IN_MESSAGES.Error,
        }
      );
    } else {
      let venueEntrances = fields.filter(
        (entrance) => Object.keys(entrance).length !== 0
      );

      onSubmitParent(
        {
          businessVenue: {
            backoff: values?.backoff,
            venueEntrances,
          },
          businessCheckinSetup: {
            hasCongregationalCheckin: isCongregational,
            preCheckinArrivalTime: 0,
            selectedVenueEntranceIds: [],
          },
          businessLateCheckinSetup: {
            allowLateCheckin: true,
            lateCheckInTimeThreshold: values?.lateCheckInTimeThreshold,
          },
        },
        9,
        business.id,
        {
          success: CHECK_IN_MESSAGES.Success,
          error: CHECK_IN_MESSAGES.Error,
        }
      );
    }
  };


  return (
    <form onSubmit={onSubmit(handleSubmit)}>
      <Columns multiline>
        <Column size='4'>
          <div className='control'>
            <label className='label'>Processing Duration</label>
            <input
              type='number'
              min='0'
              className='input'
              {...register('backoff')}
            />
          </div>
        </Column>
        <Column size='6'>
          <div className='control'>
            <label className='label'>
              Customer can check-in <strong>X</strong>-min after appointment
              time
            </label>
            <input
              type='number'
              min='0'
              className='input'
              {...register('lateCheckInTimeThreshold')}
            />
          </div>
        </Column>
        <Column size='2' />
        <Column size='12'>
          <div className='control'>
            <label className='label'>
              Check-in entry points ({fields.length} of 10)
            </label>
            <small>Assigned seating or Not</small>
          </div>
        </Column>
        {fields.map(({ key, isEditable }, index) => (
          <Fragment>
            <Column size='3'>
              <div className='control'>
                <label className='label'>
                  Entry Name <span className='has-text-danger'>*</span>
                </label>
                <input
                  key={key}
                  disabled={!isEditable}
                  className={`input ${!isEmpty(errors) && errors?.entrances[index]?.entrances
                    ? 'is-danger'
                    : ''
                    }`}
                  {...register(`entrances.${index}.name`, { required: true })}
                />
                {!isEmpty(errors) && errors?.entrances[index]?.name && (
                  <p className='help is-danger'>Entrance name is required</p>
                )}
              </div>
            </Column>
            <Column size='3'>
              <div className='control'>
                <label className='label'>
                  Processing Capacity <span className='has-text-danger'>*</span>
                </label>
                <input
                  type='number'
                  min='0'
                  key={key}
                  disabled={!isEditable}
                  className={`input ${!isEmpty(errors) && errors?.entrances[index]?.capacity
                    ? 'is-danger'
                    : ''
                    }`}
                  {...register(`entrances.${index}.capacity`, {
                    required: true,
                    min: 1,
                  })}
                />
                {!isEmpty(errors) && errors?.entrances[index]?.capacity && (
                  <p className='help is-danger'>
                    The minimum processing capacity required is 1.
                  </p>
                )}
              </div>
            </Column>
            <Column size='2'>
              <div className='control'>
                <label className='label'>
                  Order <span className='has-text-danger'>*</span>
                </label>
                <input
                  type='number'
                  min='0'
                  key={key}
                  disabled={!isEditable}
                  className={`input ${!isEmpty(errors) && errors?.entrances[index]?.order
                    ? 'is-danger'
                    : ''
                    }`}
                  {...register(`entrances.${index}.order`, { required: true })}
                />
                {!isEmpty(errors) && errors?.entrances[index]?.order && (
                  <p className='help is-danger'>Order is required</p>
                )}
              </div>
            </Column>
            <Column size='2'>
              <div className='control'>
                <label className='label is-hidden-mobile'>&nbsp;</label>
                <label className='checkbox'>
                  <input
                    type='checkbox'
                    key={key}
                    disabled={!isEditable}
                    {...register(`entrances.${index}.isActive`)}
                  />
                  <strong className='ml-2'>Is Active ?</strong>
                </label>
              </div>
            </Column>
            <Column size='2'>
              <label className='label is-hidden-mobile'>&nbsp;</label>
              <Buttons size='small'>
                {isEditable ? (
                  <Fragment>
                    <Button
                      type='button'
                      color='info'
                      onClick={() => handleFieldMakeEditable(index)}
                    >
                      <Icon name='check' />
                    </Button>
                    {!isNewEntry && (
                      <Button
                        type='button'
                        color='info'
                        onClick={() => handleEntranceCancel(index)}
                      >
                        <Icon name='times' />
                      </Button>
                    )}
                  </Fragment>
                ) : (
                  <Button
                    type='button'
                    color='info'
                    onClick={() => handleFieldMakeEditable(index)}
                  >
                    <Icon name='pencil' />
                  </Button>
                )}
                {/*{!isNewEntry && isEditable && (
                  <Button
                    color='danger'
                    disabled={!isAllowDelete}
                    onClick={() => handleEntranceRemove(index)}
                  >
                    <Icon name='trash' />
                  </Button>
                )}*/}
              </Buttons>
            </Column>
          </Fragment>
        ))}
        {error && (
          <Column size='12'>
            <span className='has-text-danger'>{error}</span>
          </Column>
        )}
        <Column size='12'>
          <Link to='#' className='has-text-info' onClick={handleAddMore}>
            <Icon name='plus' className='mr-2' />
            Add more entrance
          </Link>
        </Column>
        <Column size='12'>
          <Button
            type='submit'
            color='info'
            disabled={isEmpty(dirtyFields) || isLoading}
            loading={isLoading}
          >
            Save
          </Button>
        </Column>
      </Columns>
    </form>
  );
};
