import React, { useState, useEffect } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { has } from 'lodash';
import OtpInput from 'react-otp-input';
import {
  Box,
  Column,
  Columns,
  Subtitle,
  Title,
  Button,
  Paragraph,
} from '@safelyq/bulma-ui-library';
import { useData } from './hooks';

export const PhoneVerification = ({ ...props }) => {
  const [code, setCode] = useState();

  const { state } = useLocation();

  const { push } = useHistory();

  useEffect(() => {
    function initialRunner() {
      if (!has(state, 'phoneNumber')) push('/account/setting');
    }
    initialRunner();
  }, []);

  const { onVerifyCode, onResendCode, isVerifyLoading, isResendLoading, } = useData(code);

  const handleCodeChange = (code) => setCode(code);

  const handleVerify = () => onVerifyCode();

  const handleRequestResend = (e) => {
    e.preventDefault();
    onResendCode();
  };

  const isVerifyEnable = String(code).length === 6;

  return (
    <Box>
      <Columns multiline>
        <Column size='12'>
          <center>
            <Title size='5'>
              A one-time password has been sent to your phone
            </Title>
            <Subtitle size='6'>
              Please enter the one-time verification code below
            </Subtitle>
          </center>
        </Column>
        <Column size='12'>
          <center>
            <img src={'/images/otp-message.svg'} width={130} alt='otp' />
          </center>
        </Column>
        <Column size='12'>
          <center>
            <OtpInput
              value={code}
              onChange={handleCodeChange}
              isInputNum={true}
              containerStyle={{
                width: 'fit-content',
              }}
              inputStyle={{
                background: 'whitesmoke',
                color: 'black',
                borderRadius: '3px',
                width: 40,
                height: 40,
                border: '1px solid grey',
              }}
              separator={<span>&nbsp;&nbsp;</span>}
              numInputs={6}
            />
          </center>
        </Column>
        <Column size='12'>
          <center>
            {isResendLoading ? (
              <Paragraph>Sending...</Paragraph>
            ) : (
              <Paragraph>
                Did not receive code{' '}
                <Link onClick={handleRequestResend}>Resend code</Link>
              </Paragraph>
            )}
          </center>
        </Column>
        <Column size='12'>
          <center>
            <Button
              color='info'
              onClick={handleVerify}
              disabled={!isVerifyEnable || isVerifyLoading}
              loading={isVerifyLoading}
            >
              Verify
            </Button>
          </center>
        </Column>
      </Columns>
    </Box>
  );
};
