import { gql } from '@apollo/client';

export const GET_USER_ORGANIZATIONS = gql`
  query all {
    getUserOrganizations {
      name
    id
    address1
    address2
    city
    contactName
    createdTime
    email
    modifiedTime
    phone
    state
    webAddress
    zipCode
    }
  }
`;