import React, { Fragment } from 'react'
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import moment from 'moment';

export const TemplateCouponPreview = ({ reorderedTemplateCoupon, campaignGroups, coupon }) => {

  const state = useSelector(state => state)
  const business = state?.business;
  return (
    <Fragment>
      {reorderedTemplateCoupon?.filter(template => campaignGroups.find(camp => camp.name === template.notificationChannelDispalyName && camp.isSelected)).map((template, index) => {
        return <MarketingBox key={index}>
          <p className='is-size-6'> <span className='has-text-weight-semibold'> Message Type: {template?.notificationChannelDispalyName}</span> </p>
          {/* <p>{template?.subjectData?.value}</p> */}
          <div style={{ width: '100%', wordWrap: 'break-word' }} dangerouslySetInnerHTML={{
            // Add extra line breaks on whatsapp template
            __html: template?.notificationChannelDispalyName === "WhatsApp"
              ?
              template?.contentData?.value
                .replace(":BusinessName:", `${business?.businessName}`)
                .replace(":CouponAmount:", `${coupon?.discount || "'Your Discount'"}${coupon?.discountType === "Percentage" ? "%" : "$"}`)
                .replace(":CouponEndDateTime:", coupon?.endDate ? moment(coupon?.endDate).format("LL") : "'Coupon Discount'")
                .replace(":CouponCode:", coupon?.code || "'Coupon Code'")
                .replace(":CouponTitle:", coupon?.title || "'Coupon Title'")
                .replace(":CouponURL:", coupon?.couponUrl || "'Coupon URL'")
                .replace(":CouponSummary:", coupon?.summary || "'Coupon Summary'")
                .replace(/\r\n/g, "<br />")
              :
              template?.contentData?.value
                .replace(":BusinessName:", business?.businessName)
                .replace(":CouponAmount:", `${coupon?.discount || "'Your Discount'"}${coupon?.discountType === "Percentage" ? "%" : "$"}`)
                .replace(":CouponEndDateTime:", coupon?.endDate ? moment(coupon?.endDate).format("LL") : "'Coupon Discount'")
                .replace(":CouponCode:", coupon?.code || "'Coupon Code'")
                .replace(":CouponTitle:", coupon?.title || "'Coupon Title'")
                .replace(":CouponURL:", coupon?.couponUrl || "'Coupon URL'")
                .replace(":CouponSummary:", coupon?.summary || "'Coupon Summary'")
          }} />

          <div className='mt-2'>
            {(template?.notificationChannelDispalyName === "MMS" || template?.notificationChannelDispalyName === "Email") && coupon?.imageUrl &&
              <StyledControl className='control is-relative'>
                <StyledImage src={coupon?.imageUrl} />
              </StyledControl>
            }
          </div>
        </MarketingBox>
      })}
    </Fragment>
  )
}

const MarketingBox = styled.div`
  border: 2px solid #50677736;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 16px
`;

const StyledImage = styled.img`
      @media only screen and (min-width: 601px){
  width: 50%;
  height: 200px;
  object-fit: contain;
  margin-bottom: 8px;
  border-radius: 12px;
    }
    @media only screen and (max-width: 600px){
  width: 100%;
  height: 200px;
  object-fit: contain;
  margin-bottom: 8px;
  border-radius: 12px;
    }
`;

const DeleteButton = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const StyledControl = styled.div`
  &:hover ${DeleteButton} {
    display: flex;
  }
`;