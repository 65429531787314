import { oneOf } from 'prop-types';
import styled from 'styled-components';
import MessageHeader from './styles/MessageHeader';
import MessageBody from './styles/MessageBody';

const className = (strings, props) => {
  const classNames = ['message']
    .concat(
      ['color', 'size']
        .map((name) => (props[name] ? `is-${props[name]}` : null))
        .filter((name) => !!name)
    )
    .concat(
      ['background']
        .map((name) => (props[name] ? `has-background-${props[name]}` : null))
        .filter((name) => !!name)
    )
    .join(' ');

  return classNames;
};

const Message = styled.div.attrs((props) => ({
  className: className`${props}`,
}))``;

Message.Header = MessageHeader;
Message.Body = MessageBody;

Message.propTypes = {
  color: oneOf([
    'black',
    'white',
    'dark',
    'light',
    'text',
    'primary',
    'link',
    'info',
    'success',
    'warning',
    'danger',
  ]),
  background: oneOf([
    'black',
    'white',
    'dark',
    'light',
    'text',
    'primary',
    'link',
    'info',
    'success',
    'warning',
    'danger',
  ]),
  size: oneOf(['small', 'normal', 'medium', 'large']),
};

Message.defaultProps = {
  color: undefined,
  background: undefined,
  size: undefined,
};

export default Message;
