import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { Icon, List, Button } from '@safelyq/bulma-ui-library';
import { useProfile } from 'hooks/useProfile';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveItem } from 'Redux/MiscSlice/Misc';

const StyledTag = styled.span.attrs(() => ({
  className: 'tag is-info ml-2',
}))`
  position: absolute;
`;

export const MenuList = ({ ...props }) => {
  const { businessId, isSuperAdmin, onLogout, toggle } = props;
  const dispatch = useDispatch()

  const businessCategory = useSelector(state => state?.business?.businessCategory);
  const activeItem = useSelector(state => state?.misc?.activeItem)
  const isAssociate = useSelector(state => state?.business?.isAssociate);
  const messageCountGlobal = useSelector((state) => state?.business?.messageCount);

  let { pathname } = useLocation();

  const { logout } = useProfile();

  if (pathname.charAt(pathname.length - 1) === '/') {
    pathname = pathname.slice(0, pathname.length - 1);
  }

  const firstIndex = String(pathname).indexOf('/');

  const lastIndex = String(pathname).lastIndexOf('/');

  pathname = String(pathname).substring(firstIndex, lastIndex);

  const isActiveLogs = isEqual(pathname, '/admin/appointments-log');

  const isActiveAppointments = isEqual(pathname, '/admin/appointments');

  const isActiveNewAppointment = isEqual(pathname, '/admin/create-appointment');

  const isActiveForms = isEqual(pathname, '/admin/forms');

  const isActiveNewForms = isEqual(pathname, '/admin/create-form');

  const isActiveReportSummary = isEqual(pathname, '/admin/reports-summary');

  const isActiveManageHolidays = isEqual(pathname, '/admin/manage-business');

  const isActiveManageConsole = isEqual(pathname, '/admin/manage-console');

  const isActiveManageReserveWithGoogle = isEqual(pathname, '/admin/reserve-with-google');

  const ispricing = isEqual(pathname, '/admin/pricing');

  const isStripeAccount = isEqual(pathname, '/admin/payment');

  const isActivePromotions = isEqual(
    pathname,
    '/admin/coupons'
  );

  const isActiveMessages = isEqual(pathname, '/admin/messages');

  const isActiveViewCampaign = isEqual(pathname, '/admin/view-campaign');

  const isActiveCreateCampaign = isEqual(pathname, '/admin/create-campaign');
  const isActiveManageFiles = isEqual(pathname, '/admin/manage-files');

  const isActiveReviews = isEqual(pathname, '/admin/manage-reviews');

  const isActiveUsers = isEqual(pathname, '/admin/manage-users');

  const handleToggle = (index) => {
    dispatch(setActiveItem(activeItem === index ? null : index));
  };

  const isActive = (index) => {
    return activeItem === index ? 'is-active' : '';
  };

  const handleLogout = () => {
    localStorage.setItem('post_logout_redirect_url', '/admin');
    localStorage.setItem("authPersist", "false") // change the logged in flag to false
    logout();
  };

  // const handleLogoutAdmin = () => {
  //   localStorage.setItem('post_logout_redirect_url', '/admin');
  //   onLogout();
  // };

  // if (isError) return <LoadingSkeleton />;

  return (
    <>
      {/* hide only on desktop, widescreen */}
      <div className='navbar-item pl-0 is-hidden-desktop' title='Profile'>

        {isSuperAdmin && (
          <Link to="/superadmin/home" className="ml-3">
            {/* <Icon name='user' className='mr-3' /> */}
            <Button size="small" color="info" style={{ marginRight: "10px" }}>
              Super Admin
            </Button>
          </Link>
        )}

        <div className='is-flex mt-3 is-hidden-tablet'>
          <Icon name='gear' className='mr-3 ml-3' />
          <Link to='/admin/account/setting' className='dropdown-item p-0 '>  Account Settings </Link>
        </div>

      </div>
      <hr className='my-0 mb-2 is-hidden-tablet' />

      <List type='unordered' className='menu-list'>
        <List.Item className='my-2'>
          <Link to={`/admin/appointments-log/${businessId}`} onClick={toggle} className={`${isActiveLogs ? 'is-active button-icon' : ''}`} >
            <Icon name='home' className='mr-3' />  <b>Dashboard</b>
          </Link>
        </List.Item>
        <List.Item className='my-2'>
          {/* <Link to={`/admin/appointments/${businessId}`} */}
          <p
            style={{ marginLeft: "12px", marginTop: "18px", marginBottom: "18px", cursor: "pointer" }}
            onClick={() => {
              toggle();
              handleToggle(1);
            }}
            className={isActive(1)}
          >
            <Icon name='calendar' className='mr-3' />
            <b>Appointments</b>
            {isActive(1) ? <Icon name='angle-up' className='ml-3' /> : <Icon name='angle-down' className='ml-3' />}
          </p>
          {/* </Link> */}
          <ul
            style={{ display: isActive(1) ? 'block' : 'none' }}
          >
            <li>
              <Link to={`/admin/appointments/${businessId}`} onClick={toggle} className={`${isActiveAppointments ? 'is-active button-icon' : ''}`}   >
                View list

              </Link>
            </li>
            <li>
              <Link to={`/admin/create-appointment/${businessId}`} onClick={toggle} className={`${isActiveNewAppointment ? 'is-active button-icon' : ''}`}   >
                Create new
              </Link>
            </li>
          </ul>
        </List.Item>
        <List.Item className='my-2'>
          <p
            style={{ marginLeft: "12px", marginTop: "24px", marginBottom: "18px", cursor: "pointer" }}
            onClick={() => {
              toggle();
              handleToggle(2);
            }}
            className={isActive(2)}
          >
            <Icon name='calendar' className='mr-3' /><b>Forms</b>
            {isActive(2) ? <Icon name='angle-up' className='ml-3' /> : <Icon name='angle-down' className='ml-3' />}
          </p>

          <ul
            style={{ display: isActive(2) ? 'block' : 'none' }}
          >
            <li>
              <Link to={`/admin/forms/${businessId}`} onClick={toggle} className={`${isActiveForms ? 'is-active button-icon' : ''}`} >  View list </Link>
            </li>
            <li>
              <Link to={`/admin/create-form/${businessId}`} onClick={toggle} className={`${isActiveNewForms ? 'is-active button-icon' : ''}`}  > Create new </Link>
            </li>
          </ul>
        </List.Item>
        <List.Item className='my-2'>
          <Link to={`/admin/messages/${businessId}`} onClick={toggle} className={`${isActiveMessages ? 'is-active button-icon' : ''}`} >
            <Icon name='comments' className='mr-3' /> <b>Messages</b> {messageCountGlobal ? (<StyledTag>{messageCountGlobal > 9 ? '9+' : messageCountGlobal}</StyledTag>) : null}
            {/* <Icon name='comments' className='mr-3' /> <b>Messages</b> {unreadMessages ? (<StyledTag>{unreadMessages > 9 ? '9+' : unreadMessages}</StyledTag>) : null} */}
          </Link>
        </List.Item>
        <List.Item className='my-2'>
          <p
            style={{ marginLeft: "12px", marginTop: "18px", marginBottom: "18px", cursor: "pointer" }}
            onClick={() => {
              toggle();
              handleToggle(3);
            }}
            className={isActive(3)}
          >
            <Icon name='bullhorn' className='mr-3' />  <b>Campaign</b>
            {isActive(3) ? <Icon name='angle-up' className='ml-3' /> : <Icon name='angle-down' className='ml-3' />}
          </p>

          <ul
            style={{ display: isActive(3) ? 'block' : 'none' }}
          >
            <li> <Link to={`/admin/view-campaign/${businessId}`} onClick={toggle} className={`${isActiveViewCampaign ? 'is-active button-icon' : ''}`} >  View List </Link> </li>
            <li> <Link to={`/admin/create-campaign/${businessId}`} onClick={toggle} className={`${isActiveCreateCampaign ? 'is-active button-icon' : ''}`} >  Create New </Link> </li>
            <li> <Link to={`/admin/manage-files/${businessId}`} onClick={toggle} className={`${isActiveManageFiles ? 'is-active button-icon' : ''}`} >  Manage Files </Link> </li>
          </ul>
        </List.Item>
        <List.Item className='my-2'>
          <Link to={`/admin/manage-reviews/${businessId}`} onClick={toggle} className={`${isActiveReviews ? 'is-active button-icon' : ''}`} >
            <Icon name='star' className='mr-3' /> <b>Reviews</b>
          </Link>
        </List.Item>
        <List.Item className='my-2'>
          <p
            style={{ marginLeft: "12px", marginTop: "18px", marginBottom: "18px", cursor: "pointer" }}
            onClick={() => {
              toggle();
              handleToggle(4);
            }}
            className={isActive(4)}
          >
            <Icon name='file' className='mr-3' />  <b>Reports</b>
            {isActive(4) ? <Icon name='angle-up' className='ml-3' /> : <Icon name='angle-down' className='ml-3' />}
          </p>
          <ul
            style={{ display: isActive(4) ? 'block' : 'none' }}
          >
            <li>
              <Link to={`/admin/reports-summary/${businessId}`} onClick={toggle} className={`${isActiveReportSummary ? 'is-active button-icon' : ''}`} >
                Summary
              </Link>
            </li>
          </ul>
        </List.Item>
        <List.Item className='my-2'>
          <Link to={`/admin/manage-business/${businessId}`} onClick={toggle} className={`${isActiveManageHolidays ? 'is-active button-icon' : ''}`} >
            <Icon name='cogs' className='mr-3' /> <b>Manage Business</b>
          </Link>
        </List.Item>

        {
          // If the business category supports Google Reserve, only than check if the subscription plan includes Google Reserve
          businessCategory?.features?.googleReserveAllowed &&
          <List.Item className='my-2'>
            <Link to={`/admin/reserve-with-google/${businessId}`} onClick={toggle} className={`${isActiveManageReserveWithGoogle ? 'is-active button-icon' : ''}`} >
              <Icon name='google' className='mr-3' /><b>Reserve With Google</b>
            </Link>
          </List.Item>
        }

        <List.Item className='my-2'>
          <Link to={`/admin/pricing/${businessId}`} onClick={toggle} className={`${ispricing ? 'is-active button-icon' : ''}`} >
            <Icon name='money' className='mr-3' /><b>Subscription</b>
          </Link>
        </List.Item>

        <List.Item className='my-2'>
          <Link to={`/admin/payment/${businessId}`} onClick={toggle}
            className={`${isStripeAccount ? 'is-active button-icon' : ''}`}
          >
            <IconCoin>
              $
            </IconCoin>
            <b className='ml-2'>Payment Setup and Management</b>
          </Link>
        </List.Item>

        {businessCategory?.features?.couponAllowed &&
          <List.Item className='my-2'>
            <Link to={`/admin/coupons/${businessId}`} onClick={toggle} className={`${isActivePromotions ? 'is-active button-icon' : ''}`}  >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon name='gift' className='mr-3' />
                <div>
                  <b>Coupons/
                    <br />
                    <span>Promotions</span>
                  </b>
                </div>
              </div>
            </Link>
          </List.Item>
        }

        <List.Item className='my-2'>
          <Link to={`/admin/manage-console/${businessId}`} onClick={toggle} className={`${isActiveManageConsole ? 'is-active button-icon' : ''}`} >
            <Icon name='image' className='mr-3' /> <b>Console</b>
          </Link>
        </List.Item>
        {
          !isAssociate && (
            <List.Item className='my-2'>
              <Link to={`/admin/manage-users/${businessId}`} onClick={toggle} className={`${isActiveUsers ? 'is-active button-icon' : ''}`} >
                <Icon name='user' className='mr-3' />  <b>Users</b>
              </Link>
            </List.Item>
          )
        }

        <List.Item className='my-2' onClick={handleLogout}>
          <a onClick={toggle}>  <Icon name='power-off' className='mr-3' />  <b>Logout</b>   </a>
        </List.Item>
      </List >
    </>
  );
};

const LoadingSkeleton = () => {
  return (
    <List type='unordered' className='menu-list'>
      {[...Array(9)].map(() => (
        <List.Item className='my-4'>
          <Skeleton width={20} height={20} className='mr-2' />
          <Skeleton width={160} height={20} />
        </List.Item>
      ))}
    </List>
  );
};

const IconCoin = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #4d4d4d;
  text-align: center;
  line-height: 17px;
  font-weight: bold;
  color: #4a4a65;
  font-size: 12px;
`;