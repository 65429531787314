export const INITIAL_BUSINESS_AND_ORGANIZATION_DATA = {
    getBusinessById: {
        region: {
            id: 233,
            name: 'United States'
        },
        country: 'United States'
    },
  getUserOrganizations: [],
  getBusinessCategories: [],
  getCountries: [],
};
