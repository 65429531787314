import { gql } from '@apollo/client';

const CORE_BUSINESS_FIELDS = gql`
  fragment CoreBusinessFields on Business {
    id
    name
    wifiNames
    categoryTags
    city
    state
    timeZone
    country
    zipCode
    email
    phone
    isActive
    webAddress
    safetyTags
    address1
    address2
    description
    totalCapacity
    allowedCapacityAsPercentage
    allowedCapacityInUnits
    startingOffset
    appointmentGap
    endingOffset
    maxAllowedGuests
    isChildrenAllowed
    oldAppointmentsThreshold
    specialInstructions
    preCheckinInstructions
    appointmentConfirmationOffset
    isAppointmentConfirmationRequired
    lateCheckInTimeThreshold
    hasCongregationalCheckin
    autoCheckinRadius
    allowUserSchedule
    allowBusinessSchedule
    canBusinessOptIn
    messaging {
      messagingAllowed
      consumerResponseAllowed
      consumerAttachmentsAllowed
      consumerInitiationAllowed
    }
    logoUrl
    organization {
      id
    }
    businessCategory {
      name
      features{
				couponAllowed
				googleReserveAllowed
			}
    }
     businessSubscription {
      subscriptionPlan {
        features {
          advanceAppointmentAllowed
        }
        name
        subscriptionTierType
      }
    }
    menus {
      id
      path
    }
    pictures {
      id
      path
    }
    businessPermissions {
      isAdmin
      isAssociate
    }
    businessCoupons {
      id
      title
      code
      couponUrl
      imageUrl
      couponUsageLimit
      couponUsageLimitValue
      expireStatus
      discount
      discountType
      startDate
      endDate
      summary
      terms
      isActive
    }
    services {
      id
      name
      duration
      isActive
      isGoogleReserve
      businesses {
        id
        name
        isActive
      }
    }
    organization {
      id
      name
    }
    questions {
      id
      prompt
      textYes
      textNo
      isActive
      isUserResponseRequired
    }
    details {
      offset
    }
    businessVenue {
      venue {
        details {
          backoff
        }
        entrances {
          id
          capacity
          isActive
          name
          order
        }
      }
    }
    region {
      id
      name
      holidays {
        id
        name
        dayOfWeek
        date
        dayOfYear
        baseDayOfYear
      }
    }
    businessHours {
      id
      dayOfYear
      closingTime
      dayOfWeek
      date
      openingTime
    }
    businessHolidays {
      id
      dayOfWeek
      date
      baseDayOfYear
      dayOfYear
      isFullDayOff
      specialHours {
        openingTime
        closingTime
      }
    }
  }
`;

export const GET_BUSINESS_AND_ORGANIZATION = gql`
  ${CORE_BUSINESS_FIELDS}
  query ($id: Int) {
    getBusinessById(id: $id, showAll: true) {
      ...CoreBusinessFields
      appointmentTemplates {
        name
        notificationTemplateType
        templates {
          notificationChannel
          notificationChannelDispalyName
          contentData {
            id
            value
          }
          subjectData {
            id
            value
          }
        }
      }
      templatesReplacements {
        name
        value
      }
      __typename
      children {
        ...CoreBusinessFields
        __typename
      }
    }
    getCountries {
      id
      name
      timeZones {
        id
        name
        tzName
      }
      states {
        id
        name
      }
    }
    getBusinessCategories {
      name
      description
    }
    getUserOrganizations {
      id
      name
    }
  }
`;

export const SAVE_BUSINESS = gql`
  mutation SaveBusiness($businessInput: BusinessInput!) {
    saveBusiness(businessInput: $businessInput) {
      errorMessage
      isSaved
      business {
        id
      }
      addressValidationResult {
        errors
        isValidAddress
        googleAddresses {
          address1
          address2
          city
          country
          formattedAddress
          latitude
          locationType
          longitude
          postalCode
          postalCode
          state
        }
      }
    }
  }
`;

export const UPDATE_SERVICE_PROVIDER_STATUS = gql`
  mutation updateServiceProviderStatus(
    $businessId: Int
    $isActive: Boolean
    $serviceProviderId: Int
  ) {
    updateServiceProviderStatus(
      businessId: $businessId
      isActive: $isActive
      serviceProviderId: $serviceProviderId
    ) {
      errorMessage
      isSaved
    }
  }
`;

export const UPDATE_BUSINESS_ONLINE_STATUS = gql`
  mutation updateBusinessOnlineStatus(
    $businessId: Int
    $onlineStatus: Boolean
  ) {
    updateBusinessOnlineStatus(
      businessId: $businessId
      onlineStatus: $onlineStatus
    ) {
      missingValues
      errorMessage
      isSuccess
    }
  }
`;
