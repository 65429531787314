import React from 'react';
import styled from 'styled-components';
import { Box } from '@safelyq/bulma-ui-library';

const Panel = styled(Box).attrs(() => ({
  className: 'has-background-white p-0 mb-0',
}))`
  max-height: ${({ noOfSlides }) => noOfSlides + 'px'};
  border-radius: 0%;
  overflow: scroll;
`;

export const AccordionPanel = ({ children, noOfSlides, ...restProps }) => {
  return (
    <Panel noOfSlides={noOfSlides * 57 + 18} bordered {...restProps}>
      {children}
    </Panel>
  );
};
