import React from 'react'
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import { TextInput, Text, Icon, Button, Title, Subtitle, } from '@safelyq/bulma-ui-library';

const StyledDropzone = styled.div.attrs(() => ({}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  padding: 20px;
`;
const FormsDropZone = ({ businessGoogleForms, onFormSend }) => {
    const handleFormSend = (fromurl, title) => {
        onFormSend(fromurl, title)
    }
    return (
        <Dropzone multiple={false}>
            {({ getRootProps, getInputProps }) => (
                <StyledDropzone {...getRootProps({ className: 'dropzone' })}>
                    <Title size='6'>Select Forms</Title>
                    <hr />
                    <table className='table is-fullwidth'>
                        <thead>
                            <tr>
                                <th>Title </th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {businessGoogleForms?.map((form, index) => (
                                <tr>
                                    <td>{form?.formTitle}</td>
                                    <td>
                                        <Button size='small' color='info' onClick={() => handleFormSend(form.viewFormUrl, form.formTitle)}>     Send    </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </StyledDropzone>
            )}
        </Dropzone>
    )
}

export default FormsDropZone
