import { useQuery, useMutation } from '@apollo/client';
import { GET_BUSINESS_AND_ORGANIZATION, SAVE_BUSINESS } from './requests';


export const useSaveBusinessInfo = (refetch) => {
    const [onSaveBusiness, { loading: isSaveLoading }] = useMutation(SAVE_BUSINESS,);
    return { isSaveLoading, onSaveBusiness, };
};


export const useGetBusinessInfo = (businessID) => {
    const { loading, data, refetch, } = useQuery(GET_BUSINESS_AND_ORGANIZATION, { variables: { id: businessID }, fetchPolicy: 'no-cache', });

    return { loading, data, refetch }
};
