// import React, { useEffect, useState } from 'react';
// import styled from 'styled-components';
// import {
//   Section,
//   Container,
//   Columns,
//   Column,
//   Field,
//   Control,
//   Title,
//   Icon,
//   Button,
//   TextInput,
// } from '@safelyq/bulma-ui-library';
// import { Loader } from '@safelyq/base-feature';
// import { MembersList, AddNewMember } from '../../components';
// import { useData } from './hooks';

// const StyledSearchBar = styled(TextInput).attrs(() => ({}))`
//   background: rgba(0, 0, 0, 0.02);
// `;

// export const ManageMembers = () => {
//   const [members, setMembers] = useState();

//   const [isOpen, setOpen] = useState(false);

//   const { isLoading, data, refetch } = useData();

//   useEffect(() => {
//     const { businessLocationUsers } = data || {};

//     setMembers(businessLocationUsers?.userPermissions || []);
//   }, [data]);

//   const handleTextChange = ({ target: { value } }) => {
//     const { businessLocationUsers } = data || {};

//     if (!value) setMembers(businessLocationUsers?.userPermissions || []);
//     else {
//       const filteredMembers = members.filter(({ user }) =>
//         String(user.name).toLowerCase().includes(String(value).toLowerCase())
//       );
//       setMembers(filteredMembers);
//     }
//   };

//   const handleModalOpen = () => setOpen(true);

//   const handleModalClose = () => setOpen(false);

//   if (isLoading) return <Loader isFullScreen={true} />;

//   const { businessSubLevels } = data || {};

//   return (
//     <Section>
//       <Container>
//         <Title size='5'>
//           <Icon name='user' className='mr-3' />
//           Users
//         </Title>
//         <Columns multiline>
//           <Column size='4'>
//             <Field>
//               <Control>
//                 <StyledSearchBar
//                   onChange={handleTextChange}
//                   placeholder={'Find user'}
//                 />
//                 <Icon name='search' position='right' />
//               </Control>
//             </Field>
//           </Column>
//           <Column size='8'>
//             <Button color='info' className='is-right' onClick={handleModalOpen}>
//               Add user
//             </Button>
//           </Column>
//           <Column size='12'>
//             <MembersList members={members} refetch={refetch} />
//           </Column>
//           <Column size='12'>
//             <AddNewMember
//               isOpen={isOpen}
//               onClose={handleModalClose}
//               refetch={refetch}
//               businessSubLevels={businessSubLevels}
//             />
//           </Column>
//         </Columns>
//       </Container>
//     </Section>
//   );
// };














import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_BUSINESS_USERS } from './requests'
import { useParams } from 'react-router-dom';
import { Button, Field, Control, TextInput, Icon, Title } from '@safelyq/bulma-ui-library';
import MembersPopup from './MembersPopup';
import AllMembers from './AllMembers';
import styled from 'styled-components';
import { INITIAL_BUSINESS_DATA } from './constants';
import { useEffect } from 'react';

const StyledSearchBar = styled(TextInput).attrs(() => ({}))`
  background: rgba(0, 0, 0, 0.02);
`;
export const ManageMembers = () => {
  const [allMembers, setallMembers] = useState([])
  const [allshadowMembers, setallshadowMembers] = useState([])

  const [userPopup, setUserPopup] = useState(false)
  const [allserviceProviders, setallServiceProviders] = useState([])
  const [selectedServiceProviders, setselectedServiceProviders] = useState([])
  const [selectedOptions, setselectedOptions] = useState({
    user: null,
    roleName: null
  })



  const { id } = useParams();
  const { data = INITIAL_BUSINESS_DATA, refetch } = useQuery(GET_BUSINESS_USERS, {
    variables: { id, showAll: true }
  })

  // Call the api when the api renders
  useEffect(() => {
    refetch();
  }, [])

  useEffect(() => {
    setallMembers(data?.getBusinessById?.businessLocationUsers?.userPermissions);
    setallshadowMembers(data?.getBusinessById?.businessLocationUsers?.userPermissions);
    const updatedSP = data?.getBusinessById?.businessSubLevels?.map((sp) => ({ ...sp, status: false }));
    setallServiceProviders(updatedSP);
    setselectedServiceProviders(updatedSP);
    setselectedOptions({
      user: null,
      roleName: null
    })
  }, [data])

  const OpenModal = () => {
    setUserPopup(true)
  }

  const closeModal = (status) => {
    if (status === false) {
      setselectedServiceProviders(allserviceProviders)
      setselectedOptions({
        user: null,
        roleName: null
      })
    }

    setUserPopup(false)
  }

  const handleTextChange = (e) => {
    if (e.target.value) {
      const filteredMembers = allshadowMembers.filter(({ user }) => String(user.name).toLowerCase().includes(String(e.target.value).toLowerCase()));
      setallMembers(filteredMembers);
    }
    else {
      setallMembers(allshadowMembers)
    }
  }

  const handleMemberClick = (user, roleName, serviceP) => {

    const spdrs = allserviceProviders.map(item => ({
      id: item.id,
      name: item.name,
      status: serviceP.some(sp => sp.serviceProvider.id === item.id),
    }));
    setselectedOptions({
      user: user,
      roleName: roleName
    })
    setselectedServiceProviders(spdrs)
    setUserPopup(true)
  }

  return (
    <>
      {userPopup && <MembersPopup isOpen={userPopup} onClose={closeModal} allserviceProviders={allserviceProviders} selectedServiceProviders={selectedServiceProviders} selectedOptions={selectedOptions} refetch={refetch} />}
      <div className='bscontainer'>
        <div className='row mt-5'>
          <div className='col-lg-12 mb-4'>
            <Title size='5'>  <Icon name='user' className='mr-3' />    Users    </Title>
          </div>
          <div className='col-lg-4 mb-4'>
            <Field>
              <Control>
                <StyledSearchBar onChange={handleTextChange} placeholder={'Find user'} />
                <Icon name='search' position='right' />
              </Control>
            </Field>
          </div>
          <div className='col-lg-8 mb-4'>
            <Button color='info' className='is-right' onClick={OpenModal}>Add user</Button>
          </div>
          <div className='col-lg-12'>
            {/* <MembersList members={allMembers} refetch={refetch} /> */}
            <AllMembers allMembers={allMembers} refetch={refetch} onMemberClick={handleMemberClick} />
          </div>
        </div>
      </div>
    </>
  )
}

