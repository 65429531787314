import { useParams } from 'react-router-dom';
import { toast as showToast } from 'react-toastify';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { GET_BUSINESS_CAMPAIGNS, CHANGE_CAMPAIGN_STATUS, DELETE_CAMPAIGN, GET_SINGLE_CAMPAIGN } from './requests';
import { INITIAL_DATA } from './constants';

export const useData = ({ onPopulateData }) => {
    // console.log("campaign variables:", campaignVariables);
    const { businessId } = useParams();

    const id = parseInt(businessId);

    // const {
    //     loading: isLoading,
    //     error,
    //     data = INITIAL_DATA,
    //     refetch,
    // } = useQuery(GET_BUSINESS_CAMPAIGNS, {
    //     fetchPolicy: 'no-cache',
    //     notifyOnNetworkStatusChange: true,
    //     variables: { id },
    //     onCompleted: ({ getBusinessById: { campaigns } }) => onPopulateData(campaigns),
    //     onError: () => {
    //         showToast.error('There was a problem. Data could not be loaded.');
    //     }
    // });

    const [
        getUserCampaigns,
        {
            loading: isLoading,
            error,
            data = INITIAL_DATA,
            refetch,
        }
    ] = useLazyQuery(GET_BUSINESS_CAMPAIGNS, {
        // variables: { selectCampaignsInput: campaignVariables },
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: ({ getCampaigns }) => {
            onPopulateData(getCampaigns?.campaigns)
        },
        onError: () => {
            showToast.error('There was a problem. Data could not be loaded.');
        }
    });

    // Campaign Cancel Mutation
    const { getCampaigns } = data

    const [onUpdateCampaignStatus, { data: cancel, loading: isCancelLoading }] = useMutation(
        CHANGE_CAMPAIGN_STATUS,
        {
            fetchPolicy: 'no-cache', // Prevents Apollo Client from using the cache
            onCompleted: (cancelledData) => {
                showToast.success('Campaign status has been changed');
            },
            onError: () => {
                showToast.error('Error Occur in cancel campaign');
            },
        }
    );

    const handleCampaignStatusChange = (status, campaignId) => {
        onUpdateCampaignStatus({
            variables: {
                campaignId: campaignId,
                newStatus: status,
            },
        });
    };

    return {
        getUserCampaigns,
        isLoading,
        error,
        campaigns: getCampaigns?.campaigns,
        totalCount: getCampaigns?.totalCount,
        refetch,
        onChangeCampaignStatus: handleCampaignStatusChange,
        isCancelLoading,
        cancelledMessage: cancel?.updateCampaignStatus?.message,
    };
};

export const useDeleteData = () => {
    const [onDeleteComplete, { loading: deleteLoading }] = useMutation(DELETE_CAMPAIGN,
        {
            onCompleted: async ({ deleteCampaign }) => {
                const { isSaved, errorMessage } = deleteCampaign;
                if (isSaved) {
                    showToast.success('Campaign Deleted Successfully');
                }
                else {
                    showToast.error(errorMessage || "Error Occured in deleting campaign")
                }
            },
            onError: (error) => {
                showToast.error(error.message || 'Error Occured in deleting campaign');
            },
        });

    const deleteCampaign = async (campaignId) => {
        try {
            await onDeleteComplete({ variables: { campaignId } })
        } catch (error) {
            // Handle error
            // console.error('Error deleting campaign:', error);
        }
    };

    return {
        deleteLoading,
        deleteCampaign
    }
}

export const useSingleCampaign = () => {
    const [fetchCampaign, { loading: campaignLoading, data: campaignData, error: campaignError, refetch: refetchSingleCampaign }] = useLazyQuery(GET_SINGLE_CAMPAIGN);

    return {
        fetchCampaign,
        campaignData: campaignData?.getCampaignById,
        campaignLoading,
        campaignError,
        refetchSingleCampaign
    }
}