import styled from 'styled-components';
import LevelItem from './styles/LevelItem';
import LevelLeft from './styles/LevelLeft';
import LevelRight from './styles/LevelRight';

const className = (strings, props) => {
  const classNames = ['level'].join(' ');

  return classNames;
};

const Level = styled.div.attrs((props) => ({
  className: className`${props}`,
}))``;

Level.Item = LevelItem;
Level.Left = LevelLeft;
Level.Right = LevelRight;

Level.propTypes = {};

Level.defaultProps = {};

export default Level;
