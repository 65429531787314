import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Icon } from '@safelyq/bulma-ui-library';

export const TabList = () => {
  const { state } = useLocation() || {};

  const { currentMenu } = state || { currentMenu: 0 };

  return (
    <div className='tabs is-small is-hidden-tablet'>
      <ul>
        <li className={currentMenu === 0 ? 'is-active' : ''}>
          <Link
            to={{
              pathname: '/account/setting',
              state: { currentMenu: 0 },
            }}
          >
            <Icon name='user' />
            <span>Profile</span>
          </Link>
        </li>
        <li className={currentMenu === 1 ? 'is-active' : ''}>
          <Link
            to={{
              pathname: '/account/notifications',
              state: { currentMenu: 1 },
            }}
          >
            <Icon name='bell' /> <span>Notifications</span>
          </Link>
        </li>
        <li className={currentMenu === 4 ? 'is-active' : ''}>
          <Link
            to={{
              pathname: '/account/marketing-notifications',
              state: { currentMenu: 4 },
            }}
          >
            <Icon name='bell' /> <span>Marketing Notifications</span>
          </Link>
        </li>
        <li className={currentMenu === 2 ? 'is-active' : ''}>
          <Link
            to={{
              pathname: '/account/change-password',
              state: { currentMenu: 2 },
            }}
          >
            <Icon name='lock' />
            <span>Change Password</span>
          </Link>
        </li>
        <li className={currentMenu === 3 ? 'is-active' : ''}>
          <Link
            to={{
              pathname: '/account/change-password',
              state: { currentMenu: 3 },
            }}
          >
            <Icon name='trash' />
            <span>Delete Account</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};
