import { bool, oneOf, oneOfType } from 'prop-types';
import styled from 'styled-components';

const narrow = (strings, value) => {
  if ([
    'mobile',
    'tablet',
    'touch',
    'desktop',
    'widescreen',
    'fullhd'
  ].includes(value)) {
    return `is-narrow-${value}`;
  }

  if (value === true || value === false) {
    return value ? `is-narrow` : null;
  }

  return null;
};

const className = (strings, props) => {
  const classNames = [
    'column'
  ]
    .concat(
      [
        'narrow'
      ]
        .map((name) => narrow`${props[name]}`)
        .filter((className) => !!className)
    )
    .concat(
      [
        'size'
      ]
        .map((name) => props[name] ? `is-${props[name]}` : null)
        .filter((className) => !!className)
    )
    .concat(
      [
        'offset'
      ]
        .map((name) => props[name] ? `is-offset-${props[name]}` : null)
        .filter((className) => !!className)
    )
    .join(' ');

  return classNames;
};

const Column = styled.div.attrs((props) => ({
  className: className`${props}`
}))``;

Column.propTypes = {
  narrow: oneOfType([
    bool,
    oneOf(
      [
        'mobile',
        'tablet',
        'touch',
        'desktop',
        'widescreen',
        'fullhd'
      ]
    )
  ]),
  size: oneOf(
    [
      'full',
      'half',
      'one-quarter',
      'one-fifth',
      'one-third',
      'two-thirds',
      'three-quarters',
      'two-fifths',
      'four-fifths',
      'three-fifths',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12'
    ]
  ),
  offset: oneOf(
    [
      'full',
      'half',
      'one-quarter',
      'one-fifth',
      'one-third',
      'two-thirds',
      'three-quarters',
      'two-fifths',
      'four-fifths',
      'three-fifths',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12'
    ]
  )
};

Column.defaultProps = {
  narrow: undefined,
  breakpoint: undefined,
  size: undefined,
  offset: undefined
};

export default Column;
