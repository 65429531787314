import React, { Fragment } from 'react'
import { Title, Subtitle, Icon, Button } from '@safelyq/bulma-ui-library';
import styled from 'styled-components';
import { Spin, Tooltip } from 'antd';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

export const ConsoleImages = (props) => {

    const extensions = {
        image: {
            type: ['jpeg', 'jpg', 'gif', 'png', 'bmp', 'tiff'],
            preview: [' image/jpeg ', ' image/jpg ', ' image/gif ', ' image/png ', ' image/bmp ', ' image/tiff ']
        }
    }

    const {
        consoleImages,
        handleUploadImages,
        handleDeleteImage,
        onSaveImages,
        savedLoading,
        consoleDetails,
        setConsoleDetails,
        isDeleteLoading,
        setAllowRefetch,
        setLocallyUploadedImages,
        disableSave,
        setCanCompare,
        setUploadImageLoading,
        uploadImageLoading,
        setDisableSave,
        fileKey,
        setFileKey,
    } = props;

    const { id } = useParams();

    const onUploadBusinessImage = async (e) => {
        // if nothing exists then return
        if (!e.target.files[0]) {
            return;
        }

        // Set unique key for every file to allow re upload
        setFileKey((prev) => prev + 1)

        // Image upload Loading
        setUploadImageLoading(true);

        const totalImages = Number(e.target.files.length) + Number(consoleImages.length);

        // Maximum images validation
        if (totalImages > 10) {
            setUploadImageLoading(false);
            return toast.error("Maximum 10 images are allowed")
        }

        let totalSizeImages = 0;
        const images64 = [];
        for (const file of e.target.files) {
            let { name, size } = file;
            const actualsize = size;

            // Check for duplicate Files
            const duplicateFile = consoleImages?.find(file => file?.fileName === name);

            if (duplicateFile) {
                setUploadImageLoading(false);
                return toast.error("File with the same name already exists, please select a different file")
            }

            // const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            const extension = String(name).split('.').pop();

            // Files extension type validation
            if (!extensions?.image?.type.includes(extension)) {
                setUploadImageLoading(false);
                return toast.error(`Invalid extension type, Please upload files with allowed extension types`);
            }

            // File Size after adding new file
            totalSizeImages += actualsize;

            // Total files size validation
            if (totalSizeImages > 2000000) {
                setUploadImageLoading(false);
                return toast.error(`The maximum payload allowed for files is 2 MB. Your files exceed the payload limit.`);
            }

            const base64String = await readFileAsBase64(file);
            images64.push({
                fileName: name,
                base64String,
                isDefault: true,
            });

            handleUploadImages(images64)
        }
    }

    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                resolve(window.btoa(reader.result));
            };
            reader.onerror = reject;
        });
    };

    const handleSave = async () => {
        let images = [];
        images = consoleImages.filter((img) => img.base64String);
        setAllowRefetch(true);
        try {
            await onSaveImages({
                variables: {
                    businessConsoleImagesInput: {
                        businessId: id,
                        consoleImages: images,
                        displayInterval: consoleDetails?.intervals,
                        displayBusinessPicture: consoleDetails?.useBusinessImages
                        // ...images,
                    },
                },
            })
        } catch (error) {

        }

        // set all images to initial images and disable save button
        // setInitialImages((prevImages) => [...prevImages, ...images]);

        setDisableSave(true);
        setCanCompare(false)
        setLocallyUploadedImages([]);
    }

    const handleConsoleDetailsChange = (name, value) => {
        setConsoleDetails({
            ...consoleDetails,
            [name]: value,
        })

        // Now can compare because data changed
        setCanCompare(true);
    }

    return (
        <Fragment>
            <Title size='5' className="my-3"> Upload Console Images <span className='has-text-danger'>*</span>
                {/* <Tooltip title={toolTipTitle}> <FiInfo /> </Tooltip> */}
                <p className='is-size-7 has-text-weight-semibold has-text-info my-1 mb-2'>Images must be upto 2MB</p>
                <Subtitle size='6'>  {consoleImages?.length} of 10 images</Subtitle>
            </Title>
            <div className='is-flex is-flex-wrap-wrap'>
                {consoleImages?.map((image) => (
                    <StyledControl key={image?.id} className='control is-relative mr-4 my-1'>
                        <StyledImage src={image?.path || window.atob(image?.base64String)} />
                        <DeleteButton onClick={() => handleDeleteImage(image)}>
                            <Icon name='trash-o' color='white' />
                        </DeleteButton>
                    </StyledControl>
                ))}
                {
                    consoleImages?.length < 10 && (
                        <div className='control mr-4 my-1'>
                            <Spin spinning={uploadImageLoading}>
                                <label for='business-file-input'>
                                    <UploadButton>   <Icon name='picture-o' />   </UploadButton>
                                    <input
                                        multiple
                                        key={fileKey}
                                        onChange={onUploadBusinessImage}
                                        id={`console-file-input`}
                                        type='file'
                                        accept='image/*'
                                        style={{ opacity: 0, position: 'absolute', top: 10, width: 50 }}
                                    />
                                </label>
                            </Spin>
                        </div>
                    )
                }

            </div>

            {/* Console Details */}
            <Title size='5' className="my-3 mt-5"> Console Images Intervals<span className='has-text-danger'>*</span>
            </Title>

            <div class="columns">
                <div class="column is-half">
                    <input
                        name='intervals'
                        type='number'
                        value={consoleDetails?.intervals}
                        min='0'
                        className={`input`}
                        onChange={(e) => handleConsoleDetailsChange('intervals', e.target.value)}
                    />
                </div>
                <div class="column is-half p-0 is-flex is-justify-content-center is-align-items-center">
                    <label className='checkbox mb-2 mt-4'>
                        <input
                            type='checkbox'
                            name="useBusinessImages"
                            disabled={false}
                            checked={consoleDetails?.useBusinessImages}
                            onChange={(e) => handleConsoleDetailsChange('useBusinessImages', e.target.checked)}
                        />
                        <strong className='ml-2'>Use Business Images for Console</strong>
                    </label>
                </div>
            </div>

            <Button
                className='mt-2'
                disabled={disableSave}
                color='info'
                loading={savedLoading || isDeleteLoading}
                onClick={handleSave}
            >Save</Button>

        </Fragment>
    )
}

const UploadButton = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 1px dashed grey;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const StyledImage = styled.img`
  width: 50px;
  height: 50px;
  objectfit: cover;
  border: 1px dashed grey;
`;

const DeleteButton = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const StyledControl = styled.div`
  &:hover ${DeleteButton} {
    display: flex;
  }
`;