import { gql } from '@apollo/client';

export const GET_USER_LOCATIONS = gql`
  query GetUserLocations {
    getUserLocations {
      id
      name
      logoUrl
      isGoogleReserve
      picture {
        path
      }
      pictures {
        path
      }
      menus {
        path
      }
      services {
        name
      }
      totalCapacity
      appointmentConfirmationOffset
      details {
        offset
      }
      businessVenue {
        id
      }
      organization {
        name
      }
      appointments{
      id
      }
      whatsAppPhone
      smsPhone
      businessSubscription {
       remainingBonus
    }
    }
  }
`;

export const GET_USER_GOOGLE_RESERVE_BUSINESSES = gql`
  query GetUserGoogleReserveBusinesses {
    getUserGoogleReserveBusinesses{
   		id
      name
      logoUrl
      isGoogleReserve
      picture {
        path
      }
      pictures {
        path
      }
      menus {
        path
      }
      services {
        name
      }
      totalCapacity
      appointmentConfirmationOffset
      details {
        offset
      }
      businessVenue {
        id
      }
      organization {
        name
      }
      appointments{
      id
      } 
    }
  }
`;

export const GET_BUSINESS_NUMBERS = gql`
   query GetBusinessById($businessId: Int!) {
    getBusinessById(id: $businessId, showAll: true) {
    whatsAppPhone
    smsPhone
     businessSubscription {
      remainingBonus
    }
  }
}
`;
