import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { GET_APPOINTMENT_SUMMARY } from './requests';
import { INITIAL_DATA } from './constants';
import { useRef } from 'react';

//for firestore
import firestore from '@firebase';
import { COLLECTION } from 'constants/index';

export const useData = ({ selectedDate }) => {
    const [connection, setConnection] = useState(null);
    const initialRender = useRef(true);

    let { businessId } = useParams();

    let { pathname } = useLocation();

    const isDashboardRoute = String(pathname).includes('/admin/appointments-log');

    businessId = parseInt(businessId);

    useEffect(() => {
        const unsubscribe = firestore
            .collection(COLLECTION.APPOINTMENTS)
            .where('businessId', '==', `${businessId}`)
            .onSnapshot(async querySnapshot => {
                let shouldRefetch = false;
                querySnapshot.forEach(doc => {
                    // if data exists and also the initial render has passed than set refetch true
                    if (doc.data().customerId) {
                        if (!initialRender.current) {
                            shouldRefetch = true;
                        }
                    }
                });

                // if true then refetch appointments
                if (shouldRefetch) {
                    refetch();
                }

                // set the initial state to false for refetch after first time component has ran 
                if (initialRender.current) {
                    initialRender.current = false;
                }
            });
        return () => unsubscribe();
    }, [businessId]);

    const {
        loading: isLoading,
        error,
        refetch,
        data = INITIAL_DATA,
    } = useQuery(GET_APPOINTMENT_SUMMARY, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        variables: {
            businessId,
            startDate: selectedDate,
            // startDate: moment().format('YYYY-MM-DD'),
            endDate: selectedDate
            //  isDashboardRoute ? null : selectedDate || moment().format('YYYY-MM-DD'),
        },
    });

    useTimer({ onReload: refetch });

    const {
        getBusinessAppointmentsSummary: { summary: appointmentSummary },
    } = data;

    return { isLoading, error, data: appointmentSummary };
};

export const useTimer = ({ onReload }) => {
    useEffect(() => {
        const interval = setInterval(() => {
            onReload();
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    return {};
};

