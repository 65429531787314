import React from 'react'
import { Modal, Icon, Title, Field, Control, Label, Buttons, Button, DateInput, Paragraph } from '@safelyq/bulma-ui-library';
import { FaEye, FaPencilAlt, FaTrashAlt, FaWindowClose, FaKey } from "react-icons/fa";
import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_GOOGLE_FORM_PERMISSION, DELETE_GOOGLE_FORM_PERMISSION } from './requests';
import { toast } from 'react-toastify';
import FullPageLoader from 'sharedComponents/FullPageLoader/FullPageLoader';

const PermissionPopup = ({ allForms, isOpen, onClose, formId, refetch, }) => {
    const [showInput, setshowInput] = useState(false)
    const [email, setemail] = useState("")
    const [loader, setLoader] = useState(false)
    const [deleteloader, setdeleteloader] = useState(false)

    const [deleteFormPermission] = useMutation(DELETE_GOOGLE_FORM_PERMISSION);
    const [createFormPermission] = useMutation(CREATE_GOOGLE_FORM_PERMISSION);

    
    const getPermissions = (forms, formID) => {
        const singleForm = forms.find((form) => form.id === formID);
        const permissions = singleForm.googleFormPermissions;
        const formTitle = singleForm.formTitle;
        return [permissions, formTitle];
    }

    const [permissions, formTitle] = getPermissions(allForms, formId);

    const handleAddPermission = () => {
        setshowInput(true)
    }

    const handleCanclePermission = () => {
        setshowInput(false)
        setemail("")

    }

    const handleCreatePermission = async () => {
        try {
            setLoader(true)
            const response = await createFormPermission({ variables: { businessGoogleFormId: formId, email: email }, })
            if (response.data.createGoogleFormPermission.isSaved) {
                toast.success("Permission created successfully")
                await refetch()
                handleCanclePermission()
            }
            else {
                toast.warning(response.data.createGoogleFormPermission.errorMessage)
            }
            setLoader(false)
        } catch (error) {
            console.log(error);
            setLoader(false)
        }
    }

    const onDeletePermission = async (e, ID) => {
        e.stopPropagation()

        if (!window.confirm('Are you sure you want to delete the record?')) return;
        try {
            setdeleteloader(true)
            const response = await deleteFormPermission({ variables: { googleFormPermissionId: ID }, })
            await refetch()
            if (response.data.deleteGoogleFormPermission.isSaved) {
                toast.success("Permission deleted successfully")
                setshowInput(false)
            }
            else {
                toast.warning(response.data.deleteGoogleFormPermission.errorMessage)
            }
            setdeleteloader(false)


        } catch (error) {
            setdeleteloader(false)

        }
    }
    return (


        <>
            {deleteloader && <FullPageLoader />}
            <Modal active={isOpen} clipped>
                <Modal.Background />
                <Modal.Card>
                    <Modal.Card.Head className={'is-flex is-justify-content-space-between'}>
                        <Title size='5' className='m-0'> {formTitle} Permissions  </Title>
                        <Button size='small' className='button-icon' onClick={onClose}>
                            <Icon name='close' className='is-right' />
                        </Button>
                    </Modal.Card.Head>
                    <Modal.Card.Body>
                        <div className='row mb-5'>

                            {showInput ? (
                                <>
                                    <div className='col-lg-6'>
                                        <Field>
                                            <Control>
                                                <input onChange={(e) => setemail(e.target.value)} value={email} style={{ width: "200px" }} type="email" placeholder="Enter email" className='input' name='documentTitle' />
                                            </Control>
                                        </Field>
                                    </div>
                                    <div className='col-lg-3'>
                                        <Button onClick={handleCreatePermission} loading={loader} color='info'>Create</Button>
                                    </div>
                                    <div className='col-lg-3'>
                                        <Button onClick={handleCanclePermission} outlined color='danger'>Cancel</Button>
                                    </div>
                                </>

                            ) : (
                                <div className='col-lg-12'>
                                    <Button onClick={handleAddPermission} color='info'>Add Permission</Button>
                                </div>
                            )}


                        </div>
                        <Title size='5'>All Permissions</Title>
                        <div className='row'>
                            {
                                permissions.map(({ email, id }) => {
                                    return (
                                        <div key={id} className='col-lg-12'>
                                            <div className='row mb-4'>
                                                <div className='col-lg-8'>    {email}  </div>
                                                <div className='col-lg-4'>
                                                    <FaTrashAlt style={{ cursor: "pointer" }} onClick={(e) => onDeletePermission(e, id)} /> </div>
                                            </div>
                                        </div>
                                    )


                                })
                            }

                        </div>
                    </Modal.Card.Body>

                </Modal.Card>
            </Modal>
        </>

    )
}

export default PermissionPopup;
