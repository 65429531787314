import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components';
import moment from 'moment';
import { STATUS } from 'constants/index';
import {
    Subtitle,
    Button,
    Title,
} from '@safelyq/bulma-ui-library';
import { getStatusColor, getStatusName } from './appointmentStatuses';
import { useParams } from 'react-router-dom';

const StyledRow = styled.tr.attrs()`
  &:hover {
    cursor: pointer;
  }

`;

const StyledList = styled.td.attrs(() => ({
    className: 'py-3 pl-2',
}))`
    vertical-align: middle !important;
}
`;

const AppointmentRows = (
    {
        index,
        status,
        serviceName,
        serviceProviderName,
        userName,
        firstName,
        lastName,
        email,
        startTime,
        checkedInTime,
        isAnySubLevel,
        isHighlighted,
    }
) => {

    const getUserName = (name, firstName, lastName, email) => {
        if (firstName && !lastName) return firstName;
        if (firstName && lastName) return firstName + ' ' + lastName;
        if (!firstName && !lastName && name) return name;
        if (!firstName && !lastName && !name) return email;
    };

    return (
        <StyledRow className={`py-3 ${isHighlighted ? 'accordion-border' : ''}`}
            key={`appointments-${index}`}
            style={{ background: String(status).toUpperCase() === STATUS.CHECKED_IN && moment().isBefore(moment(checkedInTime).add(10, 'minutes')) ? 'rgba(18, 173, 43, 0.4)' : '', }}
        >
            <StyledList className='is-flex'
            >
                <Button color='info' className='icon-button'>
                    {String(getUserName(userName, firstName, lastName, email)).substring(0, 1)}
                    {/* {String(getUserName(name, firstName, lastName, email)).substring(0, 1)} */}
                </Button>
                <div className='ml-2'>
                    <Title size='6'>   {getUserName(userName, firstName, lastName, email)}   </Title>
                    {/* <Title size='6'>   {getUserName(name, firstName, lastName, email)}   </Title> */}
                    <Subtitle size='6'>{email}</Subtitle>
                </div>
            </StyledList>

            <StyledList
            >
                <span className='tag'>    <strong>{moment(startTime).format('MM-DD-YYYY')}</strong>   </span>
            </StyledList>
            <StyledList
            >
                <span className='tag'>  <strong>{moment(startTime).format('hh:mm A')}</strong>   </span>
            </StyledList>
            <StyledList
            >
                {String(serviceName).length <= 15 ? serviceName : String(serviceName).substring(0, 15) + '...'}
            </StyledList>
            {isAnySubLevel && (
                <StyledList
                >
                    {String(serviceProviderName).length <= 15 ? serviceProviderName : String(serviceProviderName).substring(0, 15) + '...'}
                </StyledList>
            )}
            <StyledList
            >
                <span className={`tag is-light`} style={{ background: `rgba(${getStatusColor(status)}, 0.1)`, color: `rgb(${getStatusColor(status)})`, }} >
                    {getStatusName(status)}
                </span>
            </StyledList>
        </StyledRow>
    )
}

export default AppointmentRows