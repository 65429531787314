import { gql } from '@apollo/client';

export const GET_BUSINESS_BY_ID = gql`
  query GetBusinessById($id: Int!) {
    getBusinessById(id: $id, showAll: false) {
      maxAllowedGuests
      isChildrenAllowed
      timeZone
      services {
        id
        name
        duration
        appointmentBlocks
        businesses {
          id
          name
        }
      }
    }
  }
`;

export const CREATE_USER_APPOINTMENT = gql`
  mutation CreateUserAppointment($appointmentInput: AppointmentInput!, $userInput: UserInput!) {
  createUserAppointment(appointmentInput: $appointmentInput, userInput: $userInput) {
    isSaved
    errorMessage
    appointment {
      id
      startTime
      endTime
      status
      business{
        id
      }
      service {
        id
      }
      adults
      subBusiness {
        name
      }
      user {
        id
      }
    }
  }
}
`;
