import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeItem: 0,
    stripeAfterPaymentToken: null,
};

const MiscSlice = createSlice({
    name: 'misc',
    initialState,
    reducers: {
        setActiveItem(state, action) {
            state.activeItem = action?.payload;
        },

        // store the token globally
        setStripeTokenGlobally: (state, action) => {

            return {
                ...state,
                stripeAfterPaymentToken: action?.payload
            }
        },

    },
});

export const { setActiveItem, setStripeTokenGlobally } = MiscSlice.actions;
export default MiscSlice.reducer;
