import React, { useState, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import {
    Section,
    Container,
    Field,
    Control,
    Buttons,
    Button,
    Label,
    Title,
    Icon,
    TextInput,
    Columns,
    Column,
} from '@safelyq/bulma-ui-library';
import { useMessageDispatch } from '@safelyq/admin-messages-feature';
import { useData } from './hooks';

const TopBar = styled.div.attrs(() => ({}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(0, 0, 0, 0.04);
`;

export const NewMessageSection = () => {
    const [userInfo, setUserInfo] = useState({});

    const [messageText, setMessageText] = useState('');

    const { businessId } = useParams();

    const { isLoading, onSendMessage } = useData();

    const dispatch = useMessageDispatch();

    const handleUserInfoChange = ({ target: { name, value } }) =>
        setUserInfo({ ...userInfo, [name]: value });

    const handleMessageTextChange = ({ target: { value } }) =>
        setMessageText(value);

    const handlePhoneChange = (phoneNumber) =>
        setUserInfo({ ...userInfo, phoneNumber: `+${phoneNumber}` });

    const handleMessageSend = () => {
        onSendMessage({
            variables: {
                ...userInfo,
                messageText,
                businessId,
            },
        });
    };

    const handleCloseNewMessageModal = () => {
        dispatch({ type: 'SET_NEW_MESSAGE_OPEN', payload: { isOpen: false } });
    };

    const isButtonDisabled = () => {
        const { firstName, lastName, email, phoneNumber } = userInfo;
        if ((!isEmpty(email) || !isEmpty(phoneNumber)) && !isEmpty(messageText))
            return false;
        else return true;
    };

    return (
        <Fragment>
            <TopBar>
                <Title size='5' className='m-0'>
                    <Icon name='comments' className='mr-2' />
                    Create new message
                </Title>
                <Icon
                    name='times'
                    className='is-hidden-tablet'
                    onClick={handleCloseNewMessageModal}
                />
            </TopBar>
            <Section>
                <Container>
                    <Columns multiline>
                        <Column size='6'>
                            <Field>
                                <Control>
                                    <Label>First Name</Label>
                                    <TextInput name='firstName' onChange={handleUserInfoChange} />
                                </Control>
                            </Field>
                        </Column>
                        <Column size='6'>
                            <Field>
                                <Control>
                                    <Label>Last Name</Label>
                                    <TextInput name='lastName' onChange={handleUserInfoChange} />
                                </Control>
                            </Field>
                        </Column>
                    </Columns>
                    <Columns multiline>
                        <Column size='6'>
                            <Field>
                                <Control>
                                    <Label>Email</Label>
                                    <TextInput name='email' onChange={handleUserInfoChange} />
                                </Control>
                            </Field>
                        </Column>
                        <Column size='6'>
                            <Field>
                                <Control>
                                    <Label>Phone</Label>
                                    <PhoneInput
                                        inputClass='input'
                                        countryCodeEditable={false}
                                        country={'us'}
                                        onChange={handlePhoneChange}
                                    />
                                </Control>
                            </Field>
                        </Column>
                    </Columns>

                    <Columns multiline>
                        <Column size='8'>
                            <Field>
                                <Label>Message</Label>
                                <Control>
                                    <textarea
                                        rows={3}
                                        className='textarea'
                                        name='messageText'
                                        onChange={handleMessageTextChange}
                                    />
                                </Control>
                            </Field>
                        </Column>
                    </Columns>
                    <Columns multiline>
                        <Column size='12'>
                            <Buttons size='small'>
                                <Button
                                    color='info'
                                    disabled={isLoading || isButtonDisabled()}
                                    onClick={handleMessageSend}
                                >
                                    Send Message
                                </Button>
                            </Buttons>
                        </Column>
                    </Columns>
                </Container>
            </Section>
        </Fragment>
    );
};
