import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { SAVE_NOTIFICATION_TEMPLATE } from './requests';

export const useData = (refetch) => {
  const [onSaveTemplate, { loading: isLoading }] = useMutation(
    SAVE_NOTIFICATION_TEMPLATE,
    {
      onCompleted: ({
        saveNotificationTemplate: { isSaved, errorMessage },
      }) => {
        if (isSaved) {
          toast.success('Template saved successfully');
          refetch();
        }
        else toast.error(errorMessage || 'Something went wrong');
      },
      onError: () => toast.error('Something went wrong'),
    }
  );

  return { onSaveTemplate, isLoading };
};
