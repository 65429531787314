import { oneOf, string } from 'prop-types';
import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = [
    props['collection']
  ]
    .concat(
      [
        'name',
        'scale'
      ]
        .map((name) => props[name] ? `fa-${props[name]}` : null)
        .filter((className) => !!className)
    )
    .join(' ');

  return classNames;
};

const Glyph = styled.i.attrs((props) => ({
  className: className`${props}`
}))``;

Glyph.propTypes = {
  collection: string.isRequired,
  name: string.isRequired,
  scale: oneOf(
    [
      'xs',
      'sm',
      'lg'
    ]
  )
};

Glyph.defaultProps = {
  scale: undefined
};

export default Glyph;
