import React, { Fragment, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import PhoneInput from 'react-phone-input-2';
import ReactTooltip from 'react-tooltip';
import { useForm, useFormState } from 'react-hook-form';
import { Columns, Column, Button } from '@safelyq/bulma-ui-library';
import { AddressessModal } from './components/AddressessModal';
import { emailRegex, webAddressRegex, DEFAULT_COUNTRY } from './constants';
import { BUSINESS_PROFILE_MESSAGES } from '../../common/constants';
import { FiInfo } from "react-icons/fi";
import { Tooltip } from 'antd';

export const CreateProfile = ({
  data: business,
  tab,
  isLoading,
  googleAddresses,
  isVisibleAddressModal,
  onSubmit: onSubmitParent,
  onCloseModal,
  toolTip
}) => {
  const [tags, setTags] = useState({ categoryTags: [], safetyTags: [] });

  const [data, setData] = useState();

  const {
    register,
    control,
    getValues,
    setValue,
    handleSubmit: onSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const { dirtyFields } = useFormState({ control });

  useEffect(() => {
    if (!isEmpty(business)) {
      const {
        name,
        businessCategory,
        categoryTags,
        city,
        state,
        zipCode,
        email,
        phone,
        webAddress,
        safetyTags,
        address1,
        address2,
        description,
        timeZone,
        country,
      } = business;

      reset({
        name,
        categoryName: businessCategory?.name,
        city,
        state,
        timeZone,
        country,
        zipCode,
        email,
        phone: phone ?? '+1',
        webAddress,
        address1,
        address2,
        description,
        safetyTags,
        categoryTags,
        country: country ?? DEFAULT_COUNTRY
        // wifiNames,
      });

      setData({
        name,
        categoryName: businessCategory?.name,
        city,
        state,
        timeZone,
        country,
        zipCode,
        email,
        phone: phone ?? '+1',
        webAddress,
        address1,
        address2,
        description,
        safetyTags,
        categoryTags,
      });
    }
  }, [business]);

  const handleTagChange = ({ target: { name, value } }) =>
    setTags({ ...tags, [name]: value });

  const handleTagAdd = (event) => {
    const {
      target: { name, value },
      key,
    } = event;

    if (key === 'Enter' && value) {
      event.preventDefault();
      if (!watch(name)) setValue(name, [value], { shouldDirty: true });
      else setValue(name, [...watch(name), value], { shouldDirty: true });
      setTags({ ...tags, [name]: '' });
    }
  };

  const handleRemoveTag = ({ name, index }) => {
    setValue(
      name,
      watch(name).filter((_, indx) => indx !== index),
      { shouldDirty: true }
    );
  };

  const handleSubmit = (values) => {
    let finalData = {};
    let phone = values.phone;
    if (String(phone).length < 4) phone = '';

    if (tab == 'LOCATION') {
      finalData = {
        regionId:
          business?.countries?.find((country) => country.name == values.country)
            ?.id || 0,
        businessDetails: {
          ...values,
          phone,
          showNotesInput: true,
        },
      };

      onSubmitParent(
        {
          ...finalData,
        },
        2,
        {
          success: BUSINESS_PROFILE_MESSAGES.Success,
          error: BUSINESS_PROFILE_MESSAGES.Error,
        }
      );
    } else {
      finalData = {
        businessDetails: {
          name: values?.name,
          phone,
          timeZone: business?.timeZone,
        },
      };

      onSubmitParent(
        {
          ...finalData,
        },
        2,
        business.id,
        {
          success: BUSINESS_PROFILE_MESSAGES.Success,
          error: BUSINESS_PROFILE_MESSAGES.Error,
        }
      );
    }
  };

  const isEmptyCategoryTags = watch('categoryTags')?.length <= 0;

  const isEmptySafetyTags = watch('safetyTags')?.length <= 0;

  return (
    <form onSubmit={onSubmit(handleSubmit)}>
      <Columns multiline>
        <Column size='4'>
          <div className='field'>
            <label className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>
              <div> Name <span className='has-text-danger'>*</span>  </div>
              <Tooltip title={toolTip?.name}> <FiInfo /> </Tooltip>
            </label>
            <div className='control'>
              <input
                className={`input ${errors.name ? 'is-danger' : ''}`}
                {...register('name', { required: true })}
              />
              {errors.name && (
                <p className='help is-danger'>Name is required</p>
              )}
            </div>
          </div>
        </Column>
        {tab == 'LOCATION' && (
          <Fragment>
            <Column size='4'>
              <div className='field'>
                <label className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>
                  <div>  Category <span className='has-text-danger'>*</span>  </div>
                  <Tooltip title={toolTip?.category}> <FiInfo /> </Tooltip>
                </label>
                <div className='control'>
                  <div
                    className={`select is-fullwidth ${errors.categoryName ? 'is-danger' : ''
                      }`}
                  >
                    <select {...register('categoryName', { required: true })}>
                      <option value={''} selected disabled>
                        Select Category
                      </option>
                      {business?.categories?.map(({ name, description }, index) => (
                        <option key={`category-${index}`} value={name}>
                          {description}
                        </option>
                      ))}
                    </select>

                    {errors.categoryName ? (
                      <p className='help is-danger'>Category is required</p>
                    ) : (
                      <a
                        href='mailto:info@safelyq.com?subject=Request for new category'
                        className='has-text-link'
                        data-tip='Please contact safelyq'
                      >
                        <small>
                          <i>Can not see your category?</i>
                        </small>
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </Column>
            <Column size='4'>
              <div className='field'>
                <label className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>
                  <div>Country <span className='has-text-danger'>*</span></div>
                  <Tooltip title={toolTip?.country}> <FiInfo /> </Tooltip>
                </label>
                <div className='control'>
                  <div
                    className={`select is-fullwidth ${errors.country ? 'is-danger' : ''
                      }`}
                  >
                    <select
                      onChange={({ target: { value } }) => {
                        reset({
                          timeZone: '',
                          state: '',
                        });
                      }}
                      {...register('country', { required: true })}
                    >
                      <option value={''} selected disabled>
                        Select Country
                      </option>
                      {business?.countries?.map((country, index) => (
                        <option key={`country-${index}`} value={country.name}>
                          {country?.name}
                        </option>
                      ))}
                    </select>
                    {errors.country && (
                      <p className='help is-danger'>Country is required</p>
                    )}
                  </div>
                </div>
              </div>
            </Column>
            {watch('country') && (
              <Column size='4'>
                <div className='field'>
                  <label className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>
                    <div> TimeZone <span className='has-text-danger'>*</span></div>
                    <Tooltip title={toolTip?.timeZone}> <FiInfo /> </Tooltip>
                  </label>
                  <div className='control'>
                    <div
                      className={`select is-fullwidth ${errors.timeZone ? 'is-danger' : ''
                        }`}
                    >
                      <select {...register('timeZone', { required: true })}>
                        <option value={''} selected disabled>
                          Select Timezone
                        </option>
                        {business?.countries
                          .find((country) => country?.name == watch('country'))
                          ?.timeZones.map((timeZone) => (
                            <option
                              key={`timeZone-${timeZone?.id}`}
                              value={timeZone?.name}
                            >
                              {timeZone?.name}
                            </option>
                          ))}
                      </select>
                      {errors.timeZone && (
                        <p className='help is-danger'>TimeZone is required</p>
                      )}
                    </div>
                  </div>
                </div>
              </Column>
            )}
            <Column size='4'>
              <div className='field'>
                <label className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>
                  <div> Address 1 <span className='has-text-danger'>*</span></div>
                  <Tooltip title={toolTip?.address1}> <FiInfo /> </Tooltip>
                </label>
                <div className='control'>
                  <input
                    name='address1'
                    className={`input ${errors.address1 ? 'is-danger' : ''}`}
                    {...register('address1', { required: true })}
                  />
                  {errors.address1 && (
                    <p className='help is-danger'>Address 1 is required</p>
                  )}
                </div>
              </div>
            </Column>
            <Column size='4'>
              <div className='field'>
                <label className='label'>Address 2</label>
                <div className='control'>
                  <input className='input' {...register('address2')} />
                </div>
              </div>
            </Column>
            <Column size='4'>
              <div className='field'>
                <label className='label'>
                  City <span className='has-text-danger'>*</span>
                </label>
                <div className='control'>
                  <input
                    className={`input ${errors.city ? 'is-danger' : ''}`}
                    {...register('city', { required: true })}
                  />
                  {errors.city && (
                    <p className='help is-danger'>City is required</p>
                  )}
                </div>
              </div>
            </Column>
            <Column size='4'>
              <div className='field'>
                <label className='label'>
                  State <span className='has-text-danger'>*</span>
                </label>
                <div className='control'>
                  <div
                    className={`select is-fullwidth ${errors.state ? 'is-danger' : ''
                      }`}
                  >
                    <select {...register('state', { required: true })}>
                      <option value={''} selected disabled>
                        Select State
                      </option>
                      {business?.countries
                        .find((country) => country?.name == watch('country'))
                        ?.states.map((state) => (
                          <option
                            key={`state-${state?.id}`}
                            value={state?.name}
                          >
                            {state?.name}
                          </option>
                        ))}
                    </select>
                    {errors.state && (
                      <p className='help is-danger'>State is required</p>
                    )}
                  </div>
                </div>
              </div>
            </Column>
            <Column size='4'>
              <div className='field'>
                <label className='label'>
                  Zip Code <span className='has-text-danger'>*</span>
                </label>
                <div className='control'>
                  <input
                    className={`input ${errors.zipCode ? 'is-danger' : ''}`}
                    {...register('zipCode', { required: true })}
                  />
                  {errors.zipCode && (
                    <p className='help is-danger'>ZipCode is required</p>
                  )}
                </div>
              </div>
            </Column>
          </Fragment>
        )}
        <Column size='4'>
          <div className='field'>
            <label className='label'>
              Phone Number <span className='has-text-danger'>*</span>
            </label>
            <div className='control'>
              <PhoneInput
                inputClass='input'
                countryCodeEditable={false}
                country={'us'}
                value={watch('phone')}
                onChange={(phone) =>
                  setValue('phone', phone, { shouldDirty: true })
                }
              />
              {errors.phone && (
                <p className='help is-danger'>Phone Number is required</p>
              )}
            </div>
          </div>
        </Column>
        {tab == 'LOCATION' && (
          <Fragment>
            <Column size='4'>
              <div className='field'>
                <label className='label'>Email Address</label>
                <div className='control'>
                  <input
                    type='email'
                    className={`input ${errors.email ? 'is-danger' : ''}`}
                    {...register('email', {
                      pattern: {
                        value: emailRegex,
                        message: 'Please enter a valid email address',
                      },
                    })}
                  />
                  {errors.email && (
                    <p className='help is-danger'>{errors?.email?.message}</p>
                  )}
                </div>
              </div>
            </Column>
            <Column size='4'>
              <div className='field'>
                <label className='label'>Web Address</label>
                <div className='control'>
                  <input
                    className='input'
                    {...register('webAddress', {
                      pattern: {
                        value: webAddressRegex,
                        message:
                          'Please enter a valid web address (http://*****)',
                      },
                    })}
                  />
                  {errors.webAddress && (
                    <p className='help is-danger'>
                      {errors?.webAddress?.message}
                    </p>
                  )}
                </div>
              </div>
            </Column>
            <Column size='4'>
              <div className='field'>
                <label className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>
                  <div>Category Tag</div>
                  <Tooltip title={toolTip?.categoryTags}> <FiInfo /> </Tooltip>
                </label>
                <div className='control'>
                  <input
                    className='input'
                    name='categoryTags'
                    value={tags.categoryTags}
                    onKeyPress={handleTagAdd}
                    onChange={handleTagChange}
                  />
                  <small>
                    <i>Enter tag and press enter</i>
                  </small>
                  {!isEmptyCategoryTags && (
                    <div className='field is-grouped is-grouped-multiline mt-2'>
                      {watch('categoryTags')?.map((category, index) => (
                        <div
                          className='control has-text-grey'
                          key={`categorytag-${index}`}
                        >
                          <div className='tags has-addons'>
                            <span className='tag '>{category}</span>
                            <a
                              className='tag is-delete'
                              onClick={() =>
                                handleRemoveTag({ name: 'categoryTags', index })
                              }
                            ></a>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </Column>
            <Column size='4'>
              <div className='field'>
                <label className='label is-flex is-justify-content-space-between is-align-content-center is-align-items-center'>
                  <div>Safety Tags</div>
                  <Tooltip title={toolTip?.safetyTags}> <FiInfo /> </Tooltip>
                </label>
                <div className='control'>
                  <input
                    name='safetyTags'
                    className='input'
                    value={tags.safetyTags}
                    onKeyPress={handleTagAdd}
                    onChange={handleTagChange}
                  />
                  <small>
                    <i>Enter tag and press enter</i>
                  </small>
                </div>
                {!isEmptySafetyTags && (
                  <div className='field is-grouped is-grouped-multiline mt-2'>
                    {watch('safetyTags')?.map((safetyTag, index) => (
                      <div
                        className='control has-text-grey'
                        key={`safetytag-${index}`}
                      >
                        <div className='tags has-addons'>
                          <span className='tag'>{safetyTag}</span>
                          <a
                            className='tag is-delete'
                            onClick={() =>
                              handleRemoveTag({ name: 'safetyTags', index })
                            }
                          ></a>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Column>
            <Column size='6'>
              <div className='field'>
                <label className='label'>Description</label>
                <div className='control'>
                  <textarea
                    className='textarea'
                    rows={4}
                    {...register('description')}
                  />
                </div>
              </div>
            </Column>
          </Fragment>
        )}
        <Column size='12'>
          <Button
            type='submit'
            color='info'
            disabled={isEmpty(dirtyFields) || !isEmpty(errors)}
            loading={isLoading}
          >
            Save
          </Button>
        </Column>
        <ReactTooltip />
      </Columns>
      <AddressessModal
        data={data}
        googleAddresses={googleAddresses}
        isOpen={isVisibleAddressModal}
        onClose={onCloseModal}
        onSubmit={handleSubmit}
      />
    </form>
  );
};
