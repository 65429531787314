import { isFunction, isUndefined } from 'lodash';
import { bool } from 'prop-types';
import React, { Children } from 'react';
import Icon from '../Icon';

const className = (strings, props) => {
  const classNames = ['control']
    .concat(
      ['has-icons-left', 'has-icons-right']
        .map((name) => (props[name] ? `${name}` : null))
        .filter((className) => !!className)
    )
    .concat(
      ['expanded']
        .map((name) => (props[name] ? `is-${name}` : null))
        .filter((className) => !!className)
    )
    .join(' ');

  return classNames;
};

const calculateWhen = (when) =>
  isUndefined(when) ? true : isFunction(when) ? when() : !!when;

const Control = ({ children }) => {
  const leftIcon = Children.toArray(children)
    .filter(
      (child) =>
        child.type === Icon &&
        child.props.position === 'left' &&
        calculateWhen(child.props.when)
    )
    .shift();

  const rightIcon = Children.toArray(children)
    .filter((child) => child.type === Icon && child.props.position === 'right')
    .shift();

  const props = {
    'has-icons-left': !isUndefined(leftIcon),
    'has-icons-right': !isUndefined(rightIcon),
  };

  return <div className={className`${props}`}>{children}</div>;
};

Control.propTypes = {
  expanded: bool,
};

Control.defaultProps = {
  expanded: undefined,
};

export default Control;
