import React from 'react';
import { createRoot } from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthenticationProvider } from '@axa-fr/react-oidc-context';
import { ToastContainer } from 'react-toastify';
import App from 'components/App';
import { Authenticating } from 'components/Authenticating';
import { AuthenticationComplete } from 'components/AuthenticationComplete';
import { NotAuthenticated } from 'components/NotAuthenticated';
import { NotAuthorized } from 'components/NotAuthorized';
import { SessionLost } from 'components/SessionLost';
import { oidcConfiguration } from 'services/oidc-config-provider';
import { ApolloProvider } from './ApolloProvider';

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store'
import 'react-datepicker/dist/react-datepicker.css';
import "react-phone-input-2/lib/style.css";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const rootElement = document.getElementById('root');

const queryClient = new QueryClient();
const container = document.getElementById("root");
const root = createRoot(container);
const Root = () => (


  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AuthenticationProvider
        isEnabled={true}
        authenticating={Authenticating}
        notAuthenticated={NotAuthenticated}
        notAuthorized={NotAuthorized}
        callbackComponentOverride={AuthenticationComplete}
        sessionLostComponent={SessionLost}
        configuration={oidcConfiguration}
      >
        <ApolloProvider>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter basename={baseUrl}>
              <App />
              <ToastContainer hideProgressBar={true} />
            </BrowserRouter>
          </QueryClientProvider>
        </ApolloProvider>
      </AuthenticationProvider>
    </PersistGate>

  </Provider>




);

root.render(<Root />, rootElement);
