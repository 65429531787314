import React from 'react';
import { Container, Title, Subtitle } from '@safelyq/bulma-ui-library';
import { useLocation } from 'react-router-dom';

export const NoCampaignsFound = ({ subtitle }) => {
  const { pathname: tempPath } = useLocation();
  const pathname = tempPath.toLowerCase();

  const dontDisplay = pathname.includes('/appointments-log');

  return (
    <section className='hero'>
      <div className='hero-body'>
        <Container className='has-text-centered'>
          <img src='/images/search-not-found.svg' alt="search-not-found" className='mb-2' width={130} />
          <Title size='5'>No Campaigns Found</Title>
          <Subtitle size='6' className={dontDisplay && "is-invisible"}>
            {subtitle || (
              <span>Change or Reset Filters to Find Campaigns</span>
            )}
          </Subtitle>
        </Container>
      </div>
    </section>
  );
};

