import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Table } from '@safelyq/bulma-ui-library';

const StyledRow = styled.tr`
  cursor: pointer;
`;

const ActiveStatusTag = styled.span.attrs(({ }) => ({
  className: 'tag is-rounded',
}))`
  background-color: rgb(174, 233, 209) !important;
`;

const ExpiredStatusTag = styled.span.attrs(({ }) => ({
  className: 'tag tag is-light is-rounded',
}))``;

export const TableView = ({ ...props }) => {
  const { businessCoupons, onModalPopulate, onCouponStatusChange } =
    props || {};

  return (
    <Table fullwidth hoverable bordered>
      <thead>
        <tr>
          {/*<th>Active</th>*/}
          <th>Code</th>
          <th>Coupon type</th>
          <th>Coupon amount</th>
          <th>Start date</th>
          <th>Expiration date</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody className='has-text-black'>
        {businessCoupons
          .slice(0)
          .reverse()
          .map(
            (
              {
                id,
                isActive,
                code,
                discountType,
                discount,
                startDate,
                endDate,
              },
              index
            ) => (
              <StyledRow key={`coupon-list-${index}`}>
                {/*<td>
                  <div className='control'>
                    <label className='checkbox'>
                      <input
                        type='checkbox'
                        name='isActive'
                        checked={isActive}
                        onChange={({ target: { checked } }) => {
                          onCouponStatusChange(checked, id);
                        }}
                      />
                    </label>
                  </div>
                      </td>*/}
                <td onClick={(e) => onModalPopulate(e, id)}>
                  <a href='#' className='is-underline has-text-info'>
                    {code}
                  </a>
                </td>
                <td onClick={(e) => onModalPopulate(e, id)}>{discountType}</td>
                <td onClick={(e) => onModalPopulate(e, id)}>
                  {String(discountType).toLowerCase() == 'value' && '$'}
                  {discount}
                  {String(discountType).toLowerCase() == 'percentage' && '%'}
                </td>
                <td onClick={(e) => onModalPopulate(e, id)}>
                  {moment(startDate).format('DD MMM YYYY')}
                </td>
                <td onClick={(e) => onModalPopulate(e, id)}>
                  {moment(endDate).format('DD MMM YYYY')}
                </td>
                <td onClick={(e) => onModalPopulate(e, id)}>
                  {!moment(moment().format('YYYY-MM-DD HH:mm:ss')).isBefore(
                    moment(
                      moment(endDate).format('YYYY-MM-DD') + ' ' + '24:00:00'
                    )
                  ) ? (
                    <ExpiredStatusTag>Expired</ExpiredStatusTag>
                  ) : (
                    <ActiveStatusTag>Active</ActiveStatusTag>
                  )}
                </td>
              </StyledRow>
            )
          )}
      </tbody>
    </Table>
  );
};
