import { gql } from '@apollo/client';

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($organizationInput: OrganizationInput!) {
    saveOrganization(organizationInput: $organizationInput) {
      errorMessage
      isSaved
    }
  }
`;
