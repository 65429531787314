import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Table, Icon } from '@safelyq/bulma-ui-library';

const StyledRow = styled.tr`
  cursor: pointer;
`;

const ActiveStatusTag = styled.span.attrs(({ }) => ({
  className: 'tag is-rounded',
}))`
  background-color: rgb(174, 233, 209) !important;
`;

const ExpiredStatusTag = styled.span.attrs(({ }) => ({
  className: 'tag tag is-light is-rounded',
}))``;

export const TableView = ({ ...props }) => {
  const { businessCoupons, onModalPopulate, onCouponStatusChange } =
    props || {};


    const [sortConfig, setSortConfig] = useState({});

    const { key, direction } = sortConfig;

    let sortedBusinessCoupons

    switch (key) {
      case 'title':
            sortedBusinessCoupons = businessCoupons?.sort((a, b) => {
                if (direction === 'ascending') return a.title.localeCompare(b.title)
                else return b.title.localeCompare(a.title)
            })
            break;
        case 'amount':
            sortedBusinessCoupons = businessCoupons?.sort((a, b) => {
                if (direction === 'ascending') return a.discount - b.discount
                else return b.discount - a.discount
            })
            break;
        case 'startdate':
            sortedBusinessCoupons = businessCoupons?.sort((a, b) => {
                if (direction === 'ascending') return new Date(a.startDate) - new Date(b.startDate)
                else return new Date(b.startDate) - new Date(a.startDate)
            })
            break;
        case 'enddate':
            sortedBusinessCoupons = businessCoupons?.sort((a, b) => {
                if (direction === 'ascending') return new Date(a.endDate) - new Date(b.endDate)
                else return new Date(b.endDate) - new Date(a.endDate)
            })
            break;
        case 'status':
            sortedBusinessCoupons = businessCoupons?.sort((a, b) => {
                if (direction === 'ascending') return new Date(a.endDate) - new Date(b.endDate)
                else return new Date(b.endDate) - new Date(a.endDate)
            })
            break;
        default:
            sortedBusinessCoupons = businessCoupons?.sort((a, b) => new Date(b.endDate) - new Date(a.endDate))
            break;
    }

  const returnArrowDirection = (key) => {
    if (sortConfig?.key === key && sortConfig.direction === 'ascending') {
      return 'arrow-up';
    } else {
      return 'arrow-down';
    }
  }

  const requestSort = key => {
    let direction = 'ascending';
    if (sortConfig?.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  }

  return (
    <Table fullwidth hoverable bordered>
      <thead>
        <tr>
          {/*<th>Active</th>*/}
          <th>Code</th>
          <th onClick={() => requestSort('title')}>Coupon Title<Icon name={returnArrowDirection('title')} className='ml-2' /></th>
          <th onClick={() => requestSort('amount')}>Coupon amount<Icon name={returnArrowDirection('amount')} className='ml-2' /></th>
          <th onClick={() => requestSort('startdate')}>Start date<Icon name={returnArrowDirection('startdate')} className='ml-2' /></th>
          <th onClick={() => requestSort('enddate')}>Expiration date<Icon name={returnArrowDirection('enddate')} className='ml-2' /></th>
          <th onClick={() => requestSort('status')}>Status<Icon name={returnArrowDirection('status')} className='ml-2' /></th>
        </tr>
      </thead>
      <tbody className='has-text-black'>
        {sortedBusinessCoupons
          .map(
            (
              {
                id,
                isActive,
                title,
                code,
                discountType,
                discount,
                startDate,
                endDate,
              },
              index
            ) => (
              <StyledRow key={`coupon-list-${index}`}>
                {/*<td>
                  <div className='control'>
                    <label className='checkbox'>
                      <input
                        type='checkbox'
                        name='isActive'
                        checked={isActive}
                        onChange={({ target: { checked } }) => {
                          onCouponStatusChange(checked, id);
                        }}
                      />
                    </label>
                  </div>
                      </td>*/}
                <td onClick={(e) => onModalPopulate(e, id)}>
                  <a href='#' className='is-underline has-text-info'>
                    {code}
                  </a>
                </td>
                <td onClick={(e) => onModalPopulate(e, id)}>{title}</td>
                <td onClick={(e) => onModalPopulate(e, id)}>
                  {String(discountType).toLowerCase() == 'value' && '$'}
                  {discount}
                  {String(discountType).toLowerCase() == 'percentage' && '%'}
                </td>
                <td onClick={(e) => onModalPopulate(e, id)}>
                  {moment(startDate).format('DD MMM YYYY')}
                </td>
                <td onClick={(e) => onModalPopulate(e, id)}>
                  {moment(endDate).format('DD MMM YYYY')}
                </td>
                <td onClick={(e) => onModalPopulate(e, id)}>
                  {!moment(moment().format('YYYY-MM-DD HH:mm:ss')).isBefore(
                    moment(
                      moment(endDate).format('YYYY-MM-DD') + ' ' + '24:00:00'
                    )
                  ) ? (
                    <ExpiredStatusTag>Expired</ExpiredStatusTag>
                  ) : (
                    <ActiveStatusTag>Active</ActiveStatusTag>
                  )}
                </td>
              </StyledRow>
            )
          )}
      </tbody>
    </Table>
  );
};
