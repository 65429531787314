import React from 'react'
import { ClipLoader } from 'react-spinners';
import { NoLogFound } from "./NoLogFound"
import AppointmentsTable from './AppointmentsTableSummary';
import { useData } from '../Hooks';
import moment from 'moment';
import { useState } from 'react';
import { Columns, Column } from '@safelyq/bulma-ui-library';

const AppointmentsSummary = ({ selectedDate }) => {
    const [slides] = useState(5);
    // Apointment API
    // const selectedDate = moment().format()
    let { isLoading, error, data: summary } = useData({ selectedDate });

    const noSummaryFound = summary.length === 0;
    return (
        <div>
            {
                isLoading
                    ?
                    <div className='is-flex is-justify-content-center is-align-items-center'>
                        <ClipLoader size={40} />
                    </div>
                    :
                    noSummaryFound
                        ?
                        <NoLogFound />
                        :
                        summary.length !== 0 && <AppointmentsTable summary={summary[0]} slides={slides} />
            }
        </div >
    )
}

export default AppointmentsSummary