import { bool } from "prop-types";
import styled from "styled-components";

const className = (strings, props) => {
  const classNames = ["table"]
    .concat(
      ["bordered", "striped", "narrow", "hoverable", "fullwidth"]
        .map((name) => (props[name] ? `is-${name}` : null))
        .filter((className) => !!className)
    )
    .join(" ");

  return classNames;
};

const Table = styled.table.attrs((props) => ({
  className: className`${props}`,
}))`
  background: transparent;
  color: white;
`;

Table.propTypes = {
  bordered: bool,
  striped: bool,
  narrow: bool,
  hoverable: bool,
  fullwidth: bool,
};

Table.defaultProps = {
  bordered: undefined,
  striped: undefined,
  narrow: undefined,
  hoverable: undefined,
  fullwidth: undefined,
};

export default Table;
