import { gql } from '@apollo/client';

export default class Queries {
  static GET_USER_PROFILE = gql`
    query all {
      getUserProfile {
        user {
          id
          email
          emailConfirmed
          firstName
          lastName
          phoneNumber
          phoneNumberConfirmed
        }
        hasSemiAuthAccount
        isVerficationCodeSent
        error
      }
    }
  `;

  static MARK_AS_VIEWED = gql`
    mutation all {
      markNotificationsAsViewed {
        errorMessage
        isSuccess
      }
    }
  `;

  static IS_PROFILE_COMPLETE = gql`
    query IsProfileComplete {
      isProfileComplete {
        isAuthenticated
        isCompleted
        isPhoneNumberConfirmed
        unviewedCount
      }
    }
  `;

  static GET_USER = gql`
    query GetUser {
      getUser {
        user {
          id
          name
          firstName
          lastName
          email
          isSuperAdmin
          conversationsSummary {
            unreadCount
          }
        }
      }
    }
  `;

  static GET_USER_IS_PROFILE_COMPLETE = gql`
    query GETUSERISPROFILECOMPLETE {
      isProfileComplete {
        isAuthenticated
        isCompleted
        isPhoneNumberConfirmed
        unviewedCount
      }
      getUser {
        user {
          id
          name
          firstName
          lastName
          email
          isSuperAdmin
          conversationsSummary {
            unreadCount
          }
           
        }
      }
    }
  `;

  static GET_EMAIL_AUTH_HASH = gql`
    query all {
      getUserProfile {
        user {
          id
          email
          emailConfirmed
          firstName
          lastName
          phoneNumber
          phoneNumberConfirmed
          userPreferences {
            allowEmail
            allowSms
            allowWhatsApp
          }
        }
        hasSemiAuthAccount
        isVerficationCodeSent
        error
      }
    }
  `;

  static SAVE_USER_PROFILE = gql`
    mutation all($userProfileInput: UserProfileInput!) {
      saveUserProfile(userProfileInput: $userProfileInput) {
        user {
          id
          email
          emailConfirmed
          firstName
          lastName
          phoneNumber
          phoneNumberConfirmed
          claimedPhoneNumber
          userPreferences {
            allowEmail
            allowSms
            allowWhatsApp
          }
        }
        hasSemiAuthAccount
        isVerficationCodeSent
        error
      }
    }
  `;

  static SEND_VERIFICATION_CODE = gql`
    mutation all {
      sendVerificationCode {
        isSuccess
        error
      }
    }
  `;

  static CONFIRM_VERIFICATION_CODE = gql`
    mutation all($code: String!) {
      confirmVerificationCode(code: $code) {
        isSuccess
        isPhoneNumberUpdated
        error
      }
    }
  `;

  static GET_EMAIL_AUTH_HASH = gql`
    query all {
      getEmailAuthHash {
        emailAuthHash
      }
    }
  `;

  static GET_BUSINESS = gql`
    query all($id: Int!) {
      getBusinessById(id: $id) {
        id
        name
        description
        address1
        address2
        allowedCapacityAsPercentage
        allowedCapacityInUnits
        appointmentGap
        city
        email
        endingOffset
        isAppointmentConfirmationRequired
        appointmentConfirmationOffset
        latitude
        longitude
        phone
        preCheckinInstructions
        startingOffset
        state
        totalCapacity
        webAddress
        zipCode
        timeZone
        categoryTags
        safetyTags
        maxAllowedGuests
        isChildrenAllowed
        timeZone
        children {
          id
        }
        services {
          id
          name
          duration
          appointmentBlocks
          businesses {
            id
            name
          }
        }
        menus {
          id
          path
        }
        menu {
          id
          path
        }
        pictures {
          id
          path
        }
        picture {
          id
          path
        }
        slots {
          id
          dayOfWeek
          openingTime
          closingTime
        }
        questions {
          id
          prompt
          questionType
        }
        promotions {
          id
          path
          text
        }
        isFavorite
      }
    }
  `;

  static GET_SERVICES = gql`
    query all($id: Int!) {
      getServices(organizationId: 0, businessId: $id) {
        id
        name
        businesses {
          id
          name
        }
      }
    }
  `;

  static GET_BUSINESS_CATEGORIES = gql`
    {
      getBusinessCategories {
        name
        description
      }
    }
  `;

  static SEARCH_BUSINESSES = gql`
    query all($searchBusinessInput: SearchBusinessInput) {
      searchBusinesses(searchBusinessInput: $searchBusinessInput) {
        id
        name
        businessCategory {
          name
          description
        }
        description
        address1
        address2
        allowedCapacityAsPercentage
        allowedCapacityInUnits
        appointmentGap
        city
        email
        endingOffset
        latitude
        longitude
        phone
        preCheckinInstructions
        startingOffset
        state
        totalCapacity
        webAddress
        zipCode
        categoryTags
        safetyTags
        isFavorite
        menus {
          id
          path
        }
        menu {
          id
          path
        }
        pictures {
          id
          path
        }
        picture {
          id
          path
        }
        slots {
          id
          dayOfWeek
          openingTime
          closingTime
        }
        questions {
          id
          prompt
          questionType
        }
        promotions {
          id
          path
          text
        }
        distanceInMeters
        distanceInMiles
        distanceInKilometers
      }
    }
  `;

  static GET_APPOINTMENT = gql`
    query all($appointmentId: Int!) {
      getUserAppointmentById(appointmentId: $appointmentId) {
        id
        adults
        children
        allocatedTime
        service {
          id
          name
        }
        service {
          name
          id
        }
        business {
          id
          name
          city
          phone
          phoneFormatted
          webAddress
          description
          address1
          timeZone
          categoryTags
          isChildrenAllowed
          maxAllowedGuests
          details {
            offset
          }
          children {
            id
          }
          services {
            id
            name
            duration
            appointmentBlocks
            businesses {
              id
              name
            }
          }
          safetyTags
          phone
          webAddress
          preCheckinInstructions
          isAppointmentConfirmationRequired
          appointmentConfirmationOffset
          lateCheckInTimeThreshold
          questions {
            id
            prompt
            questionType
            textNo
            textYes
            isUserResponseRequired
          }
          menus {
            id
            path
          }
          menu {
            id
            path
          }
          pictures {
            id
            path
          }
          picture {
            id
            path
          }
          slots {
            id
            dayOfWeek
            openingTime
            closingTime
          }
          promotions {
            id
            path
            text
          }
          serviceRatings {
            reviewsCount
            value
          }
        }
        subBusiness {
          id
          name
          description
        }
        venueEntrance {
          id
          name
        }
        startTime
        endTime
        status
        timeZone
        responses {
          id
          answer
          businessQuestion {
            id
            prompt
            questionType
            isUserResponseRequired
          }
        }
      }
    }
  `;

  static GET_BUSINESS_APPOINTMENTS = gql`
    query all($businessId: Int!, $startDate: Date!) {
      getBusinessAppointments(businessId: $businessId, startDate: $startDate) {
        id
        adults
        children
        business {
          id
          name
          description
          address1
          timeZone
          categoryTags
          safetyTags
          phone
          webAddress
          menus {
            id
            path
          }
          menu {
            id
            path
          }
          pictures {
            id
            path
          }
          picture {
            id
            path
          }
          slots {
            id
            dayOfWeek
            openingTime
            closingTime
          }
          promotions {
            id
            path
            text
          }
        }
        startTime
        endTime
        status
        responses {
          id
          answer
          businessQuestion {
            id
            prompt
            questionType
            isUserResponseRequired
          }
        }
      }
    }
  `;

  static GET_BUSINESS_AND_APPOINTMENTS = gql`
    query all($businessId: Int!, $status: String!, $startDate: Date!) {
      getBusinessById(id: $businessId) {
        id
        name
        description
        address1
        address2
        allowedCapacityAsPercentage
        allowedCapacityInUnits
        appointmentGap
        city
        state
        timeZone
        email
        endingOffset
        latitude
        longitude
        phone
        preCheckinInstructions
        isAppointmentConfirmationRequired
        appointmentConfirmationOffset
        startingOffset
        totalCapacity
        webAddress
        zipCode
        categoryTags
        safetyTags
        isChildrenAllowed
        maxAllowedGuests
        children {
          id
        }
        services {
          id
          name
          duration
          appointmentBlocks
          businesses {
            id
            name
          }
        }
        menus {
          id
          path
        }
        menu {
          id
          path
        }
        pictures {
          id
          path
        }
        picture {
          id
          path
        }
        slots {
          id
          dayOfWeek
          openingTime
          closingTime
        }
        questions {
          id
          prompt
          questionType
        }
        promotions {
          id
          path
          text
        }
        isFavorite
        details {
          offset
        }
      }

      getCurrentUserAppointments(
        businessId: $businessId
        status: $status
        startDate: $startDate
      ) {
        id
        adults
        children
        startTime
        endTime
        status
        responses {
          id
          answer
          businessQuestion {
            id
            prompt
            questionType
            isUserResponseRequired
          }
        }
      }
    }
  `;

  static GET_USER_APPOINTMENTS = gql`
    query all($businessId: Int!, $status: String!, $startDate: Date!) {
      getCurrentUserAppointments(
        businessId: $businessId
        status: $status
        startDate: $startDate
      ) {
        id
        adults
        children
        allocatedTime
        timeZone
        business {
          id
          name
          description
          address1
          city
          state
          appointmentConfirmationOffset
          isAppointmentConfirmationRequired
          categoryTags
          safetyTags
          phone
          webAddress
          menus {
            id
            path
          }
          menu {
            id
            path
          }
          pictures {
            id
            path
          }
          picture {
            id
            path
          }
          promotions {
            id
            path
            text
          }
          isFavorite
          serviceRatings {
            ratingType
            value
            reviewsCount
            modifiedTime
          }
        }
        service {
          name
          id
        }
        subBusiness {
          id
          name
          description
          address1
          appointmentConfirmationOffset
          categoryTags
          safetyTags
          phone
          isFavorite
        }
        comments {
          id
          comment {
            id
            commentText
            createdTime
            modifiedTime
            user {
              id
              name
              firstName
              lastName
              email
            }
          }
          messageDirection
        }
        venueEntrance {
          id
          name
        }
        startTime
        startTimeOnly
        endTime
        status
      }
    }
  `;

  static ADD_USER_APPOINTMENT = gql`
    mutation createUserAppointment($appointmentInput: AppointmentInput!) {
      createUserAppointment(appointmentInput: $appointmentInput) {
        isCreated
        isSaved
        errorMessage
        user {
          id
          name
        }
        appointment {
          id
          children
          adults
          startTime
          endTime
          business {
            id
            name
            description
            address1
            address2
            allowedCapacityAsPercentage
            allowedCapacityInUnits
            appointmentGap
            city
            state
            timeZone
            email
            endingOffset
            latitude
            longitude
            phone
            preCheckinInstructions
            isAppointmentConfirmationRequired
            appointmentConfirmationOffset
            startingOffset
            totalCapacity
            webAddress
            zipCode
            categoryTags
            safetyTags
            slots {
              id
              dayOfWeek
              openingTime
              closingTime
            }
            promotions {
              id
              path
              text
              isActive
              priorityOrder
            }
          }
          subBusiness {
            id
            name
            description
          }
          user {
            id
            name
          }
          responses {
            id
            businessQuestion {
              id
              prompt
            }
            answer
          }
        }
      }
    }
  `;

  static UPDATE_USER_APPOINTMENT = gql`
    mutation updateUserAppointment($appointmentInput: AppointmentInput!) {
      updateUserAppointment(appointmentInput: $appointmentInput) {
        isCreated
        isSaved
        errorMessage
        appointment {
          id
          children
          adults
          startTime
          endTime
          business {
            id
            name
            description
            address1
            address2
            allowedCapacityAsPercentage
            allowedCapacityInUnits
            appointmentGap
            city
            state
            timeZone
            email
            endingOffset
            latitude
            longitude
            phone
            preCheckinInstructions
            isAppointmentConfirmationRequired
            appointmentConfirmationOffset
            startingOffset
            totalCapacity
            webAddress
            zipCode
            categoryTags
            safetyTags
            slots {
              id
              dayOfWeek
              openingTime
              closingTime
            }
            promotions {
              id
              path
              text
              isActive
              priorityOrder
            }
          }
          subBusiness {
            id
            name
            description
          }
          user {
            id
            name
          }
          responses {
            id
            businessQuestion {
              id
              prompt
            }
            answer
          }
        }
      }
    }
  `;

  static UPDATE_APPOINTMENT_STATUS = gql`
    mutation updateAppointmentStatus(
      $appointmentId: Int!
      $newStatus: AppointmentStatus!
      $userResponses: [UserResponseInput]
    ) {
      updateAppointmentStatus(
        appointmentId: $appointmentId
        newStatus: $newStatus
        userResponses: $userResponses
      ) {
        isSaved
        errorMessage
        appointment {
          id
          startTime
          endTime
          status
          business{
            id
          }
          service {
            id
          }
          adults
          subBusiness {
            name
          }
        }
      }
    }
  `;

  static GET_CURRENT_USER_FAVORITES = gql`
    query all($businessId: Int) {
      getCurrentUserFavorites(businessId: $businessId) {
        id
        business {
          id
          name
          description
          address1
          categoryTags
          safetyTags
          phone
          webAddress
          menus {
            id
            path
          }
          menu {
            id
            path
          }
          pictures {
            id
            path
          }
          picture {
            id
            path
          }
          promotions {
            id
            path
            text
          }
          businessCategory {
            name
            description
          }
        }
      }
    }
  `;

  static ADD_REMOVE_FROM_FAVORITES = gql`
    mutation addRemoveFromFavorites($businessId: Int!, $isFavorite: Boolean!) {
      addRemoveFromFavorites(businessId: $businessId, isFavorite: $isFavorite) {
        id
        business {
          id
        }
        user {
          id
        }
      }
    }
  `;

  static GET_SUGGESTED_BUSINESSES = gql`
    query all($input: SuggestedBusinessesInput!) {
      getSuggestedBusinesses(suggestedBusinessesInput: $input) {
        category {
          name
          description
        }
        businesses {
          id
          name
          description
          isFavorite
          address1
          address2
          menus {
            id
            path
          }
          menu {
            id
            path
          }
          pictures {
            id
            path
          }
          picture {
            id
            path
          }
          promotions {
            id
            path
            text
            isActive
            priorityOrder
          }
          serviceRatings {
            ratingType
            value
            reviewsCount
            modifiedTime
          }
          allowedCapacityAsPercentage
          allowedCapacityInUnits
          appointmentGap
          city
          email
          endingOffset
          latitude
          longitude
          phone
          state
          totalCapacity
          webAddress
          zipCode
        }
      }
    }
  `;

  static GET_AVAILABLE_APPOINTMENTS = gql`
    query all(
      $business: Int!
      $service: Int!
      $appointmentDate: Date!
      $subBusinesses: [Int]
      $appointmentWindow: AppointmentWindow!
      $excludedAppointmentIds: [Int]
    ) {
      getAvailability(
        business: $business
        service: $service
        subBusinesses: $subBusinesses
        appointmentDate: $appointmentDate
        appointmentWindow: $appointmentWindow
        excludedAppointmentIds: $excludedAppointmentIds
      ) {
        first {
          business {
            id
            name
          }
          subBusiness {
            id
            name
          }
          startTime
          startDateOnly
          startTimeOnly
          endTime
          endDateOnly
          endTimeOnly
          timeZone
        }
        second {
          business {
            id
            name
          }
          subBusiness {
            id
            name
          }
          startTime
          startDateOnly
          startTimeOnly
          endTime
          endDateOnly
          endTimeOnly
          timeZone
        }
        third {
          business {
            id
            name
          }
          subBusiness {
            id
            name
          }
          startTime
          startDateOnly
          startTimeOnly
          endTime
          endDateOnly
          endTimeOnly
          timeZone
        }
        fourth {
          business {
            id
            name
          }
          subBusiness {
            id
            name
          }
          startTime
          startDateOnly
          startTimeOnly
          endTime
          endDateOnly
          endTimeOnly
          timeZone
        }
        fifth {
          business {
            id
            name
          }
          subBusiness {
            id
            name
          }
          startTime
          startDateOnly
          startTimeOnly
          endTime
          endDateOnly
          endTimeOnly
          timeZone
        }
        others {
          business {
            id
            name
          }
          subBusiness {
            id
            name
          }
          startTime
          startDateOnly
          startTimeOnly
          endTime
          endDateOnly
          endTimeOnly
          timeZone
        }
      }
    }
  `;

  static UPLOAD_BUSINESS_PICTURES = gql`
    mutation all($businessImagesInput: BusinessImagesInput!) {
      uploadBusinessPictures(businessImagesInput: $businessImagesInput) {
        business {
          id
          name
        }
        businessPictures {
          id
          path
        }
        businessMenus {
          id
          path
          isDefault
        }
        isSuccess
        error
        imageErrors {
          imageName
          error
        }
      }
    }
  `;

  static UPLOAD_BUSINESS_MENUS = gql`
    mutation all($businessImagesInput: BusinessImagesInput!) {
      uploadBusinessMenus(businessImagesInput: $businessImagesInput) {
        business {
          id
          name
        }
        businessPictures {
          id
          path
        }
        businessMenus {
          id
          path
          isDefault
        }
        isSuccess
        error
        imageErrors {
          imageName
          error
        }
      }
    }
  `;

  static SEND_CONTACT_US_EMAIL = gql`
    mutation sendContactUsMail($userContactInput: UserContactInput!) {
      contactUs(userContactInput: $userContactInput) {
        errorMessage
        isSuccess
      }
    }
  `;
}

export const GET_USER_NOTIFICATIONS = gql`
  query GetUserNotifications {
    getUserNotifications {
      userNotifications {
        id
      }
    }
  }
`;

export const GET_ABOUT_US_INFO = gql`
  query GetAboutUsInfo {
    getAboutUsPageInfo {
      isSuccess
      errorMessage
      aboutUs {
        title
        goal
        secondTitle
        description {
          pg1
          pg2
          pg3
          pg4
        }
        setupList {
          title
          options
        }
        media {
          title
          url
        }
        footer {
          text
          email
        }
      }
    }
  }
`;

export const GET_USER_FAVORITE_BUSINESS_COUPONS = gql`
  query GetFavoriteBusinessCoupons {
    getUserFavoritesBusinessCoupon {
      businessCoupon {
        id
        code
        title
        summary
        discount
        discountType
        endDate
        expireStatus
        couponUrl
        imageUrl
        isFavorite
        business {
          id
          name
          businessCategory {
            name
            description
          }
        }
      }
    }
  }
`;

export const FAVORITES_BUSINESS_COUPON = gql`
  mutation FavoritesBusinessCoupon(
    $businessCouponId: Int!
    $isFavorite: Boolean!
  ) {
    addRemoveFromFavoritesBusinessCoupon(
      businessCouponId: $businessCouponId
      isFavorite: $isFavorite
    ) {
      id
      businessCoupon {
        title
      }
    }
  }
`;
