import { STATUS } from 'constants/index';

export const getStatusColor = (status = '') => {
    let color = 'white';
    if (status.toUpperCase() === STATUS.BOOKED) {
        color = '163, 139, 0'; //red
    } else if (status.toUpperCase() === STATUS.CANCELLED) {
        color = '255, 0, 0'; //red
    } else if (status.toUpperCase() === STATUS.CHECKEDIN) {
        color = '181, 0, 0'; //red
    } else if (status.toUpperCase() === STATUS.COMPLETED) {
        color = '18, 173, 43'; //green
    } else if (status.toUpperCase() === STATUS.MISSED) {
        color = '255, 0, 0'; //red
    } else if (status.toUpperCase() === STATUS.CHECKED_IN) {
        color = '181, 0, 0'; //red
    } else if (status.toUpperCase() === STATUS.PASSED) {
        color = '255, 0, 0'; //red
    } else if (status.toUpperCase() === STATUS.SERVING_NOW) {
        color = '133, 133, 133'; //gray
    } else if (status.toUpperCase() === STATUS.CHECKED_IN_BY_BUSINESS) {
        color = '18, 173, 43'; //green
    } else if (status.toUpperCase() === STATUS.CONFIRMED) {
        color = '40, 135, 239'; //blue
    } else if (status.toUpperCase() === STATUS.ACTIVE) {
        color = '163, 139, 0'; //blue
    } else if (status.toUpperCase() === STATUS.INCOMPLETE) {
        color = '133, 133, 133'; //gray;
    }
    return color;
};

export const getStatusName = (status = '') => {
    let color = status;
    if (status.toUpperCase() === STATUS.BOOKED) {
        color = 'Booked';
    } else if (status.toUpperCase() === STATUS.CANCELLED) {
        // color = 'Cancelled';
        color = 'CANCELLED';
    } else if (status.toUpperCase() === STATUS.CHECKED_IN) {
        color = 'Customer Checked In';
    } else if (status.toUpperCase() === STATUS.COMPLETED) {
        // color = 'Completed';
        color = 'COMPLETED';
    } else if (status.toUpperCase() === STATUS.MISSED) {
        color = 'Missed';
    } else if (status.toUpperCase() === STATUS.PASSED) {
        color = 'Passed';
    } else if (status.toUpperCase() === STATUS.SERVING_NOW) {
        color = 'Now Serving';
    } else if (status.toUpperCase() === STATUS.CHECKED_IN_BY_BUSINESS) {
        color = 'Business Checked In';
    } else if (status.toUpperCase() === STATUS.CONFIRMED) {
        color = 'Confirmed';
    } else if (status.toUpperCase() === STATUS.ACTIVE) {
        // color = 'Active';
        color = 'ACTIVE';
    } else if (status.toUpperCase() === STATUS.INCOMPLETE) {
        color = 'IN_COMPLETE';
        // color = 'InComplete';
    }
    return color;
};

export const getStatusTileColor = (status) => {
    let color = '#defbff';
    if (status.toUpperCase() === STATUS.BOOKED) {
        color = '#defbff';
    } else if (status.toUpperCase() === STATUS.CANCELLED) {
        color = '#defbff';
    } else if (status.toUpperCase() === STATUS.CHECKEDIN) {
        color = 'green';
    } else if (status.toUpperCase() === STATUS.COMPLETED) {
        color = 'green';
    } else if (status.toUpperCase() === STATUS.MISSED) {
        color = '#defbff';
    } else if (status.toUpperCase() === STATUS.PASSED) {
        color = '#defbff';
    } else if (status.toUpperCase() === STATUS.SERVING_NOW) {
        color = 'white';
    } else if (status.toUpperCase() === STATUS.CHECKED_IN_BY_BUSINESS) {
        color = 'white';
    } else if (status.toUpperCase() === STATUS.CONFIRMED) {
        color = '#defbff';
    } else if (status.toUpperCase() === STATUS.ACTIVE) {
        color = '#defbff';
    }
    return color;
};
