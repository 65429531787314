import { bool, oneOf } from 'prop-types';
import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = [
    'container'
  ]
    .concat(
      [
        'fluid'
      ]
        .map((name) => props[name] ? `is-${name}` : null)
        .filter((className) => !!className)
    )
    .concat(
      [
        'size'
      ]
        .map((name) => props[name] ? `is-${props[name]}` : null)
        .filter((className) => !!className)
    )
    .join(' ');

  return classNames;
};

const Container = styled.div.attrs((props) => ({
  className: className`${props}`
}))``;

Container.propTypes = {
  fluid: bool,
  size: oneOf(
    [
      'widescreen',
      'fullhd'
    ]
  )
};

Container.defaultProps = {
  fluid: undefined,
  size: undefined
};

export default Container;
