import React from 'react'
import { Modal, Button } from '@safelyq/bulma-ui-library';
import styles from "./ApptConfirmPopup.module.css"
export const ApptConfirmPopup = ({ isOpen, onCancelClick, onOkClick }) => {
    return (
        <Modal active={isOpen}>
            <Modal.Background />
            <Modal.Card style={{ width: '550px' }}>
                <Modal.Card.Body className='p-0'>
                    <div className={styles.wrapper}>
                        <div className='row'>
                            <div className='col-12'>
                                <p className={styles.heading}>Selected holiday/closure will affect customer appointments at this time. Press OK to cancel appointments and send notifications to customers to allow them to reschedule.</p>
                            </div>
                            <div className='col-6 has-text-centered'>
                                <Button color='danger' size='small' onClick={onCancelClick}  >  Cancel </Button>
                            </div>
                            <div className='col-6  has-text-centered'>
                                <Button color='info' size='small' onClick={() => onOkClick(true)} >  OK </Button>
                            </div>
                        </div>

                    </div>
                </Modal.Card.Body>
            </Modal.Card>
        </Modal>
    )
} 