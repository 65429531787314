import { gql } from '@apollo/client';

export const GET_BUSINESS_USERS = gql`
  query all($id: Int!, $showAll: Boolean) {
    getBusinessById(id: $id, showAll: $showAll) {
      id
      businessSubLevels {
        id
        name
      }
      businessLocationUsers {
        userPermissions {
          roleName
          user {
            id
            name
            email
          }
          serviceProviders {
            serviceProvider {
              id
              name
            }
          }
        }
      }
    }
  }
`;
export const DELETE_USER = gql`
  mutation ($businessUserPermissionsInput: BusinessUserPermissionsInput!) {
    deleteBusinessUserRoles(
      businessUserPermissionsInput: $businessUserPermissionsInput
    ) {
      isSaved
      errorMessage
    }
  }
`;