import { isEmpty } from "lodash";

export const getAvatarName = (authUser) => {
    if (authUser) {
        const { firstName, lastName, email } = authUser;
        if (isEmpty(firstName) && isEmpty(lastName)) {
            return String(email).substring(0, 2).toUpperCase();
        }
        else {
            return (String(firstName).charAt(0).toUpperCase() + String(lastName).charAt(0).toUpperCase());
        }
    }
};