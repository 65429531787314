import { gql } from "@apollo/client";

export const GET_STRIPE_CONNECT = gql`
query stripeAccount($businessId: Int){
	getBusinessStripeAccountLink(businessId: $businessId){
		isSaved
		errorMessage
		accountLink
	}
}
`;

export const ALLOW_CUSTOMER_TO_PAY_FEE = gql`
mutation UpdateBusinessStripeAccountDetail($allowCustomerFee: Boolean!, $businessId: Int!) {
  updateBusinessStripeAccountDetail(allowCustomerFee: $allowCustomerFee, businessId: $businessId) {
    errorMessage
    isSaved
  }
}
`

export const BUSINESS_STRIPE_DETAILS = gql`
query GetBusinessById($id: Int!) {
  getBusinessById(id: $id, showAll:true ) {
    allowCustomerFee
    stripeAccountStatus
  }
}
`