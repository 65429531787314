import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { toast as showToast } from 'react-toastify';
import { GET_BUSINESS_BY_ID, MARK_AS_READ_MUTATION } from './requests';

// firestore
import firestore from '@firebase';
import { COLLECTION } from 'constants/index';
import { setUnreadReviewCount } from 'Redux/BusinessSlice/Business';

export const useData = () => {
  const { businessId } = useParams();

  const id = parseInt(businessId);

  useEffect(() => {
    const unsubscribe = firestore
      .collection(COLLECTION.RATINGS)
      .where('businessId', '==', `${id}`)
      .onSnapshot(async querySnapshot => {
        refetch();
      });
    return () => unsubscribe();
  }, [id]);

  const {
    loading: isLoading,
    error,
    data = {},
    refetch,
  } = useQuery(GET_BUSINESS_BY_ID, { variables: { id } });

  if (error) showToast.error('There was a problem. Data could not be loaded.');

  const { getBusinessById: business } = data;

  return { isLoading, error, data: business, refetch };
};

export const useReviewRead = (dispatch) => {
  const [onReviewRead, { loading: reviewLoading, error: reviewError, data: reviewData }] = useMutation(
    MARK_AS_READ_MUTATION,
    {
      // variables: { id },
      onCompleted: () => {

        // Set 0 to the global State
        dispatch(setUnreadReviewCount(0));
      },
      onError: (error) => {
        showToast.error(error.message || "Error occured")
      },
    }
  );
  return { onReviewRead, reviewLoading, reviewError, reviewData }
}
