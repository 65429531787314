import React from 'react';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  ApolloProvider as Provider,
} from '@apollo/client';
import { isEmpty } from 'lodash';
import { onError } from 'apollo-link-error';
import ReactGA from 'react-ga';
import { addAuthorizationHeader } from 'methods/addAuthorizationHeader';
import { APIPaths } from 'services/api-provider';

const cache = new InMemoryCache({ addTypename: false });

const triggerGAEvent = (category, action, label) =>
  ReactGA.event({
    category,
    action,
    label,
  });

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const { operationName } = operation || {};

  if (networkError) {
    triggerGAEvent(
      `Error: API`,
      `${networkError.message || 'Network request failed'}`,
      `${operationName}`
    );
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      triggerGAEvent(`Error: API`, `${message}`, `${operationName}`);
    });
  }
});

const responseLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const { data, errors } = response || {};

    const { operationName } = operation || {};

    let message = !isEmpty(errors)
      ? errors[0].message
      : Object.values(data)[0]?.errorMessage || {};

    if (!isEmpty(message))
      triggerGAEvent(`Error: API`, `${message}`, `${operationName}`);

    return response;
  });
});

const authLink = new ApolloLink(addAuthorizationHeader);

const httpLink = new HttpLink({ uri: APIPaths.QueryPath });

const link = ApolloLink.from([authLink, errorLink, responseLink, httpLink]);

const apolloClient = new ApolloClient({
  link,
  cache,
});

export function ApolloProvider({ ...props }) {
  return <Provider client={apolloClient} {...props} />;
}
