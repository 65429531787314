import React, { Fragment } from 'react';
import moment from 'moment';
import { Box, Button, Icon, Subtitle } from '@safelyq/bulma-ui-library';
import { getStatusColor, getStatusName } from 'methods/getStatuses';
import { STATUS_CAMPAIGN, SORT_PRIORITY } from 'constants/index';

export const TileView = ({ ...props }) => {

    const { campaigns, handleConfirmModalOpen, handleViewModalOpen, handleEditCampaign } = props;


    return (
        <Fragment>
            {
                campaigns?.sort((a, b) =>
                    moment(a.startDateTime).format('HH:mm:ss').localeCompare(moment(b.startDateTime).format('HH:mm:ss'))
                )
                    .sort((a, b) => {
                        let d1 = new Date(b.endDateTime)
                        let d2 = new Date(a.endDateTime)
                        d1.setHours(0, 0, 0, 0)
                        d2.setHours(0, 0, 0, 0)
                        return d1 - d2
                    }).sort((a, b) => SORT_PRIORITY[a.status] - SORT_PRIORITY[b.status])
                    .map(
                        (
                            singleCampaign,
                            index
                        ) => {
                            const {
                                id,
                                title,
                                details,
                                campaignGroups,
                                businessCoupon,
                                startDateTime,
                                endDateTime,
                                budget,
                                chargeAmount,
                                status,
                                modifiedDate,
                                businessAttachment
                            } = singleCampaign || {}

                            return (
                                <Box shadow>
                                    <article className='' key={`campaigns-${index}`}>
                                        <div className=''>
                                            <div className=''>
                                                <div>
                                                    <div className='is-flex is-justify-content-space-between' style={{ flexWrap: 'wrap' }}>
                                                        <div className='is-flex is-flex-direction-column my-1'>
                                                            <strong>{title}</strong>
                                                            {details || campaignGroups?.find(camp => camp.messageText?.trim() !== "" && camp.messageText !== null)
                                                                ?
                                                                <small>
                                                                    {
                                                                        // If details present then show otherwise show message text of campaign groups
                                                                        details
                                                                            ?
                                                                            String(details).length <= 30
                                                                                ? details
                                                                                : String(details).substring(0, 30) + '...'
                                                                            :
                                                                            String(campaignGroups?.find(camp => camp.messageText)?.messageText).length <= 30
                                                                                ? campaignGroups?.find(camp => camp.messageText)?.messageText
                                                                                : String(campaignGroups?.find(camp => camp.messageText)?.messageText).substring(0, 30) + '...'
                                                                    }
                                                                </small>
                                                                :
                                                                (
                                                                    // Else show coupon details
                                                                    businessCoupon &&
                                                                    <small>
                                                                        {String(businessCoupon?.discountType).toLowerCase() === 'value' && '$'}
                                                                        {businessCoupon?.discount && businessCoupon?.discount + " "}
                                                                        {String(businessCoupon?.discountType).toLowerCase() === 'percentage' && '%'}
                                                                        Off , Expires {" " + moment(businessCoupon?.endDate).format('MMM Do YYYY')}
                                                                    </small>
                                                                )}
                                                        </div>

                                                        <span className='my-1' style={{ display: "inline-grid" }}>
                                                            <span
                                                                className={`tag is-light`}
                                                                style={{
                                                                    background: `rgba(${getStatusColor(status)}, 0.1)`,
                                                                    color: `rgb(${getStatusColor(status)})`,
                                                                }}
                                                            >
                                                                {/* {getStatusName(status)} */}
                                                                {status === "Completed" ? "Completed On" : status === "Cancelled" ? "Cancelled On" : status === "active" ? "Activated On" : "Last Modified On"}
                                                            </span>
                                                            <small className='tag is-light has-text-black'>{moment(modifiedDate).format("MMM Do YYYY, h:mm:ss a")}</small>

                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='is-flex is-justify-content-space-between my-1'>
                                                <div>
                                                    <strong className='tag is-dark mx-1'>Budget: $ {businessAttachment?.id ? parseFloat(0.00) : parseFloat(budget).toFixed(2)}</strong>
                                                    <strong className='tag is-info mx-1'>Charge Amount: $ {parseFloat(chargeAmount).toFixed(2)}</strong>
                                                </div>
                                                <div className='is-flex is-justify-content-end'>
                                                    {
                                                        String(status).toUpperCase() !== STATUS_CAMPAIGN.INCOMPLETE &&
                                                        <Button
                                                            data-tip='View Campaign'
                                                            className='icon-button action-btn mx-1'
                                                            onClick={() => handleViewModalOpen({ id, type: "view" })}
                                                        >
                                                            <Icon name='eye' />
                                                        </Button>
                                                    }
                                                    {
                                                        String(status).toUpperCase() === STATUS_CAMPAIGN.ACTIVE &&
                                                        <Button
                                                            color='danger'
                                                            data-tip='Cancel Campaign'
                                                            className='icon-button action-btn mx-1'
                                                            onClick={() => handleConfirmModalOpen(id, STATUS_CAMPAIGN.CANCELLED)}
                                                        >
                                                            <Icon name='times' />
                                                        </Button>
                                                    }
                                                    {
                                                        // If status incomplete show archieve icon show delete icon
                                                        String(status).toUpperCase() === STATUS_CAMPAIGN.INCOMPLETE &&
                                                        <>
                                                            <Button
                                                                data-tip='Edit Campaign'
                                                                className='icon-button action-btn mx-1'
                                                                onClick={() => handleEditCampaign(singleCampaign)}
                                                            >
                                                                <Icon name='pencil' />
                                                            </Button>
                                                            <Button
                                                                data-tip='Delete Campaign'
                                                                className='icon-button action-btn mx-1'
                                                                onClick={() => handleViewModalOpen({ id, type: "delete" })}
                                                            >
                                                                <Icon name='trash' className="has-text-danger" />
                                                            </Button>
                                                        </>
                                                    }
                                                    {
                                                        // If status is neither active nor incomplete show archieve icon
                                                        String(status).toUpperCase() !== STATUS_CAMPAIGN.ACTIVE && String(status).toUpperCase() !== STATUS_CAMPAIGN.INCOMPLETE
                                                        &&
                                                        <Button
                                                            data-tip='Delete Campaign'
                                                            className='icon-button action-btn mx-1'
                                                            onClick={() => handleViewModalOpen({ id, type: "archive" })}
                                                        >
                                                            <Icon name='archive' className="has-text-danger" />
                                                        </Button>
                                                    }
                                                </div>
                                            </div>

                                            <div className='is-flex is-justify-content-space-between my-1' style={{ flexWrap: "wrap" }}>
                                                <span className='tag my-1'>
                                                    <strong>Start: {`${moment(startDateTime).format('MMM Do YYYY')} ${moment(startDateTime).format('h:mm:ss a')}`}</strong>
                                                </span>
                                                <span className='tag is-danger'>
                                                    <strong>End: {`${moment(endDateTime).format('MMMM Do YYYY')} ${moment(endDateTime).format('h:mm:ss a')}`}</strong>
                                                </span>
                                            </div>
                                        </div>
                                    </article>
                                </Box >
                            )
                        }
                    )
            }
        </Fragment >
    );
};
