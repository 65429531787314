import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { Modal, Icon, Title, Field, Control, Label, Button, Columns, Column, TextInput, } from '@safelyq/bulma-ui-library';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USER_ROLES, SAVE_USER_ROLE } from '../../components/AddNewMember/requests';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
const MembersPopup = ({ isOpen, onClose, refetch, allserviceProviders, selectedOptions, selectedServiceProviders }) => {
    const [formModel, setformModel] = useState({
        email: "",
        role: "ADMIN"
    })

    const [allRoles, setAllRoles] = useState([])
    const [serviceProviders, setServiceProviders] = useState([]);
    const [loader, setloader] = useState(false)
    const [allowEdit, setAllowEdit] = useState(false)

    const { id: businessId } = useParams() || {};

    const asfsdf = useQuery(GET_USER_ROLES, {
        onCompleted: (data) => {
            setAllRoles(data.getRoles.roles)
        }
    });
    const [onSaveUserRole] = useMutation(SAVE_USER_ROLE)

    const handleFormChange = (e) => {
        setformModel((prev) => ({
            ...prev,
            email: e.target.value
        }))
    }
    const handleRoleChange = (e) => {
        let { value } = e.target
        if (value === "ADMIN") {
            if (selectedOptions.user === null) {
                setServiceProviders(allserviceProviders)

            }
        }
        else {
            const newproviders = allserviceProviders.map((sp) => ({ ...sp, status: false }))
            setServiceProviders(newproviders)
        }
        setformModel((prev) => ({
            ...prev,
            role: String(value).toUpperCase()
        }))
    }

    const handleServiceProviderChange = (e, index) => {
        let { name } = e.target;
        const updatedInputs = [...serviceProviders];
        updatedInputs[index][name] = e.target.checked
        setServiceProviders(updatedInputs)
    }

    const handleMemberSave = async () => {
        try {
            setloader(true)
            const truesp = serviceProviders.filter((sp) => sp.status)
            const sp_Ids = truesp.map((sp) => sp.id)
            const response = await onSaveUserRole({
                variables: {
                    businessUserPermissionsInput: {
                        businessId: parseInt(businessId),
                        accessLevel: formModel.role,
                        businessUser: selectedOptions.user ? { userId: selectedOptions.user.id } : { email: formModel.email },
                        serviceProviders: sp_Ids
                    },
                },
            })
            setloader(false)

            if (response.data.saveBusinessUserRoles.isSaved) {
                toast.success(`User ${selectedOptions.user ? "Updated" : "Added"} Successfully`)
                await refetch()
                onClose(true)


            } else {
                toast.error(response.data.saveBusinessUserRoles.errorMessage)
            }
        } catch (error) {
            setloader(false)
            console.log(error);
        }
    }


    const isServiceSelect = serviceProviders.some((sp) => sp.status)

    useEffect(() => {
        if (selectedOptions.user) {
            setformModel({
                email: selectedOptions.user.email,
                role: selectedOptions.roleName
            })
        }
        setServiceProviders(selectedServiceProviders)
    }, [selectedOptions, selectedServiceProviders])

    return (
        <Modal active={isOpen} clipped>
            <Modal.Background />
            <Modal.Card>
                <Modal.Card.Head className={'is-flex is-justify-content-space-between'}>
                    <Title size='5' className='m-0'>{selectedOptions.user ? "Update" : "Add"} User</Title>
                    <Button size='small' className='button-icon' onClick={() => onClose(false)}><Icon name='close' className='is-right' />   </Button>
                </Modal.Card.Head>
                <Modal.Card.Body>
                    <Columns multiline>
                        <Column size='12'>
                            <Control>
                                <Field>
                                    <Label>Email</Label>
                                    <TextInput name="email" value={formModel.email} onChange={handleFormChange} disabled={selectedOptions.user ? true : false} />
                                </Field>
                            </Control>
                        </Column>
                        <Column size='12'>
                            <Control>
                                <Field>
                                    <Label>Role</Label>
                                    <div className='control'>
                                        {allRoles.map(({ name }) => (
                                            <label className='radio'>
                                                <input disabled={selectedOptions.user ? !allowEdit : false} type='radio' name='role' value={String(name).toUpperCase()} checked={String(formModel.role).toUpperCase() === String(name).toUpperCase()} onChange={handleRoleChange} />
                                                {" "}  {name}
                                            </label>
                                        ))}
                                    </div>
                                </Field>
                            </Control>
                        </Column>
                        {formModel.role === 'ASSOCIATE' && (
                            <Column size='12'>
                                <Control>
                                    <Field>
                                        <Label>Service Provider</Label>
                                        <Columns multiline>
                                            {(serviceProviders).map(({ name, status }, index) => (
                                                <Column size='6' className='pb-0'>
                                                    <label className='checkbox mr-3' key={`service-provider-${index}`}   >
                                                        <input disabled={selectedOptions.user ? !allowEdit : false} onChange={(e) => handleServiceProviderChange(e, index)} checked={status} name="status" type='checkbox' className='mr-2' />
                                                        {name}
                                                    </label>
                                                </Column>
                                            )
                                            )}
                                        </Columns>
                                    </Field>
                                </Control>
                            </Column>
                        )}
                        {/* <Column size='12'>
                            <Button disabled={isViewMode || isSaveLoading} onClick={handleModalCancel} className='mr-2'  >   Cancel   </Button>
                            <Button
                                color='info'
                                disabled={isSaveLoading || !email || !role || (role == 'ASSOCIATE' && isEmpty(serviceProviders))}
                                onClick={mode == modeEnum.VIEW ? () => handleModeChange(modeEnum.EDIT) : () => handleMemberSave()}
                                loading={isSaveLoading}
                            >
                                {mode == modeEnum.VIEW ? 'Edit' : mode == modeEnum.EDIT ? 'Update' : 'Add'}
                            </Button>
                        </Column> */}

                        <Column size='12'>
                            <Button onClick={onClose} className='mr-2'>Cancel</Button>
                            {/* {allowEdit ? (
                                <Button disabled={!isServiceSelect} color='info' onClick={handleMemberSave} loading={loader}>{selectedOptions.user ? "Update" : "Add"}</Button>

                            ) : (

                                <Button color='info' onClick={() => setAllowEdit(true)}  >Edit</Button>

                            )} */}


                            {selectedOptions.user ? (
                                <>
                                    {allowEdit ? (
                                        <Button disabled={formModel.role === 'ASSOCIATE' ? !isServiceSelect : false} color='info' onClick={handleMemberSave} loading={loader}>{selectedOptions.user ? "Update" : "Add"}</Button>

                                    ) : (

                                        <Button color='info' onClick={() => setAllowEdit(true)}  >Edit</Button>

                                    )}
                                </>
                            ) : (
                                <Button disabled={formModel.role === 'ASSOCIATE' ? !isServiceSelect : false} color='info' onClick={handleMemberSave} loading={loader}>{selectedOptions.user ? "Update" : "Add"}</Button>
                            )}
                        </Column>
                    </Columns>
                </Modal.Card.Body>
            </Modal.Card>
        </Modal>
    )
}

export default MembersPopup
