import React from 'react'
import { Button, Icon, Box } from '@safelyq/bulma-ui-library';
import moment from 'moment';
const Tileview = (props) => {
    return (
        <Box onClick={() => props.onSelectFile(props?.data)} shadow style={{ background: '#ffffff66' }}  >
            <article className='media' >
                <div style={{ overflow: "visible" }} className='media-content'>
                    <div className='content'>
                        <p>
                            <strong>{props?.data?.fileName}</strong>
                            <span className='is-right'>
                                <a onClick={(e) => props.onDeleteFile(e, props?.data?.id)}  >
                                    <Button color='danger' className='icon-button action-btn'  >
                                        <Icon name='trash' scale='xs' size='small' />
                                    </Button>
                                </a>
                            </span>
                            <br />
                            <span>  Total Records   -  {props?.data?.fileRecords.length} </span>
                            <span className='tag'>
                                <strong> {moment(props?.data?.createdTime).utc().format('YYYY-MM-DD hh:mm A')}</strong>
                            </span>

                        </p>
                    </div>

                </div>
            </article>
        </Box>
    )
}

export default Tileview