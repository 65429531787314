import React from 'react';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';

const StyledWrapper = styled.div.attrs(() => ({
  className: 'is-flex is-justify-content-center is-align-items-center',
}))`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
`;
const LoaderContainer = styled.div.attrs(() => ({
  className:
    'is-flex is-justify-content-center is-align-items-center has-background-white',
}))`
  width: 55px;
  height: 55px;
  border-radius: 8px;
`;

export const Loader = ({ ...props }) => {
  const { isFullScreen } = props || {};

  if (isFullScreen) return <FullScreenLoader />;
  else return <small>Loading</small>;
};

const FullScreenLoader = () => (
  <section className='hero is-fullheight'>
    <div className='hero-body'>
      <div className='container has-text-centered'>
        <ClipLoader size='40' />
        <br />
        <small>Loading please wait...</small>
      </div>
    </div>
  </section>
);
