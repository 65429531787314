import styled from 'styled-components';

const ListItem = styled.li.attrs(({ className }) => ({
  className,
}))``;

ListItem.propTypes = {};

ListItem.defaultProps = {};

export default ListItem;
