import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { INITIAL_DATA } from './constants';
import { GET_USER_LOCATIONS, GET_USER_GOOGLE_RESERVE_BUSINESSES, GET_BUSINESS_NUMBERS } from './requests';

export const useData = (googleReserve) => {
  const [locations, setLocations] = useState([]);

  const { loading: isLoading, error, data = { INITIAL_DATA }, refetch } = useQuery(
    googleReserve ? GET_USER_GOOGLE_RESERVE_BUSINESSES : GET_USER_LOCATIONS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ getUserLocations, getUserGoogleReserveBusinesses }) => {
        const resolvedLocations = googleReserve ? getUserGoogleReserveBusinesses : getUserLocations;
        setLocations(resolvedLocations);
        // Set custom loading to false
        // setIsRefetching(false);
      },
    }
  );

  return {
    isLoading,
    error,
    data: locations,
    refetch,
  };
};

export const useGetBusiness = (id) => {
  const { loading, error, data, refetch } = useQuery(GET_BUSINESS_NUMBERS, {
    variables: { businessId: id },
    fetchPolicy: 'no-cache',
    onCompleted: (responseData) => {
    },
    onError: (error) => {
      console.error("Error in hooks:", error);
    },
  });
  return {
    loading,
    error,
    data,
    refetch
  }
}