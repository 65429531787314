import { gql } from '@apollo/client';

export const GET_BUSINESS_CATEGORIES = gql`
  query GetBusinessCategories {
    getBusinessCategories {
    features {
      couponAllowed
      googleReserveAllowed
    }
    name
    description
    }
}
`;

export const DELETE_BUSINESS_CATEGORY = gql`
  mutation DeleteBusinessCategory($businessCategoryInput: BusinessCategoryInput!) {
    deleteBusinessCategory(businessCategoryInput: $businessCategoryInput) {
      errorMessage
      isSaved
    }
  }
`;


export const UPDATE_BUSINESS_CATEGORY = gql`
  mutation SaveBusinessCategory($businessCategoryInput: BusinessCategoryInput!) {
    saveBusinessCategory(businessCategoryInput: $businessCategoryInput) {
      isSaved
      errorMessage
    }
  }
`
