import { gql } from '@apollo/client';

const CORE_BUSINESS_FIELDS = gql`
  fragment CoreBusinessFields on Business {
    id
    name
    categoryTags
    city
    state
    timeZone
    country
    zipCode
    email
    phone 
    webAddress
    safetyTags
    address1
    address2
    description
    businessCategory {
      name
    } 
  }
`;

export const GET_BUSINESS_AND_ORGANIZATION = gql`
  ${CORE_BUSINESS_FIELDS}
  query ($id: Int) {
    getBusinessById(id: $id, showAll: true) {
      ...CoreBusinessFields
    }
    getCountries {
      id
      name
      timeZones {
        id
        name
        tzName
      }
      states {
        id
        name
      }
    }
    getBusinessCategories {
      name
      description
    }
    getUserOrganizations {
      id
      name
    }
  }
`;

export const SAVE_BUSINESS = gql`
  mutation SaveBusiness($businessInput: BusinessInput!) {
    saveBusiness(businessInput: $businessInput) {
      errorMessage
      isSaved
      business {
        id
      }
      addressValidationResult {
        errors
        isValidAddress
        googleAddresses {
          address1
          address2
          city
          country
          formattedAddress
          latitude
          locationType
          longitude
          postalCode
          postalCode
          state
        }
      }
    }
  }
`;

