import { gql } from '@apollo/client';

export const GET_SUBSCRIPTION_PLANS = gql`
query getBusiness {
  getSubscriptionPlans {
    bonus
    details
    fee
    id
    isDefaultPlan
    isOneTimeBonus
    name
    subscriptionTierType
    durationType
  }
}
`;

export const GET_DATA = gql`
  query all($id: Int!) {
    getSafelyQLegalTexts {
			legalTextFor
			legalText
	  }
    getUserProfile {
      user {
        email
      }
    }
    getBusinessById(id: $id, showAll: true) {
      timeZone
      businessSubscription {
        remainingBonus
      }
    }
  }
`;

export const CREATE_BUSINESS_SUBSCRIPTION = gql`
  mutation createBusinessSubscription($businessId: Int!, $subscriptionId: Int!, $tokenId: String!, $isRecurring: Boolean) {
  createBusinessSubscription(createBusinessSubscriptionInput: { businessId: $businessId, subscriptionId: $subscriptionId, tokenId: $tokenId, isRecurring: $isRecurring}) {
    errorMessage
    isSaved
  }
}
`;

export const GET_SUBSCRIPTIONS = gql`
  query all($id: Int!) {
    getBusinessById(id: $id, showAll: true) {
      businessSubscription {
        subscriptionPlan {
          bonus
          details
          durationType
          fee
          id
          isDefaultPlan
          name
          subscriptionTierType
          isOneTimeBonus
          features {
            advanceAppointmentAllowed
            basicAppointmentAllowed
            campaignAllowed
            consoleApplicationAllowed
            dedicatedNumberMessagingAllowed
            genericNumberMessagingAllowed
            googleFormAllowed
            googleReserveAllowed
            reviewResponseAllowed
          }
        } 
        bonus
        endDateTime
        id
        isActive
        remainingBonus
        startDateTime
      }
    }
  }
`;

export const GET_STRIPE_PUBLISHABLE_KEY = gql`
  query all {
    getStripePublishableKey {
      isSuccess
      errorMessage
      publishableKey
  }
}
`;