import { gql } from '@apollo/client';

export const GET_APPOINTMENT_SUMMARY = gql`
  query GetAppointmentSummary(
    $businessId: Int
    $startDate: Date
    $endDate: Date
  ) {
    getBusinessAppointmentsSummary(
      appointmentsSummaryInput: {
        businessId: $businessId
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      summary {
        summaryDetails {
          noOfGuests
          startTime
          totalCapacity
          startTimeOnly
          endTimeOnly
          endTime
          appointments {
            adults
            totalGuests
            status
            checkedInTime
            startTime
            subBusiness {
              name
            }
            venueEntrance {
              name
            }
            service {
              name
            }
            user {
              email
            }
          }
        }
      }
    }
  }
`;