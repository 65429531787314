import React from "react";
import { Link } from 'react-router-dom';
import {
  Section,
  Container,
  Title,
  Button,
} from "@safelyq/bulma-ui-library";
import BreadCrumbs from "../../components/Breadcrumbs";

export const SuperAdminHome = () => {
  return (
    <Section>
      <Container>
        <header className="is-flex is-justify-content-center is-align-items-center mb-2">
          <Title size="5" className="m-0">
            Super Admin
          </Title>
        </header>
      </Container>

      <Container className="mb-3">
        <BreadCrumbs />
      </Container>

      <Container>
        <header className="is-flex is-justify-content-left is-align-items-center mb-2">
          <Link to="/superadmin/managebusiness">
            <Button size="small" color="info">
              Manage Businesses
            </Button>
          </Link>
        </header>
      </Container>
      <Container>
        <header className="is-flex is-justify-content-left is-align-items-center mb-2">
          <Link to="/superadmin/marketinguser">
            <Button size="small" color="info" >
              Marketing Users
            </Button>
          </Link>
        </header>
      </Container>

      <Container>
        <header className="is-flex is-justify-content-left is-align-items-center mb-2">
          <Link to="/superadmin/appuser">
            <Button size="small" color="info" >
              App Users
            </Button>
          </Link>
        </header>
      </Container>

      <Container>
        <header className="is-flex is-justify-content-left is-align-items-center mb-2">
          <Link to="/superadmin/organization">
            <Button size="small" color="info" >
              Manage Organizations
            </Button>
          </Link>
        </header>
      </Container>

      <Container>
        <header className="is-flex is-justify-content-left is-align-items-center mb-2">
          <Link to="/superadmin/categories">
            <Button size="small" color="info" >
              Manage Business Categories
            </Button>
          </Link>
        </header>
      </Container>
      {/* <Container>
        <header className="is-flex is-justify-content-left is-align-items-center mb-2">
          <Button size="small" color="info" >
            Add SMS/WhatsApp Number
          </Button>
        </header>
      </Container> */}
    </Section>
  );
};
