import React, { useEffect, useRef } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Columns, Column, Block, Icon } from '@safelyq/bulma-ui-library';
import { Navbar, AdminDrawer } from '@safelyq/base-feature';
import { useAdminCount } from 'hooks/useAdminCount';
import { setIsAdmin, setIsAssosiate } from 'Redux/BusinessSlice/Business';
import { setMessageCount, setNotificationCount, setUnreadReviewCount } from 'Redux/BusinessSlice/Business';
import { useData } from '../../../AdminDrawer/hooks';

//for firestore
import firestore from '@firebase';
import { COLLECTION } from 'constants/index';
import { useDispatch, useSelector } from 'react-redux';
import useScreenWidth from '../../../CustomHookComponent/RenderingOnScreenSize';
import { useLocation } from 'react-router-dom';

export const AdminLayout = ({ ...props }) => {

  const location = useLocation();
  const path = location?.pathname;

  // If the route is admin/businesses than dont fetch admin data
  const dontAllowAdmin = (path == "/admin/businesses" || path == "/admin");

  // const state = useSelector(state => state);
  const dispatch = useDispatch();

  const {
    children,
    isAllowSideBar,
    isAllowFullScreen = false,
    isAllowNotifications,
    businessId,
    ...rest
  } = props;

  // Ref to track if API was called than dont call api again when other snapshot is triggered
  const apiCalled = useRef(false);

  // Component will only on the screens desktop or greater
  const screenWidth = useScreenWidth();
  const isDesktopOrGreater = screenWidth > 768;

  const handle = useFullScreenHandle();

  const handleFullScreen = () => {
    handle.enter();
  };

  // Admin notifications and review counts api
  const {
    onGetAdminData,
    isLoading,
    error,
    // refetchBusinessDate,
    unreadCount,
    unreadReviews,
    notificationsUnviewedCount,
    notificationsUnreadCount } = useAdminCount(apiCalled);

  // Set Unread message count to global state
  useEffect(() => {
    dispatch(setMessageCount(unreadCount));
  }, [unreadCount])

  // Set Unread review count to global state
  useEffect(() => {
    dispatch(setUnreadReviewCount(unreadReviews));
  }, [unreadReviews])

  // Set Unviewed notification count to global state
  useEffect(() => {
    dispatch(setNotificationCount(notificationsUnviewedCount));
  }, [notificationsUnviewedCount])

  // Collection will be triggered with respective value change
  useEffect(() => {

    if (dontAllowAdmin) {
      return;
    }
    else {
      const unsubscribeChat = firestore
        .collection(COLLECTION.CHAT)
        .where('businessId', '==', `${businessId}`)
        .onSnapshot(async querySnapshot => {

          if (!apiCalled.current) {
            apiCalled.current = true;
            onGetAdminData({ variables: { id: businessId, showAll: true, businessId } });
          }
        });

      const unsubscribeNotifications = firestore
        .collection(COLLECTION.NOTIFICATIONS)
        .where('businessId', '==', `${businessId}`)
        .onSnapshot(async querySnapshot => {

          if (!apiCalled.current) {
            apiCalled.current = true;
            onGetAdminData({ variables: { id: businessId, showAll: true, businessId } });
          }
        });

      const unsubscribeComments = firestore
        .collection(COLLECTION.COMMENTS)
        .where('businessId', '==', `${businessId}`)
        .onSnapshot(async querySnapshot => {

          if (!apiCalled.current) {
            apiCalled.current = true;
            onGetAdminData({ variables: { id: businessId, showAll: true, businessId } });
          }
        });

      const unsubscribeAppointments = firestore
        .collection(COLLECTION.APPOINTMENTS)
        .where('businessId', '==', `${businessId}`)
        .onSnapshot(async querySnapshot => {

          if (!apiCalled.current) {
            apiCalled.current = true;
            onGetAdminData({ variables: { id: businessId, showAll: true, businessId } });
          }
        });

      const unsubscribeRatings = firestore
        .collection(COLLECTION.RATINGS)
        .where('businessId', '==', `${businessId}`)
        .onSnapshot(async querySnapshot => {

          if (!apiCalled.current) {
            apiCalled.current = true;
            onGetAdminData({ variables: { id: businessId, showAll: true, businessId } });
          }
        });

      return () => {
        unsubscribeChat();
        unsubscribeNotifications();
        unsubscribeComments();
        unsubscribeAppointments();
        unsubscribeRatings();
      };
    }
  }, [businessId]);

  const { avatarName, logout } = rest;

  return (
    <Columns multiline gap='0' className='mb-0'>
      <Column size='12'>
        <Navbar
          onFullScreen={handleFullScreen}
          isAllowFullScreen={isAllowFullScreen}
          isAllowSideBar={isAllowSideBar}
          isAllowNotifications={isAllowNotifications}
          onLogout={logout}
          avatarName={avatarName}
        />
      </Column>
      {isAllowSideBar && isDesktopOrGreater && (
        <Column size={2} className='is-hidden-mobile'>
          <AdminDrawer />
        </Column>
      )}
      <Column size={isAllowSideBar ? 10 : 12}>
        <FullScreen handle={handle}>
          <section className='hero p-0 is-white is-fullheight-with-navbar has-text-black'>
            <Block>{children}</Block>
          </section>
        </FullScreen>
      </Column>
    </Columns>
  );
};
