import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Box,
  Title,
  Section,
  Container,
  Subtitle,
  Columns,
  Column,
  Button,
} from '@safelyq/bulma-ui-library';
import emptyImage from 'assets/empty.svg';

export const EmptyCard = () => {
  const { goBack } = useHistory();

  return (
    <Section>
      <Container>
        <Columns centered>
          <Column size='two-fifths'>
            <Box bordered>
              <div className='has-text-centered'>
                <img src={emptyImage} className='m-5' width={120} />
                <Title size='6'>No Notices Right Now!</Title>
                <Subtitle size='6' className='mb-3'>
                  When new notification comes, it will appear here.
                </Subtitle>
                <Link to='#' onClick={goBack}>
                  <Button size='small' light color='info'>
                    Go Back
                  </Button>
                </Link>
              </div>
            </Box>
          </Column>
        </Columns>
      </Container>
    </Section>
  );
};
