import React, { Fragment, useState } from 'react';
import { useParams } from 'react-router';
import { Button } from '@safelyq/bulma-ui-library';
import { useData } from './hooks';

export const SendReply = ({ refetch, ratingId, onClose }) => {
  const [commentText, setCommentText] = useState();

  const { onSaveBusinessRatingResponse } = useData(refetch);

  const { businessId } = useParams();

  const isDisabledPostButton = !Boolean(commentText);

  const handleCommentTextChange = ({ target: { value } }) =>
    setCommentText(value);

  const handleSubmit = () =>
    onSaveBusinessRatingResponse({
      variables: {
        ratingResponseInput: {
          ratingId,
          businessId,
          responseCommentText: commentText,
        },
      },
    }).then(() => {
      setCommentText('');
      onClose();
    });

  return (
    <Fragment>
      <div className='my-3'>
        <textarea
          className='textarea'
          row='2'
          onChange={handleCommentTextChange}
        />
        <Button
          size='small'
          color='info'
          disabled={isDisabledPostButton}
          className='px-5 my-2'
          onClick={handleSubmit}
        >
          Post
        </Button>
      </div>
    </Fragment>
  );
};
