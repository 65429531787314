import React from 'react'
import { DesktopHeader } from '../LayoutComponents/Header';
import { Accordion } from './AccordionSummary';
import moment from 'moment';

const AppointmentsTable = ({ summary, slides }) => {

    return (
        <div>
            <div className='is-hidden-mobile'>
                <DesktopHeader />
            </div>

            {
                summary?.summaryDetails?.map(({ appointments, ...rest }, index) => (
                    <Accordion
                        key={`summary-${index}`}
                        noOfSlides={slides}
                        appointments={appointments}
                        isHighlighted={
                            moment(rest?.endTime).isSameOrAfter( // in future wrt current time
                                moment() // current time
                            )
                                ? moment(rest?.startTime).isSameOrBefore( // in past wrt current time
                                    moment() // current time
                                )
                                    ? index + 1 < summary?.summaryDetails?.length
                                        ? moment(
                                            summary?.summaryDetails[index + 1]?.startTimeOnly,
                                            'h:mmA'
                                        ).isSameOrBefore(moment(moment().format('h:mmA'), 'h:mmA'))
                                            ? false
                                            : true
                                        : true
                                    : false
                                : false
                        }
                        {...rest}
                    />
                ))}
        </div>
    )
}

export default AppointmentsTable