import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Icon } from '@safelyq/bulma-ui-library';
import { AdminDrawer } from '../AdminDrawer';
import { useProfile } from 'hooks/useProfile';

const StyledNav = styled.nav.attrs(() => ({
    className: 'navbar p-1',
}))`
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
`;

const StyledImage = styled.img.attrs(() => ({}))`
  max-height: 2.3rem !important;
  width: auto;
`;

export const SuperAdminNavbar = ({ ...props }) => {
    const [toggle, setToggle] = useState(false);

    const [profileToggle, setProfileToggle] = useState(false);

    const {
        businessId,
        avatarName,
        onFullScreen,
        isAllowFullScreen,
        isAllowSideBar,
        isAllowNotifications,
        onLogout,
        unreadMessages,
        businessName,
    } = props;

    const { isLoggedIn } = useProfile();

    const handleToggle = () => setToggle(!toggle);

    const handleProfileToggle = () => setProfileToggle(!profileToggle);

    const handleMenuClose = () => setToggle(false);

    const handleLogout = () => {
        localStorage.setItem('post_logout_redirect_url', '/admin');
        localStorage.setItem("authPersist", "false") // change the logged in flag to false
        onLogout();
    };

    // const logoUrl =
    //     businessId === 'businesses' ||
    //         businessId === 'admin' ||
    //         businessId === 'create-organization' ||
    //         businessId === 'manage-business'
    //         ? `/assets/logo/0`
    //         : `/assets/logo/${businessId}`;
    const logoUrl = businessId == null ? `/assets/logo/0` : `/assets/logo/${businessId}`;

    return (
        <StyledNav role='navigation' aria-label='main navigation'>
            <div className='navbar-brand' style={{ width: '100%' }}>
                <Link to='/admin' className='navbar-item py-0' onClick={handleMenuClose}   >
                    <StyledImage src={logoUrl} alt='logo' />
                    {businessId ? (<h6 className='ml-3'> <b>{businessName}</b>  </h6>) : null}
                </Link>
                <div style={{ marginLeft: 'auto' }} />
                {isLoggedIn && (
                    <Fragment>
                        {isAllowNotifications && (
                            <Fragment>
                                <>
                                    <div className='navbar-item pl-0'>
                                        <Link to={`/admin/messages/${businessId}`} title='Messages'>
                                            <Button size='small' className='button-icon'> <Icon name='comments' /> </Button>
                                            {unreadMessages > 0 && (<span className='notification-badge' style={{ marginTop: -38 }} > {unreadMessages > 9 ? '9+' : unreadMessages}  </span>)}
                                        </Link>
                                    </div>
                                    <div className='navbar-item pl-0'>
                                        <Link to={`/admin/notifications/${businessId}`} title='Notifications'  >
                                            <Button size='small' className='button-icon'> <Icon name='bell' />  </Button>
                                        </Link>
                                    </div>
                                </>
                            </Fragment>
                        )}
                        {isAllowFullScreen && (
                            <div className='navbar-item pl-0' title='Expand Screen'>
                                <Button size='small' className='button-icon' onClick={onFullScreen}  >  <Icon name='arrows-alt' />  </Button>
                            </div>
                        )}
                        <div className='navbar-item pl-0' title='Profile'>
                            <div className={`dropdown ${profileToggle && 'is-active'} is-right`} >
                                <div className='dropdown-trigger'>
                                    <Link to="/admin/businesses">
                                        <Button size="small" color="info" style={{ marginRight: "10px" }}>
                                            Admin
                                        </Button>
                                    </Link>
                                    <Button size='small' color='info' light className='avatar-icon rounded' aria-controls='dropdown-menu' onClick={handleProfileToggle}  > <strong>{avatarName}</strong> </Button>
                                </div>
                                <div className='dropdown-menu' id='dropdown-menu' role='menu'>
                                    <div className='dropdown-content '>
                                        <Link to='/admin/account/setting' className='dropdown-item' onClick={handleProfileToggle}  >  Account Settings  </Link>
                                        <Link to='#' className='dropdown-item' onClick={handleLogout}  > Logout  </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
                {isAllowSideBar && (
                    <a role='button' className={`navbar-burger is-hidden-desktop burger m-0 ${toggle && 'is-active'}`} aria-label='menu' aria-expanded='false' data-target='mainNavbar' onClick={handleToggle} >
                        <span aria-hidden='true' />
                        <span aria-hidden='true' />
                        <span aria-hidden='true' />
                    </a>
                )}
            </div>
            {isAllowSideBar && (
                <div className={'navbar-menu is-hidden-tablet' + (toggle ? ' is-active' : '')}  >
                    <AdminDrawer unreadMessages={unreadMessages} toggle={handleToggle} />
                </div>
            )}
        </StyledNav>
    );
};
