import React, { useContext, useReducer, createContext } from 'react';

const BusinessStateContext = createContext();
const BusinessDispatchContext = createContext();

const businessReducer = (state, action) => {
  const { payload } = action || {};

  switch (action.type) {
    case 'SET_BUSINESS_ID':
      return {
        ...state,
        businessId: payload.businessId,
      };

    case 'SET_BUSINESS_DATA':
      return {
        ...state,
        business: payload.business,
        accordionActiveSteps: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      };

    case 'SET_COUNTRIES':
      return {
        ...state,
        countries: payload.countries,
      };

    case 'SET_ACCORDION_ACTIVE_STEPS':
      return {
        ...state,
        accordionActiveSteps: payload,
      };

    case 'ADD_NEW_COUPON': {
      const business = state.business;

      const businessCoupons = [...business.businessCoupons, { ...payload }];

      return {
        ...state,
        business: { ...business, businessCoupons },
      };
    }

    case 'UPDATE_COUPON': {
      const couponId = payload.id;

      const business = state.business;

      let businessCoupons = [...business.businessCoupons];

      let index = businessCoupons.findIndex(({ id }) => id === couponId);

      businessCoupons[index] = payload;

      return {
        ...state,
        business: { ...business, businessCoupons },
      };
    }

    case 'SELECTED_COUPON_ID': {
      return {
        ...state,
        selectedCouponId: payload,
      };
    }

    case 'CHANGE_COUPON_STATUS': {
      const couponId = payload.couponId;

      const business = state.business;

      let businessCoupons = [...business.businessCoupons];

      let index = businessCoupons.findIndex(({ id }) => id === couponId);

      businessCoupons[index] = {
        ...businessCoupons[index],
        isActive: payload.checked,
      };

      return {
        ...state,
        business: { ...business, businessCoupons },
      };
    }

    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};

export const BusinessProvider = ({ children }) => {
  const [state, dispatch] = useReducer(businessReducer, {
    businessId: null,
    business: {},
    countries: [],
    accordionActiveSteps: [],
  });

  return (
    <BusinessDispatchContext.Provider value={dispatch}>
      <BusinessStateContext.Provider value={state}>
        {children}
      </BusinessStateContext.Provider>
    </BusinessDispatchContext.Provider>
  );
};

export const useBusinessState = () => useContext(BusinessStateContext);
export const useBusinessDispatch = () => useContext(BusinessDispatchContext);
