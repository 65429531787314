import { useEffect, useState } from 'react';
const appointments = [];

export const useData = ({ id }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    setData(appointments.find(({ id: apptId }) => apptId === id));
  }, [id]);

  const handleRemoveComment = (index) => {
    const notes = data.notes.filter((_, indx) => indx !== index);
    setData({ ...data, notes });
  };

  const handleAddComment = (text) => {
    setData({ ...data, notes: [...data.notes, text] });
  };

  return {
    data,
    onRemoveComment: handleRemoveComment,
    onAddComment: handleAddComment,
  };
};
