import React, { useState } from 'react';
import { Box, Icon, Title, Subtitle, Switch, Level, Button, Text, Modal } from '@safelyq/bulma-ui-library';
import { GET_USER_PROFILE, SAVE_USER_PROFILE } from '../../routes/ManageSettings/requests';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { Spin } from 'antd';
import { LoadingSection } from '../LoadingSection';
import { FaInfoCircle } from "react-icons/fa";
import styled from 'styled-components';

const styles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexGrow: 1,
};

const notificationStyle = {
  width: "max-content",
}

export const NotificationSetting = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setnotifications] = useState({
    allowEmail: false,
    allowSms: false,
    allowWhatsApp: false
  })

  const modalStyle = {
    position: "absolute",
    top: "10%",
  }

  const ConsentDiv = styled.div`
    height: auto;
     font-weight: bold;

    @media (max-width: 992px) {
        /* Styles for screens smaller than 768px */
        height: 145px;
       margin-top: 20px;
        font-weight: bold;
    }
`;

  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [loader, setloader] = useState(false)
  const [showConsent, setshowConsent] = useState(false)

  const { loading, refetch } = useQuery(GET_USER_PROFILE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setnotifications({
        ...data.getUserProfile.user.userPreferences
      })
    }
  });

  useQuery(GET_USER_PROFILE, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      setValues({ ...data.getUserProfile.user })
    }
  });

  const [onProfileUpdate] = useMutation(SAVE_USER_PROFILE,);


  const handleValueChange = (value, name) => {
    setnotifications((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const toggleConcent = () => {
    setshowConsent((prev) => !prev)
  }

  const handleSaveChanges = async () => {
    try {
      setloader(true)
      await onProfileUpdate({
        variables: {
          userProfileInput: {
            firstName: values.firstName,
            lastName: values.lastName,
            ...notifications
          },
        }
      })
      await refetch()
      setloader(false)
      toast.success("Notifications updated successfully")
      setIsOpen(false)
    } catch (error) {
      console.log("Error", error);
    }
  }
  if (loading) {
    return <LoadingSection />
  }
  else {
    return (
      <Box shadow={true}>
        <Title size='5'>   <Icon name='bell' /> Notifications    </Title>
        <Subtitle size='6' color='grey-light'>  Configure notifications    </Subtitle>
        <hr />
        <div className='row'>
          <div className='col-lg-12 mb-5'>
            <Level>
              <Level style={styles}>
                <Text>Enable Email</Text>
                <Level className='is-flex'>
                  <span onClick={toggleConcent} className='pr-2 is-clickable is-flex is-align-items-center' style={notificationStyle}>I consent... {" "}<FaInfoCircle className='ml-2' /></span>
                  <Switch value={notifications.allowEmail} name={"allowEmail"} onValueChange={(val,) => handleValueChange(val, "allowEmail")} />
                </Level>
              </Level>
            </Level>
          </div>
          <div className='col-lg-12  mb-5'>
            <Level>
              <Level style={styles}>
                <Text>Enable WhatsApp</Text>
                <Level className='is-flex'>
                  <span onClick={toggleConcent} className='pr-2 is-clickable is-flex is-align-items-center' style={notificationStyle}>I consent... {" "}<FaInfoCircle className='ml-2' /></span>
                  <Switch value={notifications.allowWhatsApp} name={"allowWhatsApp"} onValueChange={(val,) => handleValueChange(val, "allowWhatsApp")} />
                </Level>
              </Level>
            </Level>
          </div>
          <div className='col-lg-12  mb-5'>
            <Level>
              <Level style={styles}>
                <Text>Enable SMS</Text>
                <Level className='is-flex'>
                  <span onClick={toggleConcent} className='pr-2 is-clickable is-flex is-align-items-center' style={notificationStyle}>I consent... {" "}<FaInfoCircle className='ml-2' /></span>
                  <Switch value={notifications.allowSms} name={"allowSms"} onValueChange={(val,) => handleValueChange(val, "allowSms")} />
                </Level>
              </Level>
            </Level>
          </div>
          <div className='col-lg-3'>
            <Button color='info' onClick={() => setIsOpen(true)} >   Save Changes  </Button>
          </div>
          <div className='col-lg-9 is-flex is-align-items-center is-fullheight'>
            {showConsent && <ConsentDiv className=''>By enabling this box I consent to receive notifications from SafelyQ and partner businesses.</ConsentDiv >}
          </div>
        </div>

        <Modal active={isOpen} clipped>
          <Modal.Background />
          <Modal.Card style={modalStyle}>
            <Modal.Card.Head>
              <div className='container is-flex is-justify-content-space-between is-align-items-center'>
                <Title size='6' className='m-0'>
                  User Consent for Notifications
                </Title>
                <Icon name='close' className="is-clickable mx-2" onClick={() => setIsOpen(false)} />
              </div>

            </Modal.Card.Head>
            <Modal.Card.Body>
              {notifications.allowEmail === "" || notifications.allowEmail === false
                ?
                <p className='my-3'>I will not receive Email Notifications </p>
                :
                <p className='my-3'> I consent to receive Email Notifications</p>
              }
              {notifications.allowSms === "" || notifications.allowSms == false
                ?
                <p className='my-3'> I will not receive SMS Notifications </p>
                :
                <p className='my-3'> I consent to receive SMS Notifications</p>
              }
              {notifications.allowWhatsApp === "" || notifications.allowWhatsApp === false
                ?
                <p className='my-3'>I will not receive WhatsApp Notifications </p>
                :
                <p className='my-3'> I consent to receive WhatsAppp Notifications</p>
              }
              <div className='row mt-5'>
                <div className='col-lg-3 col-6'>
                  <Spin className='' spinning={loader}>
                    <Button className="is-default is-fullwidth" color='success' onClick={handleSaveChanges}  > Confirm  </Button>
                  </Spin>
                </div>
                <div className='col-lg-3 col-6'>
                  <Button className='is-default is-fullwidth' color='danger' onClick={() => setIsOpen(false)}  > Cancel  </Button>
                </div>
              </div>
            </Modal.Card.Body>
          </Modal.Card>
        </Modal>
      </Box>
    )
  }

}
