import { gql } from '@apollo/client';

export const SAVE_BUSINESS_CAMPAIGN = gql`
  mutation ($campaignInput: CampaignInput!) {
    saveCampaign(campaignInput: $campaignInput) {
      errorMessage
      isSaved
      amount
      isPaymentRequired
      bonus
    }
  }
`;

export const SAVE_BUSINESS_CAMPAIGN_DATA = gql`
  mutation ($campaignInput: CampaignInput!) {
    saveCampaignData(campaignInput: $campaignInput) {
      errorMessage
      isSaved
    }
  }
`;

export const CALCULATE_CAMPAIGN_AUDIENCE = gql`
  query GetCalculatedCampaignUsers(
    $campaignUsersCalculationInput: CampaignUsersCalculationInput!
  ) {
    getCalculatedCampaignUsers(
      campaignUsersCalculationInput: $campaignUsersCalculationInput
    ) {
      targetSlotAudience
      totalTargetAudience
      isSuccess
      warningMessage
      errorMessage
      costInDollar
      totalMMSCost
      totalSMSCost
      totalWhatsAppCost
      totalEmailCost
      usersCost
      perUserCost 
      campaignDetails{
        platform
        targetedTimeName
        targetedDateTime
        targetSlotAudience
      }
    }
  }
`;

export const GET_DATA = gql`
  query all($id: Int!) {
    getSafelyQLegalTexts {
			legalTextFor
			legalText
	  }
    getUserProfile {
      user {
        email
      }
    }
    getBusinessById(id: $id, showAll: true) {
         timeZone
    businessSubscription {
      remainingBonus
    }
    businessAttachments {
      id
      fileName
    }
    campaignTargetTimes {
      name
      isCustomTime
      isSelected
      startTime
      endTime
    }
    safelyQCampaignGroups {
      id
      isSelected
      maxCharacter
      name
      priceInCents
      messageText
    }
    businessCoupons {
      id
      code
      couponUrl
      couponUsageLimit
      couponUsageLimitValue
      couponsUsed
      discountType
      discount
      expireStatus
      endDate
      imageUrl
      isActive
      isFavorite
      startDate
      summary
      terms
      title
    }
          marketingCampaignTemplates {
        name
        notificationTemplateType
        templates {
          contentData {
            id
            value
          }
          notificationChannel
          subjectData {
            id
            value
          }
          notificationChannelDispalyName
        }
      }
    }
  }
`;

export const GET_BRAINTREE_CLIENT_TOKEN = gql`
  query all($isCustomerRequired: Boolean!) {
  getBraintreeClientToken(isCustomerRequired: $isCustomerRequired) {
    isSuccess
    clientToken
  }
}
`;

export const BRAINTREE_TRANSACTION_REQUEST = gql`
  mutation ($transactionRequest: TransactionRequestInput!) {
  invokeBraintreeTransactionRequest(transactionRequest: $transactionRequest) {
    isSuccess
    errorMessage
    validationErrors{
      message
      errorCode
      attribute
    }
  }
}
`;

export const GET_STRIPE_PUBLISHABLE_KEY = gql`
  query all {
    getStripePublishableKey {
      isSuccess
      errorMessage
      publishableKey
  }
}
`;

export const GET_SINGLE_CAMPAIGN = gql`
  query GetCampaignById($id: Int!) {
    getCampaignById(id: $id) {
    id,
    title,
    details,
    businessAttachment{
      id
    },
    campaignImages{
      contentType
      createdTime
      fileName
      fileSize
      key
      publicUrl
      id
      jsonData
    },
    startDateTime,
    endDateTime,
    budget,
    zipCodes,
    campaignGroups{
      isSelected
      messageText
      name
      priceInCents
    },
    campaignTargetTimes{
      endTime
      name
      startTime
      isSelected
      isCustomTime
    },
    businessCoupon{
       code
        couponUrl
        couponUsageLimit
        couponUsageLimitValue
        couponsUsed
        discount
        discountType
        endDate
        expireStatus
        id
        imageUrl
        isActive
        isFavorite
        summary
        startDate
        terms
        title
    }
    }
  }
`