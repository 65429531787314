import { gql } from '@apollo/client';

export const GET_ADMIN_NOTIFICATIONS = gql`
  query GetAdminNotifications($businessId: Int!) {
    getAdminNotifications(businessId: $businessId) {
      unreadCount
      unviewedCount
      adminNotifications {
        id
        notificationChannel
        subject
        content
        createdTime
        correlationKey
        notificationType
        sourceId
        sentChannels
        isRead
        isViewed
        business {
          id
          name
          timeZone

          picture {
            path
          }
        }
      }
    }
  }
`;

export const MARK_ADMIN_AS_VIEWED = gql`
  mutation all($businessId: Int!) {
    markAdminNotificationsAsViewed(businessId: $businessId) {
      errorMessage
      isSuccess
    }
  }
`;