import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    businessName: '',
    businessCategory: '',
    businessFeatures: null,
    businessId: null,
    isAssociate: null,
    isAdmin: null,
    messageCount: 0,
    notificationCount: 0,
    reviewCount: 0,
    businessSubLevels: [],
    businessSubscriptionPlan: null,
};

const businessSlice = createSlice({
    name: 'business',
    initialState,
    reducers: {
        setBusinessName(state, action) {
            state.businessName = action?.payload;
        },
        setBusinessCategory(state, action) {
            state.businessCategory = action?.payload;
        },
        setBusinessFeatures(state, action) {
            state.businessFeatures = action?.payload;
        },
        setBusinessId(state, action) {
            state.businessId = action?.payload;
        },
        setIsAssosiate(state, action) {
            state.isAssociate = action?.payload;
        },
        setIsAdmin(state, action) {
            state.isAdmin = action?.payload;
        },
        setMessageCount(state, action) {
            state.messageCount = action?.payload;
        },
        setNotificationCount(state, action) {
            state.notificationCount = action?.payload;
        },
        setUnreadReviewCount(state, action) {
            state.reviewCount = action?.payload;
        },
        setBusinessSubLevels(state, action) {
            state.businessSubLevels = action?.payload;
        },
        setBusinessSubscriptionPlan(state, action) {
            state.businessSubscriptionPlan = action?.payload;
        },
    },
});

export const { setBusinessName, setBusinessCategory, setBusinessFeatures, setBusinessId, setIsAssosiate, setIsAdmin, setMessageCount,
    setNotificationCount, setUnreadReviewCount, setBusinessSubLevels, setBusinessSubscriptionPlan } = businessSlice.actions;
export default businessSlice.reducer;
