import styled from 'styled-components';
import LevelItem from '../LevelItem';

const className = (strings, props) => {
  const classNames = ['level-left'].join(' ');

  return classNames;
};

const LevelLeft = styled.div.attrs((props) => ({
  className: className`${props}`,
}))``;

LevelLeft.Item = LevelItem;

LevelLeft.propTypes = {};

LevelLeft.defaultProps = {};

export default LevelLeft;
