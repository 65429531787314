import React from 'react';
import { Link } from 'react-router-dom';
import { Title, Subtitle, Block } from '@safelyq/bulma-ui-library';

export const NoSearchFound = () => (
  <Block className='has-text-centered'>
    <img src='/images/search-not-found.svg' alt="not found" className='mb-2' width={100} />
    <Title size='6'>No business match</Title>
    <Subtitle size='6'>
      Try modifying your filter criteria or{' '}
      <Link to='#'>Create your own business</Link>
    </Subtitle>
  </Block>
);
