import React from 'react'
import { GiEmptyHourglass } from "react-icons/gi";
import StarRatings from 'react-star-ratings';

const Businesses = ({ businesses }) => {
    // const tags = ["React", "JavaScript", "CSS", "HTML"];
    const emptyThumbnail = 'https://s3.amazonaws.com/speedsport-news/speedsport-news/wp-content/uploads/2018/07/01082232/image-not-found.png';

    return (
        <div>
            <div className='my-4' style={{
                overflowX: "auto",
                whiteSpace: "nowrap",
                width: "100%",
                scrollbarWidth: "thin"
            }}>
                {
                    businesses?.length === 0
                        ?
                        <div className='is-flex is-justify-content-center' style={{ width: "100%" }}>
                            <GiEmptyHourglass color='gray' fontSize={26} />
                            <p className='ml-2 mb-5 has-text-weight-semibold has-text-grey-dark'>No Suggestions Found</p>
                        </div>
                        :

                        businesses?.map((business, i) => {
                            return (

                                <div
                                    className='card-custom-businesses horizontal-business-card is-relative'
                                    key={business.id}
                                >
                                    <div style={{ position: "relative" }}>
                                        <img className='business-image-business-card'
                                            src={business?.picture?.path || emptyThumbnail}
                                            alt={business.name}
                                            onError={(e) => e.target.src = emptyThumbnail}
                                            style={{ width: "100%", objectFit: "cover" }} />
                                    </div>


                                    <div className='px-2 my-2'>
                                        <p> {business?.name?.length <= 14 ? business?.name : `${business?.name.substring(0, 14)}...`} </p>
                                        <p className='has-text-weight-bold has-text-grey is-capitalized'>
                                            {business?.businessCategory?.name?.length <= 14 ? business?.businessCategory?.name : `${business?.businessCategory?.name.substring(0, 14)}...`}
                                        </p>

                                        {
                                            business?.categoryTags?.length !== 0 && <>
                                                <p className='is-size-7 has-text-weight-bold'>Category Tags</p>
                                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                    {business?.categoryTags?.map((tag, index) => (
                                                        <p className='mb-1 mr-1 is-size-7' key={index} style={{ padding: "2px 10px", border: "1px solid #ccc", borderRadius: "5px" }}>
                                                            {tag}
                                                        </p>
                                                    ))}
                                                </div>
                                            </>
                                        }
                                        {
                                            business?.safetyTags?.length !== 0 && <>
                                                <p className='is-size-7 has-text-weight-bold'>Safety Tags</p>
                                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                                    {business?.safetyTags?.map((tag, index) => (
                                                        <p className='mb-1 mr-1 is-size-7' key={index} style={{ padding: "2px 10px", border: "1px solid #ccc", borderRadius: "5px" }}>
                                                            {tag}
                                                        </p>
                                                    ))}
                                                </div>
                                            </>
                                        }

                                        <div className='business-card-details'>
                                            <span className='subtitle is-7 mr-2'>  {business?.city}  </span>
                                            <StarRatings rating={business?.serviceRatings?.value} starRatedColor='rgb(26 17 186)' starSpacing='3px' starDimension='15px' />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                }
            </div>
        </div>
    )
}

export default Businesses