import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { getStatusColor, getStatusName } from 'methods/getStatuses';
import { Modal, Title, Icon, Table } from '@safelyq/bulma-ui-library';
import ReactTooltip from 'react-tooltip';
import Overflow from 'react-overflow-indicator';
import { Spin } from 'antd';
import { SummaryTable } from './SummaryTable';

export const ViewModel = ({ isOpen, data, deletedData, loading, onCloseModal, type, deleteCampaign, deleteLoading, refetch, campaignId, refetchSingleCampaign }) => {

  // console.log("view modal rendered:");

  const [allowLoading, setAllowLoading] = useState(false);
  const startDateTime = `${moment(data?.startDateTime).format('MM-DD-YYYY')} ${moment(data?.startDateTime).format('hh:mm A')
    }`;
  const endDateTime = `${moment(data?.endDateTime).format('MM-DD-YYYY')} ${moment(data?.endDateTime).format('hh:mm A')
    }`;

  // Responsive With for the files preview
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const extensions = {
    video: {
      type: ['mpeg', 'mp4', 'quicktime', 'webm', '3gpp', '3gpp2', '3gpp-tt', 'H261', 'H263', 'H263-1998', 'H263-2000', 'H264'],
      preview: [' video/mpeg ', ' video/mp4 ', ' video/quicktime ', ' video/webm ', ' video/3gpp ', ' video/3gpp2 ', ' video/3gpp-tt ', ' video/H261 ', ' video/H263 ', ' video/H263-1998 ', ' video/H263-2000 ', ' video/H264 ']
    },
    audio: {
      type: ['basic', 'L24', 'mp4', 'mpeg', 'ogg', 'vnd.rn-realaudio', 'vnd.wave', '3gpp', '3gpp2', 'ac3', 'webm', 'amr-nb', 'amr', 'mp3'],
      preview: [' audio/basic ', ' audio/L24 ', ' audio/mp4 ', ' audio/mpeg ', ' audio/ogg ', ' audio/vnd.rn-realaudio ', ' audio/vnd.wave ', ' audio/3gpp ', ' audio/3gpp2 ', ' audio/ac3 ', ' audio/webm ', ' audio/amr-nb ', ' audio/amr ', ' mp3 ']
    },
    image: {
      type: ['jpeg', 'jpg', 'gif', 'png', 'bmp', 'tiff'],
      preview: [' image/jpeg ', ' image/jpg ', ' image/gif ', ' image/png ', ' image/bmp ', ' image/tiff ']
    }
  }

  const handleDeleteCampaign = () => {
    deleteCampaign(deletedData?.id)
    setAllowLoading(true)
  }

  // close the modal when deleteLoading is set to false
  if (allowLoading && !deleteLoading) {
    onCloseModal();
    // Refetch Campaign Data
    refetch();
  }

  return (
    <Modal active={isOpen}>
      <Modal.Background />
      {
        type === "view"
          ?
          // View Details
          <Modal.Card className='modal-compaign'>
            <Overflow style={{ overflow: "scroll" }}>
              <Overflow.Content className='is-flex is-flex-direction-column'>
                <Spin spinning={loading}>
                  <Modal.Card.Body className='modal-compaign-body'>
                    <div className='mb-5 is-flex is-justify-content-space-between is-align-items-center'>
                      <Title size='5' className='m-0'>
                        Campaign Details
                      </Title>
                      <StyledList style={{ marginLeft: "auto", marginRight: "10px" }}>
                        <span
                          className={`tag is-light has-text-black`}
                          style={{
                            background: `rgba(${getStatusColor(data?.status)}, 0.1)`,
                            color: `rgb(${getStatusColor(data?.status)})`,
                          }}
                        >
                          {getStatusName(data?.status)}
                        </span>
                      </StyledList>
                      <div>
                        <Icon
                          name='times'
                          className='is-hoverable has-text-link'
                          onClick={onCloseModal}
                        />
                      </div>
                    </div>
                    {data?.title && (
                      <div className='is-flex is-justify-content-space-between is-align-items-center'>
                        <div style={{ display: "block" }}>
                          <Title size='6' className='mb-0'>
                            Title
                          </Title>
                          <small>{data?.title}</small>
                        </div>
                        <div style={{ display: "block" }}>
                          <Title size='6' className='mb-0'>
                            {data?.status === "Completed" ? "Completed On" : data?.status === "Cancelled" ? "Cancelled On" : data?.status === "Active" ? "Activated On" : "Last Modified On"}
                          </Title>
                          <small className='tag is-light has-text-black'>{moment(data?.modifiedDate).format("MMMM Do YYYY, h:mm:ss a")}</small>
                        </div>
                      </div>
                    )}
                    {/* &nbsp; */}
                    {data?.details.trim() !== "" || data?.campaignGroups?.find(camp => camp?.messageText?.trim() !== "" && camp?.messageText !== null)?.messageText ? (
                      <>
                        <Title size='6' className='mt-2 mb-0'>
                          Campaign Detail
                        </Title>
                        <div class="columns mt-1 mb-0">
                          <div class="column is-two-thirds py-0 has-text-justified">
                            {
                              // Show campaign details if exist
                              data?.details
                                ?
                                <small className='is-light has-text-black' style={{ backgroundColor: "whitesmoke", paddingLeft: "9px", paddingRight: "9px", paddingTop: "2px", paddingBottom: "2px", borderRadius: "4px" }}>{data?.details}</small>
                                :
                                // Show campaign group text if exist
                                data?.campaignGroups?.filter(camp => camp.messageText?.trim() !== "" && camp.messageText !== null).map(camp => {
                                  return (
                                    <div>
                                      <small className='has-text-weight-semibold is-block'>{camp?.name}:</small>
                                      <small className='is-light has-text-black' style={{ backgroundColor: "whitesmoke", paddingLeft: "9px", paddingRight: "9px", paddingTop: "2px", paddingBottom: "2px", borderRadius: "4px" }}>{camp?.messageText}</small>
                                    </div>
                                  )
                                })
                            }
                          </div>
                          <div style={{ width: "100%" }}>
                            {
                              data?.campaignImages?.length !== 0 && data?.campaignImages?.map((image, index) => {
                                return <div key={index}>
                                  {
                                    // Checks if the extention type matches with any of videos
                                    (extensions?.video?.type.includes(image?.contentType) || extensions?.video?.preview?.some(item => item.trim() === image?.contentType)) ? (
                                      <div className='is-flex mt-2 w-100' style={{ position: 'relative' }}>
                                        <video controls className='mt-2 w-100'
                                          style={{
                                            width: '100%',
                                            // width: isSmallScreen ? '100%' : '50%',
                                            height: '125pm',
                                            objectFit: 'contain',
                                            marginBottom: '8px',
                                            borderRadius: '12px',
                                          }}
                                        >
                                          <source src={image?.publicUrl} type="video/mp4" />
                                          Your browser does not support the video tag.
                                        </video>
                                      </div>
                                    )
                                      :
                                      // Checks if the extention type matches with any of audios
                                      (extensions?.audio?.type.includes(image?.contentType) || extensions?.audio?.preview?.some(item => item.trim() === image?.contentType)) ? (
                                        <div className='is-flex mt-2 w-100'>
                                          <audio controls className='w-100'
                                            style={{
                                              width: '100%',
                                              // width: isSmallScreen ? '100%' : '50%',
                                              height: '75px',
                                              objectFit: 'contain',
                                              marginBottom: '8px',
                                              borderRadius: '12px',
                                            }}
                                          >
                                            <source src={image?.publicUrl} type="audio/mp3" />
                                            Your browser does not support the audio tag.
                                          </audio>
                                        </div>
                                      )
                                        :
                                        (extensions?.image?.type.includes(image?.contentType) || extensions?.image?.preview?.some(item => item.trim() === image?.contentType))
                                          ?
                                          <StyledImage src={image?.publicUrl} className='' alt='MMS' />
                                          :
                                          "File format not supported"
                                  }
                                </div>
                              })
                            }
                          </div>
                        </div>
                      </>
                    ) : data?.businessCoupon && (
                      <>
                        <Title size='6' className='mt-2 mb-0'>
                          Selected Coupon
                        </Title>
                        <div className='columns  mt-1 mb-0'>
                          <div class="column is-two-thirds py-0 has-text-justified">
                            <small className='tag is-light has-text-black'>
                              {String(data?.businessCoupon.discountType).toLowerCase() === 'value' && '$'}
                              {data?.businessCoupon.discount + ' '}
                              {String(data?.businessCoupon.discountType).toLowerCase() === 'percentage' && '% '}
                              Off, Expires {moment(data?.businessCoupon.endDate).format('MM-DD-YYYY')}
                            </small>
                          </div>
                          <div>
                            <StyledImage src={data?.businessCoupon?.imageUrl || data?.businessCoupon?.couponUrl} className='' alt='Business Coupon Image' />
                          </div>
                        </div>

                      </>
                    )
                    }
                    <div className='is-flex is-justify-content-space-between is-align-items-center'>
                      <div className='is-flex'>
                        <div className='mr-2'>
                          <Title size='6' className='mb-0 mt-2'>
                            {data?.businessAttachment ? "Cost" : "Budget"}
                          </Title>
                          <small className={`tag is-light has-text-black`}>{`$ ${parseFloat(data?.budget).toFixed(2)}`}</small>
                        </div>
                        <div className='ml-2'>
                          <Title size='6' className='mb-0 mt-2'>
                            Charge Amount
                          </Title>
                          <small className={`tag is-light has-text-black`}>{`$ ${parseFloat(data?.chargeAmount).toFixed(2)}`}</small>
                        </div>
                      </div>
                      <div>
                        {data?.businessAttachment !== null && data?.businessAttachment !== undefined ? (
                          <>
                            <Title size='6' className='mb-0 mt-2'>
                              Custom File
                            </Title>
                            <small>{data?.businessAttachment?.fileName}</small>
                          </>
                        ) : (
                          <>
                            <Title size='6' className='mb-0 mt-2'>
                              ZipCodes
                            </Title>
                            <div className='field is-grouped is-grouped-multiline mt-2'>
                              {data?.zipCodes?.map((zipCode, index) => {
                                return (
                                  <div className='control has-text-grey' key={index}>
                                    <div className='tags has-addons'>
                                      <span className='tag '>{zipCode}</span>
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div className='mb-1 is-flex is-justify-content-space-between is-align-items-center'>
                      <div>
                        <Title size='6' className='mb-0 mt-2 is-left'>
                          Start Date & Time
                        </Title>
                        <small className={`tag is-light has-text-black`}>{moment(startDateTime).format('MMMM Do YYYY, h:mm:ss a')}</small>
                      </div>
                      <div>
                        <Title size='6' className='mb-0 mt-2'>
                          End Date & Time
                        </Title>
                        <small className={`tag is-light has-text-black`}>{moment(endDateTime).format('MMMM Do YYYY, h:mm:ss a')}</small>
                      </div>
                    </div>
                    <div className='mb-1 is-flex is-justify-content-space-between is-align-items-center'>
                      <div>
                        <Title size='6' className='mb-0 mt-2'>
                          Total Target Audience
                        </Title>
                        <small className={`tag is-light has-text-black`}>{data?.totalTargetAudience}</small>
                      </div>
                    </div>
                    {data?.campaignDetails?.length > 0 &&
                      <div className='is-flex is-flex-direction-column'>
                        <Title size='6' className='mb-4 mt-2'>
                          Summary
                        </Title>

                        <Table className='mb-4'>
                          {data?.campaignDetails && <SummaryTable campaignDetails={data?.campaignDetails} refetchSingleCampaign={refetchSingleCampaign} campaignId={campaignId} />}
                        </Table>
                      </div>
                    }
                  </Modal.Card.Body>
                </Spin>
              </Overflow.Content>
              <Indicator className='has-text-right'><Overflow.Indicator direction="down">⏬</Overflow.Indicator></Indicator>
            </Overflow>
          </Modal.Card>
          :
          // Delete Modal Confirmation
          <Modal.Card className='modal-compaign'>
            <Overflow style={{ overflow: "scroll" }}>
              <Overflow.Content className='is-flex is-flex-direction-column'>
                <Modal.Card.Body style={{ overflow: "hidden" }} className='modal-compaign-body'>
                  <div className='mb-4 is-flex is-justify-content-space-between is-align-items-center'>
                    <Title size='5' className='m-0'>
                      Delete Campaign: <span className='has-text-grey'> {deletedData?.title} </span>
                    </Title>
                    <StyledList style={{ marginLeft: "auto", marginRight: "10px" }}>
                      <span
                        className={`tag is-light has-text-black`}
                        style={{
                          background: `rgba(${getStatusColor(deletedData?.status)}, 0.1)`,
                          color: `rgb(${getStatusColor(deletedData?.status)})`,
                        }}
                      >
                        {getStatusName(deletedData?.status)}
                      </span>
                    </StyledList>
                    <div>
                      <Icon
                        name='times'
                        className='is-hoverable has-text-link'
                        onClick={onCloseModal}
                      />
                    </div>
                  </div>
                  <p className='mb-4 is-size-6'>
                    {deletedData?.status === "InComplete" && "Are you sure you want to delete this saved campaign?"}
                    {deletedData?.status !== "InComplete" && deletedData?.status !== "active" && " This campaign info is going to be archieved and will no longer will be shown in the list of campaign. Please confirm?"}
                  </p>
                  <div class="buttons is-flex is-justify-content-end mb-4">
                    <button class="button is-secondary" onClick={onCloseModal}>Cancel</button>
                    <Spin spinning={deleteLoading}>
                      <button class="button is-danger" onClick={handleDeleteCampaign}>Confirm</button>
                    </Spin>
                  </div>
                </Modal.Card.Body>
              </Overflow.Content>
            </Overflow>
          </Modal.Card>
      }
      <ReactTooltip />
    </Modal>
  );
};

const Indicator = styled.div`
position: absolute;
right: 100px;
bottom: 0px;
font-size: 29px;
`


const StyledList = styled.td.attrs(() => ({
  className: 'py-3 px-2 has-text-centered th-compaign-details',
}))`
  vertical-align: middle !important;
}import { Loader } from '@safelyq/base-feature';

`;


const StyledImage = styled.img`
  width: 100%;
  height: 180px;
  object-fit: contain;
`;