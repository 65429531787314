import React from 'react'
import styled from 'styled-components';
import { Button, Title, } from '@safelyq/bulma-ui-library';
import { useSelector } from 'react-redux';
import { FaEye, FaPencilAlt, FaTrashAlt, FaWindowClose, FaKey } from "react-icons/fa";
import { useMutation } from '@apollo/client';
import { DELETE_USER } from './requests';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';


const StyledCard = styled.div.attrs((props) => ({
    style: {
        minWidth: '156px',
        borderRadius: '3px',
        boxShadow: 'rgb(9 30 66 / 25%) 0px 1px 1px, rgb(9 30 66 / 13%) 0px 0px 1px 1px',
        backgroundImage: 'linear-gradient(rgb(244, 245, 247) 33%, transparent 33%)',
        cursor: props.isSpecialUser ? 'not-allowed' : 'pointer',
        pointerEvents: props.isSpecialUser ? 'none' : 'auto',
        background: props.isSpecialUser ? '#e7e4e4' : 'white',
    },
}))`
  
`;
const AllMembers = ({ allMembers, onMemberClick, refetch }) => {
    const { userinfo } = useSelector((state) => state.auth);
    const [deleteBusinessUserRoles] = useMutation(DELETE_USER)
    const { id: businessId } = useParams() || {};

    const onDeleteUser = async (e, user) => {
        e.stopPropagation()
        if (!window.confirm('Are you sure you want to delete the record?')) return;
        try {
            const response = await deleteBusinessUserRoles({
                variables: {
                    businessUserPermissionsInput: {
                        businessId: businessId,

                        businessUser: {
                            email: user.name,
                        },
                    },
                },
            })
            if (response.data.deleteBusinessUserRoles.isSaved) {
                toast.success("User deleted successfully")
                await refetch()
            } else {
                toast.error(response.data.deleteBusinessUserRoles.errorMessage)
            }
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div className='row'>
            {allMembers.map(({ user, roleName, serviceProviders }) => {
                const isSpecialUser = user?.name === userinfo?.email;
                return (
                    <div className='col-lg-3 mb-4'>
                        <StyledCard isSpecialUser={isSpecialUser} onClick={() => onMemberClick(user, roleName, serviceProviders)}   >
                            <div className='card-content'>
                                <div className='is-flex is-justify-content-space-between is-align-items-center'>
                                    <Button color='info' size='small' className='icon-button rounded mb-3'>{String(user?.name).charAt(0).toUpperCase()} </Button>
                                </div>
                                <Title size='6' className='mb-1'>{user?.name}</Title>
                                <span className='tag is-info is-light'>{roleName}</span>
                                <div className='has-text-right mt-2'>
                                    <FaTrashAlt style={{ cursor: "pointer" }} onClick={(e) => onDeleteUser(e, user)} />
                                </div>
                            </div>
                        </StyledCard>
                    </div>
                )
            })}
        </div>
    )
}

export default AllMembers
