import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { SAVE_BUSINESS_COUPON } from '../../requests';
import { useBusinessDispatch } from '@safelyq/admin-promotions-feature';

export const useData = (onCloseModal, values, selectedCouponId, refetch) => {
  const dispatch = useBusinessDispatch();

  const [onSaveBusinessCoupon, { loading: isLoading, error }] = useMutation(
    SAVE_BUSINESS_COUPON,
    {
      onCompleted: ({ saveBusinessCoupon: { isSaved, errorMessage } }) => {
        if (!isSaved) toast.error(errorMessage || 'Something went wrong');
        else {
          refetch();
          if (!selectedCouponId)
            dispatch({ type: 'ADD_NEW_COUPON', payload: { ...values } });
          else
            dispatch({
              type: 'UPDATE_COUPON',
              payload: { ...values, id: selectedCouponId },
            });

          onCloseModal();

          if (selectedCouponId) toast.success('Coupon updated successfully');
          else toast.success('Coupon saved successfully');
        }
      },
      onError: () => toast.error('Something went wrong'),
    }
  );

  return { isLoading, error, onSaveBusinessCoupon };
};
