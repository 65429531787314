import React from 'react';
import { bool, func, oneOf, oneOfType } from 'prop-types';
import { isFunction, isUndefined } from 'lodash';

const className = (strings, props) => {
  const classNames = ['help']
    .concat(
      ['color']
        .map((name) => (props[name] ? `is-${props[name]}` : null))
        .filter((className) => !!className)
    )
    .join(' ');

  return classNames;
};

const Help = ({ when, children, ...props }) => {
  if (isUndefined(when) ? false : isFunction(when) ? !when() : !when) {
    return null;
  }

  return <p className={className`${props}`}>{children}</p>;
};

Help.propTypes = {
  color: oneOf([
    'black',
    'white',
    'dark',
    'light',
    'primary',
    'link',
    'info',
    'success',
    'warning',
    'danger',
  ]),
  when: oneOfType([bool, func]),
};

Help.defaultProps = {
  color: undefined,
  when: undefined,
};

export default Help;
