import { oneOf } from 'prop-types';
import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = [
    'modal-close'
  ]
    .concat(
      [
        'size'
      ]
        .map((name) => props[name] ? `is-${props[name]}` : null)
        .filter((className) => !!className)
    )
    .join(' ');

  return classNames;
};

const ModalClose = styled.div.attrs((props) => ({
  className: className`${props}`,
  'aria-label': 'close'
}))``;

ModalClose.propTypes = {
  size: oneOf(
    [
      'small',
      'medium',
      'large'
    ]
  )
};

ModalClose.defaultProps = {
  size: undefined
};

export default ModalClose;
