import { gql } from '@apollo/client';

export const GET_BUSINESS_ATTATCHMENTS = gql`
  query all($id: Int!) {
    getBusinessById(id: $id, showAll: true) {
      businessAttachments{
        id
        fileName
        createdTime
        fileRecords {
          firstName
          email
          id
          address1
          address2
          age
          city
          country
          lastName
          phoneNumber
          state
          zipCode
        }
      }
    }
  }
`;

export const UPLOAD_BUSINESS_ATTATCHMENTS = gql`
  mutation UploadBusiness($businessFileInput: BusinessFileInput!,) {
    uploadBusinessFile(businessFileInput: $businessFileInput) {
      errorMessage
      isSaved 
      hasDuplicates
    }
  }
`;

export const DELETE_BUSINESS_ATTATCHMENT = gql`
  mutation deleteBusinessFile($attachmentId: Int!,) {
    deleteBusinessFile(attachmentId: $attachmentId) {
      errorMessage
      isSaved 
    }
  }
`;