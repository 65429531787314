import React from 'react';
import { Table } from '@safelyq/bulma-ui-library';
import handleNumericCheck from 'utils/NumericCheck';


export const TableView = ({ weekDays, handleInputChange }) => {


  return (
    <div class="table-container">
      <Table fullwidth bordered>
        <thead>
          <tr>
            <th className='has-text-centered'>Day</th>
            <th className='has-text-centered'>Opening Time</th>
            <th className='has-text-centered'>Break Time / Duration</th>
            <th className='has-text-centered'>Closing Time</th>
          </tr>
        </thead>
        <tbody className='has-text-black'>
          {
            weekDays.map(({ dayName, openingTime, closingTime, hasBreak, isOpen, breakStartTime, breakDuration, hasBreakError, breakStartTimeError, breakDurationError }, index) => {
              return (
                <tr>
                  <td style={{ border: "none" }}>
                    <div style={{ height: 40 }} className={`row g-0 ${isOpen ? 'has-background-primary-light' : 'has-background-danger-light'} `}>
                      <div className='col-2 d-flex justify-content-center align-items-center'>
                        <input type='checkbox' name="isOpen" checked={isOpen} onChange={(e) => handleInputChange(e, index)} />
                      </div>
                      <div className='col-10 d-flex align-items-center'>
                        <b>{dayName}</b>
                      </div>
                    </div>
                  </td>
                  <td style={{ border: "none" }}>
                    <div>
                      <input onChange={(e) => handleInputChange(e, index)} className='input' name="openingTime" disabled={!isOpen} type='time' value={openingTime} />
                    </div>
                  </td>
                  <td style={{ border: "none" }}>
                    <div className='row g-0'>
                      <div className='col-2  d-flex justify-content-center align-items-center'>
                        <input onChange={(e) => handleInputChange(e, index)} type='checkbox' disabled={!isOpen} name="hasBreak" checked={hasBreak} />
                      </div>
                      <div className='col-5'>
                        <input onChange={(e) => handleInputChange(e, index)} className='input' name="breakStartTime" type='time' disabled={!hasBreak} value={breakStartTime} />
                        {breakStartTimeError && <span style={{ color: 'red' }}>{breakStartTimeError}</span>}
                      </div>
                      <div className='col-1  d-flex justify-content-center align-items-center'>
                        <div>/</div>
                      </div>
                      <div className='col-2'>
                        <input onChange={(e) => handleInputChange(e, index)} className='input' name="breakDuration" type='text' onKeyPress={(event) => handleNumericCheck(event)} disabled={hasBreak ? breakStartTime ? breakStartTimeError ? true : false : true : true} value={breakDuration} />
                      </div>
                      <div className='col-2  d-flex justify-content-center align-items-center'>
                        <span>min</span>
                      </div>
                      <div className='col-12 has-text-centered'>
                        {hasBreakError && <span style={{ color: 'red' }}>{hasBreakError}</span>}
                        {breakDurationError && <span style={{ color: 'red' }}>{breakDurationError}</span>}
                      </div>

                    </div>
                  </td>
                  <td style={{ border: "none" }}>
                    <input onChange={(e) => handleInputChange(e, index, hasBreak)} className='input' name="closingTime" type='time' disabled={!isOpen} value={closingTime} />
                  </td>

                </tr>
              )
            })
          }

        </tbody>
      </Table>

    </div>
  );
};
