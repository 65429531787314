export const dummySlots = [
  {
    dayOfWeek: 'Monday',
    dayNumber: 1,
    isOpen: false,
    hasBreak: false,
    openingTime: '0001-01-01T00:00:00',
    closingTime: '0001-01-01T00:00:00',
    breakStartTime: '0001-01-01T00:00:00',
    breakDuration: 0,
    isBreakTimeNotBetween: false,
    isWrongDuration: false,
  },
  {
    dayOfWeek: 'Tuesday',
    dayNumber: 2,
    isOpen: false,
    hasBreak: false,
    openingTime: '0001-01-01T00:00:00',
    closingTime: '0001-01-01T00:00:00',
    breakStartTime: '0001-01-01T00:00:00',
    breakDuration: 0,
    isBreakTimeNotBetween: false,
    isWrongDuration: false,
  },
  {
    dayOfWeek: 'Wednesday',
    dayNumber: 3,
    isOpen: false,
    hasBreak: false,
    openingTime: '0001-01-01T00:00:00',
    closingTime: '0001-01-01T00:00:00',
    breakStartTime: '0001-01-01T00:00:00',
    breakDuration: 0,
    isBreakTimeNotBetween: false,
    isWrongDuration: false,
  },
  {
    dayOfWeek: 'Thursday',
    dayNumber: 4,
    isOpen: false,
    hasBreak: false,
    openingTime: '0001-01-01T00:00:00',
    closingTime: '0001-01-01T00:00:00',
    breakStartTime: '0001-01-01T00:00:00',
    breakDuration: 0,
    isBreakTimeNotBetween: false,
    isWrongDuration: false,
  },
  {
    dayOfWeek: 'Friday',
    dayNumber: 5,
    isOpen: false,
    hasBreak: false,
    openingTime: '0001-01-01T00:00:00',
    closingTime: '0001-01-01T00:00:00',
    breakStartTime: '0001-01-01T00:00:00',
    breakDuration: 0,
    isBreakTimeNotBetween: false,
    isWrongDuration: false,
  },
  {
    dayOfWeek: 'Saturday',
    dayNumber: 6,
    isOpen: false,
    hasBreak: false,
    openingTime: '0001-01-01T00:00:00',
    closingTime: '0001-01-01T00:00:00',
    breakStartTime: '0001-01-01T00:00:00',
    breakDuration: 0,
    isBreakTimeNotBetween: false,
    isWrongDuration: false,
  },
  {
    dayOfWeek: 'Sunday',
    dayNumber: 0,
    isOpen: false,
    hasBreak: false,
    openingTime: '0001-01-01T00:00:00',
    closingTime: '0001-01-01T00:00:00',
    breakStartTime: '0001-01-01T00:00:00',
    breakDuration: 0,
    isBreakTimeNotBetween: false,
    isWrongDuration: false,
  },
];


export const newDummySlots = [
  {
    "id": 0,
    "dayOfYear": null,
    "closingTime": "0001-01-01T00:00:00",
    "dayOfWeek": 0,
    "date": null,
    "openingTime": "0001-01-01T00:00:00"
  },
  {
    "id": 0,
    "dayOfYear": null,
    "closingTime": "0001-01-01T00:00:00",
    "dayOfWeek": 0,
    "date": null,
    "openingTime": "0001-01-01T00:00:00"
  },
  {
    "id": 0,
    "dayOfYear": null,
    "closingTime": "0001-01-01T00:00:00",
    "dayOfWeek": 1,
    "date": null,
    "openingTime": "0001-01-01T00:00:00"
  },
  {
    "id": 0,
    "dayOfYear": null,
    "closingTime": "0001-01-01T00:00:00",
    "dayOfWeek": 1,
    "date": null,
    "openingTime": "0001-01-01T00:00:00"
  },
  {
    "id": 0,
    "dayOfYear": null,
    "closingTime": "0001-01-01T00:00:00",
    "dayOfWeek": 2,
    "date": null,
    "openingTime": "0001-01-01T00:00:00"
  },
  {
    "id": 0,
    "dayOfYear": null,
    "closingTime": "0001-01-01T00:00:00",
    "dayOfWeek": 2,
    "date": null,
    "openingTime": "0001-01-01T00:00:00"
  },
  {
    "id": 0,
    "dayOfYear": null,
    "closingTime": "0001-01-01T00:00:00",
    "dayOfWeek": 3,
    "date": null,
    "openingTime": "0001-01-01T00:00:00"
  },
  {
    "id": 0,
    "dayOfYear": null,
    "closingTime": "0001-01-01T00:00:00",
    "dayOfWeek": 3,
    "date": null,
    "openingTime": "0001-01-01T00:00:00"
  },
  {
    "id": 0,
    "dayOfYear": null,
    "closingTime": "0001-01-01T00:00:00",
    "dayOfWeek": 4,
    "date": null,
    "openingTime": "0001-01-01T00:00:00"
  },
  {
    "id": 0,
    "dayOfYear": null,
    "closingTime": "0001-01-01T00:00:00",
    "dayOfWeek": 4,
    "date": null,
    "openingTime": "0001-01-01T00:00:00"
  },
  {
    "id": 0,
    "dayOfYear": null,
    "closingTime": "0001-01-01T00:00:00",
    "dayOfWeek": 5,
    "date": null,
    "openingTime": "0001-01-01T03:00:00"
  },
  {
    "id": 0,
    "dayOfYear": null,
    "closingTime": "0001-01-01T00:00:00",
    "dayOfWeek": 5,
    "date": null,
    "openingTime": "0001-01-01T00:00:00"
  },
  {
    "id": 0,
    "dayOfYear": null,
    "closingTime": "0001-01-01T00:00:00",
    "dayOfWeek": 6,
    "date": null,
    "openingTime": "0001-01-01T00:00:00"
  },
  {
    "id": 0,
    "dayOfYear": null,
    "closingTime": "0001-01-01T00:00:00",
    "dayOfWeek": 6,
    "date": null,
    "openingTime": "0001-01-01T00:00:00"
  }
]