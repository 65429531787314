import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import ListItem from './styles/ListItem';

const OrderedList = styled.ol.attrs(({ className }) => ({
  className,
}))``;

const UnorderedList = styled.ul.attrs(({ className }) => ({
  className,
}))``;

const List = ({ type, bullets, children, ...props }) => {
  switch (type) {
    case 'ordered':
      return (
        <OrderedList type={bullets} {...props}>
          {children}
        </OrderedList>
      );
    case 'unordered':
      return <UnorderedList {...props}>{children}</UnorderedList>;
    default:
      throw new Error(`list type ${type} is not supported`);
  }
};

List.Item = ListItem;

List.propTypes = {
  type: string,
  bullets: string,
};

List.defaultProps = {
  type: undefined,
  bullets: undefined,
};

export default List;
