import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = [
    'level-item'
  ]
    .join(' ');

  return classNames;
};

const LevelItem = styled.div.attrs((props) => ({
  className: className`${props}`
}))``;

LevelItem.propTypes = {
};

LevelItem.defaultProps = {
};

export default LevelItem;
