import { gql } from "@apollo/client";

export const GET_APP_USERS = gql`
  query MyQuery($selectMarketingUsersInput: SelectMarketingUsersInput!) {
    getUsers(selectMarketingUsersInput: $selectMarketingUsersInput) {
      totalCount
      users {
        id
        firstName
        lastName
        fullName
        phoneNumber
        email
        createdTime
    }
    }
  }
`;

export const GET_CHATSLB_CONVERSATIONS = gql`
 query GetChatSLBConversations($skip: Int, $take: Int, $userId: String!) {
  getChatSLBConversations(paginationInputModel: {skip: $skip, take: $take}, userId: $userId) {
	 chatSLBConversations {
        createdTime
        id
        isLastActive
        modifiedTime
        title
      }
      totalCount
	}
}
`

export const GET_CONVERSATION_MESSAGES = gql`
query GetConversationMessages($skip: Int!, $take: Int!, $conversationId: Int!) {
  getConversationMessages(skip: $skip, take: $take,conversationId: $conversationId) {
  totalCount
  conversationMessages {
  id
   promptVersioning {
        promptText
      }
		userStatement
		geminiResponse
    geminiJsonResponse
		businesses{
		      id
          name
          categoryTags
          safetyTags
          address1
		      isFavorite
          pictures {
            id
            path
          }
          picture {
            id
            path
          }
          businessCategory {
            name
            description
          }
		}
  }
		
	}
}
`
