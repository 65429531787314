import React from 'react'
import { ClipLoader } from 'react-spinners';
import { BiConversation } from "react-icons/bi";
import { parseMarkdown } from 'constants';
import { getAvatarName } from 'utils/getAvatarName';
import { useSelector } from 'react-redux';
import { GiEmptyHourglass } from "react-icons/gi";
import Businesses from './Businesses';
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";

const MainChatSection = ({ mainChatProps }) => {
    const {
        setRefs,
        isLoadingConversationMsgs,
        messages,
        messagesEndRef,
        selectedMessageId,
        setSelectedMessageId,
        selectedMessageIdForDesktop,
        setSelectedMessageIdForDesktop
    } = mainChatProps;

    const { userinfo } = useSelector((state) => state.auth);

    return (

        <div className="messages-custom">
            {/* Loading Indicator or No Conversation */}
            {!isLoadingConversationMsgs && messages?.length === 0 ? (
                <div className="is-flex mt-3">
                    <BiConversation fontSize={22} className="mr-2" />
                    <p className="has-text-semobild is-size-6">
                        No Conversations exist for this user
                    </p>
                </div>
            ) : (
                isLoadingConversationMsgs && (
                    <div
                        className="mt-3 p-1 px-5 is-flex mx-auto"
                        style={{
                            border: "1px solid gray",
                            borderRadius: "12px",
                        }}
                    >
                        <div
                            style={{ height: "100%" }}
                            className="is-flex is-justify-content-center is-align-items-center"
                        >
                            <ClipLoader size={20} color="gray" className="" />
                        </div>
                        <p className="ml-2"> Loading...</p>
                    </div>
                )
            )}

            <div className="messages-container" ref={setRefs}>
                {
                    // design is column-reversed to show the bottom up instead of the reversing the whole array
                    // messages?.slice().reverse().map((message, index) => {
                    messages?.map((message, index) => {
                        let htmlContent, businesses;
                        if (message?.text) {
                            let text = message?.text;
                            // if (message?.sender === "user") {
                            //     text = text.replace(/\n/g, '');  // Removes any literal backslashes followed by 'n'
                            // }
                            htmlContent = parseMarkdown(text)

                        }
                        if (message?.businesses?.length !== 0) {
                            businesses = message.businesses;
                        }

                        return (

                            <>
                                <div
                                    className=''
                                    key={`${message.id}-${index}`}
                                    ref={index === messages.length - 1 ? messagesEndRef : null}
                                    style={{

                                        width: "100%",
                                        marginLeft: message.sender === "ai" || message.sender === "json" ? "0px" : "auto",
                                        marginRight: message.sender === "user" ? "0px" : "auto"
                                    }}
                                >
                                    {
                                        // show the response if exist
                                        message?.text && (
                                            <div
                                                className={`message-content mb-2 is-flex ${message.sender === "ai" || message?.sender === "json" ? "is-justify-content-start" : "is-justify-content-end"}`}
                                                style={{ alignItems: 'center' }} // To vertically center the icon and message
                                            >
                                                <span className='is-hidden-desktop'>  {message.sender === "ai" && message?.id === selectedMessageId && <p className='slb-message-icon'>SLB</p>} {/* FS icon for AI messages */}</span>
                                                <span className='is-hidden-mobile'>        {message.sender === "ai" && <p className='slb-message-icon'>SLB</p>} {/* FS icon for AI messages */}</span>

                                                <>
                                                    {/* We have 2 designs, one for the desktop and one for the mobile that initially shows only messages and onclick show details */}

                                                    {/* Mobile - show user and ai */}
                                                    <div
                                                        onClick={() => setSelectedMessageId(selectedMessageId === message?.id ? null : message?.id)}
                                                        className={`is-hidden-desktop message-custom  
                                                           ${
                                                            //if the selected message id is same as mssage id than allow both ai and user to show else only allow user to show
                                                            (selectedMessageId === message?.id && (message?.sender === "user" || message?.sender === "ai")) ||
                                                                message?.sender === "user"
                                                                ? "is-block"
                                                                : "is-hidden"
                                                            }`}
                                                        style={{
                                                            margin: '0 0px',
                                                            borderRadius: "20px",
                                                            backgroundColor: message?.sender === "user" ? "#f8f9ff" : "white",
                                                            width: "max-content",
                                                            color: "black"
                                                        }}
                                                    >
                                                        <div
                                                            dangerouslySetInnerHTML={{ __html: htmlContent }}
                                                        />
                                                    </div>

                                                    {/* Mobile - show Prompt */}
                                                    <div
                                                        className={`is-hidden-desktop message-custom
                                                            ${(message?.sender === "prompt" && selectedMessageId === message?.id)
                                                                ? "is-block" : "is-hidden"
                                                            }`}
                                                        style={{
                                                            margin: '0 0px',
                                                            borderRadius: "20px",
                                                            backgroundColor: "#f5f5f5",
                                                            width: "max-content",
                                                            color: "black"
                                                        }}
                                                    >

                                                        <p className='has-text-weight-bold is-underlined my-1'>ChatSLB Prompt</p>
                                                        <div
                                                            dangerouslySetInnerHTML={{ __html: htmlContent }}
                                                        />
                                                    </div>


                                                    {/* Desktop - show user and ai */}
                                                    <div
                                                        className={`is-hidden-mobile message-custom
                                                            ${(message?.sender === "user" || message?.sender === "ai")
                                                                ? "is-block" : "is-hidden"
                                                            }`}
                                                        style={{
                                                            margin: '0 0px',
                                                            borderRadius: "20px",
                                                            backgroundColor: message?.sender === "user" ? "#F8F9FF" : "white",
                                                            width: "max-content",
                                                            color: "black"
                                                        }}
                                                    >

                                                        <div
                                                            dangerouslySetInnerHTML={{ __html: htmlContent }}
                                                        />

                                                        {
                                                            // show text for detailed response only beneath slb response
                                                            // json, prompt and businesses present than show detailed response
                                                            message?.sender === "ai" && (message?.businesses?.length !== 0 || messages[index - 1]?.text || messages[index - 2]?.text) &&
                                                            <p
                                                                onClick={() => setSelectedMessageIdForDesktop(selectedMessageIdForDesktop === message?.id ? null : message?.id)}
                                                                className='is-flex is-clickable mt-2'>
                                                                <span
                                                                    className='is-underlined is-size-6 has-text-weight-semibold'>
                                                                    {selectedMessageIdForDesktop === message?.id ? "Show Less Response" : "Show Detailed Response"}
                                                                </span>
                                                                {selectedMessageIdForDesktop === message?.id ? <RiArrowDropUpLine fontSize={20} /> : <RiArrowDropDownLine fontSize={20} />}
                                                            </p>
                                                        }

                                                    </div>

                                                    {/* Desktop - show Prompt */}
                                                    <div
                                                        className={`is-hidden-mobile message-custom
                                                            ${(message?.sender === "prompt" && selectedMessageIdForDesktop === message?.id)
                                                                ? "is-block" : "is-hidden"
                                                            }`}
                                                        style={{
                                                            margin: '0 0px',
                                                            borderRadius: "20px",
                                                            backgroundColor: "#f5f5f5",
                                                            width: "max-content",
                                                            color: "black"
                                                        }}
                                                    >

                                                        <p className='has-text-weight-bold is-underlined my-1'>ChatSLB Prompt</p>
                                                        <div
                                                            dangerouslySetInnerHTML={{ __html: htmlContent }}
                                                        />
                                                    </div>
                                                </>

                                                {/* User Icon beside the text */}
                                                <span className='is-hidden-desktop'>  {message.sender === "user" && <p
                                                    onClick={() => setSelectedMessageId(selectedMessageId === message?.id ? null : message?.id)}
                                                    className='user-message-icon ml-2'> {getAvatarName(userinfo)}</p>} {/* FS icon for User messages */}
                                                </span>

                                                <span className='is-hidden-mobile'>  {message.sender === "user" && <p className='user-message-icon ml-2'> {getAvatarName(userinfo)}</p>} {/* FS icon for User messages */} </span>
                                            </div>
                                        )}

                                    {
                                        // Show business if exist
                                        (businesses?.length === 0 && messages?.length > 1)
                                            // && !loading 
                                            ? (
                                                <div className='is-flex is-justify-content-center my-1' style={{ width: "100%" }}>
                                                    <GiEmptyHourglass color='gray' fontSize={26} />
                                                    <p className='ml-2 is-size-5 has-text-weight-semibold has-text-grey-dark'>No Suggestions Found</p>
                                                </div>
                                            ) : <>
                                                <span className='is-hidden-desktop'>
                                                    {
                                                        businesses && message?.id === selectedMessageId && (
                                                            <Businesses businesses={businesses} />
                                                        )
                                                    }
                                                </span>
                                                <span className='is-hidden-mobile'>
                                                    {
                                                        businesses && message?.id === selectedMessageIdForDesktop && (
                                                            <Businesses businesses={businesses} />
                                                        )
                                                    }
                                                </span>
                                            </>
                                    }


                                    {
                                        message?.text && <>
                                            {/* desktop = show json */}
                                            <div
                                                className={"is-hidden-mobile message-content mb-2 is-flex is-justify-content-start"}
                                                style={{ alignItems: 'center' }} // To vertically center the icon and message
                                            >
                                                <div
                                                    className={`message-custom ${(message?.sender === "json" && selectedMessageIdForDesktop === message?.id)
                                                        ? "is-block" : "is-hidden"
                                                        }`}
                                                    style={{
                                                        margin: '0 0px',
                                                        borderRadius: "20px",
                                                        backgroundColor: "#272822",
                                                        width: "max-content",
                                                        color: "white"
                                                    }}
                                                >
                                                    <p className='has-text-weight-bold is-underlined my-1'>ChatSLB Json Response</p>
                                                    <div
                                                        dangerouslySetInnerHTML={{ __html: htmlContent }}
                                                    />
                                                </div>
                                            </div>

                                            {/* mobile = show json */}
                                            <div
                                                className={"is-hidden-desktop message-content mb-2 is-flex is-justify-content-start"}
                                                style={{ alignItems: 'center' }} // To vertically center the icon and message
                                            >
                                                <div
                                                    className={`message-custom ${(message?.sender === "json" && selectedMessageId === message?.id)
                                                        ? "is-block" : "is-hidden"
                                                        }`}
                                                    style={{
                                                        margin: '0 0px',
                                                        borderRadius: "20px",
                                                        backgroundColor: "#272822",
                                                        width: "max-content",
                                                        color: "white"
                                                    }}
                                                >

                                                    <p className='has-text-weight-bold is-underlined my-1'>ChatSLB Json Response</p>
                                                    <div
                                                        dangerouslySetInnerHTML={{ __html: htmlContent }}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </>
                        )
                    })}
            </div>
        </div>
    )
}

export default MainChatSection