import React, { Fragment, useState, useEffect } from 'react';
import {
  Columns,
  Column,
  Control,
  Field,
  Label,
  Button,
} from '@safelyq/bulma-ui-library';
import { OFFERED_SERVICES_MESSAGES } from '../../common/constants';

export const OfferedServices = ({
  tab,
  data: business,
  serviceProviderId,
  onSubmit,
  isLoading,
}) => {
  const [businessServices, setBusinessServices] = useState([]);

  const { services } = business || [];

  useEffect(() => {
    const srvs = [];
    services.map(({ id, businesses }) => {
      if (businesses.find(({ id }) => id == serviceProviderId)) {
        srvs.push({ businessId: serviceProviderId, serviceId: id });
      }
    });
    setBusinessServices([...srvs]);
  }, [services]);

  const handleServiceToggle = (checked, serviceId) => {
    if (checked) {
      setBusinessServices([
        ...businessServices,
        { businessId: serviceProviderId, serviceId },
      ]);
    } else {
      setBusinessServices(
        businessServices.filter(({ serviceId: id }) => id != serviceId)
      );
    }
  };

  const handleSave = () => {
    if (tab == 'SERVICE_PROVIDER') {
      onSubmit(
        { businessServices: { businessServices } },
        5,
        serviceProviderId,
        {
          success: OFFERED_SERVICES_MESSAGES.Success,
          error: OFFERED_SERVICES_MESSAGES.Error,
        }
      );
    } else {
      onSubmit(
        { businessServices: { businessServices } },
        5,

        {
          success: OFFERED_SERVICES_MESSAGES.Success,
          error: OFFERED_SERVICES_MESSAGES.Error,
        }
      );
    }
  };

  return (
    <Columns multiline>
      {services?.map(({ id, name, businesses }) => (
        <Fragment>
          <Column size='4'>
            <Field>
              <Control>
                <Label>Service Name</Label>
                <input
                  name='name'
                  size='small'
                  value={name}
                  disabled={true}
                  className='input'
                />
              </Control>
            </Field>
          </Column>
          <Column size='2'>
            <Field>
              <Control>
                <Label className='is-hidden-mobile'>&nbsp;</Label>
                <Label className='checkbox'>
                  <input
                    type='checkbox'
                    defaultChecked={businesses?.find(
                      ({ id }) => id == serviceProviderId
                    )}
                    onChange={({ target: { checked } }) =>
                      handleServiceToggle(checked, id)
                    }
                  />
                  <strong className='ml-2'>Is Active ?</strong>
                </Label>
              </Control>
            </Field>
          </Column>
        </Fragment>
      ))}
      <Column size='12'>
        <Button
          color='info'
          disabled={isLoading}
          loading={isLoading}
          onClick={handleSave}
        >
          Save & Next
        </Button>
      </Column>
    </Columns>
  );
};
