import React from 'react';
import styled from 'styled-components';
import { Box, Columns, Column, Text, Icon } from '@safelyq/bulma-ui-library';

const Header = styled(Box).attrs(() => ({
  className: 'px-5 py-2 has-text-white',
}))`
  background: #2f6d9c;
  border-radius: 0%;
`;

const TimeBadge = styled.div.attrs(() => ({}))`
  background: #3c3c3c;
  padding: 2px 11px;
  border-radius: 4px;
  color: white;
`;

export const DesktopHeader = () => (
  <Header>
    <Columns multiline>
      <Column size={2} className='mb-0 pb-0'>
        <Text>
          <TimeBadge>
            <Text>
              <Icon name='clock-o' />
              &nbsp;
              <span>Time Slot</span>
            </Text>
          </TimeBadge>
        </Text>
      </Column>
      <Column size={2} className='mb-0 pb-0' style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Text style={{
          wordWrap: 'break-word',
          wordBreak: 'break-all',
        }}>
          <span>
            <b>
              <i>Total Appointments</i>
            </b>
          </span>
        </Text>
      </Column>
      <Column size={2} className='mb-0 pb-0'>
        <Text>
          <span>
            <b>
              <i>Total Guests</i>
            </b>
          </span>
        </Text>
      </Column>
      <Column size={2} className='mb-0 pb-0'>
        <Text>
          <span>
            <b>
              <i>Total Checked In</i>
            </b>
          </span>
        </Text>
      </Column>
      <Column size={2} className='mb-0 pb-0'></Column>
      <Column size={2} className='mb-0 pb-0'></Column>
      <Column size={2} className='pt-1'>
        <Text>
          <span>User</span>
        </Text>
      </Column>
      <Column size={2} className='pt-1'>
        <Text>
          <span>Service</span>
        </Text>
      </Column>
      <Column size={2} className='pt-1'>
        <Text>
          <span>Service Provider</span>
        </Text>
      </Column>
      <Column size={2} className='pt-1'>
        <Text>
          <span>Entrance</span>
        </Text>
      </Column>
      <Column size={2} className='pt-1'>
        <Text>
          <span>Guests</span>
          &nbsp;
          <Icon name='users' />
        </Text>
      </Column>
      <Column size={2} className='pt-1'>
        <Text>
          <span>Status</span>
        </Text>
      </Column>
    </Columns>
  </Header>
);

export const MobileHeader = () => (
  <Header>
    <Columns gap='0' breakpoint='mobile' multiline>
      <Column size={4} className='mb-1'>
        <Text>
          <span>User</span>
        </Text>
      </Column>
      <Column size={4} className='mb-1'>
        <Text>
          <span>Guests</span>
          &nbsp;
          <Icon name='users' />
        </Text>
      </Column>
      <Column size={4}>
        <Text>
          <span>Status</span>
        </Text>
      </Column>
      <Column size={4}>
        <Text>
          <span>Entrance</span>
        </Text>
      </Column>
      <Column size={4}>
        <Text>
          <span>Service</span>
        </Text>
      </Column>
      <Column size={4}>
        <Text>
          <span>Service Provider</span>
        </Text>
      </Column>
    </Columns>
  </Header>
);
