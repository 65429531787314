import React from 'react';
import { MenuList, TabList } from './components';

export const NavigationSection = () => {
  return (
    <>
      <MenuList />
      <TabList />
    </>
  );
};
