import React from 'react';
import moment from 'moment';
import firestore from '@firebase';
import { useState, useRef, useEffect } from 'react';
import { COLLECTION } from 'constants/index';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

export const SummaryStatus = ({ targetedDateTime, platformWiseStatus, actualSent, refetchSingleCampaign, campaignId, campaignTargetTimeId }) => {

    const [currentTime, setCurrentTime] = useState(moment().format())
    const [campaignFirebase, setCampaignFirebase] = useState(null);
    const [statusLoading, setStatusLoading] = useState(true);
    const [callRefetch, setCallRefetch] = useState(false);
    const { businessId } = useParams();
    const initialRender = useRef(true);

    // Update the current time state every minute
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(moment());
        }, 5000); // 5 seconds

        return () => clearInterval(intervalId);
    }, []);

    // When data changes in the backend, firebase runs snapshot and we refetch data
    useEffect(() => {
        if (businessId) {
            const unsubscribe = firestore
                .collection(COLLECTION.CAMPAIGNS)
                .where('businessId', '==', `${businessId}`)
                .where('campaignId', '==', campaignId)
                .where('campaignTargetTimeId', '==', campaignTargetTimeId)
                .onSnapshot(async querySnapshot => {

                    setStatusLoading(false) // Loader for the first time
                    querySnapshot.forEach(doc => {
                        setCampaignFirebase({ id: doc.id, ...doc.data() });

                        // Does not run on the first render as we call api ourself
                        if (!initialRender.current && doc.id) {
                            setCallRefetch(true);
                        }
                        // When data is retuned via snapshot, allow the renders for refetch
                        if (doc.id) {
                            initialRender.current = false;
                        }
                    });

                });
            return () => unsubscribe();
        }

    }, [businessId])

    useEffect(() => {
        if (callRefetch) {
            if (campaignFirebase?.status === "Sent") {
                refetchSingleCampaign();
            }
            setCallRefetch(false); // do not call refetch until snapshot is called
        }
    }, [callRefetch, campaignFirebase?.status, refetchSingleCampaign]);
    return (
        <>
            {
                // if targetedDateTime plus 1 day isAfter (in the future) and firebase has status and status is Sent than show actualsent else show stasus
                //  if targetedDateTime plus 1 day not isAfter (in the past) than simply show actualsent
                statusLoading ? <ClipLoader size='20' /> :
                    moment(targetedDateTime).add(1, 'days').isAfter(moment(currentTime))
                        ?
                        campaignFirebase?.status
                            ?
                            campaignFirebase?.status === "Sent"
                                ?
                                <span>
                                    {
                                        platformWiseStatus.length === 0
                                            ?
                                            <small>{actualSent}</small>
                                            :
                                            platformWiseStatus.map(platform => <small className='is-block'>{platform.actualSent}</small>)
                                    }
                                </span>
                                :
                                campaignFirebase?.status
                            :
                            moment(targetedDateTime).isAfter(currentTime)
                                // moment(targetedDateTime).format() > currentTime
                                ? 'Not Started'
                                :
                                moment(targetedDateTime).isBefore(currentTime) && (
                                    // moment(targetedDateTime).format() < currentTime && (
                                    'Started'
                                )
                        :
                        <span>
                            {
                                platformWiseStatus.length === 0
                                    ?
                                    <small>{actualSent}</small>
                                    :
                                    platformWiseStatus.map(platform => <small className='is-block'>{platform.actualSent}</small>)
                            }
                        </span>
            }
        </>
    )
}
