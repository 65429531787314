import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { INITIAL_BUSINESS_AND_ORGANIZATION_DATA } from './constants';
import {
    GET_BUSINESS_COUPONS,
} from './requests';

export const useData = () => {
    const { id } = useParams();
    const {
        loading: isLoading,
        error,
        data = INITIAL_BUSINESS_AND_ORGANIZATION_DATA,
        refetch,
    } = useQuery(GET_BUSINESS_COUPONS, {
        variables: { id },
        fetchPolicy: 'no-cache',
    });

    const {
        getBusinessById: business,
    } = data;

    return {
        isLoading,
        error,
        data: { business },
        refetch
    };
};
