import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = [
    'modal-background'
  ]
    .join(' ');

  return classNames;
};

const ModalBackground = styled.div.attrs((props) => ({
  className: className`${props}`
}))``;

ModalBackground.propTypes = {
};

ModalBackground.defaultProps = {
};

export default ModalBackground;
