import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { toast as showToast } from 'react-toastify';
import { INITIAL_BUSINESS_AND_ORGANIZATION_DATA } from './constants';
import {
    GET_BUSINESS_AND_ORGANIZATION,
    SAVE_BUSINESS,
    UPDATE_SERVICE_PROVIDER_STATUS,
    UPDATE_BUSINESS_ONLINE_STATUS,
} from './requests';
import { useState } from 'react';

export const useData = () => {
    const { id } = useParams();
    const [statusError, setStatusError] = useState(false);
    const { loading: isLoading, error, data = INITIAL_BUSINESS_AND_ORGANIZATION_DATA, refetch, } = useQuery(GET_BUSINESS_AND_ORGANIZATION, { variables: { id }, fetchPolicy: 'no-cache', });

    const [onSaveBusiness, { loading: isSaveLoading }] = useMutation(
        SAVE_BUSINESS,
        {
            onCompleted: ({
                saveBusiness: {
                    isSaved,
                    errorMessage,
                    business: { id },
                },
            }) => {
                if (isSaved) {
                    // showToast.success('Business status changed successfully.');
                    refetch();
                }
            },
            onError: () => { },
        }
    );

    const [onUpdateServiceProviderStatus] = useMutation(
        UPDATE_SERVICE_PROVIDER_STATUS,
        {
            onCompleted: ({
                updateServiceProviderStatus: { isSaved, errorMessage },
            }) => {
                if (isSaved) {
                    // showToast.success('Business status changed successfully.');
                    refetch();
                } else showToast.error(errorMessage || 'Something went wrong.');
            },
            onError: () => showToast.error('Something went wrong.'),
        }
    );

    const [onUpdateBusinessOnlineStatus, { loading: businessStatusLoading }] = useMutation(
        UPDATE_BUSINESS_ONLINE_STATUS,
        {
            onCompleted: ({
                updateBusinessOnlineStatus: { isSuccess, errorMessage, missingValues },
            }) => {
                if (isSuccess) {
                    // showToast.success('Business status changed successfully');
                    refetch();
                } else {
                    showToast.error(
                        missingValues
                            ? missingValues?.join(',') +
                            ' ' +
                            'is required to make business online.'
                            : errorMessage || 'Something went wrong.'
                    );
                    setStatusError(true);
                }

            },
            onError: () => showToast.error('Something went wrong.'),
        }
    );

    if (error) showToast.error('There was a problem. Data could not be loaded.');
    const {
        getBusinessById: business,
        getUserOrganizations: organizations,
        getBusinessCategories: categories,
        getCountries: countries,
    } = data;
    return {
        isLoading,
        isSaveLoading,
        onSaveBusiness,
        onUpdateServiceProviderStatus,
        onUpdateBusinessOnlineStatus,
        error,
        data: { business, organizations, countries, categories },
        refetch,
        businessStatusLoading,
        statusError
    };
};
