import React, { Fragment, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Section, Container, Box, Title, Button, Modal } from '@safelyq/bulma-ui-library';
import {
    BusinessProvider,
    useBusinessDispatch,
    useBusinessState,
} from '../ManageBusiness/context';
import { GET_BUSINESS_BY_ID, GOOGLE_RESERVE_OPT, GET_GOOGLE_RESERVE } from "./requests"
import { useQuery, useMutation } from '@apollo/client';
import { Loader } from '@safelyq/base-feature';
import { toast as showToast } from 'react-toastify';
import { useSelector } from 'react-redux';

export const ManageReserveWithGoogle = () => {
    const history = useHistory();
    const [isAgreed, setIsAgreed] = useState(false);
    const [showpop, isShowPopup] = useState(false)
    const [alertshowpop, isAlertShowPopup] = useState(false)
    const [popupTitle, setPopupTitle] = useState('');
    const [popupContent, setPopupContent] = useState('');
    const [message, setMessage] = useState('');
    const { id } = useParams();

    const businessCategoryFeatures = useSelector(state => state?.business?.businessCategory?.features);
    const businessFeatures = useSelector((state) => state?.business?.businessFeatures);
    let googleReserveAllowed = businessFeatures?.googleReserveAllowed;

    // Skip the api calls if the module (i.e. google reserve allowed) is not accessible
    const { isLoading, data, refetch, error: onError } = useQuery(GET_BUSINESS_BY_ID, { variables: { id }, skip: !googleReserveAllowed, fetchPolicy: 'no-cache', });
    const businessOptInChecks = data?.getBusinessById?.businessOptIn;

    // Show popup that you are not subscribed for this module
    useEffect(() => {
        if (googleReserveAllowed !== null) {
            googleReserveAllowed === false ? isAlertShowPopup(true) : isAlertShowPopup(false);
        }
    }, [googleReserveAllowed])

    useEffect(() => {
        // If google reserve allowed is not true in the business category features than redirect
        if (!businessCategoryFeatures?.googleReserveAllowed) {
            history.push(`/admin/appointments-log/${id}`);
            // history.push(`/notfound`);
        }
    }, [businessCategoryFeatures, history])

    const { loading, data: messages, error } = useQuery(GET_GOOGLE_RESERVE);
    const [saveBusinessOpt, { loading: onLoad }] = useMutation(GOOGLE_RESERVE_OPT);

    if (error) { showToast.error(error.message); };
    if (onError) { showToast.error(onError.message); };

    useEffect(() => {
        let isMounted = true;
        if (!data?.getBusinessById?.canBusinessOptIn && !data?.getBusinessById?.isGoogleReserve) {
            if (data?.getBusinessById?.canBusinessOptIn === false && data?.getBusinessById?.isGoogleReserve === false) {
                const title = "Google Reserve";
                const content = 'Please complete the business setup to Opt-In into google reserve';
                setPopupTitle(title);
                setPopupContent(content);
                isShowPopup(true)
                refetch();
            }
            else {
                const delayedCheck = setTimeout(() => {
                    if (isMounted && data?.getBusinessById?.canBusinessOptIn === undefined && data?.getBusinessById?.isGoogleReserve === undefined) {
                        const title = "InActive Business";
                        const content = 'Please activate your business to Opt-In/Opt-Out into google reserve';
                        setPopupTitle(title);
                        setPopupContent(content);
                        isShowPopup(true);
                    }
                }, 1000);
                return () => {
                    isMounted = false;
                    clearTimeout(delayedCheck);
                };
            }
        }
        setMessage(messages?.getReserveWithGooglePage?.reserveWithGoogle?.messages)
    }, [data, messages, refetch]);


    let canBusinessOptIn = data?.getBusinessById?.canBusinessOptIn;
    let isGoogleReserve = data?.getBusinessById?.isGoogleReserve;
    // let isGoogleReserve = businessFeatures?.googleReserveAllowed;

    if (loading || onLoad || isLoading) return <Loader isFullScreen={true} />;

    const handleBusinessAdmin = () =>
        history.push(`/admin/manage-business/${id}`);

    const handlOpt = async () => {
        if (isGoogleReserve) {
            const confirmed = window.confirm('Are you sure you want to Opt-Out?');
            if (confirmed) {
                saveBusinessOpt({
                    variables: {
                        businessInput: {
                            isGoogleReserve: false,
                            id: id,
                        },
                    },
                }).then(result => {
                    if (result?.data?.saveBusinessOptIn?.isSaved) {
                        showToast.success('Business OptOut Successfully');
                    } else {
                        showToast.error(result?.data?.saveBusinessOptIn?.errorMessage || "Something went wrong");
                    }
                });
            }
            await refetch();
            setIsAgreed(false);
        }
        else {
            const confirmed = window.confirm('Are you sure you want to Opt-In?');
            if (confirmed) {
                saveBusinessOpt({
                    variables: {
                        businessInput: {
                            isGoogleReserve: true,
                            id: id,
                        },
                    },
                }).then(result => {
                    if (result?.data?.saveBusinessOptIn?.isSaved) {
                        showToast.success('Business OptIn Successfully');
                    } else {
                        showToast.error(result?.data?.saveBusinessOptIn?.errorMessage || "Something went wrong");
                    }
                });
            }
            await refetch();
        }
        await refetch();
    }

    const handleCheckboxChange = () => {
        if (googleReserveAllowed) {
            setIsAgreed(!isAgreed);
        } else {
            isAlertShowPopup(true);
        }
    };

    const handleUpgrade = () => {
        history.push(`/admin/pricing/${id}`);
    };

    const handleCancel = () => {
        history.push(`/admin/appointments-log/${id}`);
        isAlertShowPopup(false);
    };

    return (
        <>
            {alertshowpop &&
                <Modal active={alertshowpop} clipped>
                    <Modal.Background />
                    <Modal.Card>
                        <Modal.Card.Body>
                            <div className=' content'>
                                <p>Upgrade your business subscription to access this operation.</p>
                            </div>
                            <div className='is-flex is-justify-content-space-evenly'>
                                <Button className='button is-outlined is-rounded is-active has-text-weight-bold' onClick={() => handleUpgrade()} >Upgrade</Button>
                                <Button className='button is-outlined is-rounded is-active has-text-weight-bold' onClick={() => handleCancel()} >Cancel</Button>
                            </div>
                        </Modal.Card.Body>
                    </Modal.Card>
                </Modal>
            }

            {
                // If subscription is allowed in this module then check for other checks
                !alertshowpop && showpop &&
                <Modal active={showpop} clipped>
                    <Modal.Background />
                    <Modal.Card>
                        <Modal.Card.Head className={'is-flex is-justify-content-space-between'}>
                            <Title size='5' className='m-0'>{popupTitle}</Title>
                        </Modal.Card.Head>
                        <Modal.Card.Body>
                            <div>
                                <span className=''>{popupContent}</span>
                            </div>
                            <div className='has-text-danger content'>
                                {/* {isGoogleReserve !== undefined && */}
                                {(!businessOptInChecks?.businessHasService || !businessOptInChecks?.validCustomerSetup || !businessOptInChecks?.canBusinessOptIn || !businessOptInChecks?.validHourOfOperation) &&
                                    <ul >
                                        {
                                            !businessOptInChecks?.validHourOfOperation && <li>
                                                Ensure you have added business hours.
                                            </li>
                                        }
                                        {
                                            !businessOptInChecks?.businessHasService && <li >
                                                At least one service is required with google reserve enabled.
                                            </li>
                                        }
                                        {
                                            !businessOptInChecks?.validCustomerSetup && <li >
                                                Add total capacity and allow user and business appointments in the customer traffic setup.
                                                {/* Enable business and user appointments with total capacity in customer traffic setup. */}
                                            </li>
                                        }
                                    </ul>
                                }
                            </div>
                            <div className='is-flex is-justify-content-end'>
                                <Button className='button is-outlined is-rounded is-active has-text-weight-bold' onClick={() => handleBusinessAdmin()} >Go To Business Setup</Button>
                            </div>
                        </Modal.Card.Body>
                    </Modal.Card>
                </Modal>
            }

            <Section className='mainSection'>
                <Container>
                    <BusinessProvider>
                        <Box className='mainBox' shadow={Boolean(id)}>
                            <Fragment>
                                <div className='is-flex is-align-items-center is-justify-content-space-between pb-5'>
                                    {isGoogleReserve ?
                                        <p className='has-text-grey-light is-static'>{message?.optOutMessage}</p>
                                        :
                                        (!isGoogleReserve && canBusinessOptIn && data?.getBusinessById?.isBusinessAlreadyOptIn) ? (
                                            <p className='has-text-dark'>{message?.optInTwiceMessage}</p>

                                        ) : (
                                            <p className='has-text-dark'>{message?.optInMessage}</p>
                                        )
                                    }
                                </div>
                                <div className='is-flex is-align-items-center is-justify-content-space-between pb-5'>
                                    {isGoogleReserve ?
                                        <label for='isReserve' class="checkbox">
                                            <input id='isReserve' is disabled='true' type="checkbox" checked='true' className='mr-2' />
                                            <span className='is-italic has-text-grey-light'>I accept the terms and conditions .</span>
                                        </label>
                                        :
                                        <label for='isNotReserve' class="checkbox">
                                            <input id='isNotReserve' type="checkbox" className='mr-2' checked={isAgreed} onChange={handleCheckboxChange} />
                                            <span className='is-italic has-text-dark'>I accept the terms and conditions .</span>
                                        </label>
                                    }
                                </div>
                                <div className='is-flex is-align-items-center is-justify-content-left pb-5'>
                                    <label class="checkbox">
                                        {isGoogleReserve ?
                                            <Button color='info' className='button is-rounded has-text-weight-bold' onClick={() => handlOpt()} >OPT-Out</Button>
                                            :
                                            <Button color='info' disabled={!isAgreed} className='button is-rounded has-text-weight-bold' onClick={() => handlOpt()}>OPT-In</Button>

                                        }
                                    </label>
                                </div>
                            </Fragment>
                        </Box>
                    </BusinessProvider>
                </Container>
            </Section>
        </>
    );
};

export { useBusinessDispatch, useBusinessState };
