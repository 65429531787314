import React, { useState } from 'react';
import {
    Title,
    Button,
    Buttons,
    Subtitle,
    Table,
    Icon,
} from '@safelyq/bulma-ui-library';
import moment from 'moment';
import styled from 'styled-components';
import { getStatusColor } from 'methods/getStatuses';
import { STATUS_CAMPAIGN, SORT_PRIORITY } from 'constants/index';

const StyledRow = styled.tr.attrs()`
  &:hover {
    cursor: auto;
  }
`;
const StyledHead = styled.th.attrs()`
  &:hover {
    cursor: auto;
  }
`;
const ClickAble = styled.span`
cursor: pointer;
`;
const StyledList = styled.td.attrs(() => ({
    className: 'py-3 pl-2',
}))`
    vertical-align: middle !important;
    height: 75px;
}
`;

const ScrollableDiv = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: auto;

    /* Custom scrollbar styles for Webkit browsers */
    &::-webkit-scrollbar {
        width: 12px;
        height: 12px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #b3c7e6; /* Light aesthetic color for scrollbar thumb */
        border-radius: 10px;
        border: 3px solid #f1f1f1; /* Adds a border for better visibility and aesthetics */
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #a0b9d6; /* Slightly darker shade on hover */
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1; /* Light background color for the scrollbar track */
    }

    /* Firefox scrollbar styling */
    scrollbar-width: thin;
    scrollbar-color: #dddddd #f7f5f5;
`;

export const TableView = ({ ...props }) => {

    const { campaigns, handleConfirmModalOpen, handleViewModalOpen, handleEditCampaign } = props;

    const [sortConfig, setSortConfig] = useState({});

    const { key, direction } = sortConfig;

    let sortedCampaigns

    switch (key) {
        case 'title':
            sortedCampaigns = campaigns?.sort((a, b) => {
                if (direction === 'ascending') return a.title.localeCompare(b.title)
                else return b.title.localeCompare(a.title)
            })
            break;
        case 'startdate':
            sortedCampaigns = campaigns?.sort((a, b) => {
                if (direction === 'ascending') return new Date(a.startDateTime) - new Date(b.startDateTime)
                else return new Date(b.startDateTime) - new Date(a.startDateTime)
            })
            break;
        case 'enddate':
            sortedCampaigns = campaigns?.sort((a, b) => {
                if (direction === 'ascending') return new Date(a.endDateTime) - new Date(b.endDateTime)
                else return new Date(b.endDateTime) - new Date(a.endDateTime)
            })
            break;
        case 'budget':
            sortedCampaigns = campaigns?.sort((a, b) => {
                if (direction === 'ascending') return a.budget - b.budget
                else return b.budget - a.budget
            })
            break;
        case 'chargeAmount':
            sortedCampaigns = campaigns?.sort((a, b) => {
                if (direction === 'ascending') return a.chargeAmount - b.chargeAmount
                else return b.chargeAmount - a.chargeAmount
            })
            break;
        case 'status':
            sortedCampaigns = campaigns?.sort((a, b) => {
                if (direction === 'ascending') return SORT_PRIORITY[a.status] - SORT_PRIORITY[b.status]
                else return SORT_PRIORITY[b.status] - SORT_PRIORITY[a.status]
            })
            break;
        default:
            sortedCampaigns = campaigns?.sort((a, b) =>
                moment(a.startDateTime).format('HH:mm:ss').localeCompare(moment(b.startDateTime).format('HH:mm:ss'))
            )
                .sort((a, b) => {
                    let d1 = new Date(b.endDateTime)
                    let d2 = new Date(a.endDateTime)
                    d1.setHours(0, 0, 0, 0)
                    d2.setHours(0, 0, 0, 0)
                    return d1 - d2
                }).sort((a, b) => SORT_PRIORITY[a.status] - SORT_PRIORITY[b.status])
            break;
    }


    const returnArrowDirection = (key) => {
        if (sortConfig?.key === key && sortConfig.direction === 'ascending') {
            return 'arrow-up';
        } else {
            return 'arrow-down';
        }
    }

    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig?.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    }

    return (
        <ScrollableDiv>
            <Table
                style={{ width: "100%", whiteSpace: "nowrap", overflowX: "scroll" }}
            >
                {/* <Table fullwidth hoverable> */}
                <thead>
                    <tr>
                        <StyledHead className='pl-2' onClick={() => requestSort('title')}><ClickAble>Campaign<Icon name={returnArrowDirection('title')} className='ml-2' /></ClickAble></StyledHead>
                        <StyledHead className='pl-2' onClick={() => requestSort('startdate')}><ClickAble>Start Date & Time<Icon name={returnArrowDirection('startdate')} className='ml-2' /></ClickAble></StyledHead>
                        <StyledHead className='pl-2' onClick={() => requestSort('enddate')}><ClickAble>End Date & Time<Icon name={returnArrowDirection('enddate')} className='ml-2' /></ClickAble></StyledHead>
                        <StyledHead className='pl-2' onClick={() => requestSort('budget')}><ClickAble>Budget<Icon name={returnArrowDirection('budget')} className='ml-2' /></ClickAble></StyledHead>
                        <StyledHead className='pl-2' onClick={() => requestSort('chargeAmount')}><ClickAble>Charge Amount<Icon name={returnArrowDirection('chargeAmount')} className='ml-2' /></ClickAble></StyledHead>
                        <StyledHead className='pl-2' onClick={() => requestSort('status')}><ClickAble>Status<Icon name={returnArrowDirection('status')} className='ml-2' /></ClickAble></StyledHead>
                        <th className='pl-2'>Actions</th>
                    </tr>
                </thead>
                <tbody className='has-text-black'>
                    {
                        sortedCampaigns?.map(
                            (
                                singleCampaign,
                                index
                            ) => {
                                const {
                                    id,
                                    title,
                                    details,
                                    businessCoupon,
                                    startDateTime,
                                    endDateTime,
                                    budget,
                                    status,
                                    modifiedDate,
                                    campaignGroups,
                                    chargeAmount,
                                    businessAttachment,
                                } = singleCampaign || {}
                                return (
                                    <>
                                        <StyledRow className='py-3' key={`campaigns-${index}`}>
                                            <StyledList className='is-flex'>
                                                <Button color='info' className='icon-button'>
                                                    {String(title).substring(0, 1)}
                                                </Button>
                                                <div className='ml-2'>
                                                    <Title size='6'>{title}</Title>
                                                    {
                                                        businessCoupon ? (
                                                            <Subtitle size='6'>
                                                                {String(businessCoupon?.discountType).toLowerCase() === 'value' && '$'}
                                                                {businessCoupon?.discount && businessCoupon?.discount + " "}
                                                                {String(businessCoupon?.discountType).toLowerCase() === 'percentage' && '% '}
                                                                Off, Expires {" " + moment(businessCoupon?.endDate).format('MM-DD-YYYY')}
                                                            </Subtitle>
                                                        ) : (
                                                            (details.trim() !== "" || campaignGroups?.some(camp => camp.messageText?.trim() !== "" && camp.messageText !== null)) && (
                                                                <Subtitle size='6'>
                                                                    {
                                                                        details
                                                                            ? (String(details).length <= 25 ? details : String(details).substring(0, 25) + '...')
                                                                            : (
                                                                                campaignGroups?.find(camp => camp.messageText)?.messageText
                                                                                    ? (
                                                                                        String(campaignGroups.find(camp => camp.messageText).messageText).length <= 25
                                                                                            ? campaignGroups.find(camp => camp.messageText).messageText
                                                                                            : String(campaignGroups.find(camp => camp.messageText).messageText).substring(0, 25) + '...'
                                                                                    )
                                                                                    : ''
                                                                            )
                                                                    }
                                                                </Subtitle>
                                                            )
                                                        )
                                                    }
                                                </div>
                                            </StyledList>
                                            <StyledList>
                                                <span className='tag'>
                                                    {/* <strong>{`${moment(startDateTime).format('MM-DD-YYYY')} ${moment(startDateTime).format('hh:mm A')}`}</strong> */}
                                                    <strong>{`${moment(startDateTime).format('MMM Do YYYY')} ${moment(startDateTime).format('h:mm:ss a')}`}</strong>
                                                </span>
                                            </StyledList>
                                            <StyledList>
                                                <span className='tag'>
                                                    <strong>{`${moment(endDateTime).format('MMM Do YYYY')} ${moment(endDateTime).format('h:mm:ss a')}`}</strong>
                                                </span>
                                            </StyledList>
                                            <StyledList>
                                                <span className={`tag is-light`}>
                                                    <strong>$ {businessAttachment?.id ? parseFloat(0.00).toFixed(2) : parseFloat(budget).toFixed(2)}</strong>
                                                </span>
                                            </StyledList>
                                            <StyledList>
                                                <span className={`tag is-light`}>
                                                    <strong>$ {parseFloat(chargeAmount).toFixed(2)}</strong>
                                                </span>
                                            </StyledList>
                                            <StyledList>
                                                <div style={{ display: "inline-grid" }}>
                                                    <small
                                                        className={`tag is-light`}
                                                        style={{
                                                            background: `rgba(${getStatusColor(status)}, 0.1)`,
                                                            color: `rgb(${getStatusColor(status)})`,
                                                        }}>
                                                        {status === "Completed" ?
                                                            "Completed On" : status === "Cancelled" ?
                                                                "Cancelled On" : status === "Active" ?
                                                                    "Activated On" : "Last Modified On"}
                                                    </small>
                                                    {/* {getStatusName(status)} */}
                                                    <small className='tag is-light has-text-black'>{moment(modifiedDate).format("MMM Do YYYY, h:mm:ss a")}</small>

                                                </div>
                                            </StyledList>
                                            <StyledList>
                                                <Buttons>
                                                    {
                                                        String(status).toUpperCase() === STATUS_CAMPAIGN.ACTIVE &&
                                                        <Button
                                                            color='danger'
                                                            data-tip='Cancel Campaign'
                                                            className='icon-button action-btn'
                                                            onClick={() => handleConfirmModalOpen(id, STATUS_CAMPAIGN.CANCELLED)}
                                                        >
                                                            <Icon name='times' />
                                                        </Button>
                                                    }
                                                    {
                                                        String(status).toUpperCase() !== STATUS_CAMPAIGN.INCOMPLETE &&
                                                        <Button
                                                            data-tip='View Campaign'
                                                            className='icon-button action-btn'
                                                            onClick={() => handleViewModalOpen({ id, type: "view" })}
                                                        >
                                                            <Icon name='eye' className="has-text-link" />
                                                        </Button>
                                                    }
                                                    {
                                                        // If status incomplete show archieve icon show delete icon
                                                        String(status).toUpperCase() === STATUS_CAMPAIGN.INCOMPLETE &&
                                                        <>
                                                            <Button
                                                                data-tip='Edit Campaign'
                                                                className='icon-button action-btn'
                                                                onClick={() => handleEditCampaign(singleCampaign)}
                                                            >
                                                                <Icon name='pencil' />
                                                            </Button>
                                                            <Button
                                                                data-tip='Delete Campaign'
                                                                className='icon-button action-btn'
                                                                onClick={() => handleViewModalOpen({ id, type: "delete" })}
                                                            >
                                                                <Icon name='trash' className="has-text-danger" />
                                                            </Button>
                                                        </>
                                                    }
                                                    {
                                                        // If status is neither active nor incomplete show archieve icon
                                                        String(status).toUpperCase() !== STATUS_CAMPAIGN.ACTIVE && String(status).toUpperCase() !== STATUS_CAMPAIGN.INCOMPLETE
                                                        &&
                                                        <Button
                                                            data-tip='Delete Campaign'
                                                            className='icon-button action-btn'
                                                            onClick={() => handleViewModalOpen({ id, type: "delete" })}
                                                        >
                                                            <Icon name='archive' className="has-text-danger" />
                                                        </Button>
                                                    }

                                                </Buttons>
                                            </StyledList>
                                        </StyledRow>
                                    </>
                                )
                            })
                    }
                </tbody>
            </Table>
        </ScrollableDiv>
    );
};
