import { gql } from '@apollo/client';

export const GET_BUSINESS_CAMPAIGNS = gql`
query ($selectCampaignsInput: SelectCampaignsInput!) {
getCampaigns(selectCampaignsInput: $selectCampaignsInput){
    totalCount
    campaigns {
      budget
      chargeAmount
      details
      endDateTime
      id
      modifiedDate
      startDateTime
      status
      targetSlotAudience
      title
      totalTargetAudience
      zipCodes
      businessAttachment {
        id
      }
      businessCoupon {
        discountType
        endDate
        discount
      }
      campaignGroups {
        messageText
      }
    }
  }
  }
`;

export const CHANGE_CAMPAIGN_STATUS = gql`
  mutation ($campaignId: Int, $newStatus: CampaignStatus!) {
    updateCampaignStatus(campaignId: $campaignId, newStatus: $newStatus) {
      errorMessage
      isSaved
      campaign {
        status
        id
        title
      }
       message
    }
  }
`;
export const DELETE_CAMPAIGN = gql`
mutation ($campaignId: Int!) {
  deleteCampaign(campaignId: $campaignId){
		errorMessage
		isSaved
	}
}
`

export const GET_SINGLE_CAMPAIGN = gql`
  query GetCampaignById($id: Int!) {
    getCampaignById(id: $id) {
    id,
    title,
    details,
    chargeAmount,
    modifiedDate,
    businessAttachment{
      id,
      fileName
    },
    campaignImages{
      contentType
      fileName
      publicUrl
      id
    },
    startDateTime,
    endDateTime,
    budget,
    zipCodes,
    totalTargetAudience,
    status,
    campaignGroups{
      isSelected
      messageText
      name
    },
    businessCoupon{
        couponUrl
        discountType
        discount
        endDate
        imageUrl
    }
     campaignDetails {
      id
      actualSent
      platform
      success
      targetSlotAudience
      targetedDateTime
      endedAt
      targetedTimeName
       platformWiseStatus {
        actualSent
        isSent
        platform
      }
    }
    }
  }
`