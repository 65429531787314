import React, { useState } from 'react';
import styled from 'styled-components';
import { Columns, Column, Button, Title, } from '@safelyq/bulma-ui-library';
import { AddNewMember as UpdateMemberModal } from '../../components';

const StyledCard = styled.div.attrs(() => ({}))`
  min-width: 156px;
  border-radius: 3px;
  box-shadow: rgb(9 30 66 / 25%) 0px 1px 1px, rgb(9 30 66 / 13%) 0px 0px 1px 1px;
  background-image: linear-gradient(rgb(244, 245, 247) 33%, transparent 33%);
  cursor: pointer;
`;

export const MembersList = ({ members, refetch }) => {
  const [isOpen, setOpen] = useState(false);

  const [user, setUser] = useState({
    email: '',
    role: '',
    serviceProviders: [],
  });

  const handleModalOpen = (user, role, serviceProviders) => {
    setOpen(true);
    setUser({ email: user?.name, role, serviceProviders });
  };

  const handleModalClose = () => setOpen(false);

  return (
    <Columns multiline>
      {members?.map(({ user, roleName, serviceProviders }) => (
        <Column size='3'>
          <StyledCard
            onClick={() => handleModalOpen(user, roleName, serviceProviders)}
          >
            <div className='card-content'>
              <div className='is-flex is-justify-content-space-between is-align-items-center'>
                <Button
                  color='info'
                  size='small'
                  className='icon-button rounded mb-3'
                >
                  {String(user?.name).charAt(0).toUpperCase()}
                </Button>
              </div>
              <Title size='6' className='mb-1'>
                {user?.name}
              </Title>
              <span className='tag is-info is-light'>{roleName}</span>
            </div>
          </StyledCard>
        </Column>
      ))}
      <UpdateMemberModal
        isOpen={isOpen}
        onClose={handleModalClose}
        user={user}
        refetch={refetch}
      />
    </Columns>
  );
};
