import React from 'react';

export function SessionLost() {
  return (
    <div className="hero is-fullheight">
      <div className="hero-body is-paddingless">
        <div className="container has-text-centered">
          <div className="column is-4 is-offset-4">
            <div className="box p-6">
              <a href="/">
                <img src="/images/logo.png" alt="logo"/>
              </a>
              <h5>You have to reauthenticate because the session has expired. Please <a href="/">click here</a>.</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
