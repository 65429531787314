import React from "react";
import Label from "../Label";

const Switch = ({ name, value: initialValue, children, onValueChange: handleValueChange, ...inputProps }) => {
  return (
    <>
      <label className="switch">
        <input
          {...inputProps}
          type="checkbox"
          name={name}
          checked={initialValue}
          value={initialValue}
          onChange={() => handleValueChange(!initialValue)}
        />
        <span className="slider"></span>
      </label>
    </>
  );
};

export default Switch;
