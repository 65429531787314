import React, { Fragment, useEffect, useState } from 'react'
import { Column, Button, Icon } from '@safelyq/bulma-ui-library';

export const CampaignTargetTime = ({ target_time, i, handleCampaignTargetedTime, existingCampaignTargetTimes, SlotRadio, isAllChecked, onEditCustomSlot }) => {
  let existing = existingCampaignTargetTimes || {}
  const [isChecked, setChecked] = useState(existing[i]?.isSelected)

  useEffect(() => {
    if (target_time.isCustomTime) {
      setChecked(!target_time.isSelected)
      setChecked(target_time.isCustomTime)
    }
    if (SlotRadio) {
      setChecked(false)
      target_time.isSelected = false;
    }
  }, [target_time, SlotRadio])

  return (
    <Fragment>
      <Column size='3' className='pb-0'>
        <div className="checkbox-container">
          <label className='checkbox'>
            <input
              disabled={SlotRadio}
              type='checkbox'
              name={target_time.name}
              onChange={(e) => {
                handleCampaignTargetedTime(e, () => {
                  setChecked(!isChecked)
                })
              }}
              checked={isChecked}
            />
            <strong className='ml-2'>{target_time.name} </strong>
            <br />
            <i>
              <small>({target_time.startTime} - {target_time.endTime})</small>
            </i>
          </label>
        </div>
      </Column>
      {target_time.isCustomTime && (
        <Column size='3'>
          <Button disabled={SlotRadio} onClick={onEditCustomSlot} className="is-info is-light"> <Icon name='edit' /></Button>
        </Column>
      )}
    </Fragment>
  )
}