// Navigation component for the admin page
import { Link } from 'react-router-dom';
import React, { useState, Fragment, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Icon } from '@safelyq/bulma-ui-library';
import { AdminDrawer } from '../AdminDrawer';
import { useProfile } from 'hooks/useProfile';
import { useSelector } from 'react-redux';
import useScreenWidth from '../CustomHookComponent/RenderingOnScreenSize';

const StyledNav = styled.nav.attrs(() => ({
  className: 'navbar p-1'
}))`
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
`;

const StyledImage = styled.img.attrs(() => ({}))`
  max-height: 2.3rem !important;
  width: auto;
`;

export const Navbar = ({ ...props }) => {
  const {
    avatarName,
    onFullScreen,
    isAllowFullScreen,
    isAllowSideBar,
    isAllowNotifications,
    onLogout,
  } = props;
  const [toggle, setToggle] = useState(false);


  // Component will only on the screens tablet or smaller
  const screenWidth = useScreenWidth();
  const isMobileOrTablet = screenWidth <= 768;

  const [profileToggle, setProfileToggle] = useState(false);
  const businessId = useSelector(state => state?.business?.businessId)

  const isSuperAdmin = useSelector(state => state?.auth?.userinfo?.isSuperAdmin);
  const businessNameState = useSelector(state => state?.business?.businessName);
  const messageCountGlobal = useSelector((state) => state?.business?.messageCount);
  const notificationCountGlobal = useSelector((state) => state?.business?.notificationCount);

  const { isLoggedIn } = useProfile();

  const handleToggle = () => setToggle(!toggle);

  const handleProfileToggle = () => setProfileToggle(!profileToggle);

  const handleMenuClose = () => setToggle(false);

  const handleLogout = () => {
    localStorage.setItem('post_logout_redirect_url', '/admin');
    localStorage.setItem("authPersist", "false") // change the logged in flag to false
    onLogout();
  };

  // const logoUrl =
  //   businessId === 'businesses' ||
  //     businessId === 'admin' ||
  //     businessId === 'create-organization' ||
  //     businessId === 'manage-business'
  //     ? `/assets/logo/0`
  //     : `/assets/logo/${businessId}`;
  const logoUrl = (businessId == null || !isLoggedIn) ? `/assets/logo/0` : `/assets/logo/${businessId}`;

  return (
    <StyledNav role='navigation' aria-label='main navigation'>
      <div className='navbar-brand' style={{ width: '100%' }}>
        <Link to='/admin' className='navbar-item py-0' onClick={handleMenuClose}   >
          <StyledImage src={logoUrl} alt='logo' />
          {isLoggedIn && businessId ? (<Customh6 className='ml-3'> <b>{businessNameState} </b></Customh6>) : null}
          {/* {businessId ? (<h6 className='ml-3' style={{ flexWrap: "wrap", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}> <b>{businessName}</b>  </h6>) : null} */}
        </Link>
        <div style={{ marginLeft: 'auto' }} />
        {isLoggedIn && (
          <Fragment>
            {isAllowNotifications && (
              <Fragment>
                <>
                  {/* Messages */}
                  <div className='navbar-item pl-0'>
                    <Link to={`/admin/messages/${businessId}`} title='Messages'>
                      <Button size='small' className='button-icon'> <Icon name='comments' /> </Button>
                      {messageCountGlobal > 0 && (<span className='notification-badge' style={{ marginTop: -38 }} > {messageCountGlobal > 9 ? '9+' : messageCountGlobal}  </span>)}
                    </Link>
                  </div>
                  {/* Notification */}
                  <div className='navbar-item pl-0'>
                    <Link to={`/admin/notifications/${businessId}`} title='Notifications'  >
                      <Button size='small' className='button-icon'> <Icon name='bell' /> </Button>
                      {notificationCountGlobal > 0 && (<span className='notification-badge' style={{ marginTop: -38 }} > {notificationCountGlobal > 9 ? '9+' : notificationCountGlobal}  </span>)}
                    </Link>
                  </div>
                </>
              </Fragment>
            )}
            {isAllowFullScreen && (
              <div className='navbar-item pl-0' title='Expand Screen'>
                <Button size='small' className='button-icon' onClick={onFullScreen}  >  <Icon name='arrows-alt' />  </Button>
              </div>
            )}

            {/* Super admin / Admin details - hide only on mobile and tablets */}
            <div className='navbar-item pl-0 is-hidden-touch' title='Profile'>
              <div className={`dropdown ${profileToggle && 'is-active'} is-right`} >
                <div className='dropdown-trigger'>
                  {isSuperAdmin && (
                    <Link to="/superadmin/home">
                      <Button size="small" color="info" style={{ marginRight: "10px" }}>
                        Super Admin
                      </Button>
                    </Link>
                  )}
                  <Button size='small' color='info' light className='avatar-icon rounded' aria-controls='dropdown-menu' onClick={handleProfileToggle}  > <strong>{avatarName}</strong> </Button>
                </div>
                <div className='dropdown-menu' id='dropdown-menu' role='menu'>
                  <div className='dropdown-content '>
                    <Link to='/admin/account/setting' className='dropdown-item' onClick={handleProfileToggle}  >  Account Settings  </Link>
                    <Link to='#' className='dropdown-item' onClick={handleLogout}  > Logout  </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Businesses Page show nav details */}
            {
              !isAllowSideBar &&
              < div className='navbar-item pl-0 is-hidden-desktop' title='Profile'>
                <div className={`dropdown ${profileToggle && 'is-active'} is-right`} >
                  <div className='dropdown-trigger'>
                    {isSuperAdmin && (
                      <Link to="/superadmin/home">
                        <Button size="small" color="info" style={{ marginRight: "10px" }}>
                          Super Admin
                        </Button>
                      </Link>
                    )}
                    <Button size='small' color='info' light className='avatar-icon rounded' aria-controls='dropdown-menu' onClick={handleProfileToggle}  > <strong>{avatarName}</strong> </Button>
                  </div>
                  <div className='dropdown-menu' id='dropdown-menu' role='menu'>
                    <div className='dropdown-content '>
                      <Link to='/admin/account/setting' className='dropdown-item' onClick={handleProfileToggle}  >  Account Settings  </Link>
                      <Link to='#' className='dropdown-item' onClick={handleLogout}  > Logout  </Link>
                    </div>
                  </div>
                </div>
              </div>
            }
          </Fragment>
        )}
        {isAllowSideBar && (
          <a role='button' className={`navbar-burger is-hidden-desktop burger m-0 ${toggle && 'is-active'}`} aria-label='menu' aria-expanded='false' data-target='mainNavbar' onClick={handleToggle} >
            <span aria-hidden='true' />
            <span aria-hidden='true' />
            <span aria-hidden='true' />
          </a>
        )}
      </div>
      {isAllowSideBar && isMobileOrTablet && (
        <div className={`navbar-menu ${toggle ? 'is-active' : ''}`}>
          <AdminDrawer
            unreadMessages={messageCountGlobal}
            toggle={handleToggle}
            isSuperAdmin={isSuperAdmin}
            onLogout={onLogout}
          />
        </div>
      )}
    </StyledNav>
  );
};

const Customh6 = styled.h6`
   overflow: hidden;
    text-overflow: ellipsis;
     white-space: nowrap;
     
     @media only screen and (max-width: 550px){
      width: 62px
     }
`;