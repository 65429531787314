import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Icon } from '@safelyq/bulma-ui-library';

export const MenuList = () => {
  const { state } = useLocation() || {};

  const { currentMenu } = state || { currentMenu: 0 };

  return (
    <Box className='is-hidden-mobile'>
      <aside className='menu'>
        <p className='menu-label'>General</p>
        <ul className='menu-list'>
          <li>
            <Link to={{ pathname: '/account/setting', state: { currentMenu: 0 }, }} className={currentMenu === 0 ? 'is-active' : ''}   >
              <Icon name='user' /> Profile
            </Link>
          </li>
          <li>
            <Link to={{ pathname: '/account/notifications', state: { currentMenu: 1 }, }} className={currentMenu === 1 ? 'is-active' : ''}   >
              <Icon name='bell' /> Notifications
            </Link>
          </li>
          <li>
            <Link to={{ pathname: '/account/marketing-notifications', state: { currentMenu: 4 }, }} className={currentMenu === 4 ? 'is-active' : ''}  >
              <Icon name='bell' /> Marketing Messages
            </Link>
          </li>
        </ul>
        <p className='menu-label'>Security</p>
        <ul className='menu-list'>
          <li>
            <Link to={{ pathname: '/account/change-password', state: { currentMenu: 2 }, }} className={currentMenu === 2 ? 'is-active' : ''}   >
              <Icon name='lock' /> Change Password
            </Link>
          </li>
          <li>
            <Link to={{ pathname: '/account/delete-account', state: { currentMenu: 3 }, }} className={currentMenu === 3 ? 'is-active' : ''}  >
              <Icon name='trash' /> Delete Account
            </Link>
          </li>
        </ul>
      </aside>
    </Box>
  );
};
