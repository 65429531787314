import React, { Fragment } from 'react'
import { Table, Field, Control, Label, Title, Columns, Column } from '@safelyq/bulma-ui-library';
import styled from 'styled-components';
import Overflow from 'react-overflow-indicator';
import moment from 'moment';

export const CampaignSummary = ({ data, totalWhatsAppCost, totalEmailCost, totalSMSCost, totalMMSCost, bonusRemain, youllPay, usersCost, amount }) => {
    return (
        <Fragment>
            {data?.campaignDetails?.length > 0 &&
                // <Column size='12' className='is-inline-block p-1'>
                <Column className='p-1 is-full-mobile is-full-tablet is-full-desktop is-four-fifths-widescreen is-four-fifths-fullhd'>
                    <div
                        //  style={{ maxWidth: "660px", minWidth: "480px" }} 
                        className='box'>
                        <div className='is-flex is-flex-direction-column'>
                            <Title size='6' color='info' className='mb-4'>
                                Summary
                            </Title>
                            <Overflow style={{ overflow: "scroll", maxHeight: "70vh" }}>
                                <Overflow.Content className='is-flex is-flex-direction-column'>
                                    <Table className='mb-4'>
                                        <thead>
                                            <tr>
                                                <StyledListHead>Targeted Date Time</StyledListHead>
                                                <StyledListHead>Targeted Slot</StyledListHead>
                                                <StyledListHead>Platform</StyledListHead>
                                                <StyledListHead>Targeted Audience</StyledListHead>
                                            </tr>
                                        </thead>
                                        <tbody className='has-text-black'>
                                            {data.campaignDetails?.map(({ platform, targetSlotAudience, targetedDateTime, targetedTimeName }, index) => (
                                                <>
                                                    <StyledRow className='py-3' key={`campaigns-${index}`}>
                                                        <StyledList>
                                                            <small>{moment(targetedDateTime).format('YYYY-MM-DD')} {moment(targetedDateTime).format('hh:mm A')}</small>
                                                        </StyledList>
                                                        <StyledList>
                                                            <small>{targetedTimeName}</small>
                                                        </StyledList>
                                                        <StyledList>
                                                            <small>{platform}</small>
                                                        </StyledList>
                                                        <StyledList>
                                                            <small>{targetSlotAudience}</small>
                                                        </StyledList>
                                                    </StyledRow>
                                                </>
                                            ))}
                                            <StyledRow className='py-3'>
                                                <StyledList colSpan={3}>
                                                    <strong>Total Targeted Audience</strong>
                                                </StyledList>
                                                <StyledList>
                                                    <strong>{data.totalTargetAudience}</strong>
                                                </StyledList>
                                            </StyledRow>
                                        </tbody>
                                    </Table>
                                </Overflow.Content>
                                <Indicator className='has-text-right'><Overflow.Indicator direction="down">⏬</Overflow.Indicator></Indicator>
                            </Overflow>
                        </div>

                        <Columns multiline size='12' className='mt-3'>
                            <Column size='12'>
                                <Label className='has-text-info'>
                                    Cost Summary
                                </Label>
                            </Column>


                            {totalWhatsAppCost !== 0 ?
                                <Column size="3">
                                    <strong>WhatsApp :</strong>
                                    <small> $ <i>{parseFloat(totalWhatsAppCost).toFixed(2)}</i></small>
                                </Column>
                                : null
                            }
                            {totalEmailCost !== 0 ?
                                <Column size="2">
                                    <strong>Email :</strong>
                                    <small> $ <i>{parseFloat(totalEmailCost).toFixed(2)}</i></small>
                                </Column>
                                : null
                            }
                            {totalSMSCost !== 0 ?
                                <Column size="2">
                                    <strong>SMS :</strong>
                                    <small> $ <i>{parseFloat(totalSMSCost).toFixed(2)}</i></small>
                                </Column>
                                : null
                            }
                            {totalMMSCost !== 0 ?
                                <Column size="2">
                                    <strong>MMS :</strong>
                                    <small> $ <i>{parseFloat(totalMMSCost).toFixed(2)}</i></small>
                                </Column>
                                : null
                            }
                            {usersCost !== 0 ?
                                <Column size="4">
                                    <strong>Target Audience Cost:</strong>
                                    <small> $ <i>{parseFloat(usersCost).toFixed(2)}</i></small>
                                </Column>
                                : null
                            }

                            <Column size="2">
                                <strong>Total :</strong>
                                <small> $ <i>{parseFloat(totalSMSCost + totalEmailCost + totalWhatsAppCost + totalMMSCost + usersCost).toFixed(2)}</i></small>
                            </Column>
                        </Columns>
                        <Columns className='mt-2'>
                            <Column>
                                <Field>
                                    <Control>
                                        <Label>Remaining Bonus</Label>
                                        <input
                                            // style={{ width: "200px" }}
                                            className='input'
                                            name='bonusRemain'
                                            disabled={true}
                                            value={`$ ${bonusRemain}`}
                                        />
                                    </Control>
                                </Field>
                            </Column>
                            <Column>
                                <Field>
                                    <Control>
                                        <Label>You'll Pay</Label>
                                        <input
                                            // style={{ width: "200px" }}
                                            className='input'
                                            name='youllPay'
                                            disabled={true}
                                            // value={`$ ${amount}`}
                                            value={`$ ${youllPay}`}
                                        />
                                    </Control>
                                </Field>
                                {youllPay > 0 && youllPay <= 5 && <p className='is-size-7 has-text-link'>* A minimum of $5 will be charged, and the remaining amount will be added to the bonus balance for future use.</p>}
                            </Column>
                        </Columns>

                    </div>
                </Column>
            }
        </Fragment>
    )
}
const StyledListHead = styled.th.attrs(() => ({
    className: 'py-3 px-2 has-text-centered th-compaign-details',
}))`
  vertical-align: middle !important;
}
`;

const StyledList = styled.td.attrs(() => ({
    className: 'py-3 px-2 has-text-centered th-compaign-details',
}))`
  vertical-align: middle !important;
}import Column from './../../../../../libraries/bulma-ui-library/Column/index';
import { CouponImage } from './../../../../../components/Favorites/components/BusinessCoupons/BusinessCoupons.styled';

`;

const StyledRow = styled.tr.attrs()`
  &:hover {
    cursor: pointer;
  }
`;

const Indicator = styled.div`
position: absolute;
right: 100px;
bottom: 0px;
font-size: 29px;
`