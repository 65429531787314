import React, { useState } from 'react';
import { Modal, Icon, Title, Button } from '@safelyq/bulma-ui-library';

export const AddressessModal = ({
  isOpen,
  onClose,
  onSubmit,
  data,
  googleAddresses,
}) => {
  const [address, setAddress] = useState('');

  const handleAddressChange = ({ target: { value } }) =>
    setAddress(JSON.parse(value));

  const handleSubmit = () => {
    let googleAddress = { ...address };

    if (googleAddress.locationType !== 'EXISTING_ADDRESS') {
        delete googleAddress.formattedAddress;

        onSubmit({
            ...data,
            googleAddress,
        });
    }

    onClose();
  };

  return (
    <Modal active={isOpen} clipped>
      <Modal.Background />
      <Modal.Card>
        <Modal.Card.Head className={'is-flex is-justify-content-space-between'}>
          <Title size='5' className='m-0'>
            Save Address
          </Title>
          <Button
            size='small'
            className='button-icon'
            type='button'
            onClick={onClose}
          >
            <Icon name='close' className='is-right' />
          </Button>
        </Modal.Card.Head>
        <Modal.Card.Body>
          <div className='field'>
            <label className='label'>Google Address</label>
            <div className='control'>
              <div className='select is-fullwidth'>
                <select onChange={handleAddressChange}>
                  <option value={''} selected disabled>
                    Select Google Address
                  </option>
                  {googleAddresses &&
                    googleAddresses?.map((address) => (
                      <option key={`address`} value={JSON.stringify(address)}>
                        {address?.formattedAddress}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        </Modal.Card.Body>
        <Modal.Card.Foot className='has-background-white'>
          <Button
            size='small'
            color='info'
            type='button'
            className='is-right'
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Modal.Card.Foot>
      </Modal.Card>
    </Modal>
  );
};
