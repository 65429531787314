import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { ApolloRoute } from 'routes/ApolloRoutes';
import { useLocation } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import useGaTracker from 'hooks/useGaTracker';
import UserLayout from './UserLayout';
import FullPageLoader from 'sharedComponents/FullPageLoader/FullPageLoader';
// import Home from 'components/Home';
// import SearchBusinessAI from 'components/SearchBusinessAI';
// import Favorites from 'components/Favorites';
// import BusinessDetails from 'components/BusinessDetails';
// import PrivacyPolicy from 'components/PrivacyPolicy';
// import AppointmentDetails from 'components/AppointmentDetails';
// import RegisterAccount from 'components/RegisterAccount';
// import LoginAccount from 'components/LoginAccount';
// import ClaimAccount from 'components/ClaimAccount';
// import MarketingSignUp from 'components/MarketingSignUp';
// import VerifyAccount from 'components/VerifyAccount';
// import UserProfile from 'components/UserProfile';
// import ConfirmEmail from 'components/ConfirmEmail';
// import VerifyEmail from 'components/VerifyEmail';
// // import ForgotPassword from 'components/ForgotPassword';
// // import ResetPassword from 'components/ResetPassword';
// import ChangePassword from 'components/ChangePassword';
// import FileUploadTest from 'components/FileUploadTest';
// import AboutUs from 'components/AboutUs';
// import ContactUs from 'components/ContactUs';
// import ConfirmCheckIn from 'components/ConfirmCheckIn';
// import BusinessSubscriptionTest from 'components/BusinessSubscriptionTest';
// import UserSubscriptionTest from 'components/UserSubscriptionTest';
// import ChatHome from 'components/ChatTest';
// import BraintreeTest from 'components/BraintreeTest';
// import TestPage from 'components/TestPage';
// import Pricing from 'components/Pricing';
// import { NotFound } from 'components/NotFound';
// import { ManageBooking } from '@safelyq/booking-feature';
// import { ManageSearch } from '@safelyq/search-feature';
// import { ManageForgotPassword, ManageSettings } from '@safelyq/account-feature';
// import { ManageNotifications } from '@safelyq/notification-feature';
// import { ManageUserMessages } from '@safelyq/message-feature';
// import { CreatePatient } from '@safelyq/bahmni';
// import TestUI from 'components/TestUI';
// import TermsAndConditionsPage from 'Pages/TermsAndConditionsPage/TermsAndConditionsPage';
// import PrivacyPolicyPage from 'Pages/PrivacyPolicyPage/PrivacyPolicyPage';
// import UserLayout from './UserLayout';
// import PaymentHomePage from 'components/BusinessPayment';

// Lazy load components
const Home = React.lazy(() => import('components/Home'));
const SearchBusinessAI = React.lazy(() => import('components/SearchBusinessAI'));
const Favorites = React.lazy(() => import('components/Favorites'));
const BusinessDetails = React.lazy(() => import('components/BusinessDetails'));
const PrivacyPolicy = React.lazy(() => import('components/PrivacyPolicy'));
const AppointmentDetails = React.lazy(() => import('components/AppointmentDetails'));
const RegisterAccount = React.lazy(() => import('components/RegisterAccount'));
const LoginAccount = React.lazy(() => import('components/LoginAccount'));
const ClaimAccount = React.lazy(() => import('components/ClaimAccount'));
const MarketingSignUp = React.lazy(() => import('components/MarketingSignUp'));
const VerifyAccount = React.lazy(() => import('components/VerifyAccount'));
const UserProfile = React.lazy(() => import('components/UserProfile'));
const ConfirmEmail = React.lazy(() => import('components/ConfirmEmail'));
const VerifyEmail = React.lazy(() => import('components/VerifyEmail'));
const ChangePassword = React.lazy(() => import('components/ChangePassword'));
const FileUploadTest = React.lazy(() => import('components/FileUploadTest'));
const AboutUs = React.lazy(() => import('components/AboutUs'));
const ContactUs = React.lazy(() => import('components/ContactUs'));
const ConfirmCheckIn = React.lazy(() => import('components/ConfirmCheckIn'));
const BusinessSubscriptionTest = React.lazy(() => import('components/BusinessSubscriptionTest'));
const UserSubscriptionTest = React.lazy(() => import('components/UserSubscriptionTest'));
const ChatHome = React.lazy(() => import('components/ChatTest'));
const BraintreeTest = React.lazy(() => import('components/BraintreeTest'));
const TestPage = React.lazy(() => import('components/TestPage'));
const Pricing = React.lazy(() => import('components/Pricing'));
const NotFound = React.lazy(() => import('components/NotFound'));
const ManageBooking = React.lazy(() => import('@safelyq/booking-feature').then(module => ({ default: module.ManageBooking })));
const ManageSearch = React.lazy(() => import('@safelyq/search-feature').then(module => ({ default: module.ManageSearch })));
const ManageForgotPassword = React.lazy(() => import('@safelyq/account-feature').then(module => ({ default: module.ManageForgotPassword })));
const ManageSettings = React.lazy(() => import('@safelyq/account-feature').then(module => ({ default: module.ManageSettings })));
const ManageNotifications = React.lazy(() => import('@safelyq/notification-feature').then(module => ({ default: module.ManageNotifications })));
const ManageUserMessages = React.lazy(() => import('@safelyq/message-feature').then(module => ({ default: module.ManageUserMessages })));
const CreatePatient = React.lazy(() => import('@safelyq/bahmni'));
const TestUI = React.lazy(() => import('components/TestUI'));
const TermsAndConditionsPage = React.lazy(() => import('Pages/TermsAndConditionsPage/TermsAndConditionsPage'));
const PrivacyPolicyPage = React.lazy(() => import('Pages/PrivacyPolicyPage/PrivacyPolicyPage'));
// const UserLayout = React.lazy(() => import('./UserLayout'));
const PaymentHomePage = React.lazy(() => import('components/BusinessPayment'));

export const UserRoutes = ({ profile }) => {
  useGaTracker();
  const { pathname } = useLocation();

  // Define all your known routes
  const knownRoutes = [
    '/', '/search-business-ai', '/privacy', '/about-us', '/contact-us',
    '/terms-and-conditions', '/privacy-policy', '/business/search', '/test-page',
    '/for-business', '/account/claim', '/account/claim/:params', '/account/marketing/:params',
    '/account/verify', '/appointment/:businessId/:id/details', '/account/register',
    '/account/login', '/account/profile', '/account/confirmemail', '/account/verify-email',
    '/account/setting', '/account/change-password', '/account/notifications', '/account/marketing-notifications', '/account/delete-account',
    '/account/changepassword', '/business/upload-images', '/:id/confirm-checkin',
    '/business/:id/details', '/favorites', '/testpageUI', '/business/:businessId',
    '/search', '/account/forgot-password', '/account', '/notifications', '/messages',
    '/messages/:businessId', '/business-subscription-test', '/chat-test',
    '/braintree-test', '/user-subscription-test', '/bahmni/create-patient/:businessId', '/payment'
  ];

  const isNavbarHidePaths = [
    '/account/claim',
    '/account/claim/:params',
    '/account/marketing/:params',
    '/account/verify',
    '/account/verify-email',
    '/account/forgot-password',
  ];

  const shouldHideNavbar = (pathname) => {
    // Check if the path matches any exact known route
    const isKnownPath = knownRoutes.some((path) => matchPath(pathname, { path, exact: true, strict: false }));

    // Check if the path matches any of the specific paths where the navbar should be hidden
    const isNavbarHidePath = isNavbarHidePaths.some((path) => matchPath(pathname, { path, exact: true, strict: false }));

    // Hide navbar if it's an unknown path or one of the specific paths
    return isNavbarHidePath || !isKnownPath;
  };

  const shouldHideFooter = (pathname) => {
    // Check if the path is not a known route
    const isKnownPath = knownRoutes.some((path) => matchPath(pathname, { path, exact: true, strict: false }));

    // Hide the footer if it's an unknown path or one of the specific paths
    return !isKnownPath;
  };

  const isNavbarHide = shouldHideNavbar(pathname);
  const isFooterHide = shouldHideFooter(pathname);

  // const isNavbarHide = isNavbarHidePaths.some((path) => pathname.startsWith(path));
  return (

    <Switch>
      {/* <Suspense> */}
      {/* Routes wrapped with UserLayout */}
      <Route path={['/', '/search-business-ai', '/privacy', '/about-us', '/contact-us', '/terms-and-conditions',
        '/privacy-policy', '/business/search', '/test-page', '/for-business', '/account/claim', '/account/claim/:params',
        '/account/marketing/:params', '/account/verify', '/appointment/:businessId/:id/details', '/account/register',
        '/account/setting', '/account/change-password', '/account/notifications', '/account/marketing-notifications', '/account/delete-account',
        '/account/login', '/account/profile', '/account/confirmemail', '/account/verify-email', '/account/changepassword',
        '/business/upload-images', '/:id/confirm-checkin', '/business/:id/details', '/favorites', '/testpageUI',
        '/business/:businessId', '/search', '/account/forgot-password', '/account', '/notifications', '/messages',
        '/messages/:businessId', '/business-subscription-test', '/chat-test', '/braintree-test', '/user-subscription-test',
        '/bahmni/create-patient/:businessId', '/payment']}
      >
        <UserLayout profile={profile} isNavbarHide={isNavbarHide} isFooterHide={isFooterHide}>
          <Suspense fallback={<FullPageLoader />}>
            <Switch>
              <Route exact path='/' component={Home} />
              <ApolloRoute exact secure path='/search-business-ai' component={SearchBusinessAI} profile={profile} />
              <ApolloRoute exact path='/privacy' component={PrivacyPolicy} profile={profile} />
              <ApolloRoute exact path='/about-us' component={AboutUs} profile={profile} />
              <ApolloRoute exact path='/contact-us' component={ContactUs} profile={profile} />
              <ApolloRoute exact path='/terms-and-conditions' component={TermsAndConditionsPage} profile={profile} />
              <ApolloRoute exact path='/privacy-policy' component={PrivacyPolicyPage} profile={profile} />
              <ApolloRoute exact path='/business/search' component={ManageSearch} profile={profile} />
              <ApolloRoute exact path='/test-page' component={TestPage} profile={profile} />
              <ApolloRoute exact path='/for-business' component={Pricing} profile={profile} />
              <ApolloRoute exact path='/account/claim' component={ClaimAccount} profile={profile} />
              <ApolloRoute exact path='/account/claim/:params' component={ClaimAccount} profile={profile} />
              <ApolloRoute exact path='/account/marketing/:params' component={MarketingSignUp} profile={profile} />
              <ApolloRoute exact path='/account/verify' component={VerifyAccount} profile={profile} />
              <ApolloRoute exact path='/appointment/:businessId/:id/details' component={AppointmentDetails} profile={profile} />
              <ApolloRoute exact path='/account/register' component={RegisterAccount} />
              <ApolloRoute exact path='/account/login' component={LoginAccount} />
              <ApolloRoute secure exact path='/account/profile' component={UserProfile} profile={profile} />
              <ApolloRoute secure exact path='/account/confirmemail' component={ConfirmEmail} profile={profile} />
              <ApolloRoute exact path='/account/verify-email' component={VerifyEmail} profile={profile} />
              <ApolloRoute secure exact path='/account/changepassword' component={ChangePassword} profile={profile} />
              <ApolloRoute secure exact path='/business/upload-images' component={FileUploadTest} profile={profile} />
              <ApolloRoute secure exact path='/:id/confirm-checkin' component={ConfirmCheckIn} profile={profile} />
              <ApolloRoute secure exact path='/business/:id/details' component={BusinessDetails} profile={profile} />
              <ApolloRoute secure exact path='/favorites' component={Favorites} profile={profile} />
              <ApolloRoute secure exact path='/testpageUI' component={TestUI} profile={profile} />
              <ApolloRoute exact path='/payment' component={PaymentHomePage} profile={profile} /> {/* Business Payment */}
              <ApolloRoute path='/business/:businessId' component={ManageBooking} profile={profile} />
              <ApolloRoute exact path='/search' component={ManageSearch} profile={profile} />
              <ApolloRoute exact path='/account/forgot-password' component={ManageForgotPassword} profile={profile} />
              <ApolloRoute secure path='/account' component={ManageSettings} profile={profile} />
              <ApolloRoute secure path='/notifications' component={ManageNotifications} profile={profile} />
              <ApolloRoute secure exact path='/messages' component={ManageUserMessages} profile={profile} />
              <ApolloRoute secure exact path='/messages/:businessId' component={ManageUserMessages} profile={profile} />
              <ApolloRoute secure exact path='/business-subscription-test' component={BusinessSubscriptionTest} profile={profile} />
              <ApolloRoute secure exact path='/chat-test' component={ChatHome} profile={profile} />
              <ApolloRoute secure exact path='/braintree-test' component={BraintreeTest} profile={profile} />
              <ApolloRoute secure exact path='/user-subscription-test' component={UserSubscriptionTest} profile={profile} />
              <ApolloRoute exact secure path='/bahmni/create-patient/:businessId' component={CreatePatient} profile={profile} />
              {/* Catch-all route for unmatched paths */}
              <Route path='*' render={() => <NotFound />} />
            </Switch>
          </Suspense>
        </UserLayout>
      </Route>
      {/* </Suspense> */}
    </Switch>

  );
};
