import { gql } from '@apollo/client';

export const GET_BUSINESS_COUPONS = gql`
  query ($id: Int) {
    getBusinessById(id: $id, showAll: true) {
      id
      businessCoupons {
        id
        title
        code
        couponUrl
        imageUrl
        couponUsageLimit
        couponUsageLimitValue
        expireStatus
        discount
        discountType
        startDate
        endDate
        summary
        terms
        isActive
      }
  }
}
`;
