import moment from 'moment';
export const ParsedDays = (arr) => {
    let newarr = [];
    arr.forEach((ele, index) => {
        if (index === 0 || index === 1) {

            if (index === 0) {
                newarr[0] = { openingTime1: ele.openingTime, closingTime1: ele.closingTime, slot1Id: ele.id, date: null, dayNumber: 0, dayOfYear: null, dayName: "Sunday" }
            }
            else {
                newarr[0] = { ...newarr[0], openingTime2: ele.openingTime, closingTime2: ele.closingTime, slot2Id: ele.id }
            }
        } else if (index === 2 || index === 3) {
            if (index === 2) {
                newarr[1] = { openingTime1: ele.openingTime, closingTime1: ele.closingTime, slot1Id: ele.id, date: null, dayNumber: 1, dayOfYear: null, dayName: "Monday" }
            }
            else {
                newarr[1] = { ...newarr[1], openingTime2: ele.openingTime, closingTime2: ele.closingTime, slot2Id: ele.id }
            }
        } else if (index === 4 || index === 5) {
            if (index === 4) {
                newarr[2] = { openingTime1: ele.openingTime, closingTime1: ele.closingTime, slot1Id: ele.id, date: null, dayNumber: 2, dayOfYear: null, dayName: "Tuesday" }
            }
            else {
                newarr[2] = { ...newarr[2], openingTime2: ele.openingTime, closingTime2: ele.closingTime, slot2Id: ele.id }
            }
        } else if (index === 6 || index === 7) {
            if (index === 6) {
                newarr[3] = { openingTime1: ele.openingTime, closingTime1: ele.closingTime, slot1Id: ele.id, date: null, dayNumber: 3, dayOfYear: null, dayName: "Wednesday" }
            }
            else {
                newarr[3] = { ...newarr[3], openingTime2: ele.openingTime, closingTime2: ele.closingTime, slot2Id: ele.id }
            }
        } else if (index === 8 || index === 9) {
            if (index === 8) {
                newarr[4] = { openingTime1: ele.openingTime, closingTime1: ele.closingTime, slot1Id: ele.id, date: null, dayNumber: 4, dayOfYear: null, dayName: "Thursday" }
            }
            else {
                newarr[4] = { ...newarr[4], openingTime2: ele.openingTime, closingTime2: ele.closingTime, slot2Id: ele.id }
            }
        } else if (index === 10 || index === 11) {
            if (index === 10) {
                newarr[5] = { openingTime1: ele.openingTime, closingTime1: ele.closingTime, slot1Id: ele.id, date: null, dayNumber: 5, dayOfYear: null, dayName: "Friday" }
            }
            else {
                newarr[5] = { ...newarr[5], openingTime2: ele.openingTime, closingTime2: ele.closingTime, slot2Id: ele.id }
            }
        } else if (index === 12 || index === 13) {
            if (index === 12) {
                newarr[6] = { openingTime1: ele.openingTime, closingTime1: ele.closingTime, slot1Id: ele.id, date: null, dayNumber: 6, dayOfYear: null, dayName: "Saturday" }
            }
            else {
                newarr[6] = { ...newarr[6], openingTime2: ele.openingTime, closingTime2: ele.closingTime, slot2Id: ele.id }
            }
        }
    });

    return newarr
}


export const ModifiedDays = (weekdays) => {
    const updatedWeekdays = weekdays.map((day) => {
        let obj = {}
        obj.dayName = day.dayName
        obj.slot1Id = day.slot1Id
        obj.slot2Id = day.slot2Id
        obj.dayNumber = day.dayNumber
        obj.hasBreakError = ""
        obj.breakStartTimeError = ""
        obj.breakDurationError = ""

        if (day.openingTime2 === "0001-01-01T00:00:00" && day.closingTime2 === "0001-01-01T00:00:00") {
            // it means day is off
            obj.isOpen = false
            obj.openingTime = moment(day.openingTime2).format('HH:mm')
            obj.closingTime = moment(day.closingTime2).format('HH:mm')
        }
        else {
            obj.isOpen = true
            if (day.openingTime2 === day.closingTime2) {
                // it means no break
                obj.openingTime = moment(day.openingTime1).format('HH:mm')
                obj.closingTime = moment(day.closingTime1).format('HH:mm')
                obj.hasBreak = false
                obj.breakStartTime = ""
                obj.breakDuration = "0"
            }
            else {
                // it means ther is break
                obj.openingTime = moment(day.openingTime1).format('HH:mm')
                obj.closingTime = moment(day.closingTime2).format('HH:mm')
                obj.hasBreak = true
                obj.breakStartTime = moment(day.closingTime1).format('HH:mm')
                const timeInMilliSec = moment(day.openingTime2).diff(moment(day.closingTime1))
                const timInSec = moment.duration(timeInMilliSec).asMinutes()
                obj.breakDuration = timInSec
            }
        }

        return obj
    })
    return updatedWeekdays
}