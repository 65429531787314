import { gql } from '@apollo/client';

export const SEND_MESSAGE_TO_USER = gql`
  mutation SendMessageToUser($userMessageInput: UserMessageInput) {
    sendMessageToUser(userMessageInput: $userMessageInput) {
      messageId
      userMessage {
        business {
          id
          name
        }
        direction
        user {
          id
          fullName
          name
          email
        }
        messageType
        message {
          commentText
          createdTime
          user {
            id
            fullName
            email
          }
        }
        attachment {
          contentType
          publicUrl
          jsonData
          fileName
          id
        }
      }
    }
  }
`;

export const GET_USER_CONVERSATION = gql`
  query GetUserConversation($userId: String!, $businessId: Int!) {
    getUserConversation(userId: $userId, businessId: $businessId) {
      isSuccess
      errorMessage
      messages {
        user {
          id
          fullName
          name
          email
          phoneNumber
        }
        direction
        business {
          id
          name
          picture {
            path
          }
        }
        messageSource
        messageType
        message {
          user {
            id
            fullName
          }
          commentText
          createdTime
        }
        attachment {
          publicUrl
          fileName
          jsonData
          id
          contentType
        }
      }
    }

    getBusinessById(id: $businessId, showAll: true) {
      name
      businessCoupons {
        id
        code
        discountType
        summary
        title
        discount
        couponUrl
        imageUrl
      }
      businessGoogleForms {
        editFormUrl
        viewFormUrl
        formId
        formTitle
        responseCount
        id
        googleFormPermissions {
          email
          id
        }
      }
      messaging {
        messagingAllowed
        consumerInitiationAllowed
      }
      picture {
        path
      }
    }
  }
`;

export const GET_BUSINESS_BY_ID = gql`
  query GetBusinessById($id: Int!) {
  getBusinessById(id: $id) {
    businessSubscription {
      subscriptionPlan {
        features {
          genericNumberMessagingAllowed
        }
      }
    }
  }
}
`;