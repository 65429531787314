export default class Constants {
  static APPOINTMENT_ITEM_NAME = 'AppointmentItem';
  static BOOK_BUSINESS_URL = 'BookBusinessUrlItem';
  static BOOKED_STATUS_NAME = 'BOOKED';
  static CHECKED_IN_STATUS_NAME = 'CHECKEDIN';
  static CONFIRMED_STATUS_NAME = 'CONFIRMED';
  static CHECKED_IN_INPUT_NAME = 'CHECKED_IN';
  static CHECKED_IN_BY_BUSINESS_INPUT_NAME = 'CHECKED_IN_BY_BUSINESS';
  static COMPLETED_STATUS_NAME = 'COMPLETED';
  static PASSED_STATUS_NAME = 'PASSED';
  static CANCELLED_STATUS_NAME = 'CANCELLED';
  static DEFAULT_TIME_SLOTS_PAGE_SIZE = 8;
  static DEFAULT_SUBLEVEL_SLOTS_PAGE_SIZE = 5;
  static AppointmentView = {
    FLAT: 1,
    GROUP_BY_TIME: 2,
    GROUP_BY_SUB_LEVEL: 3,
  };
  static NO_APPOINTMENT_MESSAGE = 'No Appointment available today';
  static SELECT_SERVICE_MESSAGE =
    'Select a Service to see available time slots';
  static BUSINESS_MESSAGE_EVENT_NAME = 'BusinessMessageReceived';
  static USER_MESSAGE_EVENT_NAME = 'UserMessageReceived';
}

export const SIGNALR_EVENTS = {
  General: 0,
  UserMessageSent: 1,
  BusinessMessageSent: 2,
  AppointmentCreated: 3,
  AppointmentUpdated: 4,
  AppointmentConfirmed: 5,
  AppointmentCheckedIn: 6,
  AppointmentCancelled: 7,
  AppointmentCommentAdded: 8,
  AppointmentCreatedByAdmin: 9,
  AppointmentUpdatedByAdmin: 10,
  AppointmentConfirmedByAdmin: 11,
  AppointmentCheckedInByAdmin: 12,
  AppointmentCancelledByAdmin: 13,
  AppointmentCommentAddedByAdmin: 14,
  AppointmentEventTriggered: 15,
  AppointmentEventTriggeredByAdmin: 16,
};

export const SIGNALR_USER_TYPE = {
  Any: 0,
  ConsumerUser: 1,
  AdminUser: 2,
};

export const STATUS = {
  ACTIVE: 'ACTIVE',
  BOOKED: 'BOOKED',
  CONFIRMED: 'CONFIRMED',
  CHECKED_IN: 'CHECKEDIN',
  COMPLETED: 'COMPLETED',
  INCOMPLETE: 'INCOMPLETE',
  MISSED: 'MISSED',
  CANCELLED: 'CANCELLED',
  PASSED: 'PASSED',
  SERVING_NOW: 'SERVING_NOW',
  CHECKED_IN_BY_BUSINESS: 'CHECKED_IN_BY_BUSINESS',
};

export const STATUS_CAMPAIGN = {
  ACTIVE: 'ACTIVE',
  INCOMPLETE: 'INCOMPLETE',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
};

export const SORT_PRIORITY = {
  Active: 1,
  InComplete: 2,
  Completed: 3,
  Cancelled: 4
}

export const COLLECTION = {
  APPOINTMENTS: 'appointments',
  COMMENTS: 'comments',
  CHAT: 'chat',
  RATINGS: 'ratings',
  BUSINESSES: 'businesses',
  NOTIFICATIONS: 'notifications',
  CAMPAIGNS: 'campaigns'
};

// HTMl conversion
export const parseMarkdown = (text) => {
  // Replace multiple new lines with a single one
  text = text.replace(/\n{2,}/g, '\n\n');
  // Bold text
  let html = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  // Italic text
  html = html.replace(/\*([^*]+)\*/g, '<em>$1</em>');
  // Strikethrough text
  html = html.replace(/~~(.*?)~~/g, '<del>$1</del>');
  // Underlined text
  html = html.replace(/__([^_]+)__/g, '<u>$1</u>');
  // Lists (both unordered and ordered)
  html = html.replace(/(?:^|\n)\* (.*?)(?=\n|$)/g, '<ul><li>$1</li></ul>'); // Unordered lists
  html = html.replace(/(?:^|\n)\d+\. (.*?)(?=\n|$)/g, '<ol><li>$1</li></ol>'); // Ordered lists
  // Remove any extra closing tags for lists
  html = html.replace(/<\/ul>\s*<ul>/g, '');
  html = html.replace(/<\/ol>\s*<ol>/g, '');
  // Code blocks (multiline code)
  html = html.replace(/```([\s\S]*?)```/g, '<pre><code>$1</code></pre>');
  // Inline code
  html = html.replace(/`([^`]+)`/g, '<code>$1</code>');
  // Links
  html = html.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2">$1</a>');
  // Images
  html = html.replace(/\!\[([^\]]*)\]\(([^)]+)\)/g, '<img src="$2" alt="$1" />');
  // New lines (convert to <br> tags)
  html = html.replace(/\n/g, '<br/>');
  // Remove remaining Markdown characters
  html = html.replace(/(\*\*|__|\*|~~|`|\[\[|\]\]|\[|\]|!|`|\\)/g, '');
  // Ensure proper list closing tags
  html = html.replace(/<\/ul><ul>/g, '');
  html = html.replace(/<\/ol><ol>/g, '');
  // Return the cleaned and formatted HTML
  return html;
};