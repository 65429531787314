import React from 'react';
import { Section, Container, Columns, Column } from '@safelyq/bulma-ui-library';
import { ForgotPassword } from '../../components';

export const ManageForgotPassword = () => {
  return (
    <Section>
      <Container>
        <Columns centered>
          <Column size='4'>
            <ForgotPassword />
          </Column>
        </Columns>
      </Container>
    </Section>
  );
};
