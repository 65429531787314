export const INITIAL_DATA = {
  getUserProfile: {
    user: {
      userPreferences: {},
    },
  },
};

export const INITIAL_BUSINESS_DATA = {
  getUserLocations: [],
};