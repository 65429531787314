import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  Box,
  Title,
  Subtitle,
  Field,
  Control,
  TextInput,
  Button,
  Help,
} from '@safelyq/bulma-ui-library';
import { ConfirmationMessage } from '../../components';
import { useData } from './hooks';
import forgotPasswordImage from 'assets/forgot-password.svg';
import { APIPaths } from 'services/api-provider';

const VerticalCentered = styled.div.attrs(() => ({
  className:
    'is-flex is-flex-direction-column is-justify-content-center is-justify-items-center',
}))`
  width: 100%;
  height: 84vh;
`;

export const ForgotPassword = () => {
  const [value, setValue] = useState();

  const { push } = useHistory();

  const { loading: isLoading, error, isMailSend, onResetPassword } = useData({
    email: value,
  });

  const handleValueChange = ({ target: { value } }) => setValue(value);

  const handleGoBack = () => push(`${APIPaths.Gateway}/Account/Login`);

  if (isMailSend) return <ConfirmationMessage />;

  return (
    <VerticalCentered>
      <Box>
        <figure className='image is-3by2 mb-4'>
          <img src={forgotPasswordImage} alt='forgot-password' />
        </figure>
        <Title size='5' color='info'>
          <center>Forgot your password ?</center>
        </Title>
        <Subtitle size='6' color='grey-light'>
          <center>
            <small>
              Enter your e-mail address and we'll send you a link to reset your
              password
            </small>
          </center>
        </Subtitle>
        <Field>
          <Control>
            <TextInput
              placeholder='Email Address'
              onChange={handleValueChange}
            />
          </Control>
          <Help color='danger'>{error}</Help>
        </Field>
        <Field grouped alignment='right'>
          <Control>
            <Button size='small' onClick={handleGoBack}>
              Cancel
            </Button>
          </Control>
          <Control>
            <Button
              color='info'
              size='small'
              loading={isLoading}
              disabled={isLoading}
              onClick={onResetPassword}
            >
              Reset
            </Button>
          </Control>
        </Field>
      </Box>
      <small>
        <center>
          Never mind!{' '}
          <Link
            to={`${APIPaths.Gateway}/Account/Login`}
            className='is-underline has-text-weight-bold'
          >
            Take me back to login
          </Link>
        </center>
      </small>
    </VerticalCentered>
  );
};
