import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { CREATE_ORGANIZATION } from './requests';

export const useData = (isUpdate) => {
  const { push } = useHistory();

  const [onCreateOrganization, { loading: isLoading, error }] = useMutation(
    CREATE_ORGANIZATION,
    {
      onCompleted: ({ saveOrganization: { isSaved, errorMessage } }) => {
        if (isSaved) {
          if (isUpdate) {
            toast.success('Organization is updated successfully.');
            push('/superadmin/organization');
          }
          else {
            toast.success('Organization is created successfully.');
            push('/admin/manage-business');
          }
        } else toast.error(errorMessage || 'Organization creation failed.');
      },
      onError: () => toast.error('Organization creation failed.'),
    }
  );

  return { isLoading, error, onCreateOrganization };
};
