import React from 'react';
import { useParams } from 'react-router-dom';
import {
  Section,
  Container,
  Box,
} from '@safelyq/bulma-ui-library';
import { PromotionSetup } from '../../components';
import {
  BusinessProvider,
  useBusinessDispatch,
  useBusinessState,
} from './context';

export const ManagePromotions = () => {

  const { id = 0 } = useParams();

  return (
    <Section>
      <Container>
        <BusinessProvider>
          <Box shadow={Boolean(id)}>
            <PromotionSetup/>
          </Box>
        </BusinessProvider>
      </Container>
    </Section>
  );
};

export { useBusinessDispatch, useBusinessState };
