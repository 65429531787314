import styled from 'styled-components';

const RightArrow = styled.div.attrs(({ visible }) => ({
  className: `has-text-white is-hidden-mobile ${!visible && 'is-hidden'}`,
}))`
  position: absolute;
  right: -30px;
  margin-top: 2px;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%),
    0 0 0 1px rgb(10 10 10 / 2%);
  z-index: 1;
`;

export default RightArrow;
