import { gql } from '@apollo/client';

export const GET_BUSINESS_BY_ID = gql`
  query GetBusinessById($id: Int!) {
    getBusinessById(id: $id, showAll: true) {
      userRatings {
        id
        value
        ratingType
        modifiedTime
        isVerifiedVisitor
        reviewComment {
          commentText
          user {
            fullName
          }
        }
        reviewCommentResponse {
          id
          commentText
          modifiedTime
          user {
            fullName
          }
        }
      }
    }
  }
`;

export const MARK_AS_READ_MUTATION = gql`
 mutation MarkUnViewedReviewsCountAsViewed($businessId: Int!) {
    markUnViewedReviewsCountAsViewed(businessId: $businessId,) {
    errorMessage
    isSuccess
  }
  }
`