import React from 'react'
import { Button, Icon, } from '@safelyq/bulma-ui-library';

const AddQuestions = ({ handleAddQuestion, questions, handleInputChange, handleQuestionCancel, allowForm }) => {
    return (
        <>

            <div className='col-12 mb-4'>
                {questions.map((question, index) => {
                    return (
                        <div className='row mb-3'>
                            <div className='col-lg-1  is-flex is-align-items-center'>
                                <p>{index + 1}):</p>
                            </div>
                            <div className='col-lg-3'>
                                <label>Question</label>
                                <input onChange={(e) => handleInputChange(e, index)} value={question.name} name="name" className='input' />
                            </div>
                            <div className='col-lg-3 is-flex is-align-items-center'>
                                <input onChange={(e) => handleInputChange(e, index)} className='mr-2' checked={question.isRequired} name="isRequired" type="checkbox" />
                                <label>Is Required</label>
                            </div>
                            <div className='col-lg-3 is-flex is-align-items-center'>
                                <input onChange={(e) => handleInputChange(e, index)} className='mr-2' checked={question.isLongAnswer} name="isLongAnswer" type="checkbox" />
                                <label>long Answer</label>
                            </div>
                            <div className='col-lg-2'>
                                <Button color='info' size='small' type='button' onClick={() => handleQuestionCancel(index)}      >
                                    <Icon name='times' />
                                </Button>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className='col-12 mb-4'>
                <Button color='info' size='small' className='px-5' onClick={handleAddQuestion} disabled={!allowForm}>Add Custom Questions / Fields</Button>
            </div>
        </>

    )
}

export default AddQuestions
