import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { toast as showToast } from 'react-toastify';
import {
  SAVE_BUSINESS_CAMPAIGN,
  SAVE_BUSINESS_CAMPAIGN_DATA,
  CALCULATE_CAMPAIGN_AUDIENCE,
  GET_DATA,
  GET_BRAINTREE_CLIENT_TOKEN,
  BRAINTREE_TRANSACTION_REQUEST,
  GET_STRIPE_PUBLISHABLE_KEY,
  GET_CAMPAIGN_MARKETING_TEMPLATE,
  GET_SINGLE_CAMPAIGN,
} from './requests';
import { INITIAL_DATA } from '../../routes/ManageCampaign/constants';

export const useCreateCampaign = () => {
  const { push } = useHistory();
  const [campaignData, setCampaignData] = useState({});
  const { businessId } = useParams();
  const [onCreateCampaign, { loading: isLoading }] = useMutation(
    SAVE_BUSINESS_CAMPAIGN,
    {
      onCompleted: async ({ saveCampaign }) => {
        const { isSaved, errorMessage, isPaymentRequired } = saveCampaign;
        if (isSaved) {
          push(`/admin/view-campaign/${businessId}`);
          showToast.success('New Campaign has been created');
        } else if (isPaymentRequired) {
          setCampaignData(saveCampaign);
          showToast.success(errorMessage);
        } else showToast.error(errorMessage || 'Campaign creation failed');
      },
      onError: (error) => {
        showToast.error(error.message || "Could not run campaign");
      }
    }
  );
  return { isLoading, onCreateCampaign, campaignData, setCampaignData };
};

export const useSaveCampaign = () => {
  const { push } = useHistory();
  const { businessId } = useParams();
  const [onSaveCampaign, { loading: isSaving }] = useMutation(
    SAVE_BUSINESS_CAMPAIGN_DATA,
    {
      onCompleted: async ({ saveCampaignData }, response) => {

        const { isSaved, errorMessage } = saveCampaignData;

        if (isSaved) {
          push(`/admin/view-campaign/${businessId}`);
          showToast.success('Campaign Saved');
        } else showToast.error(errorMessage || 'Failed to Save Campaign');
      },
      onError: (error) => {
        // console.log("error:", error);
        if (error.networkError && error.networkError.statusCode === 413) {
          showToast.error("Image size is too large. Please upload a smaller image under 2mb.");
        } else {
          showToast.error(error.message || "Could not run campaign");
        }
      }
    }
  );
  return { isSaving, onSaveCampaign };
};

export const useCalculateAudiance = () => {
  const [data, setData] = useState({});
  const [onCalculateAudience, { loading: isCalculateLoading }] = useLazyQuery(
    CALCULATE_CAMPAIGN_AUDIENCE,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ getCalculatedCampaignUsers }) => {
        const { isSuccess, errorMessage, warningMessage } = getCalculatedCampaignUsers;
        setData(getCalculatedCampaignUsers);
        if (isSuccess) {
          showToast.success('Audience calculated successfully');
          warningMessage && showToast.warning(warningMessage, { autoClose: 6000 })
        } else showToast.error(errorMessage || 'Campaign creation failed');

      },
      onError: (e) => {
        showToast.error(
          'Error while calculating Audience'
        );
      },
    }
  );
  return { onCalculateAudience, isCalculateLoading, data };
};

export const useData = () => {

  const { businessId } = useParams();
  const id = parseInt(businessId);
  const {
    loading: isDataLoading,
    error,
    data = INITIAL_DATA,
    refetch,
  } = useQuery(GET_DATA, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: { id },
    onError: () => {
      showToast.error('There was a problem. Coupons could not be loaded.');
    }
  });
  const { getBusinessById, getSafelyQLegalTexts, getUserProfile } = data;

  return {
    isDataLoading,
    error,
    userEmail: getUserProfile?.user?.email,
    getSafelyQLegalTexts,
    data: getBusinessById,
    refetch,
  };
}

export const useBraintreePayment = (onBraintreeRequestCompleted) => {
  const isCustomerRequired = true;
  const {
    error,
    loading: isLoading,
    data,
  } = useQuery(GET_BRAINTREE_CLIENT_TOKEN, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: { isCustomerRequired }
  });

  const [
    invokeBraintreeTransactionRequest,
    { loading: invokingBraintreeRequest },
  ] = useMutation(BRAINTREE_TRANSACTION_REQUEST, {
    onCompleted: ({
      invokeBraintreeTransactionRequest: {
        isSuccess,
        errorMessage,
        validationErrors,
      },
    }) => {
      if (isSuccess) {
        showToast.success('Braintree transaction is completed successfully.');
      } else {
        showToast.error('Error Occurred: ' + errorMessage);
      }
      onBraintreeRequestCompleted();
    },
    onError: ({ message }) => {
      showToast.error(message || 'Something went wrong.');
      onBraintreeRequestCompleted();
    },
  });

  const { getBraintreeClientToken } = data || {};
  const { isSuccess, clientToken } = getBraintreeClientToken || {};

  return {
    isLoading,
    error,
    isSuccess,
    clientToken,
    invokingBraintreeRequest,
    invokeBraintreeTransactionRequest,
  };
};

export const useStripePayment = () => {
  const {
    error,
    loading: isLoading,
    data,
  } = useQuery(GET_STRIPE_PUBLISHABLE_KEY, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const { getStripePublishableKey } = data || {};
  const { isSuccess, publishableKey } = getStripePublishableKey || {};

  return {
    isLoading,
    error,
    isSuccess,
    publishableKey,
  };
};

export const useFile = () => {
  const [previewSource, setPreviewSource] = useState('');
  const [fileType, setFileType] = useState('');
  const [fileSize, setFileSize] = useState('');
  const [fileName, setFileName] = useState('');

  const handleUpload = (files) => {
    let file = files[0];

    const reader = new FileReader();

    reader.fileName = file?.name;

    reader.onload = () => {
      setPreviewSource(window.btoa(reader.result));

      let { type, name, size } = file || {};

      setFileType(type);

      setFileSize(size);

      setFileName(name);
    };
    reader.readAsBinaryString(file);
  };

  const handleRemove = () => {
    setFileType('');
    setFileSize('');
    setPreviewSource('');
  };

  return {
    onUpload: handleUpload,
    onRemove: handleRemove,
    path: previewSource,
    type: fileType,
    size: fileSize,
    name: fileName,
  };
};

export const useSingleCampaign = (id) => {

  const {
    loading: singleCampaignLoading,
    error: singleCampaignError,
    data: singleCampaignData,
    refetch: singleCampaignRefetch,
  } = useQuery(GET_SINGLE_CAMPAIGN, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    skip: !id, // Skip the query if id is falsy (null or undefined)
    variables: { id },
    onCompleted: ({ getCampaignById }) => {
    },
    onError: () => {
      showToast.error('There was a problem. Data could not be loaded.');
    }
  });

  return {
    singleCampaignLoading,
    singleCampaignError,
    singleCampaignData: singleCampaignData?.getCampaignById,
    singleCampaignRefetch

  }
}