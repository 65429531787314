import { gql } from '@apollo/client';

export const GET_SAFELYQLEGALTEXT = gql`
  query all {
    getSafelyQLegalTexts {
      legalTextFor
      legalText
    }
  }
`;

export const GET_BUSINESS_BY_ID = gql`
  query GetBusinessById($id: Int!) {
    getBusinessById(id: $id, showAll: true) {
      id
      canBusinessOptIn
      isGoogleReserve
      isBusinessAlreadyOptIn
      businessOptIn{
			 canBusinessOptIn
			 validCustomerSetup
			 validHourOfOperation
			 businessHasService
		}
    }
  }
`;

export const GOOGLE_RESERVE_OPT = gql`
  mutation ($businessInput: BusinessInput!) {
    saveBusinessOptIn(businessInput: $businessInput) {
      errorMessage
      isSaved
    }
  }
`;

export const GET_GOOGLE_RESERVE = gql`
  query getGoogle{
	getReserveWithGooglePage {
    reserveWithGoogle {
      messages {
        optInMessage
        optInTwiceMessage
        optOutMessage
      }
    }
  }
}
`;
