// hooks.js
import { useMutation } from '@apollo/client';
import { DELETE_ORGANIZATION } from './requests';

const useDeleteOrganization = () => {
    const [deleteOrganizationMutation, { data }] = useMutation(DELETE_ORGANIZATION);

    const deleteOrganization = async (organizationId) => {
        try {
            const result = await deleteOrganizationMutation({
                variables: { organizationId },
            });
            return result.data.deleteOrganization;
        } catch (error) {
            console.error('Error deleting organization:', error);
            // Handle errors appropriately
            throw error;
        }
    };

    return { deleteOrganization, data };
};
export default useDeleteOrganization;