export const INITIAL_BUSINESS_DATA = {
  getBusinessById: {
    services: [],
    safetyTags: [],
    slots: [],
    preCheckinInstructions: [],
    menu: [],
    picture: [],
  },
};

export const EMAIL_REGEX = /\S+@\S+\.\S+/;

export const PHONE_REGEX =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
