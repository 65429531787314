import { gql } from '@apollo/client';

export const GET_PRICING_PAGE_INFO = gql`
  query getPricing {
  getPricingPageInfo {
    pricing {
      title
      planTiers {
        heading
        planTierType
        paymentYear
        paymentMonth
        detailTitle
        buttonText
        feature1
        feature2
        feature3
        feature4
        feature5
				feature6
        feature7
      }
    }
    isSuccess
    errorMessage
  }
}
`;