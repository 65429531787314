import { arrayOf, bool, oneOf } from 'prop-types';
import { flatten } from 'lodash';
import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = []
    .concat(
      ['capitalized', 'lowercase', 'uppercase', 'italic']
        .map((name) => (props[name] ? `is-${name}` : null))
        .filter((className) => !!className)
    )
    .concat(
      ['color', 'alignment']
        .map((name) => (props[name] ? `has-text-${props[name]}` : null))
        .filter((className) => !!className)
    )
    .concat(
      ['weight']
        .map((name) => (props[name] ? `has-text-weight-${props[name]}` : null))
        .filter((className) => !!className)
    )
    .concat(
      ['family']
        .map((name) => (props[name] ? `is-family-${props[name]}` : null))
        .filter((className) => !!className)
    )
    .concat(
      ['size']
        .map((name) => (props[name] ? `is-size-${props[name]}` : null))
        .filter((className) => !!className)
    )
    .concat(
      ['alignments']
        .map((name) =>
          props[name]
            ? props[name].map((alignment) => `has-text-${alignment}`).join(' ')
            : null
        )
        .filter((className) => !!className)
    )
    .concat(
      ['sizes']
        .map((name) =>
          props[name]
            ? props[name].map((size) => `is-size-${size}`).join(' ')
            : null
        )
        .filter((className) => !!className)
    )
    .join(' ');

  return classNames;
};

const Paragraph = styled.p.attrs((props) => ({
  className: className`${props}`,
}))``;

Paragraph.propTypes = {
  color: oneOf([
    'white',
    'black',
    'light',
    'dark',
    'primary',
    'info',
    'link',
    'success',
    'warning',
    'danger',
    'black-bis',
    'black-ter',
    'grey-darker',
    'grey-dark',
    'grey',
    'grey-light',
    'grey-lighter',
    'white-ter',
    'white-bis',
  ]),
  size: oneOf(['1', '2', '3', '4', '5', '6']),
  alignment: oneOf(['left', 'right', 'justified', 'centered']),
  alignments: arrayOf(
    oneOf(
      flatten(
        ['left', 'right', 'justified', 'centered'].map((alignment) =>
          [
            'mobile',
            'tablet',
            'tablet-only',
            'touch',
            'desktop',
            'desktop-only',
            'widescreen',
            'widescreen-only',
            'fullhd',
          ].map((width) => `${alignment}-${width}`)
        )
      )
    )
  ),
  weight: oneOf(['light', 'normal', 'medium', 'semibold', 'bold']),
  family: oneOf(['sans-serif', 'monospace', 'primary', 'secondary', 'code']),
  capitalized: bool,
  lowercase: bool,
  uppercase: bool,
  italic: bool,
};

Paragraph.defaultProps = {
  color: undefined,
  alignment: undefined,
  alignments: undefined,
  weight: undefined,
  family: undefined,
  capitalized: undefined,
  lowercase: undefined,
  uppercase: undefined,
  italic: undefined,
};

export default Paragraph;
