import React from 'react';
import { Route } from 'react-router-dom';
import {
  Section,
  Container,
  Title,
  Columns,
  Column,
} from '@safelyq/bulma-ui-library';
import {
  ChangePassword,
  GeneralSetting,
  NotificationSetting,
  PhoneVerification,
  NavigationSection,
  LoadingSection,
  DeleteAccount,
  MarketingNotificationSetting,
} from '../../components';
import { useData, useAccount, useMarketing } from './hooks';

export const ManageSettings = () => {
  const { isLoading, refetch, data: profile } = useData();

  const { onProfileUpdate, isProfileLoading } = useAccount(refetch);

  const { onMarketingDetailsUpdate, isMarketingDetails } = useMarketing(refetch);

  const parameters = { ...profile, isProfileLoading, onProfileUpdate, onMarketingDetailsUpdate, isMarketingDetails };

  return (
    <div className='bscontainer'>
      <div className='row'>
        <div className='col-lg-12 mb-4 mt-4'>
          <Title size='5'>Account Settings</Title>
        </div>
        <div className='col-lg-3'>
          <NavigationSection />
        </div>
        <div className='col-lg-9'>
          <Route exact path='/account/setting' render={() => (<GeneralSetting />)} />
          <Route exact path='/account/verify-phone' render={() => (<PhoneVerification />)} />
          <Route exact path='/account/notifications' render={() => (<NotificationSetting />)} />
          <Route exact path='/account/marketing-notifications' render={() => (<MarketingNotificationSetting />)} />
          <Route exact path='/account/change-password' render={() => (<ChangePassword />)} />
          <Route exact path='/account/delete-account' render={(props) => <DeleteAccount {...props} {...parameters} />} />
        </div>
      </div>
    </div>
  )
}
