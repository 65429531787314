import { oneOf } from 'prop-types';
import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = ['']
    .concat(
      ['color']
        .map((name) => (props[name] ? `has-text-${props[name]}` : null))
        .filter((className) => !!className)
    )
    .concat(
      ['weight']
        .map((name) => (props[name] ? `has-text-weight-${props[name]}` : null))
        .filter((className) => !!className)
    )
    .join(' ');

  return classNames;
};

const Text = styled.span.attrs((props) => ({
  className: className`${props}`,
}))``;

Text.propTypes = {
  color: oneOf([
    'black',
    'white',
    'dark',
    'light',
    'primary',
    'link',
    'info',
    'success',
    'warning',
    'danger',
  ]),
  weight: oneOf(['light', 'normal', 'medium', 'semibold', 'bold']),
};

Text.defaultProps = {
  color: undefined,
  weight: undefined,
};

export default Text;
