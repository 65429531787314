import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { NoSearchFound } from "../NotFoundSection";
import { Table, Title, Button, Icon, Modal, Label } from "@safelyq/bulma-ui-library";
import { DELETE_BUSINESS, UPDATE_BUSINESS_DATA } from "../../../../admin-manage-business/requests"
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import UpdateNumbers from "../UpdateNumbers";
import { useGetBusiness } from "../../routes/ViewHome/hooks";
import { useSaveBusiness } from "./hooks";

const StyledList = styled.td.attrs(() => ({
  className: "py-3 pl-2",
}))``;

export const DeleteTableView = ({ refetch, locations, setLocations, setIsRefetching, businessStateInitial, setBusinessStateInitial }) => {
  const noLocationFound = locations?.length === 0;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [businessToUpdate, setBusinessToUpdate] = useState(null);
  const [allowLoading, setAllowLoading] = useState(false);
  const [initialBusinessData, setInitialBusinessData] = useState(null)

  const handleIsOpenModal = (props) => {
    const { name, id, organization, whatsAppPhone, smsPhone, bonus } = props;
    setBusinessToUpdate({ name, id, organization, whatsApp: whatsAppPhone, sms: smsPhone, bonus })
    // Set Intial state for comparison if the data is changed or not
    setInitialBusinessData({ whatsApp: whatsAppPhone, sms: smsPhone, bonus });
    setIsOpenModal(true);
  }

  const handleModalClose = () => {
    setIsOpenModal(false);
    setBusinessToUpdate(null);
  }


  // Delete Business
  const [deleteBusiness] = useMutation(DELETE_BUSINESS);

  // Function to delete the business
  const handleDelete = async (businessId) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this business?');
    if (confirmDelete) {
      setIsRefetching(true);
      try {
        const response = await deleteBusiness({
          variables: { businessId: businessId },
        });
        if (response.data.deleteBusiness.isSaved) {
          toast.success('Business Deleted successfully!');

          // set the filtered data to the state after delete
          const newLocations = businessStateInitial.filter(location => location.id !== businessId);
          // const newLocations = locations.filter(location => location.id !== businessId);
          setLocations(newLocations);
          setBusinessStateInitial(newLocations) // set the initial state to filter from
          // await refetch();
        } else {
          showErrorPopup(response.data.deleteBusiness.errorMessage || 'An error occurred while deleting.');
        }
      } catch (error) {
        showErrorPopup('An error occurred while deleting.');
      }
    }
    setIsRefetching(false);
  };

  // Update Business
  const { onUpdateBusinessData, savedLoading, savedError } = useSaveBusiness(refetch, setIsRefetching);

  // Function to Save Business
  const handleSaveBusiness = async () => {

    // set updated data to local state
    const newLocations = locations?.map((location) => {
      if (location?.id === businessToUpdate?.id) {
        const newLocation = {
          ...location,
          smsPhone: businessToUpdate?.sms,
          whatsAppPhone: businessToUpdate?.whatsApp,
          businessSubscription: {
            remainingBonus: businessToUpdate?.bonus
          }
        }
        return newLocation;
      }
      return location
    })

    setLocations(newLocations);

    await onUpdateBusinessData({
      variables:
      {
        businessInput:
        {
          id: businessToUpdate?.id,
          customDetails:
          {
            smsPhone: businessToUpdate?.sms,
            whatsAppPhone: businessToUpdate?.whatsApp,
            bonus: businessToUpdate?.bonus
          }
        }
      }
    })
    setAllowLoading(true);
  }

  const showErrorPopup = (errorMessage) => {
    toast.error(errorMessage);
  };

  // close the modal when savedLoading is set to false
  useEffect(() => {
    if (allowLoading && !savedLoading) {
      handleModalClose();
    }
  }, [savedLoading])

  // Props
  const updateNumbersProps = {
    isOpenModal,
    handleModalClose,
    businessToUpdate,
    handleSaveBusiness,
    savedLoading,
    initialBusinessData,
    locations,
    setBusinessToUpdate
  };


  return (
    <Fragment>
      <Table fullwidth hoverable>
        <thead>
          <tr>
            <th>#</th>
            <th className="pl-2">Business Name</th>
            <th>Business ID</th>
            <th>Organization</th>
            <th>Appointments</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody className="has-text-black">
          {noLocationFound ? (
            <tr>
              <td colSpan={4}>
                <NoSearchFound />
              </td>
            </tr>
          ) : (
            locations?.map(
              (
                { id, name, organization, appointments, whatsAppPhone, smsPhone, businessSubscription },
                index
              ) => (

                <tr className="py-3" key={`locations-${index}`}>
                  <StyledList>{index + 1}</StyledList>
                  <StyledList className="is-flex is-align-items-center">
                    <Button color="info" className="icon-button">
                      {/*<Icon name='code' />*/}
                      {String(name).charAt(0).toUpperCase()}
                    </Button>
                    <Link to={`/admin/appointments/${id}`} className="ml-2">
                      <Title size="6">{name}</Title>
                      {/*<Subtitle size='6'>{displayName}</Subtitle>*/}
                    </Link>
                  </StyledList>
                  <StyledList>{id}</StyledList>
                  <StyledList>{organization?.name ?? ""}</StyledList>
                  <StyledList>{appointments?.length ?? ""}</StyledList>
                  <StyledList>
                    <Button onClick={() => handleDelete(id)} className="is-danger is-light"><Icon name='trash ' /></Button>
                    <Button onClick={() => handleIsOpenModal({ name, id, organization, whatsAppPhone, smsPhone, bonus: businessSubscription?.remainingBonus })} className="is-info is-light ml-2"><Icon name='edit ' /></Button>
                  </StyledList>
                </tr>
              )
            )
          )}
        </tbody>
      </Table>

      {
        updateNumbersProps.isOpenModal && <UpdateNumbers {...updateNumbersProps} />
      }

    </Fragment>
  );
};
