import { bool, oneOf } from "prop-types";
import styled from "styled-components";

const className = (strings, props) => {
  const classNames = ["button"]
    .concat(
      [
        "fullwidth",
        "selected",
        "inverted",
        "outlined",
        "rounded",
        "hovered",
        "focused",
        "active",
        "loading",
        "static",
        "light",
      ]
        .map((name) => (props[name] ? `is-${name}` : null))
        .filter((name) => !!name)
    )
    .concat(
      ["color", "size"]
        .map((name) => (props[name] ? `is-${props[name]}` : null))
        .filter((name) => !!name)
    )
    .concat(
      ["position"]
        .map((name) => (props[name] ? `is-${props[name]}` : null))
        .filter((name) => !!name)
    )
    .join(" ");

  return classNames;
};

const Button = styled.button.attrs((props) => ({
  className: className`${props}`,
  loading: undefined,
}))``;

Button.propTypes = {
  fullwidth: bool,
  selected: bool,
  inverted: bool,
  outlined: bool,
  rounded: bool,
  hovered: bool,
  focused: bool,
  active: bool,
  loading: bool,
  static: bool,
  light: bool,
  position: oneOf(["right", "left", "top", "bottom"]),
  color: oneOf([
    "black",
    "white",
    "dark",
    "light",
    "text",
    "primary",
    "link",
    "info",
    "success",
    "warning",
    "danger",
  ]),
  size: oneOf(["small", "normal", "medium", "large"]),
};

Button.defaultProps = {
  fullwidth: undefined,
  selected: undefined,
  inverted: undefined,
  outlined: undefined,
  rounded: undefined,
  hovered: undefined,
  focused: undefined,
  active: undefined,
  loading: undefined,
  static: undefined,
  light: undefined,
  color: undefined,
  size: undefined,
  position: undefined,
};

export default Button;
