import { oneOf } from 'prop-types';
import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = ['content']
    .concat(
      ['size']
        .map((name) => (props[name] ? `is-${props[name]}` : null))
        .filter((name) => !!name)
    )
    .join(' ');

  return classNames;
};

const Content = styled.div.attrs((props) => ({
  className: className`${props}`,
}))``;

Content.propTypes = {
  size: oneOf(['small', 'normal', 'medium', 'large']),
};

Content.defaultProps = {
  size: undefined,
};

export default Content;
