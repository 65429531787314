import { useParams } from 'react-router-dom';
import { toast as showToast } from 'react-toastify';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { INITIAL_DATA, DUMMY_DATA } from './constants';
import { GET_ADMIN_NOTIFICATIONS, MARK_ADMIN_AS_VIEWED } from './requests';

export const useData = () => {
  const { businessId } = useParams();

  const [getAdminNotifications,
    { loading: isLoading, error, data = INITIAL_DATA, refetch }] = useLazyQuery(GET_ADMIN_NOTIFICATIONS, {
      fetchPolicy: 'no-cache',
    });

  // Send mutation for viewed
  const [onViewedNotifications, { loading: loadingViewed, error: errorViewed }] = useMutation(
    MARK_ADMIN_AS_VIEWED,
    {
      onCompleted: ({ markAdminNotificationsAsViewed }) => {
        // refetch admin notifications count as fetch latest mark as viewed
        markAdminNotificationsAsViewed?.isSuccess && getAdminNotifications({ variables: { businessId } })
        // markAdminNotificationsAsViewed?.isSuccess && refetch()
      }
    }
  );

  const {
    getAdminNotifications: { adminNotifications: notifications, unreadCount, unviewedCount },
  } = data;

  if (error) showToast.error('Something went wrong');

  return { getAdminNotifications, isLoading, error, refetch, data: notifications, unviewedCount, onViewedNotifications };
};
