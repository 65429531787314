import React from 'react';
import { Modal, Title, Text, Buttons, Button, Icon } from '@safelyq/bulma-ui-library';
import { STATUS } from 'constants/index';
import ReactTooltip from 'react-tooltip';
import Overflow from 'react-overflow-indicator';
import { Spin } from 'antd';

export const ConfirmationModal = ({ ...props }) => {
    const { isOpen, onCancel, action, onClose, data, cancelledMessage, isCancelLoading } = props;
    return (
        <Modal active={isOpen}>
            <Modal.Background />
            <Modal.Card className='modal-compaign'>
                <Overflow style={{ overflow: "scroll" }}>
                    <Overflow.Content className='is-flex is-flex-direction-column'>
                        <Modal.Card.Body style={{ overflow: "hidden" }} className='modal-compaign-body'>
                            {
                                cancelledMessage
                                    ?
                                    <>
                                        {/* Campaign Cancelled Message*/}
                                        <div className='my-4 is-flex is-justify-content-space-between is-align-items-center'>
                                            <Title size='5' className='m-0'>
                                                Campaign Cancelled: <span className='has-text-grey'> {data?.title} </span>
                                            </Title>
                                            <div>
                                                <Icon
                                                    name='times'
                                                    className='is-hoverable has-text-link'
                                                    onClick={onClose}
                                                />
                                            </div>
                                        </div>
                                        <div className='mb-4 has-text-justified'>
                                            <h5 className='has-text-weight-bold'>{cancelledMessage}</h5>

                                            <div class="buttons is-flex is-justify-content-end my-4">
                                                <button class="button is-secondary" onClick={onClose}>Okay</button>
                                            </div>
                                        </div>

                                    </>
                                    :
                                    <>
                                        {/* Cancel Campaign */}
                                        <div className='my-4 is-flex is-justify-content-space-between is-align-items-center'>
                                            <Title size='5' className='m-0'>
                                                Cancel Campaign: <span className='has-text-grey'> {data?.title} </span>
                                            </Title>
                                            <div>
                                                <Icon
                                                    name='times'
                                                    className='is-hoverable has-text-link'
                                                    onClick={onClose}
                                                />
                                            </div>
                                        </div>
                                        <div className='mb-4 has-text-justified'>
                                            <h5 className='has-text-weight-bold is-underline'>Cancel and Refund Rules (Policy)</h5>
                                            <ul style={{ listStyleType: 'disc', paddingLeft: "20px" }}>
                                                <li>
                                                    <b className='has-text-grey-dark is-size-6'>Active but no messages have been sent yet</b>
                                                    <p>This campaign has not sent any messages yet. Therefore, canceling the campaign at this time will allow a refund of the cost minus a 5% service fee.
                                                        The refund will be added to the bonus balance for future campaigs.</p>
                                                </li>
                                                <li>
                                                    <b className='has-text-grey-dark is-size-6'>Active, with all messages sent, but the campaign is not yet completed</b>
                                                    <p>This campaign has completed sending all messages and cannot be cancelled at this time.</p>
                                                </li>
                                                <li>
                                                    <b className='has-text-grey-dark is-size-6'>Active with messages in progress.</b>
                                                    <p>If some targeted date times have passed while others have not, we will calculate the refund amount, which will be added to the business's bonus balance.</p>
                                                </li>
                                            </ul>
                                            <p className='has-text-danger has-text-weight-semibold'>*  &nbsp; The actual refund amount will be provided after the campaign is cancelled.</p>
                                        </div>
                                        <Text className='mb-4'>
                                            Are your sure you want to
                                            <b> {action === STATUS.CANCELLED && "Cancel"} </b>this campaign?
                                        </Text>
                                        <div class="buttons is-flex is-justify-content-end my-4">
                                            <button class="button is-secondary" onClick={onClose}>Cancel</button>
                                            <Spin spinning={isCancelLoading}>
                                                <button class="button is-danger" onClick={onCancel}>Confirm</button>
                                            </Spin>
                                        </div>
                                    </>
                            }
                        </Modal.Card.Body>
                    </Overflow.Content>
                </Overflow>
            </Modal.Card>
            <ReactTooltip />
        </Modal>
    );
};
