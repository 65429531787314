import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC4yf564Y8--ZVV4PXyloZKpYUoDiNsmZY",
  authDomain: "safelyq-crashlytics.firebaseapp.com",
  projectId: "safelyq-crashlytics",
  storageBucket: "safelyq-crashlytics.appspot.com",
  messagingSenderId: "347896043072",
  appId: "1:347896043072:web:3a2ad40f8bbcd582d831a5",
  measurementId: "G-FMGF5D03N7"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();

export default firestore;