import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { SAVE_BUSINESS_RATING_RESPONSE } from './requests';

export const useData = (refetch) => {
  const [onSaveBusinessRatingResponse, { loading: isLoading }] = useMutation(
    SAVE_BUSINESS_RATING_RESPONSE,
    {
      onCompleted: ({
        saveBusinessRatingResponse: { errorMessage, isSaved },
      }) => {
        if (isSaved) {
          toast.success('You response is submitted');
          refetch();
        } else toast.error(errorMessage || 'Something went wrong');
      },
      onError: () => toast.error('Something went wrong'),
    }
  );

  return { onSaveBusinessRatingResponse, isLoading };
};
