import React, { Fragment, useState } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import { isEqual } from 'lodash';
import {
  Content,
  Paragraph,
  Icon,
  Buttons,
  Button,
  Text,
  Title,
  Column,
} from '@safelyq/bulma-ui-library';
import { useData } from './hooks';

export const rolesEnum = {
  NOT_FOUND: 'not-found',
  SELECTION: 'selection',
};

export const AvailableSlots = ({ ...props }) => {
  const {
    service,
    serviceProvider,
    isAnySubLevel,
    date,
    slot: selectedSlot,
    timeZone,
    currentSlot,
    onChangeSlot,
    adults,
  } = props;

  const [items, setItems] = useState(20);

  const {
    isLoading,
    error,
    data: slots,
  } = useData({
    service,
    serviceProvider,
    date,
    currentSlot,
    adults,
  });

  const isSlotsAvailable = slots?.length > 0;

  const handleSlotChange = (slot) => onChangeSlot(slot);

  const handleViewMore = () => setItems(items + 20);

  if (!service || !date || (isAnySubLevel && !serviceProvider)) return <span />;

  if (isLoading) return <LoadingSection />;

  if (error) return <ErrorSection />;

  if (!isSlotsAvailable) return <NotFoundSection role={rolesEnum.NOT_FOUND} />;

  return (
    <Fragment>
      <Column size='12'>
        <Title size='6' color='info' className='mb-4'>
          <i>Available Slots</i>{' '}
          <small className='has-text-grey-light'>({timeZone})</small>
        </Title>
        <hr className='m-0' />
      </Column>
      <Column size='12'>
        <Buttons size='small'>
          {slots
            .slice(0, items)
            .map(({ startTimeOnly, startTime, endTime }, index) => (
              <Button
                light={!isEqual(startTimeOnly, selectedSlot?.startTimeOnly)}
                color={'info'}
                key={`slot-${index}`}
                className='slot-btn'
                onClick={() =>
                  handleSlotChange({ startTime, startTimeOnly, endTime })
                }
              >
                <Text>{startTimeOnly}</Text>
              </Button>
            ))}
        </Buttons>
      </Column>
      <Column size='12' className='is-flex is-justify-content-center'>
        {items < slots.length && (
          <Text
            size='6'
            weight='bold'
            color='info'
            onClick={handleViewMore}
            className='is-hoverable'
          >
            View more slots <Icon name='angle-down' />
          </Text>
        )}
      </Column>
    </Fragment>
  );
};

const ErrorSection = () => {
  return (
    <Column size='12'>
      <Content className='py-5'>
        <Paragraph alignment='centered' weight='bold' color='grey-light'>
          <Icon name='exclamation-triangle' />
          Something went wrong
        </Paragraph>
      </Content>
    </Column>
  );
};

const LoadingSection = () => {
  return (
    <Column size='12'>
      <Content className='py-5'>
        <Paragraph alignment='centered'>
          <PulseLoader size={7} />
        </Paragraph>
      </Content>
    </Column>
  );
};

const NotFoundSection = ({ role }) => {
  return (
    <Column size='12'>
      <Content className='py-5'>
        <Paragraph alignment='centered' weight='bold' color='grey-light'>
          {role === rolesEnum.SELECTION &&
            'Select a Service to see available time slots'}
          {role === rolesEnum.NOT_FOUND && 'No slots available'}
        </Paragraph>
      </Content>
    </Column>
  );
};
