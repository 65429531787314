import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { UPDATE_BUSINESS_COUPON } from './requests';

export const useData = () => {
  const [onUpdateBusinessCoupon, { loading: isLoading, error }] = useMutation(
    UPDATE_BUSINESS_COUPON,
    {
      onCompleted: ({ saveBusinessCoupon: { isSaved, errorMessage } }) => {
        if (!isSaved) toast.error(errorMessage || 'Coupon activation failed.');
        else toast.success('Coupon status has been changed successfully');
      },
      onError: () => toast.error('Coupon activation failed.'),
    }
  );

  return { isLoading, error, onUpdateBusinessCoupon };
};
