export const INITIAL_DATA = {
  getBusinessAppointments: [],
};

export const statusColorsEnum = {
  Booked: 'warning',
  Passed: 'danger',
  Cancelled: 'danger',
  Confirmed: 'warning',
  Completed: 'warning',
  CheckedIn: 'info',
};
