import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Buttons, Button, Icon, Container } from '@safelyq/bulma-ui-library';
import { APIPaths } from 'services/api-provider';

const StyledNav = styled.nav.attrs(() => ({
  className: 'navbar',
}))`
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
`;

export const UserNavbar = ({ ...props }) => {
  const [toggle, setToggle] = useState(false);

  const { isLoggedIn } = props;

  const handleToggle = () => setToggle(!toggle);

  const handleMenuClose = () => setToggle(false);

  return (
    <StyledNav role='navigation' aria-label='main navigation'>
      <div className='navbar-brand' style={{ width: '100%' }}>
        <Link to='/' className='navbar-item' onClick={handleMenuClose}>
          <img src='/images/logo.png' alt='logo' />
        </Link>
        {!isLoggedIn ? <AuthButtons {...props} /> : <NavigationButtons />}
        <a
          role='button'
          className={`navbar-burger is-hidden-desktop burger m-0 ${
            toggle && 'is-active'
          }`}
          aria-label='menu'
          aria-expanded='false'
          data-target='mainNavbar'
          onClick={handleToggle}
        >
          <span aria-hidden='true' />
          <span aria-hidden='true' />
          <span aria-hidden='true' />
        </a>
      </div>
    </StyledNav>
  );
};

const AuthButtons = ({ ...props }) => {
  const { login } = props;

  const attemptLogin = useCallback(
    async (e) => {
      e.preventDefault();

      await login();
    },
    [login]
  );

  const attemptRegister = () => {
    //This URL needs to be change
    window.location.href = `${APIPaths.Gateway}/Account/Register`;
  };

  return (
    <div className='navbar-item pl-0' style={{ marginLeft: 'auto' }}>
      <Buttons>
        <Button size='small' onClick={attemptRegister}>
          Sign Up
        </Button>
        <Button size='small' color='primary' onClick={attemptLogin}>
          Sign In
        </Button>
      </Buttons>
    </div>
  );
};

const NavigationButtons = ({ ...props }) => {
  return (
    <>
      <Link
        to='/search'
        className='navbar-item pl-0'
        style={{ marginLeft: 'auto' }}
      >
        <Button size='small' className='button-icon'>
          <Icon name='search' />
        </Button>
      </Link>
      <Link to='/favorites' className='navbar-item pl-0'>
        <Button size='small' className='button-icon'>
          <Icon name='heart' />
        </Button>
      </Link>
      <Link to='/notifications' className='navbar-item pl-0'>
        <Button size='small' className='button-icon'>
          <Icon name='bell' />
        </Button>
      </Link>
      <div className='navbar-item has-dropdown is-hoverable '>
        <a
          className='navbar-link'
          href='https://bulma.io/documentation/overview/start/'
        >
          Docs
        </a>
        <div className='navbar-dropdown is-boxed is-right'>
          <a
            className='navbar-item'
            href='https://bulma.io/documentation/overview/start/'
          >
            Overview
          </a>
          <a
            className='navbar-item'
            href='https://bulma.io/documentation/overview/modifiers/'
          >
            Modifiers
          </a>
          <a
            className='navbar-item'
            href='https://bulma.io/documentation/columns/basics/'
          >
            Columns
          </a>
          <a
            className='navbar-item'
            href='https://bulma.io/documentation/layout/container/'
          >
            Layout
          </a>
          <a
            className='navbar-item'
            href='https://bulma.io/documentation/form/general/'
          >
            Form
          </a>
          <hr className='navbar-divider' />
          <a
            className='navbar-item'
            href='https://bulma.io/documentation/elements/box/'
          >
            Elements
          </a>
          <a
            className='navbar-item is-active'
            href='https://bulma.io/documentation/components/breadcrumb/'
          >
            Components
          </a>
        </div>
      </div>
    </>
  );
};

// <div className='navbar-item pl-0'>
//   <img
//     src={'/images/dummy-profile.jpg'}
//     alt='profile'
//     className='profile-avatar'
//   />
//         </div>
