export const STATUS = {
  BOOKED: 'BOOKED',
  CONFIRMED: 'CONFIRMED',
  CHECKEDIN: 'CHECKEDIN',
  CHECKED_IN: 'CHECKED_IN',
  COMPLETED: 'COMPLETED',
  MISSED: 'MISSED',
  CANCELLED: 'CANCELLED',
  PASSED: 'PASSED',
  SERVING_NOW: 'SERVING_NOW',
  CHECKED_IN_BY_BUSINESS: 'CHECKED_IN_BY_BUSINESS',
};
