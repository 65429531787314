import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Button, } from '@safelyq/bulma-ui-library';
import { getFinalData, parsedServiceHolidays, parsedBusinessHolidays } from './ServiceHolidaySchedule.helpers';
import { TableView } from "./TableView/TableView"
import { TileView } from "./TileView/TileView"
import { DatePicker } from 'antd';
import { handleBusinessHolidayRemove } from '../HolidaySchedule/HolidaySchedule.helpers';
import { useDeleteBusinessHoliday } from '../HolidaySchedule/hooks/useDeleteBusinessHoliday';
import { useSaveBusinessHoliday } from '../HolidaySchedule/hooks/useSaveBusinessHoliday';
import { ApptConfirmPopup } from '../HolidaySchedule/components/ApptConfirmPopup';
const uuid = () => '_' + Math.random().toString(36).substr(2, 9);
export const ServiceHolidaySchedule = ({ refetch, serviceHolidays, businessHolidays, serviceId, businessId, onSubmit, isLoading }) => {



    const [allServiceHolidays, setAllServiceHolidays] = useState([])
    const [allBusinessHolidays, setAllBusinessHolidays] = useState([])
    const [hasError, setHasError] = useState(false)
    const [dateValue, setDateValue] = useState(null)
    const [dateStatus, setDateStatus] = useState(false)
    const [hasAppointment, setHasAppointment] = useState(false);
    const [newHolidayAdd, setNewHolidayAdd] = useState(false)
    const [shadowServiceHolidays, setShadowServiceHolidays] = useState([])

    const { onBusinessHolidayDelete } = useDeleteBusinessHoliday(refetch)
    const { onSaveBusinessHoliday, isSaveLoading } = useSaveBusinessHoliday(refetch)

    const handleInputChange = (e, index, date) => {

        let { name, value } = e.target;
        const updatedInputs = [...allServiceHolidays];
        if (name === "isOpen" || name === "hasBreak") {
            if (name === "isOpen") {
                if (e.target.checked) {
                    updatedInputs[index][`openingTimeError`] = "Enter Opening Time"
                    updatedInputs[index][`closingTimeError`] = "Enter Closing Time"
                }
                else {
                    updatedInputs[index][`openingTime`] = ""
                    updatedInputs[index][`closingTime`] = ""
                    updatedInputs[index][`hasBreak`] = false
                    updatedInputs[index][`breakStartTime`] = ""
                    updatedInputs[index][`breakDuration`] = ""
                    updatedInputs[index][`openingTimeError`] = ""
                    updatedInputs[index][`closingTimeError`] = ""
                }
            }
            else {
                if (e.target.checked) {
                    updatedInputs[index][`breakStartTimeError`] = "Enter Break start Time"
                    updatedInputs[index][`breakDurationError`] = "Enter duration"
                }
                else {
                    updatedInputs[index][`breakStartTime`] = ""
                    updatedInputs[index][`breakDuration`] = ""
                    updatedInputs[index][`breakStartTimeError`] = ""
                    updatedInputs[index][`breakDurationError`] = ""
                }
            }
            updatedInputs[index][name] = e.target.checked;
        }
        else if (name === "openingTime" || name === "closingTime") {

            const isDateExistInBusiness = allBusinessHolidays.find((holiday) => holiday.date === date)
            if (isDateExistInBusiness) {
                if (isDateExistInBusiness.isOpen) {
                    const business_openingTime = isDateExistInBusiness.date + ' ' + isDateExistInBusiness.openingTime;
                    const business_closingTime = isDateExistInBusiness.date + ' ' + isDateExistInBusiness.closingTime;
                    const service_Time = date + ' ' + value;
                    const isBetweenTime = moment(service_Time).isBetween(business_openingTime, business_closingTime)
                    if (name === "closingTime") {
                        if (service_Time === business_closingTime) {
                            updatedInputs[index][`${name}Error`] = ""
                        }
                        else if (isBetweenTime) {
                            updatedInputs[index][`${name}Error`] = ""
                        } else {
                            updatedInputs[index][`${name}Error`] = "Incorrect Time"
                            toast.error(`Business opening and closing time is from ${moment(isDateExistInBusiness.openingTime, "hh mm").format('LT')} to  ${moment(isDateExistInBusiness.closingTime, "hh mm").format('LT')}. Please select time within business opening and closing time`);
                        }
                    }
                    else {
                        if (service_Time === business_openingTime) {
                            updatedInputs[index][`${name}Error`] = ""
                        }
                        else if (isBetweenTime) {
                            updatedInputs[index][`${name}Error`] = ""
                        } else {
                            updatedInputs[index][`${name}Error`] = " Incorrect Time"
                            toast.error(`Business opening and closing time is from ${moment(isDateExistInBusiness.openingTime, "hh mm").format('LT')} to  ${moment(isDateExistInBusiness.closingTime, "hh mm").format('LT')}. Please select time within business opening and closing time`);
                        }
                    }
                }
                else {

                }
            }
            else {
                if (name === "closingTime") {
                    const service_openingTime = date + ' ' + allServiceHolidays[index].openingTime;
                    const least_closingTime = date + ' 23:59';
                    const service_closingTime = date + ' ' + value;
                    const isBetweenOpeningTimeTime = moment(service_closingTime).isBetween(service_openingTime, least_closingTime);
                    if (!isBetweenOpeningTimeTime) {
                        toast.error(`Business closing time must be between  from ${moment(allServiceHolidays[index].openingTime, "hh mm").format('LT')} to  ${moment('23:59', "hh mm").format('LT')}`);
                        updatedInputs[index][`closingTimeError`] = "Invalid Closing Time"
                    }
                    else {
                        updatedInputs[index][`closingTimeError`] = ""
                    }
                }
                else {
                    if (value) {
                        updatedInputs[index][`${name}Error`] = ""
                    }

                }
            }

            updatedInputs[index][`hasBreak`] = false
            updatedInputs[index][`breakStartTime`] = ""
            updatedInputs[index][`breakDuration`] = ""
            updatedInputs[index][`breakStartTimeError`] = ""
            updatedInputs[index][`breakDurationError`] = ""
            updatedInputs[index][name] = value;
        }
        else if (name === 'breakStartTime') {
            const business_openingTime = allServiceHolidays[index].date + ' ' + allServiceHolidays[index].openingTime;
            const business_closingTime = allServiceHolidays[index].date + ' ' + allServiceHolidays[index].closingTime;
            const break_Start_Time = allServiceHolidays[index].date + ' ' + value;
            const isBreakBetween = moment(break_Start_Time).isBetween(business_openingTime, business_closingTime);
            if (isBreakBetween) {
                updatedInputs[index][`${name}Error`] = ""
            } else {
                updatedInputs[index][`${name}Error`] = "Incorrect Break Start Time"
                toast.error(`Break start time should be between ${moment(allServiceHolidays[index].openingTime, "hh mm").format('LT')} and ${moment(allServiceHolidays[index].closingTime, "hh mm").format('LT')} `)
            }
            updatedInputs[index][`breakDuration`] = ""
            updatedInputs[index][name] = value
        }
        else if (name === "breakDuration") {

            const business_closingTime = allServiceHolidays[index].date + ' ' + allServiceHolidays[index].closingTime;
            const break_Start_Time = allServiceHolidays[index].date + ' ' + allServiceHolidays[index].breakStartTime;
            const timeInMilliSec = moment(business_closingTime).diff(moment(break_Start_Time))
            const timInMinutes = moment.duration(timeInMilliSec).asMinutes()
            if (value > timInMinutes) {
                updatedInputs[index][`${name}Error`] = "Incorrect Break Durantion"
                toast.error(`You can take break maximum of ${timInMinutes} minutes `)
            }
            else {
                updatedInputs[index][`${name}Error`] = ""
            }
            updatedInputs[index][name] = value;
        }
        else if (name === "date") {
            const isDateExist = allServiceHolidays.some((holiday) => holiday.date === value)
            if (isDateExist) {
                toast.error(`Same Date cannot be added multiple times`)
                return
            }
            else {
                updatedInputs[index][name] = value;
            }

        }
        setAllServiceHolidays(updatedInputs)
    }
    const handleBlur = () => {
        setDateStatus(false)
        setDateValue(null)
    }
    const onAddNewHoliday = (date, dateString) => {
        setDateValue(null)
        setDateStatus(false)
        const commingDate = moment(date.format('YYYY-MM-DD'));
        const TodayDate = moment(); // Current date
        if (commingDate.isBefore(TodayDate)) {
            toast.error(`Cannot add old date`)
            return
        }

        const isDateExist = allServiceHolidays.some((holiday) => holiday.date === date.format('YYYY-MM-DD'))
        if (isDateExist) {
            toast.error(`Same Date cannot be added multiple times`)
            return
        }
        const isDateExistInBusiness = allBusinessHolidays.find((holiday) => holiday.date === date.format('YYYY-MM-DD'))
        let businessStatus = ""
        let closingTime = ""
        let openingTime = ""
        let isOpen = false
        if (isDateExistInBusiness) {
            if (isDateExistInBusiness.hasOwnProperty("date")) {
                businessStatus = isDateExistInBusiness.isOpen ? "partialopen" : "fulldayoff"
                openingTime = isDateExistInBusiness.isOpen ? isDateExistInBusiness.openingTime : ""
                closingTime = isDateExistInBusiness.isOpen ? isDateExistInBusiness.closingTime : ""
                isOpen = isDateExistInBusiness.isOpen ? true : false
            }
        }

        const obj = {
            isOpen: isOpen,
            id: uuid(),
            date: date.format('YYYY-MM-DD'),
            specialHolidayId1: undefined,
            specialHolidayId2: undefined,
            openingTimeError: "",
            closingTimeError: "",
            hasBreakError: "",
            breakStartTimeError: "",
            hasBreak: false,
            breakStartTime: "",
            breakDuration: "",
            openingTime: openingTime,
            businessOpeningTime: "",
            closingTime: closingTime,
            businessClosingTime: "",
            businessStatus: businessStatus,
            editMode: true
        }
        setAllServiceHolidays((prev) => ([...prev, obj]))
        setNewHolidayAdd(true)
    };
    const onDeleteholiday = async (id, date) => {
        const idexist = serviceHolidays.some((el) => el.id === id)
        if (idexist) {
            if (!window.confirm('Are you sure you want to remove slot?')) return;
            try {
                await onBusinessHolidayDelete({ variables: { businessId: +serviceId, date: date, }, });
                const updated_Holidays = handleBusinessHolidayRemove([{ id }], allServiceHolidays)
                setAllServiceHolidays(updated_Holidays)
            } catch (error) {
                toast.error(error);
            }
        }
        else {
            const updated_Holidays = handleBusinessHolidayRemove([{ id }], allServiceHolidays)
            setAllServiceHolidays(updated_Holidays)
            setNewHolidayAdd(false)
        }
    }
    const onCancelAppointment = () => {
        const removedHolidays = allServiceHolidays.filter((item) => {
            return !serviceHolidays.some((el) => el.id === item.id);
        });
        const updated_Holidays = handleBusinessHolidayRemove(removedHolidays, allServiceHolidays)
        setAllServiceHolidays(updated_Holidays)
        setHasAppointment(false);
    }
    const handleSave = async (cancelAppointtment) => {
        const newSlots = getFinalData(allServiceHolidays)
        try {
            const response = await onSaveBusinessHoliday({
                variables: {
                    businessInput: {
                        id: serviceId,
                        parentId: businessId,
                        businessHolidays: newSlots,
                        organizationId: -1,
                        isCancelAppointment: cancelAppointtment
                    },
                },
            })
            if (response.data.saveBusinessHoliday.isSaved) {
                toast.success("Service Holiday saved successfully");
                setHasAppointment(false)
                refetch()
            } else {
                if (response.data.saveBusinessHoliday.isAppointment) {
                    setHasAppointment(true)
                    throw response.data.saveBusinessHoliday.errorMessage
                } else {
                    throw response.data.saveBusinessHoliday.errorMessage
                }
            }
        } catch (error) {
            toast.error(error);
        }

    }

    const handleEditMode = (index) => {
        const updatedInputs = [...allServiceHolidays];
        updatedInputs[index][`editMode`] = !allServiceHolidays[index].editMode
        setAllServiceHolidays(updatedInputs)
    }

    const handleCancel = () => {
        window.location.reload(false);
    }

    const string_shadowServiceHolidays = JSON.stringify(shadowServiceHolidays)
    const string_allServiceHolidays = JSON.stringify(allServiceHolidays)
    const saveBtnDisabled = string_shadowServiceHolidays === string_allServiceHolidays ? true : false

    useEffect(() => {
        setAllServiceHolidays(parsedServiceHolidays(serviceHolidays, businessHolidays))
        setShadowServiceHolidays(parsedServiceHolidays(serviceHolidays, businessHolidays))
        setAllBusinessHolidays(parsedBusinessHolidays(businessHolidays))
        setNewHolidayAdd(false)
    }, [serviceHolidays, businessHolidays])

    useEffect(() => {
        const status = allServiceHolidays.some((day) => day.openingTimeError || day.closingTimeError || day.breakStartTimeError || day.hasBreakError || day.breakDurationError)
        setHasError(status)
    }, [allServiceHolidays])


    return (
        <>
            {hasAppointment && <ApptConfirmPopup isOpen={hasAppointment} onOkClick={handleSave} onCancelClick={onCancelAppointment} />}
            <div className='row g-0 mb-4  '>
                <div className='col-lg-12 is-hidden-mobile'>
                    <TableView allServiceHolidays={allServiceHolidays} allBusinessHolidays={allBusinessHolidays} handleEditMode={handleEditMode} handleInputChange={handleInputChange} onDeleteholiday={onDeleteholiday} />
                </div>
                <div className='col-lg-12 is-hidden-tablet'>
                    <TileView allServiceHolidays={allServiceHolidays} handleInputChange={handleInputChange} onDeleteholiday={onDeleteholiday} />
                </div>
            </div>
            <div className='row'>
                <div className='col-12 has-text-right'  >
                    <Button color='info' loading={isSaveLoading} disabled={saveBtnDisabled ? true : hasError ? true : false} className='px-5 mb-4 mr-4' onClick={() => handleSave(false)} >Save  </Button>
                    <Button warning='info' disabled={saveBtnDisabled ? true : hasError ? true : false} className='px-5 mb-4' onClick={() => handleCancel()} >Cancel  </Button>
                    <div style={{ position: "relative" }}>
                        <DatePicker open={dateStatus} placement={"topLeft"} bordered={false} value={dateValue ? moment(dateValue, 'YYYY-MM-DD') : null} onChange={onAddNewHoliday} />
                        <Button disabled={newHolidayAdd} onBlur={handleBlur} style={{ position: "absolute", right: "3px" }} color='info' size='small' className='px-5 addnewholidayBtn' onClick={() => setDateStatus(!dateStatus)} >Add New Holiday</Button>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>

                </div>
            </div>

        </>

    )
}