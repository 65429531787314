import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = [
    'modal-card-body'
  ]
    .join(' ');

  return classNames;
};

const ModalCardBody = styled.section.attrs((props) => ({
  className: className`${props}`
}))``;

ModalCardBody.propTypes = {
};

ModalCardBody.defaultProps = {
};

export default ModalCardBody;
