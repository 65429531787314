import { useState } from 'react';

const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

const extensions = ['jpeg', 'png', 'gif', 'tiff', 'psd', 'jpg'];

const k = 1024;

export const useImagePicker = () => {
  const [previewSource, setPreviewSource] = useState('');
  const [fileType, setFileType] = useState('');
  const [fileSize, setFileSize] = useState('');
  const [fileName, setFileName] = useState('');

  const handleUpload = ({ target }) => {
    const { name: methodName, value } = target;

    let file = null;

    if (methodName === 'url') {
      const splashIndex = String(value).lastIndexOf('/');

      const extension = String(value).split('.').pop();

      const fileName = String(value).substring(
        splashIndex + 1,
        String(value).length
      );

      if (extensions.includes(extension)) setFileType('image');
      else setFileType(extension);

      setPreviewSource(value);

      setFileName(fileName);
      return;
    } else file = target.files[0];

    const reader = new FileReader();

    reader.onloadend = () => {
      setPreviewSource(reader.result);

      let { type, name, size } = file;

      const i = Math.floor(Math.log(size) / Math.log(k));

      size = parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];

      const extension = String(name).split('.').pop();

      const index = String(type).indexOf('/');

      type = String(type).substring(0, index);

      if (type === 'image') setFileType('image');
      else setFileType(extension);
      setFileSize(size);
      setFileName(name);
    };

    if (file) reader.readAsDataURL(file);
  };

  const handleRemove = () => {
    setFileType('');
    setFileSize('');
    setPreviewSource('');
  };

  return {
    base64String: previewSource,
    type: fileType,
    size: fileSize,
    name: fileName,
    isImageUploaded: Boolean(previewSource),
    onUpload: handleUpload,
    onRemove: handleRemove,
  };
};
