import React, { useState } from 'react';
import {
  Box,
  Icon,
  Title,
  Subtitle,
  Container,
  Switch,
  Level,
  Button,
  Text,
  Columns,
  Column,
} from '@safelyq/bulma-ui-library';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { isEqual } from 'lodash';

const styles = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexGrow: 1,
};

export const AdminNotificationSetting = ({ ...props }) => {

  const { getUserLocations, onSaveNotification } = props;

  return (
    <Box shadow={true}>
      <Title size='5'>
        <Icon name='bell' /> Business Notifications
      </Title>
      <Subtitle size='6' color='grey-light'>
        Configure notifications
      </Subtitle>
      <hr />
      <Container>
        <Accordion allowZeroExpanded={true}>
          {getUserLocations?.map(
            ({ id, name, businessWatcherInfo }, index) => {
              return (
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton className='accordion__button is-flex is-align-items-center'>
                      <div className='is-flex is-justify-content-space-between w-100'>
                        <span>{name}</span>
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <UpdateNotificationFlags
                      businessId={id}
                      businessWatcherInfo={businessWatcherInfo}
                      onSaveNotification={onSaveNotification}
                    />
                  </AccordionItemPanel>
                </AccordionItem>
              );
            }
          )}
        </Accordion>
      </Container>
    </Box>
  );
};

const UpdateNotificationFlags = ({ businessId, businessWatcherInfo, onSaveNotification }) => {

  const [businessNotification, setBusinessNotification] = useState(businessWatcherInfo);

  const handleChange = (name, value) => {
    setBusinessNotification({
      ...businessNotification,
      [name]: value
    })
  }

  const handleSaveChanges = () => {
    onSaveNotification({
      variables: {
        businessWatcherInput: {
          businessId: businessId,
          userType: businessNotification.userType,
          allowBusinessSms: businessNotification.allowBusinessSms,
          allowBusinessWhatsApp: businessNotification.allowBusinessWhatsApp,
          allowBusinessEmail: businessNotification.allowBusinessEmail,
          allowBusinessMessageSms: businessNotification.allowBusinessMessageSms,
          allowBusinessMessageEmail: businessNotification.allowBusinessMessageEmail,
          allowBusinessMessageWhatsApp: businessNotification.allowBusinessMessageWhatsApp,
          allowAppointmentSms: businessNotification.allowAppointmentSms,
          allowAppointmentEmail: businessNotification.allowAppointmentEmail,
          allowAppointmentWhatsApp: businessNotification.allowAppointmentWhatsApp,
          allowRatingSms: businessNotification.allowRatingSms,
          allowRatingEmail: businessNotification.allowRatingEmail,
          allowRatingWhatsApp: businessNotification.allowRatingWhatsApp
        },
      },
    });
  };

  return (
    <>
      {/* <Columns multiline>
        <BusinessNotificationFlags 
          businessNotification={businessNotification}
          handleChange={handleChange}
        />
      </Columns>
      <hr /> */}
      <Columns multiline>
        <Column size='12'>
          <Title size='5'>Appointment Notifications</Title>
        </Column>
        <BusinessAppointmentNotificationFlags
          businessNotification={businessNotification}
          handleChange={handleChange}
        />
      </Columns>
      <Columns multiline>
        <Column size='12'>
          <Title size='5'>Messages Notifications</Title>
        </Column>
        <BusinessMessageNotificationFlags
          businessNotification={businessNotification}
          handleChange={handleChange}
        />
      </Columns>
      <Columns multiline>
        <Column size='12'>
          <Title size='5'>Rating Notifications</Title>
        </Column>
        <BusinessRatingNotificationFlags
          businessNotification={businessNotification}
          handleChange={handleChange}
        />
      </Columns>
      <Button
        color='info'
        disabled={isEqual(businessWatcherInfo, businessNotification)}
        onClick={() => handleSaveChanges()}
      >
        Save Changes
      </Button>
    </>
  )
}

const BusinessNotificationFlags = ({ businessNotification, handleChange }) => {

  return (
    <>
      <Column size='12'>
        <Level>
          <Level style={styles}>
            <Text>All Email notifications</Text>
            <Level>
              <Switch
                value={businessNotification.allowBusinessEmail}
                onValueChange={(value) => handleChange('allowBusinessEmail', value)}
              />
            </Level>
          </Level>
        </Level>
      </Column>
      <Column size='12'>
        <Level>
          <Level style={styles}>
            <Text>All WhatsApp notifications</Text>
            <Level>
              <Switch
                value={businessNotification.allowBusinessWhatsApp}
                onValueChange={(value) => handleChange('allowBusinessWhatsApp', value)}
              />
            </Level>
          </Level>
        </Level>
      </Column>
      <Column size='12'>
        <Level>
          <Level style={styles}>
            <Text>All SMS notifications</Text>
            <Level>
              <Switch
                value={businessNotification.allowBusinessSms}
                onValueChange={(value) => handleChange('allowBusinessSms', value)}
              />
            </Level>
          </Level>
        </Level>
      </Column>
    </>
  )
}

const BusinessAppointmentNotificationFlags = ({ businessNotification, handleChange }) => {

  return (
    <>
      <Column size='12'>
        <Level>
          <Level style={styles}>
            <Text>Email</Text>
            <Level>
              <Switch
                value={businessNotification.allowAppointmentEmail}
                onValueChange={(value) => handleChange('allowAppointmentEmail', value)}
              />
            </Level>
          </Level>
        </Level>
      </Column>
      <Column size='12'>
        <Level>
          <Level style={styles}>
            <Text>WhatsApp</Text>
            <Level>
              <Switch
                value={businessNotification.allowAppointmentWhatsApp}
                onValueChange={(value) => handleChange('allowAppointmentWhatsApp', value)}
              />
            </Level>
          </Level>
        </Level>
      </Column>
      <Column size='12'>
        <Level>
          <Level style={styles}>
            <Text>SMS</Text>
            <Level>
              <Switch
                value={businessNotification.allowAppointmentSms}
                onValueChange={(value) => handleChange('allowAppointmentSms', value)}
              />
            </Level>
          </Level>
        </Level>
      </Column>
    </>
  )
}

const BusinessRatingNotificationFlags = ({ businessNotification, handleChange }) => {

  return (
    <>
      <Column size='12'>
        <Level>
          <Level style={styles}>
            <Text>Email</Text>
            <Level>
              <Switch
                value={businessNotification.allowRatingEmail}
                onValueChange={(value) => handleChange('allowRatingEmail', value)}
              />
            </Level>
          </Level>
        </Level>
      </Column>
      <Column size='12'>
        <Level>
          <Level style={styles}>
            <Text>WhatsApp</Text>
            <Level>
              <Switch
                value={businessNotification.allowRatingWhatsApp}
                onValueChange={(value) => handleChange('allowRatingWhatsApp', value)}
              />
            </Level>
          </Level>
        </Level>
      </Column>
      <Column size='12'>
        <Level>
          <Level style={styles}>
            <Text>SMS</Text>
            <Level>
              <Switch
                value={businessNotification.allowRatingSms}
                onValueChange={(value) => handleChange('allowRatingSms', value)}
              />
            </Level>
          </Level>
        </Level>
      </Column>
    </>
  )
}

const BusinessMessageNotificationFlags = ({ businessNotification, handleChange }) => {

  return (
    <>
      <Column size='12'>
        <Level>
          <Level style={styles}>
            <Text>Email</Text>
            <Level>
              <Switch
                value={businessNotification.allowBusinessMessageEmail}
                onValueChange={(value) => handleChange('allowBusinessMessageEmail', value)}
              />
            </Level>
          </Level>
        </Level>
      </Column>
      <Column size='12'>
        <Level>
          <Level style={styles}>
            <Text>WhatsApp</Text>
            <Level>
              <Switch
                value={businessNotification.allowBusinessMessageWhatsApp}
                onValueChange={(value) => handleChange('allowBusinessMessageWhatsApp', value)}
              />
            </Level>
          </Level>
        </Level>
      </Column>
      <Column size='12'>
        <Level>
          <Level style={styles}>
            <Text>SMS</Text>
            <Level>
              <Switch
                value={businessNotification.allowBusinessMessageSms}
                onValueChange={(value) => handleChange('allowBusinessMessageSms', value)}
              />
            </Level>
          </Level>
        </Level>
      </Column>
    </>
  )
}
