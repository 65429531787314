import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { SAVE_IMAGES, DELETE_BUSINESS_IMAGE } from '../../requests';

export const useData = (refetch) => {
  const [onSaveImages, { loading: isLoading, error }] = useMutation(
    SAVE_IMAGES,
    {
      onCompleted: ({ uploadBusinessImages: { error, isSuccess } }) => {
        if (isSuccess) {
          refetch();
          toast.success('Images uploaded successfully');
        } else toast.error(error || 'Images uploading failed.');
      },

      onError: (error) => {
        // console.log("error:", error);
        // if (error.networkError && error.networkError.statusCode === 413) {
        //   toast.error("Image size is too large. Please upload a smaller image under 2mb.");
        // } else {
        toast.error(error.message || "Business upload images failed");
        // }
      }
    }
  );

  const [onBusinessImageDelete, { loading: isDeleteLoading }] = useMutation(DELETE_BUSINESS_IMAGE, {
    onCompleted: ({ deleteBusinessImage: { error, isSuccess } }) => {
      if (isSuccess) {
        refetch();
        toast.success('Image deleted successfully');
      } else toast.error(error || 'Image deletion failed.');
    },
    onError: () => toast.error('Image deletion failed.'),
  });

  return {
    isLoading,
    isDeleteLoading,
    error,
    onSaveImages,
    onBusinessImageDelete,
  };
};
