
import moment from 'moment';
export const parsedServiceHolidays = (holidays, businessHolidays) => {
    const newBusinessHolidays = parsedBusinessHolidays(businessHolidays)
    const updatedDays = holidays.map((day) => {

        const isDateExistInBusiness = newBusinessHolidays.find((holiday) => holiday.date === moment(day.date).format('YYYY-MM-DD'))
        let businessStatus = ""
        let closingTime = ""
        let openingTime = ""
        let isOpen = false
        let fullRowDisable = false

        let OT_status = false
        let CT_status = false
        // debugger
        if (isDateExistInBusiness) {// it means service date is include in business days
            if (isDateExistInBusiness.isOpen) // it means business is partialy open
            {
                if (day.isFullDayOff === false) {
                    if (day.specialHours.length > 0) {
                        const business_openingTime = isDateExistInBusiness.date + ' ' + isDateExistInBusiness.openingTime;
                        const business_closingTime = isDateExistInBusiness.date + ' ' + isDateExistInBusiness.closingTime;
                        const service_openingTime_ = moment(day.date).format('YYYY-MM-DD') + ' ' + moment(day.specialHours[0].openingTime).format('HH:mm');
                        const service_closingTime_ = moment(day.date).format('YYYY-MM-DD') + ' ' + moment(day.specialHours[1].closingTime).format('HH:mm');
                        const openingTime_IsBetween = moment(service_openingTime_).isBetween(business_openingTime, business_closingTime);
                        const closingTime_IsBetween = moment(service_closingTime_).isBetween(business_openingTime, business_closingTime);

                        if (openingTime_IsBetween) {
                            OT_status = true
                        } else {
                            if (service_openingTime_ === business_openingTime) {
                                OT_status = true
                            }
                            else {
                                OT_status = false
                            }
                        }

                        if (closingTime_IsBetween) {
                            CT_status = true
                        } else {
                            if (service_closingTime_ === business_closingTime) {
                                CT_status = true
                            }
                            else {
                                CT_status = false
                            }
                        }

                        if (OT_status && CT_status) {
                            fullRowDisable = false
                        }
                        else {
                            fullRowDisable = true
                        }

                    }
                }
                else if(day.isFullDayOff === true && day.specialHours.length > 0 && day.specialHours[0].openingTime !== "0001-01-01T00:00:00"){
                    fullRowDisable = true
                }

            } else { // it means business is on full holiday 
                let sevice_on_Fullholiday = true
                if (day.specialHours.length === 0) {
                    sevice_on_Fullholiday = true
                } else if (day.specialHours[0].openingTime === "0001-01-01T00:00:00") {
                    sevice_on_Fullholiday = true
                }
                else {
                    sevice_on_Fullholiday = false
                }

                if (sevice_on_Fullholiday && day.isFullDayOff) {
                    fullRowDisable = false
                }
                else {
                    fullRowDisable = true
                }
                businessStatus = "fulldayoff"
            }
        }
        else {

        }
        let obj = {}
        obj.isOpen = isOpen
        obj.id = day.id
        obj.date = moment(day.date).format('YYYY-MM-DD')
        obj.specialHolidayId1 = undefined
        obj.specialHolidayId2 = undefined
        obj.openingTimeError = ""
        obj.closingTimeError = ""
        obj.hasBreakError = ""
        obj.breakStartTimeError = ""
        obj.breakDurationError = ""
        obj.hasBreak = false
        obj.breakStartTime = ""
        obj.breakDuration = ""
        obj.closingTime = closingTime
        obj.openingTime = openingTime
        obj.businessStatus = businessStatus
        obj.fullRowDisable = fullRowDisable
        obj.editMode = false
        if (day.specialHours.length > 0) {
            if ([day.specialHours[0].openingTime, day.specialHours[0].closingTime, day.specialHours[1].openingTime, day.specialHours[1].closingTime].includes("0001-01-01T00:00:00")) {
                obj.isOpen = false
            }
            else {
                obj.openingTime = moment(day.specialHours[0].openingTime).format('HH:mm')
                obj.isOpen = true
                if (day.specialHours[1].openingTime === day.specialHours[1].closingTime) {
                    // it means business is open and there is no break 
                    obj.closingTime = moment(day.specialHours[0].closingTime).format('HH:mm')
                    obj.businessClosingTime = moment(day.specialHours[0].closingTime).format('HH:mm')
                    obj.hasBreak = false
                    obj.breakStartTime = ""
                    obj.breakDuration = ""
                }
                else {
                    // it means business is open and and there is some brek
                    obj.closingTime = moment(day.specialHours[1].closingTime).format('HH:mm')
                    obj.hasBreak = true
                    obj.breakStartTime = moment(day.specialHours[0].closingTime).format('HH:mm')
                    const timeInMilliSec = moment(day.specialHours[1].openingTime).diff(moment(day.specialHours[0].closingTime))
                    const timInSec = moment.duration(timeInMilliSec).asMinutes()
                    obj.breakDuration = timInSec
                }
            }
        }
        else {
            obj.openingTime = ""
            obj.closingTime = ""
            obj.isOpen = false
        }




        return obj
    })
    return updatedDays
}

export const parsedBusinessHolidays = (holidays) => {
    const updatedDays = holidays.map((day) => {
        let obj = {}
        obj.isOpen = true
        obj.id = day.id
        obj.date = moment(day.date).format('YYYY-MM-DD')
        obj.specialHolidayId1 = undefined
        obj.specialHolidayId2 = undefined
        obj.openingTimeError = ""
        obj.closingTimeError = ""
        obj.hasBreakError = ""
        obj.breakStartTimeError = ""
        obj.breakDurationError = ""
        obj.hasBreak = false
        obj.breakStartTime = ""
        obj.breakDuration = ""
        obj.openingTime = ""
        obj.closingTime = ""
        obj.businessStatus = ""
        if (day.specialHours.length > 0) {
            if ([day.specialHours[0].openingTime, day.specialHours[0].closingTime, day.specialHours[1].openingTime, day.specialHours[1].closingTime].includes("0001-01-01T00:00:00")) {
                obj.isOpen = false
            }
            else {
                obj.openingTime = moment(day.specialHours[0].openingTime).format('HH:mm')
                obj.isOpen = true
                if (day.specialHours[1].openingTime === day.specialHours[1].closingTime) {
                    // it means business is open and there is no break 
                    obj.closingTime = moment(day.specialHours[0].closingTime).format('HH:mm')
                    obj.businessClosingTime = moment(day.specialHours[0].closingTime).format('HH:mm')
                    obj.hasBreak = false
                    obj.breakStartTime = ""
                    obj.breakDuration = ""
                }
                else {
                    // it means business is open and and there is some brek
                    obj.closingTime = moment(day.specialHours[1].closingTime).format('HH:mm')
                    obj.hasBreak = true
                    obj.breakStartTime = moment(day.specialHours[0].closingTime).format('HH:mm')
                    const timeInMilliSec = moment(day.specialHours[1].openingTime).diff(moment(day.specialHours[0].closingTime))
                    const timInSec = moment.duration(timeInMilliSec).asMinutes()
                    obj.breakDuration = timInSec
                }
            }
        }
        else {
            obj.openingTime = ""
            obj.closingTime = ""
            obj.isOpen = false
        }




        return obj
    })
    return updatedDays
}

export const getFinalData = (workingslots) => {

    let finalSlots = [];

    let specialHours = [];
    const dateFormat = 'YYYY-MM-DD HH:mm';

    const blankTime = moment('0001-01-01 00:00').format(dateFormat);

    workingslots.map(({ id, date, specialHolidayId1, specialHolidayId2, hasBreak, openingTime, closingTime, breakDuration, breakStartTime, isOpen }) => {

        let firstSlot = {};

        let secondSlot = {};

        if (date) {
            openingTime = date + ' ' + openingTime;
            closingTime = date + ' ' + closingTime;
        }

        openingTime = moment(openingTime).format(dateFormat);
        closingTime = moment(closingTime).format(dateFormat);
        breakStartTime = moment(`0001-01-01 ${breakStartTime}`).format(dateFormat);

        firstSlot = {
            id: typeof specialHolidayId1 == 'string' || !specialHolidayId1 ? 0 : specialHolidayId1,
            openingTime,
            closingTime: breakStartTime,
            date: date,
        };

        secondSlot = {
            id: typeof specialHolidayId2 == 'string' || !specialHolidayId2 ? 0 : specialHolidayId2,
            openingTime: moment(breakStartTime, dateFormat).add(breakDuration, 'minutes').format(dateFormat),
            closingTime,
            date: date,
        };

        if (!hasBreak) {
            breakDuration = 0;
            breakStartTime = blankTime;
            firstSlot = { ...firstSlot, closingTime };
            secondSlot = { ...secondSlot, openingTime: closingTime, closingTime };
        }

        if (isOpen === false) {
            specialHours = [];
        }
        else {
            specialHours = [{ ...firstSlot }, { ...secondSlot }];
        }

        if (date) {
            finalSlots.push({ id: typeof id == 'string' || !id ? 0 : id, name: ' ', date: date, specialHours, });
        }
    });

    return finalSlots;
};


export const getformatedTime = (time) => {
    if (time) {
        return moment(time, "hh mm").format('LT')
    }
    else {
        return ""
    }
}


