import { toast as showToast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { MARK_NOTIFICATION_READ } from './requests';

export const useData = (refetch) => {
  const [onMarkRead, { loading: isLoading }] = useMutation(
    MARK_NOTIFICATION_READ,
    {
      onCompleted: ({
        markAdminNotificationsAsRead: { errorMessage, isSuccess },
      }) => {
        if (isSuccess) refetch();
        else showToast.error(errorMessage || 'Something went wrong');
      },
      onError: () => showToast.error('Something went wrong'),
    }
  );

  return { isLoading, onMarkRead };
};
