import React, { Fragment, useState, useEffect } from 'react';
import { Button, Title } from '@safelyq/bulma-ui-library';
import { useBusinessDispatch } from '@safelyq/admin-promotions-feature';
import { AddCouponModal, CouponsList } from './components';
import { useData } from './hooks';

export const PromotionSetup = () => {
  const [isOpen, setOpen] = useState(false);

  const dispatch = useBusinessDispatch();

  const handleModalOpen = () => setOpen(true);

  const handleModalClose = () => setOpen(false);

  const handleAddCoupon = () => {
    dispatch({ type: 'SELECTED_COUPON_ID', payload: 0 });

    handleModalOpen();
  };

  const {
    data,
    refetch,
  } = useData();

  const { business } = data || {};

  useEffect(() => {
    dispatch({ type: 'SET_BUSINESS_DATA', payload: { business } });
  }, [business]);

  return (
    <Fragment>
      <div className='is-flex is-align-items-center mb-3'>
        <Title size='5' className='m-0'>
          Coupons
        </Title>
        &nbsp;&nbsp;
        <Button color='info' size='small' onClick={handleAddCoupon}>
          Add coupon
        </Button>
      </div>
      <CouponsList onOpenModal={handleModalOpen} />
      {isOpen ? <AddCouponModal isOpen={isOpen} onClose={handleModalClose} refetch={refetch} /> : null}

    </Fragment>
  );
};
