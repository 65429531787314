import React from 'react'
import { useHistory, useLocation } from 'react-router-dom';

const BreadCrumbs = () => {
    const location = useLocation();
    const history = useHistory();
    return (
        <nav class="breadcrumb" aria-label="breadcrumbs">
            {
                location.pathname === "/superadmin/managebusiness"
                &&
                <ul className="is-size-7">
                    <li><p onClick={() => history.push('/superadmin/home')} className='has-text-link has-text-weight-semibold is-clickable mr-2'>Super Admin</p></li>
                    <li><p className='has-text-black has-text-weight-semibold mx-2'> Manage Business </p></li>
                </ul>
            }
            {
                location.pathname === "/superadmin/marketinguser"
                &&
                <ul className="is-size-7">
                    <li><p onClick={() => history.push('/superadmin/home')} className='has-text-link has-text-weight-semibold is-clickable mr-2'>Super Admin</p></li>
                    <li><p className='has-text-black has-text-weight-semibold mx-2'>Marketing Users</p></li>
                </ul>
            }
            {
                location.pathname === "/superadmin/appuser"
                &&
                <ul className="is-size-7">
                    <li><p onClick={() => history.push('/superadmin/home')} className='has-text-link has-text-weight-semibold is-clickable mr-2'>Super Admin</p></li>
                    <li><p className='has-text-black has-text-weight-semibold mx-2'>App Users</p></li>
                </ul>
            }
            {
                location.pathname === "/superadmin/organization"
                &&
                <ul className="is-size-7">
                    <li><p onClick={() => history.push('/superadmin/home')} className='has-text-link has-text-weight-semibold is-clickable mr-2'>Super Admin</p></li>
                    <li><p className='has-text-black has-text-weight-semibold mx-2'>Organization</p></li>
                </ul>
            }
            {
                location.pathname === "/superadmin/categories"
                &&
                <ul className="is-size-7">
                    <li><p onClick={() => history.push('/superadmin/home')} className='has-text-link has-text-weight-semibold is-clickable mr-2'>Super Admin</p></li>
                    <li><p className='has-text-black has-text-weight-semibold mx-2'>Categories</p></li>
                </ul>
            }
        </nav>
    )
}

export default BreadCrumbs