import React, { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { TableView } from './components/TableView';
import { TileView } from './components/TileView';
import {
  useBusinessState,
  useBusinessDispatch,
} from '@safelyq/admin-promotions-feature';
import { useData } from './hooks';

export const CouponsList = ({ onOpenModal }) => {
  const { search } = useLocation();

  const { business, businessId } = useBusinessState();

  const { onUpdateBusinessCoupon } = useData();

  const dispatch = useBusinessDispatch();

  const { businessCoupons } = business || {};

  const isEmptyCoupons = isEmpty(businessCoupons);

  useEffect(() => {
    const searchString = String(search).split('=');

    if (searchString.length > 1) {
      dispatch({
        type: 'SELECTED_COUPON_ID',
        payload: parseInt(searchString[1]),
      });

      onOpenModal();
    }
  }, [search]);

  const handleModalPopulate = (e, couponId) => {
    e.preventDefault();

    dispatch({ type: 'SELECTED_COUPON_ID', payload: couponId });

    onOpenModal();
  };

  const handleCouponStatusChange = (checked, couponId) => {
    let coupon = businessCoupons.find(({ id }) => id === couponId);

    coupon = (({
      id,
      code,
      couponUrl,
      discount,
      discountType,
      summary,
      terms,
    }) => ({
      id,
      code,
      couponUrl,
      discount,
      discountType,
      summary,
      terms,
    }))(coupon);

    onUpdateBusinessCoupon({
      variables: {
        businessCouponInput: {
          ...coupon,
          businessId: parseInt(businessId),
          isActive: checked,
          startDate: moment(coupon.startDate).format('YYYY-MM-DD'),
          endDate: moment(coupon.endDate).format('YYYY-MM-DD'),
        },
      },
    }).then(() =>
      dispatch({ type: 'CHANGE_COUPON_STATUS', payload: { checked, couponId } })
    );
  };

  if (isEmptyCoupons) return <h1>No coupons</h1>;

  return (
    <Fragment>
      <div className='is-hidden-tablet'>
        <TileView
          businessCoupons={businessCoupons}
          onModalPopulate={handleModalPopulate}
          onCouponStatusChange={handleCouponStatusChange}
        />
      </div>
      <div className='is-hidden-mobile'>
        <TableView
          businessCoupons={businessCoupons}
          onModalPopulate={handleModalPopulate}
          onCouponStatusChange={handleCouponStatusChange}
        />
      </div>
    </Fragment>
  );
};
