import React from 'react'
import { toast } from 'react-toastify';
import { Title, Subtitle, } from '@safelyq/bulma-ui-library';
import styled from 'styled-components';
import { Icon } from '@safelyq/bulma-ui-library';
import { FiInfo } from "react-icons/fi";
import { Spin, Tooltip } from 'antd';
const UploadButton = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 1px dashed grey;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const StyledImage = styled.img`
  width: 50px;
  height: 50px;
  objectfit: cover;
  border: 1px dashed grey;
`;

const DeleteButton = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const StyledControl = styled.div`
  &:hover ${DeleteButton} {
    display: flex;
  }
`;

export const ImagesComponent = ({ title, subtitle, images, sectionName, onUploadImage, onDeleteImage, toolTipTitle, uploadImageLoading, setUploadImageLoading }) => {
    const onUploadBusinessImage = (e) => {
        // if nothing exists then return
        if (!e.target.files[0]) {
            return;
        }

        // Image upload Loading
        setUploadImageLoading({ sectionName: sectionName, isLoading: true });

        const file = e.target.files[0];
        let { name, size } = file;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const extensions = ['jpeg', 'png', 'gif', 'tiff', 'psd', 'jpg'];
        const k = 1024;
        const i = Math.floor(Math.log(size) / Math.log(k));
        const actualsize = parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
        if (size > 2120000) {
            setUploadImageLoading({ ...uploadImageLoading, isLoading: false });
            return toast.error(`Cant upload image more then 2MB. your image size is ${actualsize}`);
        }
        const extension = String(name).split('.').pop();
        if (!extensions.includes(extension)) {
            setUploadImageLoading({ ...uploadImageLoading, isLoading: false });
            return toast.error(`Only images are allowed`);
        }

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {

            onUploadImage({
                fileName: name,
                base64String: window.btoa(reader.result),
                isDefault: true,
            }, sectionName)
        };
    }

    if (sectionName === "logo") {
        return (
            <div>
                <Title size='5'>{title}
                    <Tooltip title={toolTipTitle}> <FiInfo /> </Tooltip>
                    <p className='is-size-7 has-text-weight-semibold has-text-info my-1 mb-2'>Images must be upto 2MB</p>
                    <Subtitle size='6'> {subtitle}</Subtitle>
                </Title>
                <div className='is-flex is-flex-wrap-wrap'>
                    {
                        (images[0]?.base64String || images[0]?.path) &&
                        images.map((image) => {
                            return (
                                <StyledControl key={image.path} className='control is-relative mr-4 my-1'>
                                    <StyledImage src={image.path || window.atob(image.base64String)} />
                                    <DeleteButton onClick={() => onDeleteImage(image, sectionName)}>
                                        <Icon name='trash-o' color='white' />
                                    </DeleteButton>
                                </StyledControl>
                            )
                        })
                    }
                    <div className='control mr-4 my-1'>
                        <Spin spinning={uploadImageLoading?.sectionName === sectionName && uploadImageLoading?.isLoading}>
                            <label for='business-file-input'>
                                <UploadButton>   <Icon name='picture-o' />   </UploadButton>
                                <input onChange={onUploadBusinessImage} id={`${sectionName}-file-input`} type='file' accept='image/*' style={{ opacity: 0, position: 'absolute', top: 10, width: 50 }} />
                            </label>
                        </Spin>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <Title size='5'>{title} {title === "Upload Business Pictures" && <span className='has-text-danger'>*</span>}
                    <Tooltip title={toolTipTitle}> <FiInfo /> </Tooltip>
                    <p className='is-size-7 has-text-weight-semibold has-text-info my-1 mb-2'>Images must be upto 2MB</p>
                    <Subtitle size='6'>  {images.length} of 5 images</Subtitle>
                </Title>
                <div className='is-flex is-flex-wrap-wrap'>
                    {images.map((image) => (
                        <StyledControl key={image?.id} className='control is-relative mr-4 my-1'>
                            <StyledImage src={image?.path || window.atob(image?.base64String)} />
                            <DeleteButton onClick={() => onDeleteImage(image, sectionName)}>
                                <Icon name='trash-o' color='white' />
                            </DeleteButton>
                        </StyledControl>
                    ))}
                    {
                        images?.length < 5 && (
                            <div className='control mr-4 my-1'>
                                <Spin spinning={uploadImageLoading?.sectionName === sectionName && uploadImageLoading?.isLoading}>
                                    <label for='business-file-input'>
                                        <UploadButton>   <Icon name='picture-o' />   </UploadButton>
                                        <input onChange={onUploadBusinessImage} id={`${sectionName}-file-input`} type='file' accept='image/*' style={{ opacity: 0, position: 'absolute', top: 10, width: 50 }} />
                                    </label>
                                </Spin>
                            </div>
                        )
                    }

                </div>
            </div>
        )
    }

} 