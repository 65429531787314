import { string } from 'prop-types';
import styled from 'styled-components';

const Image = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 200px !important;
`;

Image.propTypes = {
  src: string,
};

Image.defaultProps = {
  src: undefined,
};

export default Image;
