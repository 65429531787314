import { gql } from '@apollo/client';

export const GET_BUSINESS_APPOINTMENTS = gql`
  query GetBusinessAppointments(
    $businessId: Int
    $startDate: Date
    $endDate: Date
    $orderSequence: OrderSequence
    $sortOrder: AppointmentSortOrder
  ) {
    getBusinessAppointments(
      businessId: $businessId
      startDate: $startDate
      endDate: $endDate
      orderSequence: $orderSequence
      sortOrder: $sortOrder
    ) {
      id
      status
      checkedInTime
      business {
        id
      }
      service {
        id
        name
      }
      subBusiness {
        id
        name
      }
      user {
        id
        firstName
        lastName
        name
        email
        phoneNumber
      }
      startTime
      comments {
        comment {
          commentText
        }
      }
    }
  }
`;

export const CHANGE_APPOINTMENT_STATUS = gql`
  mutation ChangeAppointmentStatus(
    $appointmentId: Int
    $newStatus: AppointmentStatus
  ) {
    updateAppointmentStatus(
      appointmentId: $appointmentId
      newStatus: $newStatus
    ) {
      isSaved
      errorMessage
      appointment {
        id
        startTime
        endTime
        status
        business{
          id
        }
        service {
          id
        }
        adults
        subBusiness {
          name
        }
        user {
          id
        }
      }
    }
  }
`;
