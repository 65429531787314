import { gql } from '@apollo/client';

export const SEND_New_MESSAGE = gql`
  mutation SendNewMessage(
    $messageText: String
    $firstName: String
    $lastName: String
    $email: String
    $phoneNumber: String
    $businessId: Int
  ) {
    sendMessageToUser(
      userInput: {
        firstName: $firstName
        lastName: $lastName
        phoneNumber: $phoneNumber
        email: $email
      }
      userMessageInput: { businessId: $businessId, messageText: $messageText }
    ) {
    messageId
    errorMessage
    isSaved
    userMessage {
      business {
        id
        name
      }
      user {
        id
        firstName
        lastName
        name
        email
      }
      messageType
      message {
        commentText
        createdTime
        user {
          id
          firstName
          lastName
          email
        }
      }
      attachment {
        contentType
        publicUrl
        jsonData
        fileName
        id
      }
    }
  }
}
`;
