export const getStatusColor = (status = '') => {
    let color = 'white';
    if (status === 'name') {
        color = '163, 139, 0'; //red
    } else if (status === 'email') {
        color = '255, 0, 0'; //red
    } else if (status === "phoneNumber") {
        color = '181, 0, 0'; //red
    } else if (status === "Country") {
        color = '18, 173, 43'; //green
    } else if (status === "zipCode") {
        color = '255, 0, 0'; //red
    }
    return color;
};

export const FilterBy = {
    name: 'name',
    email: 'email',
    country: 'country',
    phoneNumber: 'phoneNumber',
    state: 'state',
    zipCode: 'zipCode',
}

export const FilterByConstants = {
    name: 'Name',
    email: 'Email',
    country: 'Country',
    phoneNumber: 'Phone Number',
    state: 'State',
    zipCode: 'Zip Code',
}