import { gql } from '@apollo/client';

export const GET_BUSINESS_BY_ID = gql`
  query GetBusinessById($id: Int!) {
    getBusinessById(id: $id, showAll: true) {
      name
    businessCategory {
      name
      features {
        couponAllowed
        googleReserveAllowed
      }
    }
       businessSubLevels {
      id
    }
    conversationsSummary {
      unreadCount
    }
    businessPermissions {
      isAdmin
      isAssociate
    }
    businessSubscription {
      subscriptionPlan {
        features {
          advanceAppointmentAllowed
          basicAppointmentAllowed
          campaignAllowed
          dedicatedNumberMessagingAllowed
          consoleApplicationAllowed
          googleFormAllowed
          genericNumberMessagingAllowed
          googleReserveAllowed
          reviewResponseAllowed
        }
        name
      }
    }
    }
  }
`;
