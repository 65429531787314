import React from 'react';
import moment from 'moment';
import { Box, Columns, Column, Text, Icon } from '@safelyq/bulma-ui-library';
import { STATUS } from 'constants/index';
import { getStatusName, getStatusColor } from 'methods/getStatuses';

export const AccordionDesktopItem = ({
  key,
  status,
  totalGuests,
  checkedInTime,
  user: { email },
  startTime,
  subBusiness: { name: serviceProvider },
  service: { name: serviceName },
  venueEntrance,
}) => {
  const entrance = venueEntrance?.name;
  return (
    <Box key={key} border>
      <Columns
        multiline
        style={{
          background:
            String(status).toUpperCase() === STATUS.CHECKED_IN &&
              moment().isBefore(moment(checkedInTime).add(10, 'minutes'))
              ? 'rgba(18, 173, 43, 0.4)'
              : '',
          borderRadius: '8px',
        }}
      >
        <Column size={2} style={{ display: 'flex', flexWrap: 'wrap' }}>
          {/* <Text weight='bold'>{String(email).split('@')[0] + '@'}</Text> */}
          <Text weight='bold'
            style={{
              wordWrap: 'break-word',
              wordBreak: 'break-all',
            }}
          >
            {String(email).length > 20
              ? String(email).substring(0, 20) + '...'
              : email}
          </Text>
        </Column>
        <Column size={2}>
          <Text>
            {String(serviceName).length > 40
              ? String(serviceName).substring(0, 40) + '...'
              : serviceName}
          </Text>
        </Column>
        <Column size={2}>
          <Text>
            {String(serviceProvider).length > 40
              ? String(serviceProvider).substring(0, 40) + '...'
              : serviceProvider}
          </Text>
        </Column>
        <Column size={2}>
          <Text>
            {' '}
            {String(entrance).length > 25
              ? String(entrance).substring(0, 25) + '...'
              : entrance}
          </Text>
        </Column>
        <Column size={2}>
          <Text>
            <Icon name='users' />
            &nbsp;
            {totalGuests}
          </Text>
        </Column>
        <Column size={2}>
          <span
            className={`tag`}
            style={{
              background: `rgba(${getStatusColor(status)}, 0.1)`,
              color: `rgb(${getStatusColor(status)})`,
            }}
          >
            {getStatusName(status)}
          </span>
        </Column>
      </Columns>
    </Box>
  );
};

export const AccordionMobileItem = ({
  key,
  status,
  totalGuests,
  checkedInTime,
  user: { email },
  startTime,
  subBusiness: { name: serviceProvider },
  service: { name: serviceName },
  venueEntrance,
}) => {
  const entrance = venueEntrance?.name;

  return (
    <Box
      key={key}
      border
      style={{
        background:
          String(status).toUpperCase() === STATUS.CHECKED_IN &&
            moment().isBefore(moment(checkedInTime).add(10, 'minutes'))
            ? 'rgba(18, 173, 43, 0.4)'
            : '',
        borderRadius: '8px',
      }}
    >
      <Columns gap='0' breakpoint='mobile' multiline>
        <Column size={4} className='mb-1'>
          <Text weight='bold'>{String(email).split('@')[0] + '@'}</Text>
        </Column>
        <Column size={4} className='mb-1 has-text-centered'>
          <Text>
            <Icon name='users' />
            &nbsp;
            {totalGuests}
          </Text>
        </Column>
        <Column size={4} className='mb-1 has-text-right'>
          <span
            className={`tag`}
            style={{
              background: `rgba(${getStatusColor(status)}, 0.1)`,
              color: `rgb(${getStatusColor(status)})`,
            }}
          >
            {getStatusName(status)}
          </span>
        </Column>
        <Column size={4}>
          <Text>
            {String(entrance).length > 11
              ? String(entrance).substring(0, 8) + '...'
              : entrance}
          </Text>
        </Column>
        <Column size={4} className='has-text-centered'>
          <Text>
            {' '}
            {String(serviceName).length > 11
              ? String(serviceName).substring(0, 8) + '...'
              : serviceName}
          </Text>
        </Column>
        <Column size={4} className='has-text-right'>
          <Text>
            {' '}
            {String(serviceProvider).length > 11
              ? String(serviceProvider).substring(0, 8) + '...'
              : serviceProvider}
          </Text>
        </Column>
      </Columns>
    </Box>
  );
};

const statusColorsEnum = {
  Booked: 'light',
  Passed: 'danger',
  Cancelled: 'danger',
  Confirmed: 'info',
  Completed: 'success',
  CheckedIn: 'warning',
};
