import { gql } from '@apollo/client';

export const SAVE_BUSINESS_RATING_RESPONSE = gql`
  mutation SaveBusinessRatingResponse(
    $ratingResponseInput: RatingResponseInput!
  ) {
    saveBusinessRatingResponse(ratingResponseInput: $ratingResponseInput) {
      errorMessage
      isSaved
    }
  }
`;
