import { gql } from '@apollo/client';

export const GET_MARKETING_USERS = gql`
  query MyQuery($selectMarketingUsersInput: SelectMarketingUsersInput!) {
    getMarketingUsers(selectMarketingUsersInput: $selectMarketingUsersInput) {
    totalCount
    marketingUsers{
    address1
      address2
      age
      allowEmail
      allowSMS
      allowMarketingNotifications
      allowWhatsApp
      city
      correlationKey
      country
      createdTime
      email
      firstName
      middleName
      id
      lastName
      modifiedTime
      phoneNumber
      state
      zipCode
    }
      
    }
  }
`;

export const Add_Marketing_Users = gql`
  mutation AddMarketingUsers($fileInput: FileInput!,) {
    addMarketingUsers(fileInput: $fileInput) {
      errorMessage
      isSaved 
    }
  }
`;