import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Box, Title, Subtitle } from '@safelyq/bulma-ui-library';
import mailImage from 'assets/mail.png';
import { APIPaths } from 'services/api-provider';

const VerticalCentered = styled.div.attrs(() => ({
  className:
    'is-flex is-flex-direction-column is-justify-content-center is-justify-items-center',
}))`
  width: 100%;
  height: 84vh;
`;

export const ConfirmationMessage = () => {
  return (
    <VerticalCentered>
      <Box>
        <figure className='has-text-centered mb-5'>
          <img src={mailImage} width={150} alt='mail' />
        </figure>
        <Title size='5' color='info'>
          <center>Check in your email !</center>
        </Title>
        <Subtitle size='6' color='grey-light'>
          <center>
            <small>
              If the email address exists, We've just send you a link to reset
              your password
            </small>
          </center>
        </Subtitle>
        <hr />
        <Subtitle size='6' color='grey-light'>
          <center>
            <small>For any question or problems please email us at</small>
          </center>
        </Subtitle>
        <Title size='6' color='info'>
          <center>info@safelyq.com</center>
        </Title>
      </Box>
      <small>
        <center>
          <Link
            to={`${APIPaths.Gateway}/Account/Login`}
            className='is-underline has-text-weight-bold'
          >
            Go back to login screen
          </Link>
        </center>
      </small>
    </VerticalCentered>
  );
};
