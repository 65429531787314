export const INITIAL_DATA = {
  getAdminNotifications: {
    adminNotifications: [],
  },
};

export const DUMMY_DATA = [
  {
    id: 154,
    notificationChannel: 'Record',
    subject: 'Message From Muhammad Ayaan',
    content:
      'You have a message from Muhammad Ayaan on Thursday, 27 May 2021 02:29 AM\r\n\r\nMessage: 😀\r\n',
    createdTime: '2021-05-27T11:29:09.895088Z',
    correlationKey: 'b30c2de0-2c70-45cd-b795-80a7dc45a9dd',
    sentChannels: ['Email', 'OneSignal', 'Sms'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 153,
    notificationChannel: 'Record',
    subject: 'Message From Ahan Ali',
    content:
      'You have a message from Ahan Ali on Thursday, 27 May 2021 01:54 AM\r\n\r\nMessage: This is long text for testing purpose. This is long text for testing purpose. This is long text for testing purpose. 🚴‍♀️🚴‍♀️🚴‍♀️🚴‍♀️😟😟😟😈😈😈😈\r\n',
    createdTime: '2021-05-27T10:54:08.547206Z',
    correlationKey: 'd56b12f4-c0a3-4c51-9a09-ba8eae779d0f',
    sentChannels: ['Unknown'],
    isRead: true,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 152,
    notificationChannel: 'Record',
    subject: 'Message From Ahan Ali',
    content:
      'You have a message from Ahan Ali on Thursday, 27 May 2021 01:52 AM\r\n\r\nMessage: This is my text for testing purpose. This is my text for testing purpose.\r\n',
    createdTime: '2021-05-27T10:52:27.368515Z',
    correlationKey: 'd26e4a69-9800-4263-a435-c6383eb21938',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 151,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 05:29 AM\r\n\r\nMessage: ok\r\n',
    createdTime: '2021-05-26T14:29:16.472276Z',
    correlationKey: 'bee6914c-b64e-41e6-9cf2-532dce1d1cd8',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 150,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 05:29 AM\r\n\r\nMessage: ok\r\n',
    createdTime: '2021-05-26T14:29:13.53451Z',
    correlationKey: '54324b85-d66d-4d21-9a22-e05e2f647d68',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 149,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 05:27 AM\r\n\r\nMessage: ok\r\n',
    createdTime: '2021-05-26T14:27:07.159245Z',
    correlationKey: '932c0c55-1355-4946-b786-fd7434602011',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 148,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 05:25 AM\r\n\r\nMessage: \r\n',
    createdTime: '2021-05-26T14:25:52.137009Z',
    correlationKey: '27f4ae2d-3aef-4310-b4c3-a94b86b83f2c',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 147,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 05:25 AM\r\n\r\nMessage: sahi hogia\r\n',
    createdTime: '2021-05-26T14:25:42.316886Z',
    correlationKey: 'acdfb836-027f-4096-a061-ac6b3a1734e2',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 146,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 05:24 AM\r\n\r\nMessage: test-2\r\n',
    createdTime: '2021-05-26T14:24:35.765788Z',
    correlationKey: '55219422-52ce-4fc9-8313-4eb146c1d662',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 145,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 05:24 AM\r\n\r\nMessage: test\r\n',
    createdTime: '2021-05-26T14:24:13.30689Z',
    correlationKey: '666b03da-c339-4cec-b3dd-db2ea2b1cb38',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 144,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 05:22 AM\r\n\r\nMessage: ete\r\n',
    createdTime: '2021-05-26T14:22:51.485278Z',
    correlationKey: 'fb8a2bf4-cc18-4b1e-bb9f-bc76ae23716a',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 143,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 05:22 AM\r\n\r\nMessage: fdhfdh\r\n',
    createdTime: '2021-05-26T14:22:49.659375Z',
    correlationKey: '38144a91-3896-4364-928d-898429b6a519',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 142,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 05:22 AM\r\n\r\nMessage: etwe\r\n',
    createdTime: '2021-05-26T14:22:44.484154Z',
    correlationKey: 'e83365a2-f055-4206-b952-0c4f5c3c9aae',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 141,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 05:22 AM\r\n\r\nMessage: f\r\n',
    createdTime: '2021-05-26T14:22:42.017364Z',
    correlationKey: '42851783-bb34-4282-ad62-3617cdbd8e63',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 140,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 05:22 AM\r\n\r\nMessage: d\r\n',
    createdTime: '2021-05-26T14:22:38.881374Z',
    correlationKey: '7d9ebc35-a078-4b9d-be33-089e8c1073cc',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 139,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 05:22 AM\r\n\r\nMessage: d\r\n',
    createdTime: '2021-05-26T14:22:34.906396Z',
    correlationKey: '2c4f4bbf-294b-421d-aa9f-73261580d85c',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 138,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 05:22 AM\r\n\r\nMessage: fine ha\r\n',
    createdTime: '2021-05-26T14:22:23.826978Z',
    correlationKey: 'c988b5e6-d833-4fa7-ba97-2425e7fcdc21',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 137,
    notificationChannel: 'Record',
    subject: 'Message From taimoor',
    content:
      'You have a message from taimoor on Wednesday, 26 May 2021 05:20 AM\r\n\r\nMessage: great\r\n',
    createdTime: '2021-05-26T14:20:33.439032Z',
    correlationKey: '17603bcc-5b88-4d2a-a789-b436bd3d4add',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 136,
    notificationChannel: 'Record',
    subject: 'Message From taimoor',
    content:
      'You have a message from taimoor on Wednesday, 26 May 2021 05:19 AM\r\n\r\nMessage: last\r\n',
    createdTime: '2021-05-26T14:19:21.592049Z',
    correlationKey: '28bc814b-d549-405a-89d7-116e49e6a49a',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 135,
    notificationChannel: 'Record',
    subject: 'Message From taimoor',
    content:
      'You have a message from taimoor on Wednesday, 26 May 2021 05:17 AM\r\n\r\nMessage: scroll test\r\n',
    createdTime: '2021-05-26T14:17:59.942885Z',
    correlationKey: 'd0da9ec4-b209-4c1f-aecf-3c095bb7373c',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 134,
    notificationChannel: 'Record',
    subject: 'Message From taimoor',
    content:
      'You have a message from taimoor on Wednesday, 26 May 2021 05:14 AM\r\n\r\nMessage: 👉👉👉\r\n',
    createdTime: '2021-05-26T14:14:55.900151Z',
    correlationKey: '81daa498-1d1c-493a-a549-494bdb26a8f7',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 133,
    notificationChannel: 'Record',
    subject: 'Message From taimoor',
    content:
      'You have a message from taimoor on Wednesday, 26 May 2021 05:14 AM\r\n\r\nMessage: ok\r\n',
    createdTime: '2021-05-26T14:14:45.783075Z',
    correlationKey: '1f476f21-6870-4b5a-9e78-08f3ea0a3f8c',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 132,
    notificationChannel: 'Record',
    subject: 'Message From taimoor',
    content:
      'You have a message from taimoor on Wednesday, 26 May 2021 05:14 AM\r\n\r\nMessage: o\r\n',
    createdTime: '2021-05-26T14:14:42.817736Z',
    correlationKey: '4f316c7e-f665-4385-a2aa-b99deb5feca9',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 131,
    notificationChannel: 'Record',
    subject: 'Message From taimoor',
    content:
      'You have a message from taimoor on Wednesday, 26 May 2021 05:14 AM\r\n\r\nMessage: fine\r\n',
    createdTime: '2021-05-26T14:14:41.936787Z',
    correlationKey: 'c3c54dbd-0314-4a8c-a4af-691a1d3c5d58',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 130,
    notificationChannel: 'Record',
    subject: 'Message From taimoor',
    content:
      'You have a message from taimoor on Wednesday, 26 May 2021 05:14 AM\r\n\r\nMessage: good\r\n',
    createdTime: '2021-05-26T14:14:40.493983Z',
    correlationKey: '65155220-894d-4270-b6e8-e8a82cbca05c',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 129,
    notificationChannel: 'Record',
    subject: 'Message From taimoor',
    content:
      'You have a message from taimoor on Wednesday, 26 May 2021 05:14 AM\r\n\r\nMessage: sahi ha\r\n',
    createdTime: '2021-05-26T14:14:39.188316Z',
    correlationKey: '3bd71137-6291-4b28-9046-29554ad61ba3',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 128,
    notificationChannel: 'Record',
    subject: 'Message From taimoor',
    content:
      'You have a message from taimoor on Wednesday, 26 May 2021 05:14 AM\r\n\r\nMessage: list reverse ho gye ha\r\n',
    createdTime: '2021-05-26T14:14:36.705131Z',
    correlationKey: 'ae20d97a-5242-4a3d-a5e7-c9da27dd3b39',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 127,
    notificationChannel: 'Record',
    subject: 'Message From taimoor',
    content:
      'You have a message from taimoor on Wednesday, 26 May 2021 05:14 AM\r\n\r\nMessage: ho gia\r\n',
    createdTime: '2021-05-26T14:14:29.109465Z',
    correlationKey: '88d0529a-ebb5-4538-b4a1-e22903415be2',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 126,
    notificationChannel: 'Record',
    subject: 'Message From taimoor',
    content:
      'You have a message from taimoor on Wednesday, 26 May 2021 05:14 AM\r\n\r\nMessage: ok\r\n',
    createdTime: '2021-05-26T14:14:27.496588Z',
    correlationKey: 'bb1a0a81-8451-4684-ad28-f7041bd65c1f',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 125,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 02:40 AM\r\n\r\nMessage: http://via.placeholder.com/640x360\r\n',
    createdTime: '2021-05-26T11:40:54.145959Z',
    correlationKey: '81e2ea94-5f0c-47a3-b37e-58cf9a746ad2',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 124,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 02:40 AM\r\n\r\nMessage: ok thek ha\r\n',
    createdTime: '2021-05-26T11:40:41.572233Z',
    correlationKey: 'd54b2a29-44d5-4d4a-9ad8-69773a81a1ba',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 123,
    notificationChannel: 'Record',
    subject: 'Message From New User',
    content:
      'You have a message from New User on Wednesday, 26 May 2021 02:33 AM\r\n\r\nMessage: Hy!\r\n',
    createdTime: '2021-05-26T11:33:21.534717Z',
    correlationKey: '13f5da82-7995-4f33-983d-124ca4955692',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 122,
    notificationChannel: 'Record',
    subject: 'Message From Khuram Hassan',
    content:
      'You have a message from Khuram Hassan on Wednesday, 26 May 2021 02:31 AM\r\n\r\nMessage: ok\r\n',
    createdTime: '2021-05-26T11:31:51.595301Z',
    correlationKey: 'f492bf1e-8788-4285-a6d6-3ac8372b3093',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 121,
    notificationChannel: 'Record',
    subject: 'Message From Bilal Bangash',
    content:
      'You have a message from Bilal Bangash on Wednesday, 26 May 2021 02:31 AM\r\n\r\nMessage: ok\r\n',
    createdTime: '2021-05-26T11:31:46.253129Z',
    correlationKey: 'ed9fa1c5-8b10-4af9-b4c6-a1f4eb88cd3d',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 120,
    notificationChannel: 'Record',
    subject: 'Message From Bilal Bangash',
    content:
      'You have a message from Bilal Bangash on Wednesday, 26 May 2021 02:30 AM\r\n\r\nMessage: Hello Bilal\r\n',
    createdTime: '2021-05-26T11:30:39.354603Z',
    correlationKey: 'd96273ce-1af3-4729-a923-9c0be3d4833c',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 119,
    notificationChannel: 'Record',
    subject: 'Message From Imran Ali',
    content:
      'You have a message from Imran Ali on Wednesday, 26 May 2021 02:28 AM\r\n\r\nMessage: Hello Imran\r\n',
    createdTime: '2021-05-26T11:28:05.649081Z',
    correlationKey: '28a034e9-61bb-4464-9fb2-f27f331c6097',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 118,
    notificationChannel: 'Record',
    subject: 'Message From Ahan Ali',
    content:
      'You have a message from Ahan Ali on Wednesday, 26 May 2021 02:26 AM\r\n\r\nMessage: Hy Ahsan!\r\n',
    createdTime: '2021-05-26T11:26:10.53278Z',
    correlationKey: '59336c5b-4261-4d77-a302-e5c0dbc1b53a',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 117,
    notificationChannel: 'Record',
    subject: 'Message From Ali Ahmed',
    content:
      'You have a message from Ali Ahmed on Wednesday, 26 May 2021 02:20 AM\r\n\r\nMessage: ok\r\n',
    createdTime: '2021-05-26T11:20:48.258936Z',
    correlationKey: 'dfed58d8-fe25-4ab6-894d-015768af3648',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 116,
    notificationChannel: 'Record',
    subject: 'Message From Ali Ahmed',
    content:
      'You have a message from Ali Ahmed on Wednesday, 26 May 2021 02:20 AM\r\n\r\nMessage: fine\r\n',
    createdTime: '2021-05-26T11:20:43.094497Z',
    correlationKey: 'd1552c8e-f2d7-4b08-92b6-b1c705d9b488',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 115,
    notificationChannel: 'Record',
    subject: 'Message From Ali Ahmed',
    content:
      'You have a message from Ali Ahmed on Wednesday, 26 May 2021 02:20 AM\r\n\r\nMessage: ok\r\n',
    createdTime: '2021-05-26T11:20:42.030952Z',
    correlationKey: '0891e790-271c-4a49-b2a3-e048783e8d6b',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 114,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 02:13 AM\r\n\r\nMessage: ok\r\n',
    createdTime: '2021-05-26T11:13:00.348335Z',
    correlationKey: 'bf994901-9d22-4392-8dcb-7cf5905df804',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 113,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 02:12 AM\r\n\r\nMessage: 👩‍🏭\r\n',
    createdTime: '2021-05-26T11:12:49.121012Z',
    correlationKey: '94d0045e-a093-4b12-a74a-372e33f973ef',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 112,
    notificationChannel: 'Record',
    subject: 'Message From Ali Ahmed',
    content:
      'You have a message from Ali Ahmed on Wednesday, 26 May 2021 01:26 AM\r\n\r\nMessage: This is long text for testing. This is long text for testing. This is long text for testing\r\n',
    createdTime: '2021-05-26T10:26:16.055889Z',
    correlationKey: 'ded37f8e-63b2-4bc5-a5dd-eb3b4e7d568e',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 111,
    notificationChannel: 'Record',
    subject: 'Message From Muhammad Ayaan',
    content:
      'You have a message from Muhammad Ayaan on Wednesday, 26 May 2021 01:13 AM\r\n\r\nMessage: Hy\r\n',
    createdTime: '2021-05-26T10:13:29.847055Z',
    correlationKey: 'bbecdc72-4ccc-44fa-9dfa-8af859372867',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 110,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 01:13 AM\r\n\r\nMessage: Hello\r\n',
    createdTime: '2021-05-26T10:13:12.44954Z',
    correlationKey: 'c8265304-dc28-4785-8665-91ea0eaca3ad',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 109,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 01:12 AM\r\n\r\nMessage: Ok\r\n',
    createdTime: '2021-05-26T10:12:46.316946Z',
    correlationKey: 'b16c196d-c625-4fa5-b869-f18d77f31ab0',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 108,
    notificationChannel: 'Record',
    subject: 'Message From Muhammad Ayaan',
    content:
      'You have a message from Muhammad Ayaan on Wednesday, 26 May 2021 01:11 AM\r\n\r\nMessage: 📫🐬😾😖👩‍🏭👩‍⚕️🗨️🗨️\r\n',
    createdTime: '2021-05-26T10:11:39.336836Z',
    correlationKey: '746371ae-3169-4da3-a6da-8f74a9bf7631',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 107,
    notificationChannel: 'Record',
    subject: 'Message From Muhammad Ayaan',
    content:
      'You have a message from Muhammad Ayaan on Wednesday, 26 May 2021 01:11 AM\r\n\r\nMessage: 🇹🇱🇸🇿🇺🇲🇻🇳🇼🇸\r\n',
    createdTime: '2021-05-26T10:11:21.74086Z',
    correlationKey: '72ebccff-5306-483f-8fe8-428d5dab1d53',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 106,
    notificationChannel: 'Record',
    subject: 'Message From Muhammad Ayaan',
    content:
      'You have a message from Muhammad Ayaan on Wednesday, 26 May 2021 01:11 AM\r\n\r\nMessage: 🇵🇰🇵🇰\r\n',
    createdTime: '2021-05-26T10:11:05.277835Z',
    correlationKey: '6b3ff81b-c8b8-4d0c-836a-9d10237609b4',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 105,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 01:07 AM\r\n\r\nMessage: 🥚🤸\r\n',
    createdTime: '2021-05-26T10:07:45.137533Z',
    correlationKey: 'e8666336-600c-45e1-82c6-621a0396fc7d',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 104,
    notificationChannel: 'Record',
    subject: 'Message From Omair Ali',
    content:
      'You have a message from Omair Ali on Wednesday, 26 May 2021 12:19 AM\r\n\r\nMessage: 🙉\r\n',
    createdTime: '2021-05-26T09:19:06.990831Z',
    correlationKey: '4701e6b7-bcd1-47d0-b9c2-a56f3b5dc8ec',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 103,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Wednesday, 26 May 2021 12:12 AM\r\n\r\nMessage: 👹\r\n',
    createdTime: '2021-05-26T09:12:42.922784Z',
    correlationKey: 'c9f39c24-a3d3-466b-98c3-03b10889243c',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 102,
    notificationChannel: 'Record',
    subject: 'Message From Omair Ali',
    content:
      'You have a message from Omair Ali on Wednesday, 26 May 2021 12:10 AM\r\n\r\nMessage: 🐟🐠🍑🥚\r\n',
    createdTime: '2021-05-26T09:10:24.265256Z',
    correlationKey: '3750f895-df00-4ab6-8408-21bd22454c43',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 101,
    notificationChannel: 'Record',
    subject: 'Message From Omair Ali',
    content:
      'You have a message from Omair Ali on Wednesday, 26 May 2021 12:08 AM\r\n\r\nMessage: 😎🤢🤡🤒😳😱😦😧🤸🤹‍♀️🤾‍♂️\r\n',
    createdTime: '2021-05-26T09:08:58.885324Z',
    correlationKey: '194b3239-2dc0-4b70-89fb-b47101521032',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 100,
    notificationChannel: 'Record',
    subject: 'Message From Khuram Hassan',
    content:
      'You have a message from Khuram Hassan on Wednesday, 26 May 2021 12:05 AM\r\n\r\nMessage: 💟\r\n',
    createdTime: '2021-05-26T09:05:53.144489Z',
    correlationKey: 'da25d7ef-2a47-4604-93af-bb09387f8776',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 99,
    notificationChannel: 'Record',
    subject: 'Message From Khuram Hassan',
    content:
      'You have a message from Khuram Hassan on Wednesday, 26 May 2021 12:05 AM\r\n\r\nMessage: ❤️\r\n',
    createdTime: '2021-05-26T09:05:40.921676Z',
    correlationKey: 'e724b6e7-8ff1-435b-9d7a-204893e27a50',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 98,
    notificationChannel: 'Record',
    subject: 'Message From Khuram Hassan',
    content:
      'You have a message from Khuram Hassan on Wednesday, 26 May 2021 12:05 AM\r\n\r\nMessage: 👩🏻‍💻👩🏻‍💻👩🏻‍💻\r\n',
    createdTime: '2021-05-26T09:05:30.351006Z',
    correlationKey: '590c8630-200f-422e-ad3a-a1b7658b4132',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 97,
    notificationChannel: 'Record',
    subject: 'Message From Khuram Hassan',
    content:
      'You have a message from Khuram Hassan on Wednesday, 26 May 2021 12:05 AM\r\n\r\nMessage: 🙁🤖👨🏻‍🎨ok emoji workd fine\r\n',
    createdTime: '2021-05-26T09:05:05.837563Z',
    correlationKey: '27482191-53cb-485a-819b-50aeb8254994',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 96,
    notificationChannel: 'Record',
    subject: 'Message From Khuram Hassan',
    content:
      'You have a message from Khuram Hassan on Wednesday, 26 May 2021 12:04 AM\r\n\r\nMessage: 😁hy khuram☠️😂😄\r\n',
    createdTime: '2021-05-26T09:04:42.246486Z',
    correlationKey: '8155cb20-eb50-46d7-b02e-c73d9287a869',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 95,
    notificationChannel: 'Record',
    subject: 'Message From taimoor',
    content:
      'You have a message from taimoor on Tuesday, 25 May 2021 11:54 PM\r\n\r\nMessage: 😋 😋 😋 \r\n',
    createdTime: '2021-05-26T08:54:44.583584Z',
    correlationKey: '6a4e969e-2baa-4ee7-855e-55f6923518f9',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 94,
    notificationChannel: 'Record',
    subject: 'Message From Ali Ahmed',
    content:
      'You have a message from Ali Ahmed on Tuesday, 25 May 2021 11:54 PM\r\n\r\nMessage: 😋\r\n',
    createdTime: '2021-05-26T08:54:28.940793Z',
    correlationKey: '1006ceea-6f0e-4bcf-b957-a3ffb8da4573',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 93,
    notificationChannel: 'Record',
    subject: 'Message From Ali Ahmed',
    content:
      "You have a message from Ali Ahmed on Tuesday, 25 May 2021 11:54 PM\r\n\r\nMessage: that's great\r\n",
    createdTime: '2021-05-26T08:54:15.65435Z',
    correlationKey: '8ecec14e-8b3e-47ae-8531-f714da22bd42',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 92,
    notificationChannel: 'Record',
    subject: 'Message From Ali Ahmed',
    content:
      'You have a message from Ali Ahmed on Tuesday, 25 May 2021 11:54 PM\r\n\r\nMessage: fine\r\n',
    createdTime: '2021-05-26T08:54:07.989075Z',
    correlationKey: '9e1296f7-4249-4d58-8fe9-57710210a38f',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 91,
    notificationChannel: 'Record',
    subject: 'Message From Ali Ahmed',
    content:
      'You have a message from Ali Ahmed on Tuesday, 25 May 2021 11:54 PM\r\n\r\nMessage: ok\r\n',
    createdTime: '2021-05-26T08:54:06.572359Z',
    correlationKey: '55d35162-dfbb-4156-b09a-3df061ad280b',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 90,
    notificationChannel: 'Record',
    subject: 'Message From Ali Ahmed',
    content:
      'You have a message from Ali Ahmed on Tuesday, 25 May 2021 11:54 PM\r\n\r\nMessage: this is admin\r\n',
    createdTime: '2021-05-26T08:54:05.128731Z',
    correlationKey: '70bb99d5-5556-40e1-98db-dd7160c7c812',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 89,
    notificationChannel: 'Record',
    subject: 'Message From Muhammad Ayaan',
    content:
      'You have a message from Muhammad Ayaan on Tuesday, 25 May 2021 11:53 PM\r\n\r\nMessage: test again\r\n',
    createdTime: '2021-05-26T08:53:58.477242Z',
    correlationKey: '1b7c6897-2a00-464b-bffa-6545a433f124',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 88,
    notificationChannel: 'Record',
    subject: 'Message From Muhammad Ayaan',
    content:
      'You have a message from Muhammad Ayaan on Tuesday, 25 May 2021 11:51 PM\r\n\r\nMessage: no issue\r\n',
    createdTime: '2021-05-26T08:51:12.816448Z',
    correlationKey: 'e6f427fe-3570-42c1-b939-e686d55af11e',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 87,
    notificationChannel: 'Record',
    subject: 'Message From taimoor',
    content:
      'You have a message from taimoor on Tuesday, 25 May 2021 11:51 PM\r\n\r\nMessage: ahan\r\n',
    createdTime: '2021-05-26T08:51:04.839357Z',
    correlationKey: 'd550e574-421e-488a-a7f5-da795ba68cfb',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 86,
    notificationChannel: 'Record',
    subject: 'Message From taimoor',
    content:
      'You have a message from taimoor on Tuesday, 25 May 2021 11:50 PM\r\n\r\nMessage: thats good\r\n',
    createdTime: '2021-05-26T08:50:59.016669Z',
    correlationKey: 'ae32b83d-48fd-487f-8faf-7190c2987f8d',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 85,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 11:50 PM\r\n\r\nMessage: ok fine\r\n',
    createdTime: '2021-05-26T08:50:51.904205Z',
    correlationKey: 'fcdcb188-42b2-4439-b958-941b0adef121',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 84,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 11:50 PM\r\n\r\nMessage: yes all works fine\r\n',
    createdTime: '2021-05-26T08:50:46.186082Z',
    correlationKey: '97f4f468-1429-4635-ba4e-659956401381',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 83,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 11:49 PM\r\n\r\nMessage: ok\r\n',
    createdTime: '2021-05-26T08:49:00.238049Z',
    correlationKey: '06007d52-f22c-4ad5-8f15-a8bff376117a',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 82,
    notificationChannel: 'Record',
    subject: 'Message From Omair Ali',
    content:
      'You have a message from Omair Ali on Tuesday, 25 May 2021 11:48 PM\r\n\r\nMessage: how r u\r\n',
    createdTime: '2021-05-26T08:48:53.93227Z',
    correlationKey: '673678e5-6e0e-479c-9a8b-3505fca86560',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 81,
    notificationChannel: 'Record',
    subject: 'Message From Omair Ali',
    content:
      'You have a message from Omair Ali on Tuesday, 25 May 2021 11:48 PM\r\n\r\nMessage: hy\r\n',
    createdTime: '2021-05-26T08:48:46.52211Z',
    correlationKey: '261a3982-4686-4c67-80cb-40d744aa5214',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 80,
    notificationChannel: 'Record',
    subject: 'Message From Khuram Hassan',
    content:
      'You have a message from Khuram Hassan on Tuesday, 25 May 2021 11:44 PM\r\n\r\nMessage: Hy\r\n',
    createdTime: '2021-05-26T08:44:51.700536Z',
    correlationKey: '0dc9afa0-be2f-489f-8291-d3a01356bb6d',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 79,
    notificationChannel: 'Record',
    subject: 'Message From taimoor',
    content:
      'You have a message from taimoor on Tuesday, 25 May 2021 11:44 PM\r\n\r\nMessage: Hello Taimoor\r\n',
    createdTime: '2021-05-26T08:44:06.492012Z',
    correlationKey: 'd60b97a8-1147-4b16-9871-9ec80fb83c15',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 78,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 11:42 PM\r\n\r\nMessage: Name changed\r\n',
    createdTime: '2021-05-26T08:42:23.827549Z',
    correlationKey: '93dae447-37a1-4e58-b716-703c68ab9573',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 77,
    notificationChannel: 'Record',
    subject: 'Message From Ali Ahmed',
    content:
      'You have a message from Ali Ahmed on Tuesday, 25 May 2021 11:41 PM\r\n\r\nMessage: How r u?\r\n',
    createdTime: '2021-05-26T08:41:54.387742Z',
    correlationKey: '8eb29f0c-82a3-4f6c-9132-416658cfdd92',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 76,
    notificationChannel: 'Record',
    subject: 'Message From Ali Ahmed',
    content:
      'You have a message from Ali Ahmed on Tuesday, 25 May 2021 11:41 PM\r\n\r\nMessage: Hy Ali!\r\n',
    createdTime: '2021-05-26T08:41:46.345847Z',
    correlationKey: '2593ffd0-03e6-4a10-a5e8-9ee5ef831214',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 75,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 11:41 PM\r\n\r\nMessage: Hello- test- 4\r\n',
    createdTime: '2021-05-26T08:41:16.095089Z',
    correlationKey: 'b84077fb-8d1c-446a-8ea4-cd83b4b2770e',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 74,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 11:40 PM\r\n\r\nMessage: Hello-3\r\n',
    createdTime: '2021-05-26T08:40:51.765642Z',
    correlationKey: '0919b9fe-fa09-4a76-8d85-8464e16562be',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 73,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 11:40 PM\r\n\r\nMessage: Hello-2\r\n',
    createdTime: '2021-05-26T08:40:45.453742Z',
    correlationKey: '7cc4f002-913d-490e-ac8b-f0255015c3c9',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 72,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 11:39 PM\r\n\r\nMessage: Hello\r\n',
    createdTime: '2021-05-26T08:39:46.996737Z',
    correlationKey: 'd450867e-1098-43ec-8138-48e8b708a3c4',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 71,
    notificationChannel: 'Record',
    subject: 'Message From Muhammad Ayaan',
    content:
      'You have a message from Muhammad Ayaan on Tuesday, 25 May 2021 11:30 PM\r\n\r\nMessage: Test ayaan\r\n',
    createdTime: '2021-05-26T08:30:39.399195Z',
    correlationKey: 'd4a9abfa-5dea-44f7-ab11-681bb5bfb604',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 70,
    notificationChannel: 'Record',
    subject: 'Message From Omair Ali',
    content:
      'You have a message from Omair Ali on Tuesday, 25 May 2021 11:29 PM\r\n\r\nMessage: Hello omair\r\n',
    createdTime: '2021-05-26T08:29:43.560303Z',
    correlationKey: '09e558c7-5a99-4d6f-84d4-ada0bd6e5287',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 69,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 11:21 PM\r\n\r\nMessage: ok\r\n',
    createdTime: '2021-05-26T08:21:13.550054Z',
    correlationKey: '16d78574-a184-4524-9ca4-5feee2601fb0',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 68,
    notificationChannel: 'Record',
    subject: 'Message From Muhammad Ayaan',
    content:
      'You have a message from Muhammad Ayaan on Tuesday, 25 May 2021 05:45 AM\r\n\r\nMessage: I am fine\r\n',
    createdTime: '2021-05-25T14:45:52.878175Z',
    correlationKey: '1686bbc9-e9b1-4531-8d93-6e52702d9d70',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 67,
    notificationChannel: 'Record',
    subject: 'Message From Muhammad Ayaan',
    content:
      'You have a message from Muhammad Ayaan on Tuesday, 25 May 2021 05:40 AM\r\n\r\nMessage: whats your job is?\r\n',
    createdTime: '2021-05-25T14:40:51.311026Z',
    correlationKey: '0b0b00d7-b1ad-4b00-8ed3-4b437ad2bbc3',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 66,
    notificationChannel: 'Record',
    subject: 'Message From Muhammad Ayaan',
    content:
      'You have a message from Muhammad Ayaan on Tuesday, 25 May 2021 05:38 AM\r\n\r\nMessage: How r u?\r\n',
    createdTime: '2021-05-25T14:38:58.983334Z',
    correlationKey: 'c94d1e42-e8f6-4cf7-9ca0-9ee6f4e38520',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 65,
    notificationChannel: 'Record',
    subject: 'Message From Muhammad Ayaan',
    content:
      'You have a message from Muhammad Ayaan on Tuesday, 25 May 2021 05:36 AM\r\n\r\nMessage: Hello Admin\r\n',
    createdTime: '2021-05-25T14:36:05.291663Z',
    correlationKey: 'f9cd92be-13ec-4b41-87d3-65a429e0c608',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 64,
    notificationChannel: 'Record',
    subject: 'Message From Muhammad Ayaan',
    content:
      'You have a message from Muhammad Ayaan on Tuesday, 25 May 2021 05:34 AM\r\n\r\nMessage: Hy\r\n',
    createdTime: '2021-05-25T14:34:33.639999Z',
    correlationKey: '7892b076-17e6-42c0-881f-8acf1cf298fb',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 63,
    notificationChannel: 'Record',
    subject: 'Message From Muhammad Ayaan',
    content:
      'You have a message from Muhammad Ayaan on Tuesday, 25 May 2021 03:53 AM\r\n\r\nMessage: First message from admin to Ayaan\r\n',
    createdTime: '2021-05-25T12:53:55.764282Z',
    correlationKey: 'a6bd92b2-7227-4ed0-9d8a-02207e079233',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 62,
    notificationChannel: 'Record',
    subject: 'Message From Muhammad Ayaan',
    content:
      'You have a message from Muhammad Ayaan on Tuesday, 25 May 2021 03:52 AM\r\n\r\nMessage: My first message i am Ayaan\r\n',
    createdTime: '2021-05-25T12:52:43.648288Z',
    correlationKey: '29d49681-ad08-40db-8ef5-0de522cbe710',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 61,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 03:47 AM\r\n\r\nMessage: First message from Admin\r\n',
    createdTime: '2021-05-25T12:47:57.286487Z',
    correlationKey: '5a956f0e-9ea6-4e70-9a06-f7a840fca664',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 60,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 01:43 AM\r\n\r\nMessage: ok\r\n',
    createdTime: '2021-05-25T10:43:09.30921Z',
    correlationKey: '90b32059-06b2-43f3-8aa1-53ee4dde51a5',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 59,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 01:43 AM\r\n\r\nMessage: \r\n',
    createdTime: '2021-05-25T10:43:05.118931Z',
    correlationKey: 'b352b27d-c8d1-4f89-989d-876c0e66d02d',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 58,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 01:36 AM\r\n\r\nMessage: hello\r\n',
    createdTime: '2021-05-25T10:36:53.064333Z',
    correlationKey: '3d14f76f-39fa-40bb-9338-d89092ac8aad',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 57,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 01:28 AM\r\n\r\nMessage: or sunao\r\n',
    createdTime: '2021-05-25T10:28:24.128787Z',
    correlationKey: 'a255d9be-8fe8-4a87-a8db-b5d935f9a867',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 56,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 01:28 AM\r\n\r\nMessage: thk ha\r\n',
    createdTime: '2021-05-25T10:28:19.005404Z',
    correlationKey: 'b043f9e9-52b3-46ac-a21d-7bfc7d8a901b',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 55,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 01:28 AM\r\n\r\nMessage: ok\r\n',
    createdTime: '2021-05-25T10:28:16.534123Z',
    correlationKey: '16d68376-c3c4-46b4-b520-1ac0c39acd15',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 54,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 01:28 AM\r\n\r\nMessage: i am also fine\r\n',
    createdTime: '2021-05-25T10:28:14.874102Z',
    correlationKey: '05af02f8-121a-4886-b69d-6d0844039c46',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 53,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 01:28 AM\r\n\r\nMessage: and you\r\n',
    createdTime: '2021-05-25T10:28:10.576616Z',
    correlationKey: 'd03ffad8-97c3-43ac-ae5b-77f26d86ddb2',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 52,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 01:28 AM\r\n\r\nMessage: main thek\r\n',
    createdTime: '2021-05-25T10:28:06.5936Z',
    correlationKey: 'bd64b604-89d9-4b9c-8267-4fcfbd5e5df0',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 51,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 01:28 AM\r\n\r\nMessage: kia ho raha ha\r\n',
    createdTime: '2021-05-25T10:28:02.330292Z',
    correlationKey: '2fe7857d-5ef6-456c-8963-7f2f865dbb81',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 50,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 01:27 AM\r\n\r\nMessage: h r u?\r\n',
    createdTime: '2021-05-25T10:27:56.111731Z',
    correlationKey: '667a2b47-4663-4910-98e4-f6b098975779',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 49,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 01:27 AM\r\n\r\nMessage: Helo\r\n',
    createdTime: '2021-05-25T10:27:48.428145Z',
    correlationKey: 'ed952aaf-2377-4f8d-8613-868fe10ff315',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 48,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 12:31 AM\r\n\r\nMessage: hello bernadi\r\n',
    createdTime: '2021-05-25T09:31:13.15484Z',
    correlationKey: 'fcec2e20-bff6-41d8-a7fe-6628f436d8b9',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 47,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 12:30 AM\r\n\r\nMessage: Hy bernadi\r\n',
    createdTime: '2021-05-25T09:30:39.82359Z',
    correlationKey: '3a9f5deb-d0fc-417a-a380-2d1b405bd09d',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 46,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 12:27 AM\r\n\r\nMessage: hello bernadi\r\n',
    createdTime: '2021-05-25T09:27:51.018227Z',
    correlationKey: 'deb5459b-9e26-4acf-89a1-c3946b405b5a',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 45,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 12:26 AM\r\n\r\nMessage: how r u ?\r\n',
    createdTime: '2021-05-25T09:26:26.898055Z',
    correlationKey: 'c9716fbd-0faa-49c3-a3a2-45d861ccbe34',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 44,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Tuesday, 25 May 2021 12:26 AM\r\n\r\nMessage: Hello bernadi\r\n',
    createdTime: '2021-05-25T09:26:16.808286Z',
    correlationKey: '9d677e53-d522-440b-a02d-1c1dd1b6b0a4',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 39,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 11:31 PM\r\n\r\nMessage: sahi\r\n',
    createdTime: '2021-05-25T08:31:55.069786Z',
    correlationKey: 'e6704e12-455c-4972-bede-814016f66c62',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 38,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 05:35 AM\r\n\r\nMessage: ok\r\n',
    createdTime: '2021-05-24T14:35:20.943383Z',
    correlationKey: '5e3a5ec1-4fb6-4350-a136-a8bf1fe28aa6',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 37,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 05:10 AM\r\n\r\nMessage: Hy le bernadi\r\n',
    createdTime: '2021-05-24T14:10:40.973694Z',
    correlationKey: '02af7c19-ee32-4da8-964a-0e353c6c3bbc',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 36,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 05:09 AM\r\n\r\nMessage: Javascript\r\n',
    createdTime: '2021-05-24T14:09:21.501951Z',
    correlationKey: '385d5737-b200-4ebb-ad1f-8c1996122220',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 35,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 05:02 AM\r\n\r\nMessage: stack overflow\r\n',
    createdTime: '2021-05-24T14:02:02.582029Z',
    correlationKey: '0f625d41-27a2-4dc7-8f75-05e25987735b',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 34,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 04:51 AM\r\n\r\nMessage: ok bernadi\r\n',
    createdTime: '2021-05-24T13:51:35.699301Z',
    correlationKey: 'e6e471fd-04d0-4e92-ba2a-4f721a2955b0',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 33,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 04:23 AM\r\n\r\nMessage: Message for Le Bernardi\r\n',
    createdTime: '2021-05-24T13:23:02.301612Z',
    correlationKey: '38f0e26c-759f-4599-a61f-683e3b71a430',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 32,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 04:16 AM\r\n\r\nMessage: Message for Le Bernadi\r\n',
    createdTime: '2021-05-24T13:16:48.365568Z',
    correlationKey: '5eedac8b-b0b7-4e11-bd93-4277382e7fe5',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 31,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 04:03 AM\r\n\r\nMessage: Fine\r\n',
    createdTime: '2021-05-24T13:03:59.033195Z',
    correlationKey: 'a379804c-9598-4f70-a529-4799595616bf',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 30,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 04:03 AM\r\n\r\nMessage: ok\r\n',
    createdTime: '2021-05-24T13:03:44.297026Z',
    correlationKey: '8d49a68b-a6e4-44ad-9a36-81cdd7f5ef64',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 29,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 03:43 AM\r\n\r\nMessage: Permanence, perseverance and persistence in spite of all obstacles, discouragements, and impossibilities: It is this, that in all things distinguishes the strong soul from the weak.\r\n',
    createdTime: '2021-05-24T12:43:42.737601Z',
    correlationKey: 'ea072919-d977-4e59-bde7-7cf6e872f663',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 28,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 03:36 AM\r\n\r\nMessage: Do not mind anything that anyone tells you about anyone else. Judge everyone and everything for yourself.\r\n',
    createdTime: '2021-05-24T12:36:32.933555Z',
    correlationKey: '2076aea1-fa28-4dc5-a655-e182e4955ada',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 27,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 03:36 AM\r\n\r\nMessage: There is nothing permanent except change.\r\n',
    createdTime: '2021-05-24T12:36:11.842738Z',
    correlationKey: '821c9f2d-332f-4c8b-9d5f-80d064d01087',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 26,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 03:35 AM\r\n\r\nMessage: This is my long test\r\n',
    createdTime: '2021-05-24T12:35:42.994318Z',
    correlationKey: '5e751f7f-e7be-41c0-92e3-f2943b1c3d51',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 25,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 03:34 AM\r\n\r\nMessage: This is 26th message\r\n',
    createdTime: '2021-05-24T12:34:54.836833Z',
    correlationKey: 'ad187bb1-7b06-458d-a59d-048a08736346',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 24,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 03:34 AM\r\n\r\nMessage: This is 25th message\r\n',
    createdTime: '2021-05-24T12:34:45.243999Z',
    correlationKey: 'd369051a-6be9-459f-9a68-993c5e8dd482',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 23,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 03:31 AM\r\n\r\nMessage: This is 24th message\r\n',
    createdTime: '2021-05-24T12:31:21.992767Z',
    correlationKey: 'b3fac9df-c5a1-4fa6-bf80-636b5e1692ae',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 22,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 03:31 AM\r\n\r\nMessage: This is 22th message\r\n',
    createdTime: '2021-05-24T12:31:13.202118Z',
    correlationKey: '7e79dbc9-b5e4-4dd9-b566-6b9a1177f1dc',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 21,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 03:27 AM\r\n\r\nMessage: This is 21th message\r\n',
    createdTime: '2021-05-24T12:27:28.274111Z',
    correlationKey: '233602ef-9066-49bf-a8e4-47f6f6b01e21',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 20,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 03:25 AM\r\n\r\nMessage: This is 20th message\r\n',
    createdTime: '2021-05-24T12:25:13.441104Z',
    correlationKey: '378efb2f-a1c3-40ae-a49f-28ce410ab2f2',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 19,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 03:24 AM\r\n\r\nMessage: This is 19th message\r\n',
    createdTime: '2021-05-24T12:24:29.745758Z',
    correlationKey: '95e3f564-071a-4d0d-b49d-15f57aadc706',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 18,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 02:49 AM\r\n\r\nMessage: This is 18th message\r\n',
    createdTime: '2021-05-24T11:49:18.37249Z',
    correlationKey: '31cd44f1-412f-41ab-8144-7ee25b277517',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 17,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 02:49 AM\r\n\r\nMessage: This is 17th message\r\n',
    createdTime: '2021-05-24T11:49:09.817355Z',
    correlationKey: '62fcff91-eced-4cce-8673-c2b719e587e5',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 16,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 02:47 AM\r\n\r\nMessage: This is 16th message\r\n',
    createdTime: '2021-05-24T11:47:54.41989Z',
    correlationKey: 'f89b705f-a67a-4117-a996-fdcc427773e2',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 15,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 02:47 AM\r\n\r\nMessage: This is 15th message\r\n',
    createdTime: '2021-05-24T11:47:37.129212Z',
    correlationKey: 'ede86825-9c76-4709-bddc-0c59c758cd16',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 14,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 02:46 AM\r\n\r\nMessage: This is 14th message\r\n',
    createdTime: '2021-05-24T11:46:44.863351Z',
    correlationKey: 'e2bfde49-bfb1-4dcd-8220-81dd3fb5d136',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 13,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 02:43 AM\r\n\r\nMessage: This is 13th message\r\n',
    createdTime: '2021-05-24T11:43:37.550537Z',
    correlationKey: 'ff0923f8-d8b2-4e47-817c-457cafd27363',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 12,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 02:42 AM\r\n\r\nMessage: This is 12th message\r\n',
    createdTime: '2021-05-24T11:42:37.414129Z',
    correlationKey: '4cea84c9-6260-4f87-b25c-bee297121502',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 11,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 02:39 AM\r\n\r\nMessage: This is 11th message\r\n',
    createdTime: '2021-05-24T11:39:14.79117Z',
    correlationKey: '3ee76f66-4a4d-43d1-b16a-8877b8430749',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 10,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 02:34 AM\r\n\r\nMessage: This is 10th message\r\n',
    createdTime: '2021-05-24T11:34:40.716991Z',
    correlationKey: '53c8ee44-b154-4d95-b69f-91079e5f0fcb',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 9,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 02:27 AM\r\n\r\nMessage: This is 9th message\r\n',
    createdTime: '2021-05-24T11:27:19.105448Z',
    correlationKey: 'c2789600-1081-4101-a04c-387cfd187a7e',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 8,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 02:26 AM\r\n\r\nMessage: This is 8th message\r\n',
    createdTime: '2021-05-24T11:26:28.348235Z',
    correlationKey: 'ec960a41-5442-4131-a893-1682e1f7bbb8',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 7,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 02:25 AM\r\n\r\nMessage: This is 7th message\r\n',
    createdTime: '2021-05-24T11:25:30.699059Z',
    correlationKey: 'ce008255-8288-459c-b756-df2556ad53ee',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 6,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 02:24 AM\r\n\r\nMessage: This is 6th message\r\n',
    createdTime: '2021-05-24T11:24:37.709058Z',
    correlationKey: 'f76e094a-be39-4071-a7d3-0bd972d9d5b1',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 5,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 02:17 AM\r\n\r\nMessage: This is 5th message\r\n',
    createdTime: '2021-05-24T11:17:48.143417Z',
    correlationKey: '5c5cabd4-18c2-4fd3-af60-ae52d6bfc025',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 4,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 02:16 AM\r\n\r\nMessage: This is 4th message\r\n',
    createdTime: '2021-05-24T11:16:48.276526Z',
    correlationKey: '1169de1d-d5bb-4c56-b077-4bffcbefa959',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 3,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 02:09 AM\r\n\r\nMessage: This is 3rd message\r\n',
    createdTime: '2021-05-24T11:09:08.288665Z',
    correlationKey: 'f0afea9e-bf6a-44b0-805a-15cd9dbc7780',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 2,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 02:06 AM\r\n\r\nMessage: This is 2nd message\r\n',
    createdTime: '2021-05-24T11:06:35.17502Z',
    correlationKey: '259d38f1-070b-4fc4-a042-23b098540266',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
  {
    id: 1,
    notificationChannel: 'Record',
    subject: 'Message From Zubair Ashraf',
    content:
      'You have a message from Zubair Ashraf on Monday, 24 May 2021 12:32 AM\r\n\r\nMessage: Hello\r\n',
    createdTime: '2021-05-24T09:32:22.108691Z',
    correlationKey: '217237ac-ca5e-480d-be8f-656782de4559',
    sentChannels: ['Unknown'],
    isRead: false,
    isViewed: false,
    business: {
      id: 1,
      name: 'Le Bernardi',
      timeZone: 'Eastern Standard Time',
      picture: {
        path: 'http://via.placeholder.com/640x360',
      },
    },
  },
];
