import { string } from 'prop-types';
import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = ['image']
    .concat(
      ['size']
        .map((name) => (props[name] ? `is-${props[name]}` : null))
        .filter((className) => !!className)
    )
    .join(' ');

  return classNames;
};

const Figure = styled.figure.attrs((props) => ({
  className: className`${props}`,
}))``;

Figure.propTypes = {
  size: string,
};

Figure.defaultProps = {
  size: undefined,
};

export default Figure;
