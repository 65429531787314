import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = ['control'].join(' ');

  return classNames;
};

const Addon = styled.div.attrs((props) => ({
  className: className`${props}`,
}))``;

Addon.propTypes = {};

Addon.defaultProps = {};

export default Addon;
