import React from 'react';
import styled from 'styled-components';
import { Icon } from '@safelyq/bulma-ui-library';

const UploadButton = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 1px dashed grey;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const StyledImage = styled.img`
  width: 50px;
  height: 50px;
  objectfit: cover;
  border: 1px dashed grey;
`;

const DeleteButton = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const StyledControl = styled.div`
  &:hover ${DeleteButton} {
    display: flex;
  }
`;

export const UploadControl = ({
  name,
  images,
  onUpload,
  onRemove,
  onDelete,
}) => {
  return (
    <div className='is-flex is-flex-wrap-wrap'>
      {images[name].length > 0 &&
        images[name].map(({ base64String, id, path }, index) => (
          <StyledControl className='control is-relative mr-4 my-1'>
            <StyledImage src={path || window.atob(base64String)} />
            <DeleteButton
              onClick={
                base64String ? () => onRemove(index, name) : () => onDelete(id)
              }
            >
              <Icon name='trash-o' color='white' />
            </DeleteButton>
          </StyledControl>
        ))}
      {(name !== 'logo'
        ? images[name].length < 5
        : images[name].length < 1) && (
        <div className='control mr-4 my-1'>
          <label for='file-input'>
            <UploadButton>
              <Icon name='picture-o' />
            </UploadButton>
          </label>
          <input
            id='file-input'
            type='file'
            accept='image/*'
            name={name}
            style={{ opacity: 0, position: 'absolute', top: 10, width: 50 }}
            onChange={onUpload}
          />
        </div>
      )}
    </div>
  );
};
