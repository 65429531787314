import React from 'react';
import { bool, func, oneOf, oneOfType, string } from 'prop-types';
import { isFunction, isUndefined } from 'lodash';
import Glyph from './styles/Glyph';

const className = (strings, props) => {
  const classNames = ['icon']
    .concat(
      ['color']
        .map((name) => (props[name] ? `has-text-${props[name]}` : null))
        .filter((className) => !!className)
    )
    .concat(
      ['position', 'size']
        .map((name) => (props[name] ? `is-${props[name]}` : null))
        .filter((className) => !!className)
    )
    .join(' ');

  return classNames;
};

const Icon = ({ name, collection, scale, when, ...props }) => {
  if (isFunction(when) ? !when() : !isUndefined(when) && !when) {
    return null;
  }

  const { 'data-tooltip': tooltip } = props;

  return (
    <span className={className`${props}`} data-tooltip={tooltip} {...props}>
      <Glyph collection={collection} name={name} scale={scale} />
    </span>
  );
};

Icon.propTypes = {
  name: string.isRequired,
  collection: string,
  color: oneOf([
    'black',
    'white',
    'dark',
    'light',
    'primary',
    'link',
    'info',
    'success',
    'warning',
    'danger',
  ]),
  position: oneOf(['right', 'left']),
  size: oneOf(['small', 'medium', 'large']),
  scale: oneOf(['xs', 'sm', 'lg']),
  when: oneOfType([bool, func]),
};

Icon.defaultProps = {
  collection: 'fa',
  color: undefined,
  position: undefined,
  size: undefined,
  scale: undefined,
  when: undefined,
};

export default Icon;
