import { gql } from '@apollo/client';

export const GET_USER_ROLES = gql`
  query all {
    getRoles {
      isSuccess
      errorMessage
      roles {
        id
        name
      }
    }
  }
`;

export const SAVE_USER_ROLE = gql`
  mutation ($businessUserPermissionsInput: BusinessUserPermissionsInput!) {
    saveBusinessUserRoles(businessUserPermissionsInput: $businessUserPermissionsInput) {
      isSaved
      errorMessage
      businessUsers {
        user {
          id
          email
          name
        }
        role {
          id
          name
        }
      }
    }
  }
`;
