import React, { useEffect } from 'react';
import { Section, Container, Title } from '@safelyq/bulma-ui-library';
import { Loader } from '@safelyq/base-feature';
import { NotificationCard, EmptyCard } from '../../components';
import { useData } from './hooks';
import { setNotificationCount } from 'Redux/BusinessSlice/Business';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
//for firestore
import firestore from '@firebase';
import { COLLECTION } from 'constants/index';

export const ManageNotifications = () => {
  const { businessId } = useParams();
  const dispatch = useDispatch();
  const unviewedCountGlobal = useSelector(state => state?.business?.notificationCount);

  const { isLoading, error, refetch, getAdminNotifications, data: notifications, unviewedCount, onViewedNotifications } = useData();


  // call mark as viewed api
  useEffect(() => {
    // Call onViewedNotifications and on response to that call refetch only when count exist else call getAdminNotifications
    unviewedCountGlobal && onViewedNotifications({
      variables: { businessId }
    })
  }, [])

  // Set to global state
  useEffect(() => {
    dispatch(setNotificationCount(unviewedCount));
  }, [unviewedCount])

  // Collection will be triggered with respective value change
  useEffect(() => {
    const unsubscribeChat = firestore
      .collection(COLLECTION.CHAT)
      .where('businessId', '==', `${businessId}`)
      .onSnapshot(async querySnapshot => {
        onTriggerWebSocket();
      });

    const unsubscribeNotifications = firestore
      .collection(COLLECTION.NOTIFICATIONS)
      .where('businessId', '==', `${businessId}`)
      .onSnapshot(async querySnapshot => {
        onTriggerWebSocket();
      });

    const unsubscribeComments = firestore
      .collection(COLLECTION.COMMENTS)
      .where('businessId', '==', `${businessId}`)
      .onSnapshot(async querySnapshot => {
        onTriggerWebSocket();
      });

    const unsubscribeAppointments = firestore
      .collection(COLLECTION.APPOINTMENTS)
      .where('businessId', '==', `${businessId}`)
      .onSnapshot(async querySnapshot => {
        onTriggerWebSocket();
      });

    const unsubscribeRatings = firestore
      .collection(COLLECTION.RATINGS)
      .where('businessId', '==', `${businessId}`)
      .onSnapshot(async querySnapshot => {
        onTriggerWebSocket();
      });

    return () => {
      unsubscribeChat();
      unsubscribeNotifications();
      unsubscribeComments();
      unsubscribeAppointments();
      unsubscribeRatings();
    };
  }, [businessId]);

  const onTriggerWebSocket = () => {
    if (businessId) {
      // if unviewed count is 0 than call getAdminNotifications api else it will called from onViewedNotifications
      !unviewedCountGlobal && getAdminNotifications({ variables: { businessId } });
    }
  };

  const totalNotifications = !notifications ? 0 : notifications.length;

  const isEmptyNotifications = totalNotifications === 0;

  if (isLoading) return <Loader isFullScreen />;

  if (error) return <Loader isFullScreen />;

  if (isEmptyNotifications) return <EmptyCard />;

  return (
    <Section>
      <Container>
        <Title size='5'>Notifications ({totalNotifications})</Title>
        {notifications.map(({ ...notification }, index) => (
          <NotificationCard
            key={`notification-${index}`}
            {...notification}
            refetch={refetch}
          />
        ))}
      </Container>
    </Section>
  );
};
