import { gql } from '@apollo/client';

export const DELETE_BUSINESS = gql`
  mutation DeleteBusiness($businessId: Int!) {
    deleteBusiness(businessId: $businessId) {
      errorMessage
      isSaved
    }
  }
`;


export const UPDATE_BUSINESS_DATA = gql`
  mutation SaveBusiness($businessInput: BusinessInput!) {
    saveBusiness(businessInput: $businessInput) {
      isSaved
      errorMessage
    }
  }
`
