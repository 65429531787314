import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Icon } from '@safelyq/bulma-ui-library';

export const AdminMenuList = () => {
  const { state } = useLocation() || {};

  const { currentMenu } = state || { currentMenu: 0 };

  return (
    <Box className='is-hidden-mobile' shadow={true}>
      <aside className='menu'>
        <p className='menu-label'>General</p>
        <ul className='menu-list'>
          <li>
            <Link
              to={{
                pathname: '/admin/account/setting',
                state: { currentMenu: 0 },
              }}
              className={currentMenu === 0 ? 'is-active' : ''}
            >
              <Icon name='user' /> Profile
            </Link>
          </li>
          <li>
            <Link
              to={{
                pathname: '/admin/account/notifications',
                state: { currentMenu: 1 },
              }}
              className={currentMenu === 1 ? 'is-active' : ''}
            >
              <Icon name='bell' /> Business Notifications
            </Link>
          </li>
        </ul>
      </aside>
    </Box>
  );
};
