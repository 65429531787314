import React, { Children } from 'react';
import { bool, node, oneOf } from 'prop-types';
import Addon from '../Addon';

const className = (strings, props) => {
  const classNames = ['field']
    .concat(
      ['horizontal', 'grouped']
        .map((name) => (props[name] ? `is-${name}` : null))
        .filter((className) => !!className)
    )
    .concat(
      ['alignment']
        .map((name) => (props[name] ? `is-grouped-${props[name]}` : null))
        .filter((className) => !!className)
    )
    .concat(
      ['has-addons']
        .map((name) => (props[name] ? `${name}` : null))
        .filter((className) => !!className)
    )
    .join(' ');

  return classNames;
};

const Field = ({ horizontal, grouped, alignment, children }) => {
  if (horizontal) {
    return children;
  }

  const hasAddons = Children.toArray(children).some(
    (child) => child.type === Addon
  );

  const props = {
    horizontal,
    grouped: grouped || !!alignment,
    alignment,
    'has-addons': hasAddons,
  };

  return <div className={className`${props}`}>{children}</div>;
};

Field.propTypes = {
  horizontal: bool,
  grouped: bool,
  alignment: oneOf(['left', 'centered', 'right', 'multiline']),
  children: node,
};

Field.defaultProps = {
  horizontal: undefined,
  grouped: undefined,
  alignment: undefined,
  children: undefined,
};

export default Field;
