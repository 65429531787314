import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { isEmpty } from "lodash";
import {
    Section,
    Container,
    Title,
    Columns,
    Column,
    Field,
    Icon,
    Control,
    TextInput,
    Table,
    Button,
} from "@safelyq/bulma-ui-library";
import { Loader } from "@safelyq/base-feature";
import { NoSearchFound } from "./NotFoundSection";
import useGetOrganization from "./GetOrganization/hook"
import useDeleteOrganization from "./DeleteOrganization/hooks"
import { toast } from 'react-toastify';
import EditOrganization from "../admin-edit-organization/EditOrganization"
import BreadCrumbs from "features/business-admin/src/components/Breadcrumbs";

const StyledSearchBar = styled(TextInput).attrs(() => ({}))`
  background: rgba(0, 0, 0, 0.02);
`;

const StyledList = styled.td.attrs(() => ({
    className: "py-3 pl-2",
}))``;

const Organization = () => {

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const { deleteOrganization, data } = useDeleteOrganization();

    const [isRefetching, setIsRefetching] = useState(false);
    const [foundOrg, setFoundOrg] = useState();

    const { isLoading, error, data: organizations, onSearch, refetch } = useGetOrganization();

    const handleSearch = ({ target: { value } }) => {
        onSearch(value);
    };

    const isEmptyOrganization = isEmpty(organizations);

    if (isLoading || isRefetching) return <Loader isFullScreen />;

    if (error) return <small>Error</small>;

    const showErrorPopup = (errorMessage) => {
        toast.error(errorMessage);
    };

    // Function to delete the business
    const handleDelete = async (organizationId) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this business?');
        if (confirmDelete) {
            setIsRefetching(true);
            await deleteOrganization(organizationId).then(
                result => {
                    if (result.isSaved) {
                        toast.success('Organization Deleted successfully!');
                        refetch();
                    } else if (result.errorMessage) {
                        toast.error("Delete Failed:" + result.errorMessage);
                    }
                }
            ).catch(err => {
                showErrorPopup('An error occurred while deleting.');
            }
            )
            setIsRefetching(false);
        }
    };

    const handleClose = () => {
        setIsPopupOpen(false);
    };

    // Function to delete the business
    const handleUpdate = (id) => {
        let orgFound = organizations.find(x => x.id === id)
        setIsPopupOpen(true);
        setFoundOrg(orgFound);
        refetch();
    };

    return (
        <>
            {isPopupOpen && <EditOrganization foundOrg={foundOrg} isOpen={isPopupOpen} onClose={handleClose} refetch={refetch} />}
            <Section>
                <Container className="mb-4">
                    <BreadCrumbs />
                </Container>
                <Container>
                    <header className="is-flex is-justify-content-space-between is-align-items-center">
                        <Title size="5" className="m-0">
                            Organization
                        </Title>
                    </header>
                    <Columns className="m-0">
                        <Column size="3" className="pl-0">
                            <Field>
                                <Control>
                                    <StyledSearchBar
                                        placeholder="Search Organization"
                                        onChange={handleSearch}
                                    />
                                    <Icon name="search" position="right" />
                                </Control>
                            </Field>
                        </Column>
                    </Columns>
                    <div className="is-hidden-mobile">
                        {isEmptyOrganization ? (
                            <NoSearchFound />
                        ) : (
                            <Table fullwidth hoverable>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th className="pl-2">Name</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className="has-text-black">
                                    {
                                        organizations.length === 0 ? (
                                            <tr>
                                                <td>
                                                    Not Found
                                                </td>
                                            </tr>
                                        ) : (
                                            organizations.map(
                                                (
                                                    { id, name },
                                                    index
                                                ) => (
                                                    <tr className="py-3" key={`locations-${index}`}>
                                                        <StyledList>{index + 1}</StyledList>
                                                        <StyledList>{name}</StyledList>
                                                        <StyledList>
                                                            <Button onClick={() => handleDelete(id)} className="is-danger is-light"><Icon name='trash' /></Button>
                                                            <Button onClick={() => handleUpdate(id)} className="is-info is-light ml-2"> <Icon name='edit' /></Button>
                                                        </StyledList>
                                                    </tr>
                                                )
                                            )
                                        )
                                    }
                                </tbody>
                            </Table>
                        )}
                    </div>
                </Container>
            </Section>
        </>
    )
}

export default Organization;
