import React, { Fragment } from "react";
import styled from "styled-components";
import { Table, Title, Button, Icon } from "@safelyq/bulma-ui-library";
import { NoCategoryFound } from "./NoCategoryFound";
import { UpdateCategoriesModal } from "./UpdateBusinessCategory";

const StyledList = styled.td.attrs(() => ({
    className: "py-3 pl-2",
}))``;

export const CategoriesTable = ({
    categoriesState,
    loadingUpdate,
    handleDeleteCategory,
    handleIsOpenModal,
    handleModalClose,
    isOpenModal,
    categoryToUpdate,
    setCategoryToUpdate,
    initialCategoryData,
    handleSaveBusinessCategory,
    confirmUpdate,
    setConfirmUpdate,
    loadingDelete
}) => {

    // Props
    const updateNumbersProps = {
        isOpenModal,
        handleModalClose,
        categoryToUpdate,
        loadingUpdate,
        initialCategoryData,
        setCategoryToUpdate,
        handleSaveBusinessCategory,
        handleDeleteCategory,
        confirmUpdate,
        setConfirmUpdate,
        loadingDelete
    };

    return (
        <Fragment>
            <Table fullwidth hoverable>
                <thead>
                    <tr>
                        <th>#</th>
                        <th className="pl-2">Business Name</th>
                        <th className="has-text-centered">Coupon Allowed</th>
                        <th className="has-text-centered">Google Reserved Allowed</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody className="has-text-black">
                    {
                        categoriesState?.length === 0 ? (
                            <tr>
                                <td colSpan={4}>
                                    <NoCategoryFound />
                                </td>
                            </tr>
                        ) : (
                            categoriesState?.map(
                                (
                                    category,
                                    index
                                ) => (

                                    <tr className="py-3" key={`locations-${index}`}>
                                        <StyledList>{index + 1}</StyledList>
                                        <StyledList className="is-flex is-align-items-center">
                                            <Button color="info" className="icon-button">
                                                {String(category?.name).charAt(0).toUpperCase()}
                                            </Button>
                                            <div
                                                className="ml-2">
                                                <Title size="6" className="m-0">{category?.name}</Title>
                                                <small>{category?.description}</small>
                                            </div>
                                        </StyledList>
                                        <StyledList className="has-text-centered">{category?.features?.couponAllowed ? <Icon name="check" className="has-text-success" /> : <Icon name="close" className="has-text-danger" />}</StyledList>
                                        <StyledList className="has-text-centered">{category?.features?.googleReserveAllowed ? <Icon name="check" className="has-text-success" /> : <Icon name="close" className="has-text-danger" />}</StyledList>
                                        <StyledList>
                                            <Button
                                                onClick={() => handleIsOpenModal(category, "delete")}
                                                className="is-danger is-light"><Icon name='trash ' /></Button>
                                            <Button
                                                onClick={() => handleIsOpenModal(category, "edit")}
                                                className="is-info is-light ml-2"><Icon name='edit ' /></Button>
                                        </StyledList>
                                    </tr>
                                )
                            )
                        )}
                </tbody>
            </Table>

            {
                updateNumbersProps.isOpenModal && <UpdateCategoriesModal {...updateNumbersProps} />
            }

        </Fragment>
    );
};
