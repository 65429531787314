import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = ['message-body'].join(' ');

  return classNames;
};

const MessageBody = styled.section.attrs((props) => ({
  className: className`${props}`,
}))``;

MessageBody.propTypes = {};

MessageBody.defaultProps = {};

export default MessageBody;
