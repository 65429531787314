import {
    Container, Section, Block, Title, Icon, Columns, Column, Field, Control, DateInput, TextInput,
    Button,
    Table
} from "@safelyq/bulma-ui-library";
import BreadCrumbs from "features/business-admin/src/components/Breadcrumbs";
import React, { Fragment, useEffect, useState } from 'react'
import { useAppUsers } from "./hooks";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { FilterBy, FilterByConstants } from "features/marketing-user-count/src/MarketingUser/contants";
import { getStatusColor } from "methods/getStatuses";
import moment from "moment";
import { ClipLoader } from "react-spinners";
import Pagination from "components/PaginationGlobal";
import { Dropdown } from 'antd';
import { AiOutlineMessage } from "react-icons/ai";
import { HiOutlineHeart } from "react-icons/hi";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { useHistory, useLocation } from "react-router-dom";
import AdminConversations from "./components/AdminConversations";
import "./style.css";

const ApplicationUsers = () => {

    /* States */
    const history = useHistory();
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const page = queryParams.get('page');
    const userId = queryParams.get('userId');

    const [paginationVariables, setPaginationvariables] = useState({
        currentPage: 1,
        totalPages: 0,
        skip: 0,
        take: 10
    })

    const [showUsers, setShowUsers] = useState(false);

    const [filters, setFilter] = useState({
        name: true,
        email: false,
        country: false,
        phoneNumber: false,
        state: false,
        zipCode: false,
    })

    const [filterValues, setFilterValues] = useState({
        startDate: null,
        endDate: null,
        search: null,
    });

    /* Custom hookjs for api */
    const {
        loadingAppUsers,
        appUsers,
        getAppUsers
    } = useAppUsers();

    /* UseEffectts */
    useEffect(() => {
        if (appUsers) {
            setPaginationvariables({
                ...paginationVariables,
                currentPage: (paginationVariables?.skip / 10) + 1,
                totalPages: Math.ceil(appUsers?.totalCount / 10)
            })
        }
    }, [appUsers])

    useEffect(() => {
        const searchVariable = {
            startDate: filterValues.startDate ? moment(filterValues.startDate).format('YYYY-MM-DD') : null,
            endDate: filterValues.endDate ? moment(filterValues.endDate).format('YYYY-MM-DD') : null,
            name: filters?.name ? filterValues?.search : null,
            email: filters?.email ? filterValues?.search : null,
            country: filters?.country ? filterValues?.search : null,
            phoneNumber: filters?.phoneNumber ? filterValues?.search : null,
            zipCode: filters?.zipCode ? filterValues?.search : null,
            state: filters?.state ? filterValues?.search : null,
            paginationInputModel: {
                skip: paginationVariables?.skip,
                take: paginationVariables?.take
            }
        }

        getAppUsers({
            variables: {
                selectMarketingUsersInput: searchVariable
            }
        });
        setShowUsers(true);
    }, [paginationVariables?.skip])

    /* Functions */
    const handleFilterCheckboxChange = (keyName, checked) => {
        // Set all filters to false, then set the selected filter to true
        const newFilters = Object.keys(filters).reduce((acc, key) => {
            acc[key] = false;
            return acc;
        }, {});
        newFilters[keyName] = checked;

        setFilter(newFilters);
    }

    const handleFilterInputChange = (e) => {
        const { value, name } = e.target;
        setFilterValues({
            ...filterValues,
            [name]: value
        })
    }

    const handleSearchUsers = (e) => {
        e.preventDefault();
        const searchVariable = {
            startDate: filterValues.startDate ? moment(filterValues.startDate).format('YYYY-MM-DD') : null,
            endDate: filterValues.endDate ? moment(filterValues.endDate).format('YYYY-MM-DD') : null,
            name: filters?.name ? filterValues?.search : null,
            email: filters?.email ? filterValues?.search : null,
            country: filters?.country ? filterValues?.search : null,
            phoneNumber: filters?.phoneNumber ? filterValues?.search : null,
            zipCode: filters?.zipCode ? filterValues?.search : null,
            state: filters?.state ? filterValues?.search : null,
            paginationInputModel: {
                // skip: paginationVariables?.skip,
                // take: paginationVariables?.take
                skip: 0,
                take: 10
            }
        }

        getAppUsers({
            variables: {
                selectMarketingUsersInput: searchVariable
            }
        });
        setShowUsers(true);
    }

    const handlePageChange = (page) => {
        setPaginationvariables({
            ...paginationVariables,
            skip: (page * 10) - 10,
            take: 10,
            currentPage: page
        })
    };

    // drop down for the details
    const items = [
        {
            label: 'Conversations',
            name: "conversations",
            key: '0',
            icon: <AiOutlineMessage />
        },
        {
            label: 'Favourites',
            name: "favourites",
            key: '1',
            icon: <HiOutlineHeart />
        },
    ];

    const handleConversations = (id) => {
        history.push(`?userId=${id}&page=conversations`)
    }

    const handleFavourites = () => {

    }

    // props
    const conversationProps = {
        userId
    }

    return (
        <Container style={{ height: "90vh" }}>
            <Section>
                <Container className="mb-4">
                    <BreadCrumbs />
                </Container>
                <Fragment>
                    <Block className='is-flex is-align-items-center is-justify-content-space-between'>
                        <Title size='5' className='mb-0'>   <Icon name='calendar' className='mr-3' />  App Users </Title>
                    </Block>

                    {
                        page === "conversations"
                            ?
                            <AdminConversations conversationProps={conversationProps} />
                            :
                            <>
                                <Columns multiline>
                                    <Column size='3'>
                                        <Field>
                                            <Control>
                                                <StyledDateInputBar name='startDate'
                                                    selected={filterValues.startDate}
                                                    onChange={(data) => handleFilterInputChange(data)}
                                                />
                                                <small className='has-text-grey'>   <i>Choose Date Range Start</i>  </small>
                                            </Control>
                                        </Field>
                                    </Column>
                                    <Column size='3'>
                                        <Field>
                                            <Control>
                                                <StyledDateInputBar
                                                    name='endDate'
                                                    selected={filterValues.endDate}
                                                    onChange={(data) => handleFilterInputChange(data)}
                                                />
                                                <small className='has-text-grey'> <i>Choose Date Range End</i>   </small>
                                            </Control>
                                        </Field>
                                    </Column>
                                    <Column size='4'>
                                        <Field>
                                            <Control>
                                                <StyledInputField
                                                    name='search'
                                                    placeholder={`Enter ${FilterByConstants[Object.keys(filters).find(key => filters[key])] || "search text"}`}
                                                    value={filterValues?.search}
                                                    onChange={(data) => handleFilterInputChange(data)}
                                                />
                                                <small className='has-text-grey'> <i>Search App Users</i>   </small>
                                            </Control>
                                        </Field>
                                    </Column>
                                    <Column size='2' className='is-flex is-justify-content-center' >
                                        {/* <Button data-tip data-for='advanceSearch' onClick={() => setAdvanceSearchOpen(true)}>
                                <Icon name='filter' color='info' scale='lg' />
                            </Button> */}
                                        <div className=''>
                                            <div className='dropdown is-hoverable'>
                                                <div className='dropdown-trigger'>
                                                    <Button
                                                        className='button-icon'
                                                        aria-haspopup='true'
                                                        aria-controls='actions-dropdown'
                                                    >
                                                        <Icon name='filter' color='info' scale='lg' />
                                                    </Button>
                                                </div>
                                                <div className='dropdown-menu' id='actions-dropdown' role='menu'>
                                                    <div className='dropdown-content'>
                                                        {Object.keys(FilterBy).map((keyName, index) => (
                                                            <label className='checkbox dropdown-item'>
                                                                <div className='control'>
                                                                    <input
                                                                        type='checkbox'
                                                                        name={keyName}
                                                                        value={filters[keyName]}
                                                                        checked={filters[keyName]}
                                                                        onChange={({ target: { checked } }) =>
                                                                            handleFilterCheckboxChange(keyName, checked)
                                                                        }
                                                                    />
                                                                    <span
                                                                        key={`status-${index}`}
                                                                        className={`tag is-light ml-2`}
                                                                        style={{
                                                                            background: `rgba(${getStatusColor(
                                                                                keyName
                                                                            )}, 0.1)`,
                                                                            color: `rgb(${getStatusColor(keyName)})`,
                                                                        }}
                                                                    >
                                                                        {FilterByConstants[keyName]}
                                                                    </span>
                                                                </div>
                                                            </label>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <Button className='ml-4 is-info'
                                            onClick={handleSearchUsers}
                                        >
                                            <small>Search</small>
                                            <Icon name='search' className="px-2" />
                                        </Button>

                                        <ReactTooltip id='advanceSearch' place='top' effect='solid'>
                                            Click for Advance Search
                                        </ReactTooltip>
                                    </Column>
                                </Columns>

                                {
                                    !showUsers
                                        ?
                                        <div className='is-flex is-justify-content-center is-align-items-center' style={{ marginTop: "100px" }}>
                                            <div className='has-text-centered'>
                                                <Icon name='users' className="px-2 py-auto" style={{ fontSize: "80px", color: "grey" }} />
                                                <p className='is-size-5 has-text-grey'>Search for application users by entering a name or email above</p>
                                            </div>
                                        </div>
                                        :
                                        loadingAppUsers
                                            ?
                                            <div className='is-fullWidth my-5 is-flex is-justify-content-center'>
                                                <ClipLoader size='40' />
                                            </div>

                                            :
                                            <Table fullwidth hoverable>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th className="pl-2">Name</th>
                                                        <th>Phone Number</th>
                                                        <th>Email</th>
                                                        <th>Created Date</th>
                                                        <th>Details</th>
                                                        {/* <th>Zip Code</th> */}
                                                    </tr>
                                                </thead>
                                                {(appUsers && appUsers?.users?.length > 0) ? (
                                                    <tbody className="has-text-black">
                                                        {appUsers?.users?.map(({ id, firstName, lastName, phoneNumber, email, createdTime }, index) => (
                                                            <tr key={`marketingUsers-${index}`}>
                                                                <StyledList>{index + 1}</StyledList>
                                                                <StyledList>{firstName} {lastName || "---"}</StyledList>
                                                                <StyledList>{phoneNumber || "---"}</StyledList>
                                                                <StyledList>{email || "---"}</StyledList>
                                                                <StyledList>{createdTime ? moment(createdTime).format('YYYY-MM-DD') : "---"}</StyledList>
                                                                <StyledList>
                                                                    <Dropdown
                                                                        menu={{
                                                                            items: items.map(item => ({
                                                                                ...item,
                                                                                onClick: () => {
                                                                                    if (item?.name === "conversations") {
                                                                                        return handleConversations(id);
                                                                                    }
                                                                                    else if (item?.name === "favourites") {
                                                                                        return handleFavourites(id);
                                                                                    }
                                                                                },
                                                                            })),
                                                                        }}
                                                                        trigger={['click']}
                                                                    >
                                                                        <p
                                                                            className='ml-2'
                                                                            style={{
                                                                                border: "1px solid #032B98", borderRadius: "25px", width: "32px", textAlign: "center", height: "32px", display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center"
                                                                            }}>

                                                                            <HiOutlineDotsHorizontal />
                                                                        </p>
                                                                    </Dropdown>
                                                                </StyledList>
                                                                {/* <StyledList>{zipCode}</StyledList> */}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                ) : (
                                                    <tbody>
                                                        <tr>
                                                            <StyledList className="has-text-black">No records found</StyledList>
                                                        </tr>
                                                    </tbody>
                                                )}
                                            </Table>
                                }

                                {showUsers && appUsers && appUsers?.users?.length > 0
                                    &&
                                    <Pagination
                                        currentPage={paginationVariables?.currentPage}
                                        totalPages={paginationVariables?.totalPages}
                                        onPageChange={handlePageChange}
                                    />
                                }
                            </>
                    }
                </Fragment>
            </Section>
        </Container >
    )
}

export default ApplicationUsers;

const StyledList = styled.td.attrs(() => ({
    className: "py-3 pl-2",
}))``;

const StyledDateInputBar = styled(DateInput).attrs(() => ({}))`
  background: rgba(0, 0, 0, 0.02);
`;

const StyledInputField = styled(TextInput).attrs(() => ({}))`
  background: rgba(0, 0, 0, 0.02);
`;