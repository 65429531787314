import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_MARKETING_USERS, Add_Marketing_Users } from './requests';
// import { useEffect } from 'react';
import { toast as showToast } from 'react-toastify';

export const useMarketingUser = () => {
  const [getMarketingUsers, { loading, error, data }] = useLazyQuery(GET_MARKETING_USERS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  return {
    loadingGetMarkeringUsers: loading,
    error,
    marketingUsers: data ? data.getMarketingUsers : null,
    getMarketingUsers,
  };
};


export const useSaveBusinessAttatchment = () => {
  const [onUploadAttachment, { loading: isSaving }] = useMutation(
    Add_Marketing_Users,
    {
      onCompleted: async ({ uploadBusinessFile }) => {
        const { isSaved, errorMessage } = uploadBusinessFile;
        if (isSaved) {
          showToast.success('File uploaded successfully')
        } else showToast.error(errorMessage || 'Failed to upload file');
      },
      onError: (error) => {
        showToast.error(error?.message || 'Something went wrong, Could not upload CSV');
      },
    }
  );
  return { isSaving, onUploadAttachment };
};