import { gql } from "@apollo/client";

export const GET_APPOINTMENT_BY_ID = gql`
  query GetAppointmentById($appointmentId: Int) {
    getUserAppointmentById(appointmentId: $appointmentId) {
      status
      children
      adults
      user {
        fullName
        email
        phoneNumber
      }
      business {
        id
        name
        isChildrenAllowed
        maxAllowedGuests
        services {
          id
          name
          businesses {
            id
            name
          }
        }
        timeZone
      }
      service {
        id
        isGoogleReserve
      }
      subBusiness {
        id
      }
      venueEntrance {
        name
      }
      comments {
        id
        comment {
          id
          commentText
          createdTime
          modifiedTime
          user {
            id
            firstName
            lastName
            email
          }
        }
        messageDirection
      }
      startTime
      endTime
      startTimeOnly
      timeZone
      responses {
        answer
        businessQuestion {
          id
          prompt
        }
      }
    }
  }
`;

export const UPDATE_APPOINTMENT = gql`
  mutation UpdateAppointment($appointmentInput: AppointmentInput!) {
    updateUserAppointment(appointmentInput: $appointmentInput) {
      isSaved
      errorMessage
      appointment {
        id
        startTime
        endTime
        status
        business{
          id
        }
        service {
          id
        }
        adults
        subBusiness {
          name
        }
        user {
          id
        }
      }
    }
  }
`;
