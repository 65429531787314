import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2';
import {
  Section,
  Container,
  Field,
  Control,
  Buttons,
  Button,
  Label,
  Title,
  Icon,
  TextInput,
  Columns,
  Column,
  DateInput,
  Subtitle
} from '@safelyq/bulma-ui-library';
import { Loader } from '@safelyq/base-feature';
import { AvailableSlots } from './components';
import { useData, useCreateAppointment } from './hooks';
import { EMAIL_REGEX, PHONE_REGEX } from './constants';
import { Link } from 'react-router-dom';

const currentDate = moment().format('YYYY-MM-DD');

export const AddAppointment = () => {
  let { businessId } = useParams();

  businessId = parseInt(businessId);

  const [isAnySubLevel, setIsSubLevel] = useState(true);

  const [appointmentInfo, setAppointmentInfo] = useState({
    date: currentDate,
  });

  const [userInfo, setUserInfo] = useState({});

  const { isLoading, data: business } = useData();

  const { isLoadingToCreate, onCreateAppointment } = useCreateAppointment();

  // const { services, timeZone, maxAllowedGuests, isChildrenAllowed } = business;

  const isGuestsAllowed = business?.maxAllowedGuests > 0;

  const isButtonEnable = () => {
    const { service, serviceProvider, date, slot } = appointmentInfo;

    const { email, phoneNumber } = userInfo;

    const isValidEmail = email ? EMAIL_REGEX.test(email) : true;

    const isValidPhone = phoneNumber ? PHONE_REGEX.test(phoneNumber) : true;

    const emailOrPhone = email || phoneNumber;

    if (
      !isEmpty(service) &&
      !isEmpty(date) &&
      !isEmpty(slot) &&
      !isEmpty(emailOrPhone) &&
      isValidEmail &&
      isValidPhone
    )
      if (isAnySubLevel && isEmpty(serviceProvider)) return false;
      else return true;
    else return false;
  };

  useEffect(() => {
    const isOnlyOneService = business?.services.length === 1;

    if (isOnlyOneService) {
      const service = business?.services[0].id;

      setAppointmentInfo({
        ...appointmentInfo,
        service: String(service),
      });
    }
  }, [business]);

  useEffect(() => {
    const { service: serviceId } = appointmentInfo;

    const service = business?.services.find(
      ({ id }) => parseInt(id) === parseInt(serviceId)
    );

    const isAnySubLevelExist = service?.businesses?.length > 0;

    setIsSubLevel(isAnySubLevelExist);
  }, [appointmentInfo]);

  const handleServiceChange = ({ target: { name, value } }) => {
    setAppointmentInfo({
      ...appointmentInfo,
      service: value,
      serviceProvider: 0,
      slot: {},
    });
  };

  const handleServiceProviderChange = ({ target: { value, name } }) =>
    setAppointmentInfo({ ...appointmentInfo, [name]: value, slot: {} });

  const handleAppointmentInfoChange = ({ target: { value, name } }) =>
    setAppointmentInfo({ ...appointmentInfo, [name]: value });

  const handleSlotChange = (slot) =>
    setAppointmentInfo({ ...appointmentInfo, slot });

  const handleUserInfoChange = ({ target: { name, value } }) =>
    setUserInfo({ ...userInfo, [name]: value });

  const handlePhoneChange = (phoneNumber) =>
    setUserInfo({ ...userInfo, phoneNumber });

  const handleCreateAppointment = () => {
    let { service, serviceProvider, slot, notes, adults, children } =
      appointmentInfo;

    if (!serviceProvider) serviceProvider = businessId;

    let appointmentInput = {
      businessId,
      children: children ? parseInt(children) : 0,
      adults: adults ? parseInt(adults) : 0,
      serviceId: parseInt(service),
      startTime: moment(slot.startTime).utc().format('YYYY-MM-DD hh:mm A'),
      endTime: moment(slot.endTime).utc().format('YYYY-MM-DD hh:mm A'),
      subBusinessId: parseInt(serviceProvider),
      notes,
      timeZone: business?.timeZone,
      userResponses: [],
    };

    if (userInfo.email == '') delete userInfo.email;

    if (userInfo.phoneNumber == '' || userInfo.phoneNumber == '+')
      delete userInfo.phoneNumber;

    const userInput = {
      ...userInfo,
      phoneNumber: userInfo.phoneNumber ? '+' + userInfo?.phoneNumber : '',
    };

    if (!isAnySubLevel)
      appointmentInput['subBusinessId'] = parseInt(businessId);

    onCreateAppointment({
      variables: {
        userInput,
        appointmentInput,
      },
    });
  };

  if (isLoading) return <Loader isFullScreen />;
  if (business === null) {
    return (<div>
      <section className='hero'>
        <div className='hero-body'>
          <Container className='has-text-centered'>
            <img src='/images/search-not-found.svg' className='mb-2' width={130} alt="nobusiness" />
            <Title size='5'>Your Business is inactve</Title>
            <Subtitle size='6'>

              <span>You cannot create appointment unless your business is active. To activate business <Link to={`/admin/manage-business/${businessId}`} className='has-text-weight-bold' >Click here</Link></span>

            </Subtitle>
          </Container>
        </div>
      </section>
    </div>)
  } else {
    return (
      <Section>
        <Container>
          <Title size='5'>
            <Icon name='calendar' className='mr-2' />
            Create new appointment
          </Title>
          <Columns multiline>
            <Column size='12'>
              <Title size='6' color='info' className='mb-4'>
                <i>User information</i>
              </Title>
              <hr className='m-0' />
            </Column>
            <Column size='4'>
              <Field>
                <Control>
                  <Label>First Name</Label>
                  <TextInput name='firstName' onChange={handleUserInfoChange} />
                </Control>
              </Field>
            </Column>
            <Column size='4'>
              <Field>
                <Control>
                  <Label>Last Name</Label>
                  <TextInput name='lastName' onChange={handleUserInfoChange} />
                </Control>
              </Field>
            </Column>
          </Columns>
          <Columns multiline>
            <Column size='4'>
              <Field>
                <Control>
                  <Label>Email</Label>
                  <TextInput name='email' onChange={handleUserInfoChange} />
                </Control>
              </Field>
            </Column>
            <Column size='4'>
              <Field>
                <Control>
                  <Label>Phone</Label>
                  <PhoneInput
                    inputClass='input'
                    countryCodeEditable={true}
                    country={'us'}
                    onChange={handlePhoneChange}
                  />
                </Control>
              </Field>
            </Column>
          </Columns>
          <Columns multiline>
            <Column size='12'>
              <Title size='6' color='info' className='mb-4'>
                <i>Appointment information</i>
              </Title>
              <hr className='m-0' />
            </Column>
            <Column size='4'>
              <Field>
                <Control>
                  <Label>
                    Appointment Date
                    <span className='ml-1 has-text-danger'>*</span>
                  </Label>
                  <DateInput
                    name='date'
                    value={appointmentInfo.date}
                    onChange={handleAppointmentInfoChange}
                  />
                </Control>
              </Field>
            </Column>
            <Column size='4'>
              <Field>
                <Control>
                  <Label>
                    Service
                    <span className='ml-1 has-text-danger'>*</span>
                  </Label>
                  <div className='select is-fullwidth'>
                    <select
                      name='service'
                      value={appointmentInfo.service}
                      onChange={handleServiceChange}
                    >
                      <option value={''}>Select Service</option>
                      {business?.services.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </select>
                  </div>
                </Control>
              </Field>
            </Column>
            {isAnySubLevel && (
              <Column size='4'>
                <Field>
                  <Control>
                    <Label>
                      Service Provider
                      <span className='ml-1 has-text-danger'>*</span>
                    </Label>
                    <div className='select is-fullwidth'>
                      <select
                        name='serviceProvider'
                        value={appointmentInfo.serviceProvider}
                        onChange={handleServiceProviderChange}
                      >
                        <option value={0}>Select Service Provider</option>
                        {business?.services
                          .find(
                            ({ id }) =>
                              parseInt(id) === parseInt(appointmentInfo.service)
                          )
                          ?.businesses.map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </Control>
                </Field>
              </Column>
            )}
            {isGuestsAllowed && (
              <Column size='4'>
                <Field>
                  <Label size='medium'>Additional Guests</Label>
                  <div className='select is-fullwidth'>
                    <select
                      name='adults'
                      value={appointmentInfo.adults}
                      onChange={handleAppointmentInfoChange}
                    >
                      <option value={''}>No of Guests</option>
                      {[...Array(business?.maxAllowedGuests)].map((_, index) => (
                        <option key={`guest-${index}`} value={index + 1}>
                          {index + 1}
                        </option>
                      ))}
                    </select>
                  </div>
                </Field>
              </Column>
            )}
          </Columns>
          <Columns multiline>
            <Column size='4'>
              {business?.isChildrenAllowed ? (
                <Field>
                  <Label size='medium'>Children in your party?</Label>
                  <div className='control'>
                    <label className='radio'>
                      <input
                        type='radio'
                        name='children'
                        value={1}
                        onChange={handleAppointmentInfoChange}
                        className='mr-2'
                      />
                      Yes
                    </label>
                    <label className='radio'>
                      <input
                        type='radio'
                        name='children'
                        value={0}
                        onChange={handleAppointmentInfoChange}
                        className='mr-2'
                      />
                      No
                    </label>
                  </div>
                </Field>
              ) : (
                <Field>
                  <Title size='6'>
                    <Icon name='times' color='danger' />
                    Adults only
                  </Title>
                </Field>
              )}
            </Column>
          </Columns>
          <Columns multiline>
            {!isEmpty(appointmentInfo) && (
              <AvailableSlots
                {...appointmentInfo}
                isAnySubLevel={isAnySubLevel}
                timeZone={business?.timeZone}
                onChangeSlot={handleSlotChange}
              />
            )}
          </Columns>

          <Columns multiline>
            <Column size='8'>
              <Field>
                <Label>Additional notes</Label>
                <Control>
                  <textarea
                    rows={3}
                    className='textarea'
                    name='notes'
                    onChange={handleAppointmentInfoChange}
                  />
                </Control>
              </Field>
            </Column>
          </Columns>
          <Columns multiline>
            <Column size='12'>
              <Buttons size='small'>
                <Button
                  color='info'
                  disabled={isLoadingToCreate || !isButtonEnable()}
                  onClick={handleCreateAppointment}
                >
                  Create Appointment
                </Button>
                <Button>Cancel</Button>
              </Buttons>
            </Column>
          </Columns>
        </Container>
      </Section>
    );
  }

};
