import React from 'react'
import { Icon, Table } from '@safelyq/bulma-ui-library';

export const TableView = ({ allServiceHolidays, allBusinessHolidays, handleInputChange, onDeleteholiday, handleEditMode }) => {
    return (
        <div class="table-container">

            <div style={{ border: "none", color: "#a7a7a7", textAlign: "center", fontSize: 12, padding: '22px 37px' }} colSpan="6">
                <div style={{ width: '100%', height: 11, borderBottom: '1px solid #d7d4d4', textAlign: 'center' }}>
                    <b style={{ fontSize: 12, backgroundColor: '#F3F5F6', padding: '4px 11px' }}> BUSINESS HOLIDAYS WORKING HOURS  </b>
                </div>
            </div>

            <Table fullwidth bordered>
                <thead>
                    <tr>
                        <th className='has-text-centered'>Day</th>
                        <th className='has-text-centered'>Opening Time</th>
                        <th className='has-text-centered'>Break Time / Duration</th>
                        <th className='has-text-centered'>Closing Time</th>
                        <th className='has-text-centered'>Status</th>
                    </tr>
                </thead>
                <tbody className='has-text-black'>


                    {
                        allBusinessHolidays.map(({ date, id, openingTime, closingTime, hasBreak, isOpen, breakStartTime, breakDuration, breakDurationError, openingTimeError, closingTimeError, hasBreakError, breakStartTimeError }, index) => {
                            return (
                                <tr>
                                    <td>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <input value={date} name="date" disabled={true} className='input' type='date' />
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <input className='input' name="openingTime" disabled={true} type='time' value={openingTime} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className='row g-0'>
                                            <div className='col-1  d-flex justify-content-center align-items-center'>
                                                <input type='checkbox' name="hasBreak" disabled={true} checked={hasBreak} />
                                            </div>
                                            <div className='col-5'>
                                                <input className='input' name="breakStartTime" type='time' disabled={true} value={breakStartTime} />
                                            </div>
                                            <div className='col-1  d-flex justify-content-center align-items-center'>
                                                <div>/</div>
                                            </div>
                                            <div className='col-3'>
                                                <input className='input' name="breakDuration" type='number' disabled={true} value={breakDuration} />
                                            </div>
                                            <div className='col-2  d-flex justify-content-center align-items-center'>
                                                <span>min</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <input className='input' name="closingTime" type='time' disabled={true} value={closingTime} />
                                    </td>
                                    <td>
                                        <b>{isOpen ? "Partial open" : "Full day off"}</b>
                                    </td>
                                </tr>
                            )
                        })
                    }

                </tbody>
            </Table>

            <Table fullwidth bordered>
                <thead>
                    <tr>
                        <th className='has-text-centered'>Day</th>
                        <th className='has-text-centered'>Partial Time</th>
                        <th className='has-text-centered'>Opening Time</th>
                        <th className='has-text-centered'>Break Time / Duration</th>
                        <th className='has-text-centered'>Closing Time</th>
                        <th className='has-text-centered'>Action</th>
                    </tr>
                </thead>
                <tbody className='has-text-black'>

                    {
                        allServiceHolidays.map(({ fullRowDisable, editMode, date, id, openingTime, closingTime, hasBreak, isOpen, breakStartTime, breakDuration, breakDurationError, openingTimeError, closingTimeError, hasBreakError, breakStartTimeError, businessStatus }, index) => {
                            const editModeStyle = {
                                pointerEvents: editMode ? "all" : "none",
                                background: editMode ? "white" : "#F5F5F5"
                            }
                            return (
                                <>
                                    <tr style={{ background: fullRowDisable ? "#f7f7aa" : "white" }}>
                                        <td style={editModeStyle}>
                                            <div className='row'>
                                                <div className='col-12'>

                                                    <input onChange={(e) => handleInputChange(e, index, date)} value={date} name="date" disabled={true} className='input' type='date' />
                                                </div>
                                            </div>
                                        </td>
                                        <td style={editModeStyle}>
                                            <div className='row mt-3'>
                                                <div className='col-12 d-flex justify-content-center align-items-center'>
                                                    <input type='checkbox' name="isOpen" checked={isOpen} disabled={businessStatus === "fulldayoff" ? fullRowDisable ? false : true : false} onChange={(e) => handleInputChange(e, index, date)} />
                                                </div>

                                            </div>
                                        </td>
                                        <td style={editModeStyle}>
                                            <div>
                                                <input onChange={(e) => handleInputChange(e, index, date)} className='input' name="openingTime" disabled={!isOpen} type='time' value={openingTime} />
                                                {openingTimeError && <span style={{ color: 'red' }}>{openingTimeError}</span>}
                                            </div>
                                        </td>
                                        <td style={editModeStyle}>
                                            <div className='row g-0'>
                                                <div className='col-1  d-flex justify-content-center align-items-center'>
                                                    <input onChange={(e) => handleInputChange(e, index, date)} type='checkbox' disabled={businessStatus === "fulldayoff" ? true : false} name="hasBreak" checked={hasBreak} />
                                                </div>
                                                <div className='col-5'>
                                                    <input onChange={(e) => handleInputChange(e, index, date)} className='input' name="breakStartTime" type='time' disabled={!hasBreak} value={breakStartTime} />
                                                    {breakStartTimeError && <span style={{ color: 'red' }}>{breakStartTimeError}</span>}
                                                </div>
                                                <div className='col-1  d-flex justify-content-center align-items-center'>
                                                    <div>/</div>
                                                </div>
                                                <div className='col-3'>
                                                    <input onChange={(e) => handleInputChange(e, index, date)} className='input' name="breakDuration" type='number' disabled={!hasBreak} value={breakDuration} />
                                                </div>
                                                <div className='col-2  d-flex justify-content-center align-items-center'>
                                                    <span>min</span>
                                                </div>
                                                {hasBreakError && <span style={{ color: 'red' }}>{hasBreakError}</span>}
                                                {breakDurationError && <span style={{ color: 'red' }}>{breakDurationError}</span>}
                                            </div>
                                        </td>
                                        <td style={editModeStyle}>
                                            <input onChange={(e) => handleInputChange(e, index, date, hasBreak)} className='input' name="closingTime" type='time' disabled={!isOpen} value={closingTime} />
                                            {closingTimeError && <span style={{ color: 'red' }}>{closingTimeError}</span>}
                                        </td>
                                        <td>
                                            <div className='is-flex is-justify-content-center is-align-items-center'>
                                                <Icon title="Delete" onClick={() => onDeleteholiday(id, date)} name='trash' color='danger' style={{ cursor: "pointer" }} />
                                                <Icon title="Edit" onClick={() => handleEditMode(index)} name='pencil' color='info' style={{ cursor: "pointer" }} />
                                            </div>
                                        </td>

                                    </tr>
                                    {fullRowDisable && <tr>
                                        <td colSpan={6}>
                                            <div style={{ fontWeight: 700, textAlign: "center", background: "#f7f7aa", fontSize: 13, padding: "0px 8px", borderRadius: 4 }}>Holiday deactivated due to conflict with business holiday hours</div>
                                        </td>
                                    </tr>}
                                </>

                            )
                        })
                    }

                </tbody>
            </Table>
        </div>
    )
} 