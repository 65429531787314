import { bool, oneOf } from 'prop-types';
import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = [
    'buttons'
  ]
    .concat(
      [
        'addons'
      ]
        .map((name) => props[name] ? `has-${name}` : null)
        .filter((name) => !!name)
    )
    .concat(
      [
        'alignment'
      ]
        .map((name) => props[name] && props[name] !== 'left' ? `is-${name}` : null)
        .filter((name) => !!name)
    )
    .concat(
      [
        'size'
      ]
        .map((name) => props[name] ? `are-${props[name]}` : null)
        .filter((name) => !!name)
    )
    .join(' ');

  return classNames;
};

const Buttons = styled.div.attrs((props) => ({
  className: className`${props}`
}))``;

Buttons.propTypes = {
  addons: bool,
  alignment: oneOf(
    [
      'left',
      'right',
      'centered'
    ]
  ),
  size: oneOf(
    [
      'small',
      'medium',
      'large'
    ]
  )
};

Buttons.defaultProps = {
  addons: undefined,
  alignment: undefined,
  size: undefined
};

export default Buttons;
