import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLongPress } from './hooks';
import Icon from '../Icon';
import LeftArrow from './styles/LeftArrow';
import RightArrow from './styles/RightArrow';

const StyledSlider = styled.div.attrs(() => ({
  className: 'is-flex',
}))`
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  white-space: nowrap;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Slider = ({ children, scrollOffset = 50 }) => {
  const ref = useRef(null);

  let interval;

  const [visible, setVisible] = useState({ left: false, right: true });

  const maxScrollRight = 0;

  const maxScrollLeft = ref.current?.scrollWidth - ref.current?.clientWidth;

  useEffect(() => {
    const isLeftScrollable =
      ref.current?.scrollWidth > ref.current?.clientWidth;
    setVisible({ ...visible, right: isLeftScrollable });
  }, []);

  const handleControlsVisible = (scrollPosition) => {
    if (scrollPosition <= maxScrollRight) {
      setVisible({ right: true, left: false });
      clearInterval(interval);
      return;
    }
    if (scrollPosition > maxScrollRight) {
      setVisible({ right: true, left: true });
    }
    if (scrollPosition < maxScrollLeft) {
      setVisible({ left: true, right: true });
    }
    if (scrollPosition >= maxScrollLeft) {
      setVisible({ left: true, right: false });
      clearInterval(interval);
    }
  };

  const handleScrollRight = () => {
    ref.current.scrollLeft -= scrollOffset;
    handleControlsVisible(ref.current.scrollLeft);
  };

  const handleScrollLeft = () => {
    ref.current.scrollLeft += scrollOffset;
    handleControlsVisible(ref.current.scrollLeft);
  };

  const handleWheelScroll = ({ deltaY }) => {
    const isLeftScrollable =
      ref.current?.scrollWidth > ref.current?.clientWidth;
    if (isLeftScrollable)
      if (deltaY === -100) handleScrollRight();
      else handleScrollLeft();
  };

  const handleMouseDown = (callBack) => {
    interval = setInterval(callBack, 500);
  };

  const handleMouseUp = () => {
    clearInterval(interval);
  };

  return (
    <StyledSlider ref={ref} onWheel={handleWheelScroll}>
      <LeftArrow
        onClick={handleScrollRight}
        onMouseDown={() => handleMouseDown(handleScrollRight)}
        onMouseUp={handleMouseUp}
        visible={visible.left}
      >
        <Icon name='angle-left' collection='fa' />
      </LeftArrow>
      {children}
      <RightArrow
        onClick={handleScrollLeft}
        onMouseDown={() => handleMouseDown(handleScrollLeft)}
        onMouseUp={handleMouseUp}
        visible={visible.right}
      >
        <Icon name='angle-right' collection='fa' />
      </RightArrow>
    </StyledSlider>
  );
};

export default Slider;
