import { gql } from '@apollo/client';

export const SAVE_IMAGES = gql`
  mutation SaveImages($businessImagesInput: BusinessImagesInput!) {
    uploadBusinessImages(businessImagesInput: $businessImagesInput) {
      error
      isSuccess
      logoError {
        imageName
        error
      }
      imageErrors {
        imageName
        error
      }
      menuImagesErrors {
        imageName
        error
      }
    }
  }
`;

export const DELETE_BUSINESS_IMAGE = gql`
  mutation DeleteBusinessImage(
    $businessId: Int!
    $businessImageType: BusinessImage!
    $pictureId: Int!
  ) {
    deleteBusinessImage(
      businessId: $businessId
      businessImageType: $businessImageType
      pictureId: $pictureId
    ) {
      error
      isSuccess
    }
  }
`;
