import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

export const RichEditor = ({ ...props }) => {
  const {
    initialValue,
    content,
    onChange,
    editorRef,
    isEnableToolbar,
    isEnableMenubar,
    templatesReplacements = [],
  } = props || {};

  return (
    <Editor
      onInit={(_, editor) => (editorRef.current = editor)}
      initialValue={initialValue}
      value={content}
      onEditorChange={onChange}
      apiKey={'iug5leqgbb9j3vfjko9y2elgyr0lafx0ugj832hreflagb4d'}
      init={{
        height: isEnableToolbar ? 500 : 200,
        menubar: isEnableMenubar,
        branding: false,
        statusbar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
          'noneditable',
        ],
        toolbar: isEnableToolbar
          ? 'undo redo | formatselect | ' +
            'fullscreen | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | ' +
            'templateReplacementsListbox'
          : 'link templateReplacementsListbox',
        content_style:
          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }' +
          '.mce-content-body [contentEditable=false] { background-color: whitesmoke !important; padding: 5px 5px !important}',
        setup: function (editor) {
          editor.ui.registry.addMenuButton('templateReplacementsListbox', {
            text: 'Replacements',
            fetch: function (callback) {
              var items = templatesReplacements.map((template) => ({
                type: 'menuitem',
                text: template?.name || '',
                onAction: function () {
                  editor.insertContent(
                    `<span class="mceNonEditable">${
                      template?.value || ''
                    }</span>`
                  );
                },
              }));
              callback(items);
            },
          });
        },
      }}
    />
  );
};
