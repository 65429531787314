import { gql } from '@apollo/client';

export const GET_BUSINESS_CONVERSATION = gql`
  query GetBusinessConversation($businessId: Int) {
    getBusinessById(id: $businessId, showAll: true) {
      conversations {
        recentMessageTime
        user {
          id
          firstName
          lastName
          email
          name
          phoneNumber
        }
        unreadCount
        recentMessages {
          message {
            commentText
            createdTime
          }
        }
      }
    }
  }
`;

export const GET_BUSINESS_BY_ID = gql`
  query GetBusinessById($id: Int!) {
    getBusinessById(id: $id, showAll: true) {
      id
      canBusinessOptIn
      isGoogleReserve
      isBusinessAlreadyOptIn
    }
  }
`;
