import React from 'react';
import { Table, Icon } from '@safelyq/bulma-ui-library';

export const TableView = ({ handleInputChange, holidaySlots, onDeleteholiday }) => {
  return (
    <div class="table-container">
      <Table fullwidth bordered>
        <thead>
          <tr>
            <th className='has-text-centered'>Day</th>
            <th className='has-text-centered'>Partial Time</th>
            <th className='has-text-centered'>Opening Time</th>
            <th className='has-text-centered'>Break Time / Duration</th>
            <th className='has-text-centered'>Closing Time</th>
            <th className='has-text-centered'>Remove</th>
          </tr>
        </thead>
        <tbody className='has-text-black'>
          {
            holidaySlots.map(({ date, id, openingTime, closingTime, hasBreak, isOpen, breakStartTime, breakDuration, openingTimeError, closingTimeError, hasBreakError, breakStartTimeError, breakDurationError }, index) => {
              return (
                <tr>
                  <td>
                    <div className='row'>

                      <div className='col-12'>
                        <input onChange={(e) => handleInputChange(e, index, date)} value={date} name="date" disabled={true} className='input' type='date' />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='row'>
                      <div className='col-12 d-flex justify-content-center align-items-center has-text-centered mt-4'>
                        <input type='checkbox' name="isOpen" checked={isOpen} onChange={(e) => handleInputChange(e, index, date)} />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>
                      <input onChange={(e) => handleInputChange(e, index, date)} className='input' name="openingTime" disabled={!isOpen} type='time' value={openingTime} />
                      {openingTimeError && <span style={{ color: 'red' }}>{openingTimeError}</span>}
                    </div>
                  </td>
                  <td>
                    <div className='row g-0'>
                      <div className='col-1  d-flex justify-content-center align-items-center'>
                        <input onChange={(e) => handleInputChange(e, index, date)} type='checkbox' name="hasBreak" disabled={!isOpen} checked={hasBreak} />
                      </div>
                      <div className='col-5'>
                        <input onChange={(e) => handleInputChange(e, index, date)} className='input' name="breakStartTime" type='time' disabled={!hasBreak} value={breakStartTime} />
                      </div>
                      <div className='col-1  d-flex justify-content-center align-items-center'>
                        <div>/</div>
                      </div>
                      <div className='col-3'>
                        <input onChange={(e) => handleInputChange(e, index, date)} className='input' name="breakDuration" type='number' disabled={!hasBreak} value={breakDuration} />
                      </div>
                      <div className='col-2  d-flex justify-content-center align-items-center'>
                        <span>min</span>
                      </div>
                      <div className='col-12'>
                        {hasBreakError && <span style={{ color: 'red' }}>{hasBreakError}</span>}
                        {breakStartTimeError && <span style={{ color: 'red' }}>{breakStartTimeError}</span>}
                        {breakDurationError && <span style={{ color: 'red' }}> - {breakDurationError}</span>}
                      </div>
                    </div>
                  </td>
                  <td>
                    <input onChange={(e) => handleInputChange(e, index, date, hasBreak)} className='input' name="closingTime" type='time' disabled={!isOpen} value={closingTime} />
                    {closingTimeError && <span style={{ color: 'red' }}>{closingTimeError}</span>}
                  </td>
                  <td>
                    <div className='is-flex is-justify-content-center is-align-items-center'>
                      <Icon onClick={() => onDeleteholiday(id, date)} name='trash' color='danger' style={{ cursor: "pointer" }} />
                    </div>
                  </td>
                </tr>
              )
            })
          }

        </tbody>
      </Table>
    </div>
  );
};
