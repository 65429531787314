import styled from 'styled-components';

const className = () => {
  const classNames = ['menu-list'].join(' ');

  return classNames;
};

const MenuList = styled.ul.attrs((props) => ({
  className: className`${props}`,
}))``;

MenuList.propTypes = {};

MenuList.defaultProps = {};

export default MenuList;
