import React, { Fragment, useState } from 'react'
import { Modal, Icon, Title, Columns, Column, Field, Button, Control, Label } from "@safelyq/bulma-ui-library";
import Overflow from 'react-overflow-indicator';
import PhoneInput from 'react-phone-input-2';

const UpdateNumbers = ({ handleModalClose, isOpenModal, setBusinessToUpdate, businessToUpdate, handleSaveBusiness, savedLoading, initialBusinessData }) => {
    const [confirmUpdate, setConfirmUpdate] = useState(false);
    const handleNumberChange = (name, value) => {
        // Other than number return
        if (!/^\+?\d+$/.test(value)) {
            return;
        }
        setBusinessToUpdate({
            ...businessToUpdate,
            [name]: value
        });
    };

    const handleDisable = () => {
        // If response is same as state and even after omiting country code, data than disable button else able
        if ((initialBusinessData?.sms === businessToUpdate?.sms || (initialBusinessData?.sms === null && businessToUpdate?.sms?.substring(1) === "")) &&
            (initialBusinessData?.whatsApp === businessToUpdate?.whatsApp || (initialBusinessData?.whatsApp === null && businessToUpdate?.whatsApp?.substring(1) === "")) &&
            initialBusinessData?.bonus === Number(businessToUpdate?.bonus)) {
            return true;
        } else {
            return false;
        }
    }
    const handleValidationSms = () => {
        // If numbers are same as backend provided than do not check length validation
        // Omit first digit (+1) and the test if the length is 0 then return validation true
        // If initial state is null and our state both are null then do not check valdiation
        if ((initialBusinessData?.sms === businessToUpdate?.sms ||
            initialBusinessData?.sms === businessToUpdate?.sms?.substring(1)) ||
            (!initialBusinessData?.sms && !businessToUpdate?.sms?.substring(1))
        ) {
            return false
        }
        else {
            // If length is less than 11 or greater than 15 than disable the update button
            if (businessToUpdate?.sms?.length < 11 || businessToUpdate?.sms?.length > 16) {
                return true
            } else {
                return false;
            }
        }
    }
    const handleValidationWhatsapp = () => {
        // If numbers are same as backend than do not check length validation
        // Omit first digit (+1) and the test if the length is 0 then return validation true
        // If initial state is null and our state both are null then do not check valdiation
        if ((initialBusinessData?.whatsApp === businessToUpdate?.whatsApp ||
            initialBusinessData?.whatsApp === businessToUpdate?.whatsApp?.substring(1)) ||
            (!initialBusinessData?.whatsApp && !businessToUpdate?.whatsApp?.substring(1))
        ) {
            return false
        }
        else {
            // If length is less than 11 or greater than 15 than disable the update button
            if (businessToUpdate?.whatsApp?.length < 11 || businessToUpdate?.whatsApp?.length > 16) {
                return true
            } else {
                return false;
            }
        }
    }

    return (
        <Fragment>
            {/* Update Phone number Modal */}
            <Modal active={isOpenModal}>
                <Modal.Background />
                <Modal.Card className='modal-compaign'>
                    <Overflow style={{ overflow: "scroll" }}>
                        <Overflow.Content className='is-flex is-flex-direction-column'>

                            <Modal.Card.Body style={{ overflow: "hidden" }} className='modal-compaign-body'>
                                <div className='my-4 is-flex is-justify-content-space-between is-align-items-center'>
                                    <Title size='5' className='m-0'>
                                        Update Details: <span className='has-text-grey ml-2'> {businessToUpdate?.name || businessToUpdate?.id} </span>
                                    </Title>
                                    <div>
                                        <Icon
                                            name='times'
                                            className='is-hoverable has-text-link'
                                            onClick={() => handleModalClose()}
                                        />
                                    </div>
                                </div>

                                {
                                    !confirmUpdate
                                        ?
                                        <Columns multiline className='my-4'>
                                            <Column size='6'>
                                                <Field>
                                                    <Control>
                                                        <Label className='has-text-info'>
                                                            SMS Number
                                                        </Label>
                                                        <PhoneInput
                                                            inputClass='input'
                                                            countryCodeEditable={false}
                                                            country={'us'}
                                                            value={businessToUpdate?.sms}
                                                            onChange={(value) => handleNumberChange('sms', value)}
                                                        />
                                                    </Control>
                                                </Field>
                                                {handleValidationSms() && <p className='has-text-danger is-size-7'>* Valid Phone Number digits must be between 11 and 15</p>}
                                            </Column>
                                            <Column size='6'>
                                                <Field>
                                                    <Control>
                                                        <Label className='has-text-info'>
                                                            WhatsApp Number
                                                        </Label>
                                                        <PhoneInput
                                                            inputClass='input'
                                                            countryCodeEditable={false}
                                                            country={'us'}
                                                            value={businessToUpdate?.whatsApp}
                                                            onChange={(value) => handleNumberChange('whatsApp', value)}
                                                        />
                                                    </Control>
                                                </Field>
                                                {handleValidationWhatsapp() && <p className='has-text-danger is-size-7'>* Valid Phone Number digits must be between 11 and 15</p>}
                                            </Column>

                                            <Column size='6'>
                                                <Field>
                                                    <Control>
                                                        <Label className='has-text-info'>
                                                            Bonus
                                                        </Label>
                                                        <input
                                                            name='intervals'
                                                            type='number'
                                                            value={businessToUpdate?.bonus}
                                                            min='0'
                                                            max='10000'
                                                            className={`input`}
                                                            onChange={(e) => setBusinessToUpdate({ ...businessToUpdate, bonus: e.target.value })}
                                                        />
                                                    </Control>
                                                </Field>
                                            </Column>

                                        </Columns>
                                        :
                                        <p> Are you sure you want to update the business details?</p>
                                }

                                <div class="buttons is-flex is-justify-content-end my-4">
                                    <Button class="button is-secondary" onClick={() => handleModalClose()}>Close</Button>
                                    <Button class="button is-info"
                                        color='info'
                                        disabled={handleDisable() || handleValidationSms() || handleValidationWhatsapp()}
                                        loading={savedLoading}
                                        onClick={confirmUpdate ? () => handleSaveBusiness() : () => setConfirmUpdate(true)}>
                                        {confirmUpdate ? "Confirm" : "Update"}
                                    </Button>
                                </div>

                            </Modal.Card.Body>
                        </Overflow.Content>
                    </Overflow>
                </Modal.Card>
            </Modal>
        </Fragment>
    )
}

export default UpdateNumbers