export const blankServiceProvider = {
  id: 0,
  name: 'New Service Provider',
  isActive: false,
  description: '',
  address1: '',
  address2: '',
  hasCongregationalCheckin: false,
  city: '',
  details: {
    offset: 0,
    hasAssignedSeating: false,
    venueEntrances: [],
  },
  businessPermissions: {
    isAdmin: true,
    isAssociate: true,
  },
  email: null,
  slots: [
    {
      closingTime: '0001-01-01T14:00:00',
      dayOfWeek: 0,
      openingTime: '0001-01-01T07:00:00',
    },
    {
      closingTime: '0001-01-01T14:00:00',
      dayOfWeek: 0,
      openingTime: '0001-01-01T07:00:00',
    },
    {
      closingTime: '0001-01-01T23:00:00',
      dayOfWeek: 0,
      openingTime: '0001-01-01T15:00:00',
    },
    {
      closingTime: '0001-01-01T14:00:00',
      dayOfWeek: 1,
      openingTime: '0001-01-01T07:00:00',
    },
    {
      closingTime: '0001-01-01T23:00:00',
      dayOfWeek: 1,
      openingTime: '0001-01-01T15:00:00',
    },
    {
      closingTime: '0001-01-01T14:00:00',
      dayOfWeek: 2,
      openingTime: '0001-01-01T07:00:00',
    },
    {
      closingTime: '0001-01-01T23:00:00',
      dayOfWeek: 2,
      openingTime: '0001-01-01T15:00:00',
    },
    {
      closingTime: '0001-01-01T14:00:00',
      dayOfWeek: 3,
      openingTime: '0001-01-01T07:00:00',
    },
    {
      closingTime: '0001-01-01T23:00:00',
      dayOfWeek: 3,
      openingTime: '0001-01-01T15:00:00',
    },
    {
      closingTime: '0001-01-01T14:00:00',
      dayOfWeek: 4,
      openingTime: '0001-01-01T07:00:00',
    },
    {
      closingTime: '0001-01-01T23:00:00',
      dayOfWeek: 4,
      openingTime: '0001-01-01T15:00:00',
    },
    {
      closingTime: '0001-01-01T14:00:00',
      dayOfWeek: 5,
      openingTime: '0001-01-01T07:00:00',
    },
    {
      closingTime: '0001-01-01T23:00:00',
      dayOfWeek: 5,
      openingTime: '0001-01-01T15:00:00',
    },
    {
      closingTime: '0001-01-01T14:00:00',
      dayOfWeek: 6,
      openingTime: '0001-01-01T07:00:00',
    },
    {
      closingTime: '0001-01-01T23:00:00',
      dayOfWeek: 6,
      openingTime: '0001-01-01T15:00:00',
    },
  ],
  startingOffset: '',
  endingOffset: '',
  isAppointmentConfirmationRequired: false,
  isChildrenAllowed: false,
  isFavorite: false,
  latitude: null,
  longitude: null,
  maxAllowedGuests: 0,
  menu: null,
  phone: null,
  picture: null,
  preCheckinArrivalTime: 0,
  preCheckinInstructions: [],
  promotions: [],
  questions: [],
  safetyTags: [],
  state: '',
  timeZone: 'America/Chicago',
  totalCapacity: '',
  webAddress: null,
  zipCode: '',
  services: [],
  allowedCapacityInUnits: '',
  allowedCapacityAsPercentage: '',
  appointmentConfirmationOffset: '',
  appointmentGap: '',
  blockDuration: '',
  categoryTags: [],
  businessCategory: {
    description: '',
    name: '',
  },
  businessServices: [],
};
