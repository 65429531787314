import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import styled from 'styled-components';
import { SummaryStatus } from './SummaryStatus';

export const SummaryTable = ({ campaignDetails, refetchSingleCampaign, campaignId }) => {

    return (
        <>
            <thead>
                <tr>
                    <StyledListHead>Targeted Date Time</StyledListHead>
                    <StyledListHead>Targeted Slot</StyledListHead>
                    <StyledListHead>Targeted <br /> Audience</StyledListHead>
                    <StyledListHead>Platform</StyledListHead>
                    {/* <StyledListHead>Actual Sent</StyledListHead> */}
                    <StyledListHead>Status</StyledListHead>
                </tr>
            </thead>
            <tbody className='has-text-black'>
                {
                    [...campaignDetails]
                        .sort((a, b) => new Date(a.targetedDateTime) - new Date(b.targetedDateTime)) // Create a shallow copy and sort by targetedDateTime
                        .map(({ id, platform, targetSlotAudience, targetedDateTime, targetedTimeName, actualSent, platformWiseStatus, endedAt }, index) => (
                            <StyledRow className='py-3' key={`campaigns-${index}`}>
                                <StyledList>
                                    <small>{moment(targetedDateTime).format('MM-DD-YYYY')} {moment(targetedDateTime).format('hh:mm A')}</small>
                                </StyledList>
                                <StyledList>
                                    <small>{targetedTimeName}</small>
                                </StyledList>
                                <StyledList>
                                    <small>{targetSlotAudience}</small>
                                </StyledList>
                                <StyledList>
                                    {
                                        platformWiseStatus.length === 0
                                            ?
                                            <small>{platform}</small>
                                            :
                                            platformWiseStatus.map(platform => <small className='is-block'>{platform.platform}</small>)
                                    }
                                </StyledList>
                                <StyledList>
                                    <SummaryStatus
                                        campaignId={campaignId}
                                        campaignDetails={campaignDetails}
                                        refetchSingleCampaign={refetchSingleCampaign}
                                        targetedDateTime={targetedDateTime}
                                        platformWiseStatus={platformWiseStatus}
                                        actualSent={actualSent}
                                        campaignTargetTimeId={id}
                                    />
                                </StyledList>
                            </StyledRow>
                        ))}


            </tbody>

        </>
    )
}

const StyledListHead = styled.th.attrs(() => ({
    className: 'py-3 px-2 has-text-centered th-compaign-details',
}))`
  vertical-align: middle !important;
}
`

const StyledList = styled.td.attrs(() => ({
    className: 'py-3 px-2 has-text-centered th-compaign-details',
}))`
  vertical-align: middle !important;
}import { Loader } from '@safelyq/base-feature';

`;
const StyledRow = styled.tr.attrs()`
  &:hover {
    cursor: pointer;
  }
`;