import React, { useState } from 'react'
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Icon, Modal, Title } from '@safelyq/bulma-ui-library';
import { Spin, Tooltip } from 'antd';
import { FiInfo } from "react-icons/fi";

const UploadButton = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  border: 1px dashed grey;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: 400px;
  object-fit: contain;
`;

const DeleteButton = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

const StyledControl = styled.div`
  &:hover ${DeleteButton} {
    display: flex;
  }
`;

export const ImagesComponent = ({ sectionName, onUploadImage, onDeleteImage, campaignFiles, uploadImageLoading, setUploadImageLoading }) => {
  const [fileKey, setFileKey] = useState(0);
  const extensions = {
    video: {
      type: ['mpeg', 'mp4', 'quicktime', 'webm', '3gpp', '3gpp2', '3gpp-tt', 'H261', 'H263', 'H263-1998', 'H263-2000', 'H264'],
      preview: [' video/mpeg ', ' video/mp4 ', ' video/quicktime ', ' video/webm ', ' video/3gpp ', ' video/3gpp2 ', ' video/3gpp-tt ', ' video/H261 ', ' video/H263 ', ' video/H263-1998 ', ' video/H263-2000 ', ' video/H264 ']
    },
    audio: {
      type: ['basic', 'L24', 'mp4', 'mpeg', 'ogg', 'vnd.rn-realaudio', 'vnd.wave', '3gpp', '3gpp2', 'ac3', 'webm', 'amr-nb', 'amr', 'mp3'],
      preview: [' audio/basic ', ' audio/L24 ', ' audio/mp4 ', ' audio/mpeg ', ' audio/ogg ', ' audio/vnd.rn-realaudio ', ' audio/vnd.wave ', ' audio/3gpp ', ' audio/3gpp2 ', ' audio/ac3 ', ' audio/webm ', ' audio/amr-nb ', ' audio/amr ', ' mp3 ']
    },
    image: {
      type: ['jpeg', 'jpg', 'gif', 'png', 'bmp', 'tiff'],
      preview: [' image/jpeg ', ' image/jpg ', ' image/gif ', ' image/png ', ' image/bmp ', ' image/tiff ']
    }
  }

  const [isOpen, setIsOpen] = useState();

  const [filePreview, setFilePreview] = useState(null);

  // Calculating total File Size
  const filesSize = campaignFiles?.reduce((total, file) => {
    if (file?.fileSize) {
      return total + file?.fileSize;
    }
    else {
      return total;
    }

  }, 0);

  let totalImages = 0;
  const onUploadBusinessImage = async (e) => {
    // if nothing exists then return
    if (!e.target.files[0]) {
      return;
    }

    setFileKey((prev) => prev + 1)

    // Image upload Loading
    setUploadImageLoading(true);

    totalImages = Number(e.target.files.length) + Number(campaignFiles.length);

    if (totalImages > 10) {
      setUploadImageLoading(false);
      return toast.error("Maximum 10 images are allowed")
    }

    const images64 = [];
    for (const file of e.target.files) {
      let { name, size } = file;
      const actualsize = size;

      // Check for duplicate Files
      const duplicateFile = campaignFiles?.find(file => file?.fileName === name);

      if (duplicateFile) {
        setUploadImageLoading(false);
        return toast.error("File with the same name already exists, please select a different file")
      }

      // File Size after adding new file
      const totalFileSize = actualsize + filesSize;

      // Total files size validation
      if (totalFileSize > 4500000) {
        setUploadImageLoading(false);
        return toast.error(`The maximum payload allowed for files is 4.5 MB. Your files exceed the payload limit.`);
      }

      const extension = String(name).split('.').pop();

      // Files extension type validation
      if (!extensions?.audio?.type.includes(extension) && !extensions?.video?.type.includes(extension) && !extensions?.image?.type.includes(extension)) {
        setUploadImageLoading(false);
        return toast.error(`Invalid extension type, Please upload files with allowed extension types`);
      }

      // Single file size validation - Audio video max size 600kb, image max size 1mb allowed
      if ((extensions?.audio?.type.includes(extension) || extensions?.video?.type.includes(extension))) {
        if (actualsize > 600000) {
          setUploadImageLoading(false)
          toast.error("File size too large, audios and videos can only be up to 600kb");
          return;
        }
      }
      else {
        if (actualsize > 1000000) {
          setUploadImageLoading(false)
          toast.error("File size too large, images can only be up to 1mb");
          return;
        }
      }

      const base64String = await readFileAsBase64(file);
      images64.push({
        fileName: name,
        base64String,
        // isDefault: true,
        contentType: extension,
        fileSize: actualsize,
      });

      onUploadImage(images64)
    }
  }

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        resolve(window.btoa(reader.result));
      };
      reader.onerror = reject;
    });
  };

  const modalStyle = {
    position: "absolute",
    top: "5%",
  }

  return (
    <>
      {
        <>
          <div className='is-flex mt-1 mb-2'>
            <p className='is-size-6 has-text-weight-bold mr-3 has-text-info'>Attachments:</p>
            <p className='is-size-7 has-text-weight-semibold mt-1'>
              The total file size allowed for all files is {filesSize ? (filesSize / 1000000).toFixed(2) : 0}/4.5mb.
            </p>
            <Tooltip className='ml-2 is-clickable mt-1'
              title={`Files must be of the following extensions: ${extensions?.audio?.preview}, ${extensions?.video?.preview}, ${extensions?.image?.preview}`}>
              <FiInfo className='is-size-6 is-large has-text-info' />
            </Tooltip>
          </div>

          {
            campaignFiles?.length < 10 &&
            <div>
              <label>
                <div style={{ display: 'inline-block', position: 'relative' }} className='is-clickable'>
                  <UploadButton htmlFor={`${sectionName}-file-input`} >
                    <Spin spinning={uploadImageLoading}>
                      <Icon name='picture-o' />
                    </Spin>
                  </UploadButton>
                  <input
                    multiple
                    onChange={onUploadBusinessImage}
                    id={`${sectionName}-file-input`}
                    type='file'
                    key={fileKey}
                    style={{
                      opacity: 0,
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      height: '100%',
                      width: '100%',
                      cursor: 'pointer'
                    }}
                  />
                </div>
              </label>

            </div>
          }
        </>
      }

      <div className='is-flex is-flex-wrap-wrap mt-2'>
        {
          campaignFiles?.length !== 0 &&
          campaignFiles?.map((file, index) => {
            return (
              <div className='is-flex mr-2 p-1 mb-2' style={{ border: "1px solid #d9d9d9", borderRadius: "8px" }}>
                <p
                  className='is-underline has-text-info is-clickable'
                  style={{
                    width: "100px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"
                  }}
                  onClick={() => {
                    setFilePreview(file);
                    setIsOpen(true);
                  }}
                >
                  {file?.fileName}
                </p>
                <p>
                  <Icon name='close'
                    className='is-size-6 ml-2 is-clickable'
                    onClick={() => onDeleteImage(file)} />
                </p>
              </div>
            )
          })
        }

        {/* MMS File Preview */}
        <Modal active={isOpen} clipped>
          <Modal.Background />
          <Modal.Card style={modalStyle}>
            <Modal.Card.Head>
              <div className='container is-flex is-justify-content-space-between is-align-items-center'>
                <Title size='6' className='m-0'>
                  File Name: {filePreview?.fileName} File Type: {filePreview?.contentType}
                </Title>
                <Icon name='close' className="is-clickable mx-2" onClick={() => {
                  setIsOpen(false);
                  setFilePreview(null);
                }} />
              </div>
            </Modal.Card.Head>
            <Modal.Card.Body>
              <div>
                {filePreview &&
                  (extensions?.video?.type.includes(filePreview?.contentType) || extensions?.video?.preview?.some(item => item.trim() === filePreview?.contentType)) ? (
                  <div className='is-flex mt-2 w-100' style={{ position: 'relative' }}>
                    <video controls className='mt-2 w-100'>
                      <source src={filePreview?.url || window?.atob(filePreview?.base64String)} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )
                  :
                  (extensions?.audio?.type.includes(filePreview?.contentType) || extensions?.audio?.preview?.some(item => item.trim() === filePreview?.contentType)) ? (
                    <div className='is-flex mt-2 w-100'>
                      <audio controls className='w-100'>
                        <source src={filePreview?.url || window?.atob(filePreview?.base64String)} type="audio/mp3" />
                        Your browser does not support the audio tag.
                      </audio>
                    </div>
                  )
                    :
                    (extensions?.image?.type.includes(filePreview?.contentType) || extensions?.image?.preview?.some(item => item.trim() === filePreview?.contentType)) ? (
                      (filePreview?.base64String || filePreview?.url) && (
                        <StyledControl key={filePreview?.url} className='control is-relative'>
                          <StyledImage src={filePreview?.url || window.atob(filePreview.base64String)} />
                        </StyledControl>
                      )
                    )
                      :
                      "File format not supported"}
              </div>
            </Modal.Card.Body>
          </Modal.Card>
        </Modal >
      </div>
    </>

  )


} 