import { gql } from "@apollo/client";

export const GET_USER_PROFILE = gql`
  query GetUserProfile {
    getUserProfile {
      user {
        id
        email
        emailConfirmed
        firstName
        lastName
        phoneNumber
        claimedPhoneNumber
        phoneNumberConfirmed
        userPreferences {
          allowEmail
          allowWhatsApp
          allowSms
          allowWhatsApp
        }
        marketingDetails{
          allowEmail
          allowWhatsApp
          allowSMS
          allowMarketingNotifications
        }
      }
      hasSemiAuthAccount
      isVerficationCodeSent
      error
    }
  }
`;

export const SAVE_USER_PROFILE = gql`
  mutation SaveUserProfile($userProfileInput: UserProfileInput!) {
    saveUserProfile(userProfileInput: $userProfileInput) {
      user {
        id
        email
        emailConfirmed
        firstName
        lastName
        phoneNumber
        claimedPhoneNumber
        phoneNumberConfirmed
        claimedPhoneNumber
        userPreferences {
          allowEmail
          allowWhatsApp
          allowSms
        }
      }
      hasSemiAuthAccount
      isVerficationCodeSent
      error
    }
  }
`;

export const SAVE_MARKETING_DETAILS = gql`
  mutation UpdateMarketingUser($marketingUserInput: MarketingUserInput!) {
    updateMarketingUser(marketingUserInput: $marketingUserInput) {
      errorMessage
      isSaved
    }
  }
`;
