import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = [
    'modal-card-foot'
  ]
    .join(' ');

  return classNames;
};

const ModalCardFoot = styled.footer.attrs((props) => ({
  className: className`${props}`
}))``;

ModalCardFoot.propTypes = {
};

ModalCardFoot.defaultProps = {
};

export default ModalCardFoot;
