import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";
import isEqual from "lodash/isEqual";
import {
  Button,
  Title,
  Field,
  Control,
  Buttons,
} from "@safelyq/bulma-ui-library";
import { useData } from "./hooks";
import { useProfile } from "hooks/useProfile";

export const CommentSection = ({ ...props }) => {
  const { appointmentId, business, isAllowComment } = props;

  const [comments, setComments] = useState([]);

  const {
    profile: { sub },
  } = useProfile();

  useEffect(() => {
    const { comments } = props;

    if (comments) setComments(comments);
  }, [props.comments]);

  const onSaveComment = (newComment) =>
    setComments([...comments, { ...newComment }]);

  return (
    <Fragment>
      <Title size="6">Comments</Title>
      {comments.map(
        (
          {
            comment: {
              commentText,
              createdTime,
              modifiedTime,
              user: { id, email, firstName, lastName },
            },
            messageDirection,
          },
          index
        ) => (
          <article className="media has-text-black" key={`comment-${index}`}>
            <figure className="media-left">
              <Button
                size="small"
                color={isEqual(sub, id) ? "info" : "primary"}
                className="avatar-icon rounded"
              >
                {messageDirection === 'BusinessToUser' ? (
                  <strong>
                    {firstName && lastName
                      ? String(firstName).charAt(0).toUpperCase() +
                        String(lastName).charAt(0).toUpperCase()
                      : String(email).substring(0, 2).toUpperCase()}
                  </strong>
                ) : (
                  <strong>
                    {firstName && lastName
                      ? String(firstName).charAt(0).toUpperCase() +
                        String(lastName).charAt(0).toUpperCase()
                      : String(email).substring(0, 2).toUpperCase()}
                  </strong>
                )}
              </Button>
            </figure>
            <div className="media-content">
              <div className="content">
                <p>
                  <strong>
                    {firstName && lastName ? firstName + ' ' + lastName : email}
                    {messageDirection === 'BusinessToUser' ? (
                      <span className='tag is-gray is-small ml-3'>
                        <u>
                          <i>Admin</i>
                        </u>
                      </span>
                    ) : (
                      ''
                    )}
                  </strong>
                  &nbsp;&nbsp;
                  <small className="has-text-grey-light">
                    {moment(modifiedTime || createdTime).format("DD-MM-YYYY")}
                  </small>
                  <br />
                  {commentText}
                </p>
              </div>
            </div>
          </article>
        )
      )}
      {
        isAllowComment ?
          <AddNewComment
            business={business}
            appointmentId={appointmentId}
            onSaveComment={onSaveComment}
          /> : <small className="has-text-danger">{comments.length > 0 && <br/>}You can't comment on this appointment. </small>
      }
    </Fragment>
  );
};

const AddNewComment = ({ ...props }) => {
  const { appointmentId, onSaveComment, business } = props;

  const [comment, setComment] = useState("");

  const [focused, setFocused] = React.useState(false);

  const { avatarName, ...rest } = useProfile();

  const handleSaveComment = (newComment) => {
    setComment('');
    onSaveComment(newComment);
  };

  const handleFieldFocused = () => setFocused(true);

  const { isLoading, onAddComment } = useData({
    onSaveComment: handleSaveComment,
    appointmentId
  });
  const handleCommentChange = ({ target: { value } }) => setComment(value);

  const handleCancel = () => {
    setComment("");
    setFocused(false);
  };

  const handleAddComment = () =>
    onAddComment({
      variables: {
        commentText: comment,
        appointmentId: appointmentId,
        messageDirection: 'BUSINESS_TO_USER',
      },
    });
  return (
    <article className='media'>
      <figure className='media-left'>
        <Button size='small' color='info' className='avatar-icon rounded'>
          {<strong>{avatarName}</strong>}
        </Button>
      </figure>
      <div className="media-content">
        <div className="content">
          <textarea
            rows={2}
            value={comment}
            onFocus={handleFieldFocused}
            className="textarea"
            placeholder="Add a comment..."
            onChange={handleCommentChange}
          />
        </div>
        {focused && (
          <Field>
            <Control>
              <Buttons size="small" className="is-right">
                <Button
                  light
                  color="info"
                  disabled={isLoading || !comment}
                  onClick={handleAddComment}
                >
                  {isLoading ? "..." : "Save"}
                </Button>
                <Button onClick={handleCancel}>Cancel</Button>
              </Buttons>
            </Control>
          </Field>
        )}
      </div>
    </article>
  );
};
