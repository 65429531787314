import React from 'react';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Title, Subtitle, Button } from '@safelyq/bulma-ui-library';

const StyledCard = styled.div.attrs(() => ({}))`
  min-width: 156px;
  border-radius: 3px;
  box-shadow: rgb(9 30 66 / 25%) 0px 1px 1px, rgb(9 30 66 / 13%) 0px 0px 1px 1px;
  background-image: linear-gradient(rgb(244, 245, 247) 33%, transparent 33%);
`;

export const Card = ({ id, name, organization, logoUrl, picture, googleReserve, ...restProps }) => {
  const isNameMakeSubstring = String(name).length > 25;

  const location = { ...restProps };

  const isBusinessImages =
    location.pictures?.length > 0 || location.menus?.length > 0;

  const isServices = location.services?.length > 0;

  const isCustomerTraffic = !isEmpty(String(location.totalCapacity));

  const isAPTConfirm = !isEmpty(String(location.appointmentConfirmationOffset));

  const isCheckIn =
    !isEmpty(String(location.details?.offset)) ||
    !isEmpty(location.businessVenue);

  const isCompleteBusiness =
    isBusinessImages &&
    isServices &&
    isCustomerTraffic &&
    isAPTConfirm &&
    isCheckIn;

  return (
    <StyledCard>
      <Link to={googleReserve ? `/admin/reserve-with-google/${id}` : `/admin/appointments-log/${id}`}>
        <div className='card-content'>
          <div className='is-flex is-justify-content-space-between is-align-items-center'>
            {logoUrl ? (
              <img className='image is-32x32 mb-3' src={logoUrl} />
            ) : (
              <Button color='info' size='small' className='icon-button mb-3'>
                {String(name).charAt(0).toUpperCase()}
              </Button>
            )}
            <span className='tag is-primary is-light'>Admin</span>
          </div>
          <div className='is-flex is-justify-content-space-between is-align-items-center'>
            <div>
              <Title size='6'>
                {isNameMakeSubstring ? String(name).substring(0, 25) + '...' : name}
              </Title>
              <Subtitle size='6' className=''>
                {organization}
              </Subtitle>
            </div>
            <div>
              {location.isGoogleReserve &&
                <img src='https://res.cloudinary.com/safelyq/image/upload/v1706108734/GoogleReserve/GoogleLogo_f1puvw.png' alt='' style={{ height: '50px', width: '50px' }}></img>
              }
            </div>
          </div>
          {!isCompleteBusiness ? (
            <Subtitle size='6' className='has-text-danger'>
              <small>
                <i>
                  Incomplete Setup
                </i>
              </small>
            </Subtitle>
          ) : null}
        </div>
      </Link>
    </StyledCard>
  );
};
