import { getUserManager } from '@axa-fr/react-oidc-context';

export async function addAuthorizationHeader(operation, forward) {
  const user = await getUserManager().getUser();
  const isAuthenticated = !!user && !!user.access_token;
  const token = isAuthenticated
    ? user.access_token
    : '';

  operation.setContext({
    headers: {
      authorization: isAuthenticated ? `Bearer ${token}` : undefined
    }
  });

  return forward(operation);
}

export async function getAccessToken() {
    const user = await getUserManager().getUser();
    const isAuthenticated = !!user && !!user.access_token;
    const token = isAuthenticated
        ? user.access_token
        : '';
    return token;
}
