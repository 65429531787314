import React from 'react';
import { AdminMenuList, AdminTabList } from './components';

export const AdminNavigationSection = () => {
  return (
    <div>
      <AdminMenuList />
      <AdminTabList />
    </div>
  );
};
