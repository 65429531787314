import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_USER_PROFILE, SAVE_USER_PROFILE, GET_USER_LOCATIONS, SAVE_BUSINESS_WATCHER } from './requests';
import { INITIAL_DATA, INITIAL_BUSINESS_DATA } from './constants';

export const useData = () => {
  const {
    loading: isLoading,
    error,
    refetch,
    data = INITIAL_DATA,
  } = useQuery(GET_USER_PROFILE, {
    fetchPolicy: 'no-cache',
  });

  const { getUserProfile: profile } = data;

  return { isLoading, error, refetch, data: profile };
};

export const useNotificationData = () => {

  const { loading: isLoading, error, data = INITIAL_BUSINESS_DATA, refetch  } = useQuery(
    GET_USER_LOCATIONS,
  );

  const [onSaveNotification, { loading: isSaveLoading }] = useMutation(
    SAVE_BUSINESS_WATCHER,
    {
        onCompleted: ({
            saveBusinessWatcherInfo: {
                isSaved,
                errorMessage,
            },
        }) => {
            if (isSaved) {
                refetch();
                toast.success('Business Notification changed successfully.')
            }
            else{
              toast.error(errorMessage)
            }
        },
        onError: () => toast.error('Something went wrong, please try again later'),
    }
);

  return {
    isLoading,
    isSaveLoading,
    error,
    onSaveNotification,
    data: data,
  };
};

export const useAccount = (refetch) => {
  const { push } = useHistory();

  const [onProfileUpdate, { loading: isProfileLoading, error }] = useMutation(
    SAVE_USER_PROFILE,
    {
      onCompleted: ({
        saveUserProfile: {
          error,
          isVerficationCodeSent,
          hasSemiAuthAccount,
          user: { phoneNumber },
        },
      }) => {
        if (isVerficationCodeSent) {
          if(hasSemiAuthAccount) toast.success("Your account will be merged with this number acount");
          push({
            pathname: '/admin/account/verify-phone',
            state: { currentMenu: 0, phoneNumber },
          });
          return;
        }
        if (error) {
          throwError(error);
          return;
        } else {
          toast.success('You profile has been updated');
          refetch();
        }
      },
      onError: () => throwError('Something went wrong, please try again later'),
    }
  );

  const throwError = (error) => toast.error(error);

  return { isProfileLoading, error, onProfileUpdate };
};
