import { useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import mtimezone from "moment-timezone";
import { useQuery, useMutation } from "@apollo/client";
import { toast as showToast } from "react-toastify";
import { GET_APPOINTMENT_BY_ID, UPDATE_APPOINTMENT } from "./requests";
import { INITIAL_DATA } from "./constants";

// firestore
import firestore from '@firebase';
import { COLLECTION } from 'constants/index';

export const useData = ({ appointmentId, onPopulateData, onCloseModal }) => {
  let { businessId } = useParams();

  const { push } = useHistory();


  businessId = parseInt(businessId);

  useEffect(() => {
    const unsubscribe = firestore
      .collection(COLLECTION.COMMENTS)
      .where('businessId', '==', `${businessId}`)
      .onSnapshot(async querySnapshot => {
        refetch();
      });
    return () => unsubscribe();
  }, [businessId]);

  const {
    loading: isLoading,
    refetch,
    error,
    data = INITIAL_DATA,
  } = useQuery(GET_APPOINTMENT_BY_ID, {
    fetchPolicy: "no-cache",
    variables: { appointmentId },
    onCompleted: ({ getUserAppointmentById: appointment }) => {
      const {
        startTime,
        startTimeOnly,
        business: { id: businessId, timeZone },
        service: { id: service },
        subBusiness: { id: serviceProvider },
        venueEntrance,
        endTime,
        comments,
        responses,
        status,
        children,
        adults,
        user,
      } = appointment;
      onPopulateData({
        status,
        businessId,
        service,
        serviceProvider,
        timeZone,
        children,
        adults,
        name: user?.fullName,
        email: user?.email,
        phoneNumber: user?.phoneNumber,
        date: moment.tz(startTime, timeZone).format("YYYY-MM-DD"),
        startTime,
        venueEntrance: venueEntrance?.name,
        //slot: { startTimeOnly, startTime: moment.tz(startTime, 'Asia/Karachi').format('YYYY-MM-DD hh:mm A'), endTime: moment.tz(endTime, 'Asia/Karachi').format('YYYY-MM-DD hh:mm A') }, // For testing only
        slot: {
          startTimeOnly,
          startTime: moment
            .tz(startTime, timeZone)
            .format("YYYY-MM-DD hh:mm A"),
          endTime: moment.tz(endTime, timeZone).format("YYYY-MM-DD hh:mm A"),
        },
        comments,
        responses,
      });
    },
  });

  const [onUpdate, { loading: isUpdateLoading }] = useMutation(
    UPDATE_APPOINTMENT,
    {
      onCompleted: ({ updateUserAppointment: { errorMessage, isSaved, appointment } }) => {
        if (!isSaved) {
          showToast.error(
            errorMessage || "Something wrong on appointment updation"
          );
          return;
        }
        push(`/admin/appointments/${businessId}`);
        onCloseModal();
        showToast.success("Appointment has been updated sucessfully");
      },
      onError: () => showToast.error("Something went wrong on updating"),
    }
  );

  const { getUserAppointmentById: appointment } = data;

  return {
    isLoading,
    isUpdateLoading,
    error,
    data: appointment,
    refetch,
    onUpdate,
  };
};
