import React from 'react'
import styles from "./TileView.module.css"
import { getformatedTime } from './../ServiceHolidaySchedule.helpers';
import moment from 'moment';
import { Icon } from '@safelyq/bulma-ui-library';

export const TileView = ({ allServiceHolidays, handleInputChange, onDeleteholiday }) => {
    return (
        <div>
            {
                allServiceHolidays.map(({ date, id, openingTime, closingTime, hasBreak, isOpen, breakStartTime, breakDuration, businessClosingTime, businessOpeningTime, openingTimeError, closingTimeError, hasBreakError, breakStartTimeError }, index) => {
                    return (

                        <div className={`row g-0 ${styles.wrapper}`}>
                            <div className='col-12 '>
                                <h6 className={styles.heading}>Date</h6>
                            </div>
                            <div className='col-2 d-flex justify-content-center align-items-center'>
                                <input type='checkbox' name="isOpen" checked={isOpen} onChange={(e) => handleInputChange(e, index, date)} />
                            </div>
                            <div className='col-10'>
                                <input onChange={(e) => handleInputChange(e, index, date)} value={date} name="date" disabled={!isOpen} className='input' type='date' />
                            </div>
                            <div className='col-12 '>
                                <h6 className={styles.heading}>Opening Time</h6>
                            </div>
                            <div className='col-12 mb-2 '>
                                <input onChange={(e) => handleInputChange(e, index, date)} className='input' name="openingTime" disabled={!isOpen} type='time' value={openingTime} />
                                {openingTimeError && <span style={{ color: 'red' }}>{openingTimeError}</span>}
                            </div>
                            <div className='col-12 '>
                                <h6 className={styles.heading}>Break Time</h6>
                            </div>
                            <div className='col-1  d-flex justify-content-center align-items-center'>
                                <input onChange={(e) => handleInputChange(e, index, date)} type='checkbox' name="hasBreak" checked={hasBreak} />
                            </div>
                            <div className='col-5'>
                                <input onChange={(e) => handleInputChange(e, index, date)} className='input' name="breakStartTime" type='time' disabled={!hasBreak} value={breakStartTime} />
                                {breakStartTimeError && <span style={{ color: 'red' }}>{breakStartTimeError}</span>}
                            </div>
                            <div className='col-1  d-flex justify-content-center align-items-center'>
                                <div>/</div>
                            </div>
                            <div className='col-3'>
                                <input onChange={(e) => handleInputChange(e, index, date)} className='input' name="breakDuration" type='number' disabled={!hasBreak} value={breakDuration} />
                            </div>
                            <div className='col-2  d-flex justify-content-center align-items-center'>
                                <span>min</span>
                            </div>
                            <div className='col-12 mb-2'>
                                {hasBreakError && <span style={{ color: 'red' }}>{hasBreakError}</span>}
                            </div>
                            <div className='col-12 '>
                                <h6 className={styles.heading}>Closing Time</h6>
                            </div>
                            <div className='col-12 mb-2'>
                                <input onChange={(e) => handleInputChange(e, index, date, hasBreak)} className='input' name="closingTime" type='time' disabled={!isOpen} value={closingTime} />
                                {closingTimeError && <span style={{ color: 'red' }}>{closingTimeError}</span>}
                            </div>

                            <div className='col-12 mb-2'>
                                <div className='is-flex is-justify-content-center is-align-items-center'>
                                    <Icon onClick={() => onDeleteholiday(id, date)} name='trash' color='danger' style={{ cursor: "pointer" }} />
                                </div>
                            </div>

                        </div>

                    )
                })
            }
        </div>
    );

}
