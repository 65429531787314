import { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import { toast as showToast } from 'react-toastify';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_BUSINESS_APPOINTMENTS,
  CHANGE_APPOINTMENT_STATUS,
} from './requests';
import { INITIAL_DATA } from './constants';

//for firestore
import firestore from '@firebase';
import { COLLECTION } from 'constants/index';

export const useData = ({ onPopulateData }) => {
  let { businessId } = useParams();


  const { push } = useHistory();

  businessId = parseInt(businessId);

  const [appointments, setAppointments] = useState(INITIAL_DATA);
  const [isAssociate, setisAssociate] = useState(false)

  const [
    onAppointmentsRequets,
    { loading: isLoading, refetch, data = INITIAL_DATA },
  ] = useLazyQuery(GET_BUSINESS_APPOINTMENTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      businessId,
      id: businessId,
      startDate: moment().format('YYYY-MM-DD'),
      orderSequence: 'DESCENDING',
      sortOrder: 'CREATED_DATE',
      endDate: moment().format('YYYY-MM-DD'),

    },
    onCompleted: ({ getBusinessAppointments: appointments, getBusinessById }) => {
      return (
        onPopulateData(appointments),
        setisAssociate(getBusinessById.businessPermissions.isAssociate)
      )
    }
  });

  useEffect(() => {
    const unsubscribe = firestore
      .collection(COLLECTION.APPOINTMENTS)
      .where('businessId', '==', `${businessId}`)
      .onSnapshot(async querySnapshot => {
        handleAppointmentUpdates();
      });
    return () => unsubscribe();
  }, [businessId]);

  useEffect(() => {
    onAppointmentsRequets();
  }, []);

  const handleAppointmentUpdates = (message) => {
    onAppointmentsRequets();
  };

  const [onUpdateAppointmentStatus, { loading: isCancelLoading }] = useMutation(
    CHANGE_APPOINTMENT_STATUS,
    {
      onCompleted: ({ updateAppointmentStatus }) => {
        const { appointment } = updateAppointmentStatus
        push(`/admin/appointments/${businessId}`);
        showToast.success('Appointment status has been changed');
      },
      onError: () =>
        showToast.error('Something went wrong on cancel appointment'),
    }
  );

  const { getBusinessAppointments: initialAppointments } = data;

  const handleFilterByDate = (dates) => {
    if (dates.startDate === '') dates.startDate = moment().format('YYYY-MM-DD');
    if (dates.endDate === '') delete dates['endDate'];
    onAppointmentsRequets({
      variables: {
        ...dates,
        orderSequence: 'DESCENDING',
        sortOrder: 'CREATED_DATE',
      },
    });
  };

  const handleFilterByText = (text) => {
    text = String(text).toLowerCase();

    const searchResult = initialAppointments.filter(
      ({ user: { name, email, phoneNumber }, startTime }) =>
        String(name).toLowerCase().includes(text) ||
        String(email).toLowerCase().includes(text) ||
        String(phoneNumber).toLowerCase().includes(text) ||
        String(moment(startTime).format('hh:mm A')).toLowerCase().includes(text)
    );

    if (text) onPopulateData(searchResult);
    else onPopulateData(initialAppointments);
  };

  const handleAppointmentStatusChange = (status, appointmentId) => {
    onUpdateAppointmentStatus({
      variables: {
        appointmentId,
        newStatus: status,
      },
    });
  };

  return {
    data: appointments,
    isAssociate,
    isLoading,
    isCancelLoading,
    refetch,
    onChangeAppointmentStatus: handleAppointmentStatusChange,
    onFilterByDate: handleFilterByDate,
    onFilterByText: handleFilterByText,
  };
};
