import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = ['message-header'].join(' ');

  return classNames;
};

const MessageHeader = styled.section.attrs((props) => ({
  className: className`${props}`,
}))``;

MessageHeader.propTypes = {};

MessageHeader.defaultProps = {};

export default MessageHeader;
