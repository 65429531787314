import React, { useEffect, useState } from 'react'
import { Switch, Button } from '@safelyq/bulma-ui-library';
import { isEmpty } from 'lodash';
import { COMMUNICATION_CHECKS_MESSAGES } from '../../common/constants';
import { Tooltip } from 'antd';
import { FiInfo } from "react-icons/fi";

const INITIAL_MESSAGING = {
  messagingAllowed: false,
  consumerResponseAllowed: false,
  consumerAttachmentsAllowed: false,
  consumerInitiationAllowed: false
}
export const CommunicationChecksSetup = ({ data: business, onSubmit, isLoading, toolTip }) => {

  const [messaging, setmessaging] = useState(INITIAL_MESSAGING)
  const [saveButton, setSaveButton] = useState(false)

  const handlemessagingAllowed = (status) => {
    if (status) {
      setmessaging((prev) => ({
        ...prev,
        messagingAllowed: status
      }))
    }
    else {
      setmessaging(INITIAL_MESSAGING)
    }

  }
  const handleconsumerResponseAllowed = (status) => {

    if (status) {
      setmessaging((prev) => ({
        ...prev,
        consumerResponseAllowed: status,
      }))
    }
    else {
      setmessaging((prev) => ({
        ...prev,
        consumerResponseAllowed: status,
        consumerAttachmentsAllowed: false,
        consumerInitiationAllowed: false
      }))
    }
  }
  const handleconsumerAttachmentsAllowed = (status) => {
    setmessaging((prev) => ({
      ...prev,
      consumerAttachmentsAllowed: status
    }))
  }
  const handleconsumerInitiationAllowed = (status) => {
    setmessaging((prev) => ({
      ...prev,
      consumerInitiationAllowed: status
    }))
  }
  const handleSave = () => {
    onSubmit(
      { businessCommunication: { ...messaging } },
      10,
      {
        success: COMMUNICATION_CHECKS_MESSAGES.Success,
        error: COMMUNICATION_CHECKS_MESSAGES.Error,
      },
      business.id
    );
  }

  useEffect(() => {
    if (!isEmpty(business) && !isLoading) {
      const {
        messaging: {
          messagingAllowed,
          consumerResponseAllowed,
          consumerAttachmentsAllowed,
          consumerInitiationAllowed
        }
      } = business;

      setmessaging({
        messagingAllowed,
        consumerResponseAllowed,
        consumerAttachmentsAllowed,
        consumerInitiationAllowed,
      });
    }
  }, [business.messaging]);

  useEffect(() => {
    const isMessagingChanged =
      messaging.messagingAllowed !== business.messaging?.messagingAllowed ||
      messaging.consumerResponseAllowed !== business.messaging?.consumerResponseAllowed ||
      messaging.consumerAttachmentsAllowed !== business.messaging?.consumerAttachmentsAllowed ||
      messaging.consumerInitiationAllowed !== business.messaging?.consumerInitiationAllowed;
    setSaveButton(!isMessagingChanged);
  }, [messaging, business.messaging]);

  return (
    <div className='row'>
      <div className='col-lg-12 mb-4 is-flex is-flex-direction-column'>
        <div className='mb-4 message_label ' >
          <Switch value={messaging.messagingAllowed} onValueChange={(checked) => handlemessagingAllowed(checked)} />
          <span className='mx-3'>User Messaging Allowed ? (Messaging Feature)</span>
          <Tooltip title={toolTip?.userMessagingAllowed}> <FiInfo /> </Tooltip>

        </div>
        <div className='mb-4 message_label' style={{ marginLeft: "3rem", background: messaging.messagingAllowed ? "white" : "#e7e7e7" }}>
          <Switch value={messaging.consumerResponseAllowed} disabled={!messaging.messagingAllowed} onValueChange={(checked) => handleconsumerResponseAllowed(checked)} />
          <span className='mx-3'>Allow User To Respond to Messages ? (Customer send message)</span>
          <Tooltip title={toolTip?.allowUserToRespondToMessages}> <FiInfo /> </Tooltip>
        </div>
        <div className='mb-4 message_label' style={{ marginLeft: "6rem", background: messaging.consumerResponseAllowed ? "white" : "#e7e7e7" }}>
          <Switch value={messaging.consumerAttachmentsAllowed} disabled={!messaging.consumerResponseAllowed} onValueChange={(checked) => handleconsumerAttachmentsAllowed(checked)} />
          <span className='mx-3'>Allow User To Send Attachments ? (Customer send attachment)</span>
          <Tooltip title={toolTip?.allowUserToSendAttachments}> <FiInfo /> </Tooltip>
        </div>
        <div className='mb-4 message_label' style={{ marginLeft: "6rem", background: messaging.consumerResponseAllowed ? "white" : "#e7e7e7" }}>
          <Switch value={messaging.consumerInitiationAllowed} disabled={!messaging.consumerResponseAllowed} onValueChange={(checked) => handleconsumerInitiationAllowed(checked)} />
          <span className='ml-3'>Allow User To Initate Message ? (Customer send first message)</span>
        </div>
      </div>
      <div className='col-lg-12'>
        <Button type='submit' color='info' disabled={isLoading || saveButton} loading={isLoading} onClick={handleSave} >   Save </Button>
      </div>
    </div>
  )
} 