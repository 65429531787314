import styled from 'styled-components';

const className = (strings, props) => {
  const classNames = [
    'modal-card-head'
  ]
    .join(' ');

  return classNames;
};

const ModalCardHead = styled.header.attrs((props) => ({
  className: className`${props}`
}))``;

ModalCardHead.propTypes = {
};

ModalCardHead.defaultProps = {
};

export default ModalCardHead;
