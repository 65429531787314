import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Section, Container, Title, Icon, Columns, Column, Modal, Button } from '@safelyq/bulma-ui-library';
import { Table, Empty, Spin } from 'antd';
import { useData, useSaveBusinessAttatchment, useDeleteBusinessAttatchment } from './hooks';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { toast as showToast } from 'react-toastify';
import moment from 'moment';
import TileView from './components/TileView/Tileview';
import ReactTooltip from 'react-tooltip';
import Overflow from 'react-overflow-indicator';

const isValidFileUploaded = (file) => {
    const validExtensions = ['csv']
    const fileExtension = file.type.split('/')[1]
    return validExtensions.includes(fileExtension)
}

export const ManageFiles = () => {

    let { businessId } = useParams();
    const [csvDuplicateModal, setCsvDuplicateModal] = useState(false);

    const { isDataLoading, data, refetch } = useData();
    const { isSaving, onUploadAttachment, hasDuplicatesState: isDuplicate } = useSaveBusinessAttatchment(refetch);
    const { isDeleting, onDeleteAttachment } = useDeleteBusinessAttatchment();
    const [singleFile, setsingleFile] = useState({ fileName: "", fileRecords: [] });
    const [base64Attatchments, setBase64Attatchments] = useState([])
    const [isLoading, setisLoading] = useState(false);
    const [csvFile, setCsvFile] = useState();
    const [inputFileKey, setInputFileKey] = useState(0);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setsingleFile({ fileName: selectedRows[0].fileName, fileRecords: selectedRows[0].fileRecords })
        },
    };

    const onSelectFile = (selectedRow) => {
        setsingleFile({ fileName: selectedRow.fileName, fileRecords: selectedRow.fileRecords })
        const element = document.getElementById('section_1');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const onDeleteFile = (e, ID) => {
        e.stopPropagation()

        if (!window.confirm('Are you sure you want to delete the record?')) return;
        onDeleteAttachment({
            variables: {
                attachmentId: ID
            },
        }).then(() => {
            refetch();
            setsingleFile({ fileName: "", fileRecords: [] })
        });

    }

    const onUploadChange = (e) => {
        let files = e.target.files
        if (files.length > 0) {
            if (data?.getBusinessById?.businessAttachments.length === 5) {
                return showToast.error('you cannot add more then 5 files');
            }
            for (let i = 0; i < files.length; i++) {
                const isvalid = isValidFileUploaded(files[i])
                if (isvalid) {
                    let reader = new FileReader();
                    reader.readAsDataURL(files[i]);
                    reader.onload = function () {

                        let obj = { name: files[i].name, file: reader.result }
                        setBase64Attatchments(() => ([...base64Attatchments, obj]))
                        const parsedBase64 = reader.result.split(",")[1]
                        let businessFileInput = {
                            businessId: businessId,
                            attachment: {
                                fileName: files[i].name,
                                base64String: parsedBase64,
                                contentType: "application/csv"
                            }
                        };

                        // Set file
                        setCsvFile(businessFileInput)
                        // Append key to trigger the input for the same file
                        setInputFileKey((prevKey) => prevKey + 1);
                    };
                    reader.onerror = function (error) {
                    };
                }
                else {
                    showToast.error('Please upload only csv file');
                    setBase64Attatchments([])
                    break;
                }
            }
        }
    };

    // When file is set then call APi
    useEffect(() => {
        if (csvFile) {
            onUploadAttachment({
                variables: {
                    businessFileInput: csvFile,
                },
            }).then((response) => {
                // refetch();
                setBase64Attatchments([])
            });
        }
    }, [csvFile])

    const handlleDublicateConfirmation = (status) => {
        onUploadAttachment({
            variables: {
                businessFileInput: {
                    ...csvFile, isDuplicate: status
                },
            },
        }).then((response) => {
            setCsvDuplicateModal(false);
            // refetch();
            setBase64Attatchments([])
        });
    }

    useEffect(() => {
        // Run when Duplicate exists and loading has also stopped
        if (isDuplicate && !isSaving) {
            setCsvDuplicateModal(true);
        }
    }, [isDuplicate, isSaving])

    useEffect(() => {
        if (isDataLoading) { setisLoading(true) }
        else if (isSaving) { setisLoading(true) }
        else if (isDeleting) { setisLoading(true) }
        else {
            setisLoading(false)
        }

    }, [isDataLoading, isSaving, isDeleting])

    const columns = [
        { title: 'File Name', dataIndex: 'fileName', key: 'fileName', },
        { title: 'Date Added', dataIndex: 'createdTime', key: 'createdTime', render: (text, record, index) => moment(text).utc().format('YYYY-MM-DD hh:mm A') },
        {
            title: 'Total Records',
            render: (text, record, index) => {
                return (

                    <p>{record.fileRecords.length}</p>

                )
            }
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: 'action',
            render: (text, record, index) => {
                return (
                    <div className='has-text-centered'>
                        <Button className="is-danger is-light" onClick={(e) => onDeleteFile(e, record.id)} type="danger" size="small" >
                            <Icon name='trash' />
                        </Button>
                        {/* <Button className="is-danger is-light" onClick={(e) => onDeleteFile(e, record.id)} type="danger" size="small" icon={<DeleteOutlined />} /> */}
                    </div>

                )
            }
        }
    ]

    const singleRecordColumn = [
        { title: 'First Name', dataIndex: 'firstName', key: 'firstName', },
        { title: 'Last Name', dataIndex: 'lastName', key: 'lastName', },
        { title: 'Email', dataIndex: 'email', key: 'email', },
        { title: 'Phone Number', dataIndex: 'phoneNumber', key: 'phoneNumber', },
        { title: 'State', dataIndex: 'state', key: 'state', },
        { title: 'Zip Code', dataIndex: 'zipCode', key: 'zipCode', },
        { title: 'Address', dataIndex: 'address1', key: 'address1', }

    ]

    return (
        <Section>
            <Container>
                <Columns multiline className='mb-5'>
                    <Column className='p-1' size='12'>
                        <Columns className="is-mobile is-gapless"  >
                            <Column style={{ display: "flex", alignItems: "center" }}  >
                                <Title size='6'>
                                    <Icon name='bullhorn' className='mr-3' />
                                    Manage Campaign Files
                                </Title>
                            </Column>
                            <Column size='3' >
                                <div className="file has-name   is-small  is-info">
                                    <label className="file-label">
                                        <input className="file-input" key={inputFileKey} onChange={(e) => onUploadChange(e)} type="file" name="resume" />
                                        <span className="file-cta">
                                            <span className="file-icon"> <UploadOutlined /> </span>
                                            <span className="file-label">  {base64Attatchments.length > 0 ? (base64Attatchments[0].name) : "Add file"} </span>
                                        </span>
                                    </label>
                                </div>
                                <a style={{ fontSize: '12px', color: "#3298dc" }} download href="https://res.cloudinary.com/safelyq/raw/upload/v1678380221/samples/TemplateFiles/DefaultTemplate_yu05k9.csv" target="_blank" rel="noreferrer" >Click to download template </a>
                            </Column>
                        </Columns>
                        <div className='box'>
                            <div className='is-hidden-mobile'>
                                <Table rowSelection={{
                                    type: "radio",
                                    ...rowSelection,
                                }}
                                    scroll={{ x: 'max-content' }}
                                    responsive
                                    breakpoint="768"
                                    size={"small"}
                                    bordered
                                    loading={isLoading}
                                    // getPopupContainer={getPopupContainer}
                                    rowKey={(record) => record.id}
                                    pagination={{ position: ["none"] }} columns={columns} dataSource={data?.getBusinessById?.businessAttachments} />
                            </div>
                            <div className='is-hidden-tablet'>
                                {data?.getBusinessById?.businessAttachments?.map((attatchment) => {
                                    return (
                                        <TileView data={attatchment} key={attatchment.id} onSelectFile={onSelectFile} onDeleteFile={onDeleteFile} />
                                    )
                                })}
                            </div>

                        </div>
                    </Column>
                </Columns>
                {singleFile.fileName && (<Title size='6'>
                    Records of {singleFile.fileName} file
                </Title>)}
                <>

                    <Columns multiline>
                        <Column id="section_1" className='p-1' size='12'>
                            {singleFile.fileName && singleFile.fileRecords.length > 0 ? (
                                <div className='box'>
                                    <Table
                                        scroll={{ x: 'max-content' }}
                                        responsive
                                        breakpoint="768"
                                        size={"small"}
                                        bordered
                                        // getPopupContainer={getPopupContainer}
                                        rowKey={(record) => record.id}
                                        columns={singleRecordColumn} dataSource={singleFile.fileRecords} />
                                </div>
                            ) : (<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />)}

                        </Column>
                    </Columns>

                    {/* Confirmation For dublicate files Modal */}
                    <Modal active={csvDuplicateModal}>
                        <Modal.Background />
                        <Modal.Card className='modal-compaign'>
                            <Overflow style={{ overflow: "scroll" }}>
                                <Overflow.Content className='is-flex is-flex-direction-column'>
                                    <Modal.Card.Body style={{ overflow: "hidden" }} className='modal-compaign-body'>
                                        <div className='mb-4 is-flex is-justify-content-space-between is-align-items-center'>
                                            <Title size='5' className='m-0'>
                                                Dublicate Data Confirmation <span className='has-text-grey'> {data?.title} </span>
                                            </Title>

                                            <div>
                                                <Icon
                                                    name='times'
                                                    className='is-hoverable has-text-link'
                                                    onClick={() => setCsvDuplicateModal(false)}
                                                />
                                            </div>
                                        </div>
                                        <p className='mb-4 is-size-6'>
                                            Duplicated phone numbers and emails exist in the CSV file you uploaded. Are you sure you want to proceed and save them anyway?
                                        </p>
                                        <div class="is-flex is-justify-content-end mb-4">
                                            <Button className="button mx-3" color='secondary' type='button' onClick={() => setCsvDuplicateModal(false)}>Cancel</Button>
                                            {/* <button class="button is-danger" onClick={() => handlleDublicateConfirmation(false)}>Do Not Save</button> */}
                                            <Button className="button" color='success' type='button' loading={isSaving} onClick={() => handlleDublicateConfirmation(true)}>Save</Button>
                                        </div>
                                    </Modal.Card.Body>
                                </Overflow.Content>
                            </Overflow>
                        </Modal.Card>
                        <ReactTooltip />
                    </Modal>
                </>
            </Container>
        </Section>
    )
}
