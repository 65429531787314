import React, { Fragment } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Box, Button, Icon} from '@safelyq/bulma-ui-library';

export const TileView = ({ ...props }) => {
  const { businessCoupons, onModalPopulate, onCouponStatusChange } =
    props || {};

    const ActiveStatusTag = styled.span.attrs(({ }) => ({
      className: 'tag is-rounded',
    }))`
      background-color: rgb(174, 233, 209) !important;
    `;
    
    const ExpiredStatusTag = styled.span.attrs(({ }) => ({
      className: 'tag tag is-light is-rounded',
    }))``;

  return (
    <Fragment>
      {businessCoupons
        .sort((a, b) => new Date(b.endDate) - new Date(a.endDate))
        .map(
          (
            { id, title, isActive, code, discountType, discount, startDate, endDate },
            index
          ) => (
            <Box onClick={(e) => onModalPopulate(e, id)} className='p-2' shadow key={`business-coupon-${index}`}>
              <div className='is-flex is-justify-content-space-between is-size-7'>
                <div>
                  <strong>Code</strong>
                  <br />
                  <span>{code}</span>
                  <br />
                  <br />
                  {/* for responsiveness */}
                  <div style={{maxWidth: "65px"}}>
                    <strong>Title</strong>
                    <br />
                    <span>{title}</span>
                  </div>
                </div>
                <div>
                  <strong>Start Date</strong>
                  <br />
                  <span>{moment(startDate).format('DD MMM YYYY')}</span>
                  <br />
                  <br />
                  <div>
                    <strong>Value</strong>
                    <br />
                    <span>{discountType==="Value" ? `$${discount}` : `${discount}%` }</span>
                  </div>
                </div>
                <div>
                  <strong>Expiry Date</strong>
                  <br />
                  <span>{moment(endDate).format('DD MMM YYYY')}</span>
                  <br />
                  <br />
                  {!moment(moment().format('YYYY-MM-DD HH:mm:ss')).isBefore(
                    moment(
                      moment(endDate).format('YYYY-MM-DD') + ' ' + '24:00:00'
                    )
                  ) ? (
                    <ExpiredStatusTag>Expired</ExpiredStatusTag>
                  ) : (
                    <ActiveStatusTag>Active</ActiveStatusTag>
                  )}
                </div>
              </div>
            </Box>
          )
        )}
    </Fragment>
  );
};
