import React, { useState } from 'react';
import moment from 'moment';
import {
  Section,
  Container,
  DateInput,
  Field,
  Control,
  Label,
  Columns,
  Column,
} from '@safelyq/bulma-ui-library';
// import { ManageLogs } from '@safelyq/appointment-logs-feature';
import AppointmentsSummary from './Components/AppointmentsSummary';

const currentDate = moment().format('YYYY-MM-DD');

export const ManageReports = () => {
  const [date, setDate] = useState(currentDate);

  const handleDateChange = ({ target: { value } }) => setDate(value);

  return (
    <Columns multiline gap={'0'}>
      <Column size={12}>
        <Section className='pb-0'>
          <Container>
            <Columns multiline>
              <Column size={4}>
                <Control>
                  <Field>
                    <Label>Choose date</Label>
                    <DateInput value={date} onChange={handleDateChange} />
                  </Field>
                </Control>
              </Column>
            </Columns>
          </Container>
        </Section>
      </Column>
      <Column size={12} className=''>
        <Section className='pb-0'>
          <Container>
            <Columns multiline>
              <Column size={12}>
                <AppointmentsSummary selectedDate={date} />
              </Column>
            </Columns>
          </Container>
        </Section>

        {/* <ManageLogs selectedDate={date} /> */}
      </Column>
    </Columns>
  );
};
