import React, { Fragment, useRef, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { Button } from '@safelyq/bulma-ui-library';
import { RichEditor } from '../RichEditor';

export const NotificationsChannel = ({ ...props }) => {
  const {
    notificationTemplateType,
    templates,
    templatesReplacements = [],
    onSave,
    isLoading,
  } = props || {};

  const editorRef = useRef(null);

  const [content, setContent] = useState('');

  const [editIndex, setIndex] = useState(-1);

  const [isFieldTouch, setFieldTouch] = useState(false);

  // Need to fix it
  //useEffect(() => {
  //    if (editIndex && editIndex !== -1) {
  //        templates[editIndex]?.contentData?.value = content;
  //    }
  //}, [editIndex, content, templates]);

  const handleContentChange = () => {
    if (editorRef.current) setContent(editorRef.current.getContent());
    setFieldTouch(true);
  };

  const handleCancel = (oldContent) => {
    if (window.confirm('Are you sure you want to discard changes?')) {
      setContent(oldContent);
      setIndex(-1);
    }
  };

  return (
    <Accordion allowZeroExpanded={true}>
      {templates?.map(({ notificationChannel, notificationChannelDispalyName, contentData }, index) => (
        <AccordionItem uuid={index + 1}>
          <AccordionItemHeading>
            <AccordionItemButton>{notificationChannelDispalyName}</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            {editIndex == index ? (
              <Fragment>
                <RichEditor
                  initialValue={contentData?.value}
                  content={content}
                  editorRef={editorRef}
                  isEnableToolbar={notificationChannel == 'Email'}
                  isEnableMenubar={notificationChannel == 'Email'}
                  onChange={handleContentChange}
                  templatesReplacements={templatesReplacements}
                />
                <br />
                <Button
                  size='small'
                  color='info'
                  disabled={!isFieldTouch || isLoading}
                  isLoading={isLoading}
                  onClick={() => {
                    onSave(contentData.id, notificationChannel, notificationTemplateType, content).
                      then(({ data }) => {
                        const { saveNotificationTemplate: { notificationTemplate: { value } } } = data || {};
                        setContent(value);
                        // ToDo: Template updated values is not reflecting after the save button is clicked.
                        //templates[editIndex]?.contentData?.value = value;
                        //contentData.value = value;
                        setIndex(-1);
                      });
                  }}
                >
                  Save
                </Button>{' '}
                &nbsp;
                <Button
                  size='small'
                  color='info'
                  outlined
                  disabled={!isFieldTouch || isLoading}
                  onClick={() => handleCancel(contentData.value)}
                >
                  Cancel
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <div dangerouslySetInnerHTML={{ __html: contentData?.value }} />
                <br />
                <Button
                  size='small'
                  color='info'
                  onClick={() => setIndex(index)}
                  disabled={notificationChannel === "WhatsApp"}
                >
                  Edit
                </Button>
              </Fragment>
            )}
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};
