import { toast as showToast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { ADD_COMMENT } from './requests';

export const useData = ({ onSaveComment, appointmentId }) => {
  const [onAddComment, { loading: isLoading }] = useMutation(ADD_COMMENT, {
    fetchPolicy: 'no-cache',
    onCompleted: ({
      addAppointmentComment: { isSaved, appointmentComment },
    }) => {
      if (isSaved) onSaveComment(appointmentComment);
      else showToast.error('Something went wrong');
    },
    onError: () => showToast.error('Something went wrong'),
  });
  return { isLoading, onAddComment };
};
