import { bool } from 'prop-types';
import styled from 'styled-components';

const className = (strings, props) => {
  props.shadow = !props.shadow;
  const classNames = ['box']
    .concat(
      ['shadow']
        .map((name) => (props[name] ? `is-${name}less` : null))
        .filter((name) => !!name)
    )
    .join(' ');

  return classNames;
};

const Box = styled.div.attrs((props) => ({
  className: className`${props}`,
}))`
  border: ${({ bordered }) => bordered && '1px solid #80808033'};
`;

Box.propTypes = {
  shadow: bool,
};

Box.defaultProps = {
  shadow: false,
};

export default Box;
