import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { DELETE_BUSINESS_HOLIDAY } from '../requests';

export const useDeleteBusinessHoliday = (refetch) => {
    const [onBusinessHolidayDelete, { loading: isDeleteLoading, error }] = useMutation(DELETE_BUSINESS_HOLIDAY, {
        onCompleted: ({ deleteBusinessHoliday: { isSaved, errorMessage, }, }) => {
            if (isSaved) {
                toast.success('Holiday Deleted Successfully');
                refetch()
            }
            else {
                throw errorMessage || error;
            }
        },
        onError: (error) => {
            throw error;
        }
    });
    return { isDeleteLoading, onBusinessHolidayDelete, };
}; 