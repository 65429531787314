import React, { useCallback } from 'react';
import { useProfile } from 'hooks/useProfile';

export function Authenticating() {
  const { login } = useProfile();

  const attemptLogin = useCallback(
    async (e) => {
      e.preventDefault();
      sessionStorage.clear();
      await login();
    },
    [login]
  );

  // Commenting this line as it is not working as expected.
  // useEffect(() => logout(), []);

  return (
    <div className='hero is-fullheight'>
      <div className='hero-body is-paddingless'>
        <div className='container has-text-centered'>
          <div className='column is-4 is-offset-4'>
            <div className='box p-6'>
              <a href='/'>
                <img src='/images/logo.png' alt='logo' />
              </a>
              <h5>You are authenticating. Please wait or <br/> <a onClick={attemptLogin} >click here</a>.</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
