import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { isGSMAlphabet, emojiRegex } from "methods/isGSMAlphabet";

export const TemplateTextPreview = ({ reorderedTemplateText, selectedMessageType, campaign, campaignGroups, sMS, setSMS, campaignFiles }) => {
    const state = useSelector(state => state)
    const business = state?.business;

    const extensions = {
        video: {
            type: ['mpeg', 'mp4', 'quicktime', 'webm', '3gpp', '3gpp2', '3gpp-tt', 'H261', 'H263', 'H263-1998', 'H263-2000', 'H264'],
            preview: [' video/mpeg ', ' video/mp4 ', ' video/quicktime ', ' video/webm ', ' video/3gpp ', ' video/3gpp2 ', ' video/3gpp-tt ', ' video/H261 ', ' video/H263 ', ' video/H263-1998 ', ' video/H263-2000 ', ' video/H264 ']
        },
        audio: {
            type: ['basic', 'L24', 'mp4', 'mpeg', 'ogg', 'vnd.rn-realaudio', 'vnd.wave', '3gpp', '3gpp2', 'ac3', 'webm', 'amr-nb', 'amr', 'mp3'],
            preview: [' audio/basic ', ' audio/L24 ', ' audio/mp4 ', ' audio/mpeg ', ' audio/ogg ', ' audio/vnd.rn-realaudio ', ' audio/vnd.wave ', ' audio/3gpp ', ' audio/3gpp2 ', ' audio/ac3 ', ' audio/webm ', ' audio/amr-nb ', ' audio/amr ', ' mp3 ']
        },
        image: {
            type: ['jpeg', 'jpg', 'gif', 'png', 'bmp', 'tiff'],
            preview: [' image/jpeg ', ' image/jpg ', ' image/gif ', ' image/png ', ' image/bmp ', ' image/tiff ']
        }
    }


    // Responsive width for the Preview Files
    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isSmallScreen = width <= 600;

    // Template Variables
    let templateTextSms;
    reorderedTemplateText.forEach((template) => {
        if (template?.notificationChannelDispalyName === "SMS") {
            templateTextSms = template?.contentData?.value
                .replace(":BusinessName:", `${business?.businessName}`)
                .replace(":CampaignDetails:", selectedMessageType?.name === "All" ? campaign?.details || "'Your Message'" : campaignGroups.find(campaign => campaign.name === "SMS")?.messageText || "'Your Message'")
                .replace(/\n/g, "<br/>")
            // .replace("<br />", /\r\n/g,)
        }
    })

    let [arraySmsText, setArratSmsText] = useState([]);

    useEffect(() => {
        // Segments Count only for SMS
        if (templateTextSms) {
            let count = 1;
            let length = templateTextSms.length;
            let maxCharLength = sMS?.maxCharacter || 92;

            while (true) {
                if (length < (maxCharLength * count)) {
                    setSMS((sMS) => ({ ...sMS, sMSSegment: count }))
                    break;
                }
                count++;
            }
        }

        // Return Array of characters with maxCharacter chunks
        let chunks = [];
        let limit = sMS?.maxCharacter;
        if (templateTextSms && limit !== 0) {

            let startIndex = 0;
            while (startIndex < templateTextSms.length) {
                const chunk = templateTextSms.slice(startIndex, startIndex + limit);
                // Removed <p></p> tags
                const cleanedString = chunk.replace(/<\/?p>/g, '');
                // const removeEmojis = cleanedString.replace(emojiRegex, '')
                // console.log("removed emojis:", removeEmojis);
                // chunks.push(removeEmojis);
                chunks.push(cleanedString);
                startIndex += limit;
            }
        }
        if (chunks.length !== 0) {
            setArratSmsText(chunks)
        }
    }, [templateTextSms, sMS?.maxCharacter])

    const StyledImage = styled.img`
      @media only screen and (min-width: 601px){
  width: 50%;
  height: 200px;
  object-fit: contain;
  margin-bottom: 8px;
  border-radius: 12px;
    }
    @media only screen and (max-width: 600px){
  width: 100%;
  height: 200px;
  object-fit: contain;
  margin-bottom: 8px;
  border-radius: 12px;
    }
  
`;

    const DeleteButton = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

    const StyledControl = styled.div`
  &:hover ${DeleteButton} {
    display: flex;
  }
`;


    return (
        <Fragment>
            {reorderedTemplateText?.filter(template => campaignGroups.find(camp => camp?.name === template.notificationChannelDispalyName && camp?.isSelected)).map((template, index) => {
                return <MarketingBox key={index}>
                    <p className='is-size-6'> <span className='has-text-weight-semibold'> Message Type: {template?.notificationChannelDispalyName}</span> </p>
                    {template?.notificationChannelDispalyName === "SMS" && `SMS Segments: ${sMS?.sMSSegment}`}
                    {/* <p>{template?.subjectData?.value}</p> */}
                    {
                        // Get Mesage Text from campaign details for all campaign groups
                        <div style={{ width: '100%', wordWrap: 'break-word' }} dangerouslySetInnerHTML={{
                            __html:
                                template?.notificationChannelDispalyName === "SMS" ?
                                    arraySmsText?.map((line, index) => {
                                        let color = "black";
                                        let backgroundColor = index % 2 === 0 ? '#bec2cb' : "#f1f1f1";
                                        return `<span style="background-color: ${backgroundColor}; color: ${color}; border-radius:0px">${line}</span>`;
                                    }).join('')
                                    :
                                    // Add Extra line breaks on whatsapp
                                    template?.notificationChannelDispalyName === "WhatsApp"
                                        ?
                                        template?.contentData?.value
                                            .replace(":BusinessName:", `${business?.businessName}`)
                                            // .replace(":CampaignDetails:", `${campaignGroups.find(campaign => campaign.name === template?.notificationChannelDispalyName)?.messageText}<br />` || "'Campaign Details' <br />")
                                            .replace(":CampaignDetails:", selectedMessageType?.name === "All" ? campaign?.details || "'Your Message'" : campaignGroups.find(campaign => campaign?.name === template?.notificationChannelDispalyName)?.messageText || "'Your Message'")
                                            .replace(/\r\n/g, "<br />")
                                        :
                                        template?.contentData?.value
                                            .replace(":BusinessName:", `${business?.businessName}`)
                                            .replace(":CampaignDetails:", selectedMessageType?.name === "All" ? campaign?.details || "Your Message'" : campaignGroups.find(campaign => campaign?.name === template?.notificationChannelDispalyName)?.messageText || "'Your Message'")
                        }} />
                    }
                    {/*  MMS Files Preview */}
                    <div className='mt-2'>
                        {campaignFiles?.length !== 0 && (template?.notificationChannelDispalyName === "MMS" || template?.notificationChannelDispalyName === "Email") && campaignFiles?.map((file, index) => {
                            return <div key={index} className='is-relative'>
                                {file &&
                                    // file?.contentType === "mp4" ? (
                                    // previw array has spaces, we first need to remove that
                                    (extensions?.video?.type.includes(file?.contentType) || extensions?.video?.preview?.some(item => item.trim() === file?.contentType)) ? (
                                    <video controls className='mt-2'
                                        style={{
                                            width: isSmallScreen ? '100%' : '50%',
                                            height: '163px',
                                            objectFit: 'contain',
                                            marginBottom: '8px',
                                            borderRadius: '12px',
                                        }}
                                    >
                                        <source src={file?.url || window?.atob(file?.base64String)} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>

                                )
                                    :
                                    // file?.contentType === "mp3" ? (
                                    (extensions?.audio?.type.includes(file?.contentType) || extensions?.audio?.preview?.some(item => item.trim() === file?.contentType)) ? (
                                        <audio controls className='mt-2'
                                            style={{
                                                width: '100%',
                                                height: '65px',
                                                objectFit: 'contain',
                                                marginBottom: '8px',
                                                borderRadius: '12px',
                                                ...(window.innerWidth <= 600 && {
                                                    width: '100%',
                                                }),
                                                ...(window.innerWidth > 600 && {
                                                    width: '50%',
                                                }),
                                            }}
                                        >
                                            <source src={file?.url || window?.atob(file?.base64String)} type="audio/mp3" />
                                            Your browser does not support the audio tag.
                                        </audio>
                                    )
                                        :
                                        // (file?.base64String || file?.url) && (
                                        (extensions?.image?.type.includes(file?.contentType) || extensions?.image?.preview?.some(item => item.trim() === file?.contentType)) ? (
                                            <StyledControl key={file?.url} className='control is-relative'>
                                                <StyledImage src={file?.url || window.atob(file.base64String)} />
                                            </StyledControl>
                                        )
                                            :
                                            "File format is not supported"}
                            </div>
                        })
                        }
                    </div>
                </MarketingBox>
            })}
        </Fragment>
    )
}

const MarketingBox = styled.div`
  border: 2px solid #50677736;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 16px
`;

