import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    authinfo: null,
    userinfo: {
        isAuthenticated: false,
        unreadCount: 0,
        isPhoneNumberConfirmed: false,
        totalNotifications: 0,
        id: null,
        email: null,
        firstName: null,
        lastName: null,
        name: null,
        isCompleted: false,
        isSuperAdmin: false,
    },
    allAppointments: [],
    allMessages: [],
    uniqueString: ""
}
export const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        adduser: (state, action) => {
            state.authinfo = action.payload.authinfo
            state.userinfo.id = action.payload.userinfo.id
            state.userinfo.isAuthenticated = action.payload.userinfo.isAuthenticated
            state.userinfo.unreadCount = action.payload.userinfo.unreadCount
            state.userinfo.isPhoneNumberConfirmed = action.payload.userinfo.isPhoneNumberConfirmed
            state.userinfo.email = action.payload.userinfo.email
            state.userinfo.firstName = action.payload.userinfo.firstName
            state.userinfo.lastName = action.payload.userinfo.lastName
            state.userinfo.name = action.payload.userinfo.name
            state.userinfo.isSuperAdmin = action.payload.userinfo.isSuperAdmin
            state.userinfo.isCompleted = action.payload.userinfo.isCompleted
            state.userinfo.totalNotifications = action.payload.userinfo.totalNotifications
        },

        addAppointments: (state, action) => {
            state.allAppointments = action.payload.allAppointments
            state.userinfo.totalNotifications = action.payload.totalNotifications
        },
        changeApptStatus: (state, action) => {
            const updated_appts = state.allAppointments.map((appt) => {
                let obj = { ...appt }
                if (appt.id === action.payload.apptID) {
                    obj.status = action.payload.apptStatus
                }
                return obj
            })
            return {
                ...state,
                allAppointments: updated_appts
            }
        },
        addCommentInAppointment: (state, action) => {
            const updated_apptssss = state.allAppointments.map((appt) => {
                let obj = { ...appt }
                if (obj.id === action.payload.apptId) {
                    obj.comments = [action.payload.newComment, ...obj.comments]
                }
                return obj
            })
            return {
                ...state,
                allAppointments: updated_apptssss
            }
        },
        updateAppt: (state, action) => {
            const updated_apptssss = state.allAppointments.map((appt) => {
                let obj = { ...appt }
                if (obj.id === action.payload.apptId) {
                    obj = action.payload.appt
                }
                return obj
            })
            return {
                ...state,
                allAppointments: updated_apptssss
            }
        },
        updateNotification: (state) => {
            state.userinfo.totalNotifications = 0
        },

        updateNotificationagain: (state) => {
            state.userinfo.totalNotifications = state.userinfo.totalNotifications + 1
        },



        getAllMessages: (state, action) => {
            const all_Messages = action.payload.allMessages
            const newArray = all_Messages.map((obj, index) => ({
                ...obj,
                active: false,
            }));
            // newArray[0].active = true

            return {
                ...state,
                allMessages: newArray,
                userinfo: {
                    ...state.userinfo,
                    unreadCount: action.payload.unreadCount,
                    totalNotifications: action.payload.totalNotifications
                },

            }
        },
        updateSingleChatUnreadCount: (state, action) => {
            const updated_messages = state.allMessages.map((msg) => {
                let obj = { ...msg }
                if (obj.business.id === action.payload.businessid) {
                    obj.unreadCount = 0
                }
                return obj
            })
            return {
                ...state,
                allMessages: updated_messages,
                userinfo: {
                    ...state.userinfo,
                    unreadCount: action.payload.unreadCount,
                },

            }
        },
        toggleActiveChat: (state, action) => {

            const newArray = state.allMessages.map((obj) => ({
                ...obj,
                active: obj.business.id === action.payload.businessId,
            }));
            return {
                ...state,
                allMessages: newArray,
            }
        },

        // Deactivates all the business messages
        toggleDeactivateChat: (state, action) => {

            const newArray = state.allMessages.map((obj) => ({
                ...obj,
                active: false,
            }));
            return {
                ...state,
                allMessages: newArray,
            }
        },

        resetallMessages: (state, action) => {
            const newArray = state.allMessages.map((obj, index) => ({
                ...obj,
                active: false, // Set 'active' to true for the first index and false for the rest
            }));
            return {
                ...state,
                allMessages: newArray,
            }
        },
        getAllMessagesWithLatestMessage: (state, action) => {
            // then we check if the active businessid is equal to latest message businessid
            const actveMessage = state.allMessages.find(message => message.active)

            if (actveMessage) {
                if (actveMessage.business.id === parseInt(action.payload.latestMessage.businessId)) {
                    const all_Messages = action.payload.allMessages
                    const newArray = all_Messages.map((obj, index) => ({
                        ...obj,
                        active: actveMessage.business.id === obj.business.id ? true : false,
                    }));
                    // newArray[0].active = true
                    return {
                        ...state,
                        allMessages: newArray,
                        userinfo: {
                            ...state.userinfo,
                            unreadCount: action.payload.unreadCount,
                            totalNotifications: action.payload.totalNotifications
                        },
                    }
                }
                else {
                    const allNewMessages = action.payload.allMessages
                    const newArasdfasdfray = allNewMessages.map((obj, index) => ({
                        ...obj,
                        active: actveMessage.business.id === obj.business.id ? true : false,
                    }));

                    return {
                        ...state,
                        allMessages: newArasdfasdfray,
                        userinfo: {
                            ...state.userinfo,
                            unreadCount: action.payload.unreadCount,
                            totalNotifications: action.payload.totalNotifications
                        },

                    }
                }
            }
            else {

                const all_NewMessages = action.payload.allMessages
                const newNArray = all_NewMessages.map((obj, index) => ({
                    ...obj,
                    active: false,
                }));
                // newNArray[0].active = true
                return {
                    ...state,
                    allMessages: newNArray,
                    userinfo: {
                        ...state.userinfo,
                        unreadCount: action.payload.unreadCount,
                        totalNotifications: action.payload.totalNotifications
                    },
                }
            }
        },


    },
})

// Action creators are generated for each case reducer function
export const {
    adduser,
    addAppointments,
    changeApptStatus,
    addCommentInAppointment,
    updateAppt,
    updateNotification,
    updateNotificationagain,
    getAllMessages,
    updateSingleChatUnreadCount,
    toggleActiveChat,
    toggleDeactivateChat,
    resetallMessages,
    getAllMessagesWithLatestMessage

} = AuthSlice.actions

export default AuthSlice.reducer