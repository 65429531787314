import { gql } from '@apollo/client';

export const VERIFY_CODE = gql`
  mutation VerifyCode($code: String) {
    confirmVerificationCode(code: $code) {
      error
      isPhoneNumberUpdated
      isSuccess
    }
  }
`;

export const RESEND_CODE = gql`
  mutation ResendCode {
    sendVerificationCode {
      error
      isSuccess
    }
  }
`;
