import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
    Section,
    Container,
    Title,
    Columns,
    Column,
    Field,
    Icon,
    Control,
    TextInput,
    Button
} from "@safelyq/bulma-ui-library";
import { Loader } from "@safelyq/base-feature";
import { NoCategoryFound } from "./components/NoCategoryFound";
import { CategoriesTable } from "./components/CategoriesTable";
import BreadCrumbs from "features/business-admin/src/components/Breadcrumbs";
import { useCategoriesData, useCategoryUpdate, useCategoryDelete } from "./hooks";
import { useParams } from "react-router-dom";

const StyledSearchBar = styled(TextInput).attrs(() => ({}))`
  background: rgba(0, 0, 0, 0.02);
`;

const BusinessCategories = () => {

    const { businessId } = useParams();
    const [categoriesState, setCategoriesState] = useState([]);

    // Used for confirmation message and dynamic text in the modal
    const [confirmUpdate, setConfirmUpdate] = useState({
        type: null,
        status: null
    });

    // Custom hooks for api
    const { loadingCategories, errorCategories, categories, getBusinessCategories, onSearchBusinessCategory } = useCategoriesData(); // Get all categories
    const { loadingUpdate, errorUpdate, onUpdateBusinessCategory } = useCategoryUpdate(); // Update category
    const { loadingDelete, errorDelete, onDeleteBusinessCategory } = useCategoryDelete(); // Delete category

    useEffect(() => {
        getBusinessCategories()
    }, [])

    // set categories to a state so when data is updated we dont have to refetch and simply update state 
    useEffect(() => {
        categories && categories?.length !== 0 && setCategoriesState(categories)
    }, [categories])

    const handleSearch = ({ target: { value } }) => {
        onSearchBusinessCategory(value);
    };

    const [isOpenModal, setIsOpenModal] = useState(false);
    const [categoryToUpdate, setCategoryToUpdate] = useState({
        name: null,
        description: null,
        couponAllowed: null,
        googleReserveAllowed: null
    });

    const [allowLoading, setAllowLoading] = useState(false);
    const [initialCategoryData, setInitialCategoryData] = useState(null)

    const handleIsOpenModal = (category, type) => {

        // New category is being created becuase name is null
        if (!category.name) {
            setInitialCategoryData(category);

            setConfirmUpdate({
                type: "add",
                status: true,
            });
        }
        else {
            // Category is being updated or deleted
            if (type === "edit") {
                const { name, description, features } = category;
                const { couponAllowed, googleReserveAllowed } = features;
                setCategoryToUpdate({ name, description, couponAllowed, googleReserveAllowed })
                // Set Intial state for comparison if the data is changed or not
                setInitialCategoryData({ name, description, couponAllowed, googleReserveAllowed });

                setConfirmUpdate({
                    type: "update",
                    status: false,
                });
            }
            // delete confirmation
            else if (type === "delete") {
                const categoryToDelete = categoriesState?.find(state => state?.name == category.name)
                const categoryToDeleteRevised = ({
                    name: categoryToDelete?.name,
                    description: categoryToDelete?.description,
                    couponAllowed: categoryToDelete?.features?.categoryToDelete,
                    googleReserveAllowed: categoryToDelete?.features?.googleReserveAllowed,
                })
                setCategoryToUpdate(categoryToDeleteRevised)

                setConfirmUpdate({
                    type: "delete",
                    status: true,
                });
            }
        }

        setIsOpenModal(true);
    }

    const handleModalClose = () => {

        setCategoryToUpdate({
            name: null,
            description: null,
            couponAllowed: null,
            googleReserveAllowed: null
        })

        setIsOpenModal(false);
        setInitialCategoryData(null);
    }

    const handleSaveBusinessCategory = async () => {

        // new category data
        const newCategory = ({
            name: categoryToUpdate?.name,
            description: categoryToUpdate?.description,
            features: {
                couponAllowed: categoryToUpdate?.couponAllowed,
                googleReserveAllowed: categoryToUpdate?.googleReserveAllowed
            }
        })

        // upadte state
        if (categoryToUpdate?.name === initialCategoryData?.name) {
            let newCategories = categoriesState?.map((category) => {
                // set updated category to categories state
                if (category?.name === categoryToUpdate?.name) {
                    return newCategory;
                }
                return category
            })
            setCategoriesState(newCategories);
        }
        // add in the state
        else {
            setCategoriesState([...categoriesState, newCategory])
        }

        // API call for add and update category
        await onUpdateBusinessCategory({
            variables:
            {
                businessCategoryInput: categoryToUpdate
            }
        })
        setAllowLoading(true);
        setCategoryToUpdate({
            name: null,
            description: null,
            couponAllowed: null,
            googleReserveAllowed: null
        }); // set the state to null
        setInitialCategoryData(null)
    }

    // Delete the business category
    const handleDeleteCategory = async () => {

        // API call for delete category
        const { data } = await onDeleteBusinessCategory({
            variables:
            {
                businessCategoryInput: categoryToUpdate
            }
        })
        const { deleteBusinessCategory } = data;
        const { isSaved } = deleteBusinessCategory;

        // if api is success than change/filter categories state
        if (isSaved) {
            // filter category from categories
            let newCategories = categoriesState?.filter((category) => {
                // set updated category to categories state
                if (category?.name !== categoryToUpdate?.name) {
                    return category;
                }
            })
            setCategoriesState(newCategories);

            setAllowLoading(true);
            setCategoryToUpdate({
                name: null,
                description: null,
                couponAllowed: null,
                googleReserveAllowed: null
            }); // set the state to null
            setInitialCategoryData(null)
        } else {
            // console.log("Category deletion failed");
        }
    };

    // close the modal when savedLoading is set to false
    useEffect(() => {
        if (allowLoading && (!loadingUpdate || !loadingDelete)) {
            handleModalClose();
        }
    }, [loadingUpdate, loadingDelete])

    if (loadingCategories) return <Loader isFullScreen />;

    const categoriesTableProps = {
        loadingUpdate,
        onUpdateBusinessCategory,
        categoriesState,
        setCategoriesState,
        handleIsOpenModal,
        handleModalClose,
        isOpenModal,
        setIsOpenModal,
        categoryToUpdate,
        setCategoryToUpdate,
        allowLoading,
        setAllowLoading,
        initialCategoryData,
        setInitialCategoryData,
        handleSaveBusinessCategory,
        handleDeleteCategory,
        confirmUpdate,
        setConfirmUpdate,
        loadingDelete
    }

    return (
        <Section>
            <Container className="mb-3">
                <BreadCrumbs />
            </Container>

            <Container>
                <header className="is-flex is-justify-content-space-between is-align-items-center">
                    <Title size="5" className="m-0">
                        Business Categories
                    </Title>
                </header>
                <Columns className="m-0 is-flex is-justify-content-space-between">
                    <Column size="3" className="pl-0">
                        <Field>
                            <Control>
                                <StyledSearchBar
                                    placeholder="Search Business Categories"
                                    onChange={handleSearch}
                                />
                                <Icon name="search" position="right" />
                            </Control>
                        </Field>
                    </Column>
                    <Column size="3" className="pl-0">
                        <Field>
                            <Control>
                                <Button color="info" onClick={() => handleIsOpenModal(categoryToUpdate)}>
                                    Create Business Category
                                </Button>
                            </Control>
                        </Field>
                    </Column>
                </Columns>
                <div className="is-hidden-mobile">
                    {!categories ? (
                        <NoCategoryFound />
                    ) : (
                        <CategoriesTable {...categoriesTableProps} />
                    )}
                </div>
            </Container>
        </Section>
    );
}

export default BusinessCategories;
