import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Button, Icon } from '@safelyq/bulma-ui-library';
import moment from 'moment';
import StarRatings from 'react-star-ratings';
import { SendReply } from './components';

const StyledResponseBox = styled.div`
  padding: 15px;
  background: #e5e5e5;
  position: relative;
  margin-top: 15px;
`;

const ReplyArrow = styled.div`
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top: 0;
  border-bottom: 20px solid #e5e5e5;
  position: absolute;
  margin-top: -13px;
  margin-left: 10px;
  top: 0;
  left: 0;
  z-index: 1;
`;

const VerifiedReviewIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px solid #ffffff;
  position: absolute;
  right: 0;
  bottom: 0;
  background: green;
  color: #ffffff;
  font-size: 10px;
  margin-bottom: -3px;
  margin-right: -7px;
`;

export const ReviewList = ({ userRatings, refetch }) => {
  const [selectReviewId, setSelectReviewId] = useState(-1);

  return (
    <Fragment>
      {userRatings.map(
        (
          {
            id,
            value,
            modifiedTime,
            reviewComment,
            reviewCommentResponse,
            isVerifiedVisitor,
          },
          index
        ) => {
          const { user, commentText } = reviewComment || {};

          const {
            commentText: responseText,
            modifiedTime: respondTimestamp,
            user: respondUser,
          } = reviewCommentResponse || {};

          return (
            <div className='rating-user'>
              <article className='media'>
                <figure className='media-left m-0 mr-3'>
                  <Button
                    className='avatar-icon rounded is-link'
                    style={{ width: '2.5rem' }}
                  >
                    {String(user.fullName).charAt(0).toUpperCase()}
                    {isVerifiedVisitor && (
                      <VerifiedReviewIcon>
                        <Icon name='check' />
                      </VerifiedReviewIcon>
                    )}
                  </Button>
                </figure>
                <div className='media-content'>
                  <div className='content mb-0'>
                    <p>
                      <strong>
                        <small>{user.fullName}</small>
                      </strong>
                      <br />
                      <small>
                        <StarRatings
                          rating={value}
                          starRatedColor='rgb(26 17 186)'
                          starSpacing='1px'
                          starDimension='15px'
                        />{' '}
                        <i>{moment(modifiedTime).format('MMM DD, YYYY')}</i>
                      </small>
                    </p>
                  </div>
                </div>
              </article>
              <div className='mt-1'>
                <p>{commentText}</p>
              </div>
              {!reviewCommentResponse && selectReviewId !== id && (
                <Button
                  light
                  size='small'
                  color='info'
                  className='my-2'
                  onClick={() => setSelectReviewId(id)}
                >
                  <Icon name='reply' className='mr-2' />
                  Reply
                </Button>
              )}
              {!reviewCommentResponse && selectReviewId === id && (
                <SendReply
                  ratingId={selectReviewId}
                  refetch={refetch}
                  onClose={() => setSelectReviewId(-1)}
                />
              )}
              {reviewCommentResponse && (
                <Fragment>
                  <StyledResponseBox>
                    <ReplyArrow />
                    <article className='media'>
                      <figure className='media-left m-0 mr-3'>
                        <Button
                          size='small'
                          className='avatar-icon rounded is-link'
                        >
                          {String(respondUser.fullName).charAt(0).toUpperCase()}
                        </Button>
                      </figure>
                      <div className='media-content'>
                        <div className='content mb-0'>
                          <p>
                            <strong>
                              <small>{respondUser.fullName}</small>
                            </strong>
                            {'  '}
                            <small>
                              <i>
                                {moment(respondTimestamp).format(
                                  'MMM DD, YYYY'
                                )}
                              </i>
                            </small>
                          </p>
                        </div>
                      </div>
                    </article>
                    <div className='mt-1'>
                      <p>{responseText}</p>
                    </div>
                  </StyledResponseBox>
                </Fragment>
              )}
              {index !== userRatings.length - 1 && <hr className='my-4' />}
            </div>
          );
        }
      )}
    </Fragment>
  );
};
