import React from 'react';
import styled from 'styled-components';
import { Box, Columns, Column, Text, Icon } from '@safelyq/bulma-ui-library';

const Header = styled(Box).attrs(() => ({
  className: 'mb-0 px-2 py-1',
}))`
  border-radius: 0%;
`;

const TimeBadge = styled.div.attrs(() => ({}))`
  background: #3c3c3c;
  padding: 2px 11px;
  border-radius: 4px;
  color: white;
`;

export const AccordionDesktopHeader = ({
  time,
  totalGuests,
  totalAppointments,
  totalCheckedInAppointments,
}) => (
  <Header>
    <Columns multiline>
      <Column size={2}>
        <TimeBadge>
          <Text>
            <Icon name='clock-o' />
            &nbsp;
            <span>{time}</span>
          </Text>
        </TimeBadge>
      </Column>
      <Column size={2} style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Text style={{
          wordWrap: 'break-word',
          wordBreak: 'break-all',
        }}>
          <span>
            <b>
              <i>Appointments ({totalAppointments})</i>
            </b>
          </span>
        </Text>
      </Column>
      <Column size={2}>
        <Text>
          <span>
            <b>
              <i>Guests ({totalGuests})</i>
            </b>
          </span>
          &nbsp;
          <Icon name='users' />
        </Text>
      </Column>
      <Column size={2}>
        <Text>
          <span>
            <b>
              <i>Checked In ({totalCheckedInAppointments})</i>
            </b>
          </span>
        </Text>
      </Column>
    </Columns>
  </Header>
);

export const AccordionMobileHeader = ({
  time,
  totalGuests,
  totalAppointments,
  totalCheckedInAppointments,
}) => (
  <Header>
    <Columns gap='0' breakpoint='mobile' multiline>
      <Column size={4} className='mb-1'>
        <span className='tag is-dark'>{time}</span>
      </Column>
      <Column size={2} className='mb-1'></Column>
      <Column size={4} className='mb-1'>
        <Text>
          <span>
            <b>Appointments</b>
          </span>
        </Text>
      </Column>
      <Column size={2} className='mb-1'>
        <Text>{totalAppointments}</Text>
      </Column>
      <Column size={4} className='mb-1'>
        <Text>
          <span>
            <b>Guests</b>
          </span>
          &nbsp;
          <Icon name='users' />
        </Text>
      </Column>
      <Column size={2} className='mb-1'>
        <Text>{totalGuests}</Text>
      </Column>
      <Column size={4}>
        <Text>
          <span>
            <b>Checked In</b>
          </span>
        </Text>
      </Column>
      <Column size={2}>
        <Text>{totalCheckedInAppointments}</Text>
      </Column>
    </Columns>
  </Header>
);
