import React, { useContext, useReducer, createContext } from "react";

const AccountStateContext = createContext();
const AccountDispatchContext = createContext();

const accountReducer = (state, action) => {
  const { payload } = action;

  switch (action.type) {
    case "SET_PROFILE":
      const { user } = payload;

      const { userPreferences } = user;

      return {
        ...state,
        profile: {
          ...user,
          ...userPreferences,
          oldPhoneNumber: user.phoneNumber,
        },
      };

    case "UPDATE_PROFILE":
      const { name, value } = payload;

      return {
        ...state,
        profile: { ...state.profile, [name]: value },
      };

    case "UPDATE_SMS_PREFERNECE":
      return {
        ...state,
        profile: { ...state.profile, allowSms: payload },
      };

    case "UPDATE_WhatsApp_PREFERNECE":
      return {
        ...state,
        profile: { ...state.profile, allowWhatsApp: payload },
      };

    case "UPDATE_EMAIL_PREFERNECE":
      return {
        ...state,
        profile: { ...state.profile, allowEmail: payload },
      };

    default:
      throw new Error(`Unknow action type: ${action.type}`);
  }
};

export const AccountProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, {
    profile: { user: {} },
  });

  return (
    <AccountDispatchContext.Provider value={dispatch}>
      <AccountStateContext.Provider value={state}>
        {children}
      </AccountStateContext.Provider>
    </AccountDispatchContext.Provider>
  );
};

export const useAccountState = () => useContext(AccountStateContext);
export const useAccountDispatch = () => useContext(AccountDispatchContext);
